import React, { useMemo } from 'react';

import { WarningFilled } from '@ant-design/icons';
import { Typography } from 'antd';

import { countWords } from '../../utils/wordCountHelpers';

import AICorrectionsNavigation from './AICorrectionsNavigation';

export const AI_RESPONSE_FOOTER_CLASS = 'ai-response-footer';

const classes = {
    aiResponseFooter: `${AI_RESPONSE_FOOTER_CLASS}`,
    wordCountLabel: 'word-count-label',
};

const AiResponseFooter = ({ response }) => {
    const responseWordCount = useMemo(() => {
        if (!response) return 0;

        return countWords(response, true);
    }, [response]);

    return (
        <div className={classes.aiResponseFooter}>
            <Typography.Text>
                <WarningFilled />
                Check AI-generated content. It can make mistakes or replace important NLP keywords.
            </Typography.Text>
            <div className='d-flex'>
                <Typography.Text className={classes.wordCountLabel}>
                    {responseWordCount} words
                </Typography.Text>
                <AICorrectionsNavigation />
            </div>
        </div>
    );
};

export default AiResponseFooter;
