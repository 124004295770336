import { batch } from 'react-redux';

import { unauthorizedRoutes } from '../../components/common/router/Unauthorized/routes';
import { clearBillingSettings } from '../../components/pages/account/billing/store/billingSettings.actions';
import {
    clearAccountSettings,
    setUsers,
} from '../../components/pages/account/store/accountSettings.actions';
import { clearContentOptimizerMain } from '../../components/pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';
import { clearKeywordResearchSettings } from '../../components/pages/keywordResearch/store/keywordResearchSettings.actions';
import {
    setIsSubscriptionExpired,
    setUserData,
} from '../../userBrowserSettings/store/browserSettings.actions';
import { mainBreadcrumbPaths } from '../../constants/bredcrumbPaths';

export const cleanUpUserData = (dispatch) => {
    localStorage.setItem('loggedIn', false);

    batch(() => {
        dispatch(setUsers([]));
        dispatch(setUserData(null));
        dispatch(clearAccountSettings());
        dispatch(clearBillingSettings());
        dispatch(clearContentOptimizerMain());
        dispatch(clearKeywordResearchSettings());
        dispatch(setIsSubscriptionExpired(false));
    });
};

export const isUserAuthorized = () => localStorage.getItem('loggedIn') === 'true';

export const isAuthorizedRoute = (pathname = '', organizationSlug = null) => {
    if (!pathname.length || pathname.includes('/share/optimizer/')) {
        return false;
    }

    if (organizationSlug?.length > 0) {
        const matchedRoute = Object.keys(mainBreadcrumbPaths).find((item) => {
            return pathname.includes(`${organizationSlug}${item}`) && item !== '/home';
        });

        return !!matchedRoute;
    }

    const unauthorizedRoute = Object.values(unauthorizedRoutes).find((item) => {
        return pathname.includes(item);
    });

    return !unauthorizedRoute;
};
