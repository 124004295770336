export const mainBreadcrumbPaths = {
    '/research': 'Keyword Research',
    '/brief': 'Content Brief',
    '/optimizer': 'Content Optimizer',
    '/monitor': 'Content Monitor',
    '/account': 'My Account',
    '/usage': 'Usage',
    '/users': 'Users',
    '/organizations': 'Organizations',
    '/cancellations': 'Cancellations',
    '/home': 'Home',
};

export const billingBreadcrumbPaths = {
    '/invoice-history': 'Invoice History',
    '/plans': 'Plans',
    '/credits': 'Credits',
    '/invoice': 'Invoice',
};
