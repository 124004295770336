import React from 'react';

const TrashIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='stroke-icon'
        >
            <path
                d='M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18.8499 9.14014L18.1999 19.2101C18.0899 20.7801 17.9999 22.0001 15.2099 22.0001H8.7899C5.9999 22.0001 5.9099 20.7801 5.7999 19.2101L5.1499 9.14014'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M10.3301 16.5H13.6601'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M9.5 12.5H14.5'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default TrashIcon;
