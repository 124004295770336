import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';

import { Badge, Card, Typography } from 'antd';

import {
    selectDarkMode,
    selectIsSubscriptionExpired,
    selectUserOrganization,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getBillingPlanLabel } from '../../../../../../utils/helpers/getBillingPlanLabel';
import { getNumberWithCommaAsThousandSeparator } from '../../../../../../utils/helpers/getNumberWithCommaAsThousandSeparator';
import { getPlanByCurrencyAndInterval } from '../../../../../../utils/helpers/getPlanByCurrencyAndInterval';
import { getPlanFeaturesArray } from '../../../../../../utils/helpers/getPlanFeaturesArray';
import { getPlanOptimizerCredits } from '../../../../../../utils/helpers/getPlanOptimizerCredits';
import { getPricePerOptimizer } from '../../../../../../utils/helpers/getPricePerOptimizer';
import { getYearlyPriceForAllMonths } from '../../../../../../utils/helpers/getYearlyPriceForAllMonths';

import { billingPlanTypes } from '../../../../../../constants/billingPlanConsts';
import { colorPalette } from '../../../../../../resources/styles/colorPalette';
import { billingPlanIntervals } from '../../BillingConstants';

import SubscriptionHelper from '../../../../auth/helpers/subscription.helper';
import BillingHelper from '../../../../../../utils/helpers/billingHelper';

import { setPlanToSwitch } from '../../store/billingSettings.actions';
import { selectCurrentSubscription, selectPlanType } from '../../store/billingSettings.selectors';

import { useSubscriptionLimits } from '../../../../../../utils/hooks/useSubscriptionLimits';
import SwitchPlanButton from './SwitchPlanButton';

const { Title } = Typography;

const classes = {
    billingPlanCard: 'billing-plan-card w-100',
    cardTitle: 'card-title',
    planDescriptionWrapper: 'd-flex flex-column justify-content-between',
    planPriceWrapper: 'plan-price-wrapper',
    planInfoWrapper: 'd-flex justify-content-between',
    planButtonWrapper: 'd-flex flex-center',
    selectPlanButton: 'select-plan-button',
    planFeaturesList: 'plan-features-list d-flex align-items-center',
};

const BillingPlanCard = ({ planData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const darkMode = useSelector(selectDarkMode);
    const planType = useSelector(selectPlanType);

    const currentSubscription = useSelector(selectCurrentSubscription);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const organizationSlug = useOrganizationSlug();
    const organization = useSelector(selectUserOrganization);
    const { contentMonitorsUsed } = useSubscriptionLimits();

    const planPrice = getPlanByCurrencyAndInterval(planData, planType)?.unit_amount / 100 || 0;

    const active =
        currentSubscription?.plan?.id === getPlanByCurrencyAndInterval(planData, planType)?.id;

    const handleSwitchPlan = () => {
        dispatch(setPlanToSwitch(planData));
        navigate(`/${organizationSlug}/account/plans/checkout`);
    };

    const trial = SubscriptionHelper.isTrial(currentSubscription, organization);

    const getBtnText = () => {
        if (trial || isSubscriptionExpired) {
            return 'Start now';
        } else {
            if (active) {
                return 'Current Plan';
            } else {
                if (
                    (planData?.metadata?.content_monitors / 12 ===
                        currentSubscription?.plan?.metadata?.content_monitors &&
                        planData?.metadata?.content_monitors * 12 ===
                            currentSubscription?.plan?.metadata?.content_monitors) ||
                    currentSubscription?.plan?.name
                        .toLowerCase()
                        .includes(planData.name.toLowerCase())
                ) {
                    return 'Switch Plan';
                } else if (
                    planData?.metadata?.content_monitors >
                    currentSubscription?.plan?.metadata?.content_monitors
                ) {
                    return 'Upgrade';
                } else if (
                    planData?.metadata?.content_monitors <
                    currentSubscription?.plan?.metadata?.content_monitors
                ) {
                    return 'Downgrade';
                }

                return 'Switch Plan';
            }
        }
    };

    const getContentMonitorDetails = () => {
        if (planData?.metadata?.content_monitors < contentMonitorsUsed) {
            return {
                contentMonitorsUsed: contentMonitorsUsed,
                newAmountOfCredits: planData?.metadata?.content_monitors,
            };
        }

        return null;
    };

    return (
        <Card className={classes.billingPlanCard}>
            <div className={classes.cardTitle}>
                <Title level={5}>{getBillingPlanLabel(planData.name)}</Title>
                <div className={classes.planButtonWrapper}>
                    <SwitchPlanButton
                        active={active}
                        trial={trial}
                        expired={isSubscriptionExpired}
                        usersPlanLimit={planData?.metadata?.user_seats}
                        handleSwitchPlan={handleSwitchPlan}
                        btnTitle={getBtnText()}
                        contentMonitorDetails={getContentMonitorDetails()}
                    />
                </div>
            </div>
            <div className={classes.planDescriptionWrapper}>
                <div className={classes.planInfoWrapper}>
                    <Typography className={classes.planPriceWrapper}>
                        {planType === billingPlanTypes.yearly.value && (
                            <span className='text-unactive'>
                                $
                                {getNumberWithCommaAsThousandSeparator(
                                    getYearlyPriceForAllMonths(planPrice)
                                )}
                            </span>
                        )}
                        <span className='text-active'>
                            ${getNumberWithCommaAsThousandSeparator(planPrice)}
                        </span>
                        /{billingPlanIntervals[planType]}
                        <Badge
                            color={
                                darkMode
                                    ? colorPalette.textColor.dark
                                    : colorPalette.textColor.light
                            }
                        />
                        $
                        {getPricePerOptimizer(
                            planPrice,
                            getPlanOptimizerCredits(planData?.metadata, planType)
                        )?.toFixed(2)}
                        /Optimizer + Brief
                    </Typography>
                </div>
                <div className={classes.planFeaturesList}>
                    {getPlanFeaturesArray(planData?.metadata, planType)?.map((feature, index) => (
                        <React.Fragment key={index}>
                            <Title key={index} level={5}>
                                {`${BillingHelper.getFeatureValueIfNotBooleanType(feature)} ${
                                    feature?.shortTitle || feature?.title
                                }`}
                            </Title>
                            <Badge
                                color={
                                    darkMode
                                        ? colorPalette.textAccentColor.dark
                                        : colorPalette.textAccentColor.light
                                }
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default BillingPlanCard;
