import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { stripePublicKey } from '../../../../constants/stripePublicKey';
import SelectPaymentPlan from './SelectPaymentPlan';

const SelectPlanWrapper = () => {
    return (
        <Elements stripe={loadStripe(stripePublicKey)}>
            <SelectPaymentPlan />
        </Elements>
    );
};

export default SelectPlanWrapper;
