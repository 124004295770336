import { getOperationSystem } from '../../../../utils/helpers/operationSystem';

export const defaultExtractContentEditorContent = '<p></p>';

export const getIconColorByDarkMode = (darkMode = false) => {
    return darkMode ? '#9D9D9F' : '#1F1F1F';
};

export const headingOptionsValues = {
    paragraph: 'paragraph',
    heading_1: 'heading_1',
    heading_2: 'heading_2',
    heading_3: 'heading_3',
    heading_4: 'heading_4',
    heading_5: 'heading_5',
    heading_6: 'heading_6',
    blockquote: 'blockquote',
    codeBlock: 'codeBlock',
};

export const headingOptions = [
    {
        value: headingOptionsValues.paragraph,
        label: 'Paragraph',
        handleSelect: (editor) => editor.chain().focus().setParagraph().run(),
    },
    {
        value: headingOptionsValues.heading_1,
        label: 'Heading 1',
        level: 1,
        levelName: 'heading',
        handleSelect: (editor) =>
            editor.chain().focus().toggleHeading({ level: 1 }).run(),
    },
    {
        value: headingOptionsValues.heading_2,
        label: 'Heading 2',
        level: 2,
        levelName: 'heading',
        handleSelect: (editor) =>
            editor.chain().focus().toggleHeading({ level: 2 }).run(),
    },
    {
        value: headingOptionsValues.heading_3,
        label: 'Heading 3',
        level: 3,
        levelName: 'heading',
        handleSelect: (editor) =>
            editor.chain().focus().toggleHeading({ level: 3 }).run(),
    },
    {
        value: headingOptionsValues.heading_4,
        label: 'Heading 4',
        level: 4,
        levelName: 'heading',
        handleSelect: (editor) =>
            editor.chain().focus().toggleHeading({ level: 4 }).run(),
    },
    {
        value: headingOptionsValues.heading_5,
        label: 'Heading 5',
        level: 5,
        levelName: 'heading',
        handleSelect: (editor) =>
            editor.chain().focus().toggleHeading({ level: 5 }).run(),
    },
    {
        value: headingOptionsValues.heading_6,
        label: 'Heading 6',
        level: 6,
        levelName: 'heading',
        handleSelect: (editor) =>
            editor.chain().focus().toggleHeading({ level: 6 }).run(),
    },
    {
        value: headingOptionsValues.blockquote,
        label: 'Blockquote',
        handleSelect: (editor) =>
            editor.chain().focus().toggleBlockquote().run(),
    },
    {
        value: headingOptionsValues.codeBlock,
        label: 'Code block',
        handleSelect: (editor) =>
            editor.chain().focus().toggleCodeBlock().run(),
    },
];

export const WINDOWS_HOTKEYS = {
    Bold: 'Ctrl+B',
    Italic: 'Ctrl+I',
    Underline: 'Ctrl+U',
    Code: 'Ctrl+E',
    Undo: 'Ctrl+Z',
    Redo: 'Ctrl+Shift+Z',

    'Bulleted list': 'Ctrl+Shift+8',
    'Numbered list': 'Ctrl+Shift+7',
    'Insert link': 'Ctrl+K',
    'Insert image': 'Ctrl+P',
    'Toggle left align': 'Ctrl+Shift+L',
    'Toggle center align': 'Ctrl+Shift+E',
    'Toggle right align': 'Ctrl+Shift+R',
    'Toggle justify align': 'Ctrl+Shift+J',
};

export const IOS_HOTKEYS = {
    Bold: '⌘+B',
    Italic: '⌘+I',
    Underline: '⌘+U',
    Code: '⌘+E',
    Undo: '⌘+Z',
    Redo: '⌘+Shift+Z',

    'Bulleted list': '⌘+Shift+8',
    'Numbered list': '⌘+Shift+7',
    'Insert link': '⌘+K',
    'Insert image': '⌘+P',
    'Toggle left align': '⌘+Shift+L',
    'Toggle center align': '⌘+Shift+E',
    'Toggle right align': '⌘+Shift+R',
    'Toggle justify align': '⌘+Shift+J',
};

export const getButtonHotkeyLabel = (buttonName) => {
    if (!buttonName) {
        return null;
    }

    if (getOperationSystem() === 'Windows') {
        return WINDOWS_HOTKEYS[buttonName] || null;
    } else {
        return IOS_HOTKEYS[buttonName] || null;
    }
};
