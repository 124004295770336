import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'antd';

import { notificationType } from '../../../../../constants/notificationType';
import { globalAdminPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import MoreHorizontalIcon from '../../../../../resources/icons/MoreHorizontalIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import CustomDropdown from '../../../../common/dropdown/CustomDropdown';

import { updateOrganizationUser } from '../store/adminOrganizationsSettings.actions';

const classes = {
    actionsButton:
        'actions-button d-flex flex-center align-items-center btn-hover-light-primary',
    dropdownOverlay: 'dropdown-menu dropdown-with-arrow-menu-overlay',
};

const UserActionsDropdown = ({ user }) => {
    const { isOpen, handleOpen } = useOpen();

    const dispatch = useDispatch();

    const [isUserStatusChanging, setIsUserStatusChanging] = useState(false);

    const handleChangeUserStatus = (user) => {
        setIsUserStatusChanging(true);

        axiosAPI
            .patch(
                `${globalAdminPathes.changeUserRole}${user?.id}/change-role`,
                { active: !user?.active },
                { ...getAxiosHeaders() }
            )
            .then((data) => {
                if (data.status === statusCodes.success) {
                    dispatch(
                        updateOrganizationUser({
                            id: user.id,
                            active: !user?.active,
                        })
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    'Looks like something went wrong while loading users. Please try again later'
                );
            })
            .finally(() => {
                setIsUserStatusChanging(false);
            });
    };

    const userMenuItems = [
        {
            label: !user?.active ? 'Activate' : 'Disable',
            key: 'changeUserStatus',
            onClick: () => {
                if (!isUserStatusChanging) {
                    handleChangeUserStatus(user);
                }
            },
        },
    ];

    return (
        <CustomDropdown
            dropdownPlacement={'bottomRight'}
            withArrow
            dropdownOpenElement={
                <Button className={classes.actionsButton}>
                    <MoreHorizontalIcon color={colorPalette.colorPrimary} />
                </Button>
            }
            dropdownMenu={{
                items: userMenuItems,
                selectable: false,
                defaultSelectedKeys: [],
            }}
            dropdownTriggersArray={['click']}
            dropdownOverlayClassName={classes.dropdownOverlay}
            isDropdownOpen={isOpen}
            handleOpenChange={handleOpen}
        />
    );
};

export default UserActionsDropdown;
