export const optimizerSettingSelectOption = [
    {
        value: 10,
        label: '1-10',
    },
    {
        value: 20,
        label: '1-20',
    },
    {
        value: 30,
        label: '1-30 (default)',
    },
];

export const optimizerSettingSelectDefaultOption =
    optimizerSettingSelectOption[2];

export const optimizerSettingOptimalWordCountRangeOption = [
    {
        value: 5,
        label: '1-5',
    },
    {
        value: 10,
        label: '1-10 (default)',
    },
    {
        value: 20,
        label: '1-20',
    },
];

export const optimizerSettingOptimalWordCountRangeDefaultOption =
    optimizerSettingOptimalWordCountRangeOption[1];

export const optimizerSettingsAICommandMenuForSharedLinksOption = [
    {
        value: false,
        label: 'Off (default)'
    },
    {
        value: true,
        label: 'On'
    }
];

export const optimizerSettingsAICommandMenuForSharedLinksDefaultOption =
    optimizerSettingsAICommandMenuForSharedLinksOption[0];
