import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectIsSubscriptionExpired,
    selectUserData,
} from '../../userBrowserSettings/store/browserSettings.selectors';

import {
    errorNotificationMessage,
    notificationType,
} from '../../constants/notificationType';
import { paymentsPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';

import {
    selectCurrentSubscription,
    selectPaymentMethod,
} from '../../components/pages/account/billing/store/billingSettings.selectors';
import {
    setCurrentSubscription,
    setCurrentSubscriptionLoading,
} from '../../components/pages/account/billing/store/billingSettings.actions';

import { checkIsGlobalTechAdmin } from '../helpers/checkIsGlobalTechAdmin';
import { openNotification } from '../helpers/openNotification';
import { getAxiosHeaders } from '../helpers/getAxiosHeaders';

import { axiosAPI } from '../axiosAPI';

import { usePrevious } from './usePrevious';

export const useCurrentSubscription = () => {
    const dispatch = useDispatch();

    const currentSubscription = useSelector(selectCurrentSubscription);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const paymentMethod = useSelector(selectPaymentMethod);
    const userData = useSelector(selectUserData);

    const prevIsSubscriptionExpired = usePrevious(isSubscriptionExpired);
    const prevPaymentMethodId = usePrevious(paymentMethod?.id);

    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

    useEffect(() => {
        if (
            !checkIsGlobalTechAdmin(userData?.roles) &&
            ((userData &&
                (!currentSubscription ||
                    (currentSubscription &&
                        !prevIsSubscriptionExpired &&
                        isSubscriptionExpired))) ||
                (!currentSubscription?.paid &&
                    paymentMethod?.id &&
                    prevPaymentMethodId &&
                    paymentMethod?.id !== prevPaymentMethodId))
        ) {
            setIsSubscriptionLoading(true);
            dispatch(setCurrentSubscriptionLoading(true));

            axiosAPI
                .get(paymentsPathes.getSubscription, { ...getAxiosHeaders() })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setCurrentSubscription(result?.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsSubscriptionLoading(false);
                    dispatch(setCurrentSubscriptionLoading(false));
                });
        }

        // eslint-disable-next-line
    }, [currentSubscription, isSubscriptionExpired, paymentMethod?.id]);

    return { currentSubscription, isSubscriptionLoading };
};
