export const contentMonitorTabs = {
    rankabilityScore: {
        key: 'rankabilityScores',
        label: 'Rankability Scores',
    },
    projects: {
        key: 'projects',
        label: 'Projects',
    },
};
