import { contentMonitorContentActions } from './contentMonitorContent.actions';

const contentMonitorContentInitialState = {};

export const contentMonitorContentReducer = (
    state = contentMonitorContentInitialState,
    { type, payload }
) => {
    switch (type) {
        case contentMonitorContentActions.CLEAR_CONTENT_MONITOR_CONTENT: {
            return {
                ...contentMonitorContentInitialState,
            };
        }

        default: {
            return state;
        }
    }
};
