import React from 'react';

const AccountSettingsActiveIcon = () => {
    return (
        <svg
            width='23'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.7793 16.6871C10.8292 16.7397 10.8821 16.7886 10.9376 16.8336H2.19775C1.94977 16.8336 1.75 16.6329 1.75 16.3875C1.75 14.0465 3.64869 12.146 5.98876 12.146H8.76147C9.03294 12.146 9.29983 12.1744 9.56054 12.2262C9.63737 12.5031 9.78876 12.7567 10.0055 12.9559C9.56614 13.3496 9.387 13.9807 9.56269 14.5735L9.56266 14.5735L9.56423 14.5786C9.80332 15.355 10.2207 16.0777 10.7732 16.6806L10.7732 16.6807L10.7793 16.6871ZM17.4522 13.4488L17.3871 13.7962L17.6929 13.9735L18.1847 14.2588C18.0475 14.6118 17.856 14.9404 17.6206 15.2316L17.1319 14.9492L16.8266 14.7727L16.5579 15.0011C16.306 15.2153 16.0175 15.3824 15.7073 15.4915L15.3733 15.609V15.9631V16.529C15.0094 16.5869 14.6307 16.5881 14.2507 16.5287V15.9631V15.609L13.9167 15.4915C13.6065 15.3824 13.318 15.2153 13.0661 15.0011L12.798 14.7732L12.493 14.9486L12.0008 15.2316C11.7638 14.9375 11.5724 14.6095 11.4372 14.2573L11.9272 13.9718L12.2292 13.7959L12.1678 13.4519C12.1098 13.1272 12.1098 12.7902 12.1678 12.4655L12.2292 12.1215L11.9272 11.9456L11.4372 11.6601C11.5726 11.3069 11.7639 10.9777 11.9998 10.6858L12.491 10.9696L12.7963 11.146L13.065 10.9176C13.3169 10.7034 13.6054 10.5364 13.9156 10.4273L14.2496 10.3097V9.95561V9.38919C14.614 9.33167 14.9939 9.33082 15.3722 9.39046V9.95547V10.3096L15.7063 10.4271C16.0164 10.5362 16.3049 10.7033 16.5568 10.9175L16.8255 11.1459L17.1308 10.9695L17.6196 10.687C17.855 10.9783 18.0466 11.3071 18.1838 11.6603L17.692 11.9468L17.3872 12.1243L17.4522 12.471C17.5126 12.7929 17.5126 13.1269 17.4522 13.4488ZM10.3752 6.83344C10.3752 8.49029 9.03219 9.83351 7.37511 9.83351C5.71804 9.83351 4.37505 8.49029 4.37505 6.83344C4.37505 5.17659 5.71804 3.83337 7.37511 3.83337C9.03219 3.83337 10.3752 5.17659 10.3752 6.83344ZM12.9866 12.9586C12.9866 13.9662 13.8052 14.7848 14.8128 14.7848C15.8221 14.7848 16.6389 13.9658 16.6389 12.9586C16.6389 11.951 15.8204 11.1324 14.8128 11.1324C13.8052 11.1324 12.9866 11.951 12.9866 12.9586Z'
                fill='#327EEF'
                stroke='#327EEF'
            />
        </svg>
    );
};

export default AccountSettingsActiveIcon;
