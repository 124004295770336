import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ConfigProvider, Typography } from 'antd';

import { darkTheme } from '../../resources/styles/darkTheme';
import { lightTheme } from '../../resources/styles/lightTheme';

import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';

const withAntdConfigProvider = (ComposedComponent) => {
    return memo((props) => {
        const darkMode = useSelector(selectDarkMode);

        useEffect(() => {
            document.documentElement.style.setProperty(
                '--notification-background-color',
                darkMode ? '#0b0a0f' : '#ffffff'
            );

            document.documentElement.style.setProperty(
                '--notification-text-color',
                darkMode ? '#ffffff' : '#000000e0'
            );

            document.documentElement.style.setProperty(
                '--notification-icon-color',
                darkMode ? '#000000e0' : '#ffffff'
            );

            document.documentElement.style.setProperty(
                '--notification-border',
                darkMode ? '1px solid #2a2d2f' : 'none'
            );
        }, [darkMode]);

        const selectedTheme = darkMode ? darkTheme : lightTheme;

        return (
            <ConfigProvider
                theme={selectedTheme}
                renderEmpty={() => (
                    <Typography.Text className='empty-list-text'>
                        No data
                    </Typography.Text>
                )}
            >
                <ComposedComponent {...props} />
            </ConfigProvider>
        );
    });
};

export default withAntdConfigProvider;
