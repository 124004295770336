import React from 'react';

const KanbanIcon = ({ color = '#728192' }) => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.8333 4.96935V2.32102C12.8333 1.49852 12.46 1.16602 11.5325 1.16602H9.17583C8.24833 1.16602 7.875 1.49852 7.875 2.32102V4.96352C7.875 5.79185 8.24833 6.11852 9.17583 6.11852H11.5325C12.46 6.12435 12.8333 5.79185 12.8333 4.96935Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.8333 11.5325V9.17583C12.8333 8.24833 12.46 7.875 11.5325 7.875H9.17583C8.24833 7.875 7.875 8.24833 7.875 9.17583V11.5325C7.875 12.46 8.24833 12.8333 9.17583 12.8333H11.5325C12.46 12.8333 12.8333 12.46 12.8333 11.5325Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.12508 4.96935V2.32102C6.12508 1.49852 5.75175 1.16602 4.82425 1.16602H2.46758C1.54008 1.16602 1.16675 1.49852 1.16675 2.32102V4.96352C1.16675 5.79185 1.54008 6.11852 2.46758 6.11852H4.82425C5.75175 6.12435 6.12508 5.79185 6.12508 4.96935Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.12508 11.5325V9.17583C6.12508 8.24833 5.75175 7.875 4.82425 7.875H2.46758C1.54008 7.875 1.16675 8.24833 1.16675 9.17583V11.5325C1.16675 12.46 1.54008 12.8333 2.46758 12.8333H4.82425C5.75175 12.8333 6.12508 12.46 6.12508 11.5325Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default KanbanIcon;
