import React from 'react';

import DarkModeSwitch from '../sidebar/bottomMenu/DarkModeSwitch';
import CustomDivider from '../Divider';

import ViewOnlyMode from './ViewOnlyMode';

const classes = {
    siderSettingsWrapper: 'sider-settings',
    siderSettingsItem: 'sider-settings-item view-only-item',
    siderAccSettingsItem: 'sider-acc-settings-item d-flex align-items-center',
};

const ViewOnlyBottomSettings = ({ collapsed }) => {
    return (
        <div>
            <div className={classes.siderSettingsWrapper}>
                <div className={classes.siderSettingsItem}>
                    <DarkModeSwitch collapsed={collapsed} />
                </div>

                <CustomDivider marginBottom='16px' />

                <ViewOnlyMode collapsed={collapsed} />
            </div>
        </div>
    );
};

export default ViewOnlyBottomSettings;
