import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import './CustomTypography.scss';

const CustomTypography = ({ text }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Typography className={`custom-typography ${darkMode ? 'dark-mode' : ''}`}>
            {text || 'Provide your text as props with the `text` name'}
        </Typography>
    );
};

export default CustomTypography;
