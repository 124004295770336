import moment from 'moment';

import { planRecurringPeriod } from '../../../../constants/billingPlanConsts';

const SubscriptionHelper = {
    // Get diff between monthly price multiplied by 12 and annual price
    getSubscriptionPlanPricesDifference: (subscription) => {
        return (
            (subscription.prices.find(
                (price) =>
                    price.recurring.interval === planRecurringPeriod.month
            ).unit_amount *
                12 -
                subscription.prices.find(
                    (price) =>
                        price.recurring.interval === planRecurringPeriod.year
                ).unit_amount) /
            100
        );
    },

    // Removes the last word ('Subscription') from the subscription name; (0, 2) because we need two first words from the name
    getSubscriptionShortName: (subscriptionName) => {
        return subscriptionName.split(' ').slice(0, 2).join(' ');
    },

    getSubscriptionLabel: (subscription, price) => {
        return `${SubscriptionHelper.getSubscriptionShortName(
            subscription.name
        )} ($${price.unit_amount / 100}/${price.recurring.interval})`;
    },

    getSelectedSubscription: (id, products = []) => {
        const subscription = products.find((x) =>
            x.prices.find((y) => y.id === id)
        );

        return {
            subscription: subscription,
            price: subscription?.prices.find((x) => x.id === id),
        };
    },

    getSelectedRecurringPeriodName: (price) => {
        return price?.recurring.interval === planRecurringPeriod.month
            ? 'monthly'
            : 'annually';
    },

    getFormattedPrice: (price) => {
        return (price?.unit_amount / 100).toFixed(2);
    },

    isCanceled: (subscription) => {
        return subscription?.cancel_at_period_end || subscription?.canceled_at;
    },

    isTrial: (subscription, organization) => {
        return (
            subscription?.trial_end &&
            moment.utc(subscription?.trial_end) > moment.utc() &&
            !organization.temp_trial
        );
    },
};

export default SubscriptionHelper;
