import { useDispatch, batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    errorNotificationMessage,
    notificationType,
} from '../../constants/notificationType';

import { axiosAPI } from '../axiosAPI';

import { getAxiosHeaders } from '../helpers/getAxiosHeaders';
import { openNotification } from '../helpers/openNotification';
import { contentOptimizerPathes } from '../../constants/queryPathes';
import { updateEditorOptimalsBasicData } from '../../components/common/tiptapEditor/store/tiptapEditor.actions';
import {
    setCompetitorsPendingRequest,
    setCompetitorsToUpdate,
    setContentOptimizerKeywords,
    updateCompetitor,
} from '../../components/pages/contentOptimizer/contentPage/store/contentOptimizerContent.actions';

import useGetListOfContentBriefs from './useGetListOfContentBriefs';

const useIncludeInOptimalRangeChange = () => {
    const dispatch = useDispatch();
    const { contentOptimizerId } = useParams();
    const getListOfContentBriefs = useGetListOfContentBriefs();
    const fireIncludeInOptimalRangeChange = (data) => {
        dispatch(
            setCompetitorsPendingRequest([...data?.map((x) => x?.onpageTaskId)])
        );

        return axiosAPI
            .patch(
                `${contentOptimizerPathes.includeInOptimalRange}/${contentOptimizerId}`,
                data,
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                batch(() => {
                    if ('keywords' in result?.data) {
                        dispatch(
                            setContentOptimizerKeywords(
                                JSON.parse(result.data.keywords || '[]')
                            )
                        );
                    }

                    dispatch(
                        updateCompetitor(
                            data.map((record) => ({
                                onpageTaskId: record.onpageTaskId,
                                useInOptimalRange: record.useInOptimalRange,
                            }))
                        )
                    );

                    if ('optimals' in result?.data) {
                        dispatch(
                            updateEditorOptimalsBasicData(result.data.optimals)
                        );
                    }

                    dispatch(setCompetitorsToUpdate([]));
                    dispatch(setCompetitorsPendingRequest([]));
                });

                getListOfContentBriefs(contentOptimizerId);
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );

                batch(() => {
                    dispatch(setCompetitorsToUpdate([]));
                    dispatch(setCompetitorsPendingRequest([]));
                });
            });
    };

    return fireIncludeInOptimalRangeChange;
};

export default useIncludeInOptimalRangeChange;
