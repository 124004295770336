export const browserSettingsActions = {
    CLEAR_BROWSER_SETTINGS: 'BROWSER_SETTINGS/CLEAR_BROWSER_SETTINGS',

    UPDATE_DARK_MODE: 'BROWSER_SETTINGS/UPDATE_DARK_MODE',
    UPDATE_REMEMBER_USER_MODE: 'BROWSER_SETTINGS/UPDATE_REMEMBER_USER_MODE',
    UPDATE_LIST_VIEW_MODE: 'BROWSER_SETTINGS/UPDATE_LIST_VIEW_MODE',

    SET_IS_SESSION_CORRECT: 'BROWSER_SETTINGS/SET_IS_SESSION_CORRECT',
    SET_USER_DATA: 'BROWSER_SETTINGS/SET_USER_DATA',

    SET_ERROR_STATUS: 'BROWSER_SETTINGS/SET_ERROR_STATUS',
    SET_IS_SUBSCRIPTION_EXPIRED: 'BROWSER_SETTINGS/SET_IS_SUBSCRIPTION_EXPIRED',
    SET_IS_GRACE_PERIOD: 'BROWSER_SETTINGS/SET_IS_GRACE_PERIOD',

    SET_RANKABILITY_LOADING: 'BROWSER_SETTINGS/SET_RANKABILITY_LOADING',
};

export const setRankabilityLoading = (payload) => ({
    type: browserSettingsActions.SET_RANKABILITY_LOADING,
    payload,
});

export const setUserData = (payload) => {
    return {
        type: browserSettingsActions.SET_USER_DATA,
        payload,
    };
};

export const setIsSessionCorrect = (payload) => {
    return {
        type: browserSettingsActions.SET_IS_SESSION_CORRECT,
        payload,
    };
};

export const updateDarkMode = (payload) => {
    return {
        type: browserSettingsActions.UPDATE_DARK_MODE,
        payload,
    };
};

export const updateRememberUserMode = (payload) => {
    return {
        type: browserSettingsActions.UPDATE_REMEMBER_USER_MODE,
        payload,
    };
};

export const updateListViewMode = (payload) => {
    return {
        type: browserSettingsActions.UPDATE_LIST_VIEW_MODE,
        payload,
    };
};

export const setErrorStatus = (payload) => {
    return {
        type: browserSettingsActions.SET_ERROR_STATUS,
        payload,
    };
};

export const setIsSubscriptionExpired = (payload) => {
    return {
        type: browserSettingsActions.SET_IS_SUBSCRIPTION_EXPIRED,
        payload,
    };
};

export const setIsGracePeriod = (payload) => {
    return {
        type: browserSettingsActions.SET_IS_GRACE_PERIOD,
        payload,
    };
};

export const clearBrowserSettings = () => {
    return {
        type: browserSettingsActions.CLEAR_BROWSER_SETTINGS,
    };
};
