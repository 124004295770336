import axios from 'axios';

/**
 * Modified Axios instance for API calls.
 * 
 * Supports cookies from server.
 * 
 * Fetch `REACT_APP_BASE_URL` from the environment variable.
 * If one is not available, it means that frontend is compiled with `npm run build`
 * and is served from flask on the same host
 * therefore, we can use relative path, and `REACT_APP_BASE_URL` should be empty.
*/
export const axiosAPI = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL ?? ""}/api/v1`,
    withCredentials: true,
});
