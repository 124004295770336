export const defaultLanguage = 'en';

export const featuredLanguages = ['en', 'de', 'fr', 'es', 'it'];

export const languages = {
    // ar: 'Arabic ',
    // bg: 'Bulgarian ',
    // bn: 'Bengali ',
    // ca: 'Catalan ',
    // cs: 'Czech ',
    // da: 'Danish ',
    de: 'German ',
    // el: 'Greek ',
    en: 'English ',
    es: 'Spanish ',
    // et: 'Estonian ',
    // fa: 'Farsi ',
    // fi: 'Finnish ',
    fr: 'French ',
    // hi: 'Hindi ',
    // hr: 'Croatian ',
    // hu: 'Hungarian ',
    // id: 'Indonesian ',
    // is: 'Icelandic ',
    it: 'Italian ',
    // iw: 'Hebrew (old) ',
    // ja: 'Japanese ',
    // ko: 'Korean ',
    // lt: 'Lithuanian ',
    // lv: 'Latvian ',
    // ms: 'Malay ',
    // nl: 'Dutch ',
    // no: 'Norwegian ',
    // pl: 'Polish ',
    // pt: 'Portuguese ',
    // ro: 'Romanian ',
    // ru: 'Russian ',
    // sk: 'Slovak ',
    // sl: 'Slovenian ',
    // sr: 'Serbian ',
    // sv: 'Swedish ',
    // ta: 'Tamil ',
    // te: 'Telugu ',
    // th: 'Thai ',
    // tl: 'Tagalog ',
    // tr: 'Turkish ',
    // uk: 'Ukrainian ',
    // ur: 'Urdu ',
    // vi: 'Vietnamese ',
    // zh_CN: 'Chinese (Simplified) ',
    // zh_TW: 'Chinese (Traditional) ',
};

export const getLanguagesWithDefaultHighlighted = () => {
    let data = { ...languages };
    data[defaultLanguage] = `${data[defaultLanguage]} (default)`;

    return data;
};
