import React from 'react';

const SiderChevronUpIcon = ({
    color = '#9199AD',
    size = '16',
    className,
    strokeWidth = '1.2',
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d='M12 10L8 6L4 10'
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap='square'
            />
        </svg>
    );
};

export default SiderChevronUpIcon;
