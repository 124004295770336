import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { executeScroll } from '../../../../../../utils/helpers/executeScroll';

import ViewOnlyCard from '../../../../../common/viewOnly/ViewOnlyCard';

import { viewOnlyNavMenuOptions } from '../../constants';

import { selectViewOnlyActiveCard } from '../../../../contentOptimizer/viewOnly/store/viewOnlySettings.selectors';

import SeoCardContent from './cardsContent/SeoCardContent';

const classes = {
    cardWrapper: 'card-wrapper',
};

const SeoCard = () => {
    const ref = useRef();

    const activeCard = useSelector(selectViewOnlyActiveCard);

    useEffect(() => {
        if (activeCard === viewOnlyNavMenuOptions?.seo) {
            executeScroll(ref);
        }
    }, [activeCard]);

    return (
        <div ref={ref} className={classes.cardWrapper}>
            <ViewOnlyCard
                title='SEO'
                active={activeCard === viewOnlyNavMenuOptions?.seo}
                content={<SeoCardContent />}
            />
        </div>
    );
};

export default SeoCard;
