import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { OPTIONS_MENU_CLASS } from '../components/AiSpellChecker/AiResponseContent';
import { WORD_COUNT_LINK_CLASS } from '../toolbar/buttons/AISection';

import { selectAIStateAccepted } from '../store/tiptapEditor.selectors';

const useEditorEventHandlers = (editorRef, editorRootRef) => {
    const aiAccepted = useSelector(selectAIStateAccepted);

    // Handles clicks outside of the editor to reset selection
    const handleClickOutsideEditor = useCallback(
        (event) => {
            const isClickOutsideEditor =
                editorRootRef.current &&
                !editorRootRef.current.contains(event.target) &&
                !event.target.closest(`.${OPTIONS_MENU_CLASS}`) &&
                !event.target.closest(`.${WORD_COUNT_LINK_CLASS}`);

            if (
                isClickOutsideEditor &&
                editorRef.current &&
                aiAccepted &&
                !editorRef.current.state.selection.empty
            ) {
                editorRef.current.commands.selectTextblockEnd();
                editorRef.current.commands.blur();
            }
        },
        [editorRef, editorRootRef, aiAccepted]
    );

    // Handles drag end events within the editor to adjust selection
    const handleDragEnd = useCallback(
        (event) => {
            if (editorRootRef.current && editorRootRef.current.contains(event.target)) {
                if (editorRef.current) {
                    const { to } = editorRef.current.state.selection;
                    editorRef.current.commands.setTextSelection(to > 0 ? to - 1 : 0);
                }
            }
        },
        [editorRef, editorRootRef]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideEditor);
        document.addEventListener('dragend', handleDragEnd);

        return () => {
            document.removeEventListener('click', handleClickOutsideEditor);
            document.removeEventListener('dragend', handleDragEnd);
        };
    }, [handleClickOutsideEditor, handleDragEnd]);
};

export default useEditorEventHandlers;
