/**
 * Helper functions for filtering data by matching input strings with option properties.
 * These functions are designed to be flexible and can be used in various filtering contexts,
 * including filtering options for Select components, lists, or any dataset where string matching is required.
 */

/**
 * Normalizes a string by converting it to lowercase, trimming whitespace, and replacing
 * multiple spaces with a single space. This ensures consistent string comparison.
 *
 * @param {string} str - The input string to normalize.
 * @returns {string} - The normalized string, ready for comparison.
 *
 * @example
 * normalizeString('  HeLLo  WoRLD  ') // returns 'hello world'
 */
export const normalizeString = (str) => str.toLowerCase().replace(/\s+/g, ' ').trim();

/**
 * Checks if all words in the input string are present within the target option string,
 * matching based on the beginning of each word. Useful for keyword matching when filtering
 * options or any list of items by text.
 *
 * @param {string} input - The input string containing search keywords.
 * @param {string} optionName - The target string (e.g., an option's name or label) to match against.
 * @returns {boolean} - Returns true if all words in the input are found in the target string, false otherwise.
 *
 * @example
 * containsKeywords('hello world', 'hello beautiful world') // returns true
 * containsKeywords('foo bar', 'hello world') // returns false
 */
export const containsKeywords = (input, optionName) => {
    const inputWords = normalizeString(input).split(' ');
    const optionWords = normalizeString(optionName).split(' ');

    return inputWords.every((word) =>
        optionWords.some((optionWord) => optionWord.startsWith(word))
    );
};

/**
 * Filters a list of options or any objects based on whether their 'name' property
 * (or another specified key) contains all keywords from the input string.
 *
 * @param {string} input - The input string used to filter the options.
 * @param {Array<Object>} options - The list of options or objects to filter. Each object must have a 'name' property (or another specified key).
 * @param {string} [key='name'] - The key within each object to use for comparison. Defaults to 'name'.
 * @returns {Array<Object>} - A filtered list of options where the specified key matches the input string.
 *
 * @example
 * const options = [{ name: 'apple' }, { name: 'banana' }, { name: 'grape' }];
 * filterOptions('app', options) // returns [{ name: 'apple' }]
 *
 * // Custom key example:
 * const data = [{ title: 'apple' }, { title: 'banana' }];
 * filterOptions('app', data, 'title') // returns [{ title: 'apple' }]
 */
export const filterOptions = (input, options, key = 'name') => {
    const normalizedInput = normalizeString(input);

    return options.filter((option) => containsKeywords(normalizedInput, option[key]));
};
