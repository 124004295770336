import React from 'react';

import { Button } from 'antd';

const DownloadInvoiceButton = ({ pdfLink, invoiceNumber }) => {
    const downloadInvoice = () => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = pdfLink;
        link.setAttribute('download', `Invoice-${invoiceNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            type='primary'
            ghost
            onClick={downloadInvoice}
        >
            Download invoice
        </Button>
    );
};

export default DownloadInvoiceButton;
