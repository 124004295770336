import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../../../constants/statusCodes';

import { axiosAPI } from '../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../../../../utils/helpers/openNotification';

import {
    addOutlineVersion,
    setCurrentBriefId,
    setOutlineVersionLoading,
    updateOutlineVersions,
} from '../../store/contentBriefsOutline.actions';
import {
    selectCurrentOutline,
    selectCurrentOutlineVersion,
} from '../../store/contentBriefsOutline.selectors';

import OutlineContainer from './content/outline/OutlineContainer';

import './Optimizer.scss';
import OutlineVersionsBar from './content/outline/OutlineVersionsBar/OutlineVersionsBar';

const classes = {
    optimizerContainer: 'optimizer-container',
};

const OutlineOptimizerContainer = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const currentOutline = useSelector(selectCurrentOutline);
    const currentOutlineVersion = useSelector(selectCurrentOutlineVersion);

    const createNewOutlineVersion = () => {
        if (contentOptimizerId) {
            dispatch(setOutlineVersionLoading(true));

            axiosAPI
                .post(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/outlines`,
                    {
                        outline: JSON.stringify(currentOutline || []),
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((res) => {
                    dispatch(addOutlineVersion(res.data));
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    dispatch(setOutlineVersionLoading(false));
                });
        }
    };

    const fetchOutlineVersions = () => {
        if (contentOptimizerId) {
            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/outlines`,
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        if (result?.data?.length !== 0) {
                            batch(() => {
                                dispatch(updateOutlineVersions(result?.data));
                                dispatch(
                                    setCurrentBriefId(
                                        Number.parseInt(contentOptimizerId)
                                    )
                                );
                            });
                        } else {
                            createNewOutlineVersion();
                        }
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    dispatch(setOutlineVersionLoading(false));
                });
        }
    };

    useEffect(() => {
        fetchOutlineVersions();

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    useEffect(() => {
        if (currentOutlineVersion?.ai_job_id) {
            const interval = setInterval(() => {
                fetchOutlineVersions();
            }, 1000 * 5);

            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOutlineVersion?.ai_job_id]);

    return (
        <div className={`${classes.optimizerContainer}`}>
            <OutlineVersionsBar
                fetchOutlineVersions={fetchOutlineVersions}
                createNewOutlineVersion={createNewOutlineVersion}
            />
            <OutlineContainer
                createNewOutlineVersion={createNewOutlineVersion}
            />
        </div>
    );
};

export default OutlineOptimizerContainer;
