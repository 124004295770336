export const keywordResearchTabs = {
    keyword: {
        key: 'keyword',
        label: 'Keyword',
    },
    url: {
        key: 'url',
        label: 'URL',
    },
};
