import {
    billingPlanIntervals,
    priceCurrency,
} from '../../components/pages/account/billing/BillingConstants';

export const getPlanByCurrencyAndInterval = (planData, planType) => {
    return planData?.prices?.find(
        (price) =>
            price?.currency === priceCurrency.usd &&
            price?.recurring?.interval === billingPlanIntervals[planType]
    );
};
