import React, { useEffect, useState } from 'react';

import { Button, Input } from 'antd';

import { keywordResearchesAdvancedFilters } from '../../../../constants/keywordResearchesAdvancedFilters';

import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import CloseIcon from '../../../../resources/icons/CloseIcon';

import CustomDropdown from '../CustomDropdown';

import FilterPopoverButon from './FilterPopoverButton';
import TimeFrameSelect from './TimeframeSelect';
import TrendDirectionSelect from './TrendDirectionSelect';
import { monthlySearchesTrendOptions } from './trendFilterOptions';

import './Dropdowns.scss';

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay trend-popover-overlay',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
};

const TrendFilterDropdown = ({ value, handleTrendFilterChange, isActive }) => {
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const [filterValue, setFilterValue] = useState(value);

    useEffect(() => {
        if (value.every((item) => item?.value === '')) {
            setFilterValue((prevValue) =>
                prevValue.map((item) => ({ ...item, value: '' }))
            );
        } else {
            setFilterValue(value);
        }
    }, [value]);

    const handleChange = (rule, value) => {
        const newFilterValue = [...filterValue];
        const index = newFilterValue.findIndex((item) => item.rule === rule);

        newFilterValue[index] = {
            ...newFilterValue[index],
            value,
        };

        setFilterValue(newFilterValue);
    };

    const handleApply = () => {
        handleTrendFilterChange(filterValue);
        handleOpen();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleClear = () => {
        const filterNullValue = filterValue.map((item) => ({
            ...item,
            value: '',
        }));

        handleTrendFilterChange(filterNullValue);
        setFilterValue(filterNullValue);
    };

    const getFilterLabel = () => {
        let timeFrame = value?.find((item) => item?.rule === 'months');
        let trendDirection = value?.find((item) => item?.rule === 'trend');

        if (timeFrame?.value) {
            timeFrame = `Last ${timeFrame.value} months`;
        } else {
            timeFrame = '';
        }

        if (trendDirection?.value && trendDirection?.value?.length > 0) {
            const trendDirectionItem = monthlySearchesTrendOptions?.find(
                (option) => option.value === trendDirection?.value
            );
            trendDirection = trendDirectionItem?.label;
        } else {
            trendDirection = '';
        }

        if (timeFrame && trendDirection) {
            return `${timeFrame}, ${trendDirection}`;
        } else if (timeFrame) {
            return timeFrame;
        } else if (trendDirection) {
            return trendDirection;
        } else {
            return 'Trend';
        }
    };

    const content = (
        <div className={classes.filterWrapper} onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <Input.Group compact>
                    <TimeFrameSelect
                        selectValue={
                            filterValue?.find(
                                (item) =>
                                    item?.rule ===
                                    keywordResearchesAdvancedFilters
                                        .monthlySearches.rules.months
                            )?.value
                        }
                        handleChange={handleChange}
                    />
                    <TrendDirectionSelect
                        selectValue={
                            filterValue?.find(
                                (item) =>
                                    item?.rule ===
                                    keywordResearchesAdvancedFilters
                                        .monthlySearches.rules.trend
                            )?.value
                        }
                        handleChange={handleChange}
                    />
                </Input.Group>
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div className={`${classes.popoverBtn} ${isActive ? 'active' : ''}`}>
            <CustomDropdown
                withExtraContent
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={getFilterLabel()}
                        showArrow={value?.every((item) => item?.value === '')}
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                dropdownTriggersArray={['click']}
                dropdownContent={content}
                dropdownOverlayClassName={classes.popoverOverlay}
                dropdownPlacement='bottom'
                isDropdownOpen={isOpen}
                handleOpenChange={handleOpen}
            />
            {!value.every((item) => item?.value === '') && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default TrendFilterDropdown;
