import React from 'react';

const CopySectionIcon = ({ color = '#1F1F1F', size = '14' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.91663 7.81732V9.56732C9.91663 11.9007 8.98329 12.834 6.64996 12.834H4.43329C2.09996 12.834 1.16663 11.9007 1.16663 9.56732V7.35065C1.16663 5.01732 2.09996 4.08398 4.43329 4.08398H6.18329'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.91668 7.81732H8.05002C6.65002 7.81732 6.18335 7.35065 6.18335 5.95065V4.08398L9.91668 7.81732Z'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.76672 1.16602H9.30687'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M4.08337 2.91602C4.08337 1.94768 4.86504 1.16602 5.83337 1.16602H7.36171'
                stroke={color}
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M12.8334 4.95898V8.27803C12.8334 9.1822 12.0984 9.9172 11.1942 9.9172'
                stroke={color}
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M12.8334 4.66602H11.0834C9.77087 4.66602 9.33337 4.22852 9.33337 2.91602V1.16602L12.8334 4.66602Z'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CopySectionIcon;
