import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Table } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../constants/statusCodes';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../utils/axiosAPI';
import { openNotification } from '../../../../../../../utils/helpers/openNotification';
import {
    checkAreUrlsStrictlyEqual,
    normalizeUrl,
} from '../../../../../../../utils/helpers/urlHelpers';
import { useCompetitorsTableColumns } from '../../../../../../../utils/hooks/useCompetitorsTableColumns';

import {
    addItemToContentBriefCompetitors,
    setCompetitorScanUrlLoading,
    updateCompetitor,
} from '../../../store/contentOptimizerContent.actions';
import {
    selectCompetitorsSearch,
    selectCompetitorsSortDescending,
    selectCompetitorsSortKey,
    selectCurrentCompetitorsList,
} from '../../../store/contentOptimizerContent.selectors';

import { competitorsSortDictionary, competitorsSortKeys } from './competitorsContentConstants';

const classes = {
    competitorsTable: 'competitors-table',
};

const CompetitorsTable = () => {
    const dispatch = useDispatch();
    const { contentOptimizerId } = useParams();

    const darkMode = useSelector(selectDarkMode);
    const competitorsList = useSelector(selectCurrentCompetitorsList);
    const competitorsSortKey = useSelector(selectCompetitorsSortKey);
    const competitorsSortDescending = useSelector(selectCompetitorsSortDescending);
    const competitorsSearch = useSelector(selectCompetitorsSearch);

    const competitorsTableColumns = useCompetitorsTableColumns();

    const [mappedCompetitorsList, setMappedCompetitorsList] = useState([]);
    const [searchElements, setSearchElements] = useState(null);
    const [redirectedUrls, setRedirectedUrls] = useState({});

    const fetchScanUrl = () => {
        dispatch(setCompetitorScanUrlLoading(true));
        const encodedCompetitorsSearch = encodeURIComponent(competitorsSearch);

        axiosAPI
            .get(
                `${contentOptimizerPathes.scanUrl}?url=${encodedCompetitorsSearch}&optimizer_id=${contentOptimizerId}`
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    if (Array.isArray(result?.data)) {
                        dispatch(
                            updateCompetitor(
                                result?.data?.map((competitor) => ({
                                    onpageTaskId: competitor?.onpage_task_id,
                                    url: competitor?.url,
                                    rankabilityScore: Math.ceil(competitor?.score) || null,
                                    wordCount: competitor?.word_count || null,
                                    domainRank: Math.ceil(competitor?.domain_rank) || null,
                                    highlighted: competitor?.highlighted,
                                }))
                            )
                        );
                    } else {
                        dispatch(addItemToContentBriefCompetitors(result?.data));
                    }

                    const resultUrl = result?.data?.url || result?.data[0]?.url;
                    if (
                        !checkAreUrlsStrictlyEqual(resultUrl, competitorsSearch) &&
                        !redirectedUrls[normalizeUrl(competitorsSearch)]
                    ) {
                        setRedirectedUrls((prevRedirected) => ({
                            ...prevRedirected,
                            [normalizeUrl(competitorsSearch)]: resultUrl,
                        }));
                    }
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                dispatch(setCompetitorScanUrlLoading(false));
            });
    };
    useEffect(() => {
        if (competitorsSearch) {
            fetchScanUrl();
        }

        // eslint-disable-next-line
    }, [competitorsSearch]);

    useEffect(() => {
        if (competitorsList?.length > 0) {
            let filteredList = competitorsList.filter(
                (item) => item?.highlighted
            );

            setSearchElements(filteredList.reverse());
        }

        // eslint-disable-next-line
    }, [competitorsList]);

    useEffect(() => {
        const hasValidSortKey = (item) =>
            Boolean(item?.[competitorsSortDictionary[competitorsSortKey]]);

        const sortByTitle = (a, b) => {
            const aValue =
                a?.[
                    competitorsSortDictionary[competitorsSortKey]
                ]?.toLowerCase() || '';
            const bValue =
                b?.[
                    competitorsSortDictionary[competitorsSortKey]
                ]?.toLowerCase() || '';

            return aValue.localeCompare(bValue);
        };

        const sortByValue = (a, b) => {
            const aValue =
                a?.[competitorsSortDictionary[competitorsSortKey]] || 0;
            const bValue =
                b?.[competitorsSortDictionary[competitorsSortKey]] || 0;
            return aValue - bValue;
        };

        const getSortFunction = () => {
            if (competitorsSortKey === competitorsSortKeys.title) {
                return sortByTitle;
            }

            return sortByValue;
        };

        const isNotInSearchElements = (item) =>
            !searchElements.some((elem) => elem?.id === item?.id);

        let sortedList = (competitorsList || [])
            .filter(hasValidSortKey)
            .sort(getSortFunction());

        if (!competitorsSortDescending) {
            sortedList.reverse();
        }
        const itemsWithoutSortKey = (competitorsList || []).filter(
            (item) => !hasValidSortKey(item)
        );
        sortedList.push(...itemsWithoutSortKey);

        if (searchElements?.length > 0) {
            sortedList = [
                ...searchElements,
                ...sortedList.filter(isNotInSearchElements),
            ];
        }

        setMappedCompetitorsList(
            sortedList?.map((item) => ({ ...item, key: item?.id }))
        );
    }, [
        competitorsList,
        competitorsSortKey,
        competitorsSortDescending,
        searchElements,
    ]);

    const tableBodyHeightForScroll = window.innerHeight - 306;

    return (
        <Table
            dataSource={mappedCompetitorsList}
            columns={competitorsTableColumns}
            loading={false}
            bordered
            showSorterTooltip={false}
            pagination={false}
            rowClassName={(_row, index) =>
                index < searchElements?.length
                    ? darkMode
                        ? 'dark-highlighted-row'
                        : 'highlighted-row'
                    : ''
            }
            className={`${classes.competitorsTable}
                        ${darkMode ? 'dark-mode' : ''}`}
            scroll={{
                x: true,
                y: tableBodyHeightForScroll,
            }}
            sticky={{
                offsetHeader: 0,
            }}
        />
    );
};

export default CompetitorsTable;
