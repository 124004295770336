import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { BubbleMenu, useCurrentEditor } from '@tiptap/react';
import { followCursor, roundArrow } from 'tippy.js';

import EditIcon from '../../../../../../resources/icons/EditIcon';
import RemoveIcon from '../../../../../../resources/icons/outline/RemoveIcon';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getThemeColorClass } from '../../../../../../utils/helpers/themeHelpers';
import { getLinkHref } from '../../../../../../utils/helpers/urlHelpers';
import { useIconColor } from '../../../../../../utils/hooks/useIconColor';
import { nodeTypes } from '../../../constants/nodeTypes';

import EditableLinkInput from './EditableLinkInput';

import 'tippy.js/dist/svg-arrow.css';

const linkAttribute = 'link';

const classes = {
    editLinkBubbleMenu: 'edit-link-bubble-menu',
    editLinkPopup: 'edit-link-popup',
    editPopupLink: 'edit-popup-link text-overflow-ellipsis',
    linkInputWrapper: 'edit-link-wrapper d-flex',
    iconWrapper: 'edit-link-icon-wrapper',
};

const EditLinkContent = () => {
    const { editor } = useCurrentEditor();
    const linkInputRef = useRef(null);
    const iconColor = useIconColor();
    const darkMode = useSelector(selectDarkMode);

    const [isEditMode, setIsEditMode] = useState(false);

    const handleEditLink = () => {
        const linkValue = getLinkHref(linkInputRef.current?.input?.value);

        if (linkValue) {
            editor
                .chain()
                .focus()
                .extendMarkRange(linkAttribute)
                .setLink({ href: linkValue })
                .run();
        } else {
            editor.chain().focus().unsetLink().run();
        }

        setIsEditMode(false);
    };

    const removeLink = () => {
        editor.chain().focus().unsetLink().run();
    };

    const currentHref = getLinkHref(editor.getAttributes(linkAttribute).href);

    return editor ? (
        <BubbleMenu
            className={classes.editLinkBubbleMenu}
            tippyOptions={{
                duration: 100,
                sticky: false,
                placement: 'top',
                arrow: roundArrow,
                boundary:
                    document.getElementsByClassName('editor-content-wrapper')?.[0] ||
                    'scrollParent',
                offset: ({ placement, popper, reference }) => {
                    let xShift = -25;

                    if (placement === 'top') {
                        const editorWrapper =
                            document.getElementsByClassName('editor-content-wrapper')?.[0];
                        const { width } = editorWrapper?.getBoundingClientRect() || {};
                        const middleEditor = parseInt(width / 2);
                        const absMiddlePopper = parseInt(popper.width / 2) + 25;

                        if (reference.x > middleEditor) {
                            // right middlepart
                            const fromPointToRightBorder = width - reference.x - 20;

                            if (fromPointToRightBorder <= absMiddlePopper) {
                                const leftShift = absMiddlePopper - fromPointToRightBorder;
                                xShift = -25 - leftShift;
                            }
                        } else {
                            // left middlepart
                            const fromPointToLeftBorder = reference.x - 20;

                            if (fromPointToLeftBorder <= absMiddlePopper) {
                                const leftShift = absMiddlePopper - fromPointToLeftBorder;
                                xShift = leftShift - 25;
                            }
                        }
                    }

                    return [xShift, 8];
                },
                appendTo: (ref) => {
                    return ref;
                },
                onHide: () => setIsEditMode(false),
                followCursor: false,
                plugins: [followCursor],
            }}
            editor={editor}
            shouldShow={({ editor }) => {
                const isActive =
                    editor?.isActive(linkAttribute) &&
                    editor?.isFocused &&
                    !editor?.storage?.[nodeTypes.aiSpellChecker]?.spellCheckerVisible;
                setIsEditMode(false);

                return isActive;
            }}
        >
            <div
                className={`${classes.editLinkPopup} ${getThemeColorClass(darkMode)}`}
                contentEditable={false}
            >
                {isEditMode ? (
                    <div className={`${classes.linkInputWrapper}`}>
                        <EditableLinkInput
                            defaultValue={currentHref}
                            linkInputRef={linkInputRef}
                            onSave={handleEditLink}
                        />
                    </div>
                ) : (
                    <a
                        href={currentHref}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.editPopupLink}
                    >
                        {currentHref}
                    </a>
                )}
                <div className={`${classes.iconWrapper}`}>
                    <div onClick={() => setIsEditMode((prev) => !prev)}>
                        <EditIcon color={iconColor} />
                    </div>
                    <div onClick={removeLink}>
                        <RemoveIcon color={iconColor} />
                    </div>
                </div>
            </div>
        </BubbleMenu>
    ) : null;
};

export default EditLinkContent;
