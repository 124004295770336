import { useDispatch, useSelector } from 'react-redux';

import { GoogleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import moment from 'moment';

import KeywordChart from '../../components/common/charts/KeywordChart';
import TableInfoModal from '../../components/common/tableInfo/TableInfoModal';
import TableHeaderSortIcons from '../../components/common/tables/TableHeaderSortIcons';
import DifficultyBadge from '../../components/pages/keywordResearch/common/DifficultyBadge';
import IntentBadge from '../../components/pages/keywordResearch/common/IntentBadge';
import { setKeywordListSort } from '../../components/pages/keywordResearch/store/keywordResearchSettings.actions';
import { selectKeywordListSort } from '../../components/pages/keywordResearch/store/keywordResearchSettings.selectors';

import { dateFormat } from '../../constants/dateConsts';
import { difficultyBadgeSize } from '../../constants/difficultyBadgeSize';
import { difficultyCategories } from '../../constants/difficultyCategories';
import { intentCategories } from '../../constants/intentCategories';
import { keywordResearchResultsSortFields } from '../../constants/keywordResearchResultsSortFields';
import { monthsList } from '../../constants/months';
import { sortOrders } from '../../constants/sortOrders';

import ContentOptimizerIcon from '../../resources/icons/ContentOptimizer';

import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';
import { getNewOptimizerUrl } from '../helpers/getNewOptimizerUrl';
import { getNumWithZeroAfterDecimal } from '../helpers/getNumWithZeroAfterDecimal';
import { getNumberWithCommaSeparator } from '../helpers/getNumberWithCommaSeparator';
import { useOrganizationSlug } from './useOrganizationSlug';

const { Link } = Typography;

const classes = {
    reletedSearches: 'd-flex',
    actionsButtons: 'actions-buttons',
    tableHeaderTitle:
        'd-flex align-items-center keywords-result-table-header-title cursor-pointer',
    columnsWidth: 'space-no-wrap text-decoration-dotted',
    textDotted: 'text-decoration-dotted',
    columnContentColor: 'column-content-color',
    magicPen: 'magic-pen-icon',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    highlightKeyword: 'highlight-keyword',
    active: 'active',
};

export const useKeywordUrlsColumns = () => {
    const dispatch = useDispatch();

    const keywordListSort = useSelector(selectKeywordListSort);
    const darkMode = useSelector(selectDarkMode);

    const organizationSlug = useOrganizationSlug();

    const handleSortClick = (field) => () => {
        dispatch(
            setKeywordListSort({
                field: field,
                order:
                    keywordListSort?.field === field &&
                    keywordListSort?.order === sortOrders.desc
                        ? sortOrders.asc
                        : sortOrders.desc,
            })
        );
    };

    const columns = [
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('keyword')}
                >
                    <Tooltip
                        title={
                            'A list of keywords your target URL is ranking for on Google.'
                        }
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.keyword &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.columnsWidth}>
                            RANKED KEYWORDS
                        </div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.keyword
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'keyword',
            width: '35%',
            fixed: 'left',
            render: (_, record) => {
                return (
                    <div
                        className={`${classes.reletedSearches} ${
                            record.highlight ? classes.highlightKeyword : ''
                        }`}
                    >
                        {record?.relatedSearches}
                        <Tooltip
                            placement='top'
                            title={
                                'Open Google search results for this keyword in a new tab.'
                            }
                            trigger={['hover']}
                            overlayClassName={`${classes.tooltipOverlay}
                                               ${
                                                   darkMode
                                                       ? classes.tooltipOverlayDark
                                                       : ''
                                               }`}
                        >
                            <Link
                                href={`https://www.google.com/search?q=${record?.relatedSearches?.replace(
                                    ' ',
                                    '+'
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                                className={classes.actionsButtons}
                            >
                                <GoogleOutlined />
                            </Link>
                        </Tooltip>
                        <Tooltip
                            placement='top'
                            title={
                                'Open a new Optimizer for this keyword in a new tab.'
                            }
                            trigger={['hover']}
                            overlayClassName={`${classes.tooltipOverlay}
                                               ${
                                                   darkMode
                                                       ? classes.tooltipOverlayDark
                                                       : ''
                                               }`}
                        >
                            <Link
                                href={getNewOptimizerUrl(organizationSlug, record?.relatedSearches)}
                                target='_blank'
                                rel='noreferrer'
                                className={`${classes.actionsButtons} ${classes.magicPen}`}
                            >
                                <ContentOptimizerIcon size={14.5} />
                            </Link>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <Tooltip
                        title={
                            'The purpose or goal behind a user’s search query, guiding the type of content that should be provided to fulfill their needs effectively.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.columnsWidth}>
                            SEARCH INTENT
                        </div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Search Intent'}
                        infoCategories={intentCategories}
                    />
                </div>
            ),
            dataIndex: 'search_intent',
            width: '14%',
            render: (_, record) => {
                if (record.searchIntent) {
                    const showElementCount = 2;
                    return record.searchIntent
                        .split(',')
                        .slice(0, showElementCount)
                        .map((value) => <IntentBadge value={value} />);
                }

                return <IntentBadge value={null} />;
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('position')}
                >
                    <Tooltip
                        title={'Position'}
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.position &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>POSITION</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.position
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'position',
            width: '9%',
            render: (_, record) => {
                return (
                    <Typography level={5} className='space-no-wrap'>
                        {record?.position || 'N/A'}
                    </Typography>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('search_volume')}
                >
                    <Tooltip
                        title={
                            'Search Volume displays the average number of ' +
                            'monthly searches in Google over the past 12 months.'
                        }
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.searchVolume &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>VOLUME</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.searchVolume
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'search_volume',
            width: '8%',
            render: (_, record) => {
                return (
                    <Typography level={5} className='space-no-wrap'>
                        {record?.volume &&
                            getNumberWithCommaSeparator(record?.volume)}
                    </Typography>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('monthly_searches')}
                >
                    <Tooltip
                        title={
                            'A visual display of the Search Volume Trend in Google over the past 12 months.'
                        }
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.monthlySearches &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>TREND</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.monthlySearches
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'monthly_searches',
            width: '12%',
            render: (_, record) => {
                const trendData = record?.trend
                    ?.map((item) => {
                        return {
                            value: item?.search_volume,
                            name: `${monthsList[item?.month - 1]} ${
                                item?.year
                            }`,
                        };
                    })
                    ?.reverse();
                const index = record.relatedSearches.replaceAll(' ', '_');

                return (
                    trendData && (
                        <KeywordChart
                            key={`Trend_${index}`}
                            data={trendData}
                            hoverTitle={`Monthly searches between ${
                                trendData[0]?.name
                            } and ${trendData[trendData?.length - 1]?.name}`}
                        />
                    )
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('cpc_from')}
                >
                    <Tooltip
                        title={
                            'The average Cost Per Click (CPC) range for Google Ads. A higher CPC often suggests that a keyword has higher conversion rates and commercial intent.'
                        }
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.cpc &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>CPC</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.cpc
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'cpc_from',
            width: '6%',
            render: (_, record) => {
                return (
                    <Typography level={5} className='space-no-wrap'>
                        {record?.cpc?.low && record?.cpc?.high
                            ? `$${getNumWithZeroAfterDecimal(
                                  record?.cpc?.low
                              )} - $${getNumWithZeroAfterDecimal(
                                  record?.cpc?.high
                              )}`
                            : 'N/A'}
                    </Typography>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('difficulty')}
                >
                    <Tooltip
                        title={
                            'Keyword Difficulty estimates how difficult it is to rank in the top 10 organic search results on a 100-point scale, where 1 is easy and 100 is extremely hard.'
                        }
                        className={`${
                            keywordListSort?.field === 'difficulty' &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>DIFFICULTY</div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Keyword SEO Difficulty'}
                        infoCategories={difficultyCategories}
                    />
                    <TableHeaderSortIcons
                        active={keywordListSort?.field === 'difficulty'}
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'difficulty',
            width: '11%',
            render: (_, record) => {
                return (
                    <DifficultyBadge
                        marginRight='32px'
                        size={difficultyBadgeSize.small}
                        difficultyScore={record?.difficulty}
                    />
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick(
                        keywordResearchResultsSortFields.updated
                    )}
                >
                    <Tooltip
                        title={
                            'The date when we checked search engine results for the keyword.'
                        }
                        className={`${
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.updated
                                ? classes.active
                                : ''
                        }`}
                        overlayClassName={`${classes.tooltipOverlay} ${
                            darkMode ? classes.tooltipOverlayDark : ''
                        }`}
                    >
                        <div className={classes.textDotted}>Updated</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.updated
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'updated',
            width: '5%',
            render: (_, record) => {
                return (
                    <Typography level={5} className='space-no-wrap'>
                        {record.updated
                            ? moment(
                                record.updated, dateFormat.fullDatetime).format(
                                  dateFormat.shortMonthDayYear
                                )
                            : 'N/A'}
                    </Typography>
                );
            },
        },
    ];

    return columns;
};
