export const adminOrganizationsSettingsActions = {
    CLEAR_ADMIN_ORGANIZATIONS_SETTINGS: 'CLEAR_ADMIN_ORGANIZATIONS_SETTINGS',

    SET_ORGANIZATIONS_DATA: 'SET_ORGANIZATIONS_DATA',
    UPDATE_ORGANIZATION_USER: 'UPDATE_ORGANIZATION_USER',
    SET_ORGANIZATIONS_IS_DATA_LOADING: 'SET_ORGANIZATIONS_IS_DATA_LOADING',
    UPDATE_ORGANIZATION_QUERY_PARAMS: 'UPDATE_ORGANIZATION_QUERY_PARAMS',
    SET_ORGANIZATIONS_TOTAL_PAGES: 'SET_ORGANIZATIONS_TOTAL_PAGES',
};

export const setOrganizationsData = (payload) => {
    return {
        type: adminOrganizationsSettingsActions.SET_ORGANIZATIONS_DATA,
        payload,
    };
};

export const updateOrganizationUser = (payload) => {
    return {
        type: adminOrganizationsSettingsActions.UPDATE_ORGANIZATION_USER,
        payload,
    };
};

export const setOrganizationsIsDataLoading = (payload) => {
    return {
        type: adminOrganizationsSettingsActions.SET_ORGANIZATIONS_IS_DATA_LOADING,
        payload,
    };
};

export const updateOrganizationQueryParams = (payload) => {
    return {
        type: adminOrganizationsSettingsActions.UPDATE_ORGANIZATION_QUERY_PARAMS,
        payload,
    };
};

export const setOrganizationsTotalPages = (payload) => {
    return {
        type: adminOrganizationsSettingsActions.SET_ORGANIZATIONS_TOTAL_PAGES,
        payload,
    };
};

export const clearAdminOrganizationsSettings = () => {
    return {
        type: adminOrganizationsSettingsActions.CLEAR_ADMIN_ORGANIZATIONS_SETTINGS,
    };
};
