import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';
import moment from 'moment';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import {
    selectCurrentSubscription,
    selectKeywordResearchesLimit,
    selectKeywordResearchesUsed,
} from '../../account/billing/store/billingSettings.selectors';

const classes = {
    keywordCredits: 'tabs-credits',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    textDotted: 'text-decoration-dotted',
};

const KeywordResearchCredits = () => {
    const darkMode = useSelector(selectDarkMode);
    const keywordsLimit = useSelector(selectKeywordResearchesLimit);
    const keywordsUsed = useSelector(selectKeywordResearchesUsed);

    const currentSubscription = useSelector(selectCurrentSubscription);

    return (
        <Tooltip
            title={`Keyword Research credits remaining this month. One credit per keyword search. Resets on ${
                currentSubscription
                    ? moment(currentSubscription?.current_period_end).format(
                          'MMM D, YYYY'
                      )
                    : ''
            }.`}
            overlayClassName={`${classes.tooltipOverlay}
                               ${darkMode ? classes.tooltipOverlayDark : ''}`}
        >
            <Typography className={classes.keywordCredits}>
                <span className={classes.textDotted}>
                    {keywordsLimit - keywordsUsed || 0}/{keywordsLimit || 0}{' '}
                    keywords remaining
                </span>
            </Typography>
        </Tooltip>
    );
};

export default KeywordResearchCredits;
