import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import {
	selectKeywordResearchesLimit,
	selectKeywordResearchesUsed,
} from '../../account/billing/store/billingSettings.selectors';

const classes = {
	tooltipOverlay: 'tooltip-overlay',
	tooltipOverlayDark: 'tooltip-overlay-dark',
};

const KeywordResearchSearchButton = ({ isLoading, handleRun }) => {
	const darkMode = useSelector(selectDarkMode);
	const keywordsLimit = useSelector(selectKeywordResearchesLimit);
	const keywordsUsed = useSelector(selectKeywordResearchesUsed);

	const searchButton = (
		<Button type='primary' size='large' loading={isLoading} onClick={handleRun}>
			Search
		</Button>
	);

	if (keywordsUsed < keywordsLimit) {
		return searchButton;
	}

	return (
		<Tooltip
			title={
				'You have reached the limit of keyword researches for your subscription plan. Please upgrade your subscription to search more keywords.'
			}
			overlayClassName={`${classes.tooltipOverlay}
                               ${darkMode ? classes.tooltipOverlayDark : ''}`}
		>
			{searchButton}
		</Tooltip>
	);
};

export default KeywordResearchSearchButton;
