import { combineReducers } from 'redux';

import { contentBriefsOutlineReducer } from '../contentPage/content/briefTab/content/tabsContent/store/contentBriefsOutline.reducer';
import { contentOptimizerContentReducer } from '../contentPage/store/contentOptimizerContent.reducer';
import { contentOptimizerMainReducer } from '../mainPage/store/contentOptimizerMain.reducer';

// TODO: Old editor reducer. Remove after full implementation
// import { contentOptimizerEditorReducer } from '../contentPage/content/optimizerTab/editor/store/contentOptimizerEditor.reducer';
import { contentOptimizerEditorReducer } from '../../../common/tiptapEditor/store/tiptapEditor.reducer';

export const contentOptimizerReducer = combineReducers({
    contentOptimizerMain: contentOptimizerMainReducer,
    contentOptimizerContent: contentOptimizerContentReducer,
    contentOptimizerEditor: contentOptimizerEditorReducer,
    contentBriefsOutline: contentBriefsOutlineReducer,
});
