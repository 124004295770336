import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Tooltip } from 'antd';

import RedoIcon from '../../../../../resources/icons/editor/RedoIcon';
import UndoIcon from '../../../../../resources/icons/editor/UndoIcon';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { nodeTypes } from '../../constants/nodeTypes';
import { getIconColorByDarkMode } from '../constants';

import { editorCommands } from '../../constants/tiptapEditorConstants';
import CustomButtonsTooltip from './CustomButtonsTooltip';

const classes = {
    undoRedoButtonsSection: 'undo-redo-buttons-section',
    tooltipOverlay: 'tooltip-overlay toolbar-tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const UndoRedoButtonsSection = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    const handleHistoryAction = (action) => {
        if (!editor.storage?.[nodeTypes.aiSpellChecker]?.spellCheckerVisible) {
            editor.chain().focus()[action]().run();
        }
    };

    return (
        <div className={`${classes.undoRedoButtonsSection} ${darkMode ? 'dark-mode' : ''}`}>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Undo' />}
            >
                <Button
                    icon={<UndoIcon color={getIconColorByDarkMode(darkMode)} />}
                    onClick={() => handleHistoryAction(editorCommands.undo)}
                />
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Redo' />}
            >
                <Button
                    icon={<RedoIcon color={getIconColorByDarkMode(darkMode)} />}
                    onClick={() => handleHistoryAction(editorCommands.redo)}
                />
            </Tooltip>
        </div>
    );
};

export default UndoRedoButtonsSection;
