import React from 'react';
import { useSelector } from 'react-redux';

import DragHandle from '@tiptap-pro/extension-drag-handle-react';
import { useCurrentEditor } from '@tiptap/react';
import { Button } from 'antd';

import DragIcon from '../../../../resources/icons/editor/DragIcon';

import { useIconColor } from '../../../../utils/hooks/useIconColor';

import { selectAIStateAccepted } from '../store/tiptapEditor.selectors';

const classes = {
    dragButton: 'drag-button btn-hover-light-primary',
};

const DraggableComponent = () => {
    const { editor } = useCurrentEditor();
    const iconColor = useIconColor();

    const aiAccepted = useSelector(selectAIStateAccepted);

    const handleMouseDown = (event) => {
        if (!aiAccepted) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <DragHandle
            pluginKey='dragHandle'
            editor={editor}
            tippyOptions={{
                zIndex: 0,
                duration: 0,
            }}
        >
            <Button
                className={classes.dragButton}
                icon={<DragIcon color={iconColor} />}
                onMouseDown={handleMouseDown}
            />
        </DragHandle>
    );
};

export default DraggableComponent;
