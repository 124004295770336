import { roleTypes } from '../../constants/roleTypes';

export const checkIsGlobalAdmin = (roles) => {
    if (Array.isArray(roles)) {
        return roles.some((x) => x?.name === roleTypes.globalAdmin.key);
    }

    return false;
};

export const checkIsTechAdmin = (roles) => {
    if (Array.isArray(roles)) {
        return roles.some((x) => x?.name === roleTypes.techAdmin.key);
    }

    return false;
};

export const checkIsGlobalTechAdmin = (roles) => {
    if (Array.isArray(roles)) {
        return roles.some(
            (x) => x?.name === roleTypes.techAdmin.key || x?.name === roleTypes.globalAdmin.key
        );
    }

    return false;
};
