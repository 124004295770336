import { roleTypes } from '../../constants/roleTypes';

export const getRoleLabel = (roleKeys) => {
    const getRoleName = (roleKey) => {
        const role = Object.values(roleTypes).find((role) => role.key === roleKey);

        return role ? role.name : '';
    };

    if (!Array.isArray(roleKeys)) {
        return getRoleName(roleKeys);
    } else {
        return roleKeys.map((roleKey) => getRoleName(roleKey.name)).join(', ');
    }
};
