import React from 'react';

const noneStrokeStyle = { stroke: 'none' };

const TeacherIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.0103 17C11.1603 17 10.3003 16.78 9.63031 16.35L3.61031 12.42C2.49031 11.69 1.82031 10.46 1.82031 9.12003C1.82031 7.78003 2.49031 6.55003 3.61031 5.82003L9.64031 1.90003C10.9803 1.03003 13.0703 1.03003 14.4003 1.91003L20.3903 5.84003C21.5003 6.57003 22.1703 7.80003 22.1703 9.13003C22.1703 10.46 21.5003 11.69 20.3903 12.42L14.4003 16.35C13.7303 16.79 12.8703 17 12.0103 17ZM12.0103 2.75003C11.4403 2.75003 10.8703 2.88003 10.4603 3.16003L4.44031 7.08003C3.74031 7.54003 3.33031 8.28003 3.33031 9.12003C3.33031 9.96003 3.73031 10.7 4.44031 11.16L10.4603 15.09C11.2903 15.63 12.7503 15.63 13.5803 15.09L19.5703 11.16C20.2703 10.7 20.6703 9.96003 20.6703 9.12003C20.6703 8.28003 20.2703 7.54003 19.5703 7.08003L13.5803 3.15003C13.1603 2.89003 12.5903 2.75003 12.0103 2.75003Z'
                fill={color}
                style={noneStrokeStyle}
            />
            <path
                d='M11.9992 22.7501C11.5592 22.7501 11.1092 22.6901 10.7492 22.5701L7.5592 21.5101C6.0492 21.0101 4.8592 19.3601 4.8692 17.7701L4.8792 13.0801C4.8792 12.6701 5.2192 12.3301 5.6292 12.3301C6.0392 12.3301 6.3792 12.6701 6.3792 13.0801L6.3692 17.7701C6.3692 18.7101 7.1492 19.7901 8.0392 20.0901L11.2292 21.1501C11.6292 21.2801 12.3692 21.2801 12.7692 21.1501L15.9592 20.0901C16.8492 19.7901 17.6292 18.7101 17.6292 17.7801V13.1401C17.6292 12.7301 17.9692 12.3901 18.3792 12.3901C18.7892 12.3901 19.1292 12.7301 19.1292 13.1401V17.7801C19.1292 19.3701 17.9492 21.0101 16.4392 21.5201L13.2492 22.5801C12.8892 22.6901 12.4392 22.7501 11.9992 22.7501Z'
                fill={color}
                style={noneStrokeStyle}
            />
            <path
                d='M21.4004 15.75C20.9904 15.75 20.6504 15.41 20.6504 15V9C20.6504 8.59 20.9904 8.25 21.4004 8.25C21.8104 8.25 22.1504 8.59 22.1504 9V15C22.1504 15.41 21.8104 15.75 21.4004 15.75Z'
                fill={color}
                style={noneStrokeStyle}
            />
        </svg>
    );
};

export default TeacherIcon;
