import { slateToHtml, slateToHtmlConfig } from '@slate-serializers/html';

const domhandler = require('domhandler');

const customSlateToHtmlConfig = {
    ...slateToHtmlConfig,
    alwaysEncodeBreakingEntities: true,
    alwaysEncodeCodeEntities: true,
    convertLineBreakToBr: true,
    encodeEntities: true,
    markMap: {
        strikethrough: ['s'],
        bold: ['strong'],
        underline: ['u'],
        italic: ['i'],
        code: ['code'],
        image: ['img'],
    },
    elementMap: {
        p: 'p',
        paragraph: 'p',
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        ul: 'ul',
        ol: 'ol',
        li: 'li',
        blockquote: 'blockquote',
        link: 'a',
        'list-item': 'li',
        unordered_list: 'ul',
        ordered_list: 'ol',
        code_block: 'code',
        heading_1: 'h1',
        heading_2: 'h2',
        heading_3: 'h3',
        heading_4: 'h4',
        heading_5: 'h5',
        heading_6: 'h6',
        image: 'img',
        'paragraph-spacing': 'hr',
    },
    elementTransforms: {
        ...slateToHtmlConfig.elementTransforms,
        link: ({ node, attribs = {}, children = [] }) => {
            return slateToHtmlConfig.elementTransforms.link({
                node: {
                    ...node,
                    url: node.href || '',
                },
                attribs,
                children,
            });
        },
        image: ({ node, attribs = {}, children = [] }) => {
            let newAlign = '';

            switch (node.align) {
                case 'left':
                    newAlign = 'flex-start';
                    break;
                case 'center':
                    newAlign = 'center';
                    break;
                case 'right':
                    newAlign = 'flex-end';
                    break;
                default:
                    newAlign = '';
            }

            const alignAttribute =
                newAlign.length > 0 ? ` justify-content: ${newAlign};` : '';

            const additionalAttributes = {
                style: `width: ${
                    node.width ? `${node.width}px` : '100%'
                }; height: ${
                    node.height ? `${node.height}px` : 'auto'
                }; cursor: pointer;${alignAttribute}`,
                draggable: 'true',
            };

            return new domhandler.Element(
                'img',
                Object.assign(attribs, {
                    src: node.url,
                    type: 'img',
                    ...additionalAttributes,
                }),
                children
            );
        },
    },
};

const manageContentNotification = (isSlateDataFormat = false) => {
    const notificationNode = document.querySelectorAll(
        '[data-editor-notification]'
    )?.[0];

    if (notificationNode) {
        const isHidden = notificationNode.classList.contains('d-none');

        if (isSlateDataFormat) {
            if (isHidden) {
                notificationNode.classList.remove('d-none');
            }
        } else if (!isHidden) {
            notificationNode.classList.add('d-none');
        }
    }
};

export const transformContentData = (content = '', isActive = false) => {
    if (!content || !content.length) {
        return '';
    }

    const parsedContent = JSON.parse(content);
    const isSlateDataFormat = typeof parsedContent === 'object';
    const serializedToHtml = isSlateDataFormat
        ? slateToHtml(parsedContent, customSlateToHtmlConfig)
        : parsedContent;

    if (isActive) {
        manageContentNotification(isSlateDataFormat);
    }

    return JSON.stringify(serializedToHtml);
};
