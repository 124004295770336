import React from 'react';
import { useSelector } from 'react-redux';

import Typography from 'antd/es/typography/Typography';

import ForbiddenDark from '../../../resources/icons/errors/ForbiddenDark';
import ForbiddenLight from '../../../resources/icons/errors/ForbiddenLight';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import GoBackErrorButton from './GoBackErrorButton';

import './ErrorPages.scss';

const classes = {
    root: 'error-page-root',
    mainArticle: 'main-article',
    secondArticle: 'second-article',
};

const ForbiddenPage = () => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.root} ${darkMode ? 'dark-mode' : ''}`}>
            {darkMode ? <ForbiddenDark /> : <ForbiddenLight />}
            <Typography
                className={`${classes.mainArticle} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                Forbidden Error - 403
            </Typography>
            <Typography
                className={`${classes.secondArticle} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                You don't have permission to access this resource.
            </Typography>
            <GoBackErrorButton />
        </div>
    );
};

export default ForbiddenPage;
