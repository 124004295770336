import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Title from 'antd/es/typography/Title';

import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import SwitchedPlanCard from '../content/SwitchedPlanCard';
import GoBackButton from '../../../../../common/goBackButton/GoBackButton';

const classes = {
    billingPlansContentWrapper: 'billing-plans-wrapper h-100',
    headerSection: 'header-billing-section',
    headerTitle: 'header-title',
    billingPlansContentWrapperDark: 'billing-plans-wrapper-dark',
    switchedPlanContentWrapper:
        'switched-plan-content-wrapper d-flex align-items-center flex-column h-100',
};

const Checkout = () => {
    const navigate = useNavigate();

    const organizationSlug = useOrganizationSlug();

    const darkMode = useSelector(selectDarkMode);

    const goBack = () => {
        navigate(`/${organizationSlug}/account/plans`);
    };

    return (
        <div
            className={`${classes.billingPlansContentWrapper}
                ${darkMode ? classes.billingPlansContentWrapperDark : ''}`}
        >
            <div className={classes.switchedPlanContentWrapper}>
                <div className={classes.headerSection}>
                    <GoBackButton onClick={goBack} />
                    <Title level={3} className={classes.headerTitle}>
                        Checkout
                    </Title>
                </div>
                <SwitchedPlanCard />
            </div>
        </div>
    );
};

export default Checkout;
