export const statusCodes = {
    success: 200,
    create: 201,
    noContent: 204,
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    subscriptionExpired: 402,
};
