export const selectContentOptimizerMainTab = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.contentOptimizerMainTab;
export const selectContentOptimizersChecked = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.contentOptimizersChecked;
export const selectContentOptimizerData = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.contentOptimizerData;
export const selectContentOptimizerMainQueryParams = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.queryParams;
export const selectContentOptimizersMainProject = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.queryParams?.projects;
export const selectContentOptimizerMainTotalCount = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.totalCount;
export const selectContentOptimizersCount = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.optimizersCount;

export const selectContentBriefsProjects = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.projects;

export const selectIsImportURLActive = (state) =>
    state?.contentOptimizer?.contentOptimizerMain?.isImportURLActive;

export const selectOptimizerForUpdate = (state) => 
    state?.contentOptimizer?.contentOptimizerMain?.contentOptimizerForUpdate;

export const selectContentOptimizerTabKey = (state) => 
    state?.contentOptimizer?.contentOptimizerMain?.contentOptimizerTabKey;