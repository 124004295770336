import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    mainLabel: 'main-label',
};

const UsageChartLabel = ({ mainLabel }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Row justify='space-between' className={`${getThemeColorClass(darkMode)}`}>
            <Col className='d-flex align-items-center'>
                <Typography className={`${classes.mainLabel} ${getThemeColorClass(darkMode)}`}>
                    {mainLabel}
                </Typography>
            </Col>
        </Row>
    );
};

export default UsageChartLabel;
