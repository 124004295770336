// Brief
export const selectCurrentBrief = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentBrief;

export const selectCurrentBriefId = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentBriefId;

export const selectCurrentBriefLoading = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentBriefLoading;

export const selectBriefVersions = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefVersions;

export const selectPreviewBriefVersion = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefPreviewVersion;

export const selectBriefProgress = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefProgress;

export const selectBriefTimings = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefTimings;

export const selectBriefShared = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefShared;

export const selectBriefQuestions = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefQuestions;

export const selectBriefCitations = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.briefCitations;

// Outline
export const selectCurrentOutline = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentOutline;

export const selectCurrentOutlineId = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentOutlineId;

export const selectOutlineVersions = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.outlineVersions;

export const selectCurrentOutlineLoading = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentOutlineLoading;

export const selectCurrentOutlineVersion = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.currentOutlineVersion;

export const selectPreviewOutlineVersion = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.outlinePreviewVersion;

// Htags
export const selectRanksHtags = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.ranksHtags;

export const selectIsRanksHtagsLoaded = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.isRanksHtagsLoaded;

export const selectAIBriefLoading = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.aiBriefLoading;

export const selectOutlineVersionLoading = (state) =>
    state?.contentOptimizer?.contentBriefsOutline?.outlineVersionLoading;
