import { colorPalette } from '../../resources/styles/colorPalette';

export const getTrendGraphStrokeColor = (firstDataItem, lastDataItem) => {
    if (firstDataItem < lastDataItem) {
        return colorPalette.trendGraphColor.increase;
    } else if (firstDataItem > lastDataItem) {
        return colorPalette.trendGraphColor.decrease;
    } else {
        return colorPalette.trendGraphColor.neutral;
    }
};

export const getFirstLinearGradientColor = (
    firstDataItem,
    lastDataItem,
    darkMode
) => {
    if (firstDataItem < lastDataItem) {
        return darkMode
            ? colorPalette.trendGraphColor.gradient.firstColor.increase.dark
            : colorPalette.trendGraphColor.gradient.firstColor.increase.light;
    } else if (firstDataItem > lastDataItem) {
        return darkMode
            ? colorPalette.trendGraphColor.gradient.firstColor.decrease.dark
            : colorPalette.trendGraphColor.gradient.firstColor.decrease.light;
    } else {
        return darkMode
            ? colorPalette.trendGraphColor.gradient.firstColor.neutral.dark
            : colorPalette.trendGraphColor.gradient.firstColor.neutral.light;
    }
};

export const getSecondLinearGradientColor = (
    firstDataItem,
    lastDataItem,
    darkMode
) => {
    if (firstDataItem < lastDataItem) {
        return darkMode
            ? colorPalette.trendGraphColor.gradient.secondColor.increase.dark
            : colorPalette.trendGraphColor.gradient.secondColor.increase.light;
    } else if (firstDataItem > lastDataItem) {
        return darkMode
            ? colorPalette.trendGraphColor.gradient.secondColor.decrease.dark
            : colorPalette.trendGraphColor.gradient.secondColor.decrease.light;
    } else {
        return darkMode
            ? colorPalette.trendGraphColor.gradient.secondColor.neutral.dark
            : colorPalette.trendGraphColor.gradient.secondColor.neutral.light;
    }
};
