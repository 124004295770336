import React from 'react';

import { Typography } from 'antd';

import ViewOnlyModeIcon from '../../../resources/icons/viewOnlyNavMenu/ViewOnlyModeIcon';

const classes = {
    viewOnlyMode:
        'd-flex flex-center justify-content-start align-items-center view-only-mode',
    viewOnlyModeText: 'ml-8px',
};

const ViewOnlyMode = ({ collapsed }) => {
    return (
        <div className={classes.viewOnlyMode}>
            <ViewOnlyModeIcon />
            {!collapsed && (
                <>
                    <Typography className={classes.viewOnlyModeText}>
                        View-Only Mode
                    </Typography>
                </>
            )}
        </div>
    );
};

export default ViewOnlyMode;
