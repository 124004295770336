import React from 'react';

import { Typography } from 'antd';

import ExpandedUsersTable from '../../organizations/content/ExpandedUsersTable';

const { Title } = Typography;

const AdminQuotaUsersExpandedRow = ({ record }) => {
    const usersData = record?.organization?.users?.map((user) => ({
        ...user,
        key: user?.id,
        id: user?.id,
        full_name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
        role: user?.roles,
        active: user?.active,
    }));

    return (
        <>
            {usersData.length > 0 && (
                <>
                    <Title level={5}>Users</Title>
                    <ExpandedUsersTable usersData={usersData} showActions={false} />
                </>
            )}
        </>
    );
};

export default AdminQuotaUsersExpandedRow;
