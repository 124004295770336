import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { isNumber } from 'lodash';

import { countWords, getSelectedTextWordCount } from '../utils/wordCountHelpers';

import { EDITOR_AI_SELECTED_WORDS_LIMIT } from '../constants/aiEditorConstants';
import { nodeTypes } from '../constants/nodeTypes';
import { editorEvents } from '../constants/tiptapEditorConstants';

import { selectAIStateAccepted } from '../store/tiptapEditor.selectors';

const WHITESPACE_SEPARATOR = ' ';

const useSelectedTextWordCount = () => {
    const { editor } = useCurrentEditor();

    const aiAccepted = useSelector(selectAIStateAccepted);

    const [selection, setSelection] = useState(null);

    const getTextBetween = useCallback(
        (from, to) => {
            try {
                return editor.state.doc.textBetween(
                    from,
                    to,
                    WHITESPACE_SEPARATOR,
                    WHITESPACE_SEPARATOR
                );
            } catch (error) {
                return '';
            }
        },
        [editor]
    );

    useEffect(() => {
        if (!editor) return;

        const updateSelection = () => {
            setSelection(editor.state.selection);
        };

        editor.on(editorEvents.selectionUpdate, updateSelection);

        return () => {
            editor.off(editorEvents.selectionUpdate, updateSelection);
        };
    }, [editor]);

    const interruptedOptimizer = editor?.storage?.[nodeTypes.aiSpellChecker]?.interruptedOptimizer;
    const spellCheckerVisible = editor?.storage?.[nodeTypes.aiSpellChecker]?.spellCheckerVisible;

    const selectedTextWordCount = useMemo(() => {
        if (!editor) return 0;

        if (spellCheckerVisible) {
            if (interruptedOptimizer.id && !interruptedOptimizer.isMatch) {
                return 0;
            }

            const { from, to } = editor.storage?.[nodeTypes.aiSpellChecker]?.selectedRange || {};

            return isNumber(from) && isNumber(to) && editor.getText().length > 0
                ? countWords(getTextBetween(from, to), true)
                : 0;
        }

        if (!selection || selection.empty) return 0;

        const { from, to } = selection;
        const selectionContainsText = getTextBetween(from, to);

        return selectionContainsText ? getSelectedTextWordCount(editor) : 0;
    }, [editor, selection, getTextBetween, interruptedOptimizer, spellCheckerVisible]);

    const isAIFeatureEnabled = useMemo(() => {
        return aiAccepted
            ? selectedTextWordCount > 0 && selectedTextWordCount <= EDITOR_AI_SELECTED_WORDS_LIMIT
            : true;
    }, [aiAccepted, selectedTextWordCount]);

    const selectTextWithinLimit = useCallback(() => {
        let accumulatedWordCount = 0;
        let lastValidPosition = null;
        let isWordCountLimitExceeded = false;

        // Iterate through all nodes and count the words until the limit is reached
        editor.state.doc.descendants((node, pos, parent) => {
            if (!isWordCountLimitExceeded && node && isNumber(node.nodeSize)) {
                // Only iterate through top-level nodes
                if (parent === editor.state.doc) {
                    const textBetween = getTextBetween(pos, pos + node.nodeSize);
                    const nodeWordCount = countWords(textBetween, true);

                    if (accumulatedWordCount + nodeWordCount <= EDITOR_AI_SELECTED_WORDS_LIMIT) {
                        accumulatedWordCount += nodeWordCount;
                        lastValidPosition = pos + node.nodeSize;
                    } else {
                        isWordCountLimitExceeded = true;
                    }
                }
            } else {
                return false; // Stop iterating
            }
        });

        if (lastValidPosition !== null) {
            editor.commands.setTextSelection({
                from: 0,
                to: lastValidPosition,
            });
        }
    }, [editor, getTextBetween]);

    return { selectedTextWordCount, isAIFeatureEnabled, selectTextWithinLimit };
};

export default useSelectedTextWordCount;
