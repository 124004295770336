import React from 'react';

const ViewOnlyModeIcon = ({ color = '#9199AD', size = 20 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.9833 10.0009C12.9833 11.6509 11.65 12.9842 10 12.9842C8.35 12.9842 7.01667 11.6509 7.01667 10.0009C7.01667 8.35091 8.35 7.01758 10 7.01758C11.65 7.01758 12.9833 8.35091 12.9833 10.0009Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10 16.8913C12.9417 16.8913 15.6833 15.1579 17.5917 12.1579C18.3417 10.9829 18.3417 9.00794 17.5917 7.83294C15.6833 4.83294 12.9417 3.09961 10 3.09961C7.05833 3.09961 4.31667 4.83294 2.40833 7.83294C1.65833 9.00794 1.65833 10.9829 2.40833 12.1579C4.31667 15.1579 7.05833 16.8913 10 16.8913Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ViewOnlyModeIcon;
