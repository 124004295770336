import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EventType } from '@upollo/web';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import Link from 'antd/es/typography/Link';

import { localStorageUserKey, LoginStatusCode } from '../../../../constants/authConsts';
import { formEmailRules } from '../../../../constants/formItemRules';
import { authPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import {
    setUserData,
    updateRememberUserMode,
} from '../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectDarkMode,
    selectRememberUserMode,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import RuptService from '../../../../utils/helpers/RuptService';
import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';

import { unauthorizedRoutes } from '../../../common/router/Unauthorized/routes';
import { UppoloContext } from '../../../hocs/UppoloProvider';
import { setUserRegistrationEmail } from '../store/authSettings.actions';

import './LoginPageContent.scss';

const classes = {
    root: 'signin-wrapper',
    options: 'singin-options',
    contactUs: 'd-flex flex-center signin-contact-us',
    customInputs: 'custom-inputs',
    forgotPassword: 'forgot-password link-hover-highlight',
};

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const upollo = useContext(UppoloContext);

    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);
    const rememberUserMode = useSelector(selectRememberUserMode);

    const [isLoginLoading, setIsLoginLoading] = useState(false);

    const confirmMail = async (email) => {
        try {
            await axiosAPI.post(
                authPathes.getConfirmInstructions,
                { email: email },
                { ...getAxiosHeaders() }
            );

            dispatch(setUserRegistrationEmail(email));
            navigate(unauthorizedRoutes.confirmInstructions);
        } catch (err) {
            openBasicErrorNotification();
        }
    };

    const redirectToSelectPlan = (user) => {
        localStorage.setItem(localStorageUserKey, JSON.stringify(user));
        navigate(unauthorizedRoutes.selectPlan);
    };

    const logIn = (values) => {
        if (values?.email && values?.password) {
            sendUppoloTrackEvent(values.email, EventType.EVENT_TYPE_LOGIN);

            axiosAPI
                .post(authPathes.login, values, { ...getAxiosHeaders() })
                .then(async (result) => {
                    if (result?.status === statusCodes.success) {
                        localStorage.setItem('loggedIn', true);
                        dispatch(setUserData(result?.data?.user));

                        await RuptService.attachDevice(
                            result.data?.user?.email,
                            String(result?.data?.user?.id)
                        );

                        await sendUppoloTrackEvent(
                            values.email,
                            EventType.EVENT_TYPE_LOGIN_SUCCESS
                        );

                        // Sending data to ProfitWell to gather data about user engagement
                        window.profitwell('start', { user_id: result.data?.stripe_customer_id });

                        if (values?.remember && values?.remember !== rememberUserMode) {
                            dispatch(updateRememberUserMode(values?.remember));
                        }
                    }

                    if (!result || result?.status === statusCodes.unauthorized) {
                        throw new Error('Invalid email or password');
                    }
                })
                .catch(async (err) => {
                    const errorMessage = err.response?.data?.error;

                    switch (errorMessage) {
                        case LoginStatusCode.CONFIRMATION_REQUIRED: {
                            await confirmMail(values.email);

                            break;
                        }
                        case LoginStatusCode.PAYMENT_PLAN_REQUIRED: {
                            if (err.response?.data?.user) {
                                redirectToSelectPlan(err.response.data.user);
                            }

                            break;
                        }
                        default: {
                            form.setFields([
                                {
                                    name: 'password',
                                    errors: ['Invalid email and/or password'],
                                },
                            ]);
                        }
                    }
                })
                .finally(() => {
                    setIsLoginLoading(false);
                });
        }
    };

    const handleLogin = (values) => {
        setIsLoginLoading(true);

        // Need to get correct token if it was expired or missing before login
        axiosAPI
            .get(authPathes.checkAuthToken)
            .catch((_err) => {})
            .finally(() => {
                logIn(values);
            });
    };

    const handleOnKeyPress = (event) => {
        if (event.key === 'Enter') {
            form.submit();
        }
    };

    const handleForgotPassword = () => {
        navigate(unauthorizedRoutes.forgotPassword);
    };

    const handleSignUp = () => {
        navigate(unauthorizedRoutes.signUp);
    };

    const sendUppoloTrackEvent = async (userEmail, event) => {
        if (upollo) await upollo.track({ userEmail }, event);
    };

    return (
        <Form
            name='singInForm'
            form={form}
            onKeyPress={handleOnKeyPress}
            initialValues={{
                remember: rememberUserMode,
            }}
            onFinish={(values) => handleLogin(values)}
            autoComplete='off'
            className={classes.root}
        >
            <Typography>Email</Typography>
            <Form.Item name='email' rules={formEmailRules} className={classes.customInputs}>
                <Input placeholder='Enter your email' />
            </Form.Item>

            <Typography>Password</Typography>
            <Form.Item
                name='password'
                rules={[
                    {
                        required: true,
                        message: 'Please enter your password',
                    },
                ]}
                className={classes.customInputs}
            >
                <Input.Password placeholder='Enter your password' />
            </Form.Item>

            <div className={classes.options}>
                <Form.Item name='remember' valuePropName='checked' hidden>
                    <Checkbox hidden>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' loading={isLoginLoading}>
                        Sign in
                    </Button>
                </Form.Item>
            </div>

            <div className={classes.contactUs}>
                <Link
                    onClick={handleForgotPassword}
                    className={`${classes.forgotPassword} ${getThemeColorClass(darkMode)}`}
                >
                    Forgot your password?
                </Link>
            </div>

            <div className={classes.contactUs}>
                <Typography>Don’t have an account?&nbsp;</Typography>
                <Link onClick={handleSignUp}>Sign up</Link>
            </div>
        </Form>
    );
};

export default SignIn;
