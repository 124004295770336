import { useSelector } from 'react-redux';

import { colorPalette } from '../../resources/styles/colorPalette';
import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';

export const useDifficultyBadgeColor = (difficultyScore) => {
    const darkMode = useSelector(selectDarkMode);

    const getColor = () => {
        switch (true) {
            case difficultyScore <= 100 && difficultyScore > 89:
                return '#D52624';
            case difficultyScore <= 89 && difficultyScore > 69:
                return '#CC5C24';
            case difficultyScore <= 69 && difficultyScore > 49:
                return '#C38E21';
            case difficultyScore <= 49 && difficultyScore > 29:
                return '#BBBB21';
            case difficultyScore <= 29 && difficultyScore > 14:
                return '#81B21F';
            case difficultyScore <= 14 && difficultyScore > 0:
                return '#1EA11D';
            default:
                return darkMode
                    ? colorPalette.noScoreBadgeColor.dark
                    : colorPalette.noScoreBadgeColor.light;
        }
    };

    const color = getColor();

    return color;
};
