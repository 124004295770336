import React from 'react';

import { Skeleton } from 'antd';

import './Common.scss';

const SkeletonLoading = ({ width = '100%' }) => {
    return (
        <Skeleton.Input
            className='skeleton-loading'
            active
            size={'small'}
            block
            style={{ width: width }}
        />
    );
};

export default SkeletonLoading;
