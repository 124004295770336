import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

const classes = {
    goBackButton: 'go-back-error-button',
};

const GoBackErrorButton = ({ btnText = 'Go back' }) => {
    const navigate = useNavigate();

    const handleGoBackClick = () => {
        navigate('/');
    };

    return (
        <Button
            onClick={handleGoBackClick}
            className={`${classes.goBackButton}`}
            type='primary'
        >
            {btnText}
        </Button>
    );
};

export default GoBackErrorButton;
