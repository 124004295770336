import { colorPalette } from './colorPalette';

export const darkTheme = {
    token: {
        colorBgLayout: colorPalette.layoutBackground.dark,
        colorBgContainer: colorPalette.cardBackground.dark,
        colorPrimary: colorPalette.colorPrimary,
        colorPrimaryHover: colorPalette.colorPrimary,
        colorError: colorPalette.colorError,
        colorSuccess: colorPalette.colorSuccess,
        colorPrimaryBg: colorPalette.colorPrimaryBg.dark,
        colorTextDisabled: colorPalette.textDisabledColor.dark,
        colorLink: colorPalette.colorPrimary,
        colorLinkHover: colorPalette.colorPrimary,
    },
    components: {
        Menu: {
            colorItemText: colorPalette.textColor.dark,
            colorItemTextHover: colorPalette.textColor.dark,
            colorItemBgHover: colorPalette.colorPrimaryBg.dark,
            motionDurationSlow: '0s',
        },
        Card: {
            colorBgContainer: colorPalette.cardBackground.dark,
            colorBorderSecondary: colorPalette.borderColor.dark,
            borderRadiusLG: 6,
        },
        Button: {
            colorPrimaryHover: colorPalette.colorPrimaryBgHover.dark,
            colorTextDisabled: colorPalette.textDisabledBtnColor,
            colorBgContainerDisabled: colorPalette.colorPrimaryBgDisabled.dark,
            colorBorder: colorPalette.colorPrimaryBgDisabled.dark,
        },
        Typography: {
            colorText: colorPalette.textColor.dark,
            colorTextHeading: colorPalette.textAccentColor.dark,
        },
        Dropdown: {
            controlItemBgHover: colorPalette.colorPrimaryBg.dark,
            colorBgElevated: colorPalette.dropdownBackground.dark,
            colorText: colorPalette.textColor.dark,
        },
        Select: {
            colorBgContainer: colorPalette.inputBackground.dark,
            colorBgElevated: colorPalette.dropdownBackground.dark,
            controlItemBgActive: colorPalette.colorPrimaryBg.dark,
            colorTextPlaceholder: colorPalette.textColor.dark,
            colorBorder: colorPalette.borderColor.dark,
            colorText: colorPalette.textColor.dark,
            colorTextDescription: colorPalette.textColor.dark,
            controlItemBgHover: colorPalette.colorPrimaryBg.dark,
            colorTextQuaternary: colorPalette.textColor.dark,
            controlOutlineWidth: 0,
            fontWeightStrong: 400,
        },
        Modal: {
            colorBgElevated: colorPalette.modalBackground.dark,
            colorBgMask: colorPalette.modalMask.dark,
            borderRadiusLG: 6,
            boxShadowSecondary: colorPalette.modalBoxShadow.dark,
            colorTextHeading: colorPalette.textAccentColor.dark,
        },
        Input: {
            colorBgContainer: colorPalette.inputBackground.dark,
            colorTextPlaceholder: colorPalette.textColor.dark,
            colorBorder: colorPalette.borderColor.dark,
            colorText: colorPalette.textAccentColor.dark,
            controlOutline: 'transparent',
        },
        InputNumber: {
            colorBgContainer: colorPalette.inputBackground.dark,
            colorTextPlaceholder: colorPalette.textColor.dark,
            colorBorder: colorPalette.borderColor.dark,
            colorText: colorPalette.textAccentColor.dark,
            controlOutline: 'transparent',
        },
        Spin: {
            colorPrimary: colorPalette.layoutBackground.light,
        },
        Checkbox: {
            colorText: colorPalette.textColor.dark,
        },
        Tabs: {
            colorText: colorPalette.textColor.dark,
            colorSplit: colorPalette.borderColor.dark,
        },
        Form: {
            colorTextHeading: colorPalette.textColor.dark,
        },
        Table: {
            colorTextHeading: colorPalette.textColor.dark,
            colorText: colorPalette.textAccentColor.dark,
            colorBorderSecondary: colorPalette.borderColor.dark,
            colorFillAlter: colorPalette.tableHeaderBackground.dark,
            colorIcon: colorPalette.textColor.dark,
            borderRadiusLG: 6,
            padding: 24,
            motionDurationSlow: '0s',
            motionDurationMid: '0s',
            motionDurationFast: '0s',
        },
        Radio: {
            colorBorder: colorPalette.borderColor.dark,
            colorBgContainer: colorPalette.radioBackground.dark,
            colorWhite: colorPalette.radioBackground.dark,
        },
        Switch: {
            colorTextQuaternary: colorPalette.switchSecondaryBackground.dark,
            colorTextTertiary: colorPalette.switchHoverBackground.dark,
        },
        Segmented: {
            colorBgLayout: colorPalette.segmentedBackground.dark,
            colorBgElevated: colorPalette.segmentedElevated.dark,
            colorText: colorPalette.textAccentColor.dark,
            colorTextLabel: colorPalette.textColor.dark,
            fontSize: 16,
            lineHeight: 22,
        },
        Drawer: {
            colorBgElevated: colorPalette.drawerBackground.dark,
        },
    },
};
