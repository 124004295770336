import { viewModeType } from '../../constants/viewModeType';
import { browserSettingsActions } from './browserSettings.actions';

const browserSettingsInitialState = {
    user: null,
    isSessionCorrect: false,

    darkMode: JSON.parse(localStorage.getItem('darkMode')) || false,
    rememberUserMode: JSON.parse(localStorage.getItem('rememberUserMode')) || true,
    viewMode: localStorage.getItem('viewMode') || viewModeType.list,

    rankabilityLoading: 0,

    errorStatus: null,
    isSubscriptionExpired: false,
    isGracePeriod: false,
};

export const browserSettingsReducer = (
    state = browserSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case browserSettingsActions.SET_RANKABILITY_LOADING: {
            return {
                ...state,
                rankabilityLoading: payload,
            };
        }

        case browserSettingsActions.SET_USER_DATA: {
            return {
                ...state,
                user: payload,
            };
        }

        case browserSettingsActions.SET_IS_SESSION_CORRECT: {
            return {
                ...state,
                isSessionCorrect: payload,
            };
        }

        case browserSettingsActions.UPDATE_DARK_MODE: {
            localStorage.setItem('darkMode', payload);

            return {
                ...state,
                darkMode: payload,
            };
        }

        case browserSettingsActions.UPDATE_REMEMBER_USER_MODE: {
            localStorage.setItem('rememberUserMode', payload);

            return {
                ...state,
                rememberUserMode: payload,
            };
        }

        case browserSettingsActions.UPDATE_LIST_VIEW_MODE: {
            localStorage.setItem('viewMode', payload);

            return {
                ...state,
                viewMode: payload,
            };
        }

        case browserSettingsActions.SET_ERROR_STATUS: {
            return {
                ...state,
                errorStatus: payload,
            };
        }

        case browserSettingsActions.SET_IS_SUBSCRIPTION_EXPIRED: {
            return {
                ...state,
                isSubscriptionExpired: payload,
            };
        }

        case browserSettingsActions.SET_IS_GRACE_PERIOD: {
            return {
                ...state,
                isGracePeriod: payload,
            };
        }

        default:
            return state;
    }
};
