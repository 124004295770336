import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import useSelectedTextWordCount from '../../hooks/useSelectedTextWordCount';
import { getTooltipOverlayClass } from '../../../../../utils/helpers/themeHelpers';

const classes = {
    wordCounter: 'word-counter',
    active: 'active',

    tooltipOverlay: 'toolbar-tooltip-overlay',
};

const AIWordCounter = () => {
    const darkMode = useSelector(selectDarkMode);

    const { selectedTextWordCount, isAIFeatureEnabled } = useSelectedTextWordCount();

    if (!selectedTextWordCount) return null;

    return (
        <Tooltip
            overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
            placement='top'
            title='Words currently selected.'
        >
            <Typography
                className={`${classes.wordCounter} ${isAIFeatureEnabled ? classes.active : ''}`}
            >
                {selectedTextWordCount}
                <span>words</span>
            </Typography>
        </Tooltip>
    );
};

export default AIWordCounter;
