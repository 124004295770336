import React from 'react';
import { useSelector } from 'react-redux';

import { Typography, Dropdown } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { useOpen } from '../../../../utils/hooks/useOpen';
import { useIconColor } from '../../../../utils/hooks/useIconColor';

import { colorPalette } from '../../../../resources/styles/colorPalette';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';

const classes = {
    sortWrapper: 'sort-wrapper d-flex flex-center cursor-pointer ml-16px',
    dropdownOverlay: 'dropdown-overlay sort-dropdown-overlay dropdown-menu',
    sortText: 'sort-text d-flex flex-center',
};

const HeaderSort = ({
    sortItems = [{ label: 'Most Recent', key: 'most-recent' }],
    selectedSort = 'most-recent',
    handleSort,
    sortDropdownPrefix = 'Sort by',
}) => {
    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const onSelect = (item) => {
        handleSort(item.key);
        handleOpen();
    };

    const getSelectedItemLabel = () => {
        const selected = sortItems?.find((item) => item?.key === selectedSort);

        return selected?.label || sortItems[0]?.label;
    };

    return (
        <Dropdown
            menu={{
                items: sortItems,
                selectable: true,
                defaultSelectedKeys: [selectedSort],
                selectedKeys: [selectedSort],
                onSelect,
            }}
            trigger={['click']}
            placement={'bottom'}
            overlayStyle={{
                width: 'fit-content',
                minWidth: 'fit-content',
            }}
            overlayClassName={`${classes.dropdownOverlay} 
                               ${darkMode ? 'dropdown-overlay-dark' : ''}`}
            onOpenChange={handleOpen}
        >
            <div className={classes.sortWrapper}>
                <Typography className={classes.sortText}>
                    {sortDropdownPrefix}:
                    <span className='fw-500'>{getSelectedItemLabel()}</span>
                    {isOpen ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )}
                </Typography>
            </div>
        </Dropdown>
    );
};

export default HeaderSort;
