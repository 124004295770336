import React from 'react';

const PlusIcon = ({ color = 'white', size = '20' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 4.16602V15.8327'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M4.16666 10H15.8333'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default PlusIcon;
