import React from 'react';

const CrissCrossIcon = () => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.5 3.5L3.5 10.5'
                stroke='#D71515'
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3.5 3.5L10.5 10.5'
                stroke='#D71515'
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CrissCrossIcon;
