import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Segmented } from 'antd';

import {
    contentOptimizerContentTabOptions,
    contentOptimizerOnlyViewOptions,
} from '../contentOptimizerContentConstants';

import { selectContentOptimizerViewOnlyMode } from '../../viewOnly/store/viewOnlySettings.selectors';
import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setContentOptimizerTabKey } from '../../mainPage/store/contentOptimizerMain.actions';
import { selectContentOptimizerTabKey } from '../../mainPage/store/contentOptimizerMain.selectors';
import { contentOptimizerLSTabKey } from '../../../../../constants/contentOptimizer';

const classes = {
    optimizerContentTabs: 'optimizer-content-tabs',
};

const ContentOptimizerTabSwitch = () => {
    const dispatch = useDispatch();

    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const darkMode = useSelector(selectDarkMode);
    const tabKey = useSelector(selectContentOptimizerTabKey);

    const handleChange = (value) => {
        localStorage.setItem(contentOptimizerLSTabKey, value);
        dispatch(setContentOptimizerTabKey(value));
    };

    return (
        <Segmented
            block
            options={
                viewOnlyMode
                    ? contentOptimizerOnlyViewOptions
                    : contentOptimizerContentTabOptions
            }
            defaultValue={tabKey}
            value={tabKey}
            onChange={handleChange}
            className={`${classes.optimizerContentTabs} ${
                darkMode ? 'dark-mode' : ''
            }`}
        />
    );
};

export default ContentOptimizerTabSwitch;
