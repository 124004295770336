import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import {
    selectKeywordResearchesLimit,
    selectKeywordResearchesUsed,
} from '../billing/store/billingSettings.selectors';

const classes = {
    secondLabel: 'second-label',
};

const UsageKeywordsDiscoveriesCreditsLabel = () => {
    const keywordsLimit = useSelector(selectKeywordResearchesLimit);
    const keywordsUsed = useSelector(selectKeywordResearchesUsed);

    return (
        <Typography className={`${classes.secondLabel}`}>
            {keywordsLimit - keywordsUsed || 0}/{keywordsLimit || 0} keywords
            remaining
        </Typography>
    );
};

export default UsageKeywordsDiscoveriesCreditsLabel;
