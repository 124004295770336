import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';

import RankabilityLoader from '../../../rankabilityLoader/RankabilityLoader';

import { nodeTypes } from '../../constants/nodeTypes';

import { selectAIStateLoading } from '../../store/tiptapEditor.selectors';

const classes = {
    tiptapEditorLoader: 'tiptap-editor-loader',
};

const AISpellCheckLoader = () => {
    const { editor } = useCurrentEditor();

    const aiLoading = useSelector(selectAIStateLoading);

    const interruptedOptimizer = editor?.storage?.[nodeTypes.aiSpellChecker]?.interruptedOptimizer;

    if (!aiLoading) {
        return null;
    }

    return (
        <RankabilityLoader
            absolute
            className={classes.tiptapEditorLoader}
            startedStatus={interruptedOptimizer?.isMatch ? interruptedOptimizer?.progress : 0}
            onProgressChange={(progress) => {
                editor?.commands?.setInterruptedOptimizer({ progress });
            }}
        />
    );
};

export default AISpellCheckLoader;
