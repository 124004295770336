import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Dropdown, List, Progress, Tag, Typography } from 'antd';

import { queryKeywordText } from '../../../../../../../../constants/contentOptimizerKeywordSource';
import {
    isCustomKeywordSource,
    isMainKeywordSource,
} from '../../../../../../../../utils/helpers/getSortedOptimizerKeywords';
import { getOptimizerRightBlockWrapper } from '../optimizerRightBlockHelpers';

import {
    selectDarkMode,
    selectUserOrganizationSettings,
} from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateSelectedKeyword } from '../../../../store/contentOptimizerContent.actions';
import { selectIsMultipleKeywordsSelectionEnabled } from '../../../../store/contentOptimizerContent.selectors';

import OptimizerKeywordDropdownContent from './OptimizerKeywordDropdownContent';

const { Title } = Typography;

const classes = {
    optimizerKeywordsListItemWrapper:
        'optimizer-keywords-list-item-wrapper d-flex justify-content-between w-100 cursor-pointer',
    headingPresenceWrapper: 'heading-presence-wrapper',
    usesWrapper: 'uses-wrapper',
    dropdownOverlay:
        'keyword-info-dropdown-overlay dropdown-with-arrow-overlay dropdown-menu dropdown-overlay-with-extra-content',
    dropdownOverlayDark:
        'dark-mode dropdown-with-arrow-overlay-dark dropdown-overlay-with-extra-content-dark',
};

const OptimizerKeywordsListItem = ({ keywordItem, active, uses }) => {
    const dispatch = useDispatch();
    const listItemRef = useRef(null);
    const dropdownRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);
    const organizationSettings = useSelector(selectUserOrganizationSettings);
    const isMultipleKeywordsSelectionEnabled = useSelector(
        selectIsMultipleKeywordsSelectionEnabled
    );

    const [dropdownTopPosition, setDropdownTopPosition] = useState(0);

    useEffect(() => {
        document
            ?.querySelector('.optimizer-keywords-panel-wrapper')
            ?.addEventListener('scroll', handleScroll);

        return () => {
            document
                ?.querySelector('.optimizer-keywords-panel-wrapper')
                ?.removeEventListener('scroll', handleScroll);
        };

        // eslint-disable-next-line
    }, []);

    const getDropdownTopPosition = (e) => {
        const progressBarHeight = 20;

        const { top } = listItemRef?.current?.getBoundingClientRect();
        const { bottom } = listItemRef?.current?.getBoundingClientRect();

        if (bottom < window?.innerHeight - e?.target?.clientHeight) {
            return (
                window?.innerHeight -
                e?.target?.clientHeight -
                dropdownRef?.current?.offsetHeight / 2 -
                progressBarHeight
            );
        } else if (bottom + progressBarHeight > window?.innerHeight) {
            return window?.innerHeight - dropdownRef?.current?.offsetHeight - progressBarHeight;
        } else {
            return (
                top +
                listItemRef?.current?.offsetHeight / 2 -
                dropdownRef?.current?.offsetHeight / 2
            );
        }
    };

    const handleScroll = (e) => {
        if (listItemRef?.current && dropdownRef?.current) {
            setDropdownTopPosition(getDropdownTopPosition(e));
        }
    };

    const handleOnOpenChange = (event) => {
        if (event) {
            dispatch(updateSelectedKeyword(keywordItem));
        } else {
            dispatch(updateSelectedKeyword(null));
        }
    };

    const getAverageUsageText = (typicalUses) => {
        if (!typicalUses || Math.max(...typicalUses) < 1) {
            return 'n/a';
        }

        return `${
            typicalUses?.[0] === typicalUses?.[1]
                ? typicalUses?.[1]
                : `${typicalUses?.[0]}-${typicalUses?.[1]}`
        } time${typicalUses?.[1] === 1 ? '' : 's'}`;
    };

    return (
        <Dropdown
            arrow
            menu={{
                items: [],
            }}
            onOpenChange={handleOnOpenChange}
            trigger={['click']}
            placement={'left'}
            overlayClassName={`${classes.dropdownOverlay}
                               ${darkMode ? classes.dropdownOverlayDark : ''}`}
            dropdownRender={() => (
                <OptimizerKeywordDropdownContent
                    ref={dropdownRef}
                    keywordItem={{ ...keywordItem, uses }}
                />
            )}
            overlayStyle={{
                top: dropdownTopPosition,
            }}
            getPopupContainer={getOptimizerRightBlockWrapper}
        >
            <List.Item className={`${active ? 'active' : ''}`} ref={listItemRef}>
                <div className={classes.optimizerKeywordsListItemWrapper}>
                    <div className='d-flex align-items-center'>
                        {isMultipleKeywordsSelectionEnabled &&
                            !isMainKeywordSource(keywordItem?.source) && (
                                <Checkbox
                                    value={keywordItem?.keyword}
                                    // prevent dropdown from opening when clicking on the checkbox
                                    onClick={(e) => e.stopPropagation()}
                                />
                            )}
                        <div>
                            <Title level={4}>{keywordItem?.keyword}</Title>
                            <div className={classes.usesWrapper}>
                                <Typography>
                                    {isMainKeywordSource(keywordItem?.source)
                                        ? queryKeywordText
                                        : `Average usage: ${getAverageUsageText(
                                              keywordItem?.typical_uses
                                          )}`}
                                    {organizationSettings?.count_term_uses && uses
                                        ? ` (${uses})`
                                        : ''}
                                </Typography>
                                {isCustomKeywordSource(keywordItem?.source) && (
                                    <Tag color='processing' key={keywordItem?.keyword}>
                                        Custom
                                    </Tag>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Progress percent={keywordItem?.importance * 10 || 0} size='small' />
                        {organizationSettings?.show_presense && (
                            <div className={classes.headingPresenceWrapper}>
                                <Typography>{keywordItem?.heading_presence ? 'H' : ''}</Typography>
                            </div>
                        )}
                    </div>
                </div>
            </List.Item>
        </Dropdown>
    );
};

export default OptimizerKeywordsListItem;
