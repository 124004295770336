import React, { useEffect, useState } from 'react';

import { Table } from 'antd';
import moment from 'moment';

import DeleteAdvancedSettingPopconfirm from './DeleteAdvancedSettingPopconfirm';

const classes = {
    advancedSettingsTable: 'advanced-settings-table',
};

const AdvancedSettingsTable = ({ title, fieldName, advancedSettingsData }) => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(
            advancedSettingsData.map((advancedSetting) => {
                return {
                    key: advancedSetting,
                    value: advancedSetting[title],
                    addedDate: advancedSetting.created_at,
                };
            })
        );

        // eslint-disable-next-line
    }, [advancedSettingsData]);

    const columns = [
        {
            title: title,
            dataIndex: 'value',
            key: 'value',
            width: '50%',
        },
        {
            title: 'ADDED',
            dataIndex: 'addedDate',
            key: 'addedDate',
            render: (addedDate) => {
                return moment(addedDate).format('LL');
            },
        },
        {
            title: null,
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <DeleteAdvancedSettingPopconfirm
                        settingValue={record.value}
                        fieldName={fieldName}
                        title={title}
                    />
                );
            },
        },
    ];

    return (
        <Table
            className={classes.advancedSettingsTable}
            dataSource={tableData}
            columns={columns}
            bordered
            pagination={false}
        />
    );
};

export default AdvancedSettingsTable;
