import React from 'react';

import { Button, Typography } from 'antd';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../utils/hooks/useIconColor';

const classes = {
    keywordFilterLabel: 'keyword-filter-label',
    filterButtonWrapper: 'd-flex align-items-center',
    filterButtonArrow: 'd-flex flex-center',
};

const FilterPopoverButon = ({ label, showArrow, isOpen, handleOpen }) => {
    const iconColor = useIconColor();

    const getIcon = () => {
        if (showArrow) {
            return isOpen ? (
                <SiderChevronUpIcon color={colorPalette.colorPrimary} />
            ) : (
                <SiderChevronDownIcon color={iconColor} />
            );
        } else {
            return null;
        }
    };

    return (
        <Button
            className={classes.filterButtonWrapper}
            onClick={handleOpen}
            icon={getIcon()}
        >
            <Typography
                className={`${classes.keywordFilterLabel}
                            ${isOpen && 'text-active'}`}
            >
                {label}
            </Typography>
        </Button>
    );
};

export default FilterPopoverButon;
