import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import moment from 'moment';

import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import NotificationCard from '../../../common/notifications/NotificationCard';
import RankabilityLoader from '../../../common/rankabilityLoader/RankabilityLoader';

import { setKeywordForUpdate } from '../store/keywordResearchSettings.actions';
import { selectCurrentKeyword } from '../store/keywordResearchSettings.selectors';

import CopyButton from './CopyButton';
import ExportButton from './ExportButton';
import KeywordListTable from './KeywordListTable';

import './KeywordListOfResult.scss';

const classes = {
    root: 'keyword-list-of-results',
    title: 'title',
    textConfiguration: 'text-configuration',
    rankabilityLoaderContainer: 'keywords-rankability-loader-container',
};

const KeywordListOfResult = ({ isListLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const currentKeyword = useSelector(selectCurrentKeyword);

    const organizationSlug = useOrganizationSlug();

    const handleNewSearchClick = () => {
        dispatch(setKeywordForUpdate(currentKeyword?.keyword));
        navigate(`/${organizationSlug}/research`);
    };

    const notificationCardContent = (
        <Typography
            className={`${classes.title} ${darkMode ? 'dark-mode' : ''}`}
        >
            {`The results below were created on ${moment(
                currentKeyword?.created_at
            ).format(
                'MMM DD, YYYY'
            )} and may not reflect Google’s most recent keyword data for "${
                currentKeyword?.keyword
            }". You should consider `}
            <Link onClick={handleNewSearchClick}>performing a new search</Link>{' '}
            for fresh data.
        </Typography>
    );

    return (
        <div className={classes.root}>
            {(currentKeyword?.task_status === 'Ok.' && !isListLoading) ? (
                <>
                    {moment().diff(moment(currentKeyword?.created_at), 'days') >
                        30 && (
                        <div className='w-100'>
                            <NotificationCard
                                content={notificationCardContent}
                            />
                        </div>
                    )}
                    <div className='d-flex w-100 justify-content-between'>
                        <div>
                            <Typography
                                className={`${classes.textConfiguration}
                                            ${classes.title}
                                            ${darkMode ? 'dark-mode' : ''}`}
                            >
                                {/* check for undefined since we need to take zero into account */}
                                {currentKeyword?.filtered_result_count !==
                                undefined
                                    ? `${currentKeyword?.filtered_result_count} of `
                                    : null}
                                {`${
                                    currentKeyword?.result_count || 0
                                } results for "${currentKeyword?.keyword}"`}
                            </Typography>
                        </div>
                        <div className='d-flex'>
                            <CopyButton />
                            <ExportButton />
                        </div>
                    </div>
                    <KeywordListTable isListLoading={isListLoading} />
                </>
            ) : (
                <div className={classes.rankabilityLoaderContainer}>
                    <RankabilityLoader />
                </div>
            )}
        </div>
    );
};

export default KeywordListOfResult;
