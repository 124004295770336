import React from 'react';

import { Typography } from 'antd';

import ExpandedUsersTable from './ExpandedUsersTable';
import ExpandedInvitesTable from './ExpandedInvitesTable';

const { Title } = Typography;

const AdminUsersExpandedRow = ({ record }) => {
    const usersData = record?.users?.map((user) => ({
        ...user,
        key: user?.id,
        id: user?.id,
        full_name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
        role: user?.roles?.[0]?.name,
        active: user?.active,
    }));

    const invitesData = record?.invites?.map((invite) => ({
        ...invite,
        key: invite?.id,
        id: invite?.id,
        email: invite?.email,
        role: invite?.role?.name,
    }));

    return (
        <>
            {usersData.length > 0 && (
                <>
                    <Title level={5}>Users</Title>
                    <ExpandedUsersTable usersData={usersData} />
                </>
            )}
            {invitesData.length > 0 && (
                <>
                    <Title level={5}>Invites</Title>
                    <ExpandedInvitesTable invitesData={invitesData} />
                </>
            )}
        </>
    );
};

export default AdminUsersExpandedRow;
