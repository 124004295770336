export const contentOptimizerMainSortOptions = {
    recent: {
        label: 'Recently Created',
        key: 'recent',
        queryParams: {
            sort: 'created_at',
            order: 'desc',
        },
    },
    edited: {
        label: 'Recently Edited',
        key: 'edited',
        queryParams: {
            sort: 'updated_at',
            order: 'desc',
        },
    },
    scoredHigh: {
        label: 'Highest Score',
        key: 'scoredHigh',
        queryParams: {
            sort: 'rankability_score',
            order: 'desc',
        },
    },
    scoredLow: {
        label: 'Lowest Score',
        key: 'scoredLow',
        queryParams: {
            sort: 'rankability_score',
            order: 'asc',
        },
    },
};

export const contentOptimizerMainSortItems = [
    { label: 'Recently Created', key: 'recent' },
    { label: 'Recently Edited', key: 'edited' },
    { label: 'Highest Score', key: 'scoredHigh' },
    { label: 'Lowest Score', key: 'scoredLow' },
];
