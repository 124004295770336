import React from 'react';

import { Radio, Typography } from 'antd';

const { Title } = Typography;

const classes = {
    modalSubtitle: 'modal-subtitle',
    radioWrapper: 'form-radio-wrapper',
    radioDescription: 'radio-description',
};

const UserRoleRadioItem = ({ value, label, description }) => {
    return (
        <Radio value={value}>
            <div className={classes.radioWrapper}>
                <Title
                    level={5}
                    className={classes.modalSubtitle}
                >
                    {label}
                </Title>
                <Typography className={classes.radioDescription}>{description}</Typography>
            </div>
        </Radio>
    );
};

export default UserRoleRadioItem;
