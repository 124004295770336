import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { organizationPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';
import { notificationType } from '../../constants/notificationType';

import { selectUsers } from '../../components/pages/account/store/accountSettings.selectors';
import { setUsers } from '../../components/pages/account/store/accountSettings.actions';

import { selectOrganizationId } from '../../userBrowserSettings/store/browserSettings.selectors';

import { openNotification } from '../helpers/openNotification';
import { getAxiosHeaders } from '../helpers/getAxiosHeaders';

import { axiosAPI } from '../axiosAPI';

export const useUsers = () => {
    const dispatch = useDispatch();

    const users = useSelector(selectUsers);
    const organizationId = useSelector(selectOrganizationId);

    const [isUsersLoaded, setIsUsersLoaded] = useState(false);

    useEffect(() => {
        if (users.length < 1 && organizationId) {
            setIsUsersLoaded(true);

            axiosAPI
                .get(`${organizationPathes.getOrganization}${organizationId}`, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setUsers(result.data?.users));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        'Looks like something went wrong while loading users. Please try again later'
                    );
                })
                .finally(() => {
                    setIsUsersLoaded(false);
                });
        }

        // eslint-disable-next-line
    }, []);

    return { users, isUsersLoaded };
};
