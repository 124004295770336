import React, { useState } from 'react';

import { Select } from 'antd';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../utils/hooks/useIconColor';

import { monthlySearchesTrendOptions } from './trendFilterOptions';

const TrendDirectionSelect = ({ selectValue, handleChange }) => {
    const iconColor = useIconColor();

    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const handleSelectOpen = (open) => {
        setIsSelectOpen(open);
    };

    const handleSelect = (value) => {
        handleChange('trend', value);
    };

    return (
        <Select
            value={selectValue || null}
            placeholder='Trend direction'
            className='trend-direction-select'
            showSearch={false}
            options={monthlySearchesTrendOptions}
            suffixIcon={
                isSelectOpen ? (
                    <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                ) : (
                    <SiderChevronDownIcon color={iconColor} />
                )
            }
            popupClassName='keyword-filter-select'
            onDropdownVisibleChange={handleSelectOpen}
            onSelect={handleSelect}
        />
    );
};

export default TrendDirectionSelect;
