import React from 'react';

import { EditorProvider } from '@tiptap/react';

import { tiptapEditorExtensions } from '../../../../../../../../../../common/tiptapEditor/extensions/tiptapEditorExtensions';
import { defaultExtractContentEditorContent } from '../../../../../../../../../../common/tiptapEditor/toolbar/constants';

const ExtractContentEditor = ({
    importedData,
    setConvertedImportedHtmlData,
}) => {
    const handleEditorReady = (editorData) => {
        const { editor } = editorData;

        setConvertedImportedHtmlData(editor?.getHTML());
    };

    return (
        <EditorProvider
            editable={false}
            extensions={tiptapEditorExtensions}
            content={importedData || defaultExtractContentEditorContent}
            onCreate={handleEditorReady}
        />
    );
};

export default ExtractContentEditor;
