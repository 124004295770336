import { getOperationSystem } from '../../../utils/helpers/operationSystem';

export const OUTLINE_BUTTONS_TITLES = {
    moveUp: 'Move up',
    moveDown: 'Move down',
    promoteHeading: 'Promote heading',
    demoteHeading: 'Demote heading',
    addNewHeading: 'Add new heading',
    bulletPoint: 'Bullet point',
    edit: 'Edit',
    delete: 'Delete',
};

export const OUTLINE_WINDOWS_SUBTITLE_HOTKEYS = {
    [OUTLINE_BUTTONS_TITLES.moveUp]: 'Up arrow',
    [OUTLINE_BUTTONS_TITLES.moveDown]: 'Down arrow',
    [OUTLINE_BUTTONS_TITLES.promoteHeading]: 'Left arrow',
    [OUTLINE_BUTTONS_TITLES.demoteHeading]: 'Right arrow',
    [OUTLINE_BUTTONS_TITLES.addNewHeading]: 'Enter',
    [OUTLINE_BUTTONS_TITLES.bulletPoint]: 'Ctrl+Shift+8',
    [OUTLINE_BUTTONS_TITLES.delete]: 'Backspace or Delete',
};

export const OUTLINE_IOS_SUBTITLE_HOTKEYS = {
    [OUTLINE_BUTTONS_TITLES.moveUp]: 'Up arrow',
    [OUTLINE_BUTTONS_TITLES.moveDown]: 'Down arrow',
    [OUTLINE_BUTTONS_TITLES.promoteHeading]: 'Left arrow',
    [OUTLINE_BUTTONS_TITLES.demoteHeading]: 'Right arrow',
    [OUTLINE_BUTTONS_TITLES.addNewHeading]: 'Enter',
    [OUTLINE_BUTTONS_TITLES.bulletPoint]: '⌘+Shift+8',
    [OUTLINE_BUTTONS_TITLES.delete]: 'Backspace or Delete',
};

export const getOutlineButtonsSubtitle = (buttonName) => {
    if (!buttonName) {
        return null;
    }

    if (getOperationSystem() === 'Windows') {
        return OUTLINE_WINDOWS_SUBTITLE_HOTKEYS[buttonName] || null;
    } else {
        return OUTLINE_IOS_SUBTITLE_HOTKEYS[buttonName] || null;
    }
};
