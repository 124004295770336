import React from 'react';
import { Empty, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getRandomId } from '../../../../../../../../../utils/helpers/idGenerator';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { appendToCurrentOutline } from '../store/contentBriefsOutline.actions';
import { selectBriefQuestions } from '../store/contentBriefsOutline.selectors';

import CopyItem from '../../../../../../../../common/copyItem/CopyItem';
import AddButton from '../../../../../../../../common/outlineTags/AddButton';

const { Title } = Typography;

const classes = {
    questionsSection: 'brief-section questions-section',
    sectionTitle: 'section-title d-flex align-items-center',
    copyIcon: 'copy-icon',
    questionsWrapper: 'questions-wrapper',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    questionsTooltipOverlay: 'tooltip-overlay questions-tooltip-overlay',
    textDotted: 'text-decoration-dotted',
    addButtonWrapper: 'add-button-wrapper',
};

const BriefQuestionsSection = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(selectDarkMode);
    const briefsQuestions = useSelector(selectBriefQuestions);

    const getCopiedText = () => {
        if (briefsQuestions) {
            return briefsQuestions.join('\n');
        }

        return [];
    };

    const handleAddRowClick = (index) => {
        dispatch(
            appendToCurrentOutline({
                title: briefsQuestions[index],
                tag: 'h2',
                id: getRandomId(),
            })
        );
    };

    return (
        <section className={classes.questionsSection}>
            <div className={classes.sectionTitle}>
                <Tooltip
                    overlayClassName={`${classes.questionsTooltipOverlay}
                                       ${
                                           darkMode &&
                                           classes.tooltipOverlayDark
                                       }`}
                    title='Frequently asked questions by Google users searching for your primary keyword. Can provide a rich source of relevant topics and questions to cover in your content.'
                    placement='topLeft'
                >
                    <Title level={5} className={classes.textDotted}>
                        People Also Ask
                    </Title>
                </Tooltip>
                <CopyItem copiedText={getCopiedText()} />
            </div>
            {briefsQuestions?.length > 0 ? (
                <div
                    className={`${classes.questionsWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    {briefsQuestions.map((question, index) => (
                        <div
                            key={index}
                            className='question-item'
                            onClick={() => handleAddRowClick(index)}
                        >
                            <Typography>{question}</Typography>
                            <div className={classes.addButtonWrapper}>
                                <AddButton />
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </section>
    );
};

export default BriefQuestionsSection;
