import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Progress, Typography } from 'antd';

import { colorPalette } from '../../../resources/styles/colorPalette';

import { getThemeColorClass } from '../../../utils/helpers/themeHelpers';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import RankabilityDarkLogo from './RankabilityDarkLogo';
import RankabilityLightLogo from './RankabilityLightLogo';

import { ContentOptimizerQueueStatus } from '../../../constants/contentOptimizer';

import './RankabilityLoader.scss';

const classes = {
    rankabilityLoader: 'rankability-loader',
    percentageIndicator: 'percentage-indicator',
    progressLineWrapper: 'progress-line-wrapper',
    absolute: 'rankability-loader-absolute',
};

const RankabilityLoader = ({
    percentageStatus = 99,
    startedStatus = 0,
    fullWidth,
    customWidth,
    optimizerStatus,
    absolute = false,
    className = '',
    onProgressChange,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const [completed, setCompleted] = useState(startedStatus);
    const [completedLogo, setCompletedLogo] = useState(0);

    useEffect(() => {
        if (onProgressChange) {
            onProgressChange(completed);
        }
    }, [completed, onProgressChange]);

    useEffect(() => {
        if (optimizerStatus !== ContentOptimizerQueueStatus.QUEUED) {
            const updatedCount = completed + Math.random() * 4;

            let newCompleted =
                updatedCount < 100
                    ? updatedCount > percentageStatus
                        ? completed + 0.1
                        : updatedCount
                    : 100;

            newCompleted = parseFloat(newCompleted.toFixed(1));

            const timeout = setTimeout(() => {
                setCompleted((prevCompleted) =>
                    prevCompleted > newCompleted ? prevCompleted : newCompleted
                );
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, [completed, percentageStatus, optimizerStatus]);

    useEffect(() => {
        if (optimizerStatus !== ContentOptimizerQueueStatus.QUEUED) {
            const timeout = setTimeout(
                () => setCompletedLogo(completedLogo < 100 ? completedLogo + 6 : 4),
                100
            );

            return () => clearTimeout(timeout);
        }
    }, [completedLogo, optimizerStatus]);

    const getProgress = (progress) => {
        return optimizerStatus !== ContentOptimizerQueueStatus.QUEUED ? progress : 0;
    };

    return (
        <div
            className={`${classes.rankabilityLoader} ${
                absolute ? classes.absolute : ''
            } ${className}`}
            style={{ width: customWidth || (fullWidth && '100%') }}
        >
            {darkMode ? (
                <RankabilityDarkLogo progress={getProgress(completedLogo)} />
            ) : (
                <RankabilityLightLogo progress={getProgress(completedLogo)} />
            )}
            <div className={classes.progressLineWrapper} style={{ width: fullWidth && '100%' }}>
                <Progress
                    percent={getProgress(completed)}
                    showInfo={false}
                    status='active'
                    strokeColor={colorPalette.colorPrimary}
                    size='small'
                />
            </div>

            <Typography
                className={`${classes.percentageIndicator} ${getThemeColorClass(darkMode)}`}
            >
                {optimizerStatus !== ContentOptimizerQueueStatus.QUEUED
                    ? `${completed.toFixed(1)}%`
                    : `Queued...`}
            </Typography>
        </div>
    );
};

export default RankabilityLoader;
