import { useDispatch, useSelector } from 'react-redux';

import { GoogleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';

import { difficultyBadgeSize } from '../../../../../../../constants/difficultyBadgeSize';
import { rankabilityScoreCategories } from '../../../../../../../constants/rankabilityScoreCategories';
import { sortOrders } from '../../../../../../../constants/sortOrders';

import ContentOptimizerIcon from '../../../../../../../resources/icons/ContentOptimizer';
import DecreaseArrowIcon from '../../../../../../../resources/icons/DecreaseArrowIcon';
import IncreaseArrowIcon from '../../../../../../../resources/icons/IncreaseArrowIcon';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getNewOptimizerUrl } from '../../../../../../../utils/helpers/getNewOptimizerUrl';
import { useOrganizationSlug } from '../../../../../../../utils/hooks/useOrganizationSlug';

import CompleteBadge from '../../../../../../common/completeBadge/CompleteBadge';
import FlagCountrySection from '../../../../../../common/mainPagesLists/FlagCountrySection';
import TableInfoModal from '../../../../../../common/tableInfo/TableInfoModal';
import TableHeaderSortIcons from '../../../../../../common/tables/TableHeaderSortIcons';
import MonitorPageRowColumn from './MonitorPageRowColumn';

import { monitorsSortKeys } from '../constants';

import { updateMonitorsSortKey } from '../../../store/contentMonitorMain.actions';
import {
    selectMonitorsSortDescending,
    selectMonitorsSortKey,
} from '../../../store/contentMonitorMain.selectors';

const { Link } = Typography;

const classes = {
    tableHeaderTitle:
        'd-flex align-items-center competitors-table-header-title cursor-pointer space-no-wrap',
    monitoredPageTitle: 'monitored-page-title',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    active: 'active',
    textDotted: 'text-decoration-dotted',

    reletedSearches: 'd-flex',
    actionsButtons: 'actions-buttons',
    magicPen: 'magic-pen-icon',

    alignValue: 'd-flex align-items-center',
    disabledText: 'monitor-disabled-text',
};

export const useContentMonitorTableColumns = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const monitorsSortKey = useSelector(selectMonitorsSortKey);
    const monitorsSortDescending = useSelector(selectMonitorsSortDescending);

    const organizationSlug = useOrganizationSlug();

    const handleSorting = (sortKey) => () => {
        dispatch(updateMonitorsSortKey(sortKey));
    };

    const columns = [
        {
            title: (
                <div
                    className={`${classes.monitoredPageTitle} ${
                        classes.tableHeaderTitle
                    } ${darkMode ? 'dark-mode' : ''} ${
                        monitorsSortKey === monitorsSortKeys.monitoredPage
                            ? classes.active
                            : ''
                    }`}
                    onClick={handleSorting(monitorsSortKeys.monitoredPage)}
                >
                    MONITORED PAGE
                    <TableHeaderSortIcons
                        active={
                            monitorsSortKey === monitorsSortKeys.monitoredPage
                        }
                        order={
                            monitorsSortDescending
                                ? sortOrders.asc
                                : sortOrders.desc
                        }
                    />
                </div>
            ),
            dataIndex: 'monitoredPage',
            key: 'monitoredPage',
            width: '41%',
            fixed: 'left',
            render: (_, record) => <MonitorPageRowColumn record={record} />,
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    } ${
                        monitorsSortKey === monitorsSortKeys.monitoredKeyword &&
                        classes.active
                    }`}
                    onClick={handleSorting(monitorsSortKeys.monitoredKeyword)}
                >
                    MONITORED KEYWORD
                    <TableHeaderSortIcons
                        active={
                            monitorsSortKey ===
                            monitorsSortKeys.monitoredKeyword
                        }
                        order={
                            monitorsSortDescending
                                ? sortOrders.asc
                                : sortOrders.desc
                        }
                    />
                </div>
            ),
            dataIndex: 'monitoredKeyword',
            key: 'monitoredKeyword',
            width: '23%',
            render: (_, record) => {
                return (
                    <div className={`${classes.reletedSearches} `}>
                        <p
                            className={
                                record?.disabled ? classes.disabledText : ''
                            }
                        >
                            {record?.monitoredKeyword}
                        </p>
                        <FlagCountrySection
                            countryCode={record?.monitoredPage?.country_code}
                            languageCode={record?.monitoredPage?.language_code}
                        />
                        {!record?.disabled && (
                            <>
                                <Tooltip
                                    placement='top'
                                    title={
                                        'Open Google search results for this keyword in a new tab.'
                                    }
                                    trigger={['hover']}
                                    overlayClassName={`${classes.tooltipOverlay}
                                                   ${
                                                       darkMode
                                                           ? classes.tooltipOverlayDark
                                                           : ''
                                                   }`}
                                >
                                    <Link
                                        href={`https://www.google.com/search?q=${record?.monitoredKeyword.replace(
                                            ' ',
                                            '+'
                                        )}`}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={classes.actionsButtons}
                                    >
                                        <GoogleOutlined />
                                    </Link>
                                </Tooltip>
                                <Tooltip
                                    placement='top'
                                    title={
                                        'Open a new Optimizer for this keyword in a new tab.'
                                    }
                                    trigger={['hover']}
                                    overlayClassName={`${classes.tooltipOverlay}
                                                   ${
                                                       darkMode
                                                           ? classes.tooltipOverlayDark
                                                           : ''
                                                   }`}
                                >
                                    <Link
                                        href={getNewOptimizerUrl(
                                            organizationSlug, record?.monitoredKeyword
                                        )}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={`${classes.actionsButtons} ${classes.magicPen}`}
                                    >
                                        <ContentOptimizerIcon size={14.5} />
                                    </Link>
                                </Tooltip>
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    } ${
                        monitorsSortKey ===
                            monitorsSortKeys.optimalRankabilityScore &&
                        classes.active
                    }`}
                    onClick={handleSorting(
                        monitorsSortKeys.optimalRankabilityScore
                    )}
                >
                    <Tooltip
                        title={
                            'Optimal Rankability Score of the top-ranked competitors on Google for the monitored keyword. Updated every three months.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>OPTIMAL RS</div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Rankability Score'}
                        infoCategories={rankabilityScoreCategories}
                    />
                    <TableHeaderSortIcons
                        active={
                            monitorsSortKey ===
                            monitorsSortKeys.optimalRankabilityScore
                        }
                        order={
                            monitorsSortDescending
                                ? sortOrders.asc
                                : sortOrders.desc
                        }
                    />
                </div>
            ),
            dataIndex: 'optimalRankabilityScore',
            key: 'optimalRankabilityScore',
            width: '8%',
            render: (_, record) => {
                return (
                    <CompleteBadge
                        size={difficultyBadgeSize.small}
                        progress={record?.optimalRankabilityScore}
                    />
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    } ${
                        monitorsSortKey === monitorsSortKeys.rankabilityScore &&
                        classes.active
                    }`}
                    onClick={handleSorting(monitorsSortKeys.rankabilityScore)}
                >
                    <Tooltip
                        title={
                            'Rankability Score of the monitored page for the monitored keyword. Updated daily.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                               ${
                                                   darkMode
                                                       ? classes.tooltipOverlayDark
                                                       : ''
                                               }`}
                    >
                        <div className={classes.textDotted}>PAGE RS</div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Rankability Score'}
                        infoCategories={rankabilityScoreCategories}
                    />
                    <TableHeaderSortIcons
                        active={
                            monitorsSortKey ===
                            monitorsSortKeys.rankabilityScore
                        }
                        order={
                            monitorsSortDescending
                                ? sortOrders.asc
                                : sortOrders.desc
                        }
                    />
                </div>
            ),
            dataIndex: 'rankabilityScore',
            key: 'rankabilityScore',
            width: '8%',
            render: (_, record) => {
                return (
                    <CompleteBadge
                        size={difficultyBadgeSize.small}
                        progress={record?.rankabilityScore}
                    />
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    } ${
                        monitorsSortKey === monitorsSortKeys.change &&
                        classes.active
                    }`}
                    onClick={handleSorting(monitorsSortKeys.change)}
                >
                    <Tooltip
                        title={
                            'Measures the Page RS change compared to the previous week. Updated daily.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>CHANGE</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={monitorsSortKey === monitorsSortKeys.change}
                        order={
                            monitorsSortDescending
                                ? sortOrders.asc
                                : sortOrders.desc
                        }
                    />
                </div>
            ),
            dataIndex: 'change',
            key: 'change',
            width: '8%',
            render: (_, record) => {
                return (
                    <div className={classes.alignValue}>
                        <div className='mr-4px'>
                            {record?.change > 0 && '+'}
                            {record?.change || '—'}
                        </div>
                        {record?.change > 0 && <IncreaseArrowIcon />}
                        {record?.change < 0 && <DecreaseArrowIcon />}
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    } ${
                        monitorsSortKey === monitorsSortKeys.added &&
                        classes.active
                    }`}
                    onClick={handleSorting(monitorsSortKeys.added)}
                >
                    <div>ADDED</div>

                    <TableHeaderSortIcons
                        active={monitorsSortKey === monitorsSortKeys.added}
                        order={
                            monitorsSortDescending
                                ? sortOrders.asc
                                : sortOrders.desc
                        }
                    />
                </div>
            ),
            dataIndex: 'added',
            key: 'added',
            width: '12%',
            render: (_, record) => {
                return (
                    <p className={record?.disabled ? classes.disabledText : ''}>
                        {record?.added}
                    </p>
                );
            },
        },
    ];

    return columns;
};
