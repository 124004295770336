export const getBillingPlanLabel = (billingPlanName) => {
    const planWord = 'plan';
    const subscriptionWord = 'subscription';

    if (billingPlanName?.toLowerCase().indexOf(planWord) !== -1) {
        const planIndex = billingPlanName?.toLowerCase().indexOf(planWord);

        return billingPlanName.slice(0, planIndex);
    } else if (
        billingPlanName?.toLowerCase().indexOf(subscriptionWord) !== -1
    ) {
        const subscriptionIndex = billingPlanName
            ?.toLowerCase()
            .indexOf(subscriptionWord);

        return billingPlanName.slice(0, subscriptionIndex);
    } else {
        return billingPlanName;
    }
};
