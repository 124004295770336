import React from 'react';

import { Button } from 'antd';

import { useOpen } from '../../../utils/hooks/useOpen';

import ManageProjectModal from './ManageProjectModal';

const classes = {
    manageProjectBtn: 'checked-items-actions-panel-btn manage-project-btn',
};

const ManageProjectModalWrapper = ({
    getOptimizers,
    checkedItems,
    selectedProjects,
    handleUpdateProjects,
}) => {
    const { isOpen, handleOpen } = useOpen();

    return (
        <>
            <Button
                className={classes.manageProjectBtn}
                type='primary'
                ghost
                onClick={handleOpen}
            >
                Manage Project
            </Button>
            <ManageProjectModal
                getOptimizers={getOptimizers}
                isOpen={isOpen}
                handleOpen={handleOpen}
                checkedItems={checkedItems}
                selectedProjects={selectedProjects}
                handleUpdateProjects={handleUpdateProjects}
            />
        </>
    );
};

export default ManageProjectModalWrapper;
