import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Fireworks } from 'fireworks-js';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { usePrevious } from '../../../../../utils/hooks/usePrevious';

import { selectCurrentEditorVersion } from './optimizerTab/editor/store/contentOptimizerEditor.selectors';
import { calculateOptimalRankabilityScore } from '../../../../../utils/helpers/getOptimizerBasicData';

const FireworksContainer = () => {
    const fireworksRef = useRef(null);

    const currentOptimizerVersion = useSelector(selectCurrentEditorVersion);
    const darkMode = useSelector(selectDarkMode);

    const prevRankabilityScore = usePrevious(
        currentOptimizerVersion?.rankability_score
    );
    const optimalRankabilityScore = calculateOptimalRankabilityScore(
        currentOptimizerVersion?.optimal_rankability_score?.max
    );

    const [showFireworks, setShowFireworks] = useState(false);

    useEffect(() => {
        if (
            currentOptimizerVersion &&
            currentOptimizerVersion?.rankability_score >=
                optimalRankabilityScore &&
            (prevRankabilityScore || prevRankabilityScore === 0) &&
            prevRankabilityScore < optimalRankabilityScore &&
            !showFireworks
        ) {
            setShowFireworks(true);
        }

        // eslint-disable-next-line
    }, [currentOptimizerVersion]);

    useEffect(() => {
        if (fireworksRef.current) {
            const fireworks = new Fireworks(fireworksRef.current, {
                rocketsPoint: {
                    min: 20,
                    max: 80,
                },
                hue: {
                    min: darkMode ? 220 : 224,
                    max: darkMode ? 220 : 224,
                },
                acceleration: 1.0,
                brightness: {
                    min: darkMode ? 35 : 20,
                    max: darkMode ? 40 : 25,
                },
                decay: {
                    min: 0.01,
                    max: 0.025,
                },
                delay: {
                    min: 10,
                    max: 25,
                },
                explosion: 6,
                particles: 120,
                traceLength: 1.0,
                flickering: 50,
                traceSpeed: 100,
                gravity: 2.15,
                lineWidth: {
                    explosion: {
                        min: 0.75,
                        max: 1.15,
                    },
                    trace: {
                        min: 0.01,
                        max: 0.01,
                    },
                },
            });

            fireworks.launch(2);
            setTimeout(() => {
                fireworks.launch(2);
            }, 400);
            setTimeout(() => {
                fireworks.launch(1);
            }, 800);
            setTimeout(() => {
                fireworks.launch(1);
            }, 1100);
            setTimeout(() => {
                fireworks.launch(1);
            }, 1700);
            setTimeout(() => {
                fireworks.launch(2);
            }, 2500);
            setTimeout(() => {
                fireworks.launch(2);
            }, 3250);
            setTimeout(() => {
                fireworks.launch(4);
            }, 3750);
            setTimeout(() => {
                fireworks.launch(5);
            }, 3900);
            setTimeout(() => {
                fireworks.launch(5);
            }, 4250);

            setTimeout(() => {
                fireworks.clear();
                setShowFireworks(false);
            }, 7000);
        }

        // eslint-disable-next-line
    }, [fireworksRef.current, showFireworks]);

    return (
        <>
            {showFireworks && (
                <div ref={fireworksRef} id='fireworks-container' />
            )}
        </>
    );
};

export default FireworksContainer;
