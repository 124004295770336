import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { elementsStyles } from '../../../resources/styles/elementsStyles';

import { useCollapse } from '../../../utils/hooks/useCollapse';
import { useCurrentSubscription } from '../../../utils/hooks/useCurrentSubscription';

import Sidebar from '../sidebar/Sidebar';

const classes = {
    layoutWrapper: 'min-h-100 layout-wrapper',
};

const ProtectedLayout = () => {
    const { collapsed, handleCollapse } = useCollapse();

    // eslint-disable-next-line
    const _currentSubscription = useCurrentSubscription();

    return (
        <Layout className={classes.layoutWrapper}>
            <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />
            <Layout
                className='site-layout'
                style={{
                    left: collapsed
                        ? `${elementsStyles.Sider.collapsedWidth}px`
                        : `${elementsStyles.Sider.width}px`,
                    width: collapsed
                        ? `calc(100% - ${elementsStyles.Sider.collapsedWidth}px)`
                        : `calc(100% - ${elementsStyles.Sider.width}px)`,
                }}
            >
                <Outlet />
            </Layout>
        </Layout>
    );
};

export default ProtectedLayout;
