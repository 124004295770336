import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    selectContentOptimizerKeywords,
    selectKeywordsSearchKey,
    selectKeywordsSortKey,
} from '../../components/pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';

import { getSortedOptimizerKeywords } from '../helpers/getSortedOptimizerKeywords';

import { useHighlightedKeywords } from './useHighlightedKeywords';

export const useSortedOptimizerKeywords = () => {
    const keywords = useSelector(selectContentOptimizerKeywords);
    const sortKey = useSelector(selectKeywordsSortKey);
    const searchKey = useSelector(selectKeywordsSearchKey);
    const searchText = searchKey?.toLowerCase().trim();
    const highlightedKeywords = useHighlightedKeywords();

    const sortedKeywords = useMemo(() => {
        if (keywords) {
            const filteredKeywords =
                searchText && searchText.length
                    ? keywords.filter(
                          (item) =>
                              item.keyword.toLowerCase().includes(searchText) ||
                              item.keyword
                                  .toLowerCase()
                                  .split(' ')
                                  .some((keywordItem) =>
                                      keywordItem.includes(searchText)
                                  )
                      )
                    : keywords;

            return getSortedOptimizerKeywords(
                filteredKeywords.map((keyword) => {
                    const usesCount = highlightedKeywords.find(
                        (highlightedKeyword) =>
                            highlightedKeyword.keyword === keyword.keyword
                    )?.uses;

                    return {
                        ...keyword,
                        used: usesCount > 0,
                        usesCount,
                    };
                }),
                sortKey
            );
        } else {
            return [];
        }
    }, [keywords, highlightedKeywords, sortKey, searchText]);

    return sortedKeywords;
};
