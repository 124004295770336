import React from 'react';

const SearchIcon = ({ color = '#728192' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.2123 12.4496L16.7893 16.1626C17.0919 16.4768 17.0819 16.9776 16.7674 17.2796C16.6194 17.4218 16.4244 17.5 16.2189 17.5C16.0018 17.5 15.7991 17.4139 15.6485 17.2578L12.0444 13.5166C11.021 14.2344 9.82404 14.6125 8.56797 14.6125C5.22214 14.6125 2.5 11.8956 2.5 8.55624C2.5 5.21688 5.22214 2.5 8.56797 2.5C11.9138 2.5 14.6359 5.21688 14.6359 8.55624C14.6359 9.98552 14.132 11.3584 13.2123 12.4496ZM13.0524 8.55594C13.0524 6.08763 11.0404 4.07959 8.56736 4.07959C6.09426 4.07959 4.08234 6.08763 4.08234 8.55594C4.08234 11.0243 6.09426 13.0323 8.56736 13.0323C11.0404 13.0323 13.0524 11.0243 13.0524 8.55594Z'
                fill={color}
            />
        </svg>
    );
};

export default SearchIcon;
