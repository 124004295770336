export const subscriptionLimits = {
    userSeats: 'user_seats',
    userSeatsUsed: 'user_seats_used',
    keywordResearch: 'keyword_researches',
    keywordResearchUsed: 'keyword_researches_used',
    optimizers: 'optimizers',
    optimizersUsed: 'optimizers_used',
    optimizersAdded: 'optimizers_added',

    contentMonitors: 'content_monitors',
    contentMonitorsUsed: 'content_monitors_used',

    openaiBriefs: 'openai_briefs',
    openaiBriefsUsed: 'openai_briefs_used',
    openaiBriefsAdded: 'openai_briefs_added',
};
