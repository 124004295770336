export const selectContentMonitorMainTab = (state) =>
    state?.contentMonitor?.contentMonitorMain?.contentMonitorMainTab;
export const selectContentMonitorData = (state) =>
    state?.contentMonitor?.contentMonitorMain?.contentMonitorData?.filter(
        (monitor) => !monitor?.canceled
    );
export const selectContentMonitorsChecked = (state) =>
    state?.contentMonitor?.contentMonitorMain?.contentMonitorsChecked;
export const selectContentMonitorMainTotalCount = (state) =>
    state?.contentMonitor?.contentMonitorMain?.totalCount;
export const selectContentMonitorsCount = (state) =>
    state?.contentMonitor?.contentMonitorMain?.monitorsCount;

export const selectIsAddURLsActive = (state) =>
    state?.contentMonitor?.contentMonitorMain?.isAddURLsActive;

export const selectContentMonitorProjects = (state) =>
    state?.contentMonitor?.contentMonitorMain?.projects;

export const selectContentMonitorQueryParams = (state) =>
    state?.contentMonitor?.contentMonitorMain?.queryParams;

export const selectMonitorsSortKey = (state) =>
    state?.contentMonitor?.contentMonitorMain?.monitorsTableFilters
        ?.monitorsSortKey;

export const selectMonitorsSortDescending = (state) =>
    state?.contentMonitor?.contentMonitorMain?.monitorsTableFilters
        ?.monitorsSortDescending;

export const selectContentMonitorChartData = (state) =>
    state?.contentMonitor?.contentMonitorMain?.contentMonitorChartData;
