import React from 'react';

const ErrorMessageIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle
                opacity='0.25'
                cx='20'
                cy='20'
                r='20'
                fill='#EF3E32'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.0982 10.382L9.58766 24.4828C9.2306 25.0965 9.22847 25.8521 9.58206 26.4678C9.93565 27.0835 10.5919 27.4667 11.3058 27.4745H28.3269C29.0409 27.4667 29.6971 27.0835 30.0507 26.4678C30.4043 25.8521 30.4021 25.0965 30.0451 24.4828L21.5346 10.382C21.1703 9.78589 20.5188 9.42188 19.8164 9.42188C19.1139 9.42188 18.4625 9.78589 18.0982 10.382Z'
                fill='#EF3E32'
                stroke='white'
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.8159 15.5078V19.4967'
                stroke='white'
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.8167 23.4859H19.8268'
                stroke='white'
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ErrorMessageIcon;
