import React from 'react';

const GalleryIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.49935 6.66667C7.23573 6.66667 7.83268 6.06971 7.83268 5.33333C7.83268 4.59695 7.23573 4 6.49935 4C5.76297 4 5.16602 4.59695 5.16602 5.33333C5.16602 6.06971 5.76297 6.66667 6.49935 6.66667Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.16732 1.33301H6.50065C3.16732 1.33301 1.83398 2.66634 1.83398 5.99967V9.99967C1.83398 13.333 3.16732 14.6663 6.50065 14.6663H10.5007C13.834 14.6663 15.1673 13.333 15.1673 9.99967V6.66634'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M11 3.33301H14.6667'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M12.834 5.16667V1.5'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M2.2793 12.634L5.56596 10.4274C6.09263 10.074 6.85263 10.114 7.32596 10.5207L7.54596 10.714C8.06596 11.1607 8.90597 11.1607 9.42597 10.714L12.1993 8.33402C12.7193 7.88736 13.5593 7.88736 14.0793 8.33402L15.166 9.26736'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
            />
        </svg>
    );
};

export default GalleryIcon;
