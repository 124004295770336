export const checkAreBriefsAssignedToProjectNearCurrent = (briefId, briefs) => {
    const index = briefs.findIndex((brief) => brief.id === briefId);

    let slicedBriefs = [];

    // Check on which row and position the brief is located to determine which briefs to check
    if (index % 4 === 0 || index === 0) {
        // If the brief is on the first row, check the next 3 briefs

        slicedBriefs = briefs.slice(index, index + 4);
    } else if (index % 4 === 1) {
        // If the brief is on the second row, check the previous 1 and next 2 briefs

        slicedBriefs = briefs.slice(index - 1, index + 3);
    } else if (index % 4 === 2) {
        // If the brief is on the third row, check the previous 2 and next 1 briefs

        slicedBriefs = briefs.slice(index - 2, index + 2);
    } else if (index % 4 === 3) {
        // If the brief is on the fourth row, check the previous 3 briefs

        slicedBriefs = briefs.slice(index - 3, index + 1);
    }

    // Check if any of the briefs in the array are assigned to the current project
    const areBriefsAssignedToProjectNearCurrent = slicedBriefs.some((brief) =>
        brief.projects
            ? brief.projects.length > 0
            : brief?.content_brief?.projects?.length > 0
    );

    return areBriefsAssignedToProjectNearCurrent;
};
