export const rankabilityScoreCategories = [
    {
        min: 96,
        max: 100,
        category: 'Ultra Relevant',
        color: '#1EA11D',
        key: 'Ultra-relevant',
        shift: 0,
    },
    {
        min: 80,
        max: 95,
        category: 'Extremely Relevant',
        color: '#4DA91D',
        key: 'extremely-relevant',
        shift: 4,
    },
    {
        min: 64,
        max: 79,
        category: 'Very Relevant',
        color: '#81B21F',
        key: 'very-relevant',
        shift: '80',
    },
    {
        min: 48,
        max: 63,
        category: 'Strong Relevance',
        color: '#BBBB21',
        key: 'strong-relevance',
        shift: 16,
    },
    {
        min: 32,
        max: 47,
        category: 'Moderate Relevance',
        color: '#C38E21',
        key: 'moderate-relevance',
        shift: '64',
    },
    {
        min: 16,
        max: 31,
        category: 'Low Relevance',
        color: '#CC5C24',
        key: 'low-relevance',
        shift: 32,
    },
    {
        min: 0,
        max: 15,
        category: 'Irrelevant',
        color: '#D52624',
        key: 'irrelevant',
        shift: '48',
    },
];
