import React from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, Col, Form, Row, Typography } from 'antd';

import {
    optimizerSettingOptimalWordCountRangeDefaultOption,
    optimizerSettingOptimalWordCountRangeOption,
    optimizerSettingSelectDefaultOption,
    optimizerSettingSelectOption,
    optimizerSettingsAICommandMenuForSharedLinksDefaultOption,
    optimizerSettingsAICommandMenuForSharedLinksOption,
} from '../../../../../constants/optimizerSettingSelectOption';
import { optimizerSettingsOptions } from '../../../../../constants/optimizerSettingsOptions';

import { selectUserOrganizationSettings } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import {
    defaultCountry,
    featuredCountries,
    getCountriesWithDefaultHighlighted,
} from '../../../../../constants/countries';
import {
    defaultLanguage,
    featuredLanguages,
    getLanguagesWithDefaultHighlighted,
} from '../../../../../constants/languages';
import { getArrayOptionsFromObject } from '../../../../../utils/helpers/getArrayOptionsFromObject';
import KeywordSelect from '../../../../common/selects/KeywordSelect';
import OptimizerCheckedSettingItem from './OptimizerCheckedSettingItem';
import OptimizerSettingSelect from './OptimizerSettingSelect';

const { Title } = Typography;

const classes = {
    optimizerSettingsWrapper: 'optimizer-settings-wrapper',
    rowWithCheckbox: 'row-with-checkbox',
    optimizerBriefSettingWrapper:
        'optimizer-brief-setting-wrapper row-with-checkbox',
    optimizerSettingsSelectWrapper: 'optimizer-settings-select-wrapper',
    settingsSectiontitle: 'section-title',
};

const OptimizerSettingsContent = ({
    form,
    handleSelectCompetitorPositions,
    handleSelectOptimalWordCountRange,
    handleChangeLanguage,
    handleChangeLocation,
    handleSelectAICommandMenuForSharedLinks,
}) => {
    const organizationSettings = useSelector(selectUserOrganizationSettings);
    const languages = getLanguagesWithDefaultHighlighted();
    const countries = getCountriesWithDefaultHighlighted();

    return (
        <section className={classes.optimizerSettingsWrapper}>
            <Title level={4} className={classes.settingsSectiontitle}>
                Content Optimizer Settings
            </Title>
            <Row
                gutter={24}
                className={classes.rowWithCheckbox}
                justify='start'
            >
                <Form.Item name='optimizer_settings'>
                    <Checkbox.Group>
                        {optimizerSettingsOptions?.map((setting) => (
                            <OptimizerCheckedSettingItem
                                setting={setting}
                                key={setting.value}
                            />
                        ))}
                    </Checkbox.Group>
                </Form.Item>
            </Row>
            <Row
                gutter={24}
                className={`${classes.optimizerSettingsSelectWrapper}  mb-2`}
            >
                <Col span={8}>
                    <Form.Item
                        name='competitor_positions_analyzed'
                        label='Google rankings analyzed for NLP keywords'
                    >
                        <OptimizerSettingSelect
                            form={form}
                            options={optimizerSettingSelectOption}
                            defaultValue={
                                organizationSettings?.competitor_positions_analyzed ||
                                optimizerSettingSelectDefaultOption
                            }
                            value={form.getFieldValue(
                                'competitor_positions_analyzed'
                            )}
                            handleSelectCompetitorPositions={
                                handleSelectCompetitorPositions
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name='optimal_word_count_range'
                        label='Google rankings analyzed for Optimal ranges'
                    >
                        <OptimizerSettingSelect
                            form={form}
                            options={
                                optimizerSettingOptimalWordCountRangeOption
                            }
                            defaultValue={
                                organizationSettings?.optimal_word_count_range ||
                                optimizerSettingOptimalWordCountRangeDefaultOption
                            }
                            value={form.getFieldValue(
                                'optimal_word_count_range'
                            )}
                            handleSelectCompetitorPositions={
                                handleSelectOptimalWordCountRange
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} className={classes.optimizerSettingsSelectWrapper}>
                <Col span={8}>
                    <Form.Item
                        name='google_search_engine_preference'
                        label='Google search engine preference'
                    >
                        <KeywordSelect
                            form={form}
                            placeholder='Select engine'
                            options={getArrayOptionsFromObject({
                                values: countries,
                                featured: featuredCountries,
                            })}
                            value={form.getFieldValue(
                                'google_search_engine_preference'
                            )}
                            defaultValue={defaultCountry}
                            onChange={handleChangeLocation}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name='language_preference'
                        label='Language preference'
                    >
                        <KeywordSelect
                            form={form}
                            placeholder='Select language'
                            options={getArrayOptionsFromObject({
                                values: languages,
                                featured: featuredLanguages,
                            })}
                            value={form.getFieldValue('language_preference')}
                            defaultValue={defaultLanguage}
                            onChange={handleChangeLanguage}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} className={classes.optimizerSettingsSelectWrapper}>
                <Col span={8}>
                    <Form.Item
                        name='ai_command_menu_for_shared_links'
                        label='AI command menu for shared links'
                    >
                         <OptimizerSettingSelect
                            form={form}
                            options={optimizerSettingsAICommandMenuForSharedLinksOption}
                            defaultValue={
                                organizationSettings?.ai_command_menu_for_shared_links ||
                                optimizerSettingsAICommandMenuForSharedLinksDefaultOption
                            }
                            value={form.getFieldValue(
                                'ai_command_menu_for_shared_links'
                            )}
                            handleSelectCompetitorPositions={
                                handleSelectAICommandMenuForSharedLinks
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
        </section>
    );
};

export default OptimizerSettingsContent;
