import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import InvoiceHistoryCard from './content/InvoiceHistoryCard';
import './InvoiceHistoryPageContent.scss';

const { Title } = Typography;

const classes = {
    invoiceHistoryContentWrapper:
        'invoice-history-page-wrapper d-flex flex-column align-items-center w-100',
    invoiceHistoryContentWrapperDark: 'invoice-history-dark',
};

const InvoiceHistoryPageContent = () => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.invoiceHistoryContentWrapper} 
                        ${
                            darkMode && classes.invoiceHistoryContentWrapperDark
                        }`}
        >
            <Title level={2}>Invoice History</Title>
            <InvoiceHistoryCard />
        </div>
    );
};

export default InvoiceHistoryPageContent;
