import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dropdown, Typography } from 'antd';
import moment from 'moment';

import MoreHorizontalIcon from '../../../../resources/icons/MoreHorizontalIcon';
import TaskSquareIcon from '../../../../resources/icons/TaskSquareIcon';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { getUserFullName } from '../../../../utils/helpers/getUserFullName';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';
import { useUsers } from '../../../../utils/hooks/useUsers';

import { updateCurrentContentOptimizer } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.actions';
import { selectCurrentContentOptimizer } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';

import ManageProjectModal from '../../checkedItemsActionsPanel/ManageProjectModal';

const classes = {
    projectSettingButton:
        'd-flex flex-center align-items-center btn-hover-light-primary project-setting-btn ml-16px',
    dropdownOverlay:
        'project-settings dropdown-with-arrow-overlay dropdown-menu dropdown-overlay-with-extra-content',
    dropdownOverlayDark:
        'dark-mode dropdown-with-arrow-overlay-dark dropdown-overlay-with-extra-content-dark',
    dropdownContentWrapper: 'dropdown-with-extra-content-wrapper',
    dropdownButton: 'd-flex justify-content-between align-items-center w-100',
    userInfoWrapper: 'project-settings-user-info-wrapper',
    dataOption: 'data-option',
    bottomLineOption: 'bottom-line line',
};

const optimizerOptions = {
    project: {
        key: 'manage-project',
        label: 'Manage Project',
    },
};

const Projectsetting = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const contentOptimizer = useSelector(selectCurrentContentOptimizer);

    const { isOpen, handleOpen } = useOpen();
    const { users } = useUsers();
    const iconColor = useIconColor();

    const handleUpdateOptimizer = (data) => {
        dispatch(updateCurrentContentOptimizer(data?.[0]));
    };

    const optimizerSettingsItems = [
        {
            label: optimizerOptions.project.label,
            key: optimizerOptions.project.key,
            icon: <TaskSquareIcon color={iconColor} />,
            title: '',
            onClick: handleOpen,
        },
    ];

    return (
        <>
            <Dropdown
                arrow
                menu={{ items: optimizerSettingsItems }}
                trigger={['click']}
                placement={'bottomRight'}
                overlayClassName={`${classes.dropdownOverlay}
                               ${darkMode ? classes.dropdownOverlayDark : ''}`}
                dropdownRender={(menu) => (
                    <div className={classes.dropdownContentWrapper}>
                        {menu}
                        <div className={classes.userInfoWrapper}>
                            <div className={classes.bottomLineOption}></div>
                            <Typography className={classes.dataOption}>
                                {`Created ${moment
                                    .utc(contentOptimizer?.created_at)
                                    .local()
                                    .fromNow()} by ${getUserFullName(
                                    users,
                                    contentOptimizer?.user_id
                                )}`}
                            </Typography>
                            <Typography className={classes.dataOption}>
                                {`Last edited ${moment(
                                    contentOptimizer?.edited_at
                                ).format('LL')}`}
                            </Typography>
                        </div>
                    </div>
                )}
            >
                <Button className={classes.projectSettingButton}>
                    <MoreHorizontalIcon color={iconColor} />
                </Button>
            </Dropdown>
            <ManageProjectModal
                getOptimizers={true}
                isOpen={isOpen}
                handleOpen={handleOpen}
                checkedItems={[contentOptimizer?.content_brief_id]}
                selectedProjects={[contentOptimizer?.content_brief?.projects]}
                handleUpdateProjects={handleUpdateOptimizer}
            />
        </>
    );
};

export default Projectsetting;
