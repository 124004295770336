import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import {
    selectDarkMode,
    selectUserOrganization,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectCurrentSubscription,
    selectUsedUsers,
    selectUsersLimit,
} from '../../billing/store/billingSettings.selectors';

import PlusIcon from '../../../../../resources/icons/Plus';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import SubscriptionHelper from '../../../auth/helpers/subscription.helper';

const classes = {
    inviteUserButton: 'invite-user-button',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const InviteUserButton = ({ handleOpen }) => {
    const darkMode = useSelector(selectDarkMode);
    const usersLimit = useSelector(selectUsersLimit);
    const usersUsed = useSelector(selectUsedUsers);
    const subscription = useSelector(selectCurrentSubscription);
    const organization = useSelector(selectUserOrganization);

    const isDisabled =
        !Number.isInteger(usersUsed) ||
        !Number.isInteger(usersLimit) ||
        usersUsed >= usersLimit ||
        SubscriptionHelper.isTrial(subscription, organization);

    const button = (
        <Button
            className={`${classes.inviteUserButton} ${
                isDisabled ? 'disabled' : ''
            } ${darkMode ? 'dark-mode' : ''}`}
            type='primary'
            size='large'
            ghost
            onClick={!isDisabled ? handleOpen : null}
        >
            <PlusIcon color={colorPalette.colorPrimary} />
            Invite User
        </Button>
    );

    if (!isDisabled) {
        return button;
    }

    return (
        <Tooltip
            title='You have reached the limit of users for your subscription plan. Please upgrade your subscription to invite more users.'
            placement='bottom'
            overlayClassName={`${classes.tooltipOverlay} ${
                darkMode ? classes.tooltipOverlayDark : ''
            }`}
        >
            {button}
        </Tooltip>
    );
};

export default InviteUserButton;
