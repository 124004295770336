import React, { useState } from 'react';

import { Col, Row, Typography, Segmented } from 'antd';

import { advancedSettingsOptions } from '../../../../../constants/advancedSettingsOptions';

import {
    domainDescription,
    termsDescription,
} from '../../../../../constants/advancedSettingsDescription';

import TermSettingContent from './TermSettingContent';
import DomainSettingContent from './DomainSettingContent';

const { Title } = Typography;

const classes = {
    advancedSettingsWrapper: 'advanced-settings-wrapper',
    settingsSectiontitle: 'section-title',
};

const advancedOptions = [
    {
        label: advancedSettingsOptions.term.label,
        value: advancedSettingsOptions.term.value,
    },
    {
        label: advancedSettingsOptions.domain.label,
        value: advancedSettingsOptions.domain.value,
    },
];

const AdvancedSettingsContent = () => {
    const [selectedOption, setSelectedOption] = useState(
        advancedSettingsOptions.term.value
    );

    const handleChange = (value) => {
        setSelectedOption(value);
    };

    return (
        <section className={classes.advancedSettingsWrapper}>
            <Title
                level={4}
                className={classes.settingsSectiontitle}
            >
                Advanced Settings
            </Title>
            <Row>
                <Col span={16}>
                    <Typography>
                        {selectedOption === advancedSettingsOptions.term.value
                            ? termsDescription
                            : domainDescription}
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Segmented
                        block
                        options={advancedOptions}
                        defaultValue={selectedOption}
                        value={selectedOption}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            {selectedOption === advancedSettingsOptions.term.value ? (
                <TermSettingContent />
            ) : (
                <DomainSettingContent />
            )}
        </section>
    );
};

export default AdvancedSettingsContent;
