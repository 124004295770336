export const advancedSettingsOptions = {
    term: {
        value: 'term',
        label: 'Keyword filtering',
    },
    domain: {
        value: 'domain',
        label: 'Domain blocking',
    },
};
