export const accountSettingsActions = {
    CLEAR_ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS/CLEAR_ACCOUNT_SETTINGS',

    UPDATE_ACCOUNT_TAB: 'ACCOUNT_SETTINGS/UPDATE_ACCOUNT_TAB',
    UPDATE_SCROLL_SECTION: 'ACCOUNT_SETTINGS/UPDATE_SCROLL_SECTION',

    SET_USERS: 'ACCOUNT_SETTINGS/SET_USERS',
    UPDATE_USER_FULL_NAME: 'ACCOUNT_SETTINGS/UPDATE_USER_FULL_NAME',
    UPDATE_USER_ROLE: 'ACCOUNT_SETTINGS/UPDATE_USER_ROLE',
    UPDATE_USER_ACTIVE: 'ACCOUNT_SETTINGS/UPDATE_USER_ACTIVE',
    SET_INVITES: 'ACCOUNT_SETTINGS/SET_INVITES',
    ADD_INVITE: 'ACCOUNT_SETTINGS/ADD_INVITE',
    DELETE_INVITE: 'ACCOUNT_SETTINGS/DELETE_INVITE',
};

export const updateAccountTab = (payload) => {
    return {
        type: accountSettingsActions.UPDATE_ACCOUNT_TAB,
        payload,
    };
};

export const updateScrollSection = (payload) => {
    return {
        type: accountSettingsActions.UPDATE_SCROLL_SECTION,
        payload,
    };
};

export const setUsers = (payload) => {
    return {
        type: accountSettingsActions.SET_USERS,
        payload,
    };
};

export const updateUserRole = (payload) => {
    return {
        type: accountSettingsActions.UPDATE_USER_ROLE,
        payload,
    };
};

export const updateUserFullName = (payload) => {
    return {
        type: accountSettingsActions.UPDATE_USER_FULL_NAME,
        payload,
    };
};

export const updateUserActive = (payload) => {
    return {
        type: accountSettingsActions.UPDATE_USER_ACTIVE,
        payload,
    };
};

export const setInvites = (payload) => {
    return {
        type: accountSettingsActions.SET_INVITES,
        payload,
    };
};

export const addInvite = (payload) => {
    return {
        type: accountSettingsActions.ADD_INVITE,
        payload,
    };
};

export const deleteInvite = (payload) => {
    return {
        type: accountSettingsActions.DELETE_INVITE,
        payload,
    };
};

export const clearAccountSettings = () => {
    return {
        type: accountSettingsActions.CLEAR_ACCOUNT_SETTINGS,
    };
};
