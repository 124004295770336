import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectUserOrganizationSettings } from '../../../../../userBrowserSettings/store/browserSettings.selectors.js';
import NetworkSignalIcon from '../../../../../resources/icons/NetworkSignalIcon.jsx';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';

import {
    advancedSettingsDomainsListItems,
    advancedSettingsDomainsStatusListItems,
} from '../../../../../constants/advancedSettingsListItems';
import { domainPattern } from '../../../../../constants/regularExpression.js';

import StatusList from '../../../../common/lists/StatusList.jsx';

import AdvancedSettingsEmptyArea from './AdvancedSettingsEmptyArea';
import AdvancedSettingsList from './AdvancedSettingsList';
import AdvancedSettingModal from './AdvancedSettingModal';
import AdvancedSettingsTable from './AdvancedSettingsTable';

const { Title } = Typography;

const classes = {
    domainSettingWrapper: 'domain-setting-wrapper',
    settingsSectiontitle: 'section-title',
    domainFormattingTitle: 'domain-formatting-title section-title',
};

const DomainSettingContent = () => {
    const organisationSettings = useSelector(selectUserOrganizationSettings);

    const iconColor = useIconColor();

    return (
        <div className={classes.domainSettingWrapper}>
            <Title
                level={4}
                className={classes.settingsSectiontitle}
            >
                Domain Blocking
            </Title>
            <AdvancedSettingsList
                listItems={advancedSettingsDomainsListItems}
            />
            <Title
                level={4}
                className={classes.domainFormattingTitle}
            >
                Domain Formatting
            </Title>
            <StatusList listItems={advancedSettingsDomainsStatusListItems} />
            <AdvancedSettingModal
                linkText='Add a domain to block'
                title='Add Domain To Block'
                confirmBtnText='Add blocked domain'
                fieldName='blocked_domains'
                valueName='domain'
                fieldLabel='Add a specific URL to block'
                fieldPlaceholder='e.g. www.google.com'
                pattern={domainPattern}
                patternErrorMessage='Domain is not a valid domain name'
                existErrorMessage='Domain already exists'
            />
            {organisationSettings?.blocked_domains?.length > 0 ? (
                <AdvancedSettingsTable
                    title='domain'
                    fieldName='blocked_domains'
                    advancedSettingsData={organisationSettings.blocked_domains}
                />
            ) : (
                <AdvancedSettingsEmptyArea
                    tilte='No blocked domains match your filters.'
                    subtitle='Add one using the link above.'
                    icon={<NetworkSignalIcon color={iconColor} />}
                />
            )}
        </div>
    );
};

export default DomainSettingContent;
