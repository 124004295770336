import React from 'react';

import { colorPalette } from '../../styles/colorPalette';

const AITryAgainIcon = ({ color = colorPalette.textColor.light }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 26'
            fill='none'
            className='fill-icon'
        >
            <path
                d='M12.0001 22.7499C6.80008 22.7499 2.58008 18.5199 2.58008 13.3299C2.58008 8.1399 6.80008 3.8999 12.0001 3.8999C13.0701 3.8999 14.1101 4.0499 15.1101 4.3599C15.5101 4.4799 15.7301 4.8999 15.6101 5.2999C15.4901 5.6999 15.0701 5.9199 14.6701 5.7999C13.8201 5.5399 12.9201 5.3999 12.0001 5.3999C7.63008 5.3999 4.08008 8.9499 4.08008 13.3199C4.08008 17.6899 7.63008 21.2399 12.0001 21.2399C16.3701 21.2399 19.9201 17.6899 19.9201 13.3199C19.9201 11.7399 19.4601 10.2199 18.5901 8.9199C18.3601 8.5799 18.4501 8.1099 18.8001 7.8799C19.1401 7.6499 19.6101 7.7399 19.8401 8.0899C20.8801 9.6399 21.4301 11.4499 21.4301 13.3299C21.4201 18.5199 17.2001 22.7499 12.0001 22.7499Z'
                fill={color}
            />
            <path
                d='M16.1304 6.07021C15.9204 6.07021 15.7104 5.98021 15.5604 5.81021L12.6704 2.49021C12.4004 2.18021 12.4304 1.70021 12.7404 1.43021C13.0504 1.16021 13.5304 1.19021 13.8004 1.50021L16.6904 4.82021C16.9604 5.13021 16.9304 5.61021 16.6204 5.88021C16.4904 6.01021 16.3104 6.07021 16.1304 6.07021Z'
                fill={color}
            />
            <path
                d='M12.7602 8.5302C12.5302 8.5302 12.3002 8.4202 12.1502 8.2202C11.9102 7.8902 11.9802 7.4202 12.3102 7.1702L15.6802 4.7102C16.0102 4.4602 16.4802 4.5402 16.7302 4.8702C16.9802 5.2002 16.9002 5.6702 16.5702 5.9202L13.2002 8.3902C13.0702 8.4902 12.9202 8.5302 12.7602 8.5302Z'
                fill={color}
            />
        </svg>
    );
};

export default AITryAgainIcon;
