import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Typography } from 'antd';
import moment from 'moment-timezone';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import NumberHelper from '../../../../../utils/helpers/numberHelper';
import { openNotification } from '../../../../../utils/helpers/openNotification';

import BarLoader from '../../../loader/BarLoader';

const CST_VALUE_NAME = 'America/Chicago';
const DAY_OF_WEEK_KEY = 3;
export const MASTERMIND_MEETING_ID = 82487515449;

const classes = {
    joinLiveCallBtn: 'join-live-call-btn',

    mastermindTimer: 'mastermind-timer-wrapper w-100',
    timeItemsWrapper: 'time-items-wrapper d-flex justify-content-center',
    timerTitle: 'timer-title',
    timeItem: 'time-item',
    mainTimeFont: 'main-time-font',
    secondTimeFont: 'second-time-font',
    timePartsSeparator: 'time-parts-separator',
    timerLoaderWrapper: 'timer-loader-wrapper',
};

const MastermindTimer = ({
    showTitle = true,
    shortizeTimePeriodWord = false,
    callIsActiveCustomComponent,
    timePartsClassName = '',
    timeItemsWrapperClassName = '',
}) => {
    const darkMode = useSelector(selectDarkMode);

    const [timeRemaining, setTimeRemaining] = useState(null);
    const [meetingTime, setMeetingTime] = useState(null);
    const [isLiveCallActive, setIsLiveCallActive] = useState();

    const isMeetingInProgress = (nextMeetingTime) => {
        const currentTime = moment().utc();

        if (currentTime.day() !== moment(nextMeetingTime).day()) {
            return false;
        }

        return currentTime.isBetween(
            moment()
                .hour(moment(nextMeetingTime).hour())
                .minute(moment(nextMeetingTime).minute())
                .second(0),
            moment()
                .hour(moment(nextMeetingTime).hour() + 1)
                .minute(moment(nextMeetingTime).minute())
                .second(0)
        );
    };

    const setCountdownTimer = (nextMeetingTime) => {
        const interval = setInterval(() => {
            const currentTime = moment().utc();

            const isCallActive = isMeetingInProgress(nextMeetingTime);
            setIsLiveCallActive(isCallActive);

            const meetingHasFinished = currentTime.isAfter(
                moment()
                    .hour(moment(nextMeetingTime).hour() + 1)
                    .minute(moment(nextMeetingTime).minute())
                    .second(0)
            );

            if (meetingHasFinished) {
                clearInterval(interval);
                fetchMastermindMeetingTime();

                return;
            }

            if (!isCallActive) {
                const timeDiff = moment(nextMeetingTime).diff(currentTime);
                const duration = moment.duration(timeDiff);

                setTimeRemaining({
                    days: duration.days(),
                    hours: duration.hours(),
                    minutes: duration.minutes(),
                    seconds: duration.seconds(),
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    };

    const fetchMastermindMeetingTime = () => {
        axiosAPI
            .get(`/mastermind/get-by-id/${MASTERMIND_MEETING_ID}`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                const currentTime = moment().utc();
                const nextMeetingTime = result?.data?.occurrences.find(
                    (x) =>
                        moment(currentTime).isBefore(x.start_time) ||
                        isMeetingInProgress(x.start_time)
                )?.start_time;
                if (nextMeetingTime) {
                    setMeetingTime(nextMeetingTime);
                    setCountdownTimer(nextMeetingTime);
                }
            })
            .catch((_err) => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });
    };

    // NOTE: Uncomment when rate limits are fixed
    // useEffect(() => {
    //     fetchMastermindMeetingTime();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = moment().tz(CST_VALUE_NAME);

            let nextWednesday = currentTime
                .clone()
                .day(DAY_OF_WEEK_KEY)
                .hour(15)
                .minute(0)
                .second(0);

            const skip10072024Meeting =
                nextWednesday.date() === 10 &&
                nextWednesday.month() === 6 &&
                nextWednesday.year() === 2024;

            if (currentTime.isAfter(nextWednesday) || skip10072024Meeting) {
                nextWednesday = nextWednesday.add(1, 'weeks');
            }

            setMeetingTime(nextWednesday);

            const timeDiff = nextWednesday.diff(currentTime);
            const duration = moment.duration(timeDiff);

            setTimeRemaining({
                days: duration.days(),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds(),
            });

            if (currentTime.day() === DAY_OF_WEEK_KEY) {
                setIsLiveCallActive(
                    currentTime.isBetween(
                        moment()
                            .tz(CST_VALUE_NAME)
                            .hour(15)
                            .minute(0)
                            .second(0),
                        moment()
                            .tz(CST_VALUE_NAME)
                            .hour(16)
                            .minute(30)
                            .second(0)
                    )
                );
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleJoinLiveCallClick = () => {
        window.open(
            `https://us02web.zoom.us/j/${MASTERMIND_MEETING_ID}`,
            '_blank'
        );
    };

    const colonComponent = (
        <Typography
            className={`${classes.mainTimeFont} ${classes.timePartsSeparator} ${timePartsClassName}`}
        >
            :
        </Typography>
    );

    return (
        <div
            className={`${classes.mastermindTimer} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            {isLiveCallActive ? (
                callIsActiveCustomComponent || (
                    <Button
                        type='primary'
                        size='large'
                        block
                        className={classes.joinLiveCallBtn}
                        onClick={handleJoinLiveCallClick}
                    >
                        <span>Join the live call</span>
                    </Button>
                )
            ) : (
                <>
                    {timeRemaining != null ? (
                        <>
                            {showTitle && (
                                <div className={classes.timerTitle}>
                                    Next Mastermind Call (
                                    {moment(meetingTime)
                                        .tz(CST_VALUE_NAME)
                                        .format('ddd [@] hh:mm A')}{' '}
                                    CST) starts in:
                                </div>
                            )}
                            <div
                                className={
                                    timeItemsWrapperClassName ||
                                    classes.timeItemsWrapper
                                }
                            >
                                <div className={classes.timeItem}>
                                    <Typography
                                        className={`${classes.mainTimeFont} ${timePartsClassName}`}
                                    >
                                        {NumberHelper.padStartNumber(
                                            timeRemaining?.days
                                        )}
                                    </Typography>
                                    <Typography>
                                        {shortizeTimePeriodWord ? 'd' : 'days'}
                                    </Typography>
                                </div>

                                {colonComponent}
                                <div className={classes.timeItem}>
                                    <Typography
                                        className={`${classes.mainTimeFont} ${timePartsClassName}`}
                                    >
                                        {NumberHelper.padStartNumber(
                                            timeRemaining?.hours
                                        )}
                                    </Typography>
                                    <Typography>
                                        {shortizeTimePeriodWord ? 'h' : 'hours'}
                                    </Typography>
                                </div>
                                {colonComponent}
                                <div className={classes.timeItem}>
                                    <Typography
                                        className={`${classes.mainTimeFont} ${timePartsClassName}`}
                                    >
                                        {NumberHelper.padStartNumber(
                                            timeRemaining?.minutes
                                        )}
                                    </Typography>
                                    <Typography>
                                        {shortizeTimePeriodWord
                                            ? 'm'
                                            : 'minutes'}
                                    </Typography>
                                </div>
                                {colonComponent}
                                <div className={classes.timeItem}>
                                    <Typography
                                        className={`${classes.mainTimeFont} ${timePartsClassName}`}
                                    >
                                        {NumberHelper.padStartNumber(
                                            timeRemaining?.seconds
                                        )}
                                    </Typography>
                                    <Typography>
                                        {shortizeTimePeriodWord
                                            ? 's'
                                            : 'seconds'}
                                    </Typography>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div
                            className={`${classes.timerLoaderWrapper} ${timePartsClassName}`}
                        >
                            <BarLoader />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MastermindTimer;
