import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import UsageChartLabel from './UsageChartLabel';
import UsageChart from './UsageChart';

const classes = {
    chartLabel: 'chart-label',
    chartWrapper: 'chart-wrapper',
    xAxisLabel: 'x-axis-label',
};

const UsageChartsSection = ({
    contentOptimizers = [],
    contentMonitors = [],
    keywordResearches = [],
    aiCredits = [],
}) => {
    const chartWrapper = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const [chartWidth, setChartWidth] = useState(0);
    const paddingOffset = 32;

    const changeChartWidth = () => {
        if (chartWrapper?.current) {
            setChartWidth(chartWrapper?.current?.offsetWidth - paddingOffset);
        }
    };

    const prepareChartData = (
        firstArray,
        secondArray,
        firstValueKey = 'firstValue',
        secondValueKey = 'secondValue'
    ) =>
        firstArray.map((first) => {
            const second = secondArray.find((s) => s.name === first.name);
            return {
                name: first.name,
                [firstValueKey]: first.value,
                [secondValueKey]: second ? second.value : 0,
            };
        });

    const totalChartDataNum = (chartData) => {
        return chartData.reduce((total, obj) => total + obj.value, 0);
    };

    useEffect(() => {
        changeChartWidth();

        window.addEventListener('resize', changeChartWidth);

        return () => {
            window.removeEventListener('resize', changeChartWidth);
        };

        // eslint-disable-next-line
    }, [chartWrapper]);

    return (
        <Row justify='space-between' gutter={24}>
            <Col span={12} ref={chartWrapper}>
                <div className={`${classes.chartWrapper} ${getThemeColorClass(darkMode)}`}>
                    <div className={classes.chartLabel}>
                        <UsageChartLabel
                            mainLabel={`Optimizers Created: ${totalChartDataNum(contentOptimizers)}`}
                        />
                        <UsageChartLabel
                            mainLabel={`Monitors Created: ${totalChartDataNum(contentMonitors)}`}
                        />
                    </div>
                    <UsageChart
                        data={prepareChartData(
                            contentOptimizers,
                            contentMonitors,
                            'Optimizers',
                            'Monitors'
                        )}
                        linesConfig={[
                            {
                                dataKey: 'Optimizers',
                                stroke: colorPalette.usageChartColor.firstChart.first,
                            },
                            {
                                dataKey: 'Monitors',
                                stroke: colorPalette.usageChartColor.firstChart.second,
                            },
                        ]}
                        width={chartWidth}
                    />
                    <div className={classes.xAxisLabel}>
                        <Typography>{contentOptimizers[0]?.name}</Typography>
                        <Typography>
                            {contentOptimizers[contentOptimizers?.length - 1]?.name}
                        </Typography>
                    </div>
                </div>
            </Col>
            <Col span={12}>
                <div className={`${classes.chartWrapper} ${getThemeColorClass(darkMode)}`}>
                    <div className={`${classes.chartLabel}`}>
                        <UsageChartLabel
                            mainLabel={`Keyword Searches Created: ${totalChartDataNum(keywordResearches)}`}
                        />
                        <UsageChartLabel
                            mainLabel={`AI Credits Created: ${totalChartDataNum(aiCredits)}`}
                        />
                    </div>
                    <UsageChart
                        data={prepareChartData(
                            keywordResearches,
                            aiCredits,
                            'Keyword Searches',
                            'AI Credits'
                        )}
                        linesConfig={[
                            {
                                dataKey: 'Keyword Searches',
                                stroke: colorPalette.usageChartColor.secondChart.first,
                            },
                            {
                                dataKey: 'AI Credits',
                                stroke: colorPalette.usageChartColor.secondChart.second,
                            },
                        ]}
                        width={chartWidth}
                    />
                    <div className={classes.xAxisLabel}>
                        <Typography>{keywordResearches[0]?.name}</Typography>
                        <Typography>
                            {keywordResearches[keywordResearches?.length - 1]?.name}
                        </Typography>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default UsageChartsSection;
