import React from 'react';

import { Modal } from 'antd';

import MastermindCallContent from './MastermindCallContent';

import './MastermindCallModal.scss';

const classes = {
    modalWrapper: 'mastermind-call-modal',
};

const MastermindCallModal = ({ isModalOpen, handleCancel }) => {
    return (
        <Modal
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            wrapClassName={classes.modalWrapper}
            width={'800px'}
            closable={false}
        >
            <MastermindCallContent />
        </Modal>
    );
};

export default MastermindCallModal;
