import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import {
    setCurrentKeyword,
    setKeywordForUpdate,
    updateKeywordQueryParam,
} from '../store/keywordResearchSettings.actions';

import KeywordResearchHeader from '../common/KeywordResearchHeader';
import KeywordResearchList from './KeywordResearchList';

const KeywordResearchContentLayout = ({
    isKeywordsLoading,
    setIsKeywordsLoading,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentKeyword(null));

        return () => {
            batch(() => {
                dispatch(
                    updateKeywordQueryParam({
                        search: '',
                        page: 1,
                    })
                );
                dispatch(setKeywordForUpdate(null));
            });
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <KeywordResearchHeader />
            <KeywordResearchList
                isLoading={isKeywordsLoading}
                setIsLoading={setIsKeywordsLoading}
            />
        </>
    );
};

export default KeywordResearchContentLayout;
