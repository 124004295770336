import React from 'react';

const SignOutIcon = ({ color = '#9199AD'}) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.3334 13.8334V16.8334C13.3334 17.938 12.4379 18.8334 11.3334 18.8334H4.50004C3.39547 18.8334 2.50004 17.938 2.50004 16.8334V4.16675C2.50004 3.06218 3.39547 2.16675 4.50004 2.16675H11.3334C12.4379 2.16675 13.3334 3.06218 13.3334 4.16675V7.16675'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M15.8334 13L18.3334 10.5L15.8334 8'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M17.5 10.5L7.5 10.5'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default SignOutIcon;
