import { usageStatsTableFields } from '../../constants';
import { sortOrders } from '../../../../../constants/sortOrders';

const compareStrings = (order, a, b) => {
    return order === sortOrders.asc
        ? a.toLowerCase().localeCompare(b.toLowerCase())
        : b.toLowerCase().localeCompare(a.toLowerCase());
};

const compareNumbers = (order, a, b) => {
    return order === sortOrders.asc ? a - b : b - a;
};

const compareFunctions = {
    [usageStatsTableFields.user.key]: compareStrings,
    [usageStatsTableFields.organization.key]: compareStrings,
    [usageStatsTableFields.contentBriefs.key]: compareNumbers,
    [usageStatsTableFields.contentMonitors.key]: compareNumbers,
    [usageStatsTableFields.keywordResearches.key]: compareNumbers,
    [usageStatsTableFields.aiCredits.key]: compareNumbers,
};

export const sortUsageStatsByFieldAndOrder = (stats, field, order) => {
    if (!stats || !stats.length) {
        return [];
    }

    const compareFunction = compareFunctions[field];

    return compareFunction
        ? stats.sort((a, b) => compareFunction(order, a[field], b[field]))
        : stats;
};
