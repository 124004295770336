import React, { useEffect, useState } from 'react';

import { Button, Typography, Radio } from 'antd';

import { keywordResearchesAdvancedFilters } from '../../../../constants/keywordResearchesAdvancedFilters';
import { initialAdvancedFiltersValues } from '../../../../constants/initialAdvancedFiltersValues';

import { colorPalette } from '../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../resources/icons/CloseIcon';
import PlusIcon from '../../../../resources/icons/Plus';

import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import CustomDropdown from '../CustomDropdown';

import {
    keywordFilterOptions,
    keywordRulesOptions,
} from './keywordFilterOptions';
import KeywordFilterItem from './KeywordFilterItem';
import FilterPopoverButon from './FilterPopoverButton';

import './Dropdowns.scss';

const { Link } = Typography;

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay keyword-popover-overlay',
    filterWrapper: 'filter-wrapper',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
    addRuleBtn: 'add-rule-btn link-hover-highlight',
};

const KeywordFilterDropdown = ({
    value,
    handleKeywordFilterChange,
    isActive,
}) => {
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const [filterValue, setFilterValue] = useState(
        value?.filter(
            (item) =>
                item?.rule !==
                keywordResearchesAdvancedFilters.keywords.rules.operator
        )
    );

    const [rulesFilterValue, setRulesFilterValue] = useState(
        value?.find(
            (item) =>
                item?.rule ===
                keywordResearchesAdvancedFilters.keywords.rules.operator
        ) || {
            field: keywordResearchesAdvancedFilters.keywords.field,
            rule: keywordResearchesAdvancedFilters.keywords.rules.operator,
            value: keywordRulesOptions[0].value,
        }
    );

    useEffect(() => {
        if (value?.[0]?.value === '') {
            setFilterValue(initialAdvancedFiltersValues.keywords);
        } else {
            setFilterValue(
                value?.filter(
                    (item) =>
                        item?.rule !==
                        keywordResearchesAdvancedFilters.keywords.rules.operator
                )
            );
            setRulesFilterValue(
                value?.find(
                    (item) =>
                        item?.rule ===
                        keywordResearchesAdvancedFilters.keywords.rules.operator
                ) || {
                    field: keywordResearchesAdvancedFilters.keywords.field,
                    rule: keywordResearchesAdvancedFilters.keywords.rules
                        .operator,
                    value: keywordRulesOptions[0].value,
                }
            );
        }
    }, [value]);

    const handleAddRule = () => {
        setFilterValue((prevValue) => [
            ...prevValue,
            initialAdvancedFiltersValues.keywords[0],
        ]);
    };

    const handleRemoveRule = (index) => {
        const newFilterValue = [...filterValue];
        newFilterValue.splice(index, 1);

        setFilterValue(newFilterValue);
    };

    const handleChange = (index, field, value) => {
        const newFilterValue = [...filterValue];
        newFilterValue[index] = {
            ...newFilterValue[index],
            [field]: value,
        };

        setFilterValue(newFilterValue);
    };

    const handleRulesFilterChange = (event) => {
        setRulesFilterValue((prevValue) => ({
            ...prevValue,
            value: event.target.value,
        }));
    };

    const handleApply = () => {
        let keywordsWithValues = filterValue.filter(
            (item) => item?.value !== ''
        );

        if (keywordsWithValues.length === 0) {
            keywordsWithValues = initialAdvancedFiltersValues.keywords;
        }

        handleKeywordFilterChange([...keywordsWithValues, rulesFilterValue]);
        setFilterValue(keywordsWithValues);
        handleOpen();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleClear = () => {
        const filterNullValue = initialAdvancedFiltersValues.keywords;
        handleKeywordFilterChange(filterNullValue);
        setFilterValue(filterNullValue);
    };

    const getFilterLabel = () => {
        if (value?.[0]?.value && value?.[0]?.value !== '') {
            const ruleLabel = keywordFilterOptions.find(
                (item) => item.value === value[0].rule
            )?.label;

            return `Keyword: ${ruleLabel} ${value[0].value}${
                value &&
                value?.filter(
                    (item) =>
                        item?.rule !==
                        keywordResearchesAdvancedFilters.keywords.rules.operator
                )?.length > 1
                    ? ' and ...'
                    : ''
            }`;
        } else {
            return 'Keyword';
        }
    };

    const content = (
        <div onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <div className='d-flex justify-content-between'>
                    <Link
                        className={classes.addRuleBtn}
                        onClick={handleAddRule}
                    >
                        <PlusIcon color={colorPalette.colorPrimary} size='14' />
                        Rule
                    </Link>
                    <Radio.Group
                        defaultValue={rulesFilterValue?.value}
                        size='small'
                        onChange={handleRulesFilterChange}
                    >
                        {keywordRulesOptions.map((item) => (
                            <Radio.Button key={item.value} value={item.value}>
                                {item.label}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </div>
                <div className={classes.filterWrapper}>
                    {filterValue?.map((item, index) => (
                        <KeywordFilterItem
                            key={`${item.field}-${index}`}
                            index={index}
                            showRemove={filterValue.length > 1}
                            keywordFilterItem={item}
                            handleChange={handleChange}
                            handleRemoveRule={handleRemoveRule}
                        />
                    ))}
                </div>
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div className={`${classes.popoverBtn} ${isActive ? 'active' : ''}`}>
            <CustomDropdown
                withExtraContent
                isDropdownOpen={isOpen}
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={getFilterLabel()}
                        showArrow={!value?.[0]?.value}
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                dropdownContent={content}
                dropdownOverlayClassName={classes.popoverOverlay}
                dropdownPlacement='bottom'
                dropdownTriggersArray={['click']}
                handleOpenChange={handleOpen}
            />
            {value?.[0]?.value && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default KeywordFilterDropdown;
