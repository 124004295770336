import React, { useEffect, useRef } from 'react';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Input } from 'antd';

import { AISpellCheckerEnterIcon } from '../../../../../resources/icons/ai';
import ChatGPTIcon from '../../../../../resources/icons/editor/ChatGPTIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import useAiTextOptions from '../../hooks/useAiTextOptions';
import { executeAiCommandWithHighlight } from '../../utils/editorTransactionHelpers';

import { aiSpellCheckerOptionKeys } from '../../constants/aiEditorConstants';
import { nodeTypes } from '../../constants/nodeTypes';

const classes = {
    aiCustomPromptWrapper: 'd-flex',
    chatGptIconWrapper: 'chat-gpt-icon-wrapper',
    spellCheckerEnterButton: 'spell-checker-enter-button',
};

const AiCustomPrompt = ({ spellCheckActionsVisible }) => {
    const { editor } = useCurrentEditor();

    const textAreaRef = useRef(null);
    const textAreaElement = textAreaRef.current?.resizableTextArea?.textArea || null;

    const iconColor = useIconColor();
    const { aiTextOptions } = useAiTextOptions();

    useEffect(() => {
        if (spellCheckActionsVisible) {
            textAreaElement.focus();
        }
    }, [spellCheckActionsVisible, textAreaElement]);

    const handleInputChange = (event) => {
        editor.commands.setCustomPromptText(event.target.value.trim());
    };

    const handleEnterClick = () => {
        if (editor.storage[nodeTypes.aiSpellChecker].customPromptText) {
            const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;

            editor.commands.setActiveAiCommand(aiSpellCheckerOptionKeys.customPrompt);
            executeAiCommandWithHighlight(editor, from, to, () =>
                editor.commands.aiCustomPrompt(aiTextOptions)
            );
        }
    };

    return (
        <div className={classes.aiCustomPromptWrapper}>
            <div className={classes.chatGptIconWrapper}>
                <ChatGPTIcon color={iconColor} />
            </div>
            <Input.TextArea
                ref={textAreaRef}
                placeholder='Ask AI to do something...'
                bordered={false}
                autoSize={true}
                onChange={handleInputChange}
                onPressEnter={(event) => {
                    if (!event.shiftKey) {
                        handleEnterClick();
                    }
                }}
            />
            <Button
                disabled={!editor.storage[nodeTypes.aiSpellChecker].customPromptText}
                className={classes.spellCheckerEnterButton}
                icon={<AISpellCheckerEnterIcon color={colorPalette.colorPrimary} />}
                onClick={handleEnterClick}
            />
        </div>
    );
};

export default AiCustomPrompt;
