import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Form, Typography } from 'antd';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getFirstSentenceOfString } from '../../../../../utils/helpers/getFirstSentenceOfString';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { paymentsPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../../resources/icons/CloseIcon';

import PaymentMethod from '../../../../common/paymentMethod/PaymentMethod';
import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

import {
    setPaymentMethod,
    togglePaymentMethodModal,
} from '../store/billingSettings.actions';
import { selectIsPaymentMethodModalOpen } from '../store/billingSettings.selectors';

const { Link } = Typography;

const classes = {
    link: 'link-hover-highlight',
    modalWrapper: 'edit-pay-method-modal modal-wrapper',
    modalWrapperDark: 'edit-pay-method-modal-dark modal-wrapper-dark',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    cardInput: 'card-input',
    label: 'label',
};

const EditPaymentMethodModal = ({ linkClassName, onSuccess, onFailed }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const stripe = useStripe();
    const elements = useElements();

    const darkMode = useSelector(selectDarkMode);
    const isOpen = useSelector(selectIsPaymentMethodModalOpen);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleOpen = () => {
        dispatch(togglePaymentMethodModal());
    };

    const handleCloseModal = () => {
        elements.getElement(CardElement).clear();
        form.resetFields();
        setError(null);
    };

    const handleEditPaymentMethod = () => {
        setIsLoading(true);

        stripe
            .createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            })
            .then((result) => {
                if (result.error) {
                    setError(
                        getFirstSentenceOfString(result.error.message) ||
                            'Please try again'
                    );
                    setIsLoading(false);
                } else {
                    const { paymentMethod } = result;

                    axiosAPI
                        .post(
                            paymentsPathes.editPaymentMethod,
                            { payment_method_id: paymentMethod?.id },
                            { ...getAxiosHeaders() }
                        )
                        .then((result) => {
                            if (result?.status === statusCodes.success) {
                                dispatch(setPaymentMethod(result?.data));
                                handleOpen();
                                onSuccess && onSuccess();
                            }
                        })
                        .catch(() => {
                            setError('Please enter a valid credit card');
                            onFailed && onFailed();
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            });
    };

    return (
        <>
            <Link
                underline
                className={`${classes.link} ${linkClassName || ''}
                ${darkMode ? 'dark-mode' : ''} `}
                onClick={handleOpen}
            >
                Edit payment method
            </Link>
            <Modal
                centered
                title='Edit Payment Method'
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleEditPaymentMethod}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        {error && <ErrorMessage errorMessage={error} />}

                        <PaymentMethod />
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            className={`${darkMode ? 'dark-mode' : ''}`}
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default EditPaymentMethodModal;
