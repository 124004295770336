import { useEffect, useRef } from 'react';

export const useAutoSave = (callback, delay = 1000, deps = []) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (typeof delay === 'number' && delay !== 0) {
            const runCallback = () => {
                savedCallback.current();
            };

            let interval = setInterval(runCallback, delay);

            return () => clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [delay, ...deps]);
};
