import { contentOptimizerMainTabs } from '../../constants/contentOptimizerMainTabs';

export const getContentOptimizerParams = (selectedContentBriefsMainTab) => {
    switch (selectedContentBriefsMainTab) {
        case contentOptimizerMainTabs?.active?.key:
            return {
                archived: false,
                scored: false,
            };
        case contentOptimizerMainTabs?.archived?.key:
            return {
                archived: true,
            };
        case contentOptimizerMainTabs?.scored?.key:
            return {
                archived: false,
                scored: true,
            };
        default:
            return {
                archived: false,
            };
    }
};
