export const accountTabs = {
    profile: {
        key: 'profile',
        label: 'Profile',
    },
    settings: {
        key: 'settings',
        label: 'Settings',
    },
    users: {
        key: 'users',
        label: 'Users',
    },
    'billing-credits': {
        key: 'billing-credits',
        label: 'Billing & Credits',
    },
    usage: {
        key: 'usage',
        label: 'Usage',
    },
    affiliate: {
        key: 'affiliate',
        label: 'Affiliate Program',
    },
    // TODO: Uncomment after implementation
    // integrations: {
    //     key: 'integrations',
    //     label: 'Integrations',
    // },
};
