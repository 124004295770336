import React from 'react';

import { Typography } from 'antd';

import CopyItem from '../../../../../../../common/copyItem/CopyItem';
import ViewOnlyOutlineTag from '../../../../../../../common/outlineTags/ViewOnlyOutlineTag';

const { Title } = Typography;

const classes = {
    optimizerOutlineWrapper: 'optimizer-outline-wrapper',
    title: 'title d-flex align-items-center',
    outlineTagsWrapper: 'outline-tags-wrapper',
};

const OptimizerBriefOutline = ({ outlineData }) => {
    const getCopiedText = () => {
        let copiedText = 'Content Outline\n';

        outlineData?.forEach((item) => {
            copiedText += `${item?.tag} ${item?.title}\n`;
        });

        return copiedText;
    };

    const getMappedOutlineEditorText = () => {
        return outlineData
            ?.map((outlineItem) => {
                return `<${outlineItem?.tag}>${outlineItem?.title}</${outlineItem?.tag}>`;
            })
            .join('');
    };

    return (
        <div className={classes.optimizerOutlineWrapper}>
            <Title level={5} className={classes.title}>
                Content Outline
                <CopyItem
                    copiedText={getCopiedText()}
                    mappedOutlineEditorText={getMappedOutlineEditorText}
                />
            </Title>
            <div className={classes.outlineTagsWrapper}>
                {outlineData?.map((item) => {
                    return (
                        <ViewOnlyOutlineTag
                            key={item?.id}
                            tag={item?.tag}
                            label={item?.title}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default OptimizerBriefOutline;
