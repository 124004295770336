import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './Loader.scss';

const classes = {
    loaderWrapper: 'loader-wrapper w-100 h-100 d-flex flex-center',
};

const Loader = () => {
    const antIcon = <LoadingOutlined className='loader' spin />;

    return (
        <div className={classes.loaderWrapper}>
            <Spin indicator={antIcon} />
        </div>
    );
};

export default Loader;