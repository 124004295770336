import React from 'react';

import { Card, Typography } from 'antd';

const { Title } = Typography;

const classes = {
    advancedSettingsEmptyAreaWrapper: 'advanced-settings-empty-area-wrapper',
    areaContentWrapper: 'area-content-wrapper',
};

const AdvancedSettingsEmptyArea = ({ tilte, subtitle, icon }) => {
    return (
        <div className={classes.advancedSettingsEmptyAreaWrapper}>
            <Card>
                <div className={classes.areaContentWrapper}>
                    {icon}
                    <Title level={4}>{tilte}</Title>
                    <Typography>{subtitle}</Typography>
                </div>
            </Card>
        </div>
    );
};

export default AdvancedSettingsEmptyArea;
