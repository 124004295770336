import React from 'react';

import { Typography } from 'antd';
import moment from 'moment';

import { dateFormat } from '../../../constants/dateConsts';

import { getUserFullName } from '../../../utils/helpers/getUserFullName';
import { formatRelativeTime } from '../../../utils/helpers/momentHelpers';
import { useUsers } from '../../../utils/hooks/useUsers';

const classes = {
    listCreateInfoWrapper:
        'list-create-info-wrapper d-flex flex-column justify-content-center',
};

const CreateInfoSection = ({
    createdByUserId,
    createdAt,
    centered = false,
    timeFromNow = false,
}) => {
    const { users } = useUsers();

    const createdBy = getUserFullName(users || [], createdByUserId);

    const createdAtUtc = moment.utc(createdAt).local();
    const createdAtFormatted = timeFromNow
        ? formatRelativeTime(createdAtUtc)
        : createdAtUtc.format(dateFormat.shortMonthDayYearAMPMTime);

    const alignClass = centered ? 'align-items-center' : 'flex-start';

    return (
        <div className={`${classes.listCreateInfoWrapper} ${alignClass}`}>
            <Typography className='mr-4px'>
                {`Created by ${createdBy}`}
            </Typography>
            <Typography>{createdAtFormatted}</Typography>
        </div>
    );
};

export default CreateInfoSection;
