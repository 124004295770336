import React from 'react';

import { Typography } from 'antd';

import Loader from '../../../common/loader/Loader';

import { useInvites } from '../../../../utils/hooks/useInvites';
import { useUsers } from '../../../../utils/hooks/useUsers';

import UsersCredits from './content/UsersCredits';
import UsersTable from './content/UsersTable';

const { Title } = Typography;

const classes = {
    usersContentWrapper: 'users-content-wrapper',
    usersContentSubtitle: 'users-subtitle',
    usersTableWrapper: 'users-table-wrapper',
    usersTableTitleWrapper:
        'users-table-title-wrapper d-flex justify-content-between align-items-center',
    usersButtonsWrapper:
        'users-buttons-wrapper d-flex justify-content-center align-items-center',
};

const UsersContent = () => {
    const { users, isUsersLoaded } = useUsers();
    const { invites, isInvitesLoaded } = useInvites();

    return (
        <div className={classes.usersContentWrapper}>
            <div className={classes.usersTableWrapper}>
                <div className={classes.usersTableTitleWrapper}>
                    <Title
                        level={4}
                        className={classes.usersContentSubtitle}
                    >
                        Users
                    </Title>
                    <UsersCredits />
                </div>
                {isUsersLoaded && isInvitesLoaded && (
                    <div className='account-loader-wrapper'>
                        <Loader />
                    </div>
                )}
                {users.length > 0 && (
                    <UsersTable
                        users={users}
                        invites={invites}
                    />
                )}
            </div>
        </div>
    );
};

export default UsersContent;
