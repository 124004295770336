export const keywordResearchesAdvancedFilters = {
    monthlySearches: {
        field: 'monthly_searches',
        rules: {
            months: 'months',
            trend: 'trend',
        },
    },
    cpc: {
        field: 'cpc',
        rules: {
            from: 'from',
            to: 'to',
        },
    },
    position: {
        field: 'position',
        rules: {
            from: 'from',
            to: 'to',
        },
    },
    keywords: {
        field: 'keyword',
        rules: {
            contains: 'contains',
            doesNotContain: 'does_not_contain',
            startsWith: 'starts_with',
            endsWith: 'ends_with',
            equals: 'equals',
            notEquals: 'not_equals',
            operator: 'operator',
        },
    },
    difficulty: {
        field: 'difficulty',
        rules: {
            from: 'from',
            to: 'to',
        },
    },
    searchVolume: {
        field: 'search_volume',
        rules: {
            from: 'from',
            to: 'to',
        },
    },
    searchIntent: {
        field: 'search_intent',
        rules: {
            contains: 'contains',
        },
    },
};
