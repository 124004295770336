export const optimizerSettingsOptions = [
    {
        value: 'show_presense',
        label: 'Show header tags',
        description: `Display H if a keyword has frequently been used as a heading (h1, h2, h3, etc) in top-ranked pages on Google.`,
    },
    {
        value: 'count_term_uses',
        label: 'Count your keyword usage',
        description: `Display the number of times a keyword is being used within your Optimizer content.`,
    },
];

export const optimizerSettingsValues = ['show_presense', 'count_term_uses'];
