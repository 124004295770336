export const getAxiosHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.cookie
                ?.split('; ')
                ?.find((row) => row.startsWith('csrf_token'))
                ?.split('=')[1],
        },
    };
};
