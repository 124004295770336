import { QueueStatus } from '../../constants/queueConsts';

/**
 * Order by "In Progress" first, then "Queued", then others according to Sort By dropdown menu
 */
export const sortOptimizerListByStatuses = (
    optimizerList = [],
    addedElements = []
) => {
    const sortedStatusList = [
        QueueStatus.IN_PROGRESS,
        QueueStatus.QUEUED,
        QueueStatus.NOT_STARTED,
    ];

    const optimizersInProgress = optimizerList.filter(
        (a) => a.queue_status === QueueStatus.IN_PROGRESS
    );

    const optimizersQueued = optimizerList.filter((a) =>
        [QueueStatus.QUEUED, QueueStatus.NOT_STARTED].includes(a.queue_status)
    );

    return [
        ...optimizersInProgress,
        ...optimizersQueued,
        ...addedElements,
        ...optimizerList.filter(
            (a) => !sortedStatusList.includes(a.queue_status)
        ),
    ];
};
