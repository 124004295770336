import React from 'react';

import { colorPalette } from '../../styles/colorPalette';

const DragIcon = ({ color = colorPalette.textColor.light }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke={color}
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='w-4 h-4'
        >
            <circle cx='9' cy='12' r='1'></circle>
            <circle cx='9' cy='5' r='1'></circle>
            <circle cx='9' cy='19' r='1'></circle>
            <circle cx='15' cy='12' r='1'></circle>
            <circle cx='15' cy='5' r='1'></circle>
            <circle cx='15' cy='19' r='1'></circle>
        </svg>
    );
};

export default DragIcon;
