export const domainRankCategories = [
    {
        min: 0,
        max: 99,
        category: 'Very Weak',
        color: '#1EA11D',
        key: 'very-weak',
    },
    {
        min: 100,
        max: 199,
        category: 'Weak',
        color: '#81B21F',
        key: 'weak',
    },
    {
        min: 200,
        max: 299,
        category: 'Moderate',
        color: '#BBBB21',
        key: 'moderate',
    },
    {
        min: 300,
        max: 399,
        category: 'Strong',
        color: '#C38E21',
        key: 'strong',
    },
    {
        min: 400,
        max: 499,
        category: 'Very Strong',
        color: '#CC5C24',
        key: 'very-strong',
    },
    {
        min: 500,
        max: 1000,
        category: 'Extremely Strong',
        color: '#D52624',
        key: 'extremely-strong',
    },
];
