import React from 'react';
import { useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import KeywordExtraBreadcrumb from '../../../common/header/content/KeywordExtraBreadcrumb';
import CommonPageLayout from '../../../common/layout/CommonPageLayout';
import CustomHeader from '../../../common/header/Header';

import './ContentMonitorContentPage.scss';

const { Content } = Layout;

const classes = {
    content: 'container',
    contentDark: 'container-dark',
};

const ContentMonitorContentPage = () => {
    const darkMode = useSelector(selectDarkMode);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <CustomHeader
                extraBreadcrumbs={[
                    <KeywordExtraBreadcrumb
                        keyword='Keywords'
                        countryCode='US'
                        languageCode='en'
                    />,
                ]}
                // TODO: — Hide all “How To Use” buttons (temporary)
                //showUseSetting
                showShareSetting
                showProjectSetting
            />
            <CommonPageLayout>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                    ${darkMode ? classes.contentDark : ''}`}
                >
                    Content Monitor Content Page
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default ContentMonitorContentPage;
