import React from 'react';
import { useSelector } from 'react-redux';

import { selectOutlineVersionLoading } from '../../../../../store/contentBriefsOutline.selectors';

import VersionHistoryContainer from '../../versionHistory/VersionHistoryContainer';

const OutlineVersionsBar = ({
    fetchOutlineVersions,
    createNewOutlineVersion,
}) => {
    const outlineVersionLoading = useSelector(selectOutlineVersionLoading);

    return (
        <VersionHistoryContainer
            showPageTitle
            fetchOutlineVersions={fetchOutlineVersions}
            createNewOutlineVersion={createNewOutlineVersion}
            newVersionCreating={outlineVersionLoading}
        />
    );
};

export default OutlineVersionsBar;
