import React from 'react';

import ViewOnlyBottomSettings from './ViewOnlyBottomSettings';
import ViewOnlyNavMenu from './ViewOnlyNavMenu';

import './ViewOnlySidebar.scss';

const classes = {
    siderNavSettingsWrapper:
        'd-flex flex-column justify-content-between sider-nav-settings-wrapper',
};

const ViewOnlySidebarContent = ({ collapsed, withOptimizer }) => {
    return (
        <div className={classes.siderNavSettingsWrapper}>
            <ViewOnlyNavMenu collapsed={collapsed} />
            <ViewOnlyBottomSettings collapsed={collapsed} />
        </div>
    );
};

export default ViewOnlySidebarContent;
