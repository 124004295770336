import React from 'react';

const ContentOutlineIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.16666 16.25H17.5'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M9.16666 10.416H17.5'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M9.16666 4.58398H17.5'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M2.5 4.58268L3.33333 5.41602L5.83333 2.91602'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M2.5 10.4167L3.33333 11.25L5.83333 8.75'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M2.5 16.2507L3.33333 17.084L5.83333 14.584'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default ContentOutlineIcon;
