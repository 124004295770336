import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateScrollSection } from '../../components/pages/account/store/accountSettings.actions';

export const useScrollToId = (sectionId) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (sectionId) {
            const element = document.getElementById(sectionId);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }

            dispatch(updateScrollSection(null));
        }

        // eslint-disable-next-line
    }, [sectionId]);
};
