import { useSelector } from 'react-redux';

import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';
import { colorPalette } from '../../resources/styles/colorPalette';

export const useDomainRankBadgeColor = (progress) => {
    const darkMode = useSelector(selectDarkMode);

    const getColor = () => {
        switch (true) {
            case progress <= 1000 && progress > 499:
                return '#D52624';
            case progress <= 499 && progress > 399:
                return '#CC5C24';
            case progress <= 399 && progress > 299:
                return '#C38E21';
            case progress <= 299 && progress > 199:
                return '#BBBB21';
            case progress <= 199 && progress > 99:
                return '#81B21F';
            case progress <= 99 && progress > 0:
                return '#1EA11D';
            default:
                return darkMode
                    ? colorPalette.noScoreBadgeColor.dark
                    : colorPalette.noScoreBadgeColor.light;
        }
    };

    const color = getColor();

    return color;
};
