import moment from 'moment';

export const getMondaysOfMonth = (previousMonth = false) => {
    const today = new Date();
    const currentMonth = previousMonth
        ? today.getMonth() - 1
        : today.getMonth();

    const mondays = [];

    const firstDayOfMonth = new Date(today.getFullYear(), currentMonth, 1);
    const dayOfWeek = firstDayOfMonth.getDay();

    if (dayOfWeek === 1) {
        mondays.push(new Date(firstDayOfMonth));
    }

    const daysToAdd = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;

    const firstMonday = new Date(firstDayOfMonth);
    firstMonday.setDate(firstDayOfMonth.getDate() + daysToAdd);

    while (firstMonday.getMonth() === currentMonth) {
        mondays.push(new Date(firstMonday));
        firstMonday.setDate(firstMonday.getDate() + 7);
    }

    if (dayOfWeek > 1) {
        const previousMonday = new Date(firstDayOfMonth);
        previousMonday.setDate(firstDayOfMonth.getDate() - dayOfWeek + 1);

        mondays.unshift(previousMonday);
    }

    return mondays?.map((monday) => moment(monday).format('DD.MM.YYYY'));
};

export const getMondaysLastDays = (lastDaysCount = 30) => {
    const today = new Date();

    let currentDate = new Date(today);

    const lastDaysAgo = new Date(
        today.setDate(today.getDate() - lastDaysCount)
    );

    const mondays = [];
    while (currentDate >= lastDaysAgo) {
        if (currentDate.getDay() === 1) {
            mondays.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() - 1);
    }

    return mondays?.map((monday) => moment(monday).format('DD.MM.YYYY'));
};
