import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Typography } from 'antd';

import CloseIcon from '../../../../../../resources/icons/CloseIcon';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../../utils/hooks/useOpen';

import CustomDropdown from '../../../../../common/dropdown/CustomDropdown';
import FilterPopoverButon from '../../../../../common/dropdown/advancedFilters/FilterPopoverButton';

import { setMonitorsQueryParams } from '../../store/contentMonitorMain.actions';
import { selectContentMonitorQueryParams } from '../../store/contentMonitorMain.selectors';

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay keyword-popover-overlay',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
    dropdownOverlay: 'dropdown-menu projects-dropdown-overlay',

    usersFilterCheckbox: 'monitor-users-filter-checkbox',
    usersFilterCheckboxGroup: 'monitor-users-filter-checkbox-group',
};

const defaultUsersLabel = 'Users';

const UsersFilterDropdown = ({ users }) => {
    const dispatch = useDispatch();

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);

    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);

    const [usersLabel, setUsersLabel] = useState(defaultUsersLabel);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleClear = () => {
        setSelectedUsers([]);
        dispatch(setMonitorsQueryParams({ users: null }));
    };

    useEffect(() => {
        setUsersLabel(
            monitorQueryParams?.users?.length > 0
                ? `Users: ${monitorQueryParams?.users?.length} selected`
                : defaultUsersLabel
        );
    }, [monitorQueryParams?.users]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleApply = () => {
        dispatch(
            setMonitorsQueryParams({
                users: selectedUsers?.length > 0 ? selectedUsers : null,
            })
        );

        handleOpen();
    };

    const handleChangeCheckbox = (selectedUsersIds) => {
        setSelectedUsers(selectedUsersIds);
    };

    const usersFilterContent = (
        <div onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <Checkbox.Group
                    value={selectedUsers}
                    onChange={handleChangeCheckbox}
                    className={classes.usersFilterCheckboxGroup}
                >
                    {users?.map((user, index) => (
                        <div className='d-flex flex-center' key={index}>
                            <Checkbox
                                value={user?.id}
                                className={`${classes.usersFilterCheckbox} ${
                                    darkMode ? 'dark-mode' : ''
                                }`}
                            />
                            <Typography>
                                {`${user?.first_name} ${user?.last_name}`}
                            </Typography>
                        </div>
                    ))}
                </Checkbox.Group>
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div className={classes.popoverBtn}>
            <CustomDropdown
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={usersLabel}
                        showArrow
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                withExtraContent
                dropdownContent={usersFilterContent}
                dropdownOverlayClassName={classes.popoverOverlay}
                dropdownTriggersArray={['click']}
                dropdownPlacement='bottom'
                isDropdownOpen={isOpen}
                handleOpenChange={handleOpen}
            />
            {monitorQueryParams?.users && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default UsersFilterDropdown;
