import React, { useEffect, useRef } from 'react';

import { Input } from 'antd';

const { TextArea } = Input;

const classes = {
    areaWrapper: 'area-multiple-wrapper d-flex',
    areaListNumberWrapper:
        'area-multiple-list-number-wrapper d-flex flex-column align-items-center',
    areaListNumber: 'area-multiple-list-number',
    textArea: 'area-multiple-textarea',
    inputError: 'input-error',
};

const MultipleCreateArea = ({
    placeholder,
    keywordArray,
    handleChange,
    isInputError,
}) => {
    const lineCounterRef = useRef();
    const textareaRef = useRef();

    useEffect(() => {
        handleTextareaScroll();
    }, [keywordArray]);

    const handleTextareaScroll = () => {
        if (lineCounterRef.current && textareaRef.current) {
            lineCounterRef.current.scrollTop =
                textareaRef.current.resizableTextArea.textArea.scrollTop;
        }
    };

    const onChange = (e) => {
        handleChange(e.target.value);
    };

    return (
        <div
            className={`${classes.areaWrapper} ${
                isInputError ? classes.inputError : ''
            }`}
        >
            <div className={classes.areaListNumberWrapper}>
                <div className={classes.areaListNumber} ref={lineCounterRef}>
                    {keywordArray && keywordArray.length > 0 ? (
                        keywordArray.map((_, index) => (
                            <div key={index}>{index + 1}</div>
                        ))
                    ) : (
                        <div>1</div>
                    )}
                </div>
            </div>
            <TextArea
                onChange={onChange}
                placeholder={placeholder}
                autoSize={{ minRows: 4, maxRows: 4 }}
                className={classes.textArea}
                ref={textareaRef}
                onScroll={handleTextareaScroll}
            />
        </div>
    );
};

export default MultipleCreateArea;
