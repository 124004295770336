import React from 'react';

const ShareIcon = ({ color = '#728192' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M15 6.6665C16.3807 6.6665 17.5 5.54722 17.5 4.1665C17.5 2.78579 16.3807 1.6665 15 1.6665C13.6193 1.6665 12.5 2.78579 12.5 4.1665C12.5 5.54722 13.6193 6.6665 15 6.6665Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15 18.3335C16.3807 18.3335 17.5 17.2142 17.5 15.8335C17.5 14.4528 16.3807 13.3335 15 13.3335C13.6193 13.3335 12.5 14.4528 12.5 15.8335C12.5 17.2142 13.6193 18.3335 15 18.3335Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.15845 11.2583L12.8501 14.575'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.8418 5.4248L7.15845 8.74147'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ShareIcon;
