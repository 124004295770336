import React from 'react';

const RedoIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.33337 5.33333H12.6667V2'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M12.4729 10.9051C11.8517 11.8617 10.9401 12.5939 9.87209 12.9943C8.80407 13.3947 7.63581 13.4421 6.53884 13.1296C5.44188 12.8171 4.47398 12.1612 3.77726 11.2581C3.08053 10.355 2.69168 9.25237 2.6678 8.11202C2.64391 6.97166 2.98624 5.85368 3.64454 4.92222C4.30283 3.99075 5.24241 3.29486 6.32533 2.93672C7.40826 2.57858 8.57747 2.57705 9.66133 2.93236C10.7452 3.28766 11.6866 3.98109 12.3473 4.91083'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default RedoIcon;
