import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Layout, Switch, Tabs, Typography, theme } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectContentOptimizerMainQueryParams,
    selectContentOptimizerMainTab,
    selectContentOptimizersCount,
    selectIsImportURLActive,
} from './store/contentOptimizerMain.selectors';

import { contentOptimizerMainTabs } from '../../../../constants/contentOptimizerMainTabs';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { getContentOptimizerParams } from '../../../../utils/helpers/getContentOptimizerParams';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { usePrevious } from '../../../../utils/hooks/usePrevious';

import OptimizerCredits from '../../../common/OptimizerCredits';
import CommonPageLayout from '../../../common/layout/CommonPageLayout';
import ContentOptimizerCreateNewContent from './content/ContentOptimizerCreateNewContent';
import ContentOptimizerMainPageHeader from './content/ContentOptimizerMainPageHeader';
import OptimizersResultsFilters from './filters/OptimizersResultsFilters';

import {
    clearContentOptimizerMainQueryParam,
    setContentOptimizerData,
    setContentOptimizerMainTotalCount,
    setContentOptimizersChecked,
    setContentOptimizersCount,
    setIsImportURLActive,
    updateContentOptimizerMainQueryParam,
    updateContentOptimizerMainTab,
} from './store/contentOptimizerMain.actions';

import './ContentOptimizerMainPage.scss';

const { Content } = Layout;

const classes = {
    content: 'container content-briefs-container content-optimizer-container',
    contentDark: 'container-dark content-optimizer-container-dark',

    extraContentWrapper: 'd-flex justify-content-between align-items-center',
    importURLSwitchWrapper:
        'd-flex flex-center justify-content-between align-items-center',
    importURLSwitch:
        'd-flex flex-center justify-content-between align-items-center cursor-pointer',
    importURLButton: 'import-url-button',

    contentBriefsViewAllContainer: 'content-briefs-view-all-container',
    optimizerResultsFilterWrapper: 'optimizer-results-filter-wrapper',
};

const ContentOptimizerMainPage = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const selectedTab = useSelector(selectContentOptimizerMainTab);
    const queryParams = useSelector(selectContentOptimizerMainQueryParams);
    const optimizersCount = useSelector(selectContentOptimizersCount);

    const prevQueryParams = usePrevious(queryParams);
    const prevOptimizersActiveCount = usePrevious(optimizersCount.active);

    const [isOptimizerLoading, setIsOptimizerLoading] = useState(false);

    const isImportURLActive = useSelector(selectIsImportURLActive);

    const handleChangeIsImportURL = () => {
        dispatch(setIsImportURLActive(!isImportURLActive));
    };

    useEffect(() => {
        if (selectedTab !== contentOptimizerMainTabs.projects.key) {
            dispatch(
                updateContentOptimizerMainQueryParam({
                    projects: null,
                })
            );
        }

        return () => {
            batch(() => {
                dispatch(
                    updateContentOptimizerMainTab(
                        contentOptimizerMainTabs.single.key
                    )
                );
                dispatch(setContentOptimizersChecked([]));
                dispatch(
                    updateContentOptimizerMainQueryParam({
                        search: '',
                        page: 1,
                    })
                );
            });
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            selectedTab === contentOptimizerMainTabs.projects.key &&
            (optimizersCount.projects === 0 || !queryParams.projects)
        ) {
            dispatch(setContentOptimizerData([]));
        } else if (
            !isOptimizerLoading &&
            (JSON.stringify(prevQueryParams) !== JSON.stringify(queryParams) ||
                (prevOptimizersActiveCount &&
                    prevOptimizersActiveCount !== optimizersCount.active))
        ) {
            setIsOptimizerLoading(true);

            axiosAPI
                .get(
                    contentOptimizerPathes.getContentOptimizers,
                    {
                        params: {
                            ...queryParams,
                            users: JSON.stringify(queryParams?.users),
                            dateRangePicker: JSON.stringify(
                                queryParams?.dateRangePicker
                            ),
                        },
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            dispatch(setContentOptimizerData(result?.data));
                            dispatch(
                                setContentOptimizerMainTotalCount(
                                    result?.headers?.['x-total-pages'] *
                                        queryParams?.per_page
                                )
                            );
                            dispatch(
                                setContentOptimizersCount({
                                    active: parseInt(
                                        result?.headers?.['x-active-count']
                                    ),
                                    scored:
                                        parseInt(
                                            result?.headers?.['x-scored-count']
                                        ) || 0,
                                    archived: parseInt(
                                        result?.headers?.['x-archived-count']
                                    ),
                                    projects: parseInt(
                                        result?.headers?.['x-projects-count']
                                    ),
                                })
                            );
                        });
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsOptimizerLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [queryParams, optimizersCount]);

    const handleChangeTab = (key) => {
        batch(() => {
            dispatch(updateContentOptimizerMainTab(key));
            dispatch(clearContentOptimizerMainQueryParam());
            dispatch(setContentOptimizersChecked([]));
            dispatch(
                updateContentOptimizerMainQueryParam({
                    ...getContentOptimizerParams(key),
                })
            );
        });
    };

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const tabsItems = [
        {
            key: contentOptimizerMainTabs.single.key,
            label: contentOptimizerMainTabs.single.label,
            children: (
                <ContentOptimizerCreateNewContent
                    isOptimizerLoading={isOptimizerLoading}
                />
            ),
        },
        {
            key: contentOptimizerMainTabs.multiple.key,
            label: contentOptimizerMainTabs.multiple.label,
            children: (
                <ContentOptimizerCreateNewContent
                    isOptimizerLoading={isOptimizerLoading}
                />
            ),
        },
    ];

    const extraContentElement = (
        <div className={classes.extraContentWrapper}>
            <div className={classes.importURLSwitchWrapper}>
                <div
                    className={classes.importURLSwitch}
                    onClick={handleChangeIsImportURL}
                >
                    <Typography>Import URL Content</Typography>
                    <Switch
                        className={classes.importURLButton}
                        checked={isImportURLActive}
                        onChange={handleChangeIsImportURL}
                        size='small'
                    />
                </div>
                <div className={classes.optimizerResultsFilterWrapper}>
                    <OptimizersResultsFilters />
                </div>
            </div>
            <OptimizerCredits
                tooltipTitle={
                    'Content Optimizer credits remaining this month. One credit per Optimizer run. '
                }
            />
        </div>
    );

    return (
        <>
            <ContentOptimizerMainPageHeader />

            <CommonPageLayout>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                            ${darkMode ? classes.contentDark : ''}`}
                >
                    <Tabs
                        items={tabsItems}
                        defaultActiveKey={selectedTab}
                        activeKey={selectedTab}
                        onChange={handleChangeTab}
                        tabBarExtraContent={extraContentElement}
                        destroyInactiveTabPane={true}
                    />
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default ContentOptimizerMainPage;
