import React from 'react';

import { Card } from 'antd';

import RankItemContent from './RankItemContent';
import RankTitle from './RankTitle';

import './RankItem.scss';

const classes = {
    rankItem: 'rank-item',
};

const RankItem = ({ addOutlineRow, rank }) => {
    return (
        <Card
            title={<RankTitle rank={rank} />}
            bordered={false}
            className={classes.rankItem}
        >
            <RankItemContent
                addOutlineRow={addOutlineRow}
                htags={rank?.htags}
                broken={rank?.broken}
            />
        </Card>
    );
};

export default RankItem;
