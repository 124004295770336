import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip, Popconfirm } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    versionHistoryButton: 'version-history-button',
    createVersionHistoryButton: 'create-version-history-button',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    deletePopconfirm: 'delete-popconfirm version-preview-popconfirm',
    deletePopconfirmDark: 'delete-popconfirm-dark dark-mode',
};

const VersionHistoryButton = ({
    label,
    disabled,
    tooltipLabel,
    handleCollapse,
    handlePreviewVersion,
    handleActivateVersion,
    handleCreateNewVersion,
    newVersionCreating,
    disableCreateNewVersionBtn,
    isCreateNewVersionBtn,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const handleConfirmActivateVersion = () => {
        handleActivateVersion();
        handlePreviewVersion();
        handleCollapse();
    };

    const renderButton = (handleClick) => {
        return (
            <Button
                disabled={
                    handleCreateNewVersion
                        ? disableCreateNewVersionBtn
                        : disabled
                }
                type='primary'
                loading={newVersionCreating}
                className={`${classes.versionHistoryButton}
                            ${
                                handleCreateNewVersion || isCreateNewVersionBtn
                                    ? classes.createVersionHistoryButton
                                    : ''
                            }`}
                onClick={handleClick}
            >
                {label}
            </Button>
        );
    };

    const onOpenChange = (open) => {
        if (!open) {
            handlePreviewVersion();
        }
    };

    if (tooltipLabel) {
        return (
            <Tooltip
                trigger={['hover']}
                title={tooltipLabel}
                overlayClassName={`${classes.tooltipOverlay}
                                   ${
                                       darkMode
                                           ? classes.tooltipOverlayDark
                                           : ''
                                   }`}
            >
                {renderButton(
                    handleCreateNewVersion
                        ? handleCreateNewVersion
                        : handlePreviewVersion
                )}
            </Tooltip>
        );
    }

    if (handleCreateNewVersion || isCreateNewVersionBtn) {
        return <>{renderButton(handleCreateNewVersion)}</>;
    }

    return (
        <Popconfirm
            placement='topRight'
            okText='Yes'
            cancelText='Cancel'
            title='Restore the document to this version?'
            overlayClassName={`${classes.deletePopconfirm}
                               ${darkMode ? classes.deletePopconfirmDark : ''}`}
            onConfirm={handleConfirmActivateVersion}
            onCancel={handlePreviewVersion}
            onOpenChange={onOpenChange}
        >
            {renderButton(handlePreviewVersion)}
        </Popconfirm>
    );
};

export default VersionHistoryButton;
