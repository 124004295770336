export const briefExtrasArrayFields = [
    'example_articles',
];

export const briefExtrasUrlFields = [
    'example_articles',
];

export const numberFields = [
    'word_count',
];
