import React from 'react';
import { useSelector } from 'react-redux';

import EditorMoreDropdown from '../../../pages/contentOptimizer/contentPage/content/optimizerTab/editor/content/toolbar/moreDropdown/EditorMoreDropdown';
import EditorVersionHistory from '../../../pages/contentOptimizer/contentPage/content/optimizerTab/editor/content/toolbar/versionHistory/EditorVersionHistory';

import useEditorVersions from '../hooks/useEditorVersions';

import { selectAIStateLoading } from '../store/tiptapEditor.selectors';

const classes = {
    toolbarSettingsSection: 'toolbar-settings-section d-flex',
};

const ToolbarSettingsSection = () => {
    const aiLoading = useSelector(selectAIStateLoading);

    const [fetchEditorVersions, createNewEditorVersion, isVersionCreating] = useEditorVersions();

    return (
        <div className={classes.toolbarSettingsSection}>
            <EditorVersionHistory
                disabled={aiLoading}
                fetchEditorVersions={fetchEditorVersions}
                createNewEditorVersion={createNewEditorVersion}
                newEditorVersionCreating={isVersionCreating}
            />
            <EditorMoreDropdown disabled={aiLoading} />
        </div>
    );
};

export default ToolbarSettingsSection;
