import React from 'react';
import { useSelector } from 'react-redux';

import { Badge } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

const classes = {
    settingsListWrapper: 'settings-list-wrapper',
};

const AdvancedSettingsList = ({ listItems }) => {
    const darkMode = useSelector(selectDarkMode);

    const badgeColor = darkMode
        ? colorPalette.textAccentColor.dark
        : colorPalette.textAccentColor.light;

    return (
        <ul className={classes.settingsListWrapper}>
            {listItems.map((item, index) => (
                <li key={index}>
                    <Badge
                        color={badgeColor}
                        text={item}
                    />
                </li>
            ))}
        </ul>
    );
};

export default AdvancedSettingsList;
