import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Link from 'antd/es/typography/Link';
import { Typography } from 'antd';

import { axiosAPI } from '../../../utils/axiosAPI';
import { notificationType } from '../../../constants/notificationType';
import { openNotification } from '../../../utils/helpers/openNotification';
import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { unauthorizedRoutes } from '../../common/router/Unauthorized/routes';

import './AzureHealthcheck.scss';

const classes = {
    root: 'azure-healthcheck-wrapper',
    messageTypography: 'message-typography d-flex flex-center',
    backToSingIn: 'd-flex flex-center',
    messageDarkModeTypography: 'message-dark-theme-typography d-flex flex-center dark-mode',
};

const AzureHealthcheck = () => {
    const darkMode = useSelector(selectDarkMode);
    const navigate = useNavigate();

    const [message, setMessage] = useState('');

    useEffect(() => {
        axiosAPI
            .get(unauthorizedRoutes.azureHealthcheck)
            .then((result) => {
                setMessage(result?.data?.message);
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message;

                setMessage(errorMessage);
                openNotification(notificationType.error, 'Error', errorMessage);
            });
    }, []);

    const handleSignIn = () => {
        navigate(unauthorizedRoutes.login);
    };

    return (
        <div className={classes.root}>
            <Typography
                className={
                    `${classes.messageTypography} ${darkMode ? classes.messageDarkModeTypography : ''}`
                }
            >
                {message}
            </Typography>
            <div className={classes.backToSingIn}>
                <Link onClick={handleSignIn}>Back to sign in</Link>
            </div>
        </div>
    );
};

export default AzureHealthcheck;
