import React from 'react';

const DarkModeIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.5 9.65833C16.3689 11.0768 15.8366 12.4287 14.9652 13.5557C14.0939 14.6826 12.9196 15.5382 11.5798 16.0221C10.2399 16.5061 8.79 16.5984 7.3996 16.2884C6.0092 15.9784 4.73585 15.2788 3.72854 14.2715C2.72123 13.2642 2.02163 11.9908 1.71161 10.6004C1.40158 9.21001 1.49395 7.76007 1.9779 6.42025C2.46185 5.08042 3.31738 3.90614 4.44436 3.03479C5.57135 2.16345 6.92318 1.63109 8.34168 1.5C7.51119 2.62356 7.11156 4.00787 7.21546 5.40118C7.31936 6.79448 7.9199 8.10422 8.90785 9.09217C9.8958 10.0801 11.2055 10.6807 12.5988 10.7846C13.9921 10.8885 15.3765 10.4888 16.5 9.65833Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default DarkModeIcon;
