import React from 'react';

import './CustomBadge.scss';

const classes = {
    customBadge: 'custom-badge',
};
const CustomBadge = ({ backgroundColor = '#DFE3EC', label = '', color }) => {
    return (
        <span
            className={`${classes.customBadge}`}
            style={{ background: backgroundColor, color: color }}
        >
            {label}
        </span>
    );
};

export default CustomBadge;
