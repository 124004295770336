import { contentOptimizerPathes } from '../constants/queryPathes';
import { axiosAPI } from '../utils/axiosAPI';
import { getAxiosHeaders } from '../utils/helpers/getAxiosHeaders';

const ContentOptimizerService = {
    getContentOptimizerVersions: (contentOptimizerId, organizationSlug) => {
        return axiosAPI.get(
            `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/`,
            {},
            {
                ...getAxiosHeaders(),
                params: { slug: organizationSlug },
            }
        );
    },
};

export default ContentOptimizerService;
