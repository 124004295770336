import React from 'react';
import { useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import SiderCollapseButton from '../sidebar/siderHeader/SiderCollapseButton';
import SiderLogo from '../sidebar/siderHeader/SiderLogo';

import ViewOnlySidebarContent from './ViewOnlySidebarContent';

import './ViewOnlySidebar.scss';

const { Sider } = Layout;
const { useToken } = theme;

const classes = {
    siderWrapper: 'sider',
    collapsedSider: 'sider-collapsed',
    darkSider: 'sider-dark',
};

const ViewOnlySidebar = ({ withOptimizer, collapsed, handleCollapse }) => {
    const { token } = useToken();

    const darkMode = useSelector(selectDarkMode);

    return (
        <Sider
            style={{
                background: token.colorBgContainer,
            }}
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
            className={`${classes.siderWrapper}
                ${collapsed && classes.collapsedSider}
                ${darkMode ? classes.darkSider : ''}`}
            trigger={null}
        >
            <SiderCollapseButton
                collapsed={collapsed}
                handleCollapsed={handleCollapse}
            />
            <SiderLogo collapsed={collapsed} />
            <ViewOnlySidebarContent
                collapsed={collapsed}
                withOptimizer={withOptimizer}
            />
        </Sider>
    );
};

export default ViewOnlySidebar;
