import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, Typography } from 'antd';

import { selectContentOptimizerViewOnlyMode } from '../../../../../../../viewOnly/store/viewOnlySettings.selectors';

import {
    updateEditorFullWidth,
    updateEditorHighlightKeywords,
    updateEditorHtmlTags,
} from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.actions';
import {
    selectEditorFullWidth,
    selectEditorHighlightKeywords,
    selectEditorHtmlTags,
} from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';

const { Title } = Typography;

const classes = {
    EditorMoreSettingsWrapper: 'editor-more-settings-wrapper',
    editorMoreSettingItem: 'editor-more-setting-item d-flex space-no-wrap',
};

const EditorMoreSettings = () => {
    const dispatch = useDispatch();

    const editorHtmlTags = useSelector(selectEditorHtmlTags);
    const editorHighlightKeywords = useSelector(selectEditorHighlightKeywords);
    const editorFullWidth = useSelector(selectEditorFullWidth);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);

    const handleHtmlTags = (value) => {
        dispatch(updateEditorHtmlTags(value));
    };

    const handleHighlightKeywords = (value) => {
        dispatch(updateEditorHighlightKeywords(value));
    };

    const handleFullWidth = (value) => {
        dispatch(updateEditorFullWidth(value));
    };

    return (
        <div
            className={`${classes.EditorMoreSettingsWrapper}
                         ${viewOnlyMode && 'view-only'}`}
        >
            <div className={classes.editorMoreSettingItem}>
                <Switch
                    size='small'
                    onChange={handleHighlightKeywords}
                    checked={editorHighlightKeywords}
                />
                <Title level={5}>Highlight Keywords</Title>
            </div>
            <div className={classes.editorMoreSettingItem}>
                <Switch
                    size='small'
                    onChange={handleHtmlTags}
                    checked={editorHtmlTags}
                />
                <Title level={5}>HTML Tags</Title>
            </div>
            <div className={classes.editorMoreSettingItem}>
                <Switch
                    size='small'
                    onChange={handleFullWidth}
                    checked={editorFullWidth}
                />
                <Title level={5}>Full Width</Title>
            </div>
        </div>
    );
};

export default EditorMoreSettings;
