import React from 'react';

const ProfileIcon = ({ color = '#9199AD'}) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.1001 11.15C10.0417 11.1416 9.96672 11.1416 9.90006 11.15C8.43339 11.1 7.26672 9.89998 7.26672 8.42498C7.26672 6.91665 8.48339 5.69165 10.0001 5.69165C11.5084 5.69165 12.7334 6.91665 12.7334 8.42498C12.7251 9.89998 11.5667 11.1 10.1001 11.15Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
            <path
                d='M15.6166 16.65C14.1333 18.0084 12.1666 18.8334 9.99997 18.8334C7.8333 18.8334 5.86663 18.0084 4.3833 16.65C4.46663 15.8667 4.96663 15.1 5.8583 14.5C8.14163 12.9834 11.875 12.9834 14.1416 14.5C15.0333 15.1 15.5333 15.8667 15.6166 16.65Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
            <path
                d='M9.99996 18.8334C14.6023 18.8334 18.3333 15.1025 18.3333 10.5001C18.3333 5.89771 14.6023 2.16675 9.99996 2.16675C5.39759 2.16675 1.66663 5.89771 1.66663 10.5001C1.66663 15.1025 5.39759 18.8334 9.99996 18.8334Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
        </svg>
    );
};

export default ProfileIcon;
