import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout, Tabs, theme } from 'antd';

import { checkIsAdmin } from '../../../utils/helpers/checkIsAdmin';
import { hasAccessToManageUsers } from '../../../utils/helpers/hasAccessToManageUsers';
import { useAccountTabsItems } from '../../../utils/hooks/useAccountTabsItems';
import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import { accountTabs } from '../../../constants/accountTabs';
import {
    selectDarkMode,
    selectIsSubscriptionExpired,
    selectUserData,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

import CommonPageLayout from '../../common/layout/CommonPageLayout';
import SubscriptionExpiredNotificationPage from './subscriptionExpired/SubscriptionExpiredNotificationPage';

import { updateAccountTab } from './store/accountSettings.actions';
import { selectAccountTab } from './store/accountSettings.selectors';

import AccountPageHeader from './AccountPageHeader';
import InviteUserModal from './users/content/InviteUserModal';

import './AccountPage.scss';
import './profile/ProfileContent.scss';
import './profile/content/ChangePasswordModal.scss';
import './users/UsersContent.scss';

const { Content } = Layout;

const classes = {
    content: 'container account-container',
    contentDark: 'container-dark account-container-dark dark-input-autofill',
    accountTitleWrapper:
        'account-title-wrapper d-flex justify-content-between align-items-center',
    accountTitle: 'account-title',
    settingsTabBarExtraContent: 'settings-tab-bar-extra-content-wrapper',
};

const AccountPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accountTab } = useParams();

    const darkMode = useSelector(selectDarkMode);
    const selectedTab = useSelector(selectAccountTab);
    const user = useSelector(selectUserData);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const organizationSlug = useOrganizationSlug();
    const tabsItems = useAccountTabsItems();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        if (accountTabs[accountTab]) {
            dispatch(updateAccountTab(accountTab));
            setActiveTab(accountTab);
        } else {
            dispatch(updateAccountTab(accountTabs.profile.key));
            setActiveTab(accountTabs.profile.key);
            navigate(`/${organizationSlug}/account/${accountTabs.profile.key}`);
        }

        // eslint-disable-next-line
    }, [accountTab]);

    const onChange = (key) => {
        navigate(`/${organizationSlug}/account/${key}`);
        dispatch(updateAccountTab(key));
    };

    if (!checkIsAdmin(user?.roles?.[0]?.name) && isSubscriptionExpired) {
        return <SubscriptionExpiredNotificationPage />;
    }

    const settingsTabBarExtraContent =
        selectedTab === accountTabs.users.key
            ? hasAccessToManageUsers(user?.roles?.[0]?.name) && (
                  <div className={classes.settingsTabBarExtraContent}>
                      <InviteUserModal />
                  </div>
              )
            : null;

    return (
        <>
            <AccountPageHeader />
            <CommonPageLayout>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                                ${darkMode ? classes.contentDark : ''}`}
                >
                    <Tabs
                        defaultActiveKey={activeTab}
                        activeKey={activeTab}
                        items={tabsItems.map((item) => {
                            return {
                                ...item,
                                disabled:
                                    item.key !==
                                        accountTabs['billing-credits'].key &&
                                    item.key !== accountTabs.profile.key &&
                                    isSubscriptionExpired,
                            };
                        })}
                        onChange={onChange}
                        tabBarExtraContent={settingsTabBarExtraContent}
                    />
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default AccountPage;
