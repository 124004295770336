import React from 'react';

import { difficultyBadgeSize } from '../../../../constants/difficultyBadgeSize';

const classes = {
    difficultyBadge: 'difficulty-badge',
    difficultyBadgeSmall: 'difficulty-badge-small',
    noScoreBadge: 'no-score-difficulty-badge',
};

const ColorBadge = ({
    value,
    title = '',
    isInline = false,
    size = difficultyBadgeSize.large,
    backgroundColor = 'rgb(228, 231, 235)',
    marginRight = 0,
}) => {
    const isSmallSize = size === difficultyBadgeSize.small;

    let badgeClasses = classes.difficultyBadge;
    badgeClasses += isSmallSize ? (' ' + classes.difficultyBadgeSmall) : '';
    badgeClasses += !value ? (' ' + classes.noScoreBadge) : '';

    return (
        <div
            className={badgeClasses}
            style={{
                backgroundColor: backgroundColor,
                display: isInline ? 'inline-block' : 'block',
                marginRight: marginRight,
            }}
        >
            {`${isSmallSize ? '' : title} ${value ?? 'N/A'}`}
        </div>
    );
};

export default ColorBadge;
