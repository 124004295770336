import React from 'react';

const RemoveIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.9503 3.68711C10.1188 3.50561 8.27629 3.41211 6.43929 3.41211C5.35029 3.41211 4.26129 3.46711 3.17229 3.57711L2.05029 3.68711'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M5.07544 3.13155L5.19644 2.41105C5.28444 1.88855 5.35044 1.49805 6.27994 1.49805H7.72094C8.65044 1.49805 8.72194 1.91055 8.80444 2.41655L8.92544 3.13155'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.7679 5.42578L10.4104 10.9643C10.3499 11.8278 10.3004 12.4988 8.76591 12.4988H5.23491C3.70041 12.4988 3.65091 11.8278 3.59041 10.9643L3.23291 5.42578'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M6.08179 9.47266H7.91329'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M5.62549 7.27344H8.37549'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default RemoveIcon;
