import React from 'react';

import { Button, Modal, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { colorPalette } from '../../../../../../resources/styles/colorPalette';

const classes = {
    modalWrapper: 'modal-wrapper',
    modalWrapperDark: 'modal-wrapper-dark',
    switchPlanContentMonitorCreditsModal:
        'switch-plan-content-monitor-credits-modal',
    switchPlanContentMonitorCreditsWarning: 'content-monitor-credits-warning',
    modalContent: 'switch-plan-content-monitor-credits-modal-content',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
};

const SwitchPlanContentMonitorWarningModal = ({
    isModalOpen,
    setIsModalOpen,
    darkMode,
    contentMonitorDetails,
    handleConfirm,
}) => {
    return (
        <Modal
            centered
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
            closable={false}
            wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
        >
            <div className={classes.switchPlanContentMonitorCreditsModal}>
                <div className={classes.modalContent}>
                    <ExclamationCircleFilled
                        style={{ color: colorPalette.colorPrimary }}
                    />
                    <Typography
                        className={
                            classes.switchPlanContentMonitorCreditsWarning
                        }
                    >
                        You currently have{' '}
                        {contentMonitorDetails?.contentMonitorsUsed} active
                        Content Monitor pages. By downgrading your subscription,
                        you will only have{' '}
                        {contentMonitorDetails?.newAmountOfCredits} Content
                        Monitor pages available to track. So please delete{' '}
                        {contentMonitorDetails?.contentMonitorsUsed -
                            contentMonitorDetails?.newAmountOfCredits}{' '}
                        Content Monitor pages before downgrading or they will
                        automatically be disabled. Would you like to continue
                        with downgrading your subscription anyway?
                    </Typography>
                </div>
                <div className={classes.modalFormButtonsWrapper}>
                    <Button
                        className={`${darkMode ? 'dark-mode' : ''}`}
                        type='primary'
                        size='small'
                        ghost
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='primary'
                        size='small'
                        htmlType='submit'
                        onClick={handleConfirm}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SwitchPlanContentMonitorWarningModal;
