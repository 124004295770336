import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form } from 'antd';

import { setUserData } from '../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectDarkMode,
    selectUserData,
    selectUserOrganizationSettings,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { optimizerSettingsValues } from '../../../../constants/optimizerSettingsOptions';
import { organizationPathes } from '../../../../constants/queryPathes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { checkIsGlobalTechAdmin } from '../../../../utils/helpers/checkIsGlobalTechAdmin';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';

import { elementsStyles } from '../../../../resources/styles/elementsStyles';

import AdvancedSettingsContent from './content/AdvancedSettingsContent';
import OptimizerSettingsContent from './content/OptimizerSettingsContent';

import './SettingsContent.scss';

const classes = {
    settingsContentWrapper: 'account-content-wrapper settings-content-wrapper',
    settingsForm: 'd-flex flex-column justify-content-between h-100',
    settingsFormFieldsWrapper: 'form-fields-wrapper h-100 d-flex flex-column',
    settingsFormButtonsWrapper:
        'form-buttons-wrapper d-flex justify-content-center align-items-center',
    settingsFormCancelButton: 'btn-secondary',
};

const SettingsContent = () => {
    const [form] = Form.useForm();
    const optimizerSettingsData = Form.useWatch([], form);

    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);
    const organizationSettings =
        useSelector(selectUserOrganizationSettings) || {};

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!checkIsGlobalTechAdmin(user?.roles)) {
            handleSetInitialValues();
        }

        // eslint-disable-next-line
    }, [organizationSettings]);

    useEffect(() => {
        if (optimizerSettingsData && !checkIsGlobalTechAdmin(user?.roles)) {
            const defaultCheckedSettings = Object.entries(
                organizationSettings
            ).map(([key, value]) => {
                if (optimizerSettingsValues.includes(key)) {
                    if (value && typeof value === 'boolean') {
                        return key;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });

            const checkedSettings =
                optimizerSettingsData?.optimizer_settings?.filter(
                    (setting) => setting !== null && setting !== undefined
                );

            const valuesAreEqual =
                checkedSettings.every((setting) =>
                    defaultCheckedSettings.includes(setting)
                ) &&
                defaultCheckedSettings
                    ?.filter(
                        (setting) => setting !== null && setting !== undefined
                    )
                    .every((setting) => checkedSettings.includes(setting)) &&
                optimizerSettingsData?.competitor_positions_analyzed ===
                    organizationSettings?.competitor_positions_analyzed &&
                optimizerSettingsData?.optimal_word_count_range ===
                    organizationSettings?.optimal_word_count_range &&
                optimizerSettingsData?.language_preference ===
                    organizationSettings?.language_preference &&
                optimizerSettingsData?.google_search_engine_preference ===
                    organizationSettings?.google_search_engine_preference &&
                optimizerSettingsData?.ai_command_menu_for_shared_links ===
                    organizationSettings?.ai_command_menu_for_shared_links;
            if (valuesAreEqual) {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }

        // eslint-disable-next-line
    }, [optimizerSettingsData]);

    const handleSetInitialValues = () => {
        form.setFieldsValue({
            optimizer_settings: optimizerSettingsValues.filter(
                (setting) => organizationSettings[setting]
            ),
            competitor_positions_analyzed:
                organizationSettings.competitor_positions_analyzed,

            optimal_word_count_range:
                organizationSettings.optimal_word_count_range,
            google_search_engine_preference:
                organizationSettings.google_search_engine_preference,

            language_preference: organizationSettings.language_preference,

            ai_command_menu_for_shared_links:
                organizationSettings.ai_command_menu_for_shared_links,

            // TODO:
            notification_email: 'implement.backend.logic@gmail.com',
        });

        setIsDisabled(true);
    };

    const handleUpdateSettings = (values) => {
        setIsLoading(true);

        const updatedValues = {
            ...organizationSettings,
            ...values,
        };
        const checkedFormSettings = updatedValues.optimizer_settings;

        const updatedSettingsModel = Object.keys(updatedValues).reduce(
            (acc, setting) => {
                if (
                    typeof organizationSettings[setting] === 'boolean' &&
                    setting !== 'ai_command_menu_for_shared_links'
                ) {
                    if (checkedFormSettings.includes(setting)) {
                        acc[setting] = true;
                    } else {
                        acc[setting] = false;
                    }
                } else {
                    acc[setting] = updatedValues[setting];
                }

                return acc;
            },
            {}
        );

        axiosAPI
            .patch(
                `${organizationPathes.updateOrganization}/${user.organization.id}`,
                {
                    settings: updatedSettingsModel,
                },
                { ...getAxiosHeaders() }
            )
            .then((res) => {
                dispatch(
                    setUserData({
                        ...user,
                        organization: {
                            ...user.organization,
                            settings: res?.data?.settings,
                        },
                    })
                );
                openNotification(
                    notificationType.success,
                    'Success',
                    'Information has been updated successfully'
                );
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSelectCompetitorPositions = (value) => {
        form.setFieldValue('competitor_positions_analyzed', value);
    };

    const handleSelectOptimalWordCountRange = (value) => {
        form.setFieldValue('optimal_word_count_range', value);
    };

    const handleChangeLocation = (value) => {
        form.setFieldValue('google_search_engine_preference', value);
    };

    const handleChangeLanguage = (value) => {
        form.setFieldValue('language_preference', value);
    };

    const handleSelectAICommandMenuForSharedLinks = (value) => {
        form.setFieldValue('ai_command_menu_for_shared_links', value);
    }

    // TODO: Uncomment after implementation
    // const handleSelectRankabilityScoresFallBelow = (value) => {
    //     form.setFieldValue('rankability_scores_fall_below', value);
    // };

    return (
        <div className={classes.settingsContentWrapper}>
            {!checkIsGlobalTechAdmin(user?.roles) && (
                <Form
                    form={form}
                    layout='vertical'
                    className={classes.settingsForm}
                    onFinish={handleUpdateSettings}
                >
                    <div
                        className={classes.settingsFormFieldsWrapper}
                        style={{
                            bottom: !isDisabled
                                ? `${elementsStyles.AccountButtonsPanel.height}px`
                                : '0',
                        }}
                    >
                        <OptimizerSettingsContent
                            form={form}
                            handleSelectCompetitorPositions={
                                handleSelectCompetitorPositions
                            }
                            handleSelectOptimalWordCountRange={
                                handleSelectOptimalWordCountRange
                            }
                            handleChangeLanguage={handleChangeLanguage}
                            handleChangeLocation={handleChangeLocation}
                            handleSelectAICommandMenuForSharedLinks={
                                handleSelectAICommandMenuForSharedLinks
                            }
                        />
                        {/* TODO: Uncomment after implementation */}
                        {/* <ContentMonitorSettings
                            form={form}
                            handleSelectRankabilityScoresFallBelow={
                                handleSelectRankabilityScoresFallBelow
                            }
                        /> */}
                        <AdvancedSettingsContent />
                    </div>
                    {!isDisabled && (
                        <div className={classes.settingsFormButtonsWrapper}>
                            <Button
                                type='primary'
                                size='large'
                                ghost
                                className={`${
                                    classes.settingsFormCancelButton
                                } ${darkMode ? 'dark-mode' : ''}`}
                                onClick={handleSetInitialValues}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='primary'
                                size='large'
                                htmlType='submit'
                                loading={isLoading}
                                disabled={isDisabled}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                </Form>
            )}
        </div>
    );
};

export default SettingsContent;
