import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectIsGracePeriod } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentSubscription } from '../store/billingSettings.selectors';

import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { paymentsPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import { invoicesLimit } from '../BillingConstants';
import InvoiceHistoryTable from './InvoiceHistoryTable';

const { Title } = Typography;

const classes = {
    invoiceHistoryWrapper: 'invoice-history-wrapper',
    invoiceHistorySectiontitle: 'section-title',
    textAccent: 'text-accent',
    invoiceBalanceHelpText: 'invoice-balance-help-text',
};

const InvoiceHistorySection = () => {
    const navigate = useNavigate();

    const isGracePeriod = useSelector(selectIsGracePeriod);
    const subscription = useSelector(selectCurrentSubscription);

    const organizationSlug = useOrganizationSlug();

    const [invoiceData, setInvoiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getInvoiceData = (limit = invoicesLimit, starting_after = null) => {
        setIsLoading(true);

        axiosAPI
            .get(
                paymentsPathes.getInvoices,
                { params: { limit, starting_after } },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    setInvoiceData([
                        ...result?.data?.map((item) => {
                            return {
                                ...item,
                                key: item?.id,
                            };
                        }),
                        {
                            key: 'more',
                        },
                    ]);
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getInvoiceData();

        // eslint-disable-next-line
    }, [isGracePeriod]);

    const handleShowMore = () => {
        navigate(`/${organizationSlug}/account/invoice-history`);
    };

    return (
        <section className={classes.invoiceHistoryWrapper}>
            <Title level={4} className={classes.invoiceHistorySectiontitle}>
                Invoice History
            </Title>
            <Typography>
                Your credit balance:{' '}
                <span className={classes.textAccent}>
                    ${-(subscription?.customer?.balance || 0) / 100}
                </span>
            </Typography>
            <Typography>
                <div className={classes.invoiceBalanceHelpText}>
                    Credits will automatically be applied to your next invoice
                    (no monetary refunds will be given for any reason)
                </div>
            </Typography>
            <InvoiceHistoryTable
                invoiceData={invoiceData}
                isLoading={isLoading && invoiceData?.length === 0}
                handleShowMore={handleShowMore}
            />
        </section>
    );
};

export default InvoiceHistorySection;
