import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Dropdown, Typography } from 'antd';

import SiderChevronDownIcon from '../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../utils/hooks/useIconColor';

import './MoreDropdown.scss';

const classes = {
    moreDropdownOverlay:
        'dropdown-with-arrow-overlay basic-more-dropdown-overlay dropdown-menu dropdown-overlay-with-extra-content',
    moreDropdownOverlayDark:
        'dropdown-with-arrow-overlay-dark basic-more-dropdown-overlay-dark dropdown-overlay-with-extra-content-dark',
    basicMoreBtn: 'basic-more-btn',
};

const MoreDropdown = ({
    isDropdownOpen,
    handleDropdownOpen,
    moreItems = [],
}) => {
    const darkMode = useSelector(selectDarkMode);

    const iconColor = useIconColor();

    return (
        <Dropdown
            menu={{ items: moreItems }}
            arrow
            placement='bottomRight'
            trigger={['click']}
            overlayClassName={`${classes.moreDropdownOverlay}
                                   ${
                                       darkMode &&
                                       classes.moreDropdownOverlayDark
                                   }`}
            open={isDropdownOpen}
            onOpenChange={handleDropdownOpen}
        >
            <Button
                className={`${classes.basicMoreBtn}
                            ${darkMode ? 'dark-mode' : ''}`}
                icon={
                    isDropdownOpen ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )
                }
                onClick={handleDropdownOpen}
            >
                <Typography>More</Typography>
            </Button>
        </Dropdown>
    );
};

export default MoreDropdown;
