import React from 'react';

const AIToneOfVoiceIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            className='fill-icon'
        >
            <path
                d='M12 16.25C9.38 16.25 7.25 14.12 7.25 11.5V6C7.25 3.38 9.38 1.25 12 1.25C14.62 1.25 16.75 3.38 16.75 6V11.5C16.75 14.12 14.62 16.25 12 16.25ZM12 2.75C10.21 2.75 8.75 4.21 8.75 6V11.5C8.75 13.29 10.21 14.75 12 14.75C13.79 14.75 15.25 13.29 15.25 11.5V6C15.25 4.21 13.79 2.75 12 2.75Z'
                fill={color}
            />
            <path
                d='M11.9996 19.7499C7.36961 19.7499 3.59961 15.9799 3.59961 11.3499V9.6499C3.59961 9.2399 3.93961 8.8999 4.34961 8.8999C4.75961 8.8999 5.09961 9.2399 5.09961 9.6499V11.3499C5.09961 15.1499 8.19961 18.2499 11.9996 18.2499C15.7996 18.2499 18.8996 15.1499 18.8996 11.3499V9.6499C18.8996 9.2399 19.2396 8.8999 19.6496 8.8999C20.0596 8.8999 20.3996 9.2399 20.3996 9.6499V11.3499C20.3996 15.9799 16.6296 19.7499 11.9996 19.7499Z'
                fill={color}
            />
            <path
                d='M13.3899 7.17982C13.3099 7.17982 13.2199 7.16982 13.1299 7.13982C12.3999 6.86982 11.5999 6.86982 10.8699 7.13982C10.4799 7.27982 10.0499 7.07982 9.90988 6.68982C9.76988 6.29982 9.96988 5.86982 10.3599 5.72982C11.4199 5.34982 12.5899 5.34982 13.6499 5.72982C14.0399 5.86982 14.2399 6.29982 14.0999 6.68982C13.9799 6.98982 13.6899 7.17982 13.3899 7.17982Z'
                fill={color}
            />
            <path
                d='M12.8001 9.29982C12.7301 9.29982 12.6701 9.28982 12.6001 9.26982C12.2001 9.15982 11.7901 9.15982 11.3901 9.26982C10.9901 9.37982 10.5801 9.13982 10.4701 8.73982C10.3601 8.34982 10.6001 7.93982 11.0001 7.82982C11.6501 7.64982 12.3501 7.64982 13.0001 7.82982C13.4001 7.93982 13.6401 8.34982 13.5301 8.74982C13.4401 9.07982 13.1301 9.29982 12.8001 9.29982Z'
                fill={color}
            />
            <path
                d='M12 22.75C11.59 22.75 11.25 22.41 11.25 22V19C11.25 18.59 11.59 18.25 12 18.25C12.41 18.25 12.75 18.59 12.75 19V22C12.75 22.41 12.41 22.75 12 22.75Z'
                fill={color}
            />
        </svg>
    );
};

export default AIToneOfVoiceIcon;
