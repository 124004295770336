import React from 'react';

const ContactSupportIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M15.3917 14.025L15.7167 16.6583C15.8 17.35 15.0584 17.8333 14.4667 17.475L10.975 15.4C10.5917 15.4 10.2167 15.375 9.85004 15.325C10.4667 14.6 10.8334 13.6833 10.8334 12.6917C10.8334 10.325 8.78337 8.40838 6.25004 8.40838C5.28337 8.40838 4.39171 8.68335 3.65004 9.16669C3.62504 8.95835 3.6167 8.75001 3.6167 8.53335C3.6167 4.74168 6.90837 1.66669 10.975 1.66669C15.0417 1.66669 18.3334 4.74168 18.3334 8.53335C18.3334 10.7833 17.175 12.775 15.3917 14.025Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
                style={{ fill: 'none' }}
            />
            <path
                d='M10.8332 12.6916C10.8332 13.6833 10.4665 14.6 9.84984 15.325C9.02484 16.325 7.7165 16.9666 6.24984 16.9666L4.07484 18.2583C3.70817 18.4833 3.2415 18.175 3.2915 17.75L3.49983 16.1083C2.38317 15.3333 1.6665 14.0916 1.6665 12.6916C1.6665 11.225 2.44984 9.9333 3.64984 9.16664C4.39151 8.6833 5.28317 8.40833 6.24984 8.40833C8.78317 8.40833 10.8332 10.325 10.8332 12.6916Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
                style={{ fill: 'none' }}
            />
        </svg>
    );
};

export default ContactSupportIcon;
