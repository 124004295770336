import { useDispatch } from 'react-redux';

import { axiosAPI } from '../axiosAPI';

import { paymentsPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';

import { getAxiosHeaders } from '../helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../helpers/openBasicErrorNotification';

import { setSubscriptionLimits } from '../../components/pages/account/billing/store/billingSettings.actions';

export const useSetSubscriptionLimits = () => {
    const dispatch = useDispatch();

    const updateSubscriptionLimits = () => {
        axiosAPI
            .get(paymentsPathes.getQuotes, { ...getAxiosHeaders() })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(setSubscriptionLimits(result?.data));
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    return { updateSubscriptionLimits };
};
