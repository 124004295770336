import React from 'react';

import { colorPalette } from '../../styles/colorPalette';

const AIReplaceSelectionIcon = ({ color = colorPalette.textColor.light }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 1 20 23'
            fill='none'
            transform='scale(1.2)'
            className='stroke-icon'
        >
            <path
                d='M6 12L10.245 16.9375L18 7.5'
                stroke={color}
                strokeWidth='1.4'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default AIReplaceSelectionIcon;
