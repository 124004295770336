export const getRandomId = () => {
    let codeSegment = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };

    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return (
        codeSegment() +
        codeSegment() +
        '-' +
        codeSegment() +
        '-' +
        codeSegment() +
        '-' +
        codeSegment() +
        '-' +
        codeSegment() +
        codeSegment() +
        codeSegment()
    );
};
