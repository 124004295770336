import React from 'react';

import { Button } from 'antd';

import PlusIcon from '../../../resources/icons/Plus';

const classes = {
    addButton: 'add-button',
};

const AddButton = ({ onClick, loading }) => {
    return (
        <Button
            className={classes.addButton}
            icon={<PlusIcon size={14} />}
            type='primary'
            onClick={onClick}
            loading={loading}
        />
    );
};

export default AddButton;
