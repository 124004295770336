import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';

import NumberHelper from '../../../utils/helpers/numberHelper';

const Timer = ({ startDateTime, endDateTime, timerEndsCallback }) => {
	const [timeRemaining, setTimeRemaining] = useState();
	let interval = useRef();

	useEffect(() => {
		if (startDateTime) {
			let start = moment.utc(startDateTime);
			let end = moment.utc(endDateTime);
			let duration = moment.duration(start.diff(end));

			setTimeRemaining(duration);

			interval.current = setInterval(() => {
				setTimeRemaining((prev) => moment.duration(prev).add(-1, 's'));
			}, 1000);

			return () => {
				if (interval.current) {
					clearInterval(interval.current);
				}
			};
		}
	}, [startDateTime, endDateTime]);

	useEffect(() => {
		if (
			timeRemaining &&
			timeRemaining.asMilliseconds() < 1000 &&
			interval.current
		) {
			timerEndsCallback && timerEndsCallback();
			clearInterval(interval.current);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeRemaining]);

	return (
		<>
			{NumberHelper.padStartNumber(timeRemaining?.days())}d :{' '}
			{NumberHelper.padStartNumber(timeRemaining?.hours())}h :{' '}
			{NumberHelper.padStartNumber(timeRemaining?.minutes())}m :{' '}
			{NumberHelper.padStartNumber(timeRemaining?.seconds())}s
		</>
	);
};

export default Timer;
