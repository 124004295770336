import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../../../../constants/statusCodes';

import { axiosAPI } from '../../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../../../../../utils/helpers/openNotification';

import {
    setBriefVersions,
    setCurrentBrief,
} from '../../../store/contentBriefsOutline.actions';
import {
    selectCurrentBrief,
    selectCurrentBriefId,
} from '../../../store/contentBriefsOutline.selectors';

export const usePageTitle = () => {
    const { contentOptimizerId } = useParams();

    const dispatch = useDispatch();

    const currentBrief = useSelector(selectCurrentBrief);
    const currentBriefId = useSelector(selectCurrentBriefId);

    useEffect(() => {
        if (
            (contentOptimizerId && !currentBrief) ||
            (contentOptimizerId &&
                currentBriefId?.toString() !== contentOptimizerId)
        ) {
            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/versions`,
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setBriefVersions(result?.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }

        // eslint-disable-next-line
    }, []);

    const updatePageTitle = (value) => {
        if (
            contentOptimizerId &&
            currentBrief &&
            currentBrief?.extras?.page_title !== value
        ) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${currentBrief?.content_brief_id}/versions/${currentBrief?.id}`,
                    {
                        extras: {
                            ...currentBrief?.extras,
                            page_title: value,
                        },
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setCurrentBrief(result?.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    return { pageTitle: currentBrief?.extras?.page_title, updatePageTitle };
};
