import TagLine from '../components/common/outlineTags/TagLine';

export const hTagsLines = {
    h2: <TagLine />,
    h3: (
        <>
            <TagLine />
            <TagLine />
        </>
    ),
    h4: (
        <>
            <TagLine />
            <TagLine />
            <TagLine />
        </>
    ),
    h5: (
        <>
            <TagLine />
            <TagLine />
            <TagLine />
            <TagLine />
        </>
    ),
    h6: (
        <>
            <TagLine />
            <TagLine />
            <TagLine />
            <TagLine />
            <TagLine />
        </>
    ),
    p: (
        <>
            <TagLine />
            <TagLine />
            <TagLine />
            <TagLine />
            <TagLine />
            <TagLine />
        </>
    ),
};
