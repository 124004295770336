import React, { useState } from 'react';

import { Table, Typography, Badge } from 'antd';

import { sortUsersByFieldAndOrder } from '../../../../../utils/helpers/sortUsersByFieldAndOrder';
import { getUsersStatusLabel } from '../../../../../utils/helpers/getUsersStatusLabel';
import { getRoleLabel } from '../../../../../utils/helpers/getRoleLabel';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import { sortOrders } from '../../../../../constants/sortOrders';

import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';

import { accountUsersSortFields } from '../../../account/users/constants/accountUsersSortFields';

import UserActionsDropdown from './UserActionsDropdown';

const classes = {
    tableHeaderTitle:
        'd-flex align-items-center users-table-header-title cursor-pointer',
};

const ExpandedUsersTable = ({ usersData, showActions = true }) => {
    const [usersSort, setUsersSort] = useState({
        field: null,
        order: sortOrders.desc,
    });

    const handleSort = (sortField) => {
        setUsersSort({
            field: sortField,
            order:
                usersSort.field === sortField &&
                usersSort.order === sortOrders.desc
                    ? sortOrders.asc
                    : sortOrders.desc,
        });
    };

    const columns = [
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.name)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.name &&
                            'active'
                        }
                    >
                        FULL NAME
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.name
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'full_name',
            render: (_, record) => {
                return record?.full_name;
            },
            width: '30%',
            fixed: 'left',
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.email)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.email &&
                            'active'
                        }
                    >
                        EMAIL
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.email
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'email',
            render: (_, record) => {
                return (
                    <>
                        {record.email}
                        {record.invite && (
                            <Badge
                                color={colorPalette.colorBadgeGray}
                                count='Pending invite'
                            />
                        )}
                    </>
                );
            },
            width: '30%',
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.role)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.role &&
                            'active'
                        }
                    >
                        ROLE
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.role
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'role',
            render: (role) => {
                return getRoleLabel(role);
            },
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.status)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.status &&
                            'active'
                        }
                    >
                        STATUS
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.status
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'status',
            render: (_, record) => {
                return getUsersStatusLabel(record?.active, record?.invite);
            },
        },
    ];

    if (showActions) {
        columns.push({
            title: 'ACTIONS',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <div className='actions-cell'><UserActionsDropdown user={record} /></div>
            },
        });
    }

    return (
        <Table
            dataSource={[
                ...sortUsersByFieldAndOrder(
                    usersData,
                    usersSort.field,
                    usersSort.order
                ),
            ]}
            columns={columns}
            bordered
            pagination={false}
            showSorterTooltip={false}
            scroll={{
                x: true,
            }}
        />
    );
};

export default ExpandedUsersTable;
