import React from 'react';

const SiderChevronLeftIcon = ({ color = '#327EEF', strokeWidth = '1.2' }) => {
    return (
        <svg
            width='6'
            height='10'
            viewBox='0 0 6 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ marginRight: '1px' }}
        >
            <path
                d='M4.75 8.5L1.25 5L4.75 1.5'
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap='square'
            />
        </svg>
    );
};

export default SiderChevronLeftIcon;
