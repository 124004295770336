import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Menu } from 'antd';

import ContentOptimizerIcon from '../../../resources/icons/ContentOptimizer';
import ContentOutlineIcon from '../../../resources/icons/viewOnlyNavMenu/ContentOutlineIcon';
import OverviewIcon from '../../../resources/icons/viewOnlyNavMenu/OverviewIcon';
import SeoIcon from '../../../resources/icons/viewOnlyNavMenu/SeoIcon';
import SimularContentIcon from '../../../resources/icons/viewOnlyNavMenu/SimularContentIcon';

import { colorPalette } from '../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { setActiveCard } from '../../pages/contentOptimizer/viewOnly/store/viewOnlySettings.actions';
import {
    selectViewOnlyActiveCard,
    selectViewOnlyBriefData,
    selectViewOnlyOutlineData,
} from '../../pages/contentOptimizer/viewOnly/store/viewOnlySettings.selectors';

const ViewOnlyNavMenu = ({ collapsed }) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const activeCard = useSelector(selectViewOnlyActiveCard);

    const viewOnlyBriefData = useSelector(selectViewOnlyBriefData);
    const viewOnlyOutlineData = useSelector(selectViewOnlyOutlineData);

    useEffect(() => {
        // Open `Content Optimizer` menu item if previous data is empty
        if (viewOnlyBriefData?.extras && viewOnlyOutlineData?.outline) {
            const obj = viewOnlyBriefData?.extras;

            let allNull = true;

            for (let key in obj) {
                if (
                    Array.isArray(obj[key])
                        ? obj[key]?.length > 0
                        : Boolean(obj[key])
                ) {
                    allNull = false;

                    break;
                }
            }

            if (allNull && viewOnlyOutlineData?.outline?.length < 1) {
                dispatch(setActiveCard('contentOptimizer'));
            }
        }

        // eslint-disable-next-line
    }, [viewOnlyBriefData, viewOnlyOutlineData]);

    const getItemColor = (key) => {
        if (key === activeCard) {
            return colorPalette.colorPrimary;
        } else if (!darkMode) {
            return colorPalette.iconColor.light;
        } else {
            return colorPalette.iconColor.dark;
        }
    };

    const navMenuItems = [
        {
            label: (
                <Link>
                    <div className='menu-icon'>
                        <OverviewIcon color={getItemColor('overview')} />
                    </div>
                    {!collapsed && 'Overview'}
                </Link>
            ),
            key: 'overview',
            title: '',
        },
        {
            label: (
                <Link>
                    <div className='menu-icon'>
                        <SeoIcon color={getItemColor('seo')} />
                    </div>
                    {!collapsed && 'SEO'}
                </Link>
            ),
            key: 'seo',
            title: '',
        },
        {
            label: (
                <Link>
                    <div className='menu-icon'>
                        <SimularContentIcon
                            color={getItemColor('similar-content')}
                        />
                    </div>
                    {!collapsed && 'Similar Content'}
                </Link>
            ),
            key: 'similar-content',
            title: '',
        },
        {
            label: (
                <Link>
                    <div className='menu-icon'>
                        <ContentOutlineIcon
                            color={getItemColor('content-outline')}
                        />
                    </div>
                    {!collapsed && 'Content Outline'}
                </Link>
            ),
            key: 'content-outline',
            title: '',
        },
        {
            label: (
                <Link>
                    <div className='menu-icon'>
                        <ContentOptimizerIcon
                            color={getItemColor('contentOptimizer')}
                        />
                    </div>
                    {!collapsed && 'Content Optimizer'}
                </Link>
            ),
            key: 'contentOptimizer',
            title: '',
        },
    ];

    const handleSelect = ({ key }) => {
        dispatch(setActiveCard(key));
    };

    return (
        <Menu
            defaultSelectedKeys={[activeCard]}
            selectedKeys={[activeCard]}
            mode='inline'
            items={navMenuItems}
            onSelect={handleSelect}
        />
    );
};

export default ViewOnlyNavMenu;
