import React from 'react';

import { colorPalette } from '../../styles/colorPalette';

const AIInsertBelowIcon = ({ color = colorPalette.textColor.light }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            version='1.1'
            x='0px'
            y='0px'
            viewBox='0 1 20 24'
            style={{ enableBackground: 'new 0 0 24 24' }}
            xmlSpace='preserve'
            className='fill-icon'
        >
            <style type='text/css'>{`.st0{fill:${color};}`}</style>
            <g>
                <path
                    className='st0'
                    d='M20.4,14.3H6.6c-2.1,0-3.8-1.7-3.8-3.8V7.3c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v3.3c0,1.2,1,2.2,2.2,2.2   h13.8c0.4,0,0.8,0.3,0.8,0.8S20.8,14.3,20.4,14.3z'
                />
                <path
                    className='st0'
                    d='M17.3,17.5c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l2.6-2.6L16.7,11c-0.3-0.3-0.3-0.8,0-1.1   c0.3-0.3,0.8-0.3,1.1,0l3.2,3.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5l-3.2,3.2C17.6,17.4,17.5,17.5,17.3,17.5z'
                />
            </g>
        </svg>
    );
};

export default AIInsertBelowIcon;
