import React from 'react';

import { Typography } from 'antd';

import TickIcon from '../../../resources/icons/versionHistory/TickIcon';
import CrissCrossIcon from '../../../resources/icons/CrissCrossIcon';

import './Lists.scss';

const { Title } = Typography;

const classes = {
    statusListWrapper: 'status-list-wrapper',
};

const StatusList = ({ listItems }) => {
    return (
        <ul className={classes.statusListWrapper}>
            {listItems.map((item, index) => (
                <li key={index}>
                    {item.success ? <TickIcon /> : <CrissCrossIcon />}
                    <Title level={5}>{item.text}</Title>
                </li>
            ))}
        </ul>
    );
};

export default StatusList;
