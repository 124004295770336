import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Modal } from 'antd';

import { selectDarkMode } from '../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import BarLoader from '../../../../../../../../../common/loader/BarLoader';

import { axiosAPI } from '../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { getKeywordUrlErrorMessage } from '../../../../../../../../../../utils/helpers/keywordHelpers';
import { truncateString } from '../../../../../../../../../../utils/helpers/truncateHelper';
import { isUrlValid } from '../../../../../../../../../../utils/helpers/urlHelpers';

import { contentOptimizerPathes } from '../../../../../../../../../../constants/queryPathes';
import { MAX_URL_LENGTH } from './constants';

import CloseIcon from '../../../../../../../../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../../../../../../../../resources/styles/colorPalette';

import { updateEditorVersionImportedUrl } from '../../../store/contentOptimizerEditor.actions';
import {
    selectCurrentEditorId,
    selectCurrentVersionImportedUrl,
} from '../../../store/contentOptimizerEditor.selectors';

import ImportFromUrlForm from './ImportFromUrlForm';
import ImportFromUrlData from './ImportedFromUrlData';

const classes = {
    modalWrapper: 'modal-wrapper import-from-url-modal-wrapper',
    modalWrapperDark: 'modal-wrapper-dark import-from-url-modal-wrapper-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
};

const ImportFromUrlModal = ({ isOpen, handleOpen }) => {
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);
    const currentVersionId = useSelector(selectCurrentEditorId);
    const currentVersionImportedUrl = useSelector(selectCurrentVersionImportedUrl);

    const [importedData, setImportedData] = useState(null);
    const [importedUrl, setImportedUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        handleCloseModal();

        // eslint-disable-next-line
    }, [isOpen]);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        form.resetFields();
        setImportedData(null);
        setIsLoading(false);
        setError(null);
    };

    const setFormError = () => {
        form.setFields([
            {
                name: 'url',
                errors: [getKeywordUrlErrorMessage()],
            },
        ]);
    };

    const handleImport = (values) => {
        const { url, language_code, country_code } = values;

        if (url) {
            if (isUrlValid(url)) {
                setIsLoading(true);
                setImportedUrl(url);

                axiosAPI
                    .get(
                        contentOptimizerPathes.importFromUrl,
                        {
                            params: {
                                url: url,
                                language_code,
                                country_code,
                                version_id: currentVersionId || null,
                            },
                        },
                        { ...getAxiosHeaders() }
                    )
                    .then((result) => {
                        if (result?.data) {
                            setImportedData(result?.data?.content);
                            dispatch(updateEditorVersionImportedUrl(url));
                        } else {
                            setError('No content.');
                        }
                    })
                    .catch((error) => {
                        setError(
                            error?.response?.data?.message ||
                                'Please try again later.'
                        );
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setError('URL is not a valid URL.');
                setFormError();
            }
        } else {
            setError(`URL can't be blank.`);
            setFormError();
        }
    };

    return (
        <Modal
            centered
            forceRender
            title='URL Content Extraction'
            open={isOpen}
            onOk={handleOpen}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            wrapClassName={`${classes.modalWrapper}
                            ${darkMode ? classes.modalWrapperDark : ''}`}
            afterClose={handleCloseModal}
        >
            {!importedData && !isLoading && (
                <ImportFromUrlForm
                    form={form}
                    error={error}
                    setError={setError}
                    importedUrl={currentVersionImportedUrl}
                    handleOpen={handleOpen}
                    handleImport={handleImport}
                />
            )}
            {isLoading && (
                <BarLoader
                    loaderMessage={
                        <>
                            Extracting from{' '}
                            <i>{truncateString(importedUrl, MAX_URL_LENGTH)}</i>
                        </>
                    }
                />
            )}
            {importedData && (
                <ImportFromUrlData
                    importedData={importedData}
                    url={importedUrl}
                    toggleModal={handleOpen}
                />
            )}
        </Modal>
    );
};

export default ImportFromUrlModal;
