import React from 'react';
import { useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import CommonPageLayout from '../../../common/layout/CommonPageLayout';

import ContentOptimizerCreatePageHeader from './content/ContentMonitorCreatePageHeader';

const { Content } = Layout;

const classes = {
    content: 'container',
    contentDark: 'container-dark',
};

const ContentMonitorCreateNewPage = () => {
    const darkMode = useSelector(selectDarkMode);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <ContentOptimizerCreatePageHeader />
            <CommonPageLayout>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                            ${darkMode ? classes.contentDark : ''}`}
                >
                    Create New Monitor Content
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default ContentMonitorCreateNewPage;
