// Key of Content Optimizer tab key in local storage
export const contentOptimizerLSTabKey = 'coTabKey';

export const ContentOptimizerQueueStatus = {
    NOT_STARTED: 'not_started',
    QUEUED: 'queued',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    FAILED: 'failed',
};

export const editorAutoSaveDelay = 1500;
