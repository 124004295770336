import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Checkbox, Typography } from 'antd';

import CloseIcon from '../../../../resources/icons/CloseIcon';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import CustomDropdown from '../../../common/dropdown/CustomDropdown';
import FilterPopoverButon from '../../../common/dropdown/advancedFilters/FilterPopoverButton';

import { intentCategories } from '../../../../constants/intentCategories';

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay keyword-popover-overlay',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
    dropdownOverlay: 'dropdown-menu projects-dropdown-overlay',

    searchIntentFilterCheckbox: 'monitor-users-filter-checkbox',
    searchIntentFilterCheckboxGroup: 'monitor-users-filter-checkbox-group',
};

const defaultSearchIntentLabel = 'Search Intent';

const SearchIntentFilterDropdown = ({
    selectedValues,
    handleSearchIntentFilterChange,
    isActive,
}) => {
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);

    const [searchIntentLabel, setSearchIntentLabel] = useState(
        defaultSearchIntentLabel
    );
    const [selectedSearchIntent, setSelectedSearchIntent] = useState([]);

    const handleClear = () => {
        setSelectedSearchIntent([]);
        setSearchIntentLabel(defaultSearchIntentLabel);
        handleSearchIntentFilterChange([]);
    };

    useEffect(() => {
        if (!selectedValues || !selectedValues.length) {
            setSearchIntentLabel(defaultSearchIntentLabel);
        }
        setSelectedSearchIntent(selectedValues ?? []);
    }, [selectedValues]);

    const handleApply = () => {
        let labelText = defaultSearchIntentLabel;

        if (selectedSearchIntent?.length) {
            labelText +=
                ': ' +
                intentCategories
                    .filter((category) =>
                        selectedSearchIntent.includes(category.key)
                    )
                    .map((category) => {
                        return category.value;
                    });
            if (labelText.endsWith(',')) {
                labelText = labelText.substring(0, labelText.length - 1);
            }
        }

        setSearchIntentLabel(labelText);
        handleSearchIntentFilterChange(selectedSearchIntent || []);
        handleOpen();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleChangeCheckbox = (selectedSearchIntentKeys) => {
        setSelectedSearchIntent(selectedSearchIntentKeys);
    };

    const searchIntentFilterContent = (
        <div onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <Checkbox.Group
                    value={selectedSearchIntent}
                    onChange={handleChangeCheckbox}
                    className={classes.searchIntentFilterCheckboxGroup}
                >
                    {intentCategories.map((category) => (
                        <div
                            className='d-flex flex-center'
                            key={`filter-category-${category.key}`}
                        >
                            <Checkbox
                                value={category.key}
                                className={`${
                                    classes.searchIntentFilterCheckbox
                                } ${darkMode ? 'dark-mode' : ''}`}
                            />
                            <Typography>{category.category}</Typography>
                        </div>
                    ))}
                </Checkbox.Group>
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div className={`${classes.popoverBtn} ${isActive ? 'active' : ''}`}>
            <CustomDropdown
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={
                            selectedValues?.length > 0
                                ? searchIntentLabel
                                : defaultSearchIntentLabel
                        }
                        showArrow
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                withExtraContent
                dropdownContent={searchIntentFilterContent}
                dropdownOverlayClassName={classes.popoverOverlay}
                dropdownTriggersArray={['click']}
                dropdownPlacement='bottom'
                isDropdownOpen={isOpen}
                handleOpenChange={handleOpen}
            />
            {selectedValues?.length > 0 && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default SearchIntentFilterDropdown;
