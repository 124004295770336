import { passwordPattern } from './regularExpression';

export const formEmailRules = [
    {
        type: 'email',
        message: 'Please enter a valid email',
    },
    {
        required: true,
        message: 'This field is required',
    },
];

export const formPasswordRules = [
    {
        required: true,
        message: 'This field is required',
    },
    {
        pattern: passwordPattern,
        message:
            'Password must contain at least 8 characters, including upper/lowercase letters, numbers & symbols',
    },
];
