export const notificationType = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
};

export const errorNotificationMessage =
    'It looks like something went wrong. Please refresh the page and try again.';

export const commonNotificationMessage = {
    canceledTrial:
        'You have canceled your trial subscription. Please resume your trial subscription or purchase another plan.',
};
