import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import ErrorMessageIcon from '../../../resources/icons/ErrorMessageIcon';

import './ErrorMessage.scss';

const { Title } = Typography;

const classes = {
    errorMessageWrapper: 'error-message-wrapper d-flex align-items-center w-100',
    errorMessageTextWrapper: 'error-message-text-wrapper',
    errorMessageTitle: 'error-message-text fw-500',
    errorMessageText: 'error-message-text',
};

const ErrorMessage = ({
    errorTitle = 'Looks like something went wrong',
    errorMessage = 'Please try again',
}) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.errorMessageWrapper}
                        ${darkMode ? 'error-message-wrapper-dark' : ''}`}
        >
            <ErrorMessageIcon />
            <div className={classes.errorMessageTextWrapper}>
                <Title
                    level={5}
                    className={classes.errorMessageTitle}
                >
                    {errorTitle}
                </Title>
                <Typography className={classes.errorMessageText}>
                    {errorMessage}
                </Typography>
            </div>
        </div>
    );
};

export default ErrorMessage;
