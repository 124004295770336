import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Spin } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../constants/statusCodes';

import { useOrganizationSlug } from '../../../../../../../../utils/hooks/useOrganizationSlug';
import { openNotification } from '../../../../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../../../../utils/helpers/getAxiosHeaders';
import { getRandomId } from '../../../../../../../../utils/helpers/idGenerator';
import { axiosAPI } from '../../../../../../../../utils/axiosAPI';

import Loader from '../../../../../../../common/loader/Loader';

import { selectBriefIdOfCurrentContentOptimizer } from '../../../../store/contentOptimizerContent.selectors';

import OptimizerBriefOutline from './OptimizerBriefOutline';
import OptimizerBriefForm from './OptimizerBriefForm';

import './OptimizerBriefContent.scss';

const OptimizerBriefContent = () => {
    const briefId = useSelector(selectBriefIdOfCurrentContentOptimizer);

    const organizationSlug = useOrganizationSlug();

    const [isBriefLoading, setIsBriefLoading] = useState(false);
    const [briefData, setBriefData] = useState(null);
    const [isContentInBrief, setIsContentInBrief] = useState(false);

    const [isOutlineLoading, setIsOutlineLoading] = useState(false);
    const [outlineData, setOutlineData] = useState([]);

    useEffect(() => {
        if (briefId) {
            setIsBriefLoading(true);

            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${briefId}/versions`,
                    {
                        ...getAxiosHeaders(),
                        params: {
                            slug: organizationSlug,
                            active: true,
                        },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setBriefData(result?.data[0]);
                        setIsContentInBrief(
                            checkBriefDataHasValues(result?.data[0].extras)
                        );
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsBriefLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [briefId]);

    useEffect(() => {
        if (briefId) {
            setIsOutlineLoading(true);

            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${briefId}/outlines`,
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setOutlineData(
                            result?.data[0].outline
                                ? JSON.parse(result?.data[0].outline)
                                : []
                        );
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsOutlineLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [briefId]);

    const checkBriefDataHasValues = (briefExtras) => {
        if (briefExtras) {
            return Object.values(briefExtras).some((value) => {
                return value && value.length > 0;
            });
        }
    };

    const getOutlineData = () => {
        if (briefData?.extras?.page_title) {
            return [
                {
                    id: getRandomId(),
                    tag: 'h1',
                    title: briefData?.extras?.page_title || 'N/A',
                },
                ...outlineData,
            ];
        }

        return outlineData;
    };

    return (
        <div className='optimizer-brief-tab-content'>
            <Spin
                spinning={isBriefLoading || isOutlineLoading}
                indicator={<Loader />}
            >
                <OptimizerBriefForm
                    briefData={briefData}
                    isContentInBrief={isContentInBrief}
                    setBriefData={setBriefData}
                />
                <OptimizerBriefOutline
                    outlineData={getOutlineData()}
                    setOutlineData={setOutlineData}
                />
            </Spin>
        </div>
    );
};

export default OptimizerBriefContent;
