export const months = {
    january: 'Jan',
    february: 'Feb',
    march: 'Mar',
    april: 'Apr',
    may: 'May',
    june: 'Jun',
    july: 'Jul',
    august: 'Aug',
    september: 'Sep',
    october: 'Oct',
    november: 'Nov',
    december: 'Dec',
};

export const monthsList = [
    months.january,
    months.february,
    months.march,
    months.april,
    months.may,
    months.june,
    months.july,
    months.august,
    months.september,
    months.october,
    months.november,
    months.december,
];
