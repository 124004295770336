import React from 'react';

const RankabilityRoundedDarkLogoIcon = ({ width = '40' }) => {
    return (
        <svg
            width={width}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1671.26 1671.25'
        >
            <defs>
                <style>
                    {`.aRankabilityRoundedDarkLogoIcon {
                        fill: #f3f7fd;
                    }

                    .bRankabilityRoundedDarkLogoIcon {
                        fill: #327eef;
                    }`}
                </style>
            </defs>
            <path
                className='aRankabilityRoundedDarkLogoIcon'
                d='M835.63,1918C374.13,1918,0,2292.08,0,2753.59s374.13,835.62,835.63,835.62,835.63-374.12,835.63-835.62S1297.13,1918,835.63,1918Zm0,1472.67c-351.83,0-637-285.22-637-637.05s285.17-637,637-637,637.05,285.22,637.05,637.06S1187.46,3390.63,835.63,3390.63Z'
                transform='translate(0 -1917.96)'
            />
            <path
                className='bRankabilityRoundedDarkLogoIcon'
                d='M947.83,2934.77c24.22,1.86,47.75,7.12,66.56,20.84,22.6,16.48,28.28,46.35,24,72.58-4.68,28.32-18.62,51.94-47.62,59.5-13.91,3.63-28.47,4.23-42.78,4.23H548c-17,0-34.75-2.62-50.42-9.44-31.94-13.89-45.52-47.06-39.72-80.43,3.47-20,10.42-41.54,27.56-54,20.84-15.07,52.13-13.72,76.58-13.72h40.8V2587.79h-6.17c-24,0-49.25,2.48-72.24-5.89-27.22-9.91-38.66-34.86-41.21-62.29-2.37-25.56,1.38-51.68,21.67-69.49q22.6-19.85,61-19.86H724.63c13.92,0,27.18,4.41,34.83,16.7,19.32,31,8.23,70.81,5.58,104.53-.47,6-.69,11.2-.69,15.76q6.84-6.84,14.38-13.7c5-4.56,10.28-9.12,15.76-13.7A783.81,783.81,0,0,1,913,2450.12c73.16-45.44,206.34-67.16,268.51,8.91q21.21,26,26.71,54.79a61.69,61.69,0,0,0,.68,8.9,63.3,63.3,0,0,1,.69,8.91v7.53a24.55,24.55,0,0,1-1.37,7.54,84.48,84.48,0,0,1-13,33.56q-10.28,15.76-28.08,26.71l-5.48,2.74-5.48,2.74a212.77,212.77,0,0,1-23.29,8.91,69,69,0,0,1-20.55,3.42,93.73,93.73,0,0,1-37-7.53q-17.82-7.52-35.62-17.13a6.59,6.59,0,0,1-3.42-1.37,20.63,20.63,0,0,1-3.43-2.74q-13.71-6.83-27.4-12.33a103.2,103.2,0,0,0-28.76-6.85h-5.48c-3.66,0-7.77.24-12.33.69a89.11,89.11,0,0,0-12.25,2.11q-30.15,8.2-58.91,28.76a639.35,639.35,0,0,0-54.79,43.84q-15.09,13.71-28.09,26.71l-24,24v231.51h98C900,2934.38,924.25,2933,947.83,2934.77Z'
                transform='translate(0 -1917.96)'
            />
        </svg>
    );
};

export default RankabilityRoundedDarkLogoIcon;
