export const getNumWithZeroAfterDecimal = (num) => {
    if (!num) return 0;

    let formattedNum = Number(num);
    const res = num.toString().split('.');

    if (res?.length === 2 || res[1]?.length > 2) {
        formattedNum = formattedNum.toFixed(2);
    }

    return formattedNum;
};
