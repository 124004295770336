import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Typography } from 'antd';
import update from 'immutability-helper';

import { hTagsLines } from '../../../../../../../../../../../../constants/hTagsLines';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../../../../constants/queryPathes';

import AddIcon from '../../../../../../../../../../../../resources/icons/outline/AddIcon';

import { setRankabilityLoading } from '../../../../../../../../../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectDarkMode,
    selectRankabilityLoading,
} from '../../../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { getRandomId } from '../../../../../../../../../../../../utils/helpers/idGenerator';
import { openNotification } from '../../../../../../../../../../../../utils/helpers/openNotification';
import { useAutoSave } from '../../../../../../../../../../../../utils/hooks/useAutoSave';

import EditableOutlineTag from '../../../../../../../../../../../common/outlineTags/EditableOutlineTag';
import RankabilityLoader from '../../../../../../../../../../../common/rankabilityLoader/RankabilityLoader';

import { versionAutoSaveTime } from '../../../../../../../../../../../common/versionHistory/constants';

import {
    setBriefProgress,
    updateCurrentOutline,
    updateCurrentOutlineLoading,
    updateCurrentOutlineVersionProgress,
    updateCurrentOutlineVersionWordCount,
} from '../../../../store/contentBriefsOutline.actions';
import {
    selectCurrentOutline,
    selectCurrentOutlineId,
    selectCurrentOutlineVersion,
    selectPreviewOutlineVersion,
} from '../../../../store/contentBriefsOutline.selectors';

import {
    outlineTags,
    outlineTagsList,
} from '../../../ranks/constants/constants';

import './OutlineContainer.scss';

const classes = {
    outlineContainer: 'outline-container',
    addNewTag: 'add-new-tag-input',
    rankabilityLoaderWrapper: 'outline-content-rankability-loader-wrapper',

    editableOutlineTag: 'editable-outline-tag',
    prefixTag: 'prefix-tag',
    editableOutlineTagWrapper: 'editable-outline-tag-wrapper',
    bulletedTag: 'bulleted-tag',
};

const OutlineContainer = ({ createNewOutlineVersion }) => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const currentOutlineId = useSelector(selectCurrentOutlineId);
    const currentOutline = useSelector(selectCurrentOutline);
    const darkMode = useSelector(selectDarkMode);
    const outlinePreviewVersion = useSelector(selectPreviewOutlineVersion);
    const activeOutlineVersion = useSelector(selectCurrentOutlineVersion);
    const aiLoading = activeOutlineVersion?.ai_job_id !== null;
    const rankabilityLoading = useSelector(selectRankabilityLoading);

    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [hasContentChanged, setHasContentChanged] = useState(false);

    useEffect(() => {
        if (aiLoading) {
            const updatedCount = rankabilityLoading + Math.random() * 2;

            setTimeout(
                () =>
                    dispatch(
                        setRankabilityLoading(
                            updatedCount < 100 ? updatedCount : 100
                        )
                    ),
                100
            );
        } else {
            dispatch(setRankabilityLoading(0));
        }
        // eslint-disable-next-line
    }, [aiLoading, rankabilityLoading]);

    const handleIncreaseTag = (tagRaw) => {
        !hasContentChanged && setHasContentChanged(true);

        const updatedOutline = currentOutline?.map((item) => {
            if (
                (!selectedTagIds?.some(
                    (selectedTagId) => selectedTagId === item?.id
                ) &&
                    tagRaw?.outlineTagId !== item?.id) ||
                item?.tag === outlineTags.h2
            ) {
                return item;
            }

            const currentTagIndex = outlineTagsList?.indexOf(item?.tag);

            return {
                ...item,
                tag: outlineTagsList[currentTagIndex - 1],
            };
        });

        dispatch(updateCurrentOutline(updatedOutline));
    };

    const handleDecreaseTag = (tagRaw) => {
        !hasContentChanged && setHasContentChanged(true);

        const updatedOutline = currentOutline?.map((item) => {
            if (
                (!selectedTagIds?.some(
                    (selectedTagId) => selectedTagId === item?.id
                ) &&
                    tagRaw?.outlineTagId !== item?.id) ||
                item?.tag === outlineTags.p
            ) {
                return item;
            }

            const currentTagIndex = outlineTagsList?.indexOf(item?.tag);

            return {
                ...item,
                tag: outlineTagsList[currentTagIndex + 1],
            };
        });

        dispatch(updateCurrentOutline(updatedOutline));
    };

    const handleRemoveTag = (tagId) => {
        !hasContentChanged && setHasContentChanged(true);

        if (tagId) {
            const updatedOutline = currentOutline?.filter(
                (item) => item?.id !== tagId
            );

            dispatch(updateCurrentOutline(updatedOutline));
        } else {
            const updatedOutline = currentOutline?.filter(
                (item) =>
                    !selectedTagIds?.some(
                        (selectedId) => selectedId === item?.id
                    )
            );

            dispatch(updateCurrentOutline(updatedOutline));
        }
    };

    const handleAddTag = (tagRaw) => {
        !hasContentChanged && setHasContentChanged(true);

        const tagRowIndex = currentOutline?.findIndex(
            (item) => item?.id === tagRaw?.outlineTagId
        );

        const updatedOutline = [
            ...currentOutline.slice(0, tagRowIndex + 1),
            {
                title: 'New heading',
                tag: outlineTags.h2,
                id: getRandomId(),
            },
            ...currentOutline.slice(tagRowIndex + 1),
        ];

        dispatch(updateCurrentOutline(updatedOutline));
    };

    const handleAddNewTag = () => {
        !hasContentChanged && setHasContentChanged(true);
        const outlineTagId = getRandomId();

        const updatedOutline = [
            ...currentOutline,
            {
                title: 'New heading',
                tag: outlineTags.h2,
                id: outlineTagId,
            },
        ];

        setSelectedTagIds([outlineTagId]);
        dispatch(updateCurrentOutline(updatedOutline));
    };

    const handleTagLabelUpdate = (tagRaw) => {
        !hasContentChanged && setHasContentChanged(true);

        if (tagRaw?.label?.length > 0) {
            const updatedOutline = currentOutline?.map((item) => {
                if (item?.id !== tagRaw?.outlineTagId) {
                    return item;
                }

                return {
                    ...item,
                    title: tagRaw?.label,
                };
            });

            dispatch(updateCurrentOutline(updatedOutline));
        } else {
            handleRemoveTag(tagRaw?.outlineTagId);
        }
    };

    const handleUpdateOutline = (updatedOutline) => {
        if (contentOptimizerId && currentOutlineId && !outlinePreviewVersion) {
            dispatch(updateCurrentOutlineLoading(true));

            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${contentOptimizerId}/outlines/${currentOutlineId}`,
                    {
                        outline: JSON.stringify(updatedOutline || []),
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    batch(() => {
                        dispatch(updateCurrentOutlineLoading(false));
                        dispatch(
                            setBriefProgress(
                                result?.data?.content_brief?.progress
                            )
                        );
                        dispatch(
                            updateCurrentOutlineVersionProgress(
                                result?.data?.content_brief?.progress
                            )
                        );
                        dispatch(
                            updateCurrentOutlineVersionWordCount(
                                result?.data?.word_count
                            )
                        );
                    });
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    dispatch(updateCurrentOutlineLoading(false));
                });
        }
    };

    useAutoSave(
        () => {
            createNewOutlineVersion();
        },
        hasContentChanged ? versionAutoSaveTime : 0
    );

    useEffect(() => {
        if (!outlinePreviewVersion) {
            handleUpdateOutline(currentOutline);
        }

        // eslint-disable-next-line
    }, [currentOutline]);

    const outlineToMap = outlinePreviewVersion
        ? JSON.parse(outlinePreviewVersion?.outline)
        : currentOutline;

    const handleMoveOutlineTag = (result) => {
        !hasContentChanged && setHasContentChanged(true);

        if (!result.destination) {
            return;
        }

        const isSelectedDraggableItem = selectedTagIds?.some(
            (selectedId) => selectedId === result?.draggableId
        );

        if (!isSelectedDraggableItem) {
            dispatch(
                updateCurrentOutline(
                    update(currentOutline, {
                        $splice: [
                            [result?.source?.index, 1],
                            [
                                result?.destination?.index,
                                0,
                                currentOutline[result?.source?.index],
                            ],
                        ],
                    })
                )
            );
        } else {
            let updatedOutline = currentOutline;

            const draggableItem = updatedOutline?.splice(
                result?.source?.index,
                1
            )[0];

            const draggableItems = [
                draggableItem,
                ...updatedOutline.filter((item) =>
                    selectedTagIds?.some(
                        (selectedId) => selectedId === item?.id
                    )
                ),
            ];

            updatedOutline = updatedOutline.filter(
                (item) =>
                    !selectedTagIds?.some(
                        (selectedId) => selectedId === item?.id
                    )
            );

            Array.prototype.splice.apply(
                updatedOutline,
                [result?.destination?.index, 0].concat(draggableItems)
            );

            dispatch(updateCurrentOutline(updatedOutline));
        }
    };

    const handleMoveUpTag = (itemIndex = null) => {
        !hasContentChanged && setHasContentChanged(true);

        if (itemIndex) {
            if (itemIndex > 0) {
                dispatch(
                    updateCurrentOutline(
                        update(currentOutline, {
                            $splice: [
                                [itemIndex, 1],
                                [itemIndex - 1, 0, currentOutline[itemIndex]],
                            ],
                        })
                    )
                );
            }
        } else {
            let updatedOutline = currentOutline;

            updatedOutline?.some((outlineTag, index) => {
                if (
                    selectedTagIds?.some(
                        (selectedId) => selectedId === outlineTag?.id
                    )
                ) {
                    if (index > 0) {
                        updatedOutline = update(updatedOutline, {
                            $splice: [
                                [index, 1],
                                [index - 1, 0, updatedOutline[index]],
                            ],
                        });
                    } else {
                        return true;
                    }
                }

                return false;
            });

            dispatch(updateCurrentOutline(updatedOutline));
        }
    };

    const handleMoveDownTag = (itemIndex = null) => {
        !hasContentChanged && setHasContentChanged(true);

        if (itemIndex) {
            if (itemIndex < currentOutline?.length - 1) {
                dispatch(
                    updateCurrentOutline(
                        update(currentOutline, {
                            $splice: [
                                [itemIndex, 1],
                                [itemIndex + 1, 0, currentOutline[itemIndex]],
                            ],
                        })
                    )
                );
            }
        } else {
            const isHasLastElement = currentOutline?.some(
                (outlineTag, index) => {
                    return selectedTagIds?.some(
                        (selectedId) =>
                            selectedId === outlineTag?.id &&
                            index === currentOutline?.length - 1
                    );
                }
            );

            if (!isHasLastElement) {
                let updatedOutline = currentOutline.slice().reverse();

                updatedOutline?.forEach((outlineTag, index) => {
                    if (
                        selectedTagIds?.some(
                            (selectedId) => selectedId === outlineTag?.id
                        )
                    ) {
                        updatedOutline = update(updatedOutline, {
                            $splice: [
                                [index, 1],
                                [index - 1, 0, updatedOutline[index]],
                            ],
                        });
                    }
                });

                dispatch(
                    updateCurrentOutline(updatedOutline.slice().reverse())
                );
            }
        }
    };

    const handleSetBulletTag = (updatedItem) => {
        !hasContentChanged && setHasContentChanged(true);

        if (updatedItem?.id) {
            dispatch(
                updateCurrentOutline(
                    currentOutline?.map((item) => {
                        if (item?.id !== updatedItem?.id) {
                            return item;
                        }

                        return {
                            ...item,
                            ...updatedItem,
                        };
                    })
                )
            );
        } else {
            dispatch(
                updateCurrentOutline(
                    currentOutline?.map((item) => {
                        if (
                            selectedTagIds?.some(
                                (selectedId) => selectedId === item?.id
                            )
                        ) {
                            return {
                                ...item,
                                isBulletedTag: updatedItem?.isBulletedTag,
                            };
                        }

                        return item;
                    })
                )
            );
        }
    };

    return aiLoading ? (
        <div className={classes.rankabilityLoaderWrapper}>
            <RankabilityLoader startedStatus={rankabilityLoading} />
        </div>
    ) : (
        <div className={`${classes?.outlineContainer}`}>
            <DragDropContext onDragEnd={handleMoveOutlineTag}>
                <Droppable
                    key={getRandomId()}
                    droppableId={getRandomId()}
                    renderClone={(provided, _snapshot, rubric) => {
                        const draggableItem = outlineToMap?.find(
                            (outlineTag) =>
                                outlineTag?.id === rubric?.draggableId
                        );

                        const isSelectedTag = selectedTagIds?.some(
                            (selectedTagId) =>
                                selectedTagId === rubric?.draggableId
                        );

                        return (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <div
                                    className={`${
                                        classes.editableOutlineTagWrapper
                                    } ${darkMode ? 'dark-mode' : ''} ${
                                        isSelectedTag
                                            ? classes.selectedOutlineTag
                                            : ''
                                    }`}
                                >
                                    {hTagsLines[draggableItem?.tag]}
                                    <div
                                        className={`${classes.editableOutlineTag}`}
                                    >
                                        {draggableItem?.title && (
                                            <div
                                                className={`${
                                                    classes.prefixTag
                                                } ${draggableItem?.tag} ${
                                                    darkMode ? 'dark-mode' : ''
                                                } ${
                                                    draggableItem?.isBulletedTag
                                                        ? classes.bulletedTag
                                                        : ''
                                                }`}
                                            >
                                                {draggableItem?.isBulletedTag
                                                    ? '•'
                                                    : draggableItem?.tag.toUpperCase()}
                                            </div>
                                        )}
                                        <Typography
                                            className={`${draggableItem?.tag} ${
                                                darkMode ? 'dark-mode' : ''
                                            }`}
                                        >
                                            {draggableItem?.title}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                >
                    {(provided) => (
                        <div
                            className='outlineTags'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {outlineToMap?.map((item, index) => {
                                const isSelectedTag = selectedTagIds?.some(
                                    (selectedTagId) =>
                                        selectedTagId === item?.id
                                );

                                return (
                                    <EditableOutlineTag
                                        key={getRandomId()}
                                        index={index}
                                        outlineTagId={item?.id}
                                        tag={item?.tag}
                                        label={item?.title}
                                        isBulletedTag={item?.isBulletedTag}
                                        isSelectedTag={isSelectedTag}
                                        handleTagLabelUpdate={
                                            handleTagLabelUpdate
                                        }
                                        handleIncreaseTag={handleIncreaseTag}
                                        handleDecreaseTag={handleDecreaseTag}
                                        handleRemoveTag={handleRemoveTag}
                                        handleAddTag={handleAddTag}
                                        handleMoveUpTag={handleMoveUpTag}
                                        handleMoveDownTag={handleMoveDownTag}
                                        handleSetBulletTag={handleSetBulletTag}
                                        setSelectedTagIds={setSelectedTagIds}
                                    />
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div
                onClick={handleAddNewTag}
                className={`${classes.addNewTag} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                <AddIcon color={darkMode ? '#9d9d9f' : '#728192'} />
                <Typography>Add Heading</Typography>
            </div>
        </div>
    );
};

export default OutlineContainer;
