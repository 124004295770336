export const keywordResearchResultsSortFields = {
    keyword: 'keyword',
    searchIntent: 'search_intent',
    searchVolume: 'search_volume',
    cpc: 'cpc_from',
    difficulty: 'difficulty',
    monthlySearches: 'monthly_searches',
    position: 'position',
    updated: 'last_updated_time',
};
