import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'antd';
import Link from 'antd/es/typography/Link';
import moment from 'moment';

import CloseIcon from '../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../resources/styles/colorPalette';

import {
    selectCurrentUserRoles,
    selectDarkMode,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsAdmin } from '../../../utils/helpers/checkIsAdmin';
import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import {
    selectCurrentSubscription,
    selectOptimizersAdded,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../../pages/account/billing/store/billingSettings.selectors';

const classes = {
    modalWrapper: 'no-credits-modal-wrapper',
    boldText: 'fw-700',
};

const NoCreditsModal = ({
    title = 'Not enough credits',
    isOpen,
    handleOpen,
    keywordsUsed,
    keywordsLimit,
}) => {
    const navigate = useNavigate();

    const organizationSlug = useOrganizationSlug();

    const darkMode = useSelector(selectDarkMode);
    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);
    const currentSubscription = useSelector(selectCurrentSubscription);
    const currentUserRoles = useSelector(selectCurrentUserRoles);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleBillingCreditsNavigate = () => {
        navigate(`/${organizationSlug}/account/billing-credits`);
    };

    return (
        <Modal
            centered
            forceRender
            title={title}
            open={isOpen}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            wrapClassName={`${classes.modalWrapper}
                    ${darkMode ? 'dark-mode' : ''}`}
        >
            <div>
                Your account has{' '}
                <span className={classes.boldText}>
                    {keywordsLimit
                        ? keywordsLimit - keywordsUsed || 0
                        : optimizersLimit + optimizersAdded - optimizersUsed ||
                          0}{' '}
                    of {keywordsLimit || optimizersLimit + optimizersAdded || 0}
                </span>{' '}
                {keywordsLimit ? 'keywords' : 'credits'} remaining.
            </div>
            <div>
                Credits will reset on{' '}
                <span className={classes.boldText}>
                    {currentSubscription
                        ? moment(
                              currentSubscription?.current_period_end
                          ).format('LL')
                        : ''}
                </span>{' '}
                at{' '}
                <span className={classes.boldText}>
                    {currentSubscription
                        ? moment(
                              currentSubscription?.current_period_end
                          ).format('LT')
                        : ''}
                    .
                </span>
            </div>
            {checkIsAdmin(currentUserRoles[0]?.name) && (
                <div>
                    Need more credits?{' '}
                    <Link onClick={handleBillingCreditsNavigate}>
                        View options
                    </Link>
                </div>
            )}
        </Modal>
    );
};

export default NoCreditsModal;
