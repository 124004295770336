import { accountTabs } from '../../../../constants/accountTabs';
import { accountSettingsActions } from './accountSettings.actions';

const accountSettingsInitialState = {
    accountTab: accountTabs.profile.key,
    scrollSection: null,
    users: [],
    invites: [],
};

export const accountSettingsReducer = (
    state = accountSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case accountSettingsActions.UPDATE_ACCOUNT_TAB: {
            return {
                ...state,
                accountTab: payload,
            };
        }

        case accountSettingsActions.UPDATE_SCROLL_SECTION: {
            return {
                ...state,
                scrollSection: payload || null,
            };
        }

        case accountSettingsActions.SET_USERS: {
            return {
                ...state,
                users: payload,
            };
        }

        case accountSettingsActions.UPDATE_USER_ROLE: {
            return {
                ...state,
                users: state.users.map((user) => {
                    if (user.id === payload.id) {
                        return {
                            ...user,
                            roles: [payload.role],
                        };
                    }

                    return user;
                }),
            };
        }

        case accountSettingsActions.UPDATE_USER_FULL_NAME: {
            return {
                ...state,
                users: state.users.map((user) => {
                    if (user.id === payload.id) {
                        return {
                            ...user,
                            first_name: payload.first_name,
                            last_name: payload.last_name,
                        };
                    }

                    return user;
                }),
            };
        }

        case accountSettingsActions.UPDATE_USER_ACTIVE: {
            return {
                ...state,
                users: state.users.map((user) => {
                    if (user.id === payload.id) {
                        return {
                            ...user,
                            active: payload.active,
                        };
                    }

                    return user;
                }),
            };
        }

        case accountSettingsActions.SET_INVITES: {
            return {
                ...state,
                invites: payload,
            };
        }

        case accountSettingsActions.ADD_INVITE: {
            return {
                ...state,
                invites: [payload, ...state.invites],
            };
        }

        case accountSettingsActions.DELETE_INVITE: {
            return {
                ...state,
                invites: state.invites.filter(
                    (invite) => invite.id !== payload
                ),
            };
        }

        case accountSettingsActions.CLEAR_ACCOUNT_SETTINGS: {
            return {
                ...accountSettingsInitialState,
            };
        }

        default:
            return state;
    }
};
