export const selectDarkMode = (state) => state?.browserSettings?.darkMode;
export const selectRememberUserMode = (state) =>
    state?.browserSettings?.rememberUserMode;
export const selectIsSessionCorrect = (state) =>
    state?.browserSettings?.isSessionCorrect;
export const selectViewMode = (state) => state?.browserSettings?.viewMode;
export const selectUserData = (state) => state?.browserSettings?.user;
export const selectUserOrganization = (state) =>
    state?.browserSettings?.user?.organization;
export const selectUserOrganizationSettings = (state) =>
    state?.browserSettings?.user?.organization?.settings;
export const selectUserLanguagePreference = (state) =>
    state?.browserSettings?.user?.organization?.settings?.language_preference;
export const selectSwitchersVisibilitySettings =
    (contentOptimizerId) => (state) =>
        state?.browserSettings?.user?.organization?.settings?.visible_switchers_optimizer_ids?.includes(
            +contentOptimizerId
        );
export const selectUserGoogleSearchEnginePreference = (state) =>
    state?.browserSettings?.user?.organization?.settings
        ?.google_search_engine_preference;
export const selectUserOptimalWordCountRange = (state) =>
    state?.browserSettings?.user?.organization?.settings
        ?.optimal_word_count_range;
export const selectOrganizationId = (state) =>
    state?.browserSettings?.user?.organization?.id;
export const selectErrorStatus = (state) => state?.browserSettings?.errorStatus;
export const selectCurrentUserRoles = (state) =>
    state?.browserSettings?.user?.roles;
export const selectIsSubscriptionExpired = (state) =>
    state?.browserSettings?.isSubscriptionExpired;
export const selectIsGracePeriod = (state) =>
    state?.browserSettings?.isGracePeriod;

export const selectRankabilityLoading = (state) =>
    state?.browserSettings?.rankabilityLoading;
