import React from 'react';
import { useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import ErrorMessage from '../../../common/errorMessage/ErrorMessage';
import CustomHeader from '../../../common/header/Header';

import './SubscriptionExpiredNotificationPage.scss';

const { Content } = Layout;

const classes = {
    content: 'container account-notification-container',
    contentDark: 'container-dark',
};

const SubscriptionExpiredNotificationPage = () => {
    const darkMode = useSelector(selectDarkMode);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            />
            <Content
                style={{
                    background: colorBgContainer,
                }}
                className={`${classes.content} 
                            ${darkMode ? classes.contentDark : ''}`}
            >
                <ErrorMessage
                    errorTitle='Your subscription has expired.'
                    errorMessage='Please contact your administrator or sign up here.'
                />
            </Content>
        </>
    );
};

export default SubscriptionExpiredNotificationPage;
