export const checkBriefOptimal = (value, requirements, primaryKeyword) => {
    const optimalMin = requirements?.characters?.min;
    const optimalMax = requirements?.characters?.max;
    const checkPrimaryKeyword =
        requirements?.primaryKeyword && !requirements?.url;
    const checkURLPrimaryKeyword =
        requirements?.primaryKeyword && requirements?.url;

    let checkedValue;

    if (typeof value === 'string') {
        checkedValue = value?.trim()?.length;
    } else if (typeof value === 'number') {
        checkedValue = value || 0;
    } else {
        checkedValue = value ? value?.length : '';
    }

    const checkForCharacters = () => {
        if (optimalMin && optimalMax) {
            return checkedValue >= optimalMin && checkedValue <= optimalMax;
        } else if (optimalMin) {
            return checkedValue >= optimalMin;
        } else if (optimalMax) {
            return checkedValue <= optimalMax;
        } else {
            return false;
        }
    };

    const checkForPrimaryKeyword = () => {
        if (value && typeof value === 'string') {
            const lowerCaseValue = value.toLowerCase();

            if (checkPrimaryKeyword) {
                return lowerCaseValue.includes(primaryKeyword?.toLowerCase());
            } else if (checkURLPrimaryKeyword) {
                return (
                    lowerCaseValue
                        .replace(/[^\w]/g, '')
                        .includes(
                            primaryKeyword?.toLowerCase().replace(/[^\w]/g, '')
                        )
                );
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    return {
        characters: checkForCharacters(),
        primaryKeyword: checkForPrimaryKeyword(),
        all: checkForCharacters() && checkForPrimaryKeyword(),
    };
};
