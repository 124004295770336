import React from 'react';
import { Typography } from 'antd';

import CustomHeader from '../../../common/header/Header';

import OrganizationPageContent from './content/OrganizationPageContent';

import './OrganizationsPage.scss';

const { Title } = Typography;

const classes = {
    pageLayoutWrapper: 'page-layout-wrapper',
    organizationTitleWrapper: 'page-title-wrapper',
    pageTitle: 'page-title',
};

const OrganizationPage = () => {
    return (
        <>
            <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            />

            <div className={classes.pageLayoutWrapper}>
                <div className={classes.organizationTitleWrapper}>
                    <Title level={2} className={classes.pageTitle}>
                        Organizations
                    </Title>
                </div>
                <OrganizationPageContent />
            </div>
        </>
    );
};

export default OrganizationPage;
