import { contentOptimizerKeywordSource } from '../../constants/contentOptimizerKeywordSource';
import { optimizerKeywordsSortKeys } from '../../constants/optimizerKeywordsSortItems';

export const isMainKeywordSource = (source = '') =>
    source === contentOptimizerKeywordSource.main;

export const isCustomKeywordSource = (source = '') =>
    source === contentOptimizerKeywordSource.custom;

export const getSortedOptimizerKeywords = (keywords, sortKey) => {
    const mainKeywordList = keywords.filter((keyword) =>
        isMainKeywordSource(keyword.source)
    );
    const sortedByImportanceKeywords = keywords.sort(
        (a, b) => b.importance - a.importance
    );

    switch (sortKey) {
        case optimizerKeywordsSortKeys.importance:
            return mainKeywordList.concat(
                sortedByImportanceKeywords.filter(
                    (keyword) => !isMainKeywordSource(keyword.source)
                )
            );

        case optimizerKeywordsSortKeys.heading:
            return sortedByImportanceKeywords.sort(
                (a, b) => b.heading_presence - a.heading_presence
            );

        case optimizerKeywordsSortKeys.unused:
            return sortedByImportanceKeywords.sort((a, b) => {
                if (a.used && !b.used) {
                    return 1;
                }
                if (!a.used && b.used) {
                    return -1;
                }
                return 0;
            });

        default:
            return keywords;
    }
};
