import { createContext, memo } from 'react';

import { UpolloClient } from '@upollo/web';

const UppoloContext = createContext();

const withUppoloProvider = (ComposedComponent) => {
    return memo((props) => {
        const uppoloApiKey = process.env.REACT_APP_UPPOLO_PUBLIC_KEY;
        const upollo = uppoloApiKey ? new UpolloClient(uppoloApiKey) : null;

        return (
            <UppoloContext.Provider value={upollo}>
                <ComposedComponent {...props} />
            </UppoloContext.Provider>
        );
    });
};

export { UppoloContext, withUppoloProvider };
