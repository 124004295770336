import React from 'react';

const MoreHorizontalIcon = ({ color = '#728192' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.0001 10.8337C10.4603 10.8337 10.8334 10.4606 10.8334 10.0003C10.8334 9.54009 10.4603 9.16699 10.0001 9.16699C9.53984 9.16699 9.16675 9.54009 9.16675 10.0003C9.16675 10.4606 9.53984 10.8337 10.0001 10.8337Z'
                fill={color}
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.8333 10.8337C16.2936 10.8337 16.6667 10.4606 16.6667 10.0003C16.6667 9.54009 16.2936 9.16699 15.8333 9.16699C15.3731 9.16699 15 9.54009 15 10.0003C15 10.4606 15.3731 10.8337 15.8333 10.8337Z'
                fill={color}
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M4.16659 10.8337C4.62682 10.8337 4.99992 10.4606 4.99992 10.0003C4.99992 9.54009 4.62682 9.16699 4.16659 9.16699C3.70635 9.16699 3.33325 9.54009 3.33325 10.0003C3.33325 10.4606 3.70635 10.8337 4.16659 10.8337Z'
                fill={color}
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default MoreHorizontalIcon;
