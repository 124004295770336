import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Tooltip, Typography } from 'antd';

import { difficultyBadgeSize } from '../../../../../../../../../../../constants/difficultyBadgeSize';

import defaultFavicon from '../../../../../../../../../../../resources/images/default_favicon.png';
import { colorPalette } from '../../../../../../../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getFaviconUrl } from '../../../../../../../../../../../utils/helpers/getFaviconUrl';
import { getNumberWithCommaSeparator } from '../../../../../../../../../../../utils/helpers/getNumberWithCommaSeparator';

import CustomBadge from '../../../../../../../../../../common/badge/CustomBadge';
import CompleteBadge from '../../../../../../../../../../common/completeBadge/CompleteBadge';

const { Link } = Typography;

const classes = {
    rankTitle: 'rank-title',
    rankNumber: 'rank-number',
    rankTitleData: 'rank-title-data',
    mainTypography: 'main-typography',
    subtitleURL: 'subtitle-url',
    rankBadge: 'rank-badge',
    rankFavicon: 'rank-favicon',
    rankWordCount: 'rank-word-count',
    tooltipOverlay: 'tooltip-overlay rank-title-tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const RankTitle = ({ rank }) => {
    const rankTitleRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        if (rankTitleRef.current) {
            setIsOverflowed(
                rankTitleRef.current.offsetWidth <
                    rankTitleRef.current.scrollWidth
            );
        }
    }, [rankTitleRef]);

    return (
        <div className={`${classes.rankTitle}`}>
            <div className={`${classes.rankTitleData}`}>
                <div>
                    <Typography
                        className={`${classes.mainTypography}`}
                        ref={rankTitleRef}
                    >
                        <img
                            className={classes.rankFavicon}
                            alt=''
                            src={rank?.favicon || getFaviconUrl(rank?.url)}
                            onError={(e) => {
                                e.target.src = defaultFavicon;
                            }}
                            width={16}
                            height={16}
                        />
                        {isOverflowed ? (
                            <Tooltip
                                title={rank?.title}
                                placement='topLeft'
                                overlayClassName={`${classes.tooltipOverlay}
                                                   ${
                                                       darkMode &&
                                                       classes.tooltipOverlayDark
                                                   }`}
                                overlayStyle={{
                                    maxWidth: `${
                                        rankTitleRef.current?.offsetWidth - 10
                                    }px`,
                                }}
                            >
                                <Link href={rank?.url} target='_blank'>
                                    {rank?.title}
                                </Link>
                            </Tooltip>
                        ) : (
                            <Link href={rank?.url} target='_blank'>
                                {rank?.title}
                            </Link>
                        )}
                    </Typography>
                    <Typography
                        className={`${darkMode ? 'dark-mode' : ''} ${
                            classes.subtitleURL
                        }`}
                    >
                        {rank?.url}
                    </Typography>
                </div>
                <Row align='middle' justify='space-between'>
                    <Col>
                        {rank?.rank?.desktop && (
                            <span className={classes.rankBadge}>
                                <CustomBadge
                                    backgroundColor={
                                        darkMode
                                            ? colorPalette.secondaryBadgeBg.dark
                                            : colorPalette.secondaryBadgeBg
                                                  .light
                                    }
                                    color={
                                        darkMode
                                            ? colorPalette.textAccentColor.dark
                                            : colorPalette.textAccentColor.light
                                    }
                                    label={`#${rank?.rank?.desktop} desktop`}
                                />
                            </span>
                        )}
                        {rank?.rank?.mobile && (
                            <span className={classes.rankBadge}>
                                <CustomBadge
                                    backgroundColor={
                                        darkMode
                                            ? colorPalette.secondaryBadgeBg.dark
                                            : colorPalette.secondaryBadgeBg
                                                  .light
                                    }
                                    color={
                                        darkMode
                                            ? colorPalette.textAccentColor.dark
                                            : colorPalette.textAccentColor.light
                                    }
                                    label={`#${rank?.rank?.mobile} mobile`}
                                />
                            </span>
                        )}
                    </Col>
                    <Col>
                        <Typography
                            className={`${darkMode ? 'dark-mode' : ''} ${
                                classes.rankWordCount
                            }`}
                        >
                            Word Count:{' '}
                            {getNumberWithCommaSeparator(rank?.word_count || 0)}{' '}
                            •
                        </Typography>
                        <CompleteBadge
                            inline={true}
                            size={difficultyBadgeSize.small}
                            progress={Math.ceil(rank?.score)}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RankTitle;
