import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
		errorNotificationMessage,
		notificationType,
} from '../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../../constants/statusCodes';

import { axiosAPI } from '../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../../../utils/helpers/openNotification';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import {
		setBriefVersions,
		setCurrentBriefId,
} from '../store/contentBriefsOutline.actions';
import { selectCurrentBrief } from '../store/contentBriefsOutline.selectors';

import BriefForm from './BriefForm';
import BriefVersionHistoryContainer from './BriefVersionHistoryContainer';

import './BriefSummary.scss';

const classes = {
		content: 'container content-briefs-keywords-container',
		contentDark: 'container-dark content-briefs-keywords-container-dark',

		headerWrapper: 'content-briefs-keywords-header-wrapper',
		progressWrapper: 'progress-wrapper',
		switcherWrapper: 'switcher-wrapper w-100',
		sectionWrapper: 'section-wrapper',
		rightMenuWrapper: 'right-menu-wrapper section-wrapper',
};

const BriefSummaryContainer = () => {
	const dispatch = useDispatch();

	const { contentOptimizerId } = useParams();

	const darkMode = useSelector(selectDarkMode);

	const [isNewVersionsCreating, setIsNewVersionCreating] = useState(false);

	const currentBrief = useSelector(selectCurrentBrief);

	const createNewBriefVersion = () => {
		if (contentOptimizerId) {
			setIsNewVersionCreating(true);

			axiosAPI
				.post(
					`${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/versions`,
					{
						extras: currentBrief?.extras,
					},
					{
						...getAxiosHeaders(),
					}
				)
				.then(() => {
					fetchBriefVersions();
				})
				.catch(() => {
					setIsNewVersionCreating(false);
					openNotification(
						notificationType.error,
						"Error",
						errorNotificationMessage
					);
				});
		}
	};

	const fetchBriefVersions = () => {
		if (contentOptimizerId) {
			axiosAPI
				.get(
					`${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/versions`,
					{
						...getAxiosHeaders(),
					}
				)
				.then((result) => {
					if (result?.status === statusCodes.success) {
						if (result?.data?.length !== 0) {
							dispatch(setBriefVersions(result?.data));
						} else {
							createNewBriefVersion();
						}

						dispatch(setCurrentBriefId(Number.parseInt(contentOptimizerId)));
					}
				})
				.catch(() => {
					openNotification(
						notificationType.error,
						"Error",
						errorNotificationMessage
					);
				})
				.finally(() => {
					setIsNewVersionCreating(false);
				});
		}
	};

	useEffect(() => {
		fetchBriefVersions();

		// eslint-disable-next-line
	}, [contentOptimizerId]);

	return (
		<>
			<BriefVersionHistoryContainer
				createNewBriefVersion={createNewBriefVersion}
				fetchBriefVersions={fetchBriefVersions}
				newVersionCreating={isNewVersionsCreating}
			/>
            <div
                className={`${classes.content} ${
                    darkMode ? classes.contentDark : ""
                }`}
            >
                <div className={classes.sectionWrapper}>
                    <BriefForm createNewBriefVersion={createNewBriefVersion} />
                </div>
            </div>
		</>
	);
};

export default BriefSummaryContainer;
