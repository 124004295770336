import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popconfirm, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setUserData } from '../../../../../userBrowserSettings/store/browserSettings.actions';

import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { organizationPathes } from '../../../../../constants/queryPathes';

import { colorPalette } from '../../../../../resources/styles/colorPalette';

const { Link } = Typography;

const classes = {
    deletePopconfirm: 'delete-popconfirm',
    link: 'link-hover-highlight',
};

const DeleteAdvancedSettingPopconfirm = ({
    settingValue,
    fieldName,
    title,
}) => {
    const dispatch = useDispatch();

    const user = useSelector(selectUserData);
    const darkMode = useSelector(selectDarkMode);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleDelete = () => {
        setIsDeleteLoading(true);

        axiosAPI
            .patch(
                `${organizationPathes.updateOrganization}/${user.organization.id}`,
                {
                    settings: {
                        [fieldName]: [
                            ...user?.organization?.settings?.[fieldName],
                        ].filter((item) => item[title] !== settingValue),
                    },
                },
                { ...getAxiosHeaders() }
            )
            .then((res) => {
                dispatch(
                    setUserData({
                        ...user,
                        organization: {
                            ...user.organization,
                            settings: res?.data?.settings,
                        },
                    })
                );
                openNotification(
                    notificationType.success,
                    'Success',
                    'Information has been updated successfully'
                );
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });
    };

    return (
        <Popconfirm
            placement='topRight'
            title='Are you sure you want to delete?'
            onConfirm={handleDelete}
            okText='Yes'
            cancelText='Cancel'
            loading={isDeleteLoading}
            overlayClassName={`${classes.deletePopconfirm}
                               ${darkMode ? 'delete-popconfirm-dark' : ''}`}
            icon={
                <ExclamationCircleFilled
                    style={{ color: colorPalette.colorPrimary }}
                />
            }
        >
            <Link
                underline
                className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
            >
                Delete
            </Link>
        </Popconfirm>
    );
};

export default DeleteAdvancedSettingPopconfirm;
