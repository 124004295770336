export const optimizerKeywordsSortItems = [
    {
        key: 'importance',
        label: 'Relevance',
    },
    {
        key: 'heading',
        label: 'Heading',
    },
    {
        key: 'unused',
        label: 'Unused',
    },
];

export const optimizerKeywordsSortKeys = {
    importance: 'importance',
    heading: 'heading',
    unused: 'unused',
};
