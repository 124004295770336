import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Input, Button, Popconfirm } from 'antd';
import moment from 'moment';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { colorPalette } from '../../../../resources/styles/colorPalette';
import EditIcon from '../../../../resources/icons/EditIcon';
import RemoveIcon from '../../../../resources/icons/outline/RemoveIcon';

const classes = {
    mainArticle: 'main-article',
    versionName: 'd-flex version-name',
    copyIcon: 'copy-icon',
    deletePopconfirm: 'delete-popconfirm',
    deletePopconfirmDark: 'delete-popconfirm-dark',
};

const VersionItemName = ({
    version,
    handleUpdateVersionName,
    handleDeleteVersion,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const [isEditable, setIsEditable] = useState(false);
    const [versionName, setVersionName] = useState(version?.name);

    const iconColor = darkMode
        ? colorPalette.textAccentColor.dark
        : colorPalette.textAccentColor.light;

    const toggleEditable = () => {
        setIsEditable(!isEditable);
    };

    const handleChangeVersionName = (e) => {
        setVersionName(e?.target?.value);
    };

    const handleEdit = () => {
        handleUpdateVersionName(version?.id, versionName);
        toggleEditable();
    };

    const handleDelete = () => {
        handleDeleteVersion(version?.id);
    };

    return (
        <>
            {!isEditable && versionName === version?.name ? (
                <div className={classes.versionName}>
                    <Typography
                        className={`${classes.mainArticle} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        {version?.name
                            ? version?.name
                            : `Version created 
                        ${moment.utc(version?.created_at).local().fromNow()}`}
                    </Typography>

                    {handleChangeVersionName && handleDeleteVersion && (
                        <>
                            <div
                                className={classes.copyIcon}
                                onClick={toggleEditable}
                            >
                                <EditIcon color={iconColor} />
                            </div>
                            {!version?.active && (
                                <Popconfirm
                                    title='Are you sure you want to delete?'
                                    onConfirm={handleDelete}
                                    okText='Yes'
                                    cancelText='Cancel'
                                    overlayClassName={`${
                                        classes.deletePopconfirm
                                    }
                                           ${
                                               darkMode
                                                   ? classes.deletePopconfirmDark
                                                   : ''
                                           }`}
                                >
                                    <div className={classes.copyIcon}>
                                        <RemoveIcon color={iconColor} />
                                    </div>
                                </Popconfirm>
                            )}
                        </>
                    )}
                </div>
            ) : (
                <div>
                    <Button
                        type='link'
                        size='small'
                        onClick={handleEdit}
                    >
                        Save
                    </Button>
                    <Input
                        value={versionName}
                        maxLength={255}
                        onChange={handleChangeVersionName}
                    />
                </div>
            )}
        </>
    );
};

export default VersionItemName;
