import { combineReducers } from 'redux';

import { contentMonitorMainReducer } from '../mainPage/store/contentMonitorMain.reducer';
import { contentMonitorCreateReducer } from '../createNew/store/contentMonitorCreate.reducer';
import { contentMonitorContentReducer } from '../contentPage/store/contentMonitorContent.reducer';

export const contentMonitorReducer = combineReducers({
    contentMonitorMain: contentMonitorMainReducer,
    contentMonitorCreate: contentMonitorCreateReducer,
    contentMonitorContent: contentMonitorContentReducer,
});
