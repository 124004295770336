import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { getNumberWithCommaSeparator } from '../../../utils/helpers/getNumberWithCommaSeparator';
import { useOpen } from '../../../utils/hooks/useOpen';

import CustomDropdown from '../dropdown/CustomDropdown';

import SimpleLineChart from './SimpleLineChart';
import TinyAreaChart from './TinyAreaChart';

import './Charts.scss';

const classes = {
    keywordChart: 'keyword-chart',
    keywordChartTitle: 'keyword-chart-title',
    dropdownMenuWrapper: 'd-flex',
    yAxisLabel: 'y-axis-label',
    xAxisLabel: 'x-axis-label',
};

const cailedNumber = (value) => {
    const cailValue = Math.pow(10, String(value)?.length - 1);

    return Math.ceil(value / cailValue) * cailValue;
};

const KeywordChart = ({ data, hoverTitle, dataKey, xAxisDataKey }) => {
    const darkMode = useSelector(selectDarkMode);

    const [topChartPosition, setTopChartPosition] = useState(0);

    const { isOpen, handleOpen } = useOpen();

    const maxDataValue = cailedNumber(
        Math.max(...data?.map((item) => item[dataKey || 'value']))
    );

    const yAxisTicks = [
        0,
        maxDataValue / 4,
        (maxDataValue / 4) * 2,
        (maxDataValue / 4) * 3,
        maxDataValue,
    ];

    const handleChartClick = (e) => {
        setTopChartPosition(e?.target?.getBoundingClientRect()?.top || 0);
    };

    const content = (
        <>
            <Typography
                className={`${classes.keywordChartTitle} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                {hoverTitle || 'Monthly searches between'}
            </Typography>
            <div className={classes.dropdownMenuWrapper}>
                <div className={classes.yAxisLabel}>
                    {yAxisTicks?.reverse()?.map((value, index) => (
                        <Typography key={`chart_axis_${index}`}>
                            {getNumberWithCommaSeparator(value)}
                        </Typography>
                    ))}
                </div>
                <div>
                    <SimpleLineChart data={data} yAxisTicks={yAxisTicks} />
                    <div className={classes.xAxisLabel}>
                        <Typography>
                            {data[0]?.[xAxisDataKey || 'name']}
                        </Typography>
                        <Typography>
                            {data[data?.length - 1]?.[xAxisDataKey || 'name']}
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <CustomDropdown
            withArrow
            withExtraContent
            dropdownOpenElement={
                <div onClick={handleChartClick}>
                    <TinyAreaChart data={data} />
                </div>
            }
            dropdownContent={content}
            dropdownOverlayClassName={classes.keywordChart}
            dropdownTriggersArray={['click']}
            dropdownPlacement={topChartPosition > 385 ? 'top' : 'bottom'}
            handleOpenChange={handleOpen}
            isDropdownOpen={isOpen}
        />
    );
};

export default KeywordChart;
