import React from 'react';
import { useSelector } from 'react-redux';

import {
    selectDarkMode,
    selectIsGracePeriod,
    selectIsSubscriptionExpired,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import BillingPlansContent from './content/BillingPlansContent';
import SubscriptionInfo from './content/SubscriptionInfo';

import './BillingPlansPageContent.scss';

const classes = {
    billingPlansContentWrapper: 'billing-plans-wrapper h-100',
    billingPlansContentWrapperDark: 'billing-plans-wrapper-dark',
    switchedPlanContentWrapper:
        'switched-plan-content-wrapper d-flex align-items-center flex-column h-100',
};

const BillingPlansPageContent = () => {
    const darkMode = useSelector(selectDarkMode);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const isGracePeriod = useSelector(selectIsGracePeriod);

    return (
        <div
            className={`${classes.billingPlansContentWrapper}
                        ${
                            darkMode
                                ? classes.billingPlansContentWrapperDark
                                : ''
                        }`}
        >
            <>
                {!isSubscriptionExpired && !isGracePeriod && (
                    <SubscriptionInfo />
                )}
                <BillingPlansContent />
            </>
        </div>
    );
};

export default BillingPlansPageContent;
