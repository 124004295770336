import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getButtonHotkeyLabel } from '../../constants/constants';

const classes = {
    customButtonsTooltip: 'custom-buttons-tooltip',
};

const CustomButtonsTooltip = ({ title = 'title', subtitle }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.customButtonsTooltip}`}>
            <Typography className={`${darkMode ? 'dark-mode' : ''}`}>
                {title}
            </Typography>
            {subtitle || getButtonHotkeyLabel(title)}
        </div>
    );
};

export default CustomButtonsTooltip;
