import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { hTagsLines } from '../../../constants/hTagsLines';

import AddButton from './AddButton';

import './OutlineTags.scss';

const classes = {
    basicOutlineTag: 'basic-outline-tag',
    prefixTag: 'prefix-tag',
    basicOutlineTagWrapper: 'basic-outline-tag-wrapper',
    addButtonWrapper: 'add-button-wrapper',
    bulletedTag: 'bulleted-tag',
};

const BasicOutlineTag = ({
    tag = 'h2',
    label = 'Basic Outline Tag',
    addBottonOption,
    handleAddButton,
    isBulletedTag,
}) => {
    const darkMode = useSelector(selectDarkMode);
    const trimmedLabel = label.replace(/\s+/g, ' ').trim();

    const handleAddRowClick = () => {
        if (handleAddButton) {
            handleAddButton({ tag, label: trimmedLabel });
        }
    };

    return (
        <div
            className={`${classes.basicOutlineTagWrapper} ${
                darkMode ? 'dark-mode' : ''
            }`}
            onClick={handleAddRowClick}
        >
            {hTagsLines[tag]}
            <div className={`${classes.basicOutlineTag}`}>
                <div className='d-flex flex-baseline'>
                    <div
                        className={`${classes.prefixTag} ${tag} ${
                            darkMode ? 'dark-mode' : ''
                        } ${isBulletedTag ? classes.bulletedTag : ''}`}
                    >
                        {isBulletedTag ? '•' : tag.toUpperCase()}
                    </div>
                    <Typography
                        className={`${tag} ${darkMode ? 'dark-mode' : ''}`}
                    >
                        {trimmedLabel}
                    </Typography>
                </div>

                {addBottonOption && (
                    <div className={classes.addButtonWrapper}>
                        <AddButton onClick={handleAddRowClick} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BasicOutlineTag;
