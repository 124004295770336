import React from 'react';

const BillingCreditsIcon = ({ color = '#9199AD'}) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5 8H15'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                 strokeLinejoin='round'
            />
            <path
                d='M5 13H15'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                 strokeLinejoin='round'
            />
            <path
                d='M7.49996 18.8334H12.5C16.6666 18.8334 18.3333 17.1667 18.3333 13.0001V8.00008C18.3333 3.83341 16.6666 2.16675 12.5 2.16675H7.49996C3.33329 2.16675 1.66663 3.83341 1.66663 8.00008V13.0001C1.66663 17.1667 3.33329 18.8334 7.49996 18.8334Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
        </svg>
    );
};

export default BillingCreditsIcon;
