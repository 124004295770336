import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';
import moment from 'moment';

import {
    selectCurrentSubscription,
    selectOptimizersAdded,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../pages/account/billing/store/billingSettings.selectors';
import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    tabsCredits: 'tabs-credits',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    textDotted: 'text-decoration-dotted',
};

const OptimizerCredits = ({
    tooltipTitle = 'The number of credits remaining in your account this month.',
}) => {
    const darkMode = useSelector(selectDarkMode);
    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);

    const currentSubscription = useSelector(selectCurrentSubscription);

    return (
        <Tooltip
            title={`${tooltipTitle} Resets on ${
                currentSubscription
                    ? moment(currentSubscription?.current_period_end).format(
                          'MMM D, YYYY'
                      )
                    : ''
            }.`}
            overlayClassName={`${classes.tooltipOverlay}
                               ${darkMode ? classes.tooltipOverlayDark : ''}`}
        >
            <Typography className={classes.tabsCredits}>
                <span className={classes.textDotted}>
                    {optimizersLimit + optimizersAdded - optimizersUsed || 0}/
                    {optimizersLimit + optimizersAdded || 0} credits remaining
                </span>
            </Typography>
        </Tooltip>
    );
};

export default OptimizerCredits;
