import React, { useEffect } from 'react';

import { Row, Col, Typography, Form, Input, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { usePaymentMethod } from '../../../../../utils/hooks/usePaymentMethod';
import Loader from '../../../../common/loader/Loader';

const { Title } = Typography;

const classes = {
    billingInfoWrapper: 'billing-info-wrapper',
    billingInfoSectiontitle: 'section-title',
    billingInfoDescription: 'section-description',
};

const BillingInfoSection = ({ form }) => {
    const { paymentMethod, isPaymentMethodLoaded } = usePaymentMethod();

    useEffect(() => {
        if (paymentMethod) {
            form.setFieldsValue({
                email: paymentMethod?.billing_details?.email,
                extra: paymentMethod?.billing_details?.extra,
            });
        }

        // eslint-disable-next-line
    }, [paymentMethod]);

    return (
        <section className={classes.billingInfoWrapper}>
            <Title level={4} className={classes.billingInfoSectiontitle}>
                Billing Info
            </Title>
            <Spin spinning={isPaymentMethodLoaded} indicator={<Loader />}>
                <Row gutter={24}>
                    <Col span={9}>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email',
                                },
                            ]}
                        >
                            <Input placeholder='Enter your email' />
                        </Form.Item>
                        <Typography className={classes.billingInfoDescription}>
                            Receipts are sent here
                        </Typography>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name='extra'
                            label='Additional info (e.g. company name,
                                billing address, VAT number, etc)'
                        >
                            <TextArea />
                        </Form.Item>
                        <Typography className={classes.billingInfoDescription}>
                            This will display on all future receipts and invoices
                        </Typography>
                    </Col>
                </Row>
                {!isPaymentMethodLoaded && !paymentMethod && (
                    <Typography style={{ marginTop: '24px' }}>
                        Add payment method to be able to add billing info
                    </Typography>
                )}
            </Spin>
        </section>
    );
};

export default BillingInfoSection;
