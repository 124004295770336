import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'antd';

import ExtractContentEditor from './extractContentEditor/ExtractContentEditor';

import { selectDarkMode } from '../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { additionalContentToEditor } from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

import { getThemeColorClass } from '../../../../../../../../../../utils/helpers/themeHelpers';

const { Link } = Typography;

const classes = {
    importedDataWrapper: 'imported-data-wrapper',
    importedDataActionsWrapper: 'imported-data-actions-wrapper d-flex flex-end',
    link: 'link-hover-highlight',
};

const ImportFromUrlData = ({ url, importedData, toggleModal }) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const darkModeClass = getThemeColorClass(darkMode);

    const [convertedImportedHtmlData, setConvertedImportedHtmlData] =
        useState('');

    const handleImportIntoOptimizer = () => {
        dispatch(additionalContentToEditor(convertedImportedHtmlData));
        toggleModal();
    };

    return (
        <>
            <div className={`${classes.importedDataWrapper} ${darkModeClass}`}>
                {importedData && (
                    <ExtractContentEditor
                        importedData={importedData}
                        setConvertedImportedHtmlData={
                            setConvertedImportedHtmlData
                        }
                    />
                )}
            </div>
            <div className={classes.importedDataActionsWrapper}>
                <div className='d-flex align-items-center'>
                    <Link
                        underline
                        className={`${classes.link} ${darkModeClass}`}
                        href={url}
                        target='_blank'
                    >
                        Visit URL
                    </Link>
                    <Link
                        underline
                        className={`${classes.link} ${darkModeClass}`}
                        onClick={handleImportIntoOptimizer}
                    >
                        Import into Optimizer
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ImportFromUrlData;
