import SiderChevronLeftIcon from '../../resources/icons/SiderChevronLeft';
import SiderChevronRightIcon from '../../resources/icons/SiderChevronRight';

export const renderPaginationArrows = (_, type, originalElement) => {
    if (type === 'jump-prev') {
        return <SiderChevronLeftIcon />;
    }

    if (type === 'jump-next') {
        return <SiderChevronRightIcon />;
    }

    if (type === 'prev') {
        return <SiderChevronLeftIcon />;
    }

    if (type === 'next') {
        return <SiderChevronRightIcon />;
    }
    
    return originalElement;
};
