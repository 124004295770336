import React from 'react';

const FilterRemoveIcon = ({ color = '#728192' }) => {
    return (
        <svg
            width='61'
            height='60'
            viewBox='0 0 61 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M34.4821 51.9734L30.9571 54.2485C27.6821 56.2735 23.1321 53.9984 23.1321 49.9484V36.5734C23.1321 34.7984 22.1321 32.5234 21.1071 31.2734L11.5071 21.1733C10.2321 19.8983 9.23206 17.6484 9.23206 16.1234V10.3234C9.23206 7.29843 11.5071 5.02344 14.2821 5.02344H47.6321C50.4071 5.02344 52.6821 7.29849 52.6821 10.0735V15.6234C52.6821 17.6484 51.4071 20.1734 50.1571 21.4234'
                stroke={color}
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M55.032 36.875C55.032 39.1 54.407 41.2 53.307 43C51.257 46.45 47.482 48.75 43.157 48.75C38.832 48.75 35.057 46.425 33.007 43C31.907 41.225 31.282 39.1 31.282 36.875C31.282 30.325 36.607 25 43.157 25C49.707 25 55.032 30.325 55.032 36.875Z'
                stroke={color}
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M46.3321 39.9742L40.0571 33.6992'
                stroke={color}
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M46.2821 33.7734L40.0071 40.0485'
                stroke={color}
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M52.6821 10.05V15.6C52.6821 17.625 51.4071 20.15 50.1571 21.425L45.7571 25.3C44.9321 25.1 44.0571 25 43.1571 25C36.6071 25 31.2821 30.325 31.2821 36.875C31.2821 39.1 31.9071 41.2 33.0071 43C33.9321 44.55 35.2071 45.875 36.7571 46.825V47.675C36.7571 49.2 35.7571 51.2249 34.4821 51.9749L30.9571 54.25C27.6821 56.275 23.1321 54 23.1321 49.95V36.575C23.1321 34.8 22.1071 32.525 21.1071 31.275L11.5071 21.1749C10.2571 19.8999 9.23206 17.625 9.23206 16.125V10.3C9.23206 7.27499 11.5071 5 14.2821 5H47.6321C50.4071 5 52.6821 7.27505 52.6821 10.05Z'
                stroke={color}
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default FilterRemoveIcon;
