export const selectViewOnlyActiveCard = (state) =>
    state?.viewOnlySettings?.activeCard;

export const selectViewOnlyBriefData = (state) =>
    state?.viewOnlySettings?.briefData;

export const selectViewOnlyOutlineData = (state) =>
    state?.viewOnlySettings?.outlineData;

export const selectViewOnlyIsDataLoading = (state) =>
    state?.viewOnlySettings?.isDataLoading;

export const selectContentOptimizerViewOnlyMode = (state) =>
    state?.viewOnlySettings?.viewOnlyMode;
