import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';
import { useCurrentEditor } from '@tiptap/react';

import BoldIcon from '../../../../../resources/icons/editor/BoldIcon';
import CodeIcon from '../../../../../resources/icons/editor/CodeIcon';
import ItalicIcon from '../../../../../resources/icons/editor/ItalicIcon';
import UnderlineIcon from '../../../../../resources/icons/editor/UnderlineIcon';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getIconColorByDarkMode } from '../constants';

import CustomButtonsTooltip from './CustomButtonsTooltip';

const classes = {
    markButtonsSection: 'mark-buttons-section',
    tooltipOverlay: 'toolbar-tooltip-overlay tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const MarkButtonsSection = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.markButtonsSection} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Bold' />}
            >
                <>
                    <Button
                        className={editor.isActive('bold') ? 'active' : ''}
                        icon={
                            <BoldIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() =>
                            editor.chain().focus().toggleBold().run()
                        }
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Italic' />}
            >
                <>
                    <Button
                        icon={
                            <ItalicIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        className={editor.isActive('italic') ? 'active' : ''}
                        onClick={() =>
                            editor.chain().focus().toggleItalic().run()
                        }
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Underline' />}
            >
                <>
                    <Button
                        icon={
                            <UnderlineIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() =>
                            editor.chain().focus().toggleUnderline().run()
                        }
                        className={editor.isActive('underline') ? 'active' : ''}
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Code' />}
            >
                <>
                    <Button
                        icon={
                            <CodeIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() =>
                            editor.chain().focus().toggleCode().run()
                        }
                        className={editor.isActive('code') ? 'active' : ''}
                    />
                </>
            </Tooltip>
        </div>
    );
};

export default MarkButtonsSection;
