import moment from 'moment';

/**
 * Formats a date relative to now with custom settings.
 * This function ensures that relative times are always rounded down when humanizing.
 *
 * For example:
 * - "1 minute ago" instead of "2 minutes ago" when it's more than 1 minute 30 seconds but less than 2 minutes ago.
 * - "a few seconds ago" instead of "a minute ago" when it's more than 45 seconds but less than 1 minute ago.
 *
 * @param {Date | string} date - The date to format.
 * @returns {string} The formatted relative time string.
 */
export const formatRelativeTime = (date) => {
    const now = moment();
    const diff = now.diff(moment(date));
    const duration = moment.duration(diff);
    const seconds = Math.floor(duration.asSeconds());
    const minutes = Math.floor(duration.asMinutes());

    if (seconds < 60) {
        return 'a few seconds ago';
    } else if (minutes < 60) {
        return minutes === 1 ? 'a minute ago' : `${minutes} minutes ago`;
    } else {
        return moment(date).fromNow();
    }
};
