import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import CompetitorsExportButton from './CompetitorsExportButton';
import CompetitorsSearchInput from './CompetitorsSearchInput';
import RankabilityLoader from '../../../../../../common/rankabilityLoader/RankabilityLoader';

import { selectIsCompetitorScanUrlLoading } from '../../../store/contentOptimizerContent.selectors';

import './CompetitorsContent.scss';

const classes = {
    competitorsActionsWrapper: 'competitors-actions-wrapper',
    rightActionsWrapper: 'd-flex',
    searchInputWrapper: 'search-input-wrapper',
    loaderWrapper: 'competitors-loader-wrapper',
};

const CompetitorsActionsWrapper = () => {
    const isCompetitorScanUrlLoading = useSelector(
        selectIsCompetitorScanUrlLoading
    );

    return (
        <Row
            justify='space-between'
            className={`${classes.competitorsActionsWrapper}`}
        >
            <Col className={`${classes.searchInputWrapper}`}>
                <CompetitorsSearchInput />
            </Col>
            {isCompetitorScanUrlLoading && (
                <Col className={classes.loaderWrapper}>
                    <RankabilityLoader />
                </Col>
            )}
            <Col>
                <div className={`${classes.rightActionsWrapper}`}>
                    <CompetitorsExportButton />
                </div>
            </Col>
        </Row>
    );
};

export default CompetitorsActionsWrapper;
