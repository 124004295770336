import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentEditor } from '@tiptap/react';

import { colorPalette } from '../../../../resources/styles/colorPalette';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';
import { getOptimizerDetailRoute } from '../../router/routes';
import {
    createHighlightTransaction,
    createRemoveHighlightTransaction,
    deleteNode,
    insertNode,
} from '../utils/editorTransactionHelpers';
import useEditorVersions from './useEditorVersions';

import { contentOptimizerPathes } from '../../../../constants/queryPathes';
import { nodeTypes } from '../constants/nodeTypes';

import {
    setAISpellCheckerVisible,
    setAIState,
    updateCurrentEditorLoading,
} from '../store/tiptapEditor.actions';
import { selectCurrentVersionId } from '../store/tiptapEditor.selectors';

const useInterruptedAIOptimizer = () => {
    const { contentOptimizerId } = useParams();

    const { editor } = useCurrentEditor();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentOptimizerVersionId = useSelector(selectCurrentVersionId);

    const organizationSlug = useOrganizationSlug();
    const [fetchEditorVersions] = useEditorVersions();

    const interruptedOptimizer = editor?.storage?.[nodeTypes.aiSpellChecker]?.interruptedOptimizer;

    const initializeInterruptedOptimizerMatch = useCallback(
        // editor as parameter is used because the current editor is not available in the first editor initialization
        (editor) => {
            const interruptedOptimizer =
                editor?.storage?.[nodeTypes.aiSpellChecker]?.interruptedOptimizer;

            if (interruptedOptimizer.id) {
                const isMatch =
                    interruptedOptimizer.id === contentOptimizerId &&
                    interruptedOptimizer.versionId === currentOptimizerVersionId;

                editor.commands.setInterruptedOptimizer({
                    isMatch,
                });
            }
        },
        [contentOptimizerId, currentOptimizerVersionId]
    );

    const initializeSpellCheckerWhenInterrupted = useCallback(() => {
        if (interruptedOptimizer?.isMatch) {
            dispatch(setAISpellCheckerVisible(true));

            const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;
            const response = editor.storage[nodeTypes.aiSpellChecker].response;

            if (response) {
                dispatch(setAIState({ response }));
            } else {
                dispatch(setAIState({ isLoading: true }));
            }

            // resolve from and to position as content by this time might have changed
            const docContentSize = editor.state.doc.content.size;
            const resolvedFrom = from > docContentSize ? docContentSize : from;
            const resolvedTo = to > docContentSize ? docContentSize : to;

            // Use requestAnimationFrame to ensure that editor transactions are executed
            // after any pending DOM updates are complete, preparing editor for immediate acceptance
            requestAnimationFrame(() => {
                insertNode(
                    editor,
                    resolvedTo,
                    nodeTypes.aiSpellChecker,
                    { from: resolvedFrom, to: resolvedTo },
                    true
                );
                createHighlightTransaction(
                    editor,
                    resolvedFrom,
                    resolvedTo,
                    colorPalette.colorSelectedHighlight
                );
                editor.commands.setSelectedRange(resolvedFrom, resolvedTo);
                editor.commands.resetInterruptedOptimizer();
            });
        }
    }, [dispatch, editor, interruptedOptimizer]);

    const storeInterruptedOptimizer = useCallback(
        // editor as parameter is used because the current editor is not available in the editor destroy event
        (editor) => {
            if (
                editor.storage[nodeTypes.aiSpellChecker].spellCheckerVisible &&
                !editor.storage[nodeTypes.aiSpellChecker].interruptedOptimizer.id
            ) {
                const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;

                deleteNode(editor, nodeTypes.aiSpellChecker);
                createRemoveHighlightTransaction(editor, from, to);
                editor.commands.setInterruptedOptimizer({
                    id: contentOptimizerId,
                    versionId: currentOptimizerVersionId,
                });
            }
        },
        [contentOptimizerId, currentOptimizerVersionId]
    );

    // NOTE: This function will be imported from the `useOptimizerEditorVersions` hook
    // implemented in the story/3181-outline-editor
    const handleActivateEditorVersion = useCallback(
        (versionId) => {
            if (contentOptimizerId && versionId) {
                dispatch(updateCurrentEditorLoading(true));

                axiosAPI
                    .patch(
                        `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/${versionId}`,
                        {
                            active: true,
                        },
                        {
                            ...getAxiosHeaders(),
                            params: { slug: organizationSlug },
                        }
                    )
                    .then(() => {
                        fetchEditorVersions();
                    })
                    .catch(() => {
                        openBasicErrorNotification();
                    });
            }
        },
        [contentOptimizerId, dispatch, fetchEditorVersions, organizationSlug]
    );

    const previewInterruptedOptimizer = useCallback(() => {
        if (interruptedOptimizer?.id) {
            if (interruptedOptimizer.id !== contentOptimizerId) {
                navigate(`/${organizationSlug}${getOptimizerDetailRoute(interruptedOptimizer.id)}`);
            } else {
                handleActivateEditorVersion(interruptedOptimizer.versionId);
            }
        }
    }, [
        navigate,
        organizationSlug,
        handleActivateEditorVersion,
        interruptedOptimizer,
        contentOptimizerId,
    ]);

    return {
        initializeInterruptedOptimizerMatch,
        initializeSpellCheckerWhenInterrupted,
        storeInterruptedOptimizer,
        previewInterruptedOptimizer,
    };
};

export default useInterruptedAIOptimizer;
