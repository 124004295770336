import { authSettingsActions } from './authSettings.actions';

const authSettingsInitialState = {
    userRegistrationData: null,
    starterPlanData: null,
    customerId: null,
    userEmail: null,
};

export const authSettingsReducer = (
    state = authSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case authSettingsActions.UPDATE_USER_SIGN_UP: {
            return {
                ...state,
                userRegistrationData: payload,
            };
        }

        case authSettingsActions.SET_STARTER_PLAN_DATA: {
            return {
                ...state,
                starterPlanData: payload,
            };
        }

        case authSettingsActions.SET_CUSTOMER_ID: {
            return {
                ...state,
                customerId: payload,
            };
        }

        case authSettingsActions.SET_USER_EMAIL: {
            return {
                ...state,
                userEmail: payload,
            };
        }

        default:
            return state;
    }
};
