export const roleTypes = {
    techAdmin: {
        key: 'tech_admin',
        name: 'Technical Admin',
        rating: 1500,
    },
    globalAdmin: {
        key: 'global_admin',
        name: 'Rankability Admin',
        rating: 1000,
    },
    organizationOwner: {
        // this one should be visible as admin, but is higher than admin
        key: 'organization_owner',
        name: 'Admin',
        rating: 100,
    },
    organizationAdmin: {
        key: 'organization_admin',
        name: 'Admin',
        rating: 10,
    },
    organizationManager: {
        key: 'organization_manager',
        name: 'Manager',
        rating: 1,
    },
    organizationMember: {
        key: 'organization_member',
        name: 'Member',
        rating: 0,
    },
};
