import React from 'react';

const TickIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
        >
            <path
                d='M3.5 7L5.83059 9.625L10.5 4.375'
                stroke='#20C752'
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default TickIcon;
