import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'antd';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../utils/helpers/getAxiosHeaders';
import { getBriefExtrasFormItemsData } from '../../../../../../../../utils/helpers/getBriefExtrasFormItems';
import { getInitialValuesForBriefExtras } from '../../../../../../../../utils/helpers/getInitialValuesForBriefExtras';
import { openNotification } from '../../../../../../../../utils/helpers/openNotification';
import { usePrevious } from '../../../../../../../../utils/hooks/usePrevious';

import {
    briefExtrasArrayFields,
    numberFields,
} from '../../../../../../../../constants/briefExtrasArrayFields';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../constants/queryPathes';
import { urlSpecificPattern } from '../../../../../../../../constants/regularExpression';
import { statusCodes } from '../../../../../../../../constants/statusCodes';

import BriefFormItem from './BriefFormItem';

const classes = {
    formWrapper: 'form-wrapper optimizer-brief-form-wrapper',
    noContentFormWrapper: 'no-content-form-wrapper',
    formWrapperDark: 'dark-input-autofill',
    InputNumber: 'w-100',
};

const OptimizerBriefForm = ({ briefData, isContentInBrief, setBriefData }) => {
    const [form] = Form.useForm();
    const briefFormData = Form.useWatch([], form);

    const darkMode = useSelector(selectDarkMode);

    const prevBriefData = usePrevious(briefData);

    useEffect(() => {
        if (briefData?.extras && !prevBriefData) {
            form.setFieldsValue(
                getInitialValuesForBriefExtras(briefData?.extras)
            );
        }

        // eslint-disable-next-line
    }, [briefData]);

    const setError = (name, error) => {
        form.setFields([
            {
                name,
                errors: error ? [error] : [],
            },
        ]);
    };

    const handleEditBrief = (name) => {
        let value = form.getFieldValue([name]);

        if (briefExtrasArrayFields.includes(name)) {
            value = value?.match(urlSpecificPattern) || [];
        }

        if (numberFields.includes(name) && !value) {
            value = 0;
        }

        if (value !== briefData?.extras?.[name]) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${briefData?.content_brief_id}/versions/${briefData?.id}`,
                    {
                        extras: {
                            ...briefData.extras,
                            [name]: value,
                        },
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setBriefData(result?.data);

                        if (
                            result.data?.extras &&
                            briefExtrasArrayFields.includes(name)
                        ) {
                            form.setFieldsValue({
                                [name]:
                                    result.data.extras[name]?.join('\n') || '',
                            });
                        }
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                    form.setFieldsValue({
                        [name]: briefData.extras[name],
                    });
                });
        }
    };

    const deletePageUrlIfExists = (value) => {
        if (
            briefFormData?.['page_url'] &&
            value &&
            value.includes(briefFormData?.['page_url'])
        ) {
            form.setFieldValue(
                'example_articles',
                value.replaceAll(briefFormData?.['page_url'], '')
            );

            openNotification(
                notificationType.error,
                'Error',
                'Content Examples can not contain Page Url. It was automatically removed by system.'
            );
        }
    };

    return (
        <Form
            form={form}
            layout='vertical'
            className={`${classes.formWrapper}
                        ${darkMode ? classes.formWrapperDark : ''}
                        ${!isContentInBrief && classes.noContentFormWrapper}`}
            initialValues={getInitialValuesForBriefExtras(briefData?.extras)}
        >
            {getBriefExtrasFormItemsData(briefData?.extras)?.map((item) => {
                return (
                    <BriefFormItem
                        key={item.name}
                        reduceInfo={true}
                        isContentInBrief={isContentInBrief}
                        primaryKeyword={briefData?.content_brief?.keywords}
                        value={briefFormData?.[item.name] || ''}
                        briefFormItem={item}
                        handleEditBrief={handleEditBrief}
                        deletePageUrlIfExists={deletePageUrlIfExists}
                        setError={setError}
                    />
                );
            })}
        </Form>
    );
};

export default OptimizerBriefForm;
