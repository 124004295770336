import React from 'react';
import { useSelector } from 'react-redux';

import { Typography, Modal, Button } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import CloseIcon from '../../../../resources/icons/CloseIcon';
import BillingCreditsIcon from '../../../../resources/icons/BillingCreditsIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { selectBriefTimings } from '../../../pages/contentOptimizer/contentPage/content/briefTab/content/tabsContent/store/contentBriefsOutline.selectors';

const classes = {
    helpSettingButton:
        'd-flex flex-center align-items-center btn-hover-light-primary ml-16px',
    modalWrapper: 'how-to-use-modal',
};

const UseSetting = () => {
    const darkMode = useSelector(selectDarkMode);
    const briefTimings = useSelector(selectBriefTimings);

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    return (
        <>
            <Button
                style={{ marginBottom: 0 }}
                className={classes.helpSettingButton}
                onClick={handleOpen}
            >
                <BillingCreditsIcon color={iconColor} />
            </Button>
            <Modal
                centered
                open={isOpen}
                onOk={handleOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={classes.modalWrapper}
            >
                {briefTimings?.map((timing, index) => (
                    <div key={index}>
                        <Typography.Text>
                            {timing.title}:{' '}
                            {Math.round(timing.time_elapsed * 100) / 100}{' '}
                            seconds
                        </Typography.Text>
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default UseSetting;
