import Rupt from 'rupt';

class RuptService {
    static DEVICE_ID = 'device_id';

    static storeDeviceId = async (deviceId) => {
        const LSDeviceId = localStorage.getItem(this.DEVICE_ID);

        if (LSDeviceId !== deviceId) {
            localStorage.setItem(this.DEVICE_ID, deviceId);
        }
    };

    static getDeviceId = () => {
        return localStorage.getItem(this.DEVICE_ID);
    };

    static attachDevice = async (email, accountId) => {
        const clientId = process.env.REACT_APP_RUPT_CLIENT_ID;

        if (clientId) {
            try {
                const { device_id } = await Rupt.attach({
                    client_id: clientId,
                    account: accountId,
                    email,
                    // NOTE: Uncomment when the pages are implemented
                    // redirect_urls: {
                    //     logout_url: 'https://your-logout-url.com',
                    //     new_account_url: 'https://your-create-new-account-url.com',
                    // },
                });

                this.storeDeviceId(device_id);
            } catch (e) {
                console.error(e);
            }
        }
    };

    static sendRuptStartTrialTrackEvent = async (email, accountId) => {
        const clientId = process.env.REACT_APP_RUPT_CLIENT_ID;

        if (clientId) {
            try {
                await Rupt.attach({
                    client_id: clientId,
                    account: accountId,
                    email,
                    event: 'trial_started',
                    // NOTE: Uncomment when the pages are implemented
                    // redirect_urls: {
                    //     logout_url: 'https://your-logout-url.com',
                    //     new_account_url: 'https://your-create-new-account-url.com',
                    //     upgrade_url: 'https://upgrade-url.com',
                    // },
                });
            } catch (e) {
                console.error(e);
            }
        }
    };

    static detachDevice = async (accountId) => {
        const clientId = process.env.REACT_APP_RUPT_CLIENT_ID;

        if (clientId) {
            try {
                const deviceId = this.getDeviceId();

                if (deviceId) {
                    await Rupt.detach({
                        client_id: clientId,
                        account: accountId,
                        device: deviceId,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
    };
}

export default RuptService;
