import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import { selectIsSubscriptionExpired } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { setPlanToSwitch } from '../../pages/account/billing/store/billingSettings.actions';

import TopPageWarningNotification from './TopPageWarningNotification';

import './Notifications.scss';

const ExpiredSubscriptionNotification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const organizationSlug = useOrganizationSlug();

    const handleSeePricing = () => {
        navigate(`/${organizationSlug}/account/plans`);
        dispatch(setPlanToSwitch(null));
    };

    const hideBtn = location.pathname.includes('/account/plans');

    if (!isSubscriptionExpired) return null;

    return (
        <TopPageWarningNotification
            notificationTitle={
                <>
                    You are in <span className='fw-600'>read-only mode</span>{' '}
                    because your subscription has expired. Upgrade your plan to
                    continue using Rankability.
                </>
            }
            hideBtn={hideBtn}
            btnText='Subscribe now'
            handleClickButton={handleSeePricing}
        />
    );
};

export default ExpiredSubscriptionNotification;
