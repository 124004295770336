import React, { useState } from 'react';

import { Input, Select } from 'antd';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../resources/icons/CloseIcon';

import { useIconColor } from '../../../../utils/hooks/useIconColor';

import { keywordFilterOptions } from './keywordFilterOptions';

const classes = {
    ruleInputWrapper: 'rule-input-wrapper',
    filterWrapper: 'filter-wrapper',
    removeIcon: 'd-flex flex-center',
};

const KeywordFilterItem = ({
    index,
    showRemove,
    keywordFilterItem,
    handleChange,
    handleRemoveRule,
}) => {
    const iconColor = useIconColor();

    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const handleSelectOpen = (open) => {
        setIsSelectOpen(open);
    };

    const handleRuleChange = (value) => {
        handleChange(index, 'rule', value);
    };

    const handleValueChange = (e) => {
        handleChange(index, 'value', e?.target?.value);
    };

    const handleRemove = () => {
        handleRemoveRule(index);
    };

    return (
        <div className={classes.ruleInputWrapper}>
            <Input.Group compact>
                <Select
                    defaultValue={keywordFilterItem.rule}
                    value={keywordFilterItem.rule}
                    options={keywordFilterOptions}
                    suffixIcon={
                        isSelectOpen ? (
                            <SiderChevronUpIcon
                                color={colorPalette.colorPrimary}
                            />
                        ) : (
                            <SiderChevronDownIcon color={iconColor} />
                        )
                    }
                    popupClassName='keyword-filter-select'
                    onDropdownVisibleChange={handleSelectOpen}
                    onSelect={handleRuleChange}
                />
                <Input
                    placeholder='Value'
                    value={keywordFilterItem.value}
                    onChange={handleValueChange}
                    suffix={
                        showRemove && (
                            <div
                                onClick={handleRemove}
                                className={classes.removeIcon}
                            >
                                <CloseIcon
                                    size='16'
                                    color={iconColor}
                                />
                            </div>
                        )
                    }
                />
            </Input.Group>
        </div>
    );
};

export default KeywordFilterItem;
