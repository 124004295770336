import React from 'react';

import { useDomainRankBadgeColor } from '../../../../../../../utils/hooks/useDomainRankBadgeColor';

const classes = {
    completeBadge: 'complete-badge complete-badge-small',
};

const DomainRankBadge = ({ progress }) => {
    const badgeColor = useDomainRankBadgeColor(progress);

    return (
        <div
            className={`${classes.completeBadge} 
                        ${!progress && 'badge-disabled'}`}
            style={{
                backgroundColor: badgeColor,
            }}
        >
            {progress ? `${progress}` : '--'}
        </div>
    );
};

export default DomainRankBadge;
