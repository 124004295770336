import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { accountTabs } from '../../../constants/accountTabs';
import { roleTypes } from '../../../constants/roleTypes';
import {
    getAccountInvoiceDetailRoute,
    getAccountTabRoute,
    getMonitorDetailRoute,
    getOptimizerDetailRoute,
    getResearchDetailRoute,
    ROUTES,
} from './routes';
import { unauthorizedRoutes } from './Unauthorized/routes';

import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';
import { usePrevious } from '../../../utils/hooks/usePrevious';

import {
    selectCurrentUserRoles,
    selectIsSubscriptionExpired,
    selectUserData,
} from '../../../userBrowserSettings/store/browserSettings.selectors';
import { checkIsAdmin } from '../../../utils/helpers/checkIsAdmin';
import RuptService from '../../../utils/helpers/RuptService';

import AccountPage from '../../pages/account/AccountPage';
import BillingPlansPageContent from '../../pages/account/billing/billingPlan/BillingPlansPageContent';
import CreditsPageContent from '../../pages/account/billing/credits/CreditsPageContent';
import InvoicePageContent from '../../pages/account/billing/invoice/InvoicePageContent';
import InvoiceHistoryPageContent from '../../pages/account/billing/invoiceHistory/InvoiceHistoryPageContent';

import Checkout from '../../pages/account/billing/billingPlan/checkout/Checkout';
import ContentMonitorContentPage from '../../pages/contentMonitor/contentPage/ContentMonitorContentPage';
import ContentMonitorCreateNewPage from '../../pages/contentMonitor/createNew/ContentMonitorCreateNewPage';
import ContentMonitorMainPage from '../../pages/contentMonitor/mainPage/ContentMonitorMainPage';
import ContentOptimizerContentPage from '../../pages/contentOptimizer/contentPage/ContentOptimizerContentPage';
import ContentOptimizerMainPage from '../../pages/contentOptimizer/mainPage/ContentOptimizerMainPage';
import ContentOptimizerViewOnlyPage from '../../pages/contentOptimizer/viewOnly/ContentOptimizerViewOnlyPage';
import HomePage from '../../pages/home/HomePage';
import KeywordResearchPage from '../../pages/keywordResearch/keywordResearch/KeywordResearchPage';
import KeywordResultsPage from '../../pages/keywordResearch/listOfResults/KeywordResultsPage';
import BillingLayout from '../layout/BillingLayout';
import ErrorsHandeledLayout from '../layout/ErrorsHandledLayout';
import ProtectedLayout from '../layout/ProtectedLayout';

const Router = () => {
    const organizationSlug = useOrganizationSlug();
    const prevOrganizationSlug = usePrevious(organizationSlug);
    const location = useLocation();

    const userData = useSelector(selectUserData);
    const currentUserRoles = useSelector(selectCurrentUserRoles);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const adminRoles = [
        roleTypes.globalAdmin.key,
        roleTypes.organizationOwner.key,
        roleTypes.organizationAdmin.key,
    ];

    const getRedirectPath = () => {
        if (isSubscriptionExpired) {
            return `/${organizationSlug}${
                checkIsAdmin(currentUserRoles[0]?.name) ? ROUTES.ACCOUNT_PLANS : ROUTES.ACCOUNT
            }`;
        }

        if (prevOrganizationSlug && prevOrganizationSlug !== organizationSlug) {
            return `/${organizationSlug}${ROUTES.ACCOUNT}/${accountTabs.profile.key}`;
        }

        const backToLink = localStorage.getItem('backToLink');
        if (backToLink?.length > 0 && backToLink.includes(`/${organizationSlug}/`)) {
            localStorage.setItem('backToLink', '');

            return backToLink;
        }

        return `/${organizationSlug}${ROUTES.HOME}`;
    };

    const pathItems = location.pathname?.split('/');

    const PAGE_NAME_PATH_INDEX = 2;
    const currentRoute = pathItems.length > 1 ? pathItems[PAGE_NAME_PATH_INDEX] : '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // NOTE: Send track event on main page change to let RUPT track user actions
        if (currentRoute) {
            RuptService.attachDevice(userData?.email, String(userData?.id));
        }
        // eslint-disable-next-line
    }, [currentRoute]);

    return (
        <Routes>
            <Route element={<ProtectedLayout />}>
                <Route element={<ErrorsHandeledLayout />}>
                    {!isSubscriptionExpired ? (
                        <>
                            <Route
                                path={`${organizationSlug}${ROUTES.HOME}`}
                                element={<HomePage />}
                            />
                            <Route
                                path={`${organizationSlug}${ROUTES.RESEARCH}`}
                                element={<KeywordResearchPage />}
                            />
                            <Route
                                path={`${organizationSlug}${getResearchDetailRoute()}`}
                                element={<KeywordResultsPage />}
                            />
                            <Route
                                path={`${organizationSlug}${ROUTES.OPTIMIZER}`}
                                element={<ContentOptimizerMainPage />}
                            />
                            <Route
                                path={`${organizationSlug}${getOptimizerDetailRoute()}`}
                                element={<ContentOptimizerContentPage />}
                            />
                            <Route
                                path={`${organizationSlug}${ROUTES.MONITOR}`}
                                element={<ContentMonitorMainPage />}
                            />
                            <Route
                                path={`${organizationSlug}${getMonitorDetailRoute()}`}
                                element={<ContentMonitorContentPage />}
                            />
                            {/* TODO: Remove this page /monitor/create-new */}
                            <Route
                                path={`${organizationSlug}${ROUTES.MONITOR_CREATE_NEW}`}
                                element={<ContentMonitorCreateNewPage />}
                            />
                            <Route path={`${organizationSlug}${ROUTES.ACCOUNT}`}>
                                <Route index element={<Navigate to={accountTabs.profile.key} />} />
                                <Route path=':accountTab' element={<AccountPage />} />
                            </Route>
                            {checkIsAdmin(currentUserRoles[0]?.name) && (
                                <Route element={<BillingLayout />}>
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_PLANS}`}
                                        element={<BillingPlansPageContent />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_PLANS_CHECKOUT}`}
                                        element={<Checkout />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${getAccountInvoiceDetailRoute()}`}
                                        element={<InvoicePageContent />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_INVOICE_HISTORY}`}
                                        element={<InvoiceHistoryPageContent />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_CREDITS}`}
                                        element={<CreditsPageContent />}
                                    />
                                </Route>
                            )}
                        </>
                    ) : (
                        <>
                            <Route
                                path={`${organizationSlug}${getAccountTabRoute()}`}
                                element={<AccountPage />}
                            />
                            {adminRoles.some((adminRole) =>
                                currentUserRoles?.some((userRole) => adminRole === userRole?.name)
                            ) && (
                                <Route element={<BillingLayout />}>
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_PLANS}`}
                                        element={<BillingPlansPageContent />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_PLANS_CHECKOUT}`}
                                        element={<Checkout />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${getAccountInvoiceDetailRoute()}`}
                                        element={<InvoicePageContent />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_INVOICE_HISTORY}`}
                                        element={<InvoiceHistoryPageContent />}
                                    />
                                    <Route
                                        path={`${organizationSlug}${ROUTES.ACCOUNT_CREDITS}`}
                                        element={<CreditsPageContent />}
                                    />
                                </Route>
                            )}
                        </>
                    )}
                </Route>
            </Route>
            <Route element={<ErrorsHandeledLayout />}>
                <Route
                    path={unauthorizedRoutes.viewOnlyPage}
                    element={<ContentOptimizerViewOnlyPage />}
                />
            </Route>

            <Route path='*' element={<Navigate to={getRedirectPath()} />} />
        </Routes>
    );
};

export default Router;
