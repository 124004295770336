import React from 'react';
import { useSelector } from 'react-redux';

import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentContentOptimizer } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';
import { selectContentOptimizerViewOnlyMode } from '../../../pages/contentOptimizer/viewOnly/store/viewOnlySettings.selectors';
import { selectGeneratedAIToken } from '../store/tiptapEditor.selectors';

import AISection from './buttons/AISection';
import AIWordCounter from './buttons/AIWordCounter';
import GalleryButtonsSection from './buttons/GalleryButtonsSection';
import HeadingSection from './buttons/HeadingSection';
import ListButtonsSection from './buttons/ListButtonsSection';
import MarkButtonsSection from './buttons/MarkButtonsSection';
import UndoRedoButtonsSection from './buttons/UndoRedoButtonsSection';
import ToolbarSettingsSection from './ToolbarSettingsSection';

import './buttons/content/ToolbarContent.scss';
import './buttons/ToolbarButtons.scss';
import './EditorToolbar.scss';

export const EDITOR_TOOLBAR_ID = 'tiptap-editor-toolbar';

const classes = {
    editorToolbar: 'editor-toolbar justify-content-between',
    buttonWrapper: 'd-flex',
};

const EditorToolbar = () => {
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const currentContentOptimizer = useSelector(selectCurrentContentOptimizer);
    const generatedAIToken = useSelector(selectGeneratedAIToken);
    const aiAppId = process.env.REACT_APP_TIPTAP_AI_APP_ID;

    return (
        <div
            id={EDITOR_TOOLBAR_ID}
            className={`${classes.editorToolbar} ${getThemeColorClass(darkMode)}`}
        >
            <div className={`${classes.buttonWrapper}`}>
                <HeadingSection />
                <MarkButtonsSection />
                <ListButtonsSection />
                <GalleryButtonsSection />
                <UndoRedoButtonsSection />
                {generatedAIToken &&
                    aiAppId &&
                    (currentContentOptimizer?.organization_settings
                        ?.ai_command_menu_for_shared_links ||
                        !viewOnlyMode) && (
                        <>
                            <AISection />
                            <AIWordCounter />
                        </>
                    )}
            </div>

            <ToolbarSettingsSection />
        </div>
    );
};

export default EditorToolbar;
