import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Modal, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import CloseIcon from '../../../../resources/icons/CloseIcon';
import HelpIcon from '../../../../resources/icons/Help';
import RankabilityDarkLogoIcon from '../../../../resources/icons/RankabilityDarkLogoIcon';
import RankabilityLightLogoIcon from '../../../../resources/icons/RankabilityLightLogoIcon';

import { colorPalette } from '../../../../resources/styles/colorPalette';

const classes = {
    helpSettingButton:
        'd-flex flex-center align-items-center use-setting-btn btn-hover-light-primary ml-16px',
    modalWrapper: 'how-to-use-modal',
};

const UseSetting = () => {
    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    return (
        <>
            <Button className={classes.helpSettingButton} onClick={handleOpen}>
                <HelpIcon color={iconColor} />
                <Typography>How To Use</Typography>
            </Button>
            {isOpen && (
                <Modal
                    centered
                    open={isOpen}
                    onCancel={handleOpen}
                    footer={null}
                    closeIcon={<CloseIcon color={closeModalIconColor} />}
                    wrapClassName={classes.modalWrapper}
                >
                    {darkMode ? (
                        <RankabilityDarkLogoIcon width='240' />
                    ) : (
                        <RankabilityLightLogoIcon width='240' />
                    )}
                </Modal>
            )}
        </>
    );
};

export default UseSetting;
