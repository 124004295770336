import React from 'react';

import { Col, Card, Checkbox, Typography } from 'antd';

const { Title } = Typography;

const OptimizerCheckedSettingItem = ({ setting }) => {
    return (
        <Col span={8} className='mr-2'>
            <Card>
                <Checkbox key={setting.value} value={setting.value}>
                    <Title level={5}>{setting.label}</Title>
                    <Typography>{setting.description}</Typography>
                </Checkbox>
            </Card>
        </Col>
    );
};

export default OptimizerCheckedSettingItem;
