import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
    getKeywordResearchResultsSortItems,
    getKeywordResearchResultsSortQueryByKey
} from '../../../../constants/keywordResearchResultsSortOptions';
import { getKeywordResearchResultsFilterQueryByKey } from '../../../../utils/helpers/getKeywordResearchResultsSortQueryByKey';

import KeywordExtraBreadcrumb from '../../../common/header/content/KeywordExtraBreadcrumb';
import CustomHeader from '../../../common/header/Header';

import {
    setKeywordListFilters,
    setKeywordListPagination,
    setKeywordListSearch,
    setKeywordListSort,
} from '../store/keywordResearchSettings.actions';
import { keywordResearchSettingsInitialState } from '../store/keywordResearchSettings.reducer';
import {
    selectCurrentKeyword,
    selectKeywordListSearch,
    selectKeywordResearchTab
} from '../store/keywordResearchSettings.selectors';

const ListOfResultsPageHeader = () => {
    const dispatch = useDispatch();

    const currentKeyword = useSelector(selectCurrentKeyword);
    const listSearchQuery = useSelector(selectKeywordListSearch);
    const selectedTab = useSelector(selectKeywordResearchTab);

    const keywordResearchResultsSortItems = getKeywordResearchResultsSortItems(selectedTab);
    keywordResearchResultsSortItems.map((sortItem) => ({
        ...sortItem,
        disabled: !currentKeyword?.task_result,
    }))

    const [searchValue, setSearchValue] = useState('');
    const [selectedSort, setSelectedSort] = useState(
        keywordResearchResultsSortItems[0].key
    );

    useEffect(() => {
        if (!listSearchQuery) {
            setSearchValue('');
        }
    }, [listSearchQuery]);

    useEffect(() => {
        const updateKeywordParam = setTimeout(() => {
            batch(() => {
                dispatch(setKeywordListSearch(searchValue.trim()));
                dispatch(setKeywordListPagination({ page: 1 }));
            });
        }, 1500);

        return () => clearTimeout(updateKeywordParam);

        // eslint-disable-next-line
    }, [searchValue]);

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const handleSort = (value) => {
        if (currentKeyword?.task_result) {
            setSelectedSort(value);

            const filtersModel = getKeywordResearchResultsFilterQueryByKey(
                value,
                currentKeyword?.keyword
            );

            const sortQuery = getKeywordResearchResultsSortQueryByKey(value);

            batch(() => {
                if (sortQuery) {
                    dispatch(setKeywordListSort(sortQuery));
                    
                    // NOTE: Reset filter if sorting has changed
                    dispatch(setKeywordListFilters([]));
                } else {
                    dispatch(setKeywordListSort(keywordResearchSettingsInitialState.keywordListSort));
                    dispatch(setKeywordListFilters(filtersModel));
                } 
                
                dispatch(setKeywordListPagination({ page: 1 }));
            });
        }
    };

    return (
        <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            showSearch
            handleSearch={handleSearch}
            defaultSearchValue={listSearchQuery}
            searchValue={searchValue}
            showSort
            sortItems={keywordResearchResultsSortItems}
            selectedSort={selectedSort}
            handleSort={handleSort}
            extraBreadcrumbs={[
                <KeywordExtraBreadcrumb
                    keyword={currentKeyword?.keyword}
                    countryCode={currentKeyword?.country_code}
                    languageCode={currentKeyword?.language_code}
                />,
            ]}
        />
    );
};

export default ListOfResultsPageHeader;
