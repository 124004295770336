import React from 'react';

import { Button } from 'antd';

const classes = {
    optionWrapper: 'ai-response-option',
    optionButton: 'ai-response-option-button',
};

const AiResponseOptionItem = ({ aiResponseOption }) => {
    return (
        <div className={classes.optionWrapper}>
            <Button
                ghost
                type='primary'
                className={classes.optionButton}
                onClick={aiResponseOption.onClick}
                disabled={aiResponseOption.disabled}
                icon={aiResponseOption.icon}
                block
            >
                {aiResponseOption.label}
            </Button>
        </div>
    );
};

export default AiResponseOptionItem;
