import React from 'react';

const CopyIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 10H13.3333C13.7015 10 14 9.70152 14 9.33333V2.66667C14 2.29848 13.7015 2 13.3333 2L6.66667 2C6.29848 2 6 2.29848 6 2.66667L6 6'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.66667 14L9.33333 14C9.70152 14 10 13.7015 10 13.3333L10 6.66667C10 6.29848 9.70153 6 9.33334 6L2.66667 6C2.29848 6 2 6.29848 2 6.66667L2 13.3333C2 13.7015 2.29848 14 2.66667 14Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CopyIcon;
