import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Layout, Row, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';

import KeywordExtraBreadcrumb from '../../../common/header/content/KeywordExtraBreadcrumb';
import CustomHeader from '../../../common/header/Header';
import Loader from '../../../common/loader/Loader';

import SimularContentCard from './content/cards/SimularContentCard';
import ContentOutlineCard from './content/cards/ContentOutlineCard';
import OverviewCard from './content/cards/OverviewCard';
import SeoCard from './content/cards/SeoCard';

import {
    selectViewOnlyBriefData,
    selectViewOnlyIsDataLoading,
} from './store/viewOnlySettings.selectors';

import '../../../common/viewOnly/ViewOnly.scss';
import CommonPageLayout from '../../../common/layout/CommonPageLayout';

const classes = {
    viewOnlyWrapper: 'view-only-wrapper',
    leftColumn: 'left-column',
    rightColumn: 'right-column',
    viewOnlyContentWrapper: 'view-only-content-wrapper',
};

const ViewOnlyWrapper = () => {
    const viewOnlyBriefData = useSelector(selectViewOnlyBriefData);
    const isDataLoading = useSelector(selectViewOnlyIsDataLoading);

    return (
        <Layout>
            <CustomHeader
                // TODO: — Hide all “How To Use” buttons (temporary)
                //showUseSetting
                extraBreadcrumbs={[
                    <KeywordExtraBreadcrumb
                        keyword={viewOnlyBriefData?.content_brief?.keywords}
                        languageCode={
                            viewOnlyBriefData?.content_brief?.language_code
                        }
                        countryCode={
                            viewOnlyBriefData?.content_brief?.country_code
                        }
                    />,
                ]}
            />

            <CommonPageLayout>
                <Content className={`${classes.viewOnlyContentWrapper}`}>
                    <Spin indicator={<Loader />} spinning={isDataLoading}>
                        <Row className={classes.viewOnlyWrapper}>
                            <Col span={12} className={classes.leftColumn}>
                                <OverviewCard />
                                <SeoCard />
                                <SimularContentCard />
                            </Col>
                            <Col span={12} className={classes.rightColumn}>
                                <ContentOutlineCard />
                            </Col>
                        </Row>
                    </Spin>
                </Content>
            </CommonPageLayout>
        </Layout>
    );
};

export default ViewOnlyWrapper;
