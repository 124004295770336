import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Popconfirm } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../../../../constants/queryPathes';

import RemoveIcon from '../../../../../../../../../../../../resources/icons/outline/RemoveIcon';

import { selectDarkMode } from '../../../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../../../../../../utils/helpers/openNotification';
import { useOpen } from '../../../../../../../../../../../../utils/hooks/useOpen';

import PageTitleInput from '../../../../../../../../../../../common/PageTitleInput';
import AIButton from '../../../../../../../../../../../common/aiButton/AIButton';
import MoreDropdown from '../../../../../../../../../../../common/moreDropdown/MoreDropdown';
import VersionHistory from '../../../../../../../../../../../common/versionHistory/VersionHistory';

import {
    deleteOutlineVersion,
    setPreviewOutlineVersion,
    updateCurrentOutline,
    updateOutlineVersionName,
} from '../../../../store/contentBriefsOutline.actions';
import {
    selectCurrentOutlineLoading,
    selectOutlineVersions,
    selectPreviewOutlineVersion,
} from '../../../../store/contentBriefsOutline.selectors';

import './VersionHistoryContainer.scss';
import { useIconColor } from "../../../../../../../../../../../../utils/hooks/useIconColor";

const classes = {
    versionHistoryContainer: 'version-history-container',
    briefActionsWrapper: 'brief-actions-wrapper',
    versionHistoryDropdownTitle: 'version-history-dropdown-title d-flex align-items-center',
    versionHistoryDropdownTitleDark: 'version-history-dropdown-title-dark d-flex align-items-center',

    deletePopconfirm: 'delete-popconfirm',
    deletePopconfirmDark: 'delete-popconfirm-dark dark-mode',
};

const VersionHistoryContainer = ({
    showPageTitle,
    fetchOutlineVersions,
    createNewOutlineVersion,
    newVersionCreating,
}) => {
    const { contentOptimizerId } = useParams();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const outlineVersions = useSelector(selectOutlineVersions);
    const currentOutlineLoading = useSelector(selectCurrentOutlineLoading);
    const outlinePreviewVersion = useSelector(selectPreviewOutlineVersion);

    const [mappedOutlineVersions, setMappedOutlineVersions] = useState([]);

    const { isOpen: isDropdownOpen, handleOpen: handleDropdownOpen } =
        useOpen();

    const iconColor = useIconColor();

    useEffect(() => {
        if (outlineVersions?.length > 0) {
            setMappedOutlineVersions(
                outlineVersions?.map((version) => {
                    return {
                        id: version?.id,
                        name: version?.name,
                        active: version?.active,
                        created_at: version?.created_at,
                        wordCount: version?.word_count,
                        progress: Math.ceil(version?.progress * 100) || 0,
                    };
                })
            );
        }
    }, [outlineVersions]);

    const handleActivateOutlineVersion = (outlineId) => {
        if (contentOptimizerId && outlineId) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${contentOptimizerId}/outlines/${outlineId}`,
                    { active: true },
                    { ...getAxiosHeaders() }
                )
                .then(() => {
                    fetchOutlineVersions();
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handlePreviewOutlineVersion = (versionId) => {
        dispatch(
            setPreviewOutlineVersion(
                versionId && !outlinePreviewVersion ? versionId : null
            )
        );
    };

    const handleUpdateVersionName = (versionId, versionName) => {
        if (contentOptimizerId && versionId) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${contentOptimizerId}/outlines/${versionId}`,
                    { name: versionName },
                    { ...getAxiosHeaders() }
                )
                .then(() => {
                    dispatch(
                        updateOutlineVersionName({
                            id: versionId,
                            name: versionName,
                        })
                    );
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handleDeleteOutlineVersion = (versionId) => {
        if (contentOptimizerId && versionId) {
            axiosAPI
                .delete(
                    `${contentBriefPathes.deleteContentBrief}/${contentOptimizerId}/outlines/${versionId}`,
                    { ...getAxiosHeaders() }
                )
                .then(() => {
                    dispatch(deleteOutlineVersion(versionId));
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const moreItems = [
        {
            key: 'delete-outline',
            label: (
                <Popconfirm
                    placement='top'
                    okText='Yes'
                    cancelText='Cancel'
                    title='Delete this entire Brief Outline?'
                    overlayClassName={`${classes.deletePopconfirm}
                               ${darkMode ? classes.deletePopconfirmDark : ''}`}
                    onConfirm={() => {
                        dispatch(updateCurrentOutline([]));
                        handleDropdownOpen();
                    }}
                    onCancel={() => {
                        handleDropdownOpen();
                    }}
                >
                    <div className={
                        `${classes.versionHistoryDropdownTitle} ${darkMode ? classes.versionHistoryDropdownTitleDark : ''}`
                    }>
                        <RemoveIcon color={iconColor} />
                        <span>Delete Outline</span>
                    </div>
                </Popconfirm>
            ),
            onClick: (event) => {
                event.preventDefault();
            },
        },
    ];

    return (
        <div
            className={`${classes.versionHistoryContainer} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <div className={classes.briefActionsWrapper}>
                {showPageTitle && <PageTitleInput />}
                <AIButton />
            </div>

            <VersionHistory
                versions={mappedOutlineVersions}
                previewVersionId={outlinePreviewVersion?.id}
                loading={currentOutlineLoading}
                newVersionCreating={newVersionCreating}
                handleActivateVersion={handleActivateOutlineVersion}
                handlePreviewVersion={handlePreviewOutlineVersion}
                createNewVersion={createNewOutlineVersion}
                disableCreateNewVersionBtn={outlinePreviewVersion}
                handleUpdateVersionName={handleUpdateVersionName}
                handleDeleteVersion={handleDeleteOutlineVersion}
            />
            <MoreDropdown
                moreItems={moreItems}
                isDropdownOpen={isDropdownOpen}
                handleDropdownOpen={handleDropdownOpen}
            />
        </div>
    );
};

export default VersionHistoryContainer;
