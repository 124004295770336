import React, { useEffect, useRef, useState } from 'react';

import { Select } from 'antd';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import { useOnScreen } from '../../../../../utils/hooks/useOnScreen';
import { useOpen } from '../../../../../utils/hooks/useOpen';

const OptimizerSettingSelect = ({
    handleSelectCompetitorPositions,
    value,
    options,
    defaultValue,
}) => {
    const selectWrapperRef = useRef(null);

    const iconColor = useIconColor();
    const { isOpen, handleOpen } = useOpen();
    const isVisible = useOnScreen(selectWrapperRef);

    const [dropdownTopPosition, setDropdownTopPosition] = useState(0);

    const handleOptionChange = (value) => {
        handleSelectCompetitorPositions(value);
    };

    const handleScroll = () => {
        if (selectWrapperRef?.current) {
            const { bottom } =
                selectWrapperRef?.current?.getBoundingClientRect();

            setDropdownTopPosition(bottom);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('scroll', handleScroll, true);
        }

        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };

        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && !isVisible) {
            handleOpen();
        }

        // eslint-disable-next-line
    }, [isVisible, isOpen]);

    return (
        <div ref={selectWrapperRef}>
            <Select
                open={isOpen && isVisible}
                dropdownStyle={{
                    top: dropdownTopPosition,
                }}
                options={options}
                defaultValue={defaultValue}
                value={value}
                suffixIcon={
                    isOpen ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )
                }
                popupClassName='keyword-filter-select'
                onDropdownVisibleChange={handleOpen}
                onSelect={handleOptionChange}
            />
        </div>
    );
};

export default OptimizerSettingSelect;
