import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { colorPalette } from '../../../resources/styles/colorPalette';
import CloseIcon from '../../../resources/icons/CloseIcon';

import { useOpen } from '../../../utils/hooks/useOpen';

import CategoriesList from '../lists/CategoriesList';

import './TableInfoModal.scss';

const classes = {
    modalWrapper: 'modal-wrapper legend-modal',
    modalWrapperDark: 'modal-wrapper-dark legend-modal-dark',
    modalFormFieldsWrapper: 'form-fields-wrapper',
};

const TableInfoModal = ({ title, infoCategories, iconSize }) => {
    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    // Needed to prevent sorting the table when clicking on the icon or the modal window
    const stopPropagation = (e) => e?.stopPropagation();

    return (
        <div onClick={stopPropagation}>
            <InfoCircleOutlined
                onClick={handleOpen}
                style={{
                    fontSize: iconSize || '100%',
                    color: '#728192',
                }}
            />

            <Modal
                centered
                title={title}
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
            >
                <div className={classes.modalFormFieldsWrapper}>
                    <CategoriesList categories={infoCategories} />
                </div>
            </Modal>
        </div>
    );
};

export default TableInfoModal;
