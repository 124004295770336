import { viewOnlyNavMenuOptions } from '../constants';
import { viewOnlySettingsActions } from './viewOnlySettings.actions';

const viewOnlySettingsInitialState = {
    activeCard: viewOnlyNavMenuOptions?.contentOptimizer,
    briefData: null,
    outlineData: null,
    isDataLoading: false,

    viewOnlyMode: false,
};

export const viewOnlySettingsReducer = (
    state = viewOnlySettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case viewOnlySettingsActions.SET_VIEW_ONLY_MODE: {
            return {
                ...state,
                viewOnlyMode: payload,
            };
        }

        case viewOnlySettingsActions.SET_OUTLINE_DATA: {
            return {
                ...state,
                outlineData: {
                    ...payload,
                    outline: payload?.outline
                        ? JSON.parse(payload?.outline)
                        : [],
                },
            };
        }
        case viewOnlySettingsActions.SET_BRIEF_DATA: {
            return {
                ...state,
                briefData: {
                    ...payload,
                    outline: payload?.outline
                        ? JSON.parse(payload?.outline)
                        : [],
                },
            };
        }
        case viewOnlySettingsActions.SET_ACTIVE_CARD: {
            return {
                ...state,
                activeCard: payload,
            };
        }

        case viewOnlySettingsActions.SET_IS_DATA_LOADING: {
            return {
                ...state,
                isDataLoading: payload,
            };
        }

        case viewOnlySettingsActions.CLEAR_VIEW_ONLY_SETTINGS: {
            return {
                state,
            };
        }

        default:
            return state;
    }
};
