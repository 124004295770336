import React from 'react';

import { useCurrentEditor } from '@tiptap/react';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../../../constants/queryPathes';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { getRandomId } from '../../../../../../utils/helpers/idGenerator';
import { openNotification } from '../../../../../../utils/helpers/openNotification';

const SelectImageInput = ({ inputFile }) => {
    const { editor } = useCurrentEditor();

    const saveImageInStorage = (imageData) => {
        axiosAPI
            .post(
                contentOptimizerPathes.imageStorage,
                {
                    image_data: imageData,
                    image_file_name: getRandomId() + '.png',
                },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                editor.chain().focus().setImage({ src: result.data.url }).run();
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });
    };

    return (
        <input
            type='file'
            name='myImage'
            accept='image/*'
            style={{ display: 'none' }}
            ref={inputFile}
            onChange={(event) => {
                const selectedFile = event.target.files;

                if (selectedFile && selectedFile[0]?.type?.includes('image')) {
                    const [imageFile] = selectedFile;
                    const fileReader = new FileReader();

                    fileReader.onload = () => {
                        const srcData = fileReader.result;

                        saveImageInStorage(srcData);
                    };

                    fileReader.readAsDataURL(imageFile);
                }

                event.target.value = null;
            }}
        />
    );
};

export default SelectImageInput;
