import { useSelector } from 'react-redux';

import { EDITOR_AI_MODEL_NAME } from '../constants/aiEditorConstants';

import { selectCurrentOptimizerLanguageCode } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';

const defaultAITextOptions = {
    format: 'rich-text',
    modelName: EDITOR_AI_MODEL_NAME,
    stream: false,
    insert: false,
};

const useAiTextOptions = () => {
    const languageCode = useSelector(selectCurrentOptimizerLanguageCode);

    const aiTextOptions = {
        ...defaultAITextOptions,
        language: languageCode,
    };

    return { aiTextOptions };
};

export default useAiTextOptions;
