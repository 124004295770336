export const adminUsersSettingsActions = {
    CLEAR_ADMIN_USERS_SETTINGS: 'CLEAR_ADMIN_USERS_SETTINGS',

    SET_USERS_DATA: 'SET_USERS_DATA',
    SET_USERS_IS_DATA_LOADING: 'SET_USERS_IS_DATA_LOADING',
    UPDATE_USERS_QUERY_PARAMS: 'UPDATE_USERS_QUERY_PARAMS',
    SET_USERS_TOTAL_PAGES: 'SET_USERS_TOTAL_PAGES',
};

export const setUsersData = (payload) => {
    return {
        type: adminUsersSettingsActions.SET_USERS_DATA,
        payload,
    };
};

export const setUsersIsDataLoading = (payload) => {
    return {
        type: adminUsersSettingsActions.SET_USERS_IS_DATA_LOADING,
        payload,
    };
};

export const updateUsersQueryParams = (payload) => {
    return {
        type: adminUsersSettingsActions.UPDATE_USERS_QUERY_PARAMS,
        payload,
    };
};

export const setUsersTotalPages = (payload) => {
    return {
        type: adminUsersSettingsActions.SET_USERS_TOTAL_PAGES,
        payload,
    };
};

export const clearUsersOrganizationsSettings = () => {
    return {
        type: adminUsersSettingsActions.CLEAR_ADMIN_USERS_SETTINGS,
    };
};
