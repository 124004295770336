import React from 'react';

const AddIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.00049 3.14844V10.8484'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3.15039 6.99805H10.8504'
                stroke={color}
                strokeWidth='0.8'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default AddIcon;
