import React from 'react';

import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';

import SharePageDropdown from '../../../../common/dropdown/SharePageDropdown';

const ContentOptimizerShareDropdown = ({
    optimizerId,
    children,
    isShared,
    onChangedSharedCallback,
}) => {
    const organizationSlug = useOrganizationSlug();

    return (
        <SharePageDropdown
            optimizerId={optimizerId}
            shareLink={`${window.location.origin}${
                process.env.REACT_APP_REDIRECT_DEV_SUB_URL
            }${organizationSlug}/share/optimizer/${optimizerId || ''}`}
            isShared={isShared}
            onChangedSharedCallback={onChangedSharedCallback}
        >
            {children}
        </SharePageDropdown>
    );
};

export default ContentOptimizerShareDropdown;
