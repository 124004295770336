import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCurrentUserRoles } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateOpenAiBriefsUsed } from '../../../pages/account/billing/store/billingSettings.actions';
import { selectCurrentSubscription } from '../../../pages/account/billing/store/billingSettings.selectors';
import { updateAccountTab } from '../../../pages/account/store/accountSettings.actions';
import { selectContentOptimizerViewOnlyMode } from '../../../pages/contentOptimizer/viewOnly/store/viewOnlySettings.selectors';
import { setAIState } from '../store/tiptapEditor.actions';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { checkIsAdmin } from '../../../../utils/helpers/checkIsAdmin';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';
import { useSubscriptionLimits } from '../../../../utils/hooks/useSubscriptionLimits';

import { accountTabs } from '../../../../constants/accountTabs';
import { errorNotificationMessage, notificationType } from '../../../../constants/notificationType';
import { paymentsPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';
import { EDITOR_AI_CREDITS_REQUIRED } from '../constants/aiEditorConstants';
import { getAccountTabRoute } from '../../router/routes';

const useEditorAiCredits = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const currentUserRoles = useSelector(selectCurrentUserRoles);
    const currentSubscription = useSelector(selectCurrentSubscription);

    const { remainingOpenAIBriefs } = useSubscriptionLimits();
    const organizationSlug = useOrganizationSlug();

    const hasSufficientAiCredits = useMemo(() => {
        return !viewOnlyMode ? remainingOpenAIBriefs >= EDITOR_AI_CREDITS_REQUIRED : true;
    }, [viewOnlyMode, remainingOpenAIBriefs]);

    const aiCreditsResetDate = useMemo(() => {
        return moment(currentSubscription?.current_period_end).format('MMM D, YYYY');
    }, [currentSubscription?.current_period_end]);

    const decrementAiCredits = useCallback(
        (successCallback) => {
            if (viewOnlyMode) {
                dispatch(setAIState({ isLoading: false }));
                successCallback?.();

                return;
            }

            axiosAPI
                .post(
                    paymentsPathes.decrementOpenAiBriefsCredits,
                    { decrement_value: EDITOR_AI_CREDITS_REQUIRED },
                    { ...getAxiosHeaders() }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(updateOpenAiBriefsUsed(result.data.openai_briefs_used));
                        successCallback?.();
                    }
                })
                .catch((error) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        error?.response?.data?.message || errorNotificationMessage
                    );
                })
                .finally(() => {
                    dispatch(setAIState({ isLoading: false }));
                });
        },
        [viewOnlyMode, dispatch]
    );

    const redirectToUpgrade = useCallback(() => {
        if (checkIsAdmin(currentUserRoles[0]?.name)) {
            const billingCreditsTab = accountTabs['billing-credits']?.key;

            navigate(`/${organizationSlug}${getAccountTabRoute(billingCreditsTab)}`);
            dispatch(updateAccountTab(billingCreditsTab));
        }
    }, [currentUserRoles, navigate, dispatch, organizationSlug]);

    return { hasSufficientAiCredits, aiCreditsResetDate, decrementAiCredits, redirectToUpgrade };
};

export default useEditorAiCredits;
