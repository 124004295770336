import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Switch, Tooltip, Typography } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../constants/notificationType';
import {
    contentOptimizerPathes,
    organizationPathes,
} from '../../../../../../../constants/queryPathes';

import MoreHorizontalIcon from '../../../../../../../resources/icons/MoreHorizontalIcon';
import ResetToDefaultIcon from '../../../../../../../resources/icons/ResetToDefaultIcon';
import { colorPalette } from '../../../../../../../resources/styles/colorPalette';

import { setUserData } from '../../../../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectDarkMode,
    selectSwitchersVisibilitySettings,
    selectUserData,
    selectUserOrganizationSettings,
} from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateCompetitor } from '../../../store/contentOptimizerContent.actions';

import { axiosAPI } from '../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../../../utils/helpers/openBasicErrorNotification';
import { openNotification } from '../../../../../../../utils/helpers/openNotification';
import { getThemeColorClass } from '../../../../../../../utils/helpers/themeHelpers';
import useGetListOfContentBriefs from '../../../../../../../utils/hooks/useGetListOfContentBriefs';
import { useIconColor } from '../../../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../../../utils/hooks/useOpen';

import { updateEditorOptimalsBasicData } from '../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

import CustomDropdown from '../../../../../../common/dropdown/CustomDropdown';

const { Title } = Typography;

const classes = {
    listMoreButton:
        'list-more-button d-flex flex-center align-items-center btn-hover-light-primary',
    toggleContainer: 'd-flex align-items-center',
    competitors3Dots: 'competitors-3-dots',
    dropdownOverlay: 'three-dots-dropdown-overlay',
    resetToDefaultContainer: 'd-flex align-items-center reset-to-default-container',
    tooltipOverlay: 'three-dots tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    resetToDefault: 'reset-to-default',
};

const visibleSwitchersOptimizerIds = 'visible_switchers_optimizer_ids';

const ThreeDotsCompetitorActionsDropdown = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();
    const optimizerId = +contentOptimizerId;
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const getListOfContentBriefs = useGetListOfContentBriefs();
    const darkMode = useSelector(selectDarkMode);
    const isSwitchersVisible = useSelector(selectSwitchersVisibilitySettings(contentOptimizerId));
    const user = useSelector(selectUserData);
    const organizationSettings = useSelector(selectUserOrganizationSettings) || {};

    const [isLoading, setIsLoading] = useState(false);

    const handleResetToDefault = () => {
        setIsLoading(true);

        axiosAPI
            .patch(
                `${contentOptimizerPathes.resetCompetitorsToDefault}/${contentOptimizerId}`,
                {},
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                batch(() => {
                    dispatch(
                        updateCompetitor(
                            result?.data?.task_result?.map((x) => ({
                                onpageTaskId: x?.onpage_task_id,
                                useInOptimalRange: x?.include_in_optimal_range,
                            }))
                        )
                    );
                    dispatch(updateEditorOptimalsBasicData(result?.data?.optimals));
                });

                getListOfContentBriefs(contentOptimizerId);

                openNotification(
                    notificationType.success,
                    'Success',
                    'Competitors list was reset to default state'
                );
            })
            .catch(() => {
                openNotification(notificationType.error, 'Error', errorNotificationMessage);
            })
            .finally(() => setIsLoading(false));
    };

    const handleSwitchersVisibility = () => {
        setIsLoading(true);

        const value = !isSwitchersVisible;
        const optimizerIds = organizationSettings[visibleSwitchersOptimizerIds];
        let newModel = { [visibleSwitchersOptimizerIds]: optimizerIds };

        if (value) {
            if (optimizerIds) {
                newModel[visibleSwitchersOptimizerIds] = [...optimizerIds, optimizerId];
            } else {
                newModel[visibleSwitchersOptimizerIds] = [optimizerId];
            }
        } else {
            if (optimizerIds) {
                newModel[visibleSwitchersOptimizerIds] = [
                    ...optimizerIds.filter((optId) => optId !== optimizerId),
                ];
            }
        }

        axiosAPI
            .patch(
                `${organizationPathes.updateOrganization}/${user.organization.id}`,
                {
                    settings: newModel,
                },
                { ...getAxiosHeaders() }
            )
            .then((res) => {
                dispatch(
                    setUserData({
                        ...user,
                        organization: {
                            ...user.organization,
                            settings: res?.data?.settings,
                        },
                    })
                );
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <CustomDropdown
            withExtraContent
            withArrow
            dropdownOpenElement={
                <Tooltip
                    title={
                        'Customize which competitors to include in Optimal settings. If you’re unsure, we recommend using the default settings provided.'
                    }
                    overlayClassName={`${classes.tooltipOverlay} ${classes.tooltipOverlay} ${
                        darkMode ? classes.tooltipOverlayDark : ''
                    }`}
                    placement='top'
                >
                    <Button className={`${classes.listMoreButton} ${getThemeColorClass(darkMode)}`}>
                        <MoreHorizontalIcon color={colorPalette.basicGray} />
                    </Button>
                </Tooltip>
            }
            dropdownContent={
                <div className={classes.competitors3Dots}>
                    <div
                        className={classes.toggleContainer}
                        onClick={() => handleSwitchersVisibility()}
                    >
                        <Switch checked={isSwitchersVisible} size='small' />
                        <Title level={5}>Customize URLs</Title>
                    </div>
                    <div
                        className={classes.resetToDefaultContainer}
                        onClick={() => (!isLoading ? handleResetToDefault() : null)}
                    >
                        <ResetToDefaultIcon color={iconColor} />
                        <Title level={5} className={classes.resetToDefault}>
                            Reset To Default
                        </Title>
                    </div>
                </div>
            }
            dropdownOverlayClassName={classes.dropdownOverlay}
            dropdownTriggersArray={['click']}
            isDropdownOpen={isOpen}
            handleOpenChange={handleOpen}
        />
    );
};

export default ThreeDotsCompetitorActionsDropdown;
