import { combineReducers } from 'redux';

import { adminQuotasSettingsReducer } from '../quotas/store/adminQuotasSettings.reducer';
import { adminOrganizationsSettingsReducer } from '../organizations/store/adminOrganizationsSettings.reducer';
import { adminUsersSettingsReducer } from '../users/store/adminUsersSettings.reducer';

export const adminReducer = combineReducers({
    adminOrganizationsSettings: adminOrganizationsSettingsReducer,
    adminUsersSettings: adminUsersSettingsReducer,
    adminQuotasSettings: adminQuotasSettingsReducer,
});
