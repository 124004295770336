import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from 'antd';

import CopySectionIcon from '../../../resources/icons/CopySectionIcon';

import { colorPalette } from '../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { additionalContentToEditor } from '../tiptapEditor/store/tiptapEditor.actions';

import './CopyItem.scss';

const classes = {
    copyIcon: 'copy-icon',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const CopyItem = ({
    copyLabel,
    copiedText,
    iconSize,
    copyFunction,
    mappedOutlineEditorText,
}) => {
    const dispatch = useDispatch();

    const [isCopied, setIsCopied] = useState(false);

    const darkMode = useSelector(selectDarkMode);

    const iconColor = darkMode
        ? colorPalette.textAccentColor.dark
        : colorPalette.textAccentColor.light;

    const handleCopy = () => {
        setIsCopied(true);

        if (copyFunction) {
            copyFunction();
        } else {
            navigator.clipboard.writeText(copiedText || '');
        }

        if (mappedOutlineEditorText) {
            const copiedOutlineEditorText = mappedOutlineEditorText();

            if (copiedOutlineEditorText?.length > 0) {
                dispatch(additionalContentToEditor(copiedOutlineEditorText));
            }
        }
    };

    const handleTooltipOpenChange = () => {
        setIsCopied(false);
    };

    return (
        <Tooltip
            overlayClassName={`${classes.tooltipOverlay} ${
                darkMode ? classes.tooltipOverlayDark : ''
            }`}
            title={
                isCopied
                    ? 'Copied!'
                    : `${copyLabel ? copyLabel : 'Copy to clipboard'}`
            }
            onOpenChange={handleTooltipOpenChange}
        >
            <div className={classes.copyIcon} onClick={handleCopy}>
                <CopySectionIcon
                    className={classes.copyIcon}
                    color={iconColor}
                    onClick={handleCopy}
                    size={iconSize}
                />
            </div>
        </Tooltip>
    );
};

export default CopyItem;
