import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { checkIsTechAdmin } from '../../../utils/helpers/checkIsGlobalTechAdmin';
import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import { selectCurrentUserRoles } from '../../../userBrowserSettings/store/browserSettings.selectors';

import QuotaPage from '../../pages/admin/quotas/QuotasPage';
import OrganizationPage from '../../pages/admin/organizations/OrganizationsPage';
import UsagePage from '../../pages/admin/usage/UsagePage';
import AccountPage from '../../pages/account/AccountPage';

import ErrorsHandeledLayout from '../layout/ErrorsHandledLayout';
import ProtectedLayout from '../layout/ProtectedLayout';
import CancellationsPage from '../../pages/admin/cancellations/CancellationsPage';

const GlobalAdminRouter = () => {
    const organizationSlug = useOrganizationSlug();

    const currentUserRoles = useSelector(selectCurrentUserRoles);

    return (
        <Routes>
            <Route element={<ProtectedLayout />}>
                <Route element={<ErrorsHandeledLayout />}>
                    <Route
                        path={`${organizationSlug}/usage`}
                        element={<UsagePage />}
                    />
                    <Route
                        path={`${organizationSlug}/organizations`}
                        element={<OrganizationPage />}
                    />
                    <Route
                        path={`${organizationSlug}/account/profile`}
                        element={<AccountPage />}
                    />
                    <Route
                        path={`${organizationSlug}/cancellations`}
                        element={<CancellationsPage />}
                    />
                </Route>
                {checkIsTechAdmin(currentUserRoles) && (
                    <Route
                        path={`${organizationSlug}/quota`}
                        element={<QuotaPage />}
                    />
                )}
            </Route>

            <Route
                path='*'
                element={<Navigate to={`${organizationSlug}/usage`} />}
            />
        </Routes>
    );
};

export default GlobalAdminRouter;
