import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getNumberWithCommaAsThousandSeparator } from '../../../../../utils/helpers/getNumberWithCommaAsThousandSeparator';
import { getNumberRoundedToHundreds } from '../../../../../utils/helpers/getNumberRoundedToHundreds';

import { rankabilityScoreCategories } from '../../../../../constants/rankabilityScoreCategories';

import {
    calculateOptimalRankabilityScore,
    getOptimalReadabilityLabel,
    getReadabilityItem,
    getReadabilityLabel,
} from '../../../../../utils/helpers/getOptimizerBasicData';

import CompleteBadge from '../../../../common/completeBadge/CompleteBadge';
import TableInfoModal from '../../../../common/tableInfo/TableInfoModal';

import { selectEditorBasicData } from '../../../../common/tiptapEditor/store/tiptapEditor.selectors';

import TickIcon from '../../../../../resources/icons/versionHistory/TickIcon';

const classes = {
    contentOptimizerBasicData: 'content-optimizer-basic-data',
    basicDataBlock: 'basic-data-block',
    blockTitle: 'block-title text-decoration-dotted',
    blockBody: 'block-body',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    checkOutlinedIcon: 'check-outlined-icon',
    textDotted: 'text-decoration-dotted',
    basicDataValue: 'basic-data-value',
    recommendationArticle: 'basic-data-recommendation-article',
};

const ContentOptimizerBasicData = () => {
    const darkMode = useSelector(selectDarkMode);
    const stateBasicData = useSelector(selectEditorBasicData);

    const [basicData, setBasicData] = useState(null);

    useEffect(() => {
        setBasicData({
            ...stateBasicData,
        });
    }, [stateBasicData, setBasicData]);

    const optimalRS = calculateOptimalRankabilityScore(
        basicData?.optimal_rankability_score?.max
    );

    const checkReadability = () => {
        const basicDataReadability = Math.max(
            1,
            Math.min(basicData?.readability || 100, 100)
        );

        if (basicDataReadability && basicData?.optimal_readability) {
            if (
                basicData?.optimal_readability?.min &&
                basicData?.optimal_readability?.max
            ) {
                return (
                    basicDataReadability >=
                        getReadabilityItem(basicData?.optimal_readability.min)
                            ?.min &&
                    basicDataReadability <=
                        getReadabilityItem(basicData?.optimal_readability.max)
                            ?.max
                );
            } else if (basicData?.optimal_readability?.min) {
                return (
                    basicDataReadability >=
                    getReadabilityItem(basicData?.optimal_readability.min)?.min
                );
            } else if (basicData?.optimal_readability?.max) {
                return (
                    basicDataReadability <=
                    getReadabilityItem(basicData?.optimal_readability.max)?.max
                );
            }
        } else {
            return false;
        }
    };

    const checkRankabilityScore = () => {
        if (
            basicData?.rankability_score &&
            basicData?.optimal_rankability_score
        ) {
            return Math.ceil(basicData?.rankability_score) >= optimalRS;
        } else {
            return false;
        }
    };

    if (!basicData) return null;

    return (
        <div className={`${classes.contentOptimizerBasicData}`}>
            <div className={`${classes.basicDataBlock}`}>
                <Typography
                    className={`d-flex ${classes.blockTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <Tooltip
                        placement='bottom'
                        title={
                            'Measures content quality and relevance using natural language processing (NLP) AI technology from Google and IBM Watson.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                    ${
                                        darkMode
                                            ? classes.tooltipOverlayDark
                                            : ''
                                    }`}
                    >
                        <div className={classes.textDotted}>
                            Rankability Score
                        </div>
                    </Tooltip>
                    <TableInfoModal
                        iconSize='0.8em'
                        title={'Rankability Score'}
                        infoCategories={rankabilityScoreCategories}
                    />
                </Typography>
                <div className={`${classes.basicDataValue} d-flex`}>
                    <CompleteBadge
                        progress={
                            basicData
                                ? Math.ceil(basicData?.rankability_score)
                                : 0
                        }
                        size='small'
                    />
                    {checkRankabilityScore() && (
                        <div className={classes.checkOutlinedIcon}>
                            <TickIcon />
                        </div>
                    )}
                </div>
                <Typography className={classes.recommendationArticle}>
                    Optimal: {optimalRS}+{' '}
                </Typography>
            </div>
            <div className={`${classes.basicDataBlock}`}>
                <Tooltip
                    placement='bottom'
                    title={
                        'Recommended number of words to use in your content relative to the top-ranked competition.'
                    }
                    overlayClassName={`${classes.tooltipOverlay}
                                    ${
                                        darkMode
                                            ? classes.tooltipOverlayDark
                                            : ''
                                    }`}
                >
                    <Typography
                        className={`${classes.blockTitle} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        Word Count
                    </Typography>
                </Tooltip>
                <Typography className={`${classes.blockBody}`}>
                    {getNumberWithCommaAsThousandSeparator(
                        basicData?.word_count
                    )}
                    {basicData?.word_count &&
                    basicData?.word_count >=
                        getNumberRoundedToHundreds(
                            basicData?.optimal_word_count.min
                        ) &&
                    basicData?.word_count <=
                        getNumberRoundedToHundreds(
                            basicData?.optimal_word_count.max
                        ) ? (
                        <div className={classes.checkOutlinedIcon}>
                            <TickIcon />
                        </div>
                    ) : (
                        ''
                    )}
                </Typography>
                <Typography className={classes.recommendationArticle}>
                    Optimal:{' '}
                    {getNumberWithCommaAsThousandSeparator(
                        getNumberRoundedToHundreds(
                            basicData?.optimal_word_count.min
                        )
                    )}
                    {' - '}
                    {getNumberWithCommaAsThousandSeparator(
                        getNumberRoundedToHundreds(
                            basicData?.optimal_word_count.max
                        )
                    )}
                </Typography>
            </div>
            <div className={`${classes.basicDataBlock}`}>
                <Tooltip
                    placement='bottom'
                    title={
                        'Grade that measures how easy your content is to read based on the Flesch-Kincaid formula.'
                    }
                    overlayClassName={`${classes.tooltipOverlay}
                                    ${
                                        darkMode
                                            ? classes.tooltipOverlayDark
                                            : ''
                                    }`}
                >
                    <Typography
                        className={`${classes.blockTitle} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        Readability
                    </Typography>
                </Tooltip>
                <Typography className={`${classes.blockBody}`}>
                    {getReadabilityLabel(basicData?.readability || 100)}
                    {checkReadability() && (
                        <div className={classes.checkOutlinedIcon}>
                            <TickIcon />
                        </div>
                    )}
                </Typography>
                <Typography className={classes.recommendationArticle}>
                    Optimal:{' '}
                    {getOptimalReadabilityLabel(basicData?.optimal_readability)}
                </Typography>
            </div>
        </div>
    );
};

export default ContentOptimizerBasicData;
