/**
 * @deprecated
 */
export const contentOptimizerEditorActions = {
    CLEAR_CONTENT_OPTIMIZER_EDITOR:
        'CONTENT_OPTIMIZER_EDITOR/CLEAR_CONTENT_OPTIMIZER_EDITOR',

    UPDATE_CURRENT_EDITOR: 'CONTENT_OPTIMIZER_EDITOR/UPDATE_CURRENT_EDITOR',
    UPDATE_EDITOR_VERSIONS: 'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_VERSIONS',
    ADD_EDITOR_VERSION: 'CONTENT_OPTIMIZER_EDITOR/ADD_EDITOR_VERSION',
    UPDATE_EDITOR_CURRENT_VERSION_SCORE:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_CURRENT_VERSION_SCORE',
    UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT',
    UPDATE_EDITOR_VERSION_IMPORTED_URL:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_VERSION_IMPORTED_URL',
    SET_EDITOR_PREVIEW_VERSION:
        'CONTENT_OPTIMIZER_EDITOR/SET_EDITOR_PREVIEW_VERSION',
    UPDATE_EDITOR_VERSION_NAME:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_VERSION_NAME',
    DELETE_EDITOR_VERSION: 'CONTENT_OPTIMIZER_EDITOR/DELETE_EDITOR_VERSION',

    UPDATE_CURRENT_EDITOR_LOADING:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_CURRENT_EDITOR_LOADING',
    UPDATE_SAVE_EDITOR_LOADING:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_SAVE_EDITOR_LOADING',
    ADD_CONTENT_TO_EDITOR: 'CONTENT_OPTIMIZER_EDITOR/ADD_CONTENT_TO_EDITOR',

    UPDATE_HTML_TAGS: 'CONTENT_OPTIMIZER_EDITOR/UPDATE_HTML_TAGS',
    UPDATE_HIGHLIGHT_KEYWORDS:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_HIGHLIGHT_KEYWORDS',
    UPDATE_EDITOR_FULL_WIDTH:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_FULL_WIDTH',

    UPDATE_EDITOR_BASIC_DATA:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_BASIC_DATA',

    IS_ADD_LINK_MODAL_OPEN: 'CONTENT_OPTIMIZER_EDITOR/IS_ADD_LINK_MODAL_OPEN',

    UPDATE_EDITOR_DATA_AFTER_SAVE:
        'CONTENT_OPTIMIZER_EDITOR/UPDATE_EDITOR_DATA_AFTER_SAVE',
};

export const updateEditorFullWidth = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_FULL_WIDTH,
    payload,
});

export const updateEditorHighlightKeywords = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_HIGHLIGHT_KEYWORDS,
    payload,
});

export const isAddLinkModalOpen = (payload) => ({
    type: contentOptimizerEditorActions.IS_ADD_LINK_MODAL_OPEN,
    payload,
});

/**
 * @deprecated The action was used for Slate Editor
 */
export const updateEditorBasicData = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_BASIC_DATA,
    payload,
});

export const updateEditorHtmlTags = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_HTML_TAGS,
    payload,
});

export const updateEditorVersions = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_VERSIONS,
    payload,
});

export const addEditorVersion = (payload) => ({
    type: contentOptimizerEditorActions.ADD_EDITOR_VERSION,
    payload,
});

export const updateEditorCurrentVersionScore = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_CURRENT_VERSION_SCORE,
    payload,
});

export const updateEditorCurrentVersionWordCount = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT,
    payload,
});

export const updateEditorVersionImportedUrl = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_VERSION_IMPORTED_URL,
    payload,
});

export const setEditorPreviewVersion = (payload) => ({
    type: contentOptimizerEditorActions.SET_EDITOR_PREVIEW_VERSION,
    payload,
});

export const updateEditorVersionName = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_VERSION_NAME,
    payload,
});

export const deleteEditorVersion = (payload) => ({
    type: contentOptimizerEditorActions.DELETE_EDITOR_VERSION,
    payload,
});

export const updateSaveEditorLoading = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_SAVE_EDITOR_LOADING,
    payload,
});

export const updateCurrentEditorLoading = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_CURRENT_EDITOR_LOADING,
    payload,
});

export const updateCurrentEditor = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_CURRENT_EDITOR,
    payload,
});

export const addContentToEditor = (payload) => ({
    type: contentOptimizerEditorActions.ADD_CONTENT_TO_EDITOR,
    payload,
});

export const clearContentOptimizerEditor = () => ({
    type: contentOptimizerEditorActions.CLEAR_CONTENT_OPTIMIZER_EDITOR,
});

export const updateEditorDataAfterSave = (payload) => ({
    type: contentOptimizerEditorActions.UPDATE_EDITOR_DATA_AFTER_SAVE,
    payload,
});
