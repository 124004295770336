import React from 'react';
import { useSelector } from 'react-redux';

import {
    CartesianGrid,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import BaseChartTooltip from '../../../common/charts/BaseChartTooltip';

const classes = {};

const UsageChart = ({
    width,
    height,
    data,
    dataKey,
    xAxisDataKey,
    tooltipContent,
    yAxisTicks,
    valueDescLabel,
    strokeColor,
}) => {
    const darkMode = useSelector(selectDarkMode);

    if (!data || data?.length < 2) {
        return null;
    }

    return (
        <LineChart
            height={height || 200}
            width={width || 720}
            data={data}
            margin={{ left: -16, right: 16, top: 10, bottom: -10 }}
        >
            <CartesianGrid strokeDasharray='8 8' />
            <XAxis
                dataKey={xAxisDataKey || 'name'}
                strokeOpacity={0}
                dy={10}
                // We need it for disable x axis label
                tickFormatter={() => ''}
            />
            <YAxis
                strokeOpacity={0}
                dx={0}
                a
                ticks={yAxisTicks}
                allowDecimals={false}
            />
            <Tooltip
                content={
                    tooltipContent || (
                        <BaseChartTooltip
                            dataKey={dataKey || 'value'}
                            valueDescLabel={valueDescLabel}
                        />
                    )
                }
                overlayClassName={`${classes.tooltipOverlay}
                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
            />
            <Line
                dataKey={dataKey || 'value'}
                stroke={strokeColor}
                strokeWidth={1.5}
            />
        </LineChart>
    );
};

export default UsageChart;
