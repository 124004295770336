import React from 'react';

import { Col, Row } from 'antd';

import ContentOptimizerBasicData from './ContentOptimizerBasicData';
import ContentOptimizerTabSwitch from './ContentOptimizerTabSwitch';

import './OptimizerContent.scss';

const classes = {
    contentOptimizerTopContent: 'content-optimizer-top-content',
    switcherWrapper: 'content-optimizer-switcher-wrapper',
};

const ContentOptimizerTopContent = () => {
    return (
        <Row className={`${classes.contentOptimizerTopContent}`}>
            <Col span={17}>
                <ContentOptimizerBasicData />
            </Col>
            <Col span={7} className={`${classes.switcherWrapper}`}>
                <ContentOptimizerTabSwitch />
            </Col>
        </Row>
    );
};

export default ContentOptimizerTopContent;
