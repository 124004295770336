import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectSubscriptionLimits } from '../../components/pages/account/billing/store/billingSettings.selectors';

const handleNonNegativeValue = (value) => Math.max(value, 0);

const calculateRemainingCredits = (total, used) =>
    handleNonNegativeValue(total - used);

export const useSubscriptionLimits = () => {
    const subscriptionLimits = useSelector(selectSubscriptionLimits);

    const totalKeywordResearchCredits = useMemo(
        () => subscriptionLimits?.keyword_researches || 0,
        [subscriptionLimits?.keyword_researches]
    );

    const usedKeywordResearchCredits = useMemo(
        () => subscriptionLimits?.keyword_researches_used || 0,
        [subscriptionLimits?.keyword_researches_used]
    );

    const remainingKeywordResearchCredits = useMemo(
        () =>
            calculateRemainingCredits(
                totalKeywordResearchCredits,
                usedKeywordResearchCredits
            ),
        [totalKeywordResearchCredits, usedKeywordResearchCredits]
    );

    const totalOptimizers = useMemo(
        () =>
            (subscriptionLimits?.optimizers || 0) +
            (subscriptionLimits?.optimizers_added || 0),
        [subscriptionLimits?.optimizers, subscriptionLimits?.optimizers_added]
    );

    const usedOptimizers = useMemo(
        () => subscriptionLimits?.optimizers_used || 0,
        [subscriptionLimits?.optimizers_used]
    );

    const remainingOptimizers = useMemo(
        () => calculateRemainingCredits(totalOptimizers, usedOptimizers),
        [totalOptimizers, usedOptimizers]
    );

    const totalContentMonitors = useMemo(
        () => subscriptionLimits?.content_monitors || 0,
        [subscriptionLimits?.content_monitors]
    );

    const usedContentMonitors = useMemo(
        () => subscriptionLimits?.content_monitors_used || 0,
        [subscriptionLimits?.content_monitors_used]
    );

    const remainingContentMonitors = useMemo(
        () =>
            calculateRemainingCredits(
                totalContentMonitors,
                usedContentMonitors
            ),
        [totalContentMonitors, usedContentMonitors]
    );

    const totalOpenAIBriefs = useMemo(
        () =>
            (subscriptionLimits?.openai_briefs || 0) +
            (subscriptionLimits?.openai_briefs_added || 0),
        [
            subscriptionLimits?.openai_briefs,
            subscriptionLimits?.openai_briefs_added,
        ]
    );

    const usedOpenAIBriefs = useMemo(
        () => subscriptionLimits?.openai_briefs_used || 0,
        [subscriptionLimits?.openai_briefs_used]
    );

    const remainingOpenAIBriefs = useMemo(
        () => calculateRemainingCredits(totalOpenAIBriefs, usedOpenAIBriefs),
        [totalOpenAIBriefs, usedOpenAIBriefs]
    );

    return {
        totalKeywordResearchCredits,
        usedKeywordResearchCredits,
        remainingKeywordResearchCredits,
        totalOptimizers,
        usedOptimizers,
        remainingOptimizers,
        totalContentMonitors,
        usedContentMonitors,
        remainingContentMonitors,
        totalOpenAIBriefs,
        usedOpenAIBriefs,
        remainingOpenAIBriefs,
    };
};
