import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'antd';
import moment from 'moment';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { finishedStatusList } from '../../../../../../constants/queueConsts';

import { getObjectDataFromJSON } from '../../../../../../utils/helpers/getObjectFromJSON';
import { calculateOptimalRankabilityScore } from '../../../../../../utils/helpers/getOptimizerBasicData';

import { selectContentMonitorData } from '../../store/contentMonitorMain.selectors';
import { useContentMonitorTableColumns } from './hooks/useContentMonitorTableColumns';

const classes = {
    monitorsTable: '',
    tableRow: 'table-row',
};

const ContentMonitorTable = () => {
    const darkMode = useSelector(selectDarkMode);
    const contentMonitorData = useSelector(selectContentMonitorData);

    const contentMonitorTableColumns = useContentMonitorTableColumns();

    const [monitorTableData, setMonitorTableData] = useState([]);

    useEffect(() => {
        if (contentMonitorData?.length > 0) {
            const mappedCMData = contentMonitorData.map((monitor) => {
                if (!monitor) {
                    return null;
                }

                const optimalRankabilityScore = getObjectDataFromJSON(
                    monitor.optimal_rankability_score
                );
                const scoreToDisplay =
                    optimalRankabilityScore?.max !== undefined &&
                    finishedStatusList.includes(monitor.queue_status)
                        ? calculateOptimalRankabilityScore(optimalRankabilityScore.max)
                        : 0;

                return {
                    id: monitor.id,
                    key: monitor.id,
                    monitoredPage: {
                        title: monitor.title,
                        link: monitor.url,
                        country_code: monitor.country_code,
                        language_code: monitor.language_code,
                    },
                    monitoredKeyword: monitor.monitored_keyword,
                    avgRs: Math.ceil(monitor.average_rankability_score),
                    optimalRankabilityScore: scoreToDisplay,
                    rankabilityScore: monitor.rankability_score,
                    change: monitor.change,
                    added: moment(monitor.created_at).format('MMM DD, YYYY'),
                    projects: monitor.cm_projects,
                    queue_progress: monitor.queue_progress,
                    queue_status: monitor.queue_status,
                    created_at: monitor.created_at,
                    disabled: monitor.disabled,
                };
            });

            setMonitorTableData(mappedCMData);
        } else {
            setMonitorTableData([]);
        }
    }, [contentMonitorData]);

    return (
        <Table
            dataSource={monitorTableData}
            columns={contentMonitorTableColumns}
            loading={false}
            bordered
            showSorterTooltip={false}
            pagination={false} // No need, because 100 is a maximum possible limit
            rowClassName={`${classes.tableRow}`}
            className={`${classes.monitorsTable}
            ${darkMode ? 'dark-mode' : ''}`}
            scroll={{
                x: true,
            }}
        />
    );
};

export default ContentMonitorTable;
