import React from 'react';

const SettingsIcon = ({ color = '#9199AD'}) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 13C11.3807 13 12.5 11.8807 12.5 10.5C12.5 9.11929 11.3807 8 10 8C8.61929 8 7.5 9.11929 7.5 10.5C7.5 11.8807 8.61929 13 10 13Z'
                stroke={color}
                strokeWidth='1.2'
                strokeMiterlimit='10'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
            <path
                d='M1.66663 11.2334V9.76669C1.66663 8.90003 2.37496 8.18336 3.24996 8.18336C4.75829 8.18336 5.37496 7.1167 4.61663 5.80836C4.18329 5.05836 4.44163 4.08336 5.19996 3.65003L6.64163 2.82503C7.29996 2.43336 8.14996 2.6667 8.54163 3.32503L8.63329 3.48336C9.38329 4.7917 10.6166 4.7917 11.375 3.48336L11.4666 3.32503C11.8583 2.6667 12.7083 2.43336 13.3666 2.82503L14.8083 3.65003C15.5666 4.08336 15.825 5.05836 15.3916 5.80836C14.6333 7.1167 15.25 8.18336 16.7583 8.18336C17.625 8.18336 18.3416 8.89169 18.3416 9.76669V11.2334C18.3416 12.1 17.6333 12.8167 16.7583 12.8167C15.25 12.8167 14.6333 13.8834 15.3916 15.1917C15.825 15.95 15.5666 16.9167 14.8083 17.35L13.3666 18.175C12.7083 18.5667 11.8583 18.3334 11.4666 17.675L11.375 17.5167C10.625 16.2084 9.39163 16.2084 8.63329 17.5167L8.54163 17.675C8.14996 18.3334 7.29996 18.5667 6.64163 18.175L5.19996 17.35C4.44163 16.9167 4.18329 15.9417 4.61663 15.1917C5.37496 13.8834 4.75829 12.8167 3.24996 12.8167C2.37496 12.8167 1.66663 12.1 1.66663 11.2334Z'
                stroke={color}
                strokeWidth='1.2'
                strokeMiterlimit='10'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
        </svg>
    );
};

export default SettingsIcon;
