import React from 'react';

const AISummarizeIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 24 24'
            fill='none'
            stroke={color}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='stroke-icon'
        >
            <path d='M3 6h18'></path>
            <path d='M7 12h10'></path>
            <path d='M10 18h4'></path>
        </svg>
    );
};

export default AISummarizeIcon;
