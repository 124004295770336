import { useSelector } from 'react-redux';

import { selectIsSubscriptionExpired } from '../../userBrowserSettings/store/browserSettings.selectors';

import ContentOptimizerIcon from '../../resources/icons/ContentOptimizer';
import KeywordResearchIcon from '../../resources/icons/KeywordResearch';
import ContentMonitorIcon from '../../resources/icons/ContentMonitor';

export const useDefaultSiderMenuItems = () => {
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const preventRedirect = (e) => {
        e?.preventDefault();
    };

    return [
        {
            label: 'Keyword Research',
            key: 'research',
            icon: KeywordResearchIcon,
            isDisabled: isSubscriptionExpired,
            handleClick: isSubscriptionExpired ? preventRedirect : null,
        },
        {
            label: 'Content Optimizer',
            key: 'optimizer',
            icon: ContentOptimizerIcon,
            isDisabled: isSubscriptionExpired,
            handleClick: isSubscriptionExpired ? preventRedirect : null,
        },
        {
            label: 'Content Monitor',
            key: 'monitor',
            icon: ContentMonitorIcon,
            isDisabled: isSubscriptionExpired,
            handleClick: isSubscriptionExpired ? preventRedirect : null,
        },
    ];
};
