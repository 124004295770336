import { userRoleRadioItems } from '../../components/pages/account/users/constants/userRoleRadioItems';
import { roleTypes } from '../../constants/roleTypes';

export const getFilteredUserRoleItems = (userRole) => {
    const userRoleRating = Object.values(roleTypes).find(
        (roleType) => roleType.key === userRole
    ).rating;

    const filteredRoleKeys = Object.values(roleTypes)
        .filter((roleType) => roleType.rating <= userRoleRating)
        .map((roleType) => roleType.key);

    return userRoleRadioItems.filter((item) =>
        filteredRoleKeys.includes(item.value)
    );
};
