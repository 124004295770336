import React from 'react';

import CustomHeader from '../../common/header/Header';

const HomePageHeader = () => {
    return (
        <CustomHeader
        // TODO: — Hide all “How To Use” buttons (temporary)
        //showUseSetting
        />
    );
};

export default HomePageHeader;
