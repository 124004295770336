import { useState } from 'react';

export const useOpen = (defaultIsOpen = false) => {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    return { isOpen, handleOpen };
};