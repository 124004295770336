import React from 'react';

const InvoiceUnpaidIcon = ({ color = '#728192' }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 22.24 19.9'
            width='70'
        >
            <defs>
                <style>{`.cls-1{fill:${color};}.cls-2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}`}</style>
            </defs>
            <path
                className='cls-1'
                d='M13.5,9.25H2a.75.75,0,0,1,0-1.5H13.5a.75.75,0,0,1,0,1.5Z'
                transform='translate(-1.25 -1.35)'
            />
            <path
                className='cls-1'
                d='M8,17.25H6a.75.75,0,0,1,0-1.5H8a.75.75,0,0,1,0,1.5Z'
                transform='translate(-1.25 -1.35)'
            />
            <path
                className='cls-1'
                d='M14.5,17.25h-4a.75.75,0,0,1,0-1.5h4a.75.75,0,0,1,0,1.5Z'
                transform='translate(-1.25 -1.35)'
            />
            <path
                className='cls-1'
                d='M17.56,21.25H6.44c-4,0-5.19-1.2-5.19-5.14V7.89C1.25,4,2.46,2.75,6.44,2.75H13.5a.75.75,0,0,1,0,1.5H6.44c-3.14,0-3.69.54-3.69,3.64V16.1c0,3.1.55,3.64,3.69,3.64H17.55c3.14,0,3.69-.54,3.69-3.64V11a.75.75,0,0,1,1.5,0V16.1C22.75,20.05,21.54,21.25,17.56,21.25Z'
                transform='translate(-1.25 -1.35)'
            />
            <path
                className='cls-2'
                d='M22.74,5.9a3.73,3.73,0,0,1-.55,2,3.79,3.79,0,0,1-6.5,0,3.73,3.73,0,0,1-.55-2,3.8,3.8,0,1,1,7.6,0Z'
                transform='translate(-1.25 -1.35)'
                stroke='#EF3E32'
            />
            <path
                className='cls-2'
                d='M18.94,9.7a3.8,3.8,0,1,0-3.8-3.8A3.8,3.8,0,0,0,18.94,9.7Z'
                transform='translate(-1.25 -1.35)'
                stroke='#EF3E32'
            />
            <path
                className='cls-2'
                d='M20,7,18,5'
                transform='translate(-1.25 -1.35)'
                stroke='#EF3E32'
            />
            <path
                className='cls-2'
                d='M19.93,5l-2,2'
                transform='translate(-1.25 -1.35)'
                stroke='#EF3E32'
            />
        </svg>
    );
};

export default InvoiceUnpaidIcon;
