export const contentBriefsMainTabs = {
    active: {
        key: 'active',
        label: 'Active',
    },
    archived: {
        key: 'archived',
        label: 'Archived',
    },
    progress: {
        key: 'progress',
        label: 'In Progress',
    },
    completed: {
        key: 'completed',
        label: 'Completed',
    },
    projects: {
        key: 'projects',
        label: 'Projects',
    },
}
