export const monthlySearchesTrendOptions = [
    {
        value: 'up',
        label: 'Upward trend',
    },
    {
        value: 'down',
        label: 'Downward trend',
    },
    {
        value: 'flat',
        label: 'No change',
    },
];

export const monthlySearchesMonthsOptions = [
    {
        value: 3,
        label: 'Last 3 months',
    },
    {
        value: 6,
        label: 'Last 6 months',
    },
    {
        value: 12,
        label: 'Last 12 months',
    },
];
