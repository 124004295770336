import { sortOrders } from '../../../../../constants/sortOrders';
import { usersSortFields } from '../../constants';
import { adminUsersSettingsActions } from './adminUsersSettings.actions';

const adminUsersSettingsInitialState = {
    usersData: [],
    isDataLoading: false,
    queryParams: {
        page: 1,
        per_page: 20,
        sort_field: usersSortFields.created_at,
        sort_order: sortOrders.desc,
    },
    totalPages: 1,
};

export const adminUsersSettingsReducer = (
    state = adminUsersSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case adminUsersSettingsActions.SET_USERS_DATA: {
            return {
                ...state,
                usersData: payload,
            };
        }

        case adminUsersSettingsActions.SET_USERS_IS_DATA_LOADING: {
            return {
                ...state,
                isDataLoading: payload,
            };
        }

        case adminUsersSettingsActions.UPDATE_USERS_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        case adminUsersSettingsActions.SET_USERS_TOTAL_PAGES: {
            return {
                ...state,
                totalPages: payload,
            };
        }

        case adminUsersSettingsActions.CLEAR_ADMIN_USERS_SETTINGS: {
            return {
                ...adminUsersSettingsInitialState,
            };
        }

        default:
            return state;
    }
};
