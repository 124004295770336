import { contentOptimizerMainTabs } from '../../../../../constants/contentOptimizerMainTabs';
import { sortOptimizerListByStatuses } from '../../../../../utils/helpers/contentOptimizerHelpers';
import { contentOptimizerMainActions } from './contentOptimizerMain.actions';

const contentOptimizerMainInitialState = {
    contentOptimizerMainTab: contentOptimizerMainTabs.single.key,
    contentOptimizerData: null,
    contentOptimizersChecked: [],
    queryParams: {
        page: 1,
        per_page: 20,
        search: '',
        sort: 'created_at',
        order: 'desc',
        projects: null,
        archived: false,
        users: null,
        dateRangePicker: null,
    },
    totalCount: 0,
    optimizersCount: {
        active: 0,
        scored: 0,
        archived: 0,
        projects: 0,
    },
    projects: [],
    isImportURLActive: false,
    contentOptimizerForUpdate: null,
    contentOptimizerTabKey: '',
};

export const contentOptimizerMainReducer = (
    state = contentOptimizerMainInitialState,
    { type, payload }
) => {
    switch (type) {
        case contentOptimizerMainActions.SET_IS_IMPORT_URL_ACTIVE: {
            return {
                ...state,
                isImportURLActive: payload,
            };
        }

        case contentOptimizerMainActions.SET_PROJECTS: {
            return {
                ...state,
                projects: payload,
            };
        }

        case contentOptimizerMainActions.ADD_PROJECT: {
            return {
                ...state,
                projects: [payload, ...state.projects],
            };
        }

        case contentOptimizerMainActions.MULTIPLE_UPDATE_CONTENT_OPTIMIZER_DATA: {
            const updatedContentOptimizersData =
                state.contentOptimizerData.map((optimizer) => {
                    const newOptimizer = payload?.find(
                        (newOptimizer) => newOptimizer?.id === optimizer?.id
                    );

                    if (newOptimizer) {
                        return { ...optimizer, ...newOptimizer };
                    }

                    return optimizer;
                }) || [];

            return {
                ...state,
                contentOptimizerData: sortOptimizerListByStatuses(
                    updatedContentOptimizersData
                ),
            };
        }

        case contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_MAIN_TAB: {
            return {
                ...state,
                contentOptimizerMainTab: payload,
            };
        }

        case contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_DATA: {
            return {
                ...state,
                contentOptimizerData: sortOptimizerListByStatuses(payload),
            };
        }

        case contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_DATA: {
            return {
                ...state,
                contentOptimizerData: state.contentOptimizerData.map((item) => {
                    if (payload.find((i) => i.id === item.id)) {
                        return {
                            ...item,
                            ...payload.find((i) => i.id === item.id),
                        };
                    }

                    return item;
                }),
            };
        }

        case contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_SHARED: {
            return {
                ...state,
                contentOptimizerData: state.contentOptimizerData.map((item) => {
                    if (payload.id === item.id) {
                        return {
                            ...item,
                            shared: payload.shared,
                        };
                    }

                    return item;
                }),
            };
        }

        case contentOptimizerMainActions.REMOVE_CONTENT_OPTIMIZER_DATA: {
            return {
                ...state,
                contentOptimizerData: state.contentOptimizerData.filter(
                    (item) => !payload.includes(item.id)
                ),
            };
        }

        case contentOptimizerMainActions.ADD_CONTENT_OPTIMIZER_DATA: {
            return {
                ...state,
                contentOptimizerData: sortOptimizerListByStatuses(
                    state.contentOptimizerData,
                    payload
                ),
            };
        }

        case contentOptimizerMainActions.SET_CONTENT_OPTIMIZERS_CHECKED: {
            return {
                ...state,
                contentOptimizersChecked: payload,
            };
        }

        case contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        case contentOptimizerMainActions.CLEAR_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM: {
            return {
                ...state,
                queryParams: {
                    ...contentOptimizerMainInitialState.queryParams,
                },
            };
        }

        case contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_MAIN_TOTAL_COUNT: {
            return {
                ...state,
                totalCount: payload,
            };
        }

        case contentOptimizerMainActions.SET_CONTENT_OPTIMIZERS_COUNT: {
            return {
                ...state,
                optimizersCount: payload,
            };
        }

        case contentOptimizerMainActions.INCREASE_OPTIMIZERS_COUNT: {
            return {
                ...state,
                optimizersCount: Object.keys(state.optimizersCount).reduce(
                    (acc, key) => {
                        if (payload.find((item) => item.key === key)) {
                            return {
                                ...acc,
                                [key]:
                                    state.optimizersCount[key] +
                                    payload.find((item) => item.key === key)
                                        .value,
                            };
                        } else {
                            return {
                                ...acc,
                                [key]: state.optimizersCount[key],
                            };
                        }
                    },
                    {}
                ),
            };
        }

        case contentOptimizerMainActions.DECREASE_OPTIMIZERS_COUNT: {
            return {
                ...state,
                optimizersCount: Object.keys(state.optimizersCount).reduce(
                    (acc, key) => {
                        if (payload.find((item) => item.key === key)) {
                            return {
                                ...acc,
                                [key]:
                                    state.optimizersCount[key] -
                                    payload.find((item) => item.key === key)
                                        .value,
                            };
                        } else {
                            return {
                                ...acc,
                                [key]: state.optimizersCount[key],
                            };
                        }
                    },
                    {}
                ),
            };
        }

        case contentOptimizerMainActions.CLEAR_CONTENT_OPTIMIZER_MAIN: {
            return {
                ...state.contentOptimizerData,
                ...state.optimizersCount,
                ...contentOptimizerMainInitialState,
            };
        }

        case contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_FOR_UPDATE: {
            return {
                ...state,
                contentOptimizerForUpdate: payload,
            };
        }

        case contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_TAB_KEY: {
            return {
                ...state,
                contentOptimizerTabKey: payload,
            };
        }

        default: {
            return state;
        }
    }
};
