import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Badge, Table, Typography } from 'antd';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsAdmin } from '../../../../../utils/helpers/checkIsAdmin';
import { getRoleLabel } from '../../../../../utils/helpers/getRoleLabel';
import { getUsersStatusLabel } from '../../../../../utils/helpers/getUsersStatusLabel';
import { sortUsersByFieldAndOrder } from '../../../../../utils/helpers/sortUsersByFieldAndOrder';
import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';

import { accountTabs } from '../../../../../constants/accountTabs';
import { roleTypes } from '../../../../../constants/roleTypes';
import { sortOrders } from '../../../../../constants/sortOrders';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';

import { updateAccountTab } from '../../store/accountSettings.actions';

import { accountUsersSortFields } from '../constants/accountUsersSortFields';

import DeleteInvitePopConfirm from './DeleteInvitePopConfirm';
import DisableUserPopConfirm from './DisableUserPopConfirm';
import EditProfileModalWrapper from './EditProfileModalWrapper';

const classes = {
    actionsWrapper: 'actions-wrapper d-flex justify-content-between',
    linkWrapper: 'link-wrapper',
    link: 'link-hover-highlight space-no-wrap',
    tableHeaderTitle:
        'd-flex align-items-center users-table-header-title cursor-pointer',

    usersStatusLabel: 'users-status-label',
    usersStatusLabelActive: 'users-status-label-active',
    usersStatusLabelInactive: 'users-status-label-inactive',
};

const UsersTable = ({ users, invites }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const organizationSlug = useOrganizationSlug();

    const user = useSelector(selectUserData);
    const darkMode = useSelector(selectDarkMode);

    const [tableData, setTableData] = useState([]);
    const [usersSort, setUsersSort] = useState({
        field: null,
        order: sortOrders.desc,
    });

    useEffect(() => {
        const concatedData = invites.concat(users);
        const sortedUsers = sortUsersByFieldAndOrder(
            concatedData,
            usersSort.field,
            usersSort.order
        );

        const updatedTableData = sortedUsers.map((user) => {
            return {
                key: `${user.id}${user?.user ? '-invite' : ''}`,
                id: user?.id,
                email: user?.email,
                role: user?.roles?.[0]?.name || user?.role?.name,
                invite: user?.user,
                active: user?.active,
            };
        });

        setTableData(updatedTableData);
    }, [users, invites, usersSort]);

    const handleEditOwnProfile = () => {
        dispatch(updateAccountTab(accountTabs.profile.key));
        navigate(`/${organizationSlug}/account/${accountTabs.profile.key}`);
    };

    const handleSort = (sortField) => {
        setUsersSort({
            field: sortField,
            order:
                usersSort.field === sortField &&
                usersSort.order === sortOrders.desc
                    ? sortOrders.asc
                    : sortOrders.desc,
        });
    };

    const checkIsUserRoleLower = (editableUserRole) => {
        const editRoleRating = Object.values(roleTypes).find(
            (roleType) => roleType?.key === editableUserRole
        )?.rating;
        const userRoleRating = Object.values(roleTypes).find(
            (roleType) => roleType?.key === user?.roles?.[0]?.name
        )?.rating;

        return editRoleRating <= userRoleRating;
    };

    const renderActions = (record) => {
        if (user.id?.toString() === record.key) {
            return (
                <Typography.Link
                    underline
                    onClick={handleEditOwnProfile}
                    className={`${classes.link}
                    ${darkMode ? 'dark-mode' : ''}`}
                >
                    Edit profile
                </Typography.Link>
            );
        } else if (checkIsUserRoleLower(record.role)) {
            if (record.invite) {
                return (
                    <div className={classes.actionsWrapper}>
                        <div className={classes.linkWrapper}>
                            <Typography.Link
                                underline
                                className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
                            >
                                Resend
                            </Typography.Link>
                        </div>
                        <div className={classes.linkWrapper}>
                            <DeleteInvitePopConfirm inviteId={record?.id} />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={classes.actionsWrapper}>
                        {record?.active && (
                            <div className={classes.linkWrapper}>
                                <EditProfileModalWrapper
                                    editableUser={record}
                                />
                            </div>
                        )}
                        {checkIsAdmin(user?.roles?.[0]?.name) && (
                            <div className={classes.linkWrapper}>
                                <DisableUserPopConfirm
                                    userId={record?.id}
                                    userRole={record?.role}
                                    active={record?.active}
                                />
                            </div>
                        )}
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    const columns = [
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.email)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.email &&
                            'active'
                        }
                    >
                        EMAIL
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.email
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'email',
            width: '50%',
            render: (_, record) => {
                return (
                    <>
                        {record.email}
                        {record.invite && (
                            <Badge
                                color={colorPalette.colorBadgeGray}
                                count='Pending invite'
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.status)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.status &&
                            'active'
                        }
                    >
                        STATUS
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.status
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'status',
            render: (_, record) => {
                return (
                    <div
                        className={`${classes.usersStatusLabel}
                    ${
                        record?.active
                            ? classes.usersStatusLabelActive
                            : classes.usersStatusLabelInactive
                    }
                    ${darkMode ? 'dark-mode' : ''}`}
                    >
                        {getUsersStatusLabel(record?.active, record?.invite)}
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.role)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.role &&
                            'active'
                        }
                    >
                        ROLE
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.role
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'role',
            render: (role) => {
                return getRoleLabel(role);
            },
        },
        {
            title: 'ACTION',
            dataIndex: 'operation',
            render: (_, record) => {
                return renderActions(record);
            },
        },
    ];

    return (
        <Table
            dataSource={tableData}
            columns={columns}
            bordered
            pagination={false}
            showSorterTooltip={false}
        />
    );
};

export default UsersTable;
