import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

// TODO: Remove after tiptap editor is done
// import EditorWrapper from './editor/EditorWrapper';

import TipTapEditorWrapper from './tiptapEditor/TipTapEditorWrapper';

import KeywordsBriefWrapper from './rightBlock/brief/KeywordsBriefWrapper';
import './OptimizerTab.scss';

const classes = {
    optimizerTab: 'optimizer-tab',
};

const OptimizerTabContainer = () => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Row
            className={`${classes.optimizerTab} 
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            <Col span={17}>
                {/* TODO: Remove after tiptap editor is done */}
                {/* <EditorWrapper /> */}
                <TipTapEditorWrapper />
            </Col>
            <Col span={7}>
                <KeywordsBriefWrapper />
            </Col>
        </Row>
    );
};

export default OptimizerTabContainer;
