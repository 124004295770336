export const linkSearchParams = {
    keyword: {
        param: 'keyword',
    },
    source: {
        param: 'source',
        values: {
            invite: 'invite',
        },
    },
    email: {
        param: 'email'
    },
};
