// representation of Flesch-Kincaid readability score
// https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests
export const readabilityLabel = [
    {
        label: 'Professional',
        min: 0,
        max: 10,
    },
    {
        label: 'College Graduate',
        min: 10,
        max: 30,
    },
    {
        label: 'College',
        min: 30,
        max: 50,
    },
    {
        label: 'High School',
        min: 50,
        max: 60,
    },
    {
        label: '8-9th Grade',
        min: 60,
        max: 70,
    },
    {
        label: '7th Grade',
        min: 70,
        max: 80,
    },
    {
        label: '6th Grade',
        min: 80,
        max: 90,
    },
    {
        label: '5th Grade',
        min: 90,
        max: 100,
    },
];
