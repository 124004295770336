import { dateSortKeys } from '../../../contentMonitor/mainPage/content/header/constants';

export const usageDateSortItems = [
    {
        key: dateSortKeys.thisMonth,
        label: 'This month',
    },
    {
        key: dateSortKeys.lastMonth,
        label: 'Last month',
    },
    {
        key: dateSortKeys.last30Days,
        label: 'Last 30 days',
    },
    {
        key: dateSortKeys.last90Days,
        label: 'Last 90 days',
    },
];

export const defaultUsageDateSortItem = usageDateSortItems[2]?.key;

export const usageUserSortKeys = {
    all: 'all',
    freeTrial: 'free_trial',
    paid: 'paid',
};

export const usageUserSortItems = [
    {
        key: usageUserSortKeys.all,
        label: 'All',
    },
    {
        key: usageUserSortKeys.freeTrial,
        label: 'Free trial',
    },
    {
        key: usageUserSortKeys.paid,
        label: 'Paid',
    },
];

export const defaultUsageUserSortItem = usageUserSortItems[0]?.key;
