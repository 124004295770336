import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table, Typography } from 'antd';

import { sortOrders } from '../../../../../constants/sortOrders';

import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { renderPaginationArrows } from '../../../../../utils/helpers/renderPaginationArrows';
import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';

import { usageStatsTableFields } from '../../constants';
import { sortUsageStatsByFieldAndOrder } from './sortUsageStatsByFieldAndOrder';

const classes = {
    mainLabel: 'main-label',
    organizationStatsLabel: 'organization-stats-label',
    tableHeaderTitle: 'd-flex align-items-center stats-table-header-title cursor-pointer',
};

const UsageOrganizationStatsTable = ({ organizationStats }) => {
    const darkMode = useSelector(selectDarkMode);

    const [statsData, setStatsData] = useState([]);
    const [statsSort, setStatsSort] = useState({
        field: null,
        order: sortOrders.desc,
    });
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        defaultCurrent: 1,
        itemRender: renderPaginationArrows,
        showSizeChanger: true,
    });

    useEffect(() => {
        const sortedStats = sortUsageStatsByFieldAndOrder(
            organizationStats,
            statsSort.field,
            statsSort.order
        );

        const updatedStats = sortedStats.map((stat, index) => {
            return {
                ...stat,
                key: index,
            };
        });

        setStatsData(updatedStats);
    }, [organizationStats, statsSort]);

    const handleSort = (sortField) => {
        setStatsSort({
            field: sortField,
            order:
                statsSort.field === sortField && statsSort.order === sortOrders.desc
                    ? sortOrders.asc
                    : sortOrders.desc,
        });
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const organizationStatsColumns = Object.keys(usageStatsTableFields).map((field) => {
        const column = usageStatsTableFields[field];

        return {
            title: (
                <div className={classes.tableHeaderTitle} onClick={() => handleSort(field)}>
                    <Typography className={statsSort.field === field && 'active'}>
                        {column.title.toUpperCase()}
                    </Typography>
                    <TableHeaderSortIcons
                        active={statsSort?.field === field}
                        order={statsSort?.order}
                    />
                </div>
            ),
            dataIndex: field,
            key: field,
            width: column.width,
        };
    });

    return (
        <>
            <Typography
                className={`${classes.mainLabel} ${classes.organizationStatsLabel} ${getThemeColorClass(darkMode)}`}
            >
                Organization Stats
            </Typography>
            <Table
                dataSource={statsData}
                columns={organizationStatsColumns}
                loading={false}
                bordered
                rowKey={'userId'}
                showSorterTooltip={false}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default UsageOrganizationStatsTable;
