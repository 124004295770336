export const getRanksWithCorrectSortMobilePosition = (ranks) => {
    for (let i = 0; i < ranks.length - 1; i++) {
        let currentRank = ranks[i];
        let nextRank = ranks[i + 1];

        if (
            currentRank.rank?.mobile &&
            !currentRank.rank?.desktop &&
            nextRank.rank?.desktop &&
            currentRank.rank?.mobile === nextRank.rank?.desktop
        ) {
            ranks.splice(i, 2, nextRank, currentRank);
        }
    }

    return ranks;
};
