import React from 'react';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

const classes = {
    navigationButton:
        'd-flex flex-center align-items-center quick-nav-btn btn-hover-light-primary ml-16px',
};

const QuickNavigationItem = ({ title, icon, link }) => {
    const navigate = useNavigate();

    const organizationSlug = useOrganizationSlug();

    const handleNavigate = () => {
        navigate(`/${organizationSlug}/${link}`);
    };

    return (
        <Button
            className={classes.navigationButton}
            onClick={handleNavigate}
        >
            {icon}
            <Typography>{title}</Typography>
        </Button>
    );
};

export default QuickNavigationItem;
