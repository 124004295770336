import React from 'react';
import { useSelector } from 'react-redux';

import { Drawer, Typography } from 'antd';
import moment from 'moment';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../../../utils/hooks/useIconColor';
import CloseIcon from '../../../../../../resources/icons/CloseIcon';

import CustomDivider from '../../../../../common/Divider';

const { Title } = Typography;

const classes = {
    invoiceDetailsDraweWrapper: 'invoice-details-drawer-wrapper',
    invoiceDetailsDraweWrapperDark: 'invoice-details-drawer-wrapper-dark',
    closeWrapper: 'close-wrapper d-flex cursor-pointer',
    summarySection: 'invoice-section summary-section',
    itemsSection: 'invoice-section items-section',
    itemsSectionInfoBlock:
        'info-block d-flex justify-content-between align-items-center',
    sectionSubtitle: 'section-subtitle',
    sectionLabel: 'section-label',
    directionColumn: 'flex-column',
    invoiceDataItemWrapper: 'd-flex w-100 justify-content-between',
};

const InvoiceDetailsDrawer = ({ isOpen, handleOpen, invoiceData }) => {
    const darkMode = useSelector(selectDarkMode);

    const iconColor = useIconColor();

    const regex = /×(.*?)\(at/g;

    return (
        <Drawer
            width={460}
            rootClassName={`${classes.invoiceDetailsDraweWrapper}
                            ${
                                darkMode
                                    ? classes.invoiceDetailsDraweWrapperDark
                                    : ''
                            }`}
            title={null}
            placement='right'
            onClose={handleOpen}
            open={isOpen}
            closable={false}
        >
            <div
                className={classes.closeWrapper}
                onClick={handleOpen}
            >
                <CloseIcon color={iconColor} />
                <Typography>Close invoice and payment details</Typography>
            </div>
            <Title level={4}>
                Paid on {moment(invoiceData?.created * 1000).format('LL') || ''}
            </Title>
            <section className={classes.summarySection}>
                <Typography className={classes.sectionSubtitle}>
                    Summary
                </Typography>
                <CustomDivider marginBottom={12} />
                <div className='d-flex align-items-center'>
                    <Typography className={classes.sectionLabel}>
                        Invoice
                    </Typography>
                    <Title level={5}>{invoiceData?.number || ''}</Title>
                </div>
            </section>
            <section className={classes.itemsSection}>
                <Typography className={classes.sectionSubtitle}>
                    Items
                </Typography>
                <CustomDivider marginBottom={12} />
                <Typography className='fw-500'>
                    {moment(
                        invoiceData?.items?.[0]?.period?.start * 1000
                    ).format('MMM D') || ''}{' '}
                    -{' '}
                    {moment(invoiceData?.items?.[0]?.period?.end * 1000).format(
                        'LL'
                    ) || ''}
                </Typography>
                <div
                    className={`${classes.itemsSectionInfoBlock} ${classes.directionColumn}`}
                >
                    {invoiceData?.items?.map((item) => {
                        return (
                            <div
                                className={classes.invoiceDataItemWrapper}
                                key={item?.id}
                            >
                                <Title level={5}>
                                    {item?.description?.indexOf('×') > -1
                                        ? regex
                                              .exec(item?.description)[1]
                                              .trim()
                                        : item?.description?.split('after')[0]}
                                </Title>
                                <Title level={5}>
                                    {item?.amount < 0
                                        ? `-$${-(
                                              item?.amount / 100 || 0
                                          ).toFixed(2)}`
                                        : `$${(item?.amount / 100 || 0).toFixed(
                                              2
                                          )}`}
                                </Title>
                            </div>
                        );
                    })}
                </div>
                <CustomDivider marginBottom={12} />
                <div className={classes.itemsSectionInfoBlock}>
                    <Title level={5}>Total due</Title>
                    <Title level={5}>
                        ${(invoiceData?.amount_due / 100 || 0).toFixed(2)}
                    </Title>
                </div>
                <CustomDivider marginBottom={12} />
                <div className={classes.itemsSectionInfoBlock}>
                    <Title level={5}>Amount paid</Title>
                    <Title level={5}>
                        -${(invoiceData?.amount_paid / 100 || 0).toFixed(2)}
                    </Title>
                </div>
                <div className={classes.itemsSectionInfoBlock}>
                    <Title level={5}>Amount remaining</Title>
                    <Title level={5}>
                        ${(invoiceData?.amount_remaining / 100 || 0).toFixed(2)}
                    </Title>
                </div>
            </section>
        </Drawer>
    );
};

export default InvoiceDetailsDrawer;
