export const authSettingsActions = {
    CLEAR_AUTH_SETTINGS: 'AUTH_SETTINGS/CLEAR_AUTH_SETTINGS',

    UPDATE_USER_SIGN_UP: 'AUTH_SETTINGS/UPDATE_USER_SIGN_UP',
    SET_STARTER_PLAN_DATA: 'AUTH_SETTINGS/SET_STARTER_PLAN_DATA',
    SET_CUSTOMER_ID: 'AUTH_SETTINGS/SET_CUSTOMER_ID',
    SET_USER_EMAIL: 'AUTH_SETTINGS/SET_USER_EMAIL',
};

export const updateUserSignUp = (payload) => {
    return {
        type: authSettingsActions.UPDATE_USER_SIGN_UP,
        payload,
    };
};

export const setStarterPlanData = (payload) => {
    return {
        type: authSettingsActions.SET_STARTER_PLAN_DATA,
        payload,
    };
};

export const setCustomerId = (payload) => {
    return {
        type: authSettingsActions.SET_CUSTOMER_ID,
        payload,
    };
};

export const clearBrowserSettings = () => {
    return {
        type: authSettingsActions.CLEAR_AUTH_SETTINGS,
    };
};

export const setUserRegistrationEmail = (payload) => {
    return {
        type: authSettingsActions.SET_USER_EMAIL,
        payload,
    };
};
