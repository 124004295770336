import React from 'react';

const OverviewIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.6667 6.87435V14.9993C16.6667 17.4993 15.175 18.3327 13.3333 18.3327H6.66667C4.825 18.3327 3.33334 17.4993 3.33334 14.9993V6.87435C3.33334 4.16602 4.825 3.54102 6.66667 3.54102C6.66667 4.05768 6.87498 4.52435 7.21665 4.86601C7.55831 5.20768 8.025 5.41602 8.54167 5.41602H11.4583C12.4917 5.41602 13.3333 4.57435 13.3333 3.54102C15.175 3.54102 16.6667 4.16602 16.6667 6.87435Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.3333 3.54102C13.3333 4.57435 12.4917 5.41602 11.4583 5.41602H8.54166C8.025 5.41602 7.55831 5.20768 7.21664 4.86601C6.87498 4.52435 6.66666 4.05768 6.66666 3.54102C6.66666 2.50768 7.50833 1.66602 8.54166 1.66602H11.4583C11.975 1.66602 12.4417 1.87435 12.7834 2.21602C13.125 2.55769 13.3333 3.02435 13.3333 3.54102Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.66666 10.834H10'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M6.66666 14.166H13.3333'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default OverviewIcon;
