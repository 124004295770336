export const advancedSettingsTermListItems = [
    'Prevents the keyword from displaying inside Optimizer',
    `Doesn't impact previously-completed Optimizers`,
    'Will not be used in Rankability score calculation',
];

export const advancedSettingsDomainsListItems = [
    'Prevents a URL from being analyzed by our NLP technology',
    'Can impact the quality and accuracy of keyword recommendations',
    `Doesn't impact previously-completed Optimizers`,
];

export const advancedSettingsDomainsStatusListItems = [
    {
        success: false,
        text: 'google.com/',
    },
    {
        success: false,
        text: 'https://www.google.com',
    },
    {
        success: true,
        text: 'www.google.com',
    },
    {
        success: true,
        text: 'google.com',
    },
];
