import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import {
    selectOptimizersLimit,
    selectOptimizersUsed,
    selectOptimizersAdded,
} from '../billing/store/billingSettings.selectors';

const classes = {
    secondLabel: 'second-label',
};

const UsageReportsCreditsLabel = () => {
    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);

    return (
        <Typography className={`${classes.secondLabel}`}>
            {optimizersLimit + optimizersAdded - optimizersUsed || 0}/
            {optimizersLimit + optimizersAdded || 0} credits remaining
        </Typography>
    );
};

export default UsageReportsCreditsLabel;
