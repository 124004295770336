import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Form, Input, Modal, Spin, Typography } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../constants/notificationType';
import { contentMonitorPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';

import CloseIcon from '../../../resources/icons/CloseIcon';
import PlusIcon from '../../../resources/icons/Plus';
import { colorPalette } from '../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../utils/helpers/openNotification';

import {
    addContentMonitorProject,
    setMonitorsQueryParams,
} from '../../pages/contentMonitor/mainPage/store/contentMonitorMain.actions';
import { selectContentMonitorQueryParams } from '../../pages/contentMonitor/mainPage/store/contentMonitorMain.selectors';

import ErrorMessage from '../errorMessage/ErrorMessage';
import Loader from '../loader/Loader';

import { projectsMethods } from './projectsMethods';

const { Title, Link } = Typography;

const classes = {
    manageProjectBtn: 'checked-items-actions-panel-btn manage-project-btn',
    modalWrapper: 'modal-wrapper manage-project-modal',
    modalWrapperDark: 'modal-wrapper-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalInputWrapper: 'input-wrapper',
    modalSubtitle: 'modal-subtitle',
    modalLink: 'manage-project-modal-link',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    availableProjectsWrapper: 'available-projects-wrapper',
    noProjectMessage: 'no-project-message',
};

const formsNames = {
    newProjectName: 'newProjectName',
    selected: 'selected',
    available: 'available',
};

const ManageMonitorsProjectModal = ({
    isOpen,
    handleOpen,
    checkedItems,
    selectedProjects,
    handleUpdateProjects,
}) => {
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const newProjectName = Form.useWatch(formsNames.newProjectName, form);

    const darkMode = useSelector(selectDarkMode);
    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);

    const [fetchedProjects, setFetchedProjects] = useState([]);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [checkedAvailableProjects, setCheckedAvailableProjects] = useState(
        []
    );
    const [isProjectsLoading, setIsProjectsLoading] = useState(false);
    const [projectsError, setProjectsError] = useState(false);

    const [currentlySelectedProjects, setCurrentlySelectedProjects] = useState(
        []
    );
    const [isAddNewProjectLoading, setIsAddNewProjectLoading] = useState(false);
    const [addNewProjectError, setAddNewProjectError] = useState(false);

    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);

    const [newProjectAdded, setNewProjectAdded] = useState(false);

    useEffect(() => {
        if (selectedProjects?.length && isOpen) {
            // Get projects for selected monitors
            const selectedForAllProjects =
                projectsMethods.getArrayOfProjectsSelectedForAllMonitors(
                    selectedProjects
                );
            setCurrentlySelectedProjects(selectedForAllProjects);

            // Get projects for checked available monitors
            const selectedForSomeProjects =
                projectsMethods.getArrayOfProjectsSelectedForSomeMonitors(
                    selectedProjects,
                    selectedForAllProjects
                );
            setCheckedAvailableProjects(selectedForSomeProjects);
        }
    }, [selectedProjects, isOpen]);

    useEffect(() => {
        // Filter all projects to get available projects
        if (isOpen) {
            setAvailableProjects(
                fetchedProjects?.filter(
                    (project) =>
                        !currentlySelectedProjects?.some(
                            (selectedProject) =>
                                selectedProject?.id === project?.id
                        )
                )
            );
        }
    }, [isOpen, currentlySelectedProjects, fetchedProjects]);

    useEffect(() => {
        if (isOpen) {
            setIsProjectsLoading(true);

            axiosAPI
                .get(
                    contentMonitorPathes.cmProject,
                    { params: { with_monitors: true } },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setFetchedProjects(result.data);
                    }
                })
                .catch(() => {
                    setProjectsError(true);
                })
                .finally(() => {
                    setIsProjectsLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        form.setFieldValue(
            formsNames.selected,
            currentlySelectedProjects?.map((project) => project.id)
        );
        form.setFieldValue(
            formsNames.available,
            checkedAvailableProjects?.map((project) => project.id)
        );
    }, [currentlySelectedProjects, checkedAvailableProjects, form]);

    useEffect(() => {
        form.setFields([
            {
                name: formsNames.newProjectName,
                errors: [],
            },
        ]);
    }, [newProjectName, form]);

    const handleAddNewProject = () => {
        if (
            newProjectName &&
            !fetchedProjects.some((p) => p.name === newProjectName)
        ) {
            setIsAddNewProjectLoading(true);

            axiosAPI
                .post(
                    contentMonitorPathes.cmProject,
                    {
                        name: newProjectName,
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.create) {
                        dispatch(addContentMonitorProject(result?.data));
                        setCurrentlySelectedProjects([
                            result?.data,
                            ...currentlySelectedProjects,
                        ]);
                        setFetchedProjects([result?.data, ...fetchedProjects]);
                        form.setFieldValue(formsNames.newProjectName, '');

                        setNewProjectAdded(true);
                    }
                })
                .catch(() => {
                    setAddNewProjectError(true);
                })
                .finally(() => {
                    setIsAddNewProjectLoading(false);
                });
        } else {
            form.setFields([
                {
                    name: formsNames.newProjectName,
                    errors: ['Project already exists'],
                },
            ]);
        }
    };

    const handleSelectAvailableProject = (e) => {
        const { checked } = e.target;

        if (checked) {
            setCurrentlySelectedProjects([
                availableProjects.find((p) => p.id === e.target.value),
                ...currentlySelectedProjects,
            ]);
        } else {
            setCheckedAvailableProjects(
                checkedAvailableProjects.filter((p) => p.id !== e.target.value)
            );
        }
    };

    const handleSelectSelectedProject = (e) => {
        setCurrentlySelectedProjects(
            currentlySelectedProjects.filter((p) => p.id !== e.target.value)
        );
        setFetchedProjects((prev) => {
            if (prev.some((p) => p.id === e.target.value)) {
                return prev;
            } else {
                return [
                    ...prev,
                    fetchedProjects.find((p) => p.id === e.target.value),
                ];
            }
        });
    };

    const handleApply = () => {
        const checkedProjects = [
            ...currentlySelectedProjects,
            ...checkedAvailableProjects,
        ];

        const projectsToRemove = projectsMethods.getArrayOfProjectsToRemove(
            selectedProjects,
            checkedProjects
        );

        axiosAPI
            .patch(
                contentMonitorPathes.baseContentMonitorUrl,
                {
                    ids: checkedItems,
                    content_monitor: {
                        cm_project_ids_add: currentlySelectedProjects.map(
                            (project) => project.id
                        ),
                        cm_project_ids_remove: projectsToRemove.map(
                            (project) => project.id
                        ),
                    },
                },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    const filteredToProjectsResult = result?.data?.map(
                        (item) => item.cm_projects
                    );

                    const numOfInitialMonitorsWithProjects =
                        projectsMethods.getNumberOfItemsWithProjects(
                            selectedProjects
                        );
                    const numOfMonitorsWithProjectsAfterUpdate =
                        projectsMethods.getNumberOfItemsWithProjects(
                            filteredToProjectsResult
                        );

                    const monitorsAssignedToProjectsDiff =
                        numOfMonitorsWithProjectsAfterUpdate -
                        numOfInitialMonitorsWithProjects;

                    handleUpdateProjects(
                        result?.data,
                        monitorsAssignedToProjectsDiff
                    );
                    handleOpen();
                    deleteProjectsWithoutMonitors();
                }
            })
            .catch(() => {
                setUpdateError(true);
            })
            .finally(() => {
                setIsUpdateLoading(false);
                setNewProjectAdded(false);
            });
    };

    const handleCloseModal = () => {
        form.resetFields();

        setCurrentlySelectedProjects([]);
        setCheckedAvailableProjects([]);
        setAvailableProjects([]);
        setProjectsError(false);
        setAddNewProjectError(false);
        setUpdateError(false);

        if (newProjectAdded) {
            deleteProjectsWithoutMonitors();
        }
    };

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const deleteProjectsWithoutMonitors = () => {
        axiosAPI
            .delete(contentMonitorPathes.cmProject, {
                params: { without_monitors: true },
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (
                    result?.data?.deleted?.some(
                        (deleteProjectId) =>
                            deleteProjectId ===
                            Number(monitorQueryParams.project)
                    )
                ) {
                    dispatch(setMonitorsQueryParams({ project: null }));
                }
                setNewProjectAdded(false);
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });
    };

    return (
        <Modal
            centered
            title='Manage Project'
            open={isOpen}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            wrapClassName={`${classes.modalWrapper}
                            ${darkMode ? classes.modalWrapperDark : ''}`}
            afterClose={handleCloseModal}
        >
            {isProjectsLoading && <Loader />}
            {projectsError && <ErrorMessage />}
            {!isProjectsLoading && !projectsError && (
                <Form
                    form={form}
                    layout='vertical'
                    className={classes.profileForm}
                    onFinish={handleApply}
                >
                    <Spin
                        spinning={isAddNewProjectLoading}
                        indicator={<Loader />}
                    >
                        <div className={classes.modalFormFieldsWrapper}>
                            <div className={classes.modalInputWrapper}>
                                {(addNewProjectError || updateError) && (
                                    <ErrorMessage />
                                )}
                                <Form.Item
                                    label='Project name'
                                    name={formsNames.newProjectName}
                                >
                                    <Input placeholder='Enter your project name' />
                                </Form.Item>
                                {newProjectName && !isAddNewProjectLoading && (
                                    <Link
                                        className={classes.modalLink}
                                        onClick={handleAddNewProject}
                                    >
                                        <PlusIcon
                                            color={colorPalette.colorPrimary}
                                        />{' '}
                                        Add new Project
                                    </Link>
                                )}
                            </div>
                            {currentlySelectedProjects?.length > 0 && (
                                <>
                                    <Title
                                        level={5}
                                        className={classes.modalSubtitle}
                                    >
                                        Selected
                                    </Title>
                                    <Form.Item name={formsNames.selected}>
                                        <Checkbox.Group>
                                            {currentlySelectedProjects?.map(
                                                (project) => (
                                                    <Checkbox
                                                        key={`selected${project.id}`}
                                                        value={project.id}
                                                        onChange={
                                                            handleSelectSelectedProject
                                                        }
                                                        checked={true}
                                                        defaultChecked={true}
                                                    >
                                                        {project.name}
                                                    </Checkbox>
                                                )
                                            )}
                                        </Checkbox.Group>
                                    </Form.Item>
                                </>
                            )}
                            <Title level={5} className={classes.modalSubtitle}>
                                Available
                            </Title>
                            <Form.Item
                                name={formsNames.available}
                                className={classes.availableProjectsWrapper}
                            >
                                {availableProjects?.length > 0 &&
                                !isAddNewProjectLoading ? (
                                    <Checkbox.Group>
                                        {availableProjects.map((project) => (
                                            <Checkbox
                                                key={`available${project.id}`}
                                                value={project.id}
                                                onChange={
                                                    handleSelectAvailableProject
                                                }
                                            >
                                                {project.name}
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>
                                ) : (
                                    <Typography
                                        className={classes.noProjectMessage}
                                    >
                                        No available projects
                                    </Typography>
                                )}
                            </Form.Item>
                        </div>
                    </Spin>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                            className={`${darkMode ? 'dark-mode' : ''}`}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            loading={isUpdateLoading}
                        >
                            Apply
                        </Button>
                    </div>
                </Form>
            )}
        </Modal>
    );
};

export default ManageMonitorsProjectModal;
