import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../constants/statusCodes';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';

import { updateEditorBasicData } from '../../../../../common/tiptapEditor/store/tiptapEditor.actions';
import { updateContentBriefCompetitors } from '../../store/contentOptimizerContent.actions';
import { selectCurrentContentOptimizer } from '../../store/contentOptimizerContent.selectors';

import CompetitorsActionsWrapper from './content/CompetitorsActionsWrapper';
import CompetitorsTable from './content/CompetitorsTable';

import ContentOptimizerService from '../../../../../../services/contentOptimizer.service';

import './CompetitorsTab.scss';

const classes = {
    competitorsTab: 'competitors-tab',
    competitorsTableWrapper: 'competitors-table-wrapper',
};

const CompetitorsTabContainer = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const darkMode = useSelector(selectDarkMode);
    const currentContentOptimizer = useSelector(selectCurrentContentOptimizer);

    const organizationSlug = useOrganizationSlug();

    useEffect(() => {
        if (currentContentOptimizer?.content_brief_id) {
            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${currentContentOptimizer?.content_brief_id}`,
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (
                        result?.status === statusCodes.success &&
                        result?.data?.task_result?.length > 0
                    ) {
                        dispatch(updateContentBriefCompetitors(result?.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContentOptimizer?.content_brief_id]);

    useEffect(() => {
        if (contentOptimizerId) {
            ContentOptimizerService.getContentOptimizerVersions(
                contentOptimizerId,
                organizationSlug
            )
                .then((result) => {
                    dispatch(
                        updateEditorBasicData(
                            result?.data?.find((item) => item?.active)
                        )
                    );
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    return (
        <div
            className={`${classes.competitorsTab} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <CompetitorsActionsWrapper />
            <div className={classes.competitorsTableWrapper}>
                <CompetitorsTable />
            </div>
        </div>
    );
};

export default CompetitorsTabContainer;
