export const contentBriefsOutlineActions = {
    CLEAR_CONTENT_BRIEFS_SETTINGS:
        'CONTENT_BRIEFS_SETTINGS/CLEAR_CONTENT_BRIEFS_SETTINGS',

    SET_CURRENT_BRIEF: 'CONTENT_BRIEFS_SETTINGS/SET_CURRENT_BRIEF',
    SET_CURRENT_BRIEF_LOADING:
        'CONTENT_BRIEFS_SETTINGS/SET_CURRENT_BRIEF_LOADING',
    SET_BRIEF_VERSIONS: 'CONTENT_BRIEFS_SETTINGS/SET_BRIEF_VERSIONS',
    UPDATE_BRIEF_VERSION: 'CONTENT_BRIEFS_SETTINGS/UPDATE_BRIEF_VERSION',
    SET_CURRENT_BRIEF_ID: 'CONTENT_BRIEFS_SETTINGS/SET_CURRENT_BRIEF_ID',
    SET_PREVIEW_BRIEF_VERSION:
        'CONTENT_BRIEFS_SETTINGS/SET_PREVIEW_BRIEF_VERSION',
    UPDATE_BRIEF_VERSION_NAME:
        'CONTENT_BRIEFS_SETTINGS/UPDATE_BRIEF_VERSION_NAME',
    DELETE_BRIEF_VERSION: 'CONTENT_BRIEFS_SETTINGS/DELETE_BRIEF_VERSION',

    SET_RANKS_HTAGS: 'CONTENT_BRIEFS_SETTINGS/SET_RANKS_HTAGS',

    UPDATE_CURRENT_OUTLINE: 'CONTENT_BRIEFS_SETTINGS/UPDATE_CURRENT_OUTLINE',
    APPEND_TO_CURRENT_OUTLINE:
        'CONTENT_BRIEFS_SETTINGS/APPEND_TO_CURRENT_OUTLINE',
    UPDATE_OUTLINE_VERSIONS: 'CONTENT_BRIEFS_SETTINGS/UPDATE_OUTLINE_VERSIONS',
    ADD_OUTLINE_VERSION: 'CONTENT_BRIEFS_SETTINGS/ADD_OUTLINE_VERSION',
    UPDATE_CURRENT_OUTLINE_VERSION_PROGRESS:
        'CONTENT_BRIEFS_SETTINGS/UPDATE_CURRENT_OUTLINE_PROGRESS',
    UPDATE_CURRENT_OUTLINE_VERSION_WORD_COUNT:
        'CONTENT_BRIEFS_SETTINGS/UPDATE_CURRENT_OUTLINE_VERSION_WORD_COUNT',
    SET_PREVIEW_OUTLINE_VERSION:
        'CONTENT_BRIEFS_SETTINGS/SET_PREVIEW_OUTLINE_VERSION',
    UPDATE_OUTLINE_VERSION_NAME:
        'CONTENT_BRIEFS_SETTINGS/UPDATE_OUTLINE_VERSION_NAME',
    DELETE_OUTLINE_VERSION: 'CONTENT_BRIEFS_SETTINGS/DELETE_OUTLINE_VERSION',

    UPDATE_CURRENT_OUTLINE_LOADING:
        'CONTENT_BRIEFS_SETTINGS/UPDATE_CURRENT_OUTLINE_LOADING',

    SET_BRIEF_PROGRESS: 'CONTENT_BRIEFS_SETTINGS/SET_BRIEF_PROGRESS',
    SET_BRIEF_TIMINGS: 'CONTENT_BRIEFS_SETTINGS/SET_BRIEF_TIMINGS',
    SET_BRIEF_SHARED: 'CONTENT_BRIEFS_SETTINGS/SET_BRIEF_SHARED',
    SET_BRIEF_QUESTIONS: 'CONTENT_BRIEFS_SETTINGS/SET_BRIEF_QUESTIONS',
    SET_BRIEF_CITATIONS: 'CONTENT_BRIEFS_SETTINGS/SET_BRIEF_CITATIONS',

    SET_AI_BRIEF_LOADING: 'CONTENT_BRIEFS_SETTINGS/SET_AI_BRIEF_LOADING',
    SET_OUTLINE_VERSION_LOADING:
        'CONTENT_BRIEFS_SETTINGS/SET_OUTLINE_VERSION_LOADING',
};

export const setAIBriefLoading = (payload) => ({
    type: contentBriefsOutlineActions.SET_AI_BRIEF_LOADING,
    payload,
});

export const updateBriefVersion = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_BRIEF_VERSION,
    payload,
});

export const setBriefProgress = (payload) => ({
    type: contentBriefsOutlineActions.SET_BRIEF_PROGRESS,
    payload,
});

export const setBriefTimings = (payload) => ({
    type: contentBriefsOutlineActions.SET_BRIEF_TIMINGS,
    payload,
});

export const setBriefShared = (payload) => ({
    type: contentBriefsOutlineActions.SET_BRIEF_SHARED,
    payload,
});

export const setBriefQuestions = (payload) => ({
    type: contentBriefsOutlineActions.SET_BRIEF_QUESTIONS,
    payload,
});

export const setBriefCitations = (payload) => ({
    type: contentBriefsOutlineActions.SET_BRIEF_CITATIONS,
    payload,
});

export const clearContentBriefsSettings = () => ({
    type: contentBriefsOutlineActions.CLEAR_CONTENT_BRIEFS_SETTINGS,
});

export const setRanksHtags = (payload) => ({
    type: contentBriefsOutlineActions.SET_RANKS_HTAGS,
    payload,
});

export const setBriefVersions = (payload) => ({
    type: contentBriefsOutlineActions.SET_BRIEF_VERSIONS,
    payload,
});

export const setPreviewBriefVersion = (payload) => ({
    type: contentBriefsOutlineActions.SET_PREVIEW_BRIEF_VERSION,
    payload,
});

export const updateBriefVersionName = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_BRIEF_VERSION_NAME,
    payload,
});

export const deleteBriefVersion = (payload) => ({
    type: contentBriefsOutlineActions.DELETE_BRIEF_VERSION,
    payload,
});

export const setCurrentBriefLoading = (payload) => ({
    type: contentBriefsOutlineActions.SET_CURRENT_BRIEF_LOADING,
    payload,
});

export const updateOutlineVersions = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_OUTLINE_VERSIONS,
    payload,
});

export const addOutlineVersion = (payload) => ({
    type: contentBriefsOutlineActions.ADD_OUTLINE_VERSION,
    payload,
});

export const setPreviewOutlineVersion = (payload) => ({
    type: contentBriefsOutlineActions.SET_PREVIEW_OUTLINE_VERSION,
    payload,
});

export const updateOutlineVersionName = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_OUTLINE_VERSION_NAME,
    payload,
});

export const deleteOutlineVersion = (payload) => ({
    type: contentBriefsOutlineActions.DELETE_OUTLINE_VERSION,
    payload,
});

export const updateCurrentOutlineVersionProgress = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE_VERSION_PROGRESS,
    payload,
});

export const updateCurrentOutlineVersionWordCount = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE_VERSION_WORD_COUNT,
    payload,
});

export const updateCurrentOutlineLoading = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE_LOADING,
    payload,
});

export const updateCurrentOutline = (payload) => ({
    type: contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE,
    payload,
});

export const appendToCurrentOutline = (payload) => ({
    type: contentBriefsOutlineActions.APPEND_TO_CURRENT_OUTLINE,
    payload,
});

export const setCurrentBrief = (payload) => ({
    type: contentBriefsOutlineActions.SET_CURRENT_BRIEF,
    payload,
});

export const setCurrentBriefId = (payload) => ({
    type: contentBriefsOutlineActions.SET_CURRENT_BRIEF_ID,
    payload,
});

export const setOutlineVersionLoading = (payload) => ({
    type: contentBriefsOutlineActions.SET_OUTLINE_VERSION_LOADING,
    payload,
});
