export const contentMonitorCreateActions = {
    CLEAR_CONTENT_MONITOR_CREATE:
        'CONTENT_MONITOR_CREATE/CLEAR_CONTENT_MONITOR_CREATE',
};

export const clearContentMonitorCreate = () => {
    return {
        type: contentMonitorCreateActions.CLEAR_CONTENT_MONITOR_CREATE,
    };
};
