import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Form, Typography, Input, Radio, Space } from 'antd';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../../resources/icons/CloseIcon';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getFilteredUserRoleItems } from '../../../../../utils/helpers/getFilteredUserRoleItems';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { useOpen } from '../../../../../utils/hooks/useOpen';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { subscriptionLimits } from '../../../../../constants/subscriptionLimits';
import { invitesPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

import { increaseSubscriptionLimits } from '../../billing/store/billingSettings.actions';
import { addInvite } from '../../store/accountSettings.actions';

import { defaultUserRole } from '../constants/userRoleRadioItems';

import UserRoleRadioItem from './UserRoleRadioItem';
import InviteUserButton from './InviteUserButton';

const { Title } = Typography;

const classes = {
    inviteUserButton: 'invite-user-button',
    modalWrapper: 'invite-user-modal',
    modalWrapperDark: 'invite-user-modal-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormRadioWrapper: 'form-radio-wrapper',
    modalSubtitle: 'modal-subtitle',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
};

const InviteUserModal = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { isOpen, handleOpen } = useOpen();

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        setError(null);
        setIsLoading(false);
        form.resetFields();
    };

    const handleInviteUser = (values) => {
        setIsLoading(true);

        axiosAPI
            .post(invitesPathes.createInvite, values, { ...getAxiosHeaders() })
            .then((result) => {
                if (result?.status === statusCodes.create) {
                    dispatch(addInvite(result?.data));
                    dispatch(
                        increaseSubscriptionLimits({
                            limit: subscriptionLimits.userSeatsUsed,
                            value: 1,
                        })
                    );
                    form.resetFields();
                    handleOpen();
                }
            })
            .catch((error) => {
                setError(
                    error?.response?.data?.errors?.email?.[0] ||
                        error?.response?.data?.errors?.role_name?.[0] ||
                        null
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <InviteUserButton handleOpen={handleOpen} />

            <Modal
                centered
                title='Invite A User'
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleInviteUser}
                    initialValues={{ role_name: defaultUserRole }}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        <Title
                            level={5}
                            className={classes.modalSubtitle}
                        >
                            Enter an email address to invite a user to this
                            organization. We’ll send them further instructions.
                        </Title>
                        {error && (
                            <ErrorMessage
                                errorMessage={
                                    error ? error : 'Please try again'
                                }
                            />
                        )}
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid email',
                                },
                                {
                                    required: true,
                                    message: 'Please enter your email',
                                },
                            ]}
                        >
                            <Input placeholder='Enter email' />
                        </Form.Item>
                        <Title
                            level={5}
                            className={classes.modalSubtitle}
                        >
                            Role
                        </Title>
                        <Form.Item name='role_name'>
                            <Radio.Group>
                                <Space direction='vertical'>
                                    {getFilteredUserRoleItems(
                                        user?.roles?.[0]?.name
                                    )?.map((userRole) => {
                                        return (
                                            <UserRoleRadioItem
                                                {...userRole}
                                                key={userRole.value}
                                            />
                                        );
                                    })}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                        >
                            Cancel
                        </Button>
                        <Form.Item
                            shouldUpdate
                            className='submit'
                        >
                            {() => (
                                <Button
                                    type='primary'
                                    size='large'
                                    htmlType='submit'
                                    disabled={
                                        !form.isFieldTouched('email') ||
                                        form
                                            .getFieldsError()
                                            .some(
                                                (item) => item.errors.length > 0
                                            )
                                    }
                                    loading={isLoading}
                                >
                                    Save
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default InviteUserModal;
