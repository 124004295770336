import React from 'react';

const ContentOptimizerIcon = ({ color = '#9199AD', size = '20' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.9167 17.0835C3.60837 17.7752 4.72503 17.7752 5.4167 17.0835L16.25 6.2502C16.9417 5.55853 16.9417 4.44186 16.25 3.7502C15.5584 3.05853 14.4417 3.05853 13.75 3.7502L2.9167 14.5835C2.22503 15.2752 2.22503 16.3919 2.9167 17.0835Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
            />
            <path
                d='M15.0083 7.49121L12.5083 4.99121'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.08337 2.03366L8.33337 1.66699L7.96671 2.91699L8.33337 4.16699L7.08337 3.80033L5.83337 4.16699L6.20004 2.91699L5.83337 1.66699L7.08337 2.03366Z'
                stroke={color}
                strokeLinecap='round'
            />
            <path
                d='M3.75 7.03366L5 6.66699L4.63333 7.91699L5 9.16699L3.75 8.80033L2.5 9.16699L2.86667 7.91699L2.5 6.66699L3.75 7.03366Z'
                stroke={color}
                strokeLinecap='round'
            />
            <path
                d='M16.25 11.1997L16.1093 11.6795C16.2012 11.7064 16.2988 11.7064 16.3907 11.6795L16.25 11.1997ZM17.5 10.833L17.9798 10.9737L18.2374 10.0956L17.3593 10.3532L17.5 10.833ZM17.1333 12.083L16.6535 11.9423C16.6266 12.0342 16.6266 12.1319 16.6535 12.2237L17.1333 12.083ZM17.5 13.333L17.3593 13.8128L18.2374 14.0704L17.9798 13.1923L17.5 13.333ZM16.25 12.9663L16.3907 12.4866C16.2988 12.4596 16.2012 12.4596 16.1093 12.4866L16.25 12.9663ZM15 13.333L14.5202 13.1923L14.2626 14.0704L15.1407 13.8128L15 13.333ZM15.3667 12.083L15.8465 12.2237C15.8734 12.1319 15.8734 12.0342 15.8465 11.9423L15.3667 12.083ZM15 10.833L15.1407 10.3532L14.2626 10.0956L14.5202 10.9737L15 10.833ZM16.3907 11.6795L17.6407 11.3128L17.3593 10.3532L16.1093 10.7199L16.3907 11.6795ZM16.6535 12.2237L17.0202 13.4737L17.9798 13.1923L17.6131 11.9423L16.6535 12.2237ZM17.6407 12.8532L16.3907 12.4866L16.1093 13.4461L17.3593 13.8128L17.6407 12.8532ZM16.1093 12.4866L14.8593 12.8532L15.1407 13.8128L16.3907 13.4461L16.1093 12.4866ZM15.4798 13.4737L15.8465 12.2237L14.8869 11.9423L14.5202 13.1923L15.4798 13.4737ZM15.8465 11.9423L15.4798 10.6923L14.5202 10.9737L14.8869 12.2237L15.8465 11.9423ZM14.8593 11.3128L16.1093 11.6795L16.3907 10.7199L15.1407 10.3532L14.8593 11.3128ZM17.0202 10.6923L16.8369 11.3173L17.7965 11.5987L17.9798 10.9737L17.0202 10.6923ZM16.8369 11.3173L16.6535 11.9423L17.6131 12.2237L17.7965 11.5987L16.8369 11.3173Z'
                fill={color}
                id='stroke-star'
            />
        </svg>
    );
};

export default ContentOptimizerIcon;
