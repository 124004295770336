import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';
import { CardElement } from '@stripe/react-stripe-js';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import PaymentCardsIcon from '../../../resources/icons/PaymentCardsIcon';
import { colorPalette } from '../../../resources/styles/colorPalette';

import './PaymentMethod.scss';

const classes = {
    paymentMethodWrapper: 'payment-method-wrapper',
    paymentMethodWrapperDark: 'payment-method-wrapper-dark',
    cardInput: 'card-input',
    label: 'label',
};

const PaymentMethod = () => {
    const darkMode = useSelector(selectDarkMode);

    const cardOptions = {
        style: {
            base: {
                height: '38px',
                fontSize: '16px',
                fontWeight: 500,
                color: darkMode
                    ? colorPalette.textAccentColor.dark
                    : colorPalette.textColor.light,
                '::placeholder': {
                    color: darkMode
                        ? colorPalette.textColor.dark
                        : colorPalette.textColor.light,
                },
                iconColor: darkMode
                    ? colorPalette.textColor.dark
                    : colorPalette.textColor.light,
            },
            invalid: {
                color: colorPalette.colorError,
            },
        },
        hidePostalCode: true,
    };

    return (
        <div
            className={`${classes.paymentMethodWrapper}
                        ${darkMode ? classes.paymentMethodWrapperDark : ''}`}
        >
            <PaymentCardsIcon />
            <Typography className={classes.label}>
                Credit card details
            </Typography>
            <div className={classes.cardInput}>
                <CardElement options={cardOptions} />
            </div>
        </div>
    );
};

export default PaymentMethod;
