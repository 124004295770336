import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Form, Typography, Radio, Space } from 'antd';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../../resources/icons/CloseIcon';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getFilteredUserRoleItems } from '../../../../../utils/helpers/getFilteredUserRoleItems';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { userDataPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

import { updateUserRole } from '../../store/accountSettings.actions';

import UserRoleRadioItem from './UserRoleRadioItem';

const { Title } = Typography;

const classes = {
    modalWrapper: 'edit-user-modal',
    modalWrapperDark: 'edit-user-modal-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormRadioWrapper: 'form-radio-wrapper',
    modalSubtitle: 'modal-subtitle',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
};

const EditProfileModal = ({ editableUser, isOpen, handleOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        setError(null);
        setIsLoading(false);
        form.resetFields();
    };

    useEffect(() => {
        if (isOpen) {
            form.setFieldValue('role', editableUser?.role);
        }

        // eslint-disable-next-line
    }, [isOpen]);

    const handleEditUser = (values) => {
        const { role } = values;

        if (role !== editableUser.role) {
            axiosAPI
                .patch(
                    `${userDataPathes.changeUserRole}${editableUser.id}/change-role`,
                    { role_name: role },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(
                            updateUserRole({
                                id: editableUser.id,
                                role: {
                                    name: role,
                                },
                            })
                        );
                        form.resetFields();
                        handleOpen();
                    }
                })
                .catch(() => {
                    setError('Please try again');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            handleOpen();
        }
    };

    return (
        <Modal
            centered
            title='Edit User'
            open={isOpen}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            wrapClassName={`${classes.modalWrapper} 
                            ${darkMode ? classes.modalWrapperDark : ''}`}
            afterClose={handleCloseModal}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={handleEditUser}
                initialValues={{ role: editableUser?.role }}
            >
                <div className={classes.modalFormFieldsWrapper}>
                    <Title
                        level={5}
                        className={classes.modalSubtitle}
                    >
                        {editableUser.email}
                    </Title>
                    {error && <ErrorMessage errorMessage={error} />}
                    <Title
                        level={5}
                        className={classes.modalSubtitle}
                    >
                        Role
                    </Title>
                    <Form.Item name='role'>
                        <Radio.Group>
                            <Space direction='vertical'>
                                {getFilteredUserRoleItems(
                                    user?.roles?.[0]?.name
                                )?.map((userRole) => {
                                    return (
                                        <UserRoleRadioItem
                                            {...userRole}
                                            key={userRole.value}
                                        />
                                    );
                                })}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className={classes.modalFormButtonsWrapper}>
                    <Button
                        type='primary'
                        size='large'
                        ghost
                        onClick={handleOpen}
                    >
                        Cancel
                    </Button>

                    <Button
                        type='primary'
                        size='large'
                        htmlType='submit'
                        loading={isLoading}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default EditProfileModal;
