// Description: This file contains the methods for the projects checked items actions panel

export const projectsMethods = {
    // Destrucutre the array of arrays of projects into an array of unique projects
    getArrayOfAllProjects: (projects) => {
        const arrayOfAllProjects = projects.flat().reduce((acc, obj) => {
            if (!acc.some((o) => o.id === obj.id)) {
                acc.push(obj);
            }

            return acc;
        }, []);

        return arrayOfAllProjects;
    },

    // Get projects that are selected for all briefs
    getArrayOfProjectsSelectedForAllMonitors: (projects) => {
        const arrayOfProjectsSelectedForAllBriefs = projects[0].filter(
            (obj) => {
                return projects.every((subArray) =>
                    subArray.some(({ id }) => id === obj.id)
                );
            }
        );

        return arrayOfProjectsSelectedForAllBriefs;
    },

    // Get projects that are selected not for all briefs
    getArrayOfProjectsSelectedForSomeMonitors: (
        projects,
        projectsForAllBriefs
    ) => {
        const arrayOfProjectsSelectedForSomeBriefs = projects
            .flat()
            .reduce((acc, obj) => {
                if (
                    !acc.some((o) => o.id === obj.id) &&
                    !projectsForAllBriefs.some((o) => o.id === obj.id)
                ) {
                    acc.push(obj);
                }

                return acc;
            }, []);

        return arrayOfProjectsSelectedForSomeBriefs;
    },

    // Get projects that were checked and then unchecked to be removed
    getArrayOfProjectsToRemove: (projects, selectedProjects) => {
        const arrayOfRemovedProjects = projects.flat().reduce((acc, obj) => {
            if (
                !acc.some((o) => o.id === obj.id) &&
                !selectedProjects.some((o) => o.id === obj.id)
            ) {
                acc.push(obj);
            }

            return acc;
        }, []);

        return arrayOfRemovedProjects;
    },

    getNumberOfItemsWithProjects: (projects) => {
        const numberOfItemsWithProjects = projects.reduce((acc, subArray) => {
            if (subArray.length > 0) {
                acc++;
            }

            return acc;
        }, 0);

        return numberOfItemsWithProjects;
    },
};
