import { useSelector } from 'react-redux';

import { colorPalette } from '../../resources/styles/colorPalette';
import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';

export const useIconColor = () => {
    const darkMode = useSelector(selectDarkMode);

    return darkMode ? colorPalette.iconColor.dark : colorPalette.basicGray;
};
