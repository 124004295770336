import { alignments } from '../constants/alignments';

const handleJustifyChange = (editor, value) => {
    if (editor && value) {
        editor.chain().focus().setTextAlign(value).run();
        editor.commands.alignImage(value);

        return true;
    }

    return false;
};

export const configureCustomKeyboardShortcuts = (editor, event) => {
    const { code, ctrlKey, metaKey, shiftKey } = event;

    if (shiftKey && (ctrlKey || metaKey)) {
        if (shiftKey && (ctrlKey || metaKey)) {
            switch (code) {
                case 'KeyL':
                    handleJustifyChange(editor, alignments.left.key);
                    break;
                case 'KeyE':
                    handleJustifyChange(editor, alignments.center.key);
                    break;
                case 'KeyR':
                    handleJustifyChange(editor, alignments.right.key);
                    break;
                case 'KeyJ':
                    handleJustifyChange(editor, alignments.justify.key);
                    break;
                default:
                    break;
            }
        }
    }
};
