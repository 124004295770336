import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { contentOptimizerPathes } from '../../../../constants/queryPathes';
import { defaultExtractContentEditorContent } from '../toolbar/constants';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import {
    updateCurrentEditorContent,
    updateSaveEditorLoading,
    updateEditorBasicData,
    updateEditorDataAfterSave,
    updateCurrentEditorText,
} from '../store/tiptapEditor.actions';
import {
    selectCurrentEditorId,
    selectEditorPreviewVersion,
} from '../store/tiptapEditor.selectors';

const useUpdateEditorContent = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const organizationSlug = useOrganizationSlug();

    const currentEditorId = useSelector(selectCurrentEditorId);
    const editorPreviewVersion = useSelector(selectEditorPreviewVersion);

    const handleUpdateEditor = (updatedEditorData, updatedEditorText = '') => {
        if (contentOptimizerId && currentEditorId && !editorPreviewVersion) {
            dispatch(updateSaveEditorLoading(true));
            dispatch(updateCurrentEditorText(updatedEditorText));
            const dataToUpdate =
                updatedEditorData || defaultExtractContentEditorContent;

            axiosAPI
                .patch(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/${currentEditorId}`,
                    {
                        content: JSON.stringify(dataToUpdate),
                        content_text: updatedEditorText,
                    },
                    {
                        ...getAxiosHeaders(),
                        params: {
                            slug: organizationSlug,
                        },
                    }
                )
                .then((result) => {
                    dispatch(updateCurrentEditorContent(dataToUpdate));

                    if (result?.data) {
                        batch(() => {
                            dispatch(updateEditorDataAfterSave(result.data));
                            dispatch(updateEditorBasicData(result.data));
                        });
                    }
                })
                .finally(() => {
                    dispatch(updateSaveEditorLoading(false));
                });
        }
    };

    return [handleUpdateEditor];
};

export default useUpdateEditorContent;
