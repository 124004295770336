import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomHeader from '../../../../common/header/Header';

import { updateQuotasQueryParams } from '../store/adminQuotasSettings.actions';
import { selectQuotasQueryParams } from '../store/adminQuotasSettings.selectors';

const QuotaPageHeader = () => {
    const dispatch = useDispatch();

    const queryParams = useSelector(selectQuotasQueryParams);
    const searchQuery = queryParams.search;
    const [searchValue, setSearchValue] = useState('');

    const updateQuotasParams = useCallback(() => {
        const timeoutId = setTimeout(() => {
            dispatch(
                updateQuotasQueryParams({
                    search: searchValue.trim().replace(/\s+/g, ' '),
                    page: 1,
                })
            );
        }, 1500);

        return () => clearTimeout(timeoutId);
    }, [dispatch, searchValue]);

    useEffect(() => {
        if (!searchValue) {
            setSearchValue('');
        }
    }, [searchQuery]);

    useEffect(() => {
        return updateQuotasParams();
    }, [searchQuery, updateQuotasParams]);

    const handleSearch = useCallback((value) => {
        setSearchValue(value);
    }, []);

    return (
        <CustomHeader
            showSearch
            handleSearch={handleSearch}
            defaultSearchValue={searchQuery}
            searchValue={searchValue}
        />
    );
};

export default QuotaPageHeader;
