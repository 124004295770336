import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Menu } from 'antd';

import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';

import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';
import { getOptimizerRightBlockWrapper } from '../../../../pages/contentOptimizer/contentPage/content/optimizerTab/rightBlock/optimizerRightBlockHelpers';
import useAiSpellCheckerItems from '../../hooks/useAiSpellCheckerItems';
import { createRemoveHighlightTransaction, deleteNode } from '../../utils/editorTransactionHelpers';

import { nodeTypes } from '../../constants/nodeTypes';
import { AI_BUTTON_CLASS } from '../../toolbar/buttons/AISection';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setAISpellCheckerVisible } from '../../store/tiptapEditor.actions';
import { selectAIStateLoading } from '../../store/tiptapEditor.selectors';

import { EDITOR_CONTENT_WRAPPER_CLASS } from '../../TipTapEditor';
import AiCustomPrompt from './AiCustomPrompt';

const AI_PROMPT_WRAPPER_CLASS = 'ai-prompt-wrapper';
const AI_ACTIONS_WRAPPER_CLASS = 'ai-actions-wrapper';

const classes = {
    actionsWrapper: `${AI_ACTIONS_WRAPPER_CLASS} positioned-container`,
    promptWrapper: `${AI_PROMPT_WRAPPER_CLASS} positioned-container`,
};

const AiActionsPanel = () => {
    const dispatch = useDispatch();

    const spellCheckActionsRef = useRef(null);
    const currentRef = spellCheckActionsRef.current;

    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);
    const aiLoading = useSelector(selectAIStateLoading);

    const { aiSpellCheckerItems } = useAiSpellCheckerItems();

    const [spellCheckActionsVisible, setSpellCheckActionsVisible] = useState(false);

    useEffect(() => {
        if (currentRef) {
            // Listen for when the text area and the options menu are fully visible
            const observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    setSpellCheckActionsVisible(entry.isIntersecting);
                },
                { root: null, threshold: 1 }
            );

            observer.observe(currentRef);

            return () => {
                observer.unobserve(currentRef);
            };
        }
    }, [currentRef]);

    const hideSpellChecker = useCallback(
        (keepFocused) => {
            if (aiLoading) {
                return;
            }

            const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;

            dispatch(setAISpellCheckerVisible(false));
            deleteNode(editor, nodeTypes.aiSpellChecker);
            createRemoveHighlightTransaction(editor, from, to);

            editor.commands.resetAiSpellCheckerStorage();

            if (keepFocused) {
                editor.commands.setTextSelection({ from, to });
                editor.commands.focus();
            }
        },
        [aiLoading, dispatch, editor]
    );

    const handleClickOutside = useCallback(
        (event) => {
            if (
                !event.target.closest(`.${AI_BUTTON_CLASS}`) &&
                !event.target.closest(`.${AI_PROMPT_WRAPPER_CLASS}`) &&
                !event.target.closest(`.${AI_ACTIONS_WRAPPER_CLASS}`) &&
                !getOptimizerRightBlockWrapper().contains(event.target)
            ) {
                const keepFocusedAfterHide = !event.target.closest(
                    `.${EDITOR_CONTENT_WRAPPER_CLASS}`
                );

                hideSpellChecker(keepFocusedAfterHide);
            }
        },
        [hideSpellChecker]
    );

    const handleKeyDown = useCallback(
        (event) => {
            if (
                !event.target.closest(`.${AI_PROMPT_WRAPPER_CLASS}`) &&
                !getOptimizerRightBlockWrapper().contains(event.target)
            ) {
                hideSpellChecker();
            }
        },
        [hideSpellChecker]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [editor, handleClickOutside, handleKeyDown]);

    if (aiLoading) {
        return null;
    }

    return (
        <div ref={spellCheckActionsRef}>
            <div className={`${classes.promptWrapper} ${getThemeColorClass(darkMode)}`}>
                <AiCustomPrompt spellCheckActionsVisible={spellCheckActionsVisible} />
            </div>
            {aiSpellCheckerItems.length ? (
                <div className={`${classes.actionsWrapper} ${getThemeColorClass(darkMode)}`}>
                    <Menu
                        items={aiSpellCheckerItems}
                        getPopupContainer={(node) => node.parentNode}
                        expandIcon={<SiderChevronUpIcon />}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default AiActionsPanel;
