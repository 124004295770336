import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import ReactCountryFlag from 'react-country-flag';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { getArrayOptionsFromObject } from '../../../utils/helpers/getArrayOptionsFromObject';

import { countries, featuredCountries } from '../../../constants/countries';
import { featuredLanguages, languages } from '../../../constants/languages';

import './FlagCountrySection.scss';

const classes = {
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    flagWrapper: 'flag-wrapper',
};

const FlagCountrySection = ({ countryCode, languageCode }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className='d-flex'>
            <Tooltip
                title={`${
                    getArrayOptionsFromObject({
                        values: countries,
                        featured: featuredCountries,
                    })?.find(
                        (language) =>
                            language?.value?.toUpperCase() ===
                            countryCode?.toUpperCase()
                    )?.label || 'N/A'
                } · ${
                    getArrayOptionsFromObject({
                        values: languages,
                        featured: featuredLanguages,
                    })?.find(
                        (language) =>
                            language?.value?.toUpperCase() ===
                            languageCode?.toUpperCase()
                    )?.label || 'N/A'
                }`}
                overlayClassName={`${classes.tooltipOverlay}
                                   ${
                                       darkMode
                                           ? classes.tooltipOverlayDark
                                           : ''
                                   }`}
                className={classes.flagWrapper}
            >
                <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    style={{
                        width: '16px',
                        height: '16px',
                    }}
                />
                <Typography>{languageCode?.toUpperCase()}</Typography>
            </Tooltip>
        </div>
    );
};

export default FlagCountrySection;
