import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Typography } from 'antd';

import ChatGPTIcon from '../../../resources/icons/editor/ChatGPTIcon';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../utils/helpers/openNotification';
import { useSubscriptionLimits } from '../../../utils/hooks/useSubscriptionLimits';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../constants/notificationType';
import { contentBriefPathes } from '../../../constants/queryPathes';
import { subscriptionLimits as subscriptionLimitsNames } from '../../../constants/subscriptionLimits';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { increaseSubscriptionLimits } from '../../pages/account/billing/store/billingSettings.actions';
import {
    setBriefVersions,
    updateOutlineVersions,
} from '../../pages/contentOptimizer/contentPage/content/briefTab/content/tabsContent/store/contentBriefsOutline.actions';
import {
    selectBriefVersions,
    selectCurrentBrief,
    selectCurrentOutlineVersion,
    selectOutlineVersions,
} from '../../pages/contentOptimizer/contentPage/content/briefTab/content/tabsContent/store/contentBriefsOutline.selectors';

const classes = {
    AIButtonWrapper: 'ai-button',
    AIButtonLoading: 'ai-button-loading',
};

const AIButton = () => {
    const { contentOptimizerId } = useParams();

    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const outlineVersions = useSelector(selectOutlineVersions);
    const briefVersions = useSelector(selectBriefVersions);

    const activeOutlineVersion = useSelector(selectCurrentOutlineVersion);
    const activeBriefVersion = useSelector(selectCurrentBrief);

    const { remainingOpenAIBriefs } = useSubscriptionLimits();

    const isOutlineTab = localStorage.getItem('briefTabKey') === 'outline';
    const aiLoading = isOutlineTab
        ? activeOutlineVersion?.ai_job_id !== null
        : activeBriefVersion?.ai_job_id !== null;
    const notEnoughAICredits = remainingOpenAIBriefs <= 0;

    const handleAIButtonClick = () => {
        axiosAPI
            .post(
                `${contentBriefPathes.generateOutline}/${contentOptimizerId}/generate`,
                {
                    options: {
                        brief: !isOutlineTab,
                        outline: isOutlineTab,
                    },
                },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                const updatedOutlineVersions =
                    outlineVersions?.length > 0
                        ? [
                              ...outlineVersions?.map((version) => ({
                                  ...version,
                                  active: false,
                              })),
                              result.data?.outline,
                          ]
                        : [result.data?.outline];

                const updatedBriefVersions =
                    briefVersions?.length > 0
                        ? [
                              ...briefVersions?.map((version) => ({
                                  ...version,
                                  active: false,
                              })),
                              result.data?.version,
                          ]
                        : [result.data?.version];

                batch(() => {
                    dispatch(updateOutlineVersions(updatedOutlineVersions));
                    dispatch(setBriefVersions(updatedBriefVersions));
                    dispatch(
                        increaseSubscriptionLimits({
                            limit: subscriptionLimitsNames.openaiBriefsUsed,
                            value: result.data?.openai_credits_charged || 1,
                        })
                    );
                });
            })
            .catch((_err) => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });
    };

    return (
        <div
            className={`${classes.AIButtonWrapper} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <Button
                className={`${aiLoading ? classes.AIButtonLoading : ''}`}
                icon={<ChatGPTIcon color={darkMode ? '#9D9D9F' : '#1F1F1F'} />}
                disabled={aiLoading || notEnoughAICredits}
                onClick={handleAIButtonClick}
            >
                <Typography className={` ${darkMode ? 'dark-mode' : ''}`}>
                    AI {isOutlineTab ? 'Outline' : 'Brief'}
                </Typography>
            </Button>
        </div>
    );
};

export default AIButton;
