import React from 'react';

import { Input } from 'antd';

import { useIconColor } from '../../../utils/hooks/useIconColor';
import SearchIcon from '../../../resources/icons/SearchIcon';

const classes = {
    searchInput: 'search-input',
    inputError: 'input-error',
};

const KeywordSearchInput = ({ placeholder, value, onChange, isInputError }) => {
    const iconColor = useIconColor();

    return (
        <Input
            placeholder={placeholder}
            prefix={<SearchIcon color={iconColor} />}
            className={`${classes.searchInput} ${
                isInputError ? classes.inputError : ''
            }`}
            value={value}
            onChange={onChange}
        />
    );
};

export default KeywordSearchInput;
