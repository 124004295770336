import { contentMonitorCreateActions } from './contentMonitorCreate.actions';

const contentMonitorCreateInitialState = {};

export const contentMonitorCreateReducer = (
    state = contentMonitorCreateInitialState,
    { type, payload }
) => {
    switch (type) {
        case contentMonitorCreateActions.CLEAR_CONTENT_MONITOR_CREATE: {
            return {
                ...contentMonitorCreateInitialState,
            };
        }

        default: {
            return state;
        }
    }
};
