import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import TickIcon from '../../../../resources/icons/versionHistory/TickIcon';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    versionStatus: 'version-status',
    statusText: 'status-text space-no-wrap',
};

const VersionStatus = ({ loading }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.versionStatus} ${darkMode ? 'dark-mode' : ''}`}>
            {!loading && <TickIcon />}
            <Typography
                className={`${classes.statusText} ${darkMode ? 'dark-mode' : ''}`}
            >
                {loading ? 'Saving...' : 'Saved'}
            </Typography>
        </div>
    );
};

export default VersionStatus;
