import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import UsageKeywordsDiscoveriesCreditsLabel from './UsageKeywordsDiscoveriesCreditsLabel';
import UsageReportsCreditsLabel from './UsageReportsCreditsLabel';
import ChartLabel from './ChartLabel';
import UsageChart from './UsageChart';

const classes = {
    chartWrapper: 'chart-wrapper',
    xAxisLabel: 'x-axis-label',
};

const ReportsChartsSection = ({ reports = [], keywordDiscoveries = [] }) => {
    const chartWrapper = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const [chartWidth, setChartWidth] = useState(0);

    const changeChartWidth = () => {
        if (chartWrapper?.current) {
            setChartWidth(chartWrapper?.current?.offsetWidth - 32);
        }
    };

    useEffect(() => {
        changeChartWidth();

        window.addEventListener('resize', changeChartWidth);

        return () => {
            window.removeEventListener('resize', changeChartWidth);
        };

        // eslint-disable-next-line
    }, [chartWrapper]);

    return (
        <Row justify='space-between' gutter={24}>
            <Col span={12} ref={chartWrapper}>
                <div
                    className={`${classes.chartWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <ChartLabel
                        mainLabel={'Content Optimizers'}
                        secondLabel={<UsageReportsCreditsLabel />}
                    />
                    <UsageChart
                        data={reports}
                        valueDescLabel={'Optimizers & Briefs'}
                        strokeColor={'#21CC90'}
                        width={chartWidth}
                    />
                    <div className={classes.xAxisLabel}>
                        <Typography>{reports[0]?.name}</Typography>
                        <Typography>
                            {reports[reports?.length - 1]?.name}
                        </Typography>
                    </div>
                </div>
            </Col>
            <Col span={12}>
                <div
                    className={`${classes.chartWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <ChartLabel
                        mainLabel={'Keyword Searches'}
                        secondLabel={<UsageKeywordsDiscoveriesCreditsLabel />}
                    />

                    <UsageChart
                        data={keywordDiscoveries}
                        valueDescLabel={'Keyword Searches'}
                        strokeColor={'#DEB87E'}
                        width={chartWidth}
                    />
                    <div className={classes.xAxisLabel}>
                        <Typography>{keywordDiscoveries[0]?.name}</Typography>
                        <Typography>
                            {
                                keywordDiscoveries[
                                    keywordDiscoveries?.length - 1
                                ]?.name
                            }
                        </Typography>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ReportsChartsSection;
