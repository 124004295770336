import React from 'react';

const CodeIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M10.5 3L6.5 13' stroke={color} strokeWidth='1.2' />
            <path
                d='M12 10L14 8L12 6'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M5 6L3 8L5 10'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default CodeIcon;
