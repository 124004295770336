import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { projectPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { useUsers } from '../../../../../utils/hooks/useUsers';

import { setProjects } from '../store/contentOptimizerMain.actions';

import ProjectFilterDropdown from './ProjectFilterDropdown';
import UsersFilterDropdown from './UsersFilterDropdown';
import DateRangeFilterDropdown from './DateRangeFilterDropdown';

import './OptimizersResultsFilters.scss';

const classes = {
    optimizerResultsFilterContainer:
        'optimizer-results-filter-container d-flex',
};

const OptimizersResultsFilters = () => {
    const dispatch = useDispatch();

    const { users } = useUsers();

    useEffect(() => {
        axiosAPI
            .get(
                `${projectPathes.getListOfProjects}`,
                { params: { with_briefs: true } },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(setProjects(result?.data));
                }
            });

        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.optimizerResultsFilterContainer}>
            <ProjectFilterDropdown />
            <UsersFilterDropdown users={users} />
            <DateRangeFilterDropdown />
        </div>
    );
};

export default OptimizersResultsFilters;
