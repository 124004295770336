import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'antd';
import moment from 'moment';

import ExportIcon from '../../../../../resources/icons/ExportIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getObjectDataFromJSON } from '../../../../../utils/helpers/getObjectFromJSON';
import { calculateOptimalRankabilityScore } from '../../../../../utils/helpers/getOptimizerBasicData';

import { selectContentMonitorData } from '../store/contentMonitorMain.selectors';

const classes = {
    exportButton: 'create-new-btn',
};

const ExportMonitorButton = () => {
    const darkMode = useSelector(selectDarkMode);

    const contentMonitorData = useSelector(selectContentMonitorData);

    const [mappedMonitorExportData, setMappedMonitorExportData] = useState([]);

    useEffect(() => {
        if (contentMonitorData?.length > 0) {
            const mappedCMData = contentMonitorData.map((monitor) => {
                if (!monitor) {
                    return null;
                }

                const optimalRankabilityScore = getObjectDataFromJSON(
                    monitor.optimal_rankability_score
                );
                const scoreToDisplay = calculateOptimalRankabilityScore(
                    optimalRankabilityScore?.max || 0
                );

                return {
                    monitoredLink: monitor.url,
                    monitoredTitle: monitor.title,
                    monitoredKeyword: monitor.monitored_keyword,
                    projects: monitor.cm_projects
                        ?.map((project) => project?.name)
                        ?.join(', '),
                    optimalRankabilityScore: scoreToDisplay,
                    rankabilityScore: monitor.rankability_score,
                    change: monitor.change,
                    added: moment(monitor.created_at).format('MMM DD, YYYY'),
                };
            });

            setMappedMonitorExportData(mappedCMData);
        } else {
            setMappedMonitorExportData([]);
        }
    }, [contentMonitorData]);

    const handleExport = () => {
        let convertedMonitorsData =
            'URL,Page Title,Keyword,Projects,Optimal RS,Page RS,Change,Added\n';

        mappedMonitorExportData?.forEach((item) => {
            const convertedMonitorItem = JSON.stringify(Object.values(item))
                ?.substring(1)
                ?.slice(0, -1)
                ?.replaceAll(',null', ',');

            convertedMonitorsData =
                convertedMonitorsData + convertedMonitorItem + '\n';
        });

        const BOM = '\uFEFF';
        const url = window.URL.createObjectURL(
            new Blob([BOM + convertedMonitorsData], {
                type: 'text/csv;charset=utf-8',
            })
        );
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.setAttribute('download', `Monitors.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <Button
            className={`${classes.exportButton}
                ${darkMode ? 'dark-mode' : ''}`}
            type='primary'
            size='large'
            ghost
            onClick={handleExport}
        >
            <ExportIcon color={colorPalette.colorPrimary} />
            Export
        </Button>
    );
};

export default ExportMonitorButton;
