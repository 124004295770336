import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectViewOnlyBriefData } from '../../../store/viewOnlySettings.selectors';

import './CardsContentStyles.scss';

const classes = {
    propertyValue: 'property-value',
    propertyName: 'property-name text-decoration-dotted',
    linksValue: 'links-value ',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const SimularCardContent = () => {
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyBriefData = useSelector(selectViewOnlyBriefData);

    return (
        <>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay}
                                       ${
                                           darkMode &&
                                           classes.tooltipOverlayDark
                                       }`}
                title={'Content Examples'}
                placement='top'
            >
                <span
                    className={`${classes.propertyName} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    Content Examples
                </span>
            </Tooltip>

            <div
                className={`${classes.propertyValue}  ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                {viewOnlyBriefData?.extras?.example_articles?.length > 0 &&
                viewOnlyBriefData?.extras?.example_articles?.some((x) =>
                    x?.trim()
                )
                    ? viewOnlyBriefData?.extras?.example_articles?.map(
                          (link, index) => {
                              return (
                                  link.trim() && (
                                      <div key={link + index}>
                                          <Link
                                              href={
                                                  link.includes('https')
                                                      ? link
                                                      : `https://${link}`
                                              }
                                              rel='noreferrer'
                                              target='_blank'
                                              className={`${classes.linksValue}`}
                                          >
                                              {link || 'N/A'}
                                          </Link>
                                      </div>
                                  )
                              );
                          }
                      )
                    : 'N/A'}
            </div>
        </>
    );
};

export default SimularCardContent;
