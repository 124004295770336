import React from 'react';

const ItalicIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.91333 2H13.08'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3.91333 14H10.08'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M10 2L7 14'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ItalicIcon;
