import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, theme } from 'antd';

import './Common.scss';

const classes = {
    pageLoadingSpin: 'page-loading-spin ',
};

const antIcon = <LoadingOutlined spin />;

const PageLoadingSpin = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <div
            className={classes.pageLoadingSpin}
            style={{
                background: colorBgContainer,
            }}
        >
            <Spin indicator={antIcon} />
        </div>
    );
};

export default PageLoadingSpin;
