import { useEffect } from 'react';

import { keyActionTypes } from './constants';

const KeyPressListener = ({ onKeyPress, keyAction }) => {
    const handleKeyPressByAction = (event) => {
        event.preventDefault();
        onKeyPress();
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey || event.metaKey) {
                if (
                    keyAction === keyActionTypes.insertImage &&
                    event.key === 'p'
                ) {
                    handleKeyPressByAction(event);
                } else if (
                    keyAction === keyActionTypes.insertLink &&
                    event.key === 'k'
                ) {
                    handleKeyPressByAction(event);
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };

        // eslint-disable-next-line
    }, [onKeyPress, keyAction]);

    return null;
};

export default KeyPressListener;
