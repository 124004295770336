import DOMPurify from 'dompurify';
import { decode } from 'html-entities';
import HtmlDiff from 'htmldiff-js';
import { DOMSerializer } from 'prosemirror-model';

import {
    SPELLING_DIFF_CLASS,
    spellingGrammarCongratsMessages,
} from '../constants/spellingGrammarConstants';

import {
    removeEmptyTags,
    removeExtraSpacesBetweenTags,
} from '../../../../utils/helpers/htmlHelpers';

/**
 * Picks a random congratulations message from the array.
 * @returns {string} - A random congratulatory message.
 */
const getRandomCongratsMessage = () => {
    const randomIndex = Math.floor(Math.random() * spellingGrammarCongratsMessages.length);
    const randomMessage = spellingGrammarCongratsMessages[randomIndex];

    return `<p><strong>${randomMessage.title}</strong> ${randomMessage.message}</p>`;
};

/**
 * Cleans the input HTML string by removing unwanted tags and decoding HTML entities.
 * @param {string} html - The HTML string to be cleaned.
 * @returns {string} - The cleaned HTML string.
 */
const cleanHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Remove <mark> tags as they are in selected text and not part of the correction.
    const regex = /<mark[^>]*>|<\/mark>/g;
    const cleanedHtml = doc.body.innerHTML.replace(regex, '');

    // Decode HTML entities like &nbsp;
    return decode(cleanedHtml);
};

/**
 * Groups the differences between two HTML strings by wrapping adjacent <del> and <ins> tags in a <span>
 * and adding a class to the <del> and <ins> tags that are not part of the same group.
 * @param {string} html - The HTML string to group the differences in.
 * @returns {string} - The HTML string with grouped differences.
 */
const groupDifferences = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const body = doc.body;

    let hasDifferences = false;

    const processDiffs = (parent) => {
        const nodes = Array.from(parent.childNodes);
        let i = 0;

        while (i < nodes.length) {
            const current = nodes[i];
            const next = nodes[i + 1];

            if (current.nodeName === 'DEL' && next && next.nodeName === 'INS') {
                // Wrap adjacent <del> and <ins> tags in a <span>
                const span = doc.createElement('span');
                span.className = SPELLING_DIFF_CLASS;
                parent.insertBefore(span, current);
                span.appendChild(current);
                span.appendChild(next);

                hasDifferences = true;
                i += 2; // Skip the next node as it's already wrapped
            } else {
                // Add a class to <del> and <ins> tags that are not part of the same group and include the text content
                if (
                    current.textContent.trim() !== '' &&
                    (current.nodeName === 'DEL' || current.nodeName === 'INS')
                ) {
                    current.classList.add(SPELLING_DIFF_CLASS);
                    hasDifferences = true;
                }

                i++;
            }
        }
    };

    // Apply the processDiffs function to the body and its descendants
    const processAllDiffs = (parent) => {
        processDiffs(parent);
        parent.querySelectorAll('*').forEach((child) => processDiffs(child));
    };

    processAllDiffs(body);

    return { html: body.innerHTML, hasDifferences };
};

/**
 * Highlights differences between two HTML strings.
 * @param {string} originalHtml - The original HTML string.
 * @param {string} correctedHtml - The corrected HTML string.
 * @returns {string} - The HTML string with highlighted differences.
 */
export const highlightHtmlDifferences = (editor, originalHtml, correctedHtml) => {
    const cleanedOriginal = cleanHtml(originalHtml);
    const cleanedCorrected = cleanHtml(correctedHtml);

    const diffHtml = HtmlDiff.execute(cleanedOriginal, cleanedCorrected);
    const { html: groupedDiffHtml, hasDifferences } = groupDifferences(diffHtml);

    editor.commands.setSpellingErrorsDetected(hasDifferences);

    return hasDifferences ? groupedDiffHtml : getRandomCongratsMessage();
};

/**
 * Gets the HTML content between two positions in the editor.
 * @param {Editor} editor - The editor instance.
 * @param {number} start - The start position.
 * @param {number} end - The end position.
 * @returns {string} - The HTML content between the positions.
 */
export const getHTMLBetweenRange = (editor, start, end) => {
    const { state } = editor;
    const { doc } = state;

    const fromPos = doc.resolve(start);
    const toPos = doc.resolve(end);

    const slice = doc.slice(fromPos.pos, toPos.pos);
    const serializer = DOMSerializer.fromSchema(editor.schema);

    const div = document.createElement('div');
    div.appendChild(serializer.serializeFragment(slice.content));

    return div.innerHTML;
};

/**
 * Formats generated by the AI response HTML string to avoid corner cases.
 * @param {string} html - The HTML string to be cleaned.
 * @returns {string} - The cleaned HTML string.
 */
export const formatAIResponseHtml = (html) => {
    // Corrects the HTML structure to avoid corner cases and decoding HTML entities
    const decodedSanitizedHtml = DOMPurify.sanitize(decode(html));

    // Cleans the HTML by removing empty tags that may have been generated after sanitization
    const cleanedHtml = removeExtraSpacesBetweenTags(removeEmptyTags(decodedSanitizedHtml));

    return cleanedHtml;
};

/**
 * Extracts the body content from an HTML string.
 * @param {string} html - The HTML string.
 * @returns {string} - The body content.
 */
export const extractBodyContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    return doc.body.innerHTML;
};

/**
 * Resolves the position in the document, traversing up the node tree
 * to find the top-level block element.
 * @param {Editor} editor - The editor instance.
 * @param {number} pos - The current cursor position.
 * @param {boolean} isEnd - Indicates whether to compute the end position or start position.
 * @returns {number} - The resolved position adjusted for blockquotes.
 */
const resolvePosition = (editor, pos, isEnd) => {
    const { state } = editor;
    const { doc } = state;

    let $pos = doc.resolve(pos);

    // Check if this position is already at the top level and return it as is
    if ($pos.depth === 0) {
        return pos;
    }

    // Traverse up the node tree to find the top-level block element
    while ($pos.depth > 1) {
        $pos = doc.resolve($pos.before($pos.depth));
    }

    // Determine the adjusted position
    let resolvedPos = isEnd ? $pos.end($pos.depth) : $pos.start($pos.depth);

    // Check if the node is a blockquote and adjust the position
    if ($pos.node($pos.depth).type.name === 'blockquote') {
        resolvedPos += isEnd ? 1 : -1;
    }

    return resolvedPos;
};

/**
 * Gets the correct end position of the cursor in the editor.
 * @param {Editor} editor - The editor instance.
 * @param {number} pos - The current cursor position.
 * @returns {number} - The correct end position.
 */
export const getCorrectEndPosition = (editor, pos) => {
    return resolvePosition(editor, pos, true);
};

/**
 * Gets the correct start position of the cursor in the editor.
 * @param {Editor} editor - The editor instance.
 * @param {number} pos - The current cursor position.
 * @returns {number} - The correct start position.
 */
export const getCorrectStartPosition = (editor, pos) => {
    return resolvePosition(editor, pos, false);
};
