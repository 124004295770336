import React, { useState } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';

import { getRandomId } from '../../../../../../../../../../../utils/helpers/idGenerator';
import BasicOutlineTag from '../../../../../../../../../../common/outlineTags/BasicOutlineTag';

const defaultTagsLength = 5;
const classes = {
    expandButton: 'expand-button',
};

const RankItemContent = ({ addOutlineRow, htags, broken }) => {
    // every block stores it's own expanded state, it's not global, so it's not in redux
    const [expanded, setExpanded] = useState(
        htags?.length <= defaultTagsLength
    );
    const shownHtags = expanded ? htags : htags?.slice(0, defaultTagsLength);

    const handleAddButton = (item) => {
        addOutlineRow({
            title: item?.label,
            tag: item?.tag,
            id: getRandomId(),
        });
    };

    return (
        <div>
            {broken ? (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='Header tags not available'
                />
            ) : shownHtags?.length > 0 ? (
                shownHtags?.map((item, index) => {
                    return (
                        item?.text && (
                            <BasicOutlineTag
                                key={`${item?.tag}${item?.text}${index}`}
                                tag={item?.tag}
                                label={item?.text}
                                addBottonOption
                                handleAddButton={handleAddButton}
                            />
                        )
                    );
                })
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='Header tags not available'
                />
            )}
            {htags?.length > defaultTagsLength && (
                <Button
                    onClick={() => setExpanded(!expanded)}
                    type='text'
                    className={classes.expandButton}
                >
                    {expanded ? (
                        <>
                            <ArrowUpOutlined /> Collapse
                        </>
                    ) : (
                        <>
                            <ArrowDownOutlined /> Expand
                        </>
                    )}
                </Button>
            )}
        </div>
    );
};

export default RankItemContent;
