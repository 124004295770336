export const elementsStyles = {
    Header: {
        height: 54,
    },
    Sider: {
        width: 200,
        collapsedWidth: 70,
    },
    AccountButtonsPanel: {
        height: 64,
    },
};
