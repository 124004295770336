import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Tooltip } from 'antd';

import GalleryIcon from '../../../../../resources/icons/editor/GalleryIcon';
import ParagraphSpacingIcon from '../../../../../resources/icons/editor/ParagraphSpacingIcon';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import KeyPressListener from '../../../keyPressListener/KeyPressListener';
import { keyActionTypes } from '../../../keyPressListener/constants';

import { getIconColorByDarkMode } from '../constants';

import CustomButtonsTooltip from './CustomButtonsTooltip';
import SelectImageInput from './content/SelectImageInput';

const classes = {
    galleryButtonsSection: 'gallery-buttons-section',

    tooltipOverlay: 'toolbar-tooltip-overlay tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const GalleryButtonsSection = () => {
    const inputFile = useRef();

    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    const handleInsertImage = () => {
        inputFile?.current?.click();
    };

    return (
        <div
            className={`${classes.galleryButtonsSection} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <KeyPressListener
                onKeyPress={handleInsertImage}
                keyAction={keyActionTypes.insertImage}
            />
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Insert image' />}
            >
                <>
                    <SelectImageInput inputFile={inputFile} />
                    <Button
                        icon={
                            <GalleryIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() => {
                            inputFile?.current?.click();
                        }}
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Insert Horizontal Line' />}
            >
                <>
                    <Button
                        icon={
                            <ParagraphSpacingIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() =>
                            editor.chain().focus().setHorizontalRule().run()
                        }
                    />
                </>
            </Tooltip>
        </div>
    );
};

export default GalleryButtonsSection;
