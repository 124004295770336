import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Segmented } from 'antd';

import { billingPlanTypeOptions } from '../../../../../../constants/billingPlanConsts';

import { selectPlanType } from '../../store/billingSettings.selectors';
import { updatePlanType } from '../../store/billingSettings.actions';

const classes = {
    billingSwitchWrapper: 'billing-switch-wrapper',
};

const PlanTypesSwitcher = ({ isLoading }) => {
    const dispatch = useDispatch();

    const planType = useSelector(selectPlanType);

    const handleChangePlanType = (value) => {
        dispatch(updatePlanType(value));
    };

    return (
        <Segmented
            options={billingPlanTypeOptions}
            defaultValue={planType}
            onChange={handleChangePlanType}
            className={classes.billingSwitchWrapper}
            disabled={isLoading}
        />
    );
};

export default PlanTypesSwitcher;
