import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Typography } from 'antd';
import moment from 'moment';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { renderPaginationArrows } from '../../../../../utils/helpers/renderPaginationArrows';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';

import { sortOrders } from '../../../../../constants/sortOrders';
import { subscriptionLimits } from '../../../../../constants/subscriptionLimits';

import Loader from '../../../../common/loader/Loader';
import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';

import { quotasSortFields, organizationFieldsPatterns } from '../../constants';

import { updateQuotasQueryParams } from '../store/adminQuotasSettings.actions';
import {
    selectQuotasData,
    selectQuotasIsDataLoading,
    selectQuotasQueryParams,
    selectQuotasTotalPages,
} from '../store/adminQuotasSettings.selectors';

import AdminQuotaUsersExpandedRow from './AdminQuotaUsersExpandedRow';
import EditQuotaModalWrapper from './EditQuotaModalWrapper';

const classes = {
    actionsWrapper: 'actions-wrapper d-flex justify-content-between',
    linkWrapper: 'link-wrapper',
    tableHeaderTitle: 'd-flex align-items-center users-table-header-title cursor-pointer',
    expandIcon: 'expand-icon d-flex flex-center cursor-pointer',
};

const AdminQuotasTable = () => {
    const dispatch = useDispatch();

    const quotasData = useSelector(selectQuotasData);
    const isLoading = useSelector(selectQuotasIsDataLoading);
    const quotasQueryParams = useSelector(selectQuotasQueryParams);
    const totalPages = useSelector(selectQuotasTotalPages);

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const iconColor = useIconColor();

    const handleSort = (sortField) => {
        dispatch(
            updateQuotasQueryParams({
                sort_field: sortField,
                sort_order:
                    quotasQueryParams?.sort_field === sortField &&
                    quotasQueryParams?.sort_order === sortOrders.desc
                        ? sortOrders.asc
                        : sortOrders.desc,
                page: 1,
            })
        );
    };

    const handleChange = (pagination) => {
        window?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        dispatch(
            updateQuotasQueryParams(
                pagination?.current
                    ? { page: pagination?.current, per_page: pagination?.pageSize }
                    : null
            )
        );
    };

    const renderActions = (record) => {
        return (
            <div className={classes.actionsWrapper}>
                <div className={classes.linkWrapper}>
                    <EditQuotaModalWrapper editableQuota={record} />
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(quotasSortFields.name)}
                >
                    <Typography
                        className={
                            quotasQueryParams.sort_field === quotasSortFields.name && 'active'
                        }
                    >
                        ORGANIZATION
                    </Typography>
                    <TableHeaderSortIcons
                        active={quotasQueryParams.sort_field === quotasSortFields.name}
                        order={quotasQueryParams?.sort_order}
                    />
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(quotasSortFields.created_at)}
                >
                    <Typography
                        className={
                            quotasQueryParams.sort_field === quotasSortFields.created_at && 'active'
                        }
                    >
                        DATE CREATED
                    </Typography>
                    <TableHeaderSortIcons
                        active={quotasQueryParams.sort_field === quotasSortFields.created_at}
                        order={quotasQueryParams?.sort_order}
                    />
                </div>
            ),
            dataIndex: 'created_at',
            key: 'created_at',
            width: '12%',
            render: (created_at) => {
                return moment.utc(created_at).local().format('LL');
            },
        },
        {
            title: 'USERS',
            dataIndex: 'users_length',
            key: 'users_length',
        },
        {
            title: 'OPTIMIZERS',
            dataIndex: subscriptionLimits.optimizers,
            key: subscriptionLimits.optimizers,
        },
        {
            title: 'KEYWORDS',
            dataIndex: subscriptionLimits.keywordResearch,
            key: subscriptionLimits.keywordResearch,
        },
        {
            title: 'MONITORS',
            dataIndex: subscriptionLimits.contentMonitors,
            key: subscriptionLimits.contentMonitors,
        },
        {
            title: 'AI CREDITS',
            dataIndex: subscriptionLimits.openaiBriefs,
            key: subscriptionLimits.openaiBriefs,
            width: '7%',
        },
        {
            title: 'STRIPE CUSTOMER ID',
            dataIndex: organizationFieldsPatterns.stripe_customer_id.key,
            key: organizationFieldsPatterns.stripe_customer_id.key,
        },
        {
            title: 'STRIPE SUBSCRIPTION ID',
            dataIndex: organizationFieldsPatterns.stripe_subscription_id.key,
            key: organizationFieldsPatterns.stripe_subscription_id.key,
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            fixed: 'right',
            render: (_, record) => {
                return renderActions(record);
            },
        },
    ];

    return (
        <Table
            columns={columns}
            expandable={{
                expandedRowRender: (record) => {
                    return <AdminQuotaUsersExpandedRow record={record} />;
                },
                expandRowByClick: true,
                defaultExpandedRowKeys: expandedRowKeys,
                expandedRowKeys: expandedRowKeys,
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <div
                            className={classes.expandIcon}
                            onClick={(e) => {
                                onExpand(record, e);
                                setExpandedRowKeys([]);
                            }}
                        >
                            <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                        </div>
                    ) : (
                        <div
                            className={classes.expandIcon}
                            onClick={(e) => {
                                onExpand(record, e);
                                setExpandedRowKeys([record?.key]);
                            }}
                        >
                            <SiderChevronDownIcon color={iconColor} />
                        </div>
                    ),
            }}
            dataSource={quotasData?.map((quota) => ({
                ...quota,
                key: quota?.id,
                name: quota?.organization?.name,
                users_length: quota?.organization?.users?.length,
                stripe_customer_id: quota?.organization?.stripe_customer_id,
                stripe_subscription_id: quota?.organization?.stripe_subscription_id,
            }))}
            loading={{
                indicator: <Loader />,
                spinning: isLoading,
            }}
            bordered
            pagination={{
                pageSize: quotasQueryParams?.per_page || 20,
                defaultCurrent: quotasQueryParams?.page || 1,
                current: quotasQueryParams?.page || 1,
                itemRender: renderPaginationArrows,
                total: totalPages * (quotasQueryParams?.per_page || 20) || 0,
                showSizeChanger: true,
            }}
            onChange={handleChange}
            rowClassName={(record, _) => {
                return record?.key === expandedRowKeys?.[0] ? 'expanded-row' : '';
            }}
            scroll={{ x: true }}
        />
    );
};

export default AdminQuotasTable;
