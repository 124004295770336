export const intentCategories = [
    {
        value: 'I',
        category: 'Informational',
        tooltip:
            'The searcher wants an answer to a specific question or needs more information on a particular topic.',
        color: '#327EEF',
        key: 'informational',
    },
    {
        value: 'N',
        category: 'Navigational',
        tooltip:
            'The searcher is looking for a specific website or page but isn’t sure of the URL.',
        color: '#728192',
        key: 'navigational',
    },
    {
        value: 'C',
        category: 'Commercial',
        tooltip:
            'The searcher is investigating a specific brand, product, or service.',
        color: '#C38E21',
        key: 'commercial',
    },
    {
        value: 'T',
        category: 'Transactional',
        tooltip:
            'The searcher is ready to complete a specific action such as purchasing a product or signing up to a service.',
        color: '#1EA11D',
        key: 'transactional',
    },
];
