import { accountUsersSortFields } from '../../components/pages/account/users/constants/accountUsersSortFields';
import { sortOrders } from '../../constants/sortOrders';
import { roleTypes } from '../../constants/roleTypes';
import { getUsersStatusLabel } from './getUsersStatusLabel';

export const sortUsersByFieldAndOrder = (users, field, order) => {
    switch (field) {
        case accountUsersSortFields.email:
            return users.sort((a, b) => {
                if (order === sortOrders.asc) {
                    return a?.email < b?.email ? -1 : 1;
                } else {
                    return a?.email > b?.email ? -1 : 1;
                }
            });

        case accountUsersSortFields.role:
            return users.sort((a, b) => {
                const aRoleRating = Object.values(roleTypes).find(
                    (role) =>
                        role.key === a?.roles?.[0]?.name ||
                        role.key === a?.role?.name
                )?.rating;
                const bRoleRating = Object.values(roleTypes).find(
                    (role) =>
                        role.key === b?.roles?.[0]?.name ||
                        role.key === b?.role?.name
                )?.rating;

                if (order === sortOrders.asc) {
                    return bRoleRating - aRoleRating;
                } else {
                    return aRoleRating - bRoleRating;
                }
            });

        case accountUsersSortFields.status:
            return users
                .map((user) => {
                    return {
                        ...user,
                        status: getUsersStatusLabel(user?.active, user?.user),
                    };
                })
                ?.sort((a, b) => {
                    if (order === sortOrders.asc) {
                        return a?.status < b?.status ? -1 : 1;
                    } else {
                        return a?.status > b?.status ? -1 : 1;
                    }
                });

        case accountUsersSortFields.name:
            return users.sort((a, b) => {
                if (order === sortOrders.asc) {
                    return a?.full_name < b?.full_name ? -1 : 1;
                } else {
                    return a?.full_name > b?.full_name ? -1 : 1;
                }
            });

        default:
            return users;
    }
};
