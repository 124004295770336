import { planFeatures } from '../../components/pages/account/billing/BillingConstants';
import { billingPlanTypes } from '../../constants/billingPlanConsts';

export const getPlanFeaturesArray = (planMetaData, planType, isTrial) => {
    const planFeaturesArray = [];

    const getMetadataFeatureValue = (feature) => {
        if (typeof planFeatures[feature].value === 'boolean') {
            return planFeatures[feature].value;
        } else {
            if (planType === 'year' || planType === billingPlanTypes.yearly.value) {
                return planMetaData[feature] * planFeatures[feature].yearlyCoefficient;
            } else {
                return planMetaData[feature];
            }
        }
    };

    const getPlanFeatureValue = (feature) => {
        if (planType === 'year' || planType === billingPlanTypes.yearly.value) {
            if (typeof planFeatures[feature].value === 'boolean') {
                return planFeatures[feature].value;
            } else {
                return planFeatures[feature].value * planFeatures[feature].yearlyCoefficient;
            }
        } else {
            return planFeatures[feature].value;
        }
    };

    for (const feature in planFeatures) {
        if (planMetaData?.[feature]) {
            planFeaturesArray.push({
                ...planFeatures[feature],
                title:
                    isTrial && planFeatures[feature].trialValue === 1
                        ? planFeatures[feature].title.substring(
                              0,
                              planFeatures[feature].title.length - 1 // Cut last symbol to avoid plural form in inappropriate cases
                          )
                        : planFeatures[feature].title,
                value: isTrial
                    ? planFeatures[feature].trialValue
                    : getMetadataFeatureValue(feature),
            });
        } else {
            if (isTrial) {
                if (planFeatures[feature].hideIfTrial) {
                    continue;
                }
            }

            planFeaturesArray.push({
                ...planFeatures[feature],
                value: getPlanFeatureValue(feature),
            });
        }
    }

    return planFeaturesArray;
};
