import React from 'react';

import { Progress, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { useHighlightedKeywords } from '../../../../../../../../utils/hooks/useHighlightedKeywords';
import { useCompleteBadgeColor } from '../../../../../../../common/completeBadge/useCompleteBadgeColor';

import { selectContentOptimizerKeywords } from '../../../../store/contentOptimizerContent.selectors';
import { selectEditorBasicData } from '../../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';

const classes = {
    progressWrapper: 'optimizer-keywords-complete-progress-wrapper',
    progress: 'optimizer-keywords-complete-progress-bar',
    progressText: 'optimizer-keywords-complete-progress-text',
};

const OptimizerKeywordsCompleteProgressBar = () => {
    const keywords = useSelector(selectContentOptimizerKeywords);
    const basicData = useSelector(selectEditorBasicData);

    const highlightedKeywords = useHighlightedKeywords();
    const progressColor = useCompleteBadgeColor(
        Math.ceil(basicData?.rankability_score || 1)
    );

    const usedKeywords = highlightedKeywords.filter(
        (keyword) => keyword.uses > 0
    );

    const currentKeywords = keywords?.filter((keyword) =>
        usedKeywords.map((keyword) => keyword.keyword).includes(keyword.keyword)
    ).length;

    const totalKeywords = keywords?.length || 0;

    const progessPercent = Math.round((currentKeywords / totalKeywords) * 100);

    return (
        <div
            className={`${classes.progressWrapper}
                        ${
                            currentKeywords === totalKeywords &&
                            totalKeywords !== 0 &&
                            'complete'
                        }`}
        >
            <Progress
                percent={progessPercent}
                showInfo={false}
                className={classes.progress}
                strokeColor={progressColor}
            />
            <div className={classes.progressText}>
                <Typography>
                    {currentKeywords}/{totalKeywords} Keywords
                </Typography>
            </div>
        </div>
    );
};

export default OptimizerKeywordsCompleteProgressBar;
