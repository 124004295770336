import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';

import useInterruptedAIOptimizer from './hooks/useInterruptedAIOptimizer';
import { insertContentAfterNode } from './utils/editorTransactionHelpers';

import { nodeTypes } from './constants/nodeTypes';

import { HighlightedKeywordContext } from '../../pages/contentOptimizer/contentPage/content/optimizerTab/tiptapEditor/TipTapEditorWrapper';

import { selectSelectedKeyword } from '../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';
import { additionalContentToEditor, updateCurrentEditorText } from './store/tiptapEditor.actions';
import {
    selectAdditionalEditorContent,
    selectCurrentEditorContent,
    selectEditorHighlightKeywords,
    selectEditorPreviewVersion,
} from './store/tiptapEditor.selectors';

import './TipTapEditor.scss';

const TipTapEditorSettings = () => {
    const dispatch = useDispatch();

    const { editor } = useCurrentEditor();

    const selectedKeyword = useSelector(selectSelectedKeyword);
    const isHighlightKeywordsOn = useSelector(selectEditorHighlightKeywords);
    const additionalEditorContent = useSelector(selectAdditionalEditorContent);

    const currentEditorContent = useSelector(selectCurrentEditorContent);
    const editorPreviewVersion = useSelector(selectEditorPreviewVersion);

    const mappedKeywords = React.useContext(HighlightedKeywordContext) || [];

    const { initializeSpellCheckerWhenInterrupted } = useInterruptedAIOptimizer(editor);

    useEffect(() => {
        if (additionalEditorContent) {
            if (editor.storage?.[nodeTypes.aiSpellChecker]?.spellCheckerVisible) {
                insertContentAfterNode(editor, nodeTypes.aiSpellChecker, additionalEditorContent);
            } else {
                editor.commands.insertContent(additionalEditorContent);
            }

            dispatch(additionalContentToEditor(null));
        }

        // eslint-disable-next-line
    }, [additionalEditorContent]);

    useEffect(() => {
        const { mappedKeywords: storageKeywords } = editor?.storage.colorHighlighter || {};

        if (storageKeywords && mappedKeywords.length !== storageKeywords.length) {
            editor.commands.setKeywordList(mappedKeywords);
        }

        // eslint-disable-next-line
    }, [mappedKeywords?.length, editor]);

    useEffect(() => {
        if (editor?.storage.colorHighlighter) {
            editor.commands.setActiveKeyword(selectedKeyword);
        }
    }, [selectedKeyword, editor]);

    useEffect(() => {
        if (editor?.storage.colorHighlighter) {
            editor.commands.setHighlightingOn(isHighlightKeywordsOn);
        }
    }, [isHighlightKeywordsOn, editor]);

    useEffect(() => {
        const newEditorContent = editorPreviewVersion
            ? editorPreviewVersion.content.slice(1, -1)
            : currentEditorContent;

        editor.commands.setContent(newEditorContent);

        // eslint-disable-next-line
    }, [editorPreviewVersion]);

    useEffect(() => {
        const updatedEditorText = editor?.getText() || '';

        dispatch(updateCurrentEditorText(updatedEditorText));

        // eslint-disable-next-line
    }, [currentEditorContent]);

    useEffect(() => {
        initializeSpellCheckerWhenInterrupted();
    }, [editor, initializeSpellCheckerWhenInterrupted]);

    return <></>;
};

export default TipTapEditorSettings;
