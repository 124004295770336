import React from 'react';
import { useSelector } from 'react-redux';

import { Dropdown, Modal, Typography } from 'antd';

import CloseIcon from '../../../../resources/icons/CloseIcon';
import ContactSupportIcon from '../../../../resources/icons/ContactSupportIcon';
import FastStartGuideIcon from '../../../../resources/icons/FastStartGuideIcon';
import HelpIcon from '../../../../resources/icons/Help';
import HelpActiveIcon from '../../../../resources/icons/HelpActiveIcon';
import MastermindCallIcon from '../../../../resources/icons/MastermindCallIcon';
import TeacherIcon from '../../../../resources/icons/TeacherIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import StartGuideModalContent from './StartGuideModalContent';

const classes = {
    helpSettingWrapper: 'd-flex align-items-center cursor-pointer',
    helpSettingText: 'ml-8px',
    helpSettingDropdownOverlay:
        'dropdown-with-arrow-overlay help-setting-dropdown-overlay dropdown-menu dropdown-overlay-with-extra-content',
    dropdownOverlayDark:
        'dark-mode dropdown-with-arrow-overlay-dark dropdown-overlay-with-extra-content-dark',
    dropdownContentWrapper:
        'dropdown-with-extra-content-wrapper help-dropdown-menu',
    modalWrapper: 'fast-start-guide-modal',
};

const HelpSetting = ({ collapsed }) => {
    const darkMode = useSelector(selectDarkMode);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const openContactSupportModal = () => {
        window.Beacon && window.Beacon('open');
        handleOpen();
    };

    const { isOpen: isStartGuideOpen, handleOpen: handleStartGuideOpen } =
        useOpen();

    const openFastStartGuideModal = () => {
        handleOpen();
        handleStartGuideOpen();
    };

    const redirectToMastermindLink = (url) => {
        if (url) {
            window.open(url, '_blank');
        }

        handleOpen();
    };

    const items = [
        {
            label: 'Fast-Start Guide',
            key: 'fast-start-guide',
            icon: <FastStartGuideIcon color={iconColor} />,
            title: '',
            onClick: handleStartGuideOpen,
        },
        {
            label: 'SEO Masterclass',
            key: 'seo-masterclass',
            icon: <TeacherIcon color={iconColor} />,
            title: '',
            onClick: () => {
                redirectToMastermindLink(
                    'https://www.youtube.com/playlist?list=PLG-SDzSWuKQFE9bJ-Xu84rGtCbvGCx7mT'
                );
            },
        },
        {
            label: 'Mastermind Calls',
            key: 'mastermind calls',
            icon: <MastermindCallIcon color={iconColor} />,
            title: '',
            onClick: () => {
                redirectToMastermindLink(
                    'https://vimeo.com/showcase/rankability'
                );
            },
        },
        {
            label: 'Contact Support',
            key: 'contact-support',
            icon: <ContactSupportIcon color={iconColor} />,
            title: '',
            onClick: openContactSupportModal,
        },
    ];

    return (
        <>
            <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement={'right'}
                overlayStyle={{
                    width: '178px',
                }}
                arrow
                overlayClassName={`${classes.helpSettingDropdownOverlay}
                ${darkMode ? classes.dropdownOverlayDark : ''}
                ${collapsed && 'help-setting-dropdown-overlay-collapsed'}`}
                onOpenChange={handleOpen}
                dropdownRender={(menu) => (
                    <div className={classes.dropdownContentWrapper}>{menu}</div>
                )}
            >
                <div className={classes.helpSettingWrapper}>
                    {isOpen ? (
                        <HelpActiveIcon />
                    ) : (
                        <HelpIcon color={iconColor} />
                    )}
                    {!collapsed && (
                        <Typography
                            className={`${classes.helpSettingText} 
                                    ${isOpen && 'text-active'}`}
                        >
                            Help
                        </Typography>
                    )}
                </div>
            </Dropdown>
            {isStartGuideOpen && (
                <Modal
                    centered
                    open={isStartGuideOpen}
                    onCancel={openFastStartGuideModal}
                    footer={null}
                    closeIcon={<CloseIcon color={closeModalIconColor} />}
                    wrapClassName={classes.modalWrapper}
                    width={'800px'}
                >
                    <StartGuideModalContent />
                </Modal>
            )}
        </>
    );
};

export default HelpSetting;
