import React, { useState } from 'react';

import { Button } from 'antd';
import { useSelector } from 'react-redux';

import ExportIcon from '../../../../resources/icons/ExportIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { ordering } from '../../../../constants/ordering';
import { keywordPathes } from '../../../../constants/queryPathes';
import { axiosAPI } from '../../../../utils/axiosAPI';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectCurrentKeyword,
    selectKeywordListFilters,
    selectKeywordListSearch,
    selectKeywordListSort,
} from '../store/keywordResearchSettings.selectors';

const classes = {
    root: 'd-flex',
    exportButton: 'export-button btn-hover-light-primary',
};

const ExportButton = () => {
    const [exportLoading, setExportLoading] = useState(false);

    const darkMode = useSelector(selectDarkMode);
    const currentKeyword = useSelector(selectCurrentKeyword);
    const keywordListSort = useSelector(selectKeywordListSort);
    const keywordListSearch = useSelector(selectKeywordListSearch);
    const keywordListFilters = useSelector(selectKeywordListFilters);

    const handleExport = () => {
        setExportLoading(true);

        const getFilterRules = () => {
            if (keywordListFilters) {
                return JSON.stringify(keywordListFilters);
            } else {
                return null;
            }
        };

        axiosAPI
            .get(
                `${keywordPathes.getKeywordResearchInfo}${currentKeyword.id}`,
                {
                    params: {
                        format: 'csv',
                        sort: keywordListSort?.field,
                        order: ordering[keywordListSort?.order],
                        search: keywordListSearch,
                        filter_rules: getFilterRules(),
                    },
                }
            )
            .then((response) => {
                const BOM = '\uFEFF';
                const url = window.URL.createObjectURL(
                    new Blob([BOM + response.data], {
                        type: 'text/csv;charset=utf-8',
                    })
                );
                const link = document.createElement('a');
                link.target = '_blank';
                link.href = url;
                link.setAttribute('download', `${currentKeyword.keyword}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => setExportLoading(false));
    };

    return (
        <div className={classes.root}>
            <Button
                className={`${classes.exportButton}
                ${darkMode ? 'dark-mode' : ''}`}
                icon={<ExportIcon color={colorPalette.colorPrimary} />}
                type='primary'
                ghost
                onClick={handleExport}
                loading={exportLoading}
            >
                Export
            </Button>
        </div>
    );
};

export default ExportButton;
