import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Typography, Popconfirm } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { contentBriefsMainTabs } from '../../../constants/contentBriefsTabs';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../constants/notificationType';
import {
    contentBriefPathes,
    keywordPathes,
} from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';

import { openNotification } from '../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../utils/axiosAPI';

import ManageProjectModalWrapper from './ManageProjectModalWrapper';

import './CheckedItemsActionsPanel.scss';

const { Link } = Typography;

const classes = {
    checkedItemsActionsPanel:
        'checked-items-actions-panel d-flex align-items-center',
    checkedItemsActionsPanelBtn: 'checked-items-actions-panel-btn',
    checkedItemsActionsPanelLink: 'selected-link',
    deletePopconfirm: 'delete-popconfirm',
};

const CheckedItemsActionsPanel = ({
    getOptimizers,
    selectedTab,
    checkedItems,
    showOnlyDeleteAction,
    selectedProjects,
    handleArchiveAction,
    handleDeleteAction,
    handleUpdateProjects,
    isKeywordResearchBulkDelete,
    showOnlyManageProjectAction,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const [isArchiveLoading, setIsArchiveLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleArchive = () => {
        setIsArchiveLoading(true);

        const archiveBriefStatus =
            selectedTab === contentBriefsMainTabs.archived.key;

        axiosAPI
            .patch(
                `${contentBriefPathes.bulkUpdateContentBriefs}`,
                {
                    ids: checkedItems,
                    content_brief: {
                        archived: !archiveBriefStatus,
                    },
                },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    handleArchiveAction();
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsArchiveLoading(false);
            });
    };

    const handleDelete = () => {
        setIsDeleteLoading(true);

        axiosAPI
            .delete(
                `${
                    isKeywordResearchBulkDelete
                        ? keywordPathes.deleteKeywordResearch
                        : contentBriefPathes.bulkDeleteContentBriefs
                }`,
                {
                    ...getAxiosHeaders(),
                    data: {
                        ids: checkedItems,
                    },
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    handleDeleteAction();
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });
    };

    return (
        <div className={classes.checkedItemsActionsPanel}>
            <Link className={classes.checkedItemsActionsPanelLink}>{`Selected ${
                getOptimizers ? 'Optimizers' : 'Keywords'
            } (${checkedItems?.length})`}</Link>
            {showOnlyManageProjectAction && (
                <ManageProjectModalWrapper
                    getOptimizers={getOptimizers}
                    checkedItems={checkedItems}
                    selectedProjects={selectedProjects}
                    handleUpdateProjects={handleUpdateProjects}
                />
            )}
            {!showOnlyDeleteAction && !showOnlyManageProjectAction && (
                <>
                    {selectedTab !== contentBriefsMainTabs.archived.key && (
                        <>
                            <ManageProjectModalWrapper
                                getOptimizers={getOptimizers}
                                checkedItems={checkedItems}
                                selectedProjects={selectedProjects}
                                handleUpdateProjects={handleUpdateProjects}
                            />
                            <Button
                                className={`${
                                    classes.checkedItemsActionsPanelBtn
                                } ${darkMode ? 'dark-mode' : ''}`}
                                type='primary'
                                ghost
                                onClick={handleArchive}
                                loading={isArchiveLoading}
                            >
                                Archive
                            </Button>
                        </>
                    )}
                    {selectedTab === contentBriefsMainTabs.archived.key && (
                        <Button
                            className={`${
                                classes.checkedItemsActionsPanelBtn
                            } ${darkMode ? 'dark-mode' : ''}`}
                            type='primary'
                            ghost
                            onClick={handleArchive}
                            loading={isArchiveLoading}
                        >
                            Unarchive
                        </Button>
                    )}
                </>
            )}
            <Popconfirm
                placement='topRight'
                title='Are you sure you want to delete?'
                onConfirm={handleDelete}
                okText='Yes'
                cancelText='Cancel'
                loading={isDeleteLoading}
                overlayClassName={`${classes.deletePopconfirm}
                                   ${darkMode ? 'delete-popconfirm-dark' : ''}`}
            >
                <Button
                    className={`${classes.checkedItemsActionsPanelBtn} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    type='primary'
                    ghost
                >
                    Delete
                </Button>
            </Popconfirm>
        </div>
    );
};

export default CheckedItemsActionsPanel;
