import React from 'react';

const RankabilityDarkLogo = ({ progress }) => {
    return (
        <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='70 70 1200 230'
            width='158'
        >
            <defs>
                <style>{`.cls-1{fill:#327eef;}.cls-2{fill:#fff;}`}</style>
            </defs>
            <path
                className={`cls-1 ${progress > 5 ? 'loaded' : ''}`}
                d='M166.14,206.55l.74.37a13.76,13.76,0,0,1,8.32,2.53,8.37,8.37,0,0,1,3.45,7.19,11,11,0,0,1-.19,1.86c-.12.75-.25,1.44-.37,2.06a3.2,3.2,0,0,1-.09.75,4.47,4.47,0,0,1-.28.74c-.88,2.74-2.4,4.42-4.58,5a25.47,25.47,0,0,1-7,.93H111.6a15,15,0,0,1-9.15-2.62,8.52,8.52,0,0,1-3.55-7.28,10.15,10.15,0,0,1,.28-2.15c.19-.81.41-1.58.66-2.33a2.19,2.19,0,0,1,.09-.66,4.53,4.53,0,0,1,.28-.65,6.81,6.81,0,0,1,4.2-4.67,18.65,18.65,0,0,1,6.44-1.12h8.22V159.3h-1.86a.6.6,0,0,1-.38.19H113.1a16,16,0,0,1-5.79-1.5,7.41,7.41,0,0,1-3.93-4.48,4.57,4.57,0,0,1-.28-.75A3.12,3.12,0,0,1,103,152a19,19,0,0,1-.28-2.06c-.06-.75-.09-1.37-.09-1.87a9.53,9.53,0,0,1,3.08-7.56,12.15,12.15,0,0,1,8.31-2.71h21.66q3.56,0,4.86,2.43a11.28,11.28,0,0,1,1.31,5.42v.56a16.56,16.56,0,0,1-.1,1.86c-.06.63-.09,1.31-.09,2.06a3.2,3.2,0,0,1-.09.75,2.69,2.69,0,0,0-.1.74c-.12,1-.21,1.9-.28,2.71s-.09,1.53-.09,2.15c.62-.62,1.28-1.24,2-1.87s1.4-1.24,2.15-1.87a106.72,106.72,0,0,1,16.16-12.23A35,35,0,0,1,180.15,135a27.55,27.55,0,0,1,3.17.19,30.19,30.19,0,0,1,3.36.56c.75.12,1.46.28,2.15.46s1.4.41,2.15.66a14.7,14.7,0,0,1,7,4.85,16.79,16.79,0,0,1,3.64,7.47,7.7,7.7,0,0,0,.1,1.22,9,9,0,0,1,.09,1.21v1a3.18,3.18,0,0,1-.19,1,11.52,11.52,0,0,1-1.77,4.57,11.75,11.75,0,0,1-3.83,3.64l-.75.38-.74.37a28.75,28.75,0,0,1-3.18,1.22,9.46,9.46,0,0,1-2.8.46,12.83,12.83,0,0,1-5-1q-2.43-1-4.86-2.34a.9.9,0,0,1-.47-.19,2.56,2.56,0,0,1-.46-.37c-1.25-.62-2.49-1.18-3.74-1.68a13.61,13.61,0,0,0-3.92-.93h-.75q-.75,0-1.68.09a12.24,12.24,0,0,0-1.68.28,25.2,25.2,0,0,0-8,3.92,86.92,86.92,0,0,0-7.47,6c-1.37,1.24-2.65,2.46-3.83,3.64L143.35,175v31.56Z'
            />
            <path
                className={`cls-1 ${progress > 12 ? 'loaded' : ''}`}
                d='M296.33,206.55h.75c3.61,0,6.35.94,8.22,2.8a11.41,11.41,0,0,1,3.36,6.35,1.92,1.92,0,0,1,.19.84v1.59a12.22,12.22,0,0,1-1.87,5.7,8.29,8.29,0,0,1-4.48,3.64,6,6,0,0,0-.94.09,6.06,6.06,0,0,1-1.12.1,9.79,9.79,0,0,1-1.87.18,9.78,9.78,0,0,0-1.86.19h-3.55c-1.25,0-2.46,0-3.64-.09s-2.34-.16-3.46-.28a12.24,12.24,0,0,1-1.68-.1,11.09,11.09,0,0,0-1.31-.09,5.41,5.41,0,0,1-3.73-2.15,18.66,18.66,0,0,1-2.24-4,5.12,5.12,0,0,0-.47-1,4.84,4.84,0,0,1-.47-1A81.14,81.14,0,0,1,260,226.82a59.16,59.16,0,0,1-17.84,3.08l-1.31.18a6.39,6.39,0,0,0-1.49-.18h-1.5A45,45,0,0,1,218.45,224q-9-5.13-10.83-16.15a10.3,10.3,0,0,0-.09-1.4,8.41,8.41,0,0,0-.28-1.22c-.13-.74-.22-1.46-.29-2.14s-.09-1.34-.09-2q0-11.4,7.47-19a36.73,36.73,0,0,1,19.24-10l4.2-.75c1.43-.25,2.9-.44,4.39-.56a32.12,32.12,0,0,1,4.2-.47q2.15-.09,4.2-.09c1.62,0,3.21.06,4.77.19l4.76.37c1.37.12,2.71.28,4,.47s2.59.34,3.83.46l2.53.38,2.33.37q0-9.9-4.67-14.94a15.57,15.57,0,0,0-11.95-5,24.7,24.7,0,0,0-5.33.65,56.74,56.74,0,0,0-6.07,1.77c-.87.38-1.93.88-3.17,1.5s-2.55,1.24-3.92,1.87l-2.62,1.12-2.61,1.12c-1.5.5-3,.93-4.39,1.3a15.66,15.66,0,0,1-4,.56,9.72,9.72,0,0,1-5.13-1.4,8.26,8.26,0,0,1-3.46-4.57,3.81,3.81,0,0,1-.28-.75,4.06,4.06,0,0,0-.28-.75c-.12-.62-.25-1.18-.37-1.68a5.16,5.16,0,0,1-.19-1.3q0-6,6.26-9.34a53.87,53.87,0,0,1,13.91-5.05A73.55,73.55,0,0,1,245,135.86q5-.47,7.66-.47h5.42c1,0,2,0,3.08.1s2.14.09,3.27.09a66.13,66.13,0,0,1,11.67,2.15,32.62,32.62,0,0,1,10.36,5q7.29,5.41,8.59,12.42a83.15,83.15,0,0,1,1.31,15.22Zm-58.55,1.68a21.78,21.78,0,0,0,7.57,2.43c.62,0,1.27,0,2,.09a19.06,19.06,0,0,0,2,.1,43.4,43.4,0,0,0,12.42-2.52,50.52,50.52,0,0,0,11.11-5.7V187.87q-2.43-.56-4.95-.93l-5.14-.75c-1.24-.12-2.52-.21-3.82-.28s-2.59-.09-3.83-.09q-3,0-5.89.28a29.43,29.43,0,0,0-5.88,1.21,16.92,16.92,0,0,0-6.82,4.67,11,11,0,0,0-3.08,7.66v.56a.73.73,0,0,0,.19.56v1.31A8.14,8.14,0,0,0,237.78,208.23Z'
            />
            <path
                className={`cls-1 ${progress > 19 ? 'loaded' : ''}`}
                d='M419.2,208.7q3.46,2.34,3.46,8.5,0,5.79-3.46,8.31a14.67,14.67,0,0,1-8.87,2.52h-23.9q-5.61,0-9-2.43t-3.36-7.84a4.46,4.46,0,0,1,.09-.84,4.54,4.54,0,0,0,.1-.84,3.76,3.76,0,0,1,.09-.84,4.46,4.46,0,0,0,.09-.84c.38-3.24,1.74-5.39,4.11-6.45a19.12,19.12,0,0,1,7.85-1.59V175.55a36.74,36.74,0,0,0-2.53-12.61q-2.32-5.88-10.73-6.44H372q-8.78,0-13.44,6.72a25.56,25.56,0,0,0-4.67,14.94v28.2a18.51,18.51,0,0,1,7.75,1.59q3.45,1.59,4.2,6.45v3.36q0,5.42-3.27,7.84a14.2,14.2,0,0,1-8.68,2.43H329.65a15.16,15.16,0,0,1-8.78-2.43q-3.56-2.43-3.55-7.84a3.82,3.82,0,0,1,.1-.84,4.46,4.46,0,0,0,.09-.84,4.46,4.46,0,0,1,.09-.84,4.54,4.54,0,0,0,.1-.84q.74-4.86,4.29-6.45a18.69,18.69,0,0,1,7.66-1.59V159.3l-.75.19q-5.41,0-8.22-2.81a11.34,11.34,0,0,1-3.36-6.16,5.56,5.56,0,0,0-.09-1,4.61,4.61,0,0,1-.09-1v-.75a1.72,1.72,0,0,1,.18-.75,10.78,10.78,0,0,1,1.22-4.2,8.86,8.86,0,0,1,2.52-3.08,11.38,11.38,0,0,1,3.64-1.4,34.79,34.79,0,0,1,4.58-.66,5.91,5.91,0,0,1,1.4-.18h4.39a3.89,3.89,0,0,0,1.21.18h1.21c.87,0,1.71,0,2.52.1s1.53.09,2.15.09h4.3a10.29,10.29,0,0,1,3.55,1,3.3,3.3,0,0,1,1.68,3.27v.74a.76.76,0,0,0-.19.56v3.27a.61.61,0,0,0,.19.47v2.43a41,41,0,0,1,12.23-10.46,30,30,0,0,1,14.66-3.74c.75,0,1.56,0,2.43.1s1.74.15,2.61.28,1.56.25,2.43.37a14.42,14.42,0,0,1,2.43.56q13.81,3.74,17.84,14.1a63,63,0,0,1,4,22.88v32.68A15.5,15.5,0,0,1,419.2,208.7Z'
            />
            <path
                className={`cls-1 ${progress > 26 ? 'loaded' : ''}`}
                d='M483.42,153.42a7.76,7.76,0,0,1-1.31-4.39,3.82,3.82,0,0,1,.1-.84,7.32,7.32,0,0,1,.28-1V147c.12-.37.21-.71.28-1a4.46,4.46,0,0,0,.09-.84q.75-4.86,4.2-6.07a22.72,22.72,0,0,1,7.57-1.22h21.29l.37.37c3.74,0,6.54.88,8.41,2.62a11.63,11.63,0,0,1,3.55,5.6,4.7,4.7,0,0,0,.09,1,5.41,5.41,0,0,1,.09,1v.74a13.07,13.07,0,0,1-1,4.21,4.79,4.79,0,0,1-2,2.52,18.07,18.07,0,0,1-3.08,1.21c-1.06.31-2.08.59-3.08.84a.74.74,0,0,0-.37.1.88.88,0,0,1-.38.09q-1.68.38-3.45.84a11.79,11.79,0,0,0-3.27,1.4,35.13,35.13,0,0,0-5.79,4.67l-5.23,5.23-.47.47-.46.46a20.8,20.8,0,0,1-1.59,1.78l-1.59,1.58,25.59,31.94h1.87a13.93,13.93,0,0,1,8.68,2.62c2.3,1.74,3.46,4.42,3.46,8v2.61c-.63,3.36-2.15,5.58-4.58,6.63a19.62,19.62,0,0,1-7.94,1.59H500a24.72,24.72,0,0,1-6.81-.93,7.27,7.27,0,0,1-4.77-4.49,2.17,2.17,0,0,0-.09-.65,2.13,2.13,0,0,1-.09-.65l-.19-.19v-.19a6.33,6.33,0,0,1-.28-1.12,7,7,0,0,1-.09-1.12,7.91,7.91,0,0,1,2.15-5.41,14.14,14.14,0,0,1,5.32-3.74L479.13,190.3,466.8,201.51v17.74c0,3.86-.63,6.29-1.87,7.29s-4.11,1.49-8.59,1.49H442.15a10.17,10.17,0,0,1-7.38-2.8,11.21,11.21,0,0,1-3.46-6.35,1.92,1.92,0,0,1-.18-.84v-1.4a10.72,10.72,0,0,1,3.17-6.91c2-2.12,4.86-3.18,8.59-3.18h.94V121.76h-.94q-5.42,0-8.4-3a10.77,10.77,0,0,1-3.36-6.54v-1.86a12,12,0,0,1,3.27-7.1,9.5,9.5,0,0,1,7.37-3.18h.38c.74,0,1.61,0,2.61-.09s2.12-.15,3.36-.28h3a13.14,13.14,0,0,1,2.06-.19H455a24,24,0,0,1,8.13,1.31c2.43.87,3.64,2.87,3.64,6V175l20.92-18.49A8.59,8.59,0,0,1,483.42,153.42Z'
            />
            <path
                className={`cls-2 ${progress > 33 ? 'loaded' : ''}`}
                d='M632.16,206.55h.74c3.61,0,6.35.94,8.22,2.8a11.35,11.35,0,0,1,3.36,6.35,1.79,1.79,0,0,1,.19.84v1.59a12.22,12.22,0,0,1-1.87,5.7,8.25,8.25,0,0,1-4.48,3.64,6,6,0,0,0-.93.09,6.14,6.14,0,0,1-1.12.1,10,10,0,0,1-1.87.18,9.8,9.8,0,0,0-1.87.19H629c-1.24,0-2.46,0-3.64-.09s-2.34-.16-3.46-.28a12.24,12.24,0,0,1-1.68-.1,11.07,11.07,0,0,0-1.3-.09,5.44,5.44,0,0,1-3.74-2.15,19.09,19.09,0,0,1-2.24-4,5.12,5.12,0,0,0-.47-1,5.36,5.36,0,0,1-.46-1,81.22,81.22,0,0,1-16.16,7.57A59.11,59.11,0,0,1,578,229.9l-1.3.18a6.41,6.41,0,0,0-1.5-.18H573.7A44.91,44.91,0,0,1,554.28,224q-9-5.13-10.84-16.15a10.3,10.3,0,0,0-.09-1.4,7.27,7.27,0,0,0-.28-1.22c-.13-.74-.22-1.46-.28-2.14a19.23,19.23,0,0,1-.09-2q0-11.4,7.47-19t19.23-10l4.21-.75c1.43-.25,2.89-.44,4.38-.56a32.08,32.08,0,0,1,4.21-.47q2.14-.09,4.2-.09c1.62,0,3.2.06,4.76.19l4.76.37c1.37.12,2.71.28,4,.47s2.58.34,3.83.46l2.52.38,2.33.37q0-9.9-4.66-14.94a15.59,15.59,0,0,0-12-5,24.61,24.61,0,0,0-5.32.65,56.74,56.74,0,0,0-6.07,1.77q-1.3.57-3.18,1.5c-1.24.62-2.55,1.24-3.92,1.87l-2.61,1.12-2.62,1.12c-1.49.5-3,.93-4.39,1.3a15.64,15.64,0,0,1-4,.56,9.76,9.76,0,0,1-5.14-1.4,8.2,8.2,0,0,1-3.45-4.57,3.27,3.27,0,0,1-.28-.75,4.93,4.93,0,0,0-.28-.75c-.13-.62-.25-1.18-.38-1.68a5.59,5.59,0,0,1-.18-1.3q0-6,6.25-9.34a53.8,53.8,0,0,1,13.92-5.05,73.31,73.31,0,0,1,10.46-1.77q5-.47,7.65-.47h5.42c1,0,2,0,3.08.1s2.15.09,3.27.09a66.13,66.13,0,0,1,11.67,2.15,32.67,32.67,0,0,1,10.37,5q7.28,5.41,8.59,12.42a83.15,83.15,0,0,1,1.31,15.22Zm-58.55,1.68a21.79,21.79,0,0,0,7.56,2.43c.62,0,1.27,0,2,.09a19.21,19.21,0,0,0,2,.1,43.26,43.26,0,0,0,12.42-2.52,50.3,50.3,0,0,0,11.11-5.7V187.87c-1.62-.37-3.26-.68-5-.93l-5.13-.75c-1.25-.12-2.52-.21-3.83-.28s-2.59-.09-3.83-.09q-3,0-5.88.28a29.67,29.67,0,0,0-5.89,1.21A16.82,16.82,0,0,0,572.3,192a11,11,0,0,0-3.08,7.66v.56a.77.77,0,0,0,.18.56v1.31A8.15,8.15,0,0,0,573.61,208.23Z'
                fill='white'
            />
            <path
                className={`cls-2 ${progress > 40 ? 'loaded' : ''}`}
                d='M686.76,220.75a12.07,12.07,0,0,1-1,5C685,227.28,683.46,228,681,228H661.92a8.79,8.79,0,0,1-6-3.27,11.6,11.6,0,0,1-2.8-6.26v-2a1.79,1.79,0,0,1,.19-.84,11.14,11.14,0,0,1,3.45-6.35c1.93-1.86,4.64-2.8,8.13-2.8h.93V121.76h-.93q-5.61,0-8.5-3.08a11.52,11.52,0,0,1-3.27-6.82v-1.77a1.79,1.79,0,0,1,.19-.84,11.56,11.56,0,0,1,3.26-6.35,9.56,9.56,0,0,1,7.19-2.81H664a24.62,24.62,0,0,0,2.71-.18l3.45-.38c.87,0,1.75,0,2.62-.09s1.74-.15,2.61-.28h2.25a23,23,0,0,1,8.59,1.5c2.49,1,3.73,3,3.73,6.16V146.6a34.2,34.2,0,0,1,9.15-5.79,48.11,48.11,0,0,1,9.9-3c1-.12,2-.21,3-.28s2-.09,3-.09q17.17,0,31.37,12.88t14.2,33.44v1.68q-.19,19.61-12.51,30.91t-27.08,13.16c-1,.13-2,.22-3,.28s-2,.1-3,.1a42.82,42.82,0,0,1-15.69-3,27.82,27.82,0,0,1-12.51-9.53Zm4.39-37.45a3.82,3.82,0,0,0-.1.84,4.46,4.46,0,0,1-.09.84,3.76,3.76,0,0,0-.09.84,27.25,27.25,0,0,0,5.22,16.25q5.24,7.27,15.88,7.28,10.46,0,16.34-8a30.35,30.35,0,0,0,6.07-17.74V181.8a3.65,3.65,0,0,0-.19-1.21,26.8,26.8,0,0,0-4.94-13.54,18.84,18.84,0,0,0-12.61-7.75h-3.92q-9.72,0-15.22,7.19a29.84,29.84,0,0,0-6.26,16A4.46,4.46,0,0,1,691.15,183.3Z'
                fill='white'
            />
            <path
                className={`cls-2 ${progress > 47 ? 'loaded' : ''}`}
                d='M779.55,152.76a3.12,3.12,0,0,1-.09-.74c-.13-.63-.25-1.31-.38-2.06a12.2,12.2,0,0,1-.18-1.87,9.4,9.4,0,0,1,3.17-7.56,12.55,12.55,0,0,1,8.41-2.71h30.63a7.11,7.11,0,0,1,3.54.94,3.5,3.5,0,0,1,1.68,3.36v64.43h20.74l.74.37a13.93,13.93,0,0,1,8.59,2.53,8.44,8.44,0,0,1,3.37,7.19c0,.5,0,1.12-.1,1.86a7.31,7.31,0,0,1-.46,2.06,3.22,3.22,0,0,1-.1.75,3.73,3.73,0,0,1-.28.74,6.62,6.62,0,0,1-4.11,4.77,23.44,23.44,0,0,1-6.35,1.21h-67a15.09,15.09,0,0,1-8.87-2.43q-3.45-2.43-3.45-8.4c0-4.23,1.12-7.07,3.36-8.5a16.48,16.48,0,0,1,9-2.15h20.92V159.3c-.62,0-1.31,0-2,.09s-1.5.1-2.24.1a3.44,3.44,0,0,1-1,.18h-1a5.91,5.91,0,0,1-1.49.19h-3.74a22.53,22.53,0,0,1-6.72-1.77,7,7,0,0,1-4.11-4.58A3.27,3.27,0,0,1,779.55,152.76Zm24-57c1.12-1.24,3-1.93,5.6-2H814a6.39,6.39,0,0,1,1.49.18,12.28,12.28,0,0,1,3.92,1,6.52,6.52,0,0,1,3,2.71,12.76,12.76,0,0,1,1.4,4.76,49.72,49.72,0,0,1,.47,7,42.3,42.3,0,0,1-.56,6.91A11.41,11.41,0,0,1,822,121.2c-.37.87-1.43,1.62-3.18,2.24a19.89,19.89,0,0,1-5.6,1.12h-2.43a23.13,23.13,0,0,1-4.48-.75,12.19,12.19,0,0,1-3-1.12,8.63,8.63,0,0,1-2.71-4.67,26.1,26.1,0,0,1-.84-6.72v-1.21a6,6,0,0,1,.19-1.4c0-.63,0-1.25.09-1.87s.16-1.24.28-1.87.25-1.46.38-2.15a9.8,9.8,0,0,0,.18-1.58A13.06,13.06,0,0,1,803.55,95.8Z'
                fill='white'
            />
            <path
                className={`cls-2 ${progress > 52 ? 'loaded' : ''}`}
                d='M899.63,121.85c-.75.07-1.5.16-2.24.28a7,7,0,0,0-1.12.1,7.14,7.14,0,0,1-1.12.09,5.58,5.58,0,0,1-1.4.19h-3.27a22.85,22.85,0,0,1-7.1-1.87,7.3,7.3,0,0,1-4.3-4.86,4.47,4.47,0,0,1-.28-.74,3.2,3.2,0,0,1-.09-.75,9.54,9.54,0,0,1-.56-2.06,11,11,0,0,1-.19-1.86,8.9,8.9,0,0,1,3.65-7.57,14.76,14.76,0,0,1,9-2.71h29.7a9.12,9.12,0,0,1,3.55,1.13,3.33,3.33,0,0,1,1.68,3.17V206.55h21.1l.56.37a13.62,13.62,0,0,1,8.41,2.53,8.45,8.45,0,0,1,3.36,7.19q0,.75-.09,1.86c-.07.75-.16,1.44-.28,2.06a3.38,3.38,0,0,1-.38,1.49,7,7,0,0,1-3.64,4.49,19.7,19.7,0,0,1-5.51,1.49H880.58a15.16,15.16,0,0,1-8.78-2.43q-3.56-2.43-3.55-8.4,0-6.34,3.55-8.5a16.68,16.68,0,0,1,8.78-2.15H901.5V121.76C901,121.76,900.38,121.79,899.63,121.85Z'
                fill='white'
            />
            <path
                className={`cls-2 ${progress > 60 ? 'loaded' : ''}`}
                d='M978,152.76A3.12,3.12,0,0,1,978,152c-.13-.63-.25-1.31-.38-2.06a12.2,12.2,0,0,1-.18-1.87,9.4,9.4,0,0,1,3.17-7.56,12.55,12.55,0,0,1,8.41-2.71h30.63a7.11,7.11,0,0,1,3.54.94,3.49,3.49,0,0,1,1.69,3.36v64.43h20.73l.74.37a13.93,13.93,0,0,1,8.59,2.53,8.44,8.44,0,0,1,3.37,7.19c0,.5,0,1.12-.1,1.86a7.31,7.31,0,0,1-.46,2.06,3.38,3.38,0,0,1-.38,1.49,6.6,6.6,0,0,1-4.11,4.77,23.44,23.44,0,0,1-6.35,1.21h-67a15.09,15.09,0,0,1-8.87-2.43q-3.45-2.43-3.45-8.4c0-4.23,1.12-7.07,3.36-8.5a16.48,16.48,0,0,1,9-2.15h20.92V159.3c-.62,0-1.3,0-2.05.09s-1.5.1-2.24.1a3.44,3.44,0,0,1-1,.18h-1a5.91,5.91,0,0,1-1.49.19h-3.74a22.53,22.53,0,0,1-6.72-1.77,7,7,0,0,1-4.11-4.58A3.27,3.27,0,0,1,978,152.76Zm24-57c1.12-1.24,3-1.93,5.6-2h4.86a6.39,6.39,0,0,1,1.49.18,12.41,12.41,0,0,1,3.93,1,6.54,6.54,0,0,1,3,2.71,12.76,12.76,0,0,1,1.4,4.76,49.72,49.72,0,0,1,.47,7,42.3,42.3,0,0,1-.56,6.91,11.41,11.41,0,0,1-1.68,4.86c-.37.87-1.43,1.62-3.18,2.24a19.89,19.89,0,0,1-5.6,1.12h-2.43a23.13,23.13,0,0,1-4.48-.75,12.19,12.19,0,0,1-3-1.12,8.56,8.56,0,0,1-2.7-4.67,26.1,26.1,0,0,1-.84-6.72v-1.21a6,6,0,0,1,.18-1.4,16.61,16.61,0,0,1,.1-1.87,17.6,17.6,0,0,1,.28-1.87c.12-.75.24-1.46.37-2.15a10.06,10.06,0,0,0,.19-1.58A12.94,12.94,0,0,1,1002,95.8Z'
                fill='white'
            />
            <path
                className={`cls-2 ${progress > 70 ? 'loaded' : ''}`}
                d='M1060.2,162a8.71,8.71,0,0,1-3.64-7.38,13.62,13.62,0,0,1,.09-1.4,11,11,0,0,1,.28-1.59c.12-.49.25-1,.37-1.4s.25-.84.38-1.21a7,7,0,0,1,4.39-4.77,23.73,23.73,0,0,1,7-1.4h3.36a3.18,3.18,0,0,0,1,.19h1c.74,0,1.46,0,2.14.09a19.4,19.4,0,0,0,2,.1v-23a.9.9,0,0,0-.1-.38,1.33,1.33,0,0,1-.09-.56.74.74,0,0,0-.1-.37.85.85,0,0,1-.09-.38,7.14,7.14,0,0,1,.09-1.12,6,6,0,0,0,.1-1.12,9.67,9.67,0,0,1,.65-2.42,8.55,8.55,0,0,1,1.22-2.06,13.77,13.77,0,0,1,2.89-2.52,10.14,10.14,0,0,1,3.27-1.4,13.85,13.85,0,0,1,1.4-.28,8.86,8.86,0,0,1,1.21-.09h1.5a14.3,14.3,0,0,1,8,4.1,11.42,11.42,0,0,1,3.73,8.6v23h29.89c3.85,0,6.88.81,9,2.43s3.27,4,3.27,7.28a22,22,0,0,1-.18,2.61,28.07,28.07,0,0,1-.56,3c-.88,2.74-2.4,4.46-4.58,5.14a23.37,23.37,0,0,1-7,1h-29.89v27.82a36.44,36.44,0,0,0,1.12,7.47,9.18,9.18,0,0,0,4.3,5.61,14.11,14.11,0,0,0,3.27,1.49,17.18,17.18,0,0,0,3.64.75,3.93,3.93,0,0,0,1.21.19h1.22a14.3,14.3,0,0,0,1.77-.1,15,15,0,0,1,1.59-.09,29.28,29.28,0,0,0,3.55-.56c1.12-.25,2.18-.5,3.17-.75s2.21-.87,3.65-1.49,2.89-1.25,4.38-1.87c.87-.37,1.81-.75,2.81-1.12s1.93-.75,2.8-1.12c1.37-.38,2.71-.72,4-1a15.89,15.89,0,0,1,3.65-.47,11.74,11.74,0,0,1,5.6,1.4A8.79,8.79,0,0,1,1158,206a3.2,3.2,0,0,0,.09.75,2.89,2.89,0,0,1,.1.56c.12.5.21,1,.28,1.4a9,9,0,0,1,.09,1.21q0,5.79-5.79,9.62a51.84,51.84,0,0,1-12.7,6.07,61.05,61.05,0,0,1-8.59,2.24,66.49,66.49,0,0,1-6.72.94q-.75,0-1.68.09c-.63.06-1.25.09-1.87.09a8.78,8.78,0,0,0-1.4.1,9,9,0,0,1-1.22.09q-1.3,0-2.61.09c-.87.06-1.68.1-2.43.1H1111a18.5,18.5,0,0,1-2.52-.19,58.33,58.33,0,0,1-9.71-1.59,32.72,32.72,0,0,1-9.16-3.83A18.43,18.43,0,0,1,1081,213a58.63,58.63,0,0,1-2.42-14.1,24.45,24.45,0,0,1-.19-3.17,26.65,26.65,0,0,0-.19-3.18V164.72h-9A14.79,14.79,0,0,1,1060.2,162Z'
                fill='white'
            />
            <path
                className={`cls-2 ${progress > 75 ? 'loaded' : ''}`}
                d='M1255.33,138.19a14.4,14.4,0,0,1,8.41,2.34q3.36,2.34,3.36,7.56v3c-.5,3.36-2,5.58-4.39,6.63a19.65,19.65,0,0,1-7.94,1.59l-40.15,85.91a.63.63,0,0,1,.46-.18h.66a36.93,36.93,0,0,1,10.74,1.49c3.29,1,5,4,5,9.15q0,6.54-3.46,8.69a16.6,16.6,0,0,1-8.87,2.14l-42.77.19c-3.86-.13-6.94-1.18-9.25-3.18a9.86,9.86,0,0,1-3.45-7.84,8.86,8.86,0,0,1,.09-1.21,7.27,7.27,0,0,1,.28-1.22,1.85,1.85,0,0,1,.1-.65,2.17,2.17,0,0,0,.09-.65,1.23,1.23,0,0,0,.28-.47,2.19,2.19,0,0,0,.09-.66,7.56,7.56,0,0,1,4.49-4.94,24.53,24.53,0,0,1,7.09-1.78h4.3c.87.12,1.71.22,2.52.28s1.59.16,2.33.28a26.86,26.86,0,0,1,3.27.19,26,26,0,0,0,2.9.19l9.34-19.43-29.89-66.3a18.94,18.94,0,0,1-7.84-1.59c-2.37-1.05-3.8-3.27-4.3-6.63a3.27,3.27,0,0,0-.09-.75,3.06,3.06,0,0,1-.09-.74,3.22,3.22,0,0,0-.1-.75,3.2,3.2,0,0,1-.09-.75q0-5,3.27-7.65a12.85,12.85,0,0,1,8.31-2.62h26l.56.37a13.52,13.52,0,0,1,8.22,2.34q3.16,2.34,3.17,7.56v3c-.37,3.36-1.74,5.58-4.11,6.63a18.94,18.94,0,0,1-7.84,1.59L1213.31,201l18.49-41.65a19.62,19.62,0,0,1-7.94-1.59c-2.43-1.05-3.83-3.27-4.2-6.63v-1.49a1.61,1.61,0,0,1-.19-.75v-.75q0-5,3.27-7.65a12.57,12.57,0,0,1,8.13-2.62h23.9Z'
                fill='white'
            />
        </svg>
    );
};

export default RankabilityDarkLogo;
