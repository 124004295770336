import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUserRoles } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsGlobalTechAdmin } from '../../../../utils/helpers/checkIsGlobalTechAdmin';

import AccountNavDropdown from './AccountNavDropdown';
import CreditsRemaining from './CreditsRemaining';
import DarkModeSwitch from './DarkModeSwitch';
import HelpSetting from './HelpSetting';
import MastermindCountdown from './MastermindCountdown';

const classes = {
    siderSettingsWrapper: 'sider-settings',
    siderSettingsItem: 'sider-settings-item',
    siderAccSettingsItem: 'sider-acc-settings-item d-flex align-items-center',
};

const SiderSettings = ({ collapsed }) => {
    const userRoles = useSelector(selectCurrentUserRoles);

    return (
        <div>
            <div className={classes.siderSettingsWrapper}>
                <div>
                    <div className={classes.siderSettingsItem}>
                        <HelpSetting collapsed={collapsed} />
                    </div>
                    <div className={classes.siderSettingsItem}>
                        <MastermindCountdown collapsed={collapsed} />
                    </div>
                    {!checkIsGlobalTechAdmin(userRoles) && (
                        <div className={classes.siderSettingsItem}>
                            <CreditsRemaining collapsed={collapsed} />
                        </div>
                    )}
                    <div className={classes.siderSettingsItem}>
                        <DarkModeSwitch collapsed={collapsed} />
                    </div>
                </div>
                <div className={classes.siderAccSettingsItem}>
                    <AccountNavDropdown collapsed={collapsed} />
                </div>
            </div>
        </div>
    );
};

export default SiderSettings;
