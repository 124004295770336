import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Typography } from 'antd';
import moment from 'moment';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { renderPaginationArrows } from '../../../../../utils/helpers/renderPaginationArrows';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';

import { sortOrders } from '../../../../../constants/sortOrders';

import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';
import Loader from '../../../../common/loader/Loader';

import { organizationsSortFields } from '../../constants';

import {
    selectOrganizationsData,
    selectOrganizationsIsDataLoading,
    selectOrganizationsQueryParams,
    selectOrganizationsTotalPages,
} from '../store/adminOrganizationsSettings.selectors';
import { updateOrganizationQueryParams } from '../store/adminOrganizationsSettings.actions';

import OrganizationActionsDropdown from './OrganizationActionsDropdown';
import AdminUsersExpandedRow from './AdminUsersExpandedRow';

const classes = {
    tableHeaderTitle:
        'd-flex align-items-center users-table-header-title cursor-pointer',
    expandIcon: 'expand-icon d-flex flex-center cursor-pointer',
};

const AdminOrganizationsTable = () => {
    const dispatch = useDispatch();

    const organizationsData = useSelector(selectOrganizationsData);
    const isLoading = useSelector(selectOrganizationsIsDataLoading);
    const organizationsQueryParams = useSelector(
        selectOrganizationsQueryParams
    );
    const totalPages = useSelector(selectOrganizationsTotalPages);

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const iconColor = useIconColor();

    const handleSort = (sortField) => {
        dispatch(
            updateOrganizationQueryParams({
                sort_field: sortField,
                sort_order:
                    organizationsQueryParams?.sort_field === sortField &&
                    organizationsQueryParams?.sort_order === sortOrders.desc
                        ? sortOrders.asc
                        : sortOrders.desc,
                page: 1,
            })
        );
    };

    const handleChange = (pagination) => {
        window?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        dispatch(
            updateOrganizationQueryParams(
                pagination?.current
                    ? {
                          page: pagination?.current,
                          per_page: pagination?.pageSize,
                      }
                    : null
            )
        );
    };

    const columns = [
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(organizationsSortFields.name)}
                >
                    <Typography
                        className={
                            organizationsQueryParams.sort_field ===
                                organizationsSortFields.name && 'active'
                        }
                    >
                        COMPANY NAME
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            organizationsQueryParams.sort_field ===
                            organizationsSortFields.name
                        }
                        order={organizationsQueryParams?.sort_order}
                    />
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'USERS',
            dataIndex: 'users_length',
            key: 'users_length',
        },
        {
            title: 'INVITES',
            dataIndex: 'invites_length',
            key: 'invites_length',
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() =>
                        handleSort(organizationsSortFields.created_at)
                    }
                >
                    <Typography
                        className={
                            organizationsQueryParams.sort_field ===
                                organizationsSortFields.created_at && 'active'
                        }
                    >
                        DATE CREATED
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            organizationsQueryParams.sort_field ===
                            organizationsSortFields.created_at
                        }
                        order={organizationsQueryParams?.sort_order}
                    />
                </div>
            ),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => {
                return moment.utc(created_at).local().format('LL');
            },
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <div className='actions-cell'>
                        <OrganizationActionsDropdown organization={record} />
                    </div>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            expandable={{
                expandedRowRender: (record) => {
                    return <AdminUsersExpandedRow record={record} />;
                },
                expandRowByClick: true,
                defaultExpandedRowKeys: expandedRowKeys,
                expandedRowKeys: expandedRowKeys,
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <div
                            className={classes.expandIcon}
                            onClick={(e) => {
                                onExpand(record, e);
                                setExpandedRowKeys([]);
                            }}
                        >
                            <SiderChevronUpIcon
                                color={colorPalette.colorPrimary}
                            />
                        </div>
                    ) : (
                        <div
                            className={classes.expandIcon}
                            onClick={(e) => {
                                onExpand(record, e);
                                setExpandedRowKeys([record?.key]);
                            }}
                        >
                            <SiderChevronDownIcon color={iconColor} />
                        </div>
                    ),
            }}
            dataSource={organizationsData?.map((organization) => ({
                ...organization,
                key: organization?.id,
                users_length: organization?.users?.length,
                invites_length: organization?.invites?.length,
            }))}
            loading={{
                indicator: <Loader />,
                spinning: isLoading,
            }}
            bordered
            pagination={{
                pageSize: organizationsQueryParams?.per_page || 20,
                defaultCurrent: organizationsQueryParams?.page || 1,
                current: organizationsQueryParams?.page || 1,
                itemRender: renderPaginationArrows,
                total:
                    totalPages * (organizationsQueryParams?.per_page || 20) ||
                    0,
                showSizeChanger: true,
            }}
            onChange={handleChange}
            rowClassName={(record, _) => {
                return record?.key === expandedRowKeys?.[0]
                    ? 'expanded-row'
                    : '';
            }}
            scroll={{
                x: true,
            }}
        />
    );
};

export default AdminOrganizationsTable;
