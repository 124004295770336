import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { selectErrorStatus } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { setErrorStatus } from '../../../userBrowserSettings/store/browserSettings.actions';

import { statusCodes } from '../../../constants/statusCodes';

import ForbiddenPage from '../../pages/error/ForbiddenPage';
import NotFoundPage from '../../pages/error/NotFoundPage';

const ErrorsHandeledLayout = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const errorStatus = useSelector(selectErrorStatus);

    useEffect(() => {
        dispatch(setErrorStatus(null));

        // eslint-disable-next-line
    }, [location.pathname]);

    const getErrorPage = () => {
        switch (errorStatus) {
            case statusCodes.forbidden:
                return <ForbiddenPage />;
            case statusCodes.notFound:
                return <NotFoundPage />;
            default:
                return <NotFoundPage />;
        }
    };

    return <>{errorStatus ? getErrorPage() : <Outlet />}</>;
};

export default ErrorsHandeledLayout;
