export const alignments = {
    left: {
        key: 'left',
        margin: '0px auto 0px 0px',
    },
    center: {
        key: 'center',
        margin: '0px auto',
    },
    right: {
        key: 'right',
        margin: '0px 0px 0px auto',
    },
    justify: {
        key: 'justify',
        margin: '0px auto',
    },
};
