import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popconfirm, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { subscriptionLimits } from '../../../../../constants/subscriptionLimits';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { invitesPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import { decreaseSubscriptionLimits } from '../../billing/store/billingSettings.actions';
import { deleteInvite } from '../../store/accountSettings.actions';

const { Link } = Typography;

const classes = {
    deletePopconfirm: 'delete-popconfirm',
    link: 'link-hover-highlight space-no-wrap',
};

const DeleteInvitePopConfirm = ({ inviteId }) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleDelete = () => {
        setIsDeleteLoading(true);

        axiosAPI
            .delete(`${invitesPathes.deleteInvite}${inviteId}`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (result?.status === statusCodes.noContent) {
                    dispatch(deleteInvite(inviteId));
                    dispatch(
                        decreaseSubscriptionLimits({
                            limit: subscriptionLimits.userSeatsUsed,
                            value: 1,
                        })
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });
    };

    return (
        <Popconfirm
            placement='topRight'
            title='Are you sure you want to delete?'
            onConfirm={handleDelete}
            okText='Yes'
            cancelText='Cancel'
            loading={isDeleteLoading}
            overlayClassName={`${classes.deletePopconfirm}
                               ${darkMode ? 'delete-popconfirm-dark' : ''}`}
        >
            <Link
                underline
                className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
            >
                Delete
            </Link>
        </Popconfirm>
    );
};

export default DeleteInvitePopConfirm;
