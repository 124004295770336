import { Editor, Element as SlateElement, Transforms } from 'slate';

import { getOperationSystem } from '../../../../../../../../../utils/helpers/operationSystem';

export const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+e': 'code',
};

export const SECTIONS_HOTKEYS = {
    'mod+shift+8': 'unordered_list',
    'mod+shift+7': 'ordered_list',
    'mod+k': 'link',
    'mod+p': 'image',
};

export const UNORDERED_LIST = 'unordered_list';
export const ORDERED_LIST = 'ordered_list';
export const LIST_ITEM = 'list-item';
export const LIST_TYPES = [UNORDERED_LIST, ORDERED_LIST];
export const LIST_ELEMENTS = [UNORDERED_LIST, ORDERED_LIST, LIST_ITEM];

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const Leaf = ({
    attributes,
    children,
    leaf,
    highlightKeywords,
    darkMode,
}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = (
            <code
                className={
                    leaf.highlight
                        ? leaf?.highlightColor
                            ? `highlight-selected-keyword ${
                                  darkMode ? 'dark-mode' : ''
                              }`
                            : darkMode
                            ? 'highlight-keyword-dark-mode dark-mode'
                            : 'highlight-keyword'
                        : ''
                }
            >
                {children}
            </code>
        );
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return highlightKeywords ? (
        <span
            {...attributes}
            {...(leaf.highlight && { 'data-cy': 'search-highlighted' })}
            className={
                leaf.highlight
                    ? leaf?.highlightColor
                        ? 'highlight-selected-keyword'
                        : darkMode
                        ? 'highlight-keyword-dark-mode'
                        : 'highlight-keyword'
                    : ''
            }
        >
            {children}
        </span>
    ) : (
        <span {...attributes}>{children}</span>
    );
};

export const isMarkActive = (editor, format) => {
    const marks = Editor?.marks(editor);

    return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor?.removeMark(editor, format);
    } else {
        Editor?.addMark(editor, format, true);
    }
};

export const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;

    if (!selection) return false;

    const [match] = Array.from(
        Editor?.nodes(editor, {
            at: Editor?.unhangRange(editor, selection),
            match: (n) =>
                !Editor?.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    );

    return !!match;
};

export const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor?.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    });

    let newProperties = {};

    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        };
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };

        Transforms.wrapNodes(editor, block, {
            match: (n) => n?.type === 'list-item',
        });
    }
};

export const WINDOWS_HOTKEYS = {
    Bold: 'Ctrl+B',
    Italic: 'Ctrl+I',
    Underline: 'Ctrl+U',
    Code: 'Ctrl+E',
    Undo: 'Ctrl+Z',
    Redo: 'Ctrl+Shift+Z',

    'Bulleted list': 'Ctrl+Shift+8',
    'Numbered list': 'Ctrl+Shift+7',
    'Insert link': 'Ctrl+K',
    'Insert image': 'Ctrl+P',
    'Toggle left align': 'Ctrl+Shift+L',
    'Toggle center align': 'Ctrl+Shift+E',
    'Toggle right align': 'Ctrl+Shift+R',
    'Toggle justify align': 'Ctrl+Shift+J',
};

export const IOS_HOTKEYS = {
    Bold: '⌘+B',
    Italic: '⌘+I',
    Underline: '⌘+U',
    Code: '⌘+E',
    Undo: '⌘+Z',
    Redo: '⌘+Shift+Z',

    'Bulleted list': '⌘+Shift+8',
    'Numbered list': '⌘+Shift+7',
    'Insert link': '⌘+K',
    'Insert image': '⌘+P',
    'Toggle left align': '⌘+Shift+L',
    'Toggle center align': '⌘+Shift+E',
    'Toggle right align': '⌘+Shift+R',
    'Toggle justify align': '⌘+Shift+J',
};

export const getButtonHotkeyLabel = (buttonName) => {
    if (!buttonName) {
        return null;
    }

    if (getOperationSystem() === 'Windows') {
        return WINDOWS_HOTKEYS[buttonName] || null;
    } else {
        return IOS_HOTKEYS[buttonName] || null;
    }
};

export const disableEditorList = (event, editor, selectedElement) => {
    const { selection } = editor;

    if (
        selectedElement?.type === LIST_ITEM
        && (
            !selectedElement?.children[0]?.text
            || (selection?.focus?.offset === 0 && selection?.focus?.path[1] === 0)
        )
    ) {
        if (isBlockActive(editor, UNORDERED_LIST)) {
            event.preventDefault();

            return toggleBlock(editor, UNORDERED_LIST);
        }

        if (isBlockActive(editor, ORDERED_LIST)) {
            event.preventDefault();

            return toggleBlock(editor, ORDERED_LIST);
        }

        return;
    }
};

export const isListCornerPoint = (selectedBlock, previousSelectedBlock) => {
    return ((
        LIST_ELEMENTS.includes(selectedBlock?.type)
        && !LIST_TYPES.includes(previousSelectedBlock?.type)
    ) || (
        LIST_TYPES.includes(previousSelectedBlock?.type)
        && !LIST_ELEMENTS.includes(selectedBlock?.type)
    ));
};

export const isSelectedRangeEqual = (selection) => {
    const { anchor, focus } = selection;
    return JSON.stringify(anchor?.path) === JSON.stringify(focus?.path);
}

export const defaultInitialValue = [
    {
        children: [
            {
                text: '',
            },
        ],
        type: 'paragraph',
    },
];

export const isSelectedElementHasType = (editor, elementType = 'paragraph') => {
    const [tableNode] = Editor.nodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === elementType,
        mode: 'highest',
    });

    return tableNode ? true : false;
};
