import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import SiderRankabilitySmallLightLogoIcon from '../../../../resources/icons/SiderRankabilitySmallLightLogo';
import SiderRankabilitySmallDarkLogoIcon from '../../../../resources/icons/SiderRankabilitySmallDarkLogo';
import RankabilityLightLogoIcon from '../../../../resources/icons/RankabilityLightLogoIcon';
import RankabilityDarkLogoIcon from '../../../../resources/icons/RankabilityDarkLogoIcon';

import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import './SiderHeader.scss';

const classes = {
    siderLogoWrapper: 'sider-logo d-flex flex-center cursor-pointer',
};

const SiderLogo = ({ collapsed }) => {
    const darkMode = useSelector(selectDarkMode);

    const organizationSlug = useOrganizationSlug();

    const getLogo = () => {
        if (!darkMode) {
            if (collapsed) {
                return <SiderRankabilitySmallLightLogoIcon />;
            }

            return <RankabilityLightLogoIcon />;
        } else {
            if (collapsed) {
                return <SiderRankabilitySmallDarkLogoIcon />;
            }

            return <RankabilityDarkLogoIcon />;
        }
    };

    return (
        <Link
            to={`/${organizationSlug}/home`}
            className={`${classes.siderLogoWrapper} +
                        ${collapsed ? 'sider-logo-small' : ''}`}
        >
            {getLogo()}
        </Link>
    );
};

export default SiderLogo;
