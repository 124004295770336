import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Link from 'antd/es/typography/Link';
import moment from 'moment';

import { Layout, Typography, theme } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectContentOptimizerViewOnlyMode } from '../viewOnly/store/viewOnlySettings.selectors';
import { selectCurrentContentOptimizer } from './store/contentOptimizerContent.selectors';
import { selectContentOptimizerTabKey } from '../mainPage/store/contentOptimizerMain.selectors';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';
import { contentOptimizerContentTabValues } from './contentOptimizerContentConstants';
import { contentOptimizerMainTabs } from '../../../../constants/contentOptimizerMainTabs';
import { contentOptimizerLSTabKey } from '../../../../constants/contentOptimizer';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import CustomHeader from '../../../common/header/Header';
import CommonPageLayout from '../../../common/layout/CommonPageLayout';
import NotificationCard from '../../../common/notifications/NotificationCard';

import ContentOptimizerHeaderContent from './content/ContentOptimizerHeaderContent';
import ContentOptimizerTopContent from './content/ContentOptimizerTopContent';
import FireworksContainer from './content/FireworksContainer';
import BriefTabContainer from './content/briefTab/BriefTabContainer';
import CompetitorsTabContainer from './content/competitorsTab/CompetitorsTabContainer';
import OptimizerTabContainer from './content/optimizerTab/OptimizerTabContainer';

import './ContentOptimizerContentPage.scss';

import { clearContentBriefsSettings } from './content/briefTab/content/tabsContent/store/contentBriefsOutline.actions';
import {
    clearContentOptimizerContent,
    setOptimizerLoading,
    updateCurrentContentOptimizer,
} from './store/contentOptimizerContent.actions';
import {
    setContentOptimizerForUpdate,
    setContentOptimizerTabKey,
    updateContentOptimizerMainTab,
} from '../mainPage/store/contentOptimizerMain.actions';
import { clearContentOptimizerEditor } from '../../../common/tiptapEditor/store/tiptapEditor.actions';

const { Content } = Layout;

const classes = {
    root: 'content-optimizer-content-page',
    content: 'content-optimizer-content',
    contentDark: 'container-dark',
    warningNotification: 'optimizer-date-warning-notification',
    infoNotification: 'optimizer-info-notification',
    contentBelowWarningContainer: 'optimizer-below-warning-content-container',
    title: 'title',
};

const customOptimizerPageLayoutStyle = { overflowY: 'hidden' };

const ContentOptimizerContentPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { contentOptimizerId } = useParams();

    const darkMode = useSelector(selectDarkMode);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const currentContentOptimizer = useSelector(selectCurrentContentOptimizer);
    const currentTabKey = useSelector(selectContentOptimizerTabKey);

    const organizationSlug = useOrganizationSlug();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        let key;

        if (viewOnlyMode) {
            key = contentOptimizerContentTabValues.optimizer.value;
            localStorage.setItem(
                contentOptimizerLSTabKey,
                contentOptimizerContentTabValues.optimizer.value
            );
        }

        const coTabKey =
            localStorage.getItem(contentOptimizerLSTabKey) ||
            contentOptimizerContentTabValues.brief.value;

        localStorage.setItem(contentOptimizerLSTabKey, coTabKey);

        key = coTabKey;

        dispatch(setContentOptimizerTabKey(key));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewOnlyMode]);

    useEffect(() => {
        if (contentOptimizerId) {
            dispatch(setOptimizerLoading(true));

            axiosAPI
                .get(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}`,
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(updateCurrentContentOptimizer(result?.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    dispatch(setOptimizerLoading(false));
                });
        }

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    useEffect(() => {
        return () => {
            batch(() => {
                dispatch(clearContentBriefsSettings());
                dispatch(clearContentOptimizerContent());
                dispatch(clearContentOptimizerEditor());
            });
        };

        // eslint-disable-next-line
    }, []);

    const handleNewSearchClick = () => {
        batch(() => {
            dispatch(
                setContentOptimizerForUpdate(
                    currentContentOptimizer.content_brief.keywords
                )
            );
            dispatch(
                updateContentOptimizerMainTab(
                    contentOptimizerMainTabs.single.key
                )
            );
        });
        navigate(`/${organizationSlug}/optimizer`);
    };

    const notificationCardContent = (
        <Typography
            className={`${classes.title} ${darkMode ? 'dark-mode' : ''}`}
        >
            {`This Optimizer was created on ${moment(
                currentContentOptimizer?.created_at
            ).format(
                'MMM DD, YYYY'
            )} and may not reflect the most recent NLP and competitor data for "${
                currentContentOptimizer?.content_brief.keywords
            }". You should consider `}
            <Link onClick={handleNewSearchClick}>running a new Optimizer</Link>{' '}
            for fresh data.
        </Typography>
    );

    const openContactSupportModal = () => {
        window.Beacon && window.Beacon('open');
    };

    const notificationOldEditorContent = (
        <Typography
            className={`${classes.title} ${darkMode ? 'dark-mode' : ''}`}
        >
            We’re pleased to inform you that we have migrated to a new Optimizer
            editor. The new editor is much more stable and less buggy than our
            previous version. However, you may need to fix some minor structural
            changes in your article below that was created with our old editor.
            If you have any issues, please{' '}
            <Link onClick={openContactSupportModal}>Contact Support</Link>.
        </Typography>
    );

    const isTheOptimizerExpired = (createdAt) => {
        const threeMonthsAgo = moment().subtract(90, 'days');

        return threeMonthsAgo > moment(createdAt);
    };

    return (
        <>
            <CustomHeader
                extraBreadcrumbs={[<ContentOptimizerHeaderContent />]}
                // TODO: — Hide all “How To Use” buttons (temporary)
                //showUseSetting
                showShareSetting={!viewOnlyMode}
                showProjectSetting={!viewOnlyMode}
            />
            <CommonPageLayout customStyle={customOptimizerPageLayoutStyle}>
                {isTheOptimizerExpired(currentContentOptimizer?.created_at) && (
                    <div className={`${classes.warningNotification}`}>
                        <NotificationCard content={notificationCardContent} />
                    </div>
                )}

                <div
                    className={`${classes.infoNotification} d-none`}
                    data-editor-notification='true'
                >
                    <NotificationCard content={notificationOldEditorContent} />
                </div>

                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content} ${
                        isTheOptimizerExpired(currentContentOptimizer?.created_at)
                            ? classes.contentBelowWarningContainer
                            : ''
                    } ${darkMode ? `${classes.contentDark}` : ''}`}
                >
                    <ContentOptimizerTopContent />
                    {currentTabKey === contentOptimizerContentTabValues.brief.value &&
                        !viewOnlyMode && <BriefTabContainer />}
                    {currentTabKey === contentOptimizerContentTabValues.optimizer.value && (
                        <OptimizerTabContainer />
                    )}
                    {currentTabKey === contentOptimizerContentTabValues.competitors.value && (
                        <CompetitorsTabContainer />
                    )}
                </Content>
                <FireworksContainer />
            </CommonPageLayout>
        </>
    );
};

export default ContentOptimizerContentPage;
