import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import {
	selectOptimizersAdded,
	selectOptimizersLimit,
	selectOptimizersUsed,
} from '../../pages/account/billing/store/billingSettings.selectors';

const classes = {
	tooltipOverlay: 'tooltip-overlay',
	tooltipOverlayDark: 'tooltip-overlay-dark',
};

const RunOptimizerButton = ({
	isLoading,
	runBtnText,
	isDisabled,
	handleRunBrief,
}) => {
	const darkMode = useSelector(selectDarkMode);
	const optimizersLimit = useSelector(selectOptimizersLimit);
	const optimizersUsed = useSelector(selectOptimizersUsed);
	const optimizersAdded = useSelector(selectOptimizersAdded);

	const runButton = (
		<Button
			type='primary'
			size='large'
			loading={isLoading}
			onClick={handleRunBrief}
			className={classes.runBriefBtn}
			disabled={isDisabled}
		>
			{runBtnText || 'Run Brief'}
		</Button>
	);

	if (optimizersUsed < optimizersLimit + optimizersAdded) {
		return runButton;
	}

	return (
		<Tooltip
			title={
				'You have reached the limit of optimizers for your subscription plan. Please upgrade your subscription to run more briefs and optimizers.'
			}
			overlayClassName={`${classes.tooltipOverlay}
                                 ${darkMode ? classes.tooltipOverlayDark : ''}`}
		>
			{runButton}
		</Tooltip>
	);
};

export default RunOptimizerButton;
