export const EDITOR_AI_CREDITS_REQUIRED = 1;

export const EDITOR_AI_SELECTED_WORDS_LIMIT = 5000;

export const EDITOR_AI_MODEL_NAME = 'gpt-4o-2024-08-06';

export const aiSpellCheckerOptionKeys = {
    spellingGrammar: 'spelling-grammar',
    improveWriting: 'improve-writing',
    extend: 'extend',
    shorten: 'shorten',
    rephrase: 'rephrase',
    simplify: 'simplify',
    keyPoints: 'key-points',
    toneOfVoice: 'tone-of-voice',
    aiLanguage: 'ai-language',
    customPrompt: 'custom-prompt',
};
