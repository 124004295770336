import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DatePicker, Typography } from 'antd';
import moment from 'moment';

import { dateRangeFilterDropdownDateFormat } from '../../../../../../constants/dateConsts';

import CloseIcon from '../../../../../../resources/icons/CloseIcon';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../../utils/hooks/useOpen';

import CustomDropdown from '../../../../../common/dropdown/CustomDropdown';
import FilterPopoverButon from '../../../../../common/dropdown/advancedFilters/FilterPopoverButton';

import { setMonitorsQueryParams } from '../../store/contentMonitorMain.actions';
import { selectContentMonitorQueryParams } from '../../store/contentMonitorMain.selectors';

const { RangePicker } = DatePicker;

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay keyword-popover-overlay',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
    dropdownOverlay: 'dropdown-menu projects-dropdown-overlay',

    rangePicker: 'monitor-filter-range-picker',
    rangePickerDropdown: 'monitor-filter-range-picker-dropdown-content',
};

const defaultDateLabel = 'Date Created';

const DateRangeFilterDropdown = () => {
    const dispatch = useDispatch();

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);

    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);

    const [dateLabel, setDateLabel] = useState(defaultDateLabel);
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    const handleClear = () => {
        setSelectedDateRange(null);
        dispatch(setMonitorsQueryParams({ dateRangePicker: null }));
    };

    useEffect(() => {
        setDateLabel(
            monitorQueryParams?.dateRangePicker?.length > 0
                ? `${defaultDateLabel}: ${moment(
                      new Date(monitorQueryParams?.dateRangePicker[0])
                  ).format(dateRangeFilterDropdownDateFormat)} → ${moment(
                      new Date(monitorQueryParams?.dateRangePicker[1])
                  ).format(dateRangeFilterDropdownDateFormat)}`
                : defaultDateLabel
        );
    }, [monitorQueryParams?.dateRangePicker]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleApply = () => {
        dispatch(
            setMonitorsQueryParams({
                dateRangePicker:
                    selectedDateRange?.length > 0 ? selectedDateRange : null,
            })
        );

        handleOpen();
    };

    const handleChangeDate = (newDateRange) => {
        setSelectedDateRange(newDateRange);

        if (!newDateRange) {
            dispatch(
                setMonitorsQueryParams({
                    dateRangePicker: null,
                })
            );
        }
    };

    const usersFilterContent = (
        <div onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <RangePicker
                    value={selectedDateRange}
                    onChange={handleChangeDate}
                    className={`${classes.rangePicker} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    dropdownClassName={`${classes.rangePickerDropdown} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    separator={
                        <Typography
                            style={{ color: darkMode ? '#9d9d9f' : '#728192' }}
                        >
                            →
                        </Typography>
                    }
                    format={dateRangeFilterDropdownDateFormat}
                />
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div className={classes.popoverBtn}>
            <CustomDropdown
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={dateLabel}
                        showArrow
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                withExtraContent
                dropdownContent={usersFilterContent}
                dropdownOverlayClassName={classes.popoverOverlay}
                dropdownTriggersArray={['click']}
                dropdownPlacement='bottom'
                isDropdownOpen={isOpen}
                handleOpenChange={handleOpen}
            />
            {monitorQueryParams?.dateRangePicker && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default DateRangeFilterDropdown;
