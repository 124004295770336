import { useLocation } from 'react-router-dom';

export const useBreadcrumbItem = (breadcrumbPaths) => {
    const { pathname } = useLocation();

    const path =
        Object.keys(breadcrumbPaths).find((path) => pathname.includes(path)) ||
        '/';
    const name = breadcrumbPaths[path] || '';

    return {
        path: path === '/account' ? '/account/profile' : path,
        name,
    };
};
