import React from 'react';

import SiderSettings from '../bottomMenu/SiderSettings';

import NavMenu from './NavMenu';

const classes = {
    siderNavSettingsWrapper:
        'd-flex flex-column justify-content-between sider-nav-settings-wrapper',
};

const SiderList = ({ collapsed }) => {
    return (
        <div className={classes.siderNavSettingsWrapper}>
            <div>
                <NavMenu collapsed={collapsed} />
            </div>
            <SiderSettings collapsed={collapsed} />
        </div>
    );
};

export default SiderList;
