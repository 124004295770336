import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { authPathes } from '../../constants/queryPathes';

import { statusCodes } from '../../constants/statusCodes';
import { setUserData } from '../../userBrowserSettings/store/browserSettings.actions';

import { axiosAPI } from '../../utils/axiosAPI';

import PageLoadingSpin from '../common/PageLoadingSpin';

const withAuthenticatorProvider = (ComposedComponent) => {
    return memo((props) => {
        const dispatch = useDispatch();

        const [sessionLoading, setSessionLoading] = useState(true);

        /** getSession is not nessessary anymore since we always gonna request auth token before any other request
         *  so even if we have no cookies set, this request just fail, an user will be unauthenticated,
         *  but checkAuthToken will set cookies in every case
         *
         *  nevertheless, this will remove some server load and keyword research load should be faster
         * */
        useEffect(() => {
            axiosAPI
                .get(authPathes.checkAuthToken)
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setUserData(result?.data?.user));
                    }
                })
                .catch((_err) => {})
                .finally(() => {
                    setSessionLoading(false);
                });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        if (sessionLoading) {
            return <PageLoadingSpin />;
        }

        return (
            <div style={{ height: '100vh' }}>
                <ComposedComponent {...props} />
            </div>
        );
    });
};

export default withAuthenticatorProvider;
