import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Modal, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import AIGenerateIcon from '../../../../../resources/icons/ai/AIGenerateIcon';

import './AiResponseConfirmModal.scss';

const { Title } = Typography;

const classes = {
    modalWrapper: 'modal-wrapper ai-response-confirm-modal',
    modalWrapperDark: 'modal-wrapper-dark',
    title: 'ai-response-title',
    actionsWrapper: 'actions-wrapper',
};

const AiResponseConfirmModal = ({
    isOpen,
    handleCancel,
    handleDiscard,
    wrapClassName,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const wrapHandler = (handler) => {
        return () => {
            handleCancel();
            handler();
        };
    };

    return (
        <Modal
            centered
            width={400}
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
            wrapClassName={`${classes.modalWrapper} ${
                darkMode ? classes.modalWrapperDark : ''
            } ${wrapClassName}`}
            closable={false}
        >
            <div className={classes.title}>
                <AIGenerateIcon />
                <Title level={5}>
                    Would you like to discard the AI-generated content?
                </Title>
            </div>
            <div className={classes.actionsWrapper}>
                <Button onClick={wrapHandler(handleDiscard)} type='primary'>
                    Discard
                </Button>
                <Button onClick={handleCancel} type='primary' ghost>
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default AiResponseConfirmModal;
