import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import SearchInput from '../../../../../../../common/inputs/SearchInput';
import { updateKeywordsSearchKey } from '../../../../store/contentOptimizerContent.actions';

const OptimizerSearchInput = () => {
    const dispatch = useDispatch();

    const handleSearch = (text) => {
        dispatch(updateKeywordsSearchKey(text));
    };

    useEffect(() => {
        dispatch(updateKeywordsSearchKey(''));

        // eslint-disable-next-line
    }, []);

    return <SearchInput handleSearch={handleSearch} />;
};

export default OptimizerSearchInput;
