import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { Button, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { hTagsLines } from '../../../constants/hTagsLines';

import EditableOutlineButtons from './EditableOutlineButtons';

import './OutlineTags.scss';

const classes = {
    editableOutlineTag: 'editable-outline-tag',
    prefixTag: 'prefix-tag',
    editableOutlineTagWrapper: 'editable-outline-tag-wrapper',
    bulletedTag: 'bulleted-tag',
    tagSaveButtonWrapper: 'tag-save-button-wrapper',
    selectedOutlineTag: 'selected-outline-tag',
};

const EditableOutlineTag = ({
    outlineTagId,
    tag = 'h1',
    label,
    handleTagLabelUpdate,
    handleIncreaseTag,
    handleDecreaseTag,
    handleRemoveTag,
    handleAddTag,
    index,

    handleMoveUpTag,
    handleMoveDownTag,
    handleSetBulletTag,
    isBulletedTag,

    isSelectedTag,
    setSelectedTagIds,
}) => {
    const wrapperRef = useRef(null);
    const textareaRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const [isEditable, setIsEditable] = useState(false);
    const [newValue, setNewValue] = useState(label);

    useEffect(() => {
        let selectedElement;
        const keydownHandler = (event) => {
            if (!isEditable) {
                handleKeyDown(event);
            }
        };

        if (isEditable) {
            selectedElement?.removeEventListener('keydown', keydownHandler);
        } else if (isSelectedTag) {
            selectedElement = document.getElementById(`div-tag${outlineTagId}`);

            if (selectedElement) {
                selectedElement?.focus();
                selectedElement?.addEventListener('keydown', keydownHandler);
            }
        }

        return () => {
            if (selectedElement) {
                selectedElement?.removeEventListener('keydown', keydownHandler);
            }
        };

        // eslint-disable-next-line
    }, [isSelectedTag, isEditable]);

    useEffect(() => {
        if (isEditable) {
            textareaRef.current.focus();
        }

        // eslint-disable-next-line
    }, [isEditable]);

    const handleTagUpdate = (event) => {
        handleTagLabelUpdate({
            outlineTagId,
            label: event?.target?.value,
        });
    };

    const increaseTag = () => {
        handleIncreaseTag({ outlineTagId, tag, label });
    };

    const decreaseTag = () => {
        handleDecreaseTag({ outlineTagId, tag, label });
    };

    const addTag = () => {
        handleAddTag({ outlineTagId, tag, label, isBulletedTag });
    };

    const removeTag = () => {
        handleRemoveTag(outlineTagId);
    };

    const handleNewValueChange = (event) => {
        setNewValue(event?.target?.value);
    };

    const handleSetEditable = (event) => {
        event.stopPropagation();
        setIsEditable(true);
    };

    const handleSaveClick = () => {
        setIsEditable(false);
    };

    const moveUpTag = () => {
        handleMoveUpTag(index);
    };
    const moveDownTag = () => {
        handleMoveDownTag(index);
    };
    const setBulletTag = () => {
        handleSetBulletTag({
            id: outlineTagId,
            isBulletedTag: !isBulletedTag,
        });
    };

    const handleKeyDown = (event) => {
        if (!isEditable) {
            if (
                (event.shiftKey && event.keyCode === 9) ||
                event.key === 'ArrowLeft'
            ) {
                event.preventDefault();
                increaseTag();
            } else if (event.keyCode === 9 || event.key === 'ArrowRight') {
                event.preventDefault();
                decreaseTag();
            } else if (event.key === 'ArrowUp') {
                handleMoveUpTag();
            } else if (event.key === 'ArrowDown') {
                handleMoveDownTag();
            } else if (event.key === 'Enter') {
                addTag();
            } else if (
                event.shiftKey &&
                event.ctrlKey &&
                event.keyCode === 56
            ) {
                handleSetBulletTag({
                    id: null,
                    isBulletedTag: !isBulletedTag,
                });
            } else if (event.key === 'Backspace' || event.key === 'Delete') {
                handleRemoveTag();
            }
        }
    };

    const handleClickByTag = (event) => {
        if (event.detail === 1 && !event.ctrlKey && !event.shiftKey) {
            setSelectedTagIds([outlineTagId]);
        } else if (event.detail === 1 && (event.ctrlKey || event.shiftKey)) {
            event.preventDefault();
            setSelectedTagIds((prev) => [...prev, outlineTagId]);
        }
    };

    return (
        <Draggable key={outlineTagId} draggableId={outlineTagId} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div
                        id={`div-tag${outlineTagId}`}
                        tabIndex={0}
                        onClick={handleClickByTag}
                        onDoubleClick={handleSetEditable}
                        ref={wrapperRef}
                        onKeyDown={handleKeyDown}
                        className={`${classes.editableOutlineTagWrapper} ${
                            darkMode ? 'dark-mode' : ''
                        } ${isSelectedTag ? classes.selectedOutlineTag : ''}`}
                    >
                        {hTagsLines[tag]}
                        <div className={`${classes.editableOutlineTag}`}>
                            {label && (
                                <div
                                    className={`${classes.prefixTag} ${tag} ${
                                        darkMode ? 'dark-mode' : ''
                                    } ${
                                        isBulletedTag ? classes.bulletedTag : ''
                                    }`}
                                >
                                    {isBulletedTag ? '•' : tag.toUpperCase()}
                                </div>
                            )}
                            {isEditable ? (
                                <TextArea
                                    ref={textareaRef}
                                    onChange={handleNewValueChange}
                                    onBlur={handleTagUpdate}
                                    value={newValue}
                                    className={`${tag} ${
                                        darkMode ? 'dark-mode' : ''
                                    }`}
                                    autoSize
                                    onFocus={(event) => {
                                        event.target.selectionStart =
                                            event.target.selectionEnd =
                                                event.target.value.length;
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                />
                            ) : (
                                <Typography
                                    className={`${tag} ${
                                        darkMode ? 'dark-mode' : ''
                                    }`}
                                >
                                    {newValue}
                                </Typography>
                            )}
                            {isEditable ? (
                                <div className={classes.tagSaveButtonWrapper}>
                                    <Button
                                        type='link'
                                        size='small'
                                        onClick={handleSaveClick}
                                    >
                                        Save
                                    </Button>
                                </div>
                            ) : (
                                <EditableOutlineButtons
                                    moveUpTag={moveUpTag}
                                    moveDownTag={moveDownTag}
                                    editTag={handleSetEditable}
                                    setBulletTag={setBulletTag}
                                    increaseTag={increaseTag}
                                    decreaseTag={decreaseTag}
                                    removeTag={removeTag}
                                    addTag={addTag}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default EditableOutlineTag;
