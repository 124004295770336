import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import FlagCountrySection from '../../mainPagesLists/FlagCountrySection';

const classes = {
    headerRoot: 'd-flex align-items-center header-content',
    mainTitle: 'maint-title',
    title: 'title',
    createSectionWrapper: 'create-section-wrapper',
};

const KeywordExtraBreadcrumb = ({ keyword, countryCode, languageCode }) => {
    const darkMode = useSelector(selectDarkMode);

    if (!keyword) {
        return null;
    }

    return (
        <div className={`${classes.headerRoot}`}>
            <Typography
                className={`${classes.title} ${darkMode ? 'dark-mode' : ''}`}
            >
                {keyword || ''}
            </Typography>
            <div className={classes.createSectionWrapper}>
                <FlagCountrySection
                    countryCode={countryCode}
                    languageCode={languageCode}
                />
            </div>
        </div>
    );
};

export default KeywordExtraBreadcrumb;
