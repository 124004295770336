export const ROUTES = {
    HOME: '/home',
    RESEARCH: '/research',
    OPTIMIZER: '/optimizer',
    MONITOR: '/monitor',
    MONITOR_CREATE_NEW: '/monitor/create-new',
    ACCOUNT: '/account',
    ACCOUNT_PLANS: '/account/plans',
    ACCOUNT_PLANS_CHECKOUT: '/account/plans/checkout',
    ACCOUNT_INVOICE: '/account/invoice',
    ACCOUNT_INVOICE_HISTORY: '/account/invoice-history',
    ACCOUNT_CREDITS: '/account/credits',
};

const PARAMS = {
    KEYWORD_ID: ':keywordId',
    CONTENT_OPTIMIZER_ID: ':contentOptimizerId',
    CONTENT_MONITOR_ID: ':contentMonitorId',
    ACCOUNT_TAB: ':accountTab',
    INVOICE_ID: ':invoiceId',
};

export const getResearchDetailRoute = (keywordId = PARAMS.KEYWORD_ID) =>
    `${ROUTES.RESEARCH}/${keywordId}`;

export const getOptimizerDetailRoute = (contentOptimizerId = PARAMS.CONTENT_OPTIMIZER_ID) =>
    `${ROUTES.OPTIMIZER}/${contentOptimizerId}`;

export const getMonitorDetailRoute = (contentMonitorId = PARAMS.CONTENT_MONITOR_ID) =>
    `${ROUTES.MONITOR}/${contentMonitorId}`;

export const getAccountTabRoute = (accountTab = PARAMS.ACCOUNT_TAB) =>
    `${ROUTES.ACCOUNT}/${accountTab}`;

export const getAccountInvoiceDetailRoute = (invoiceId = PARAMS.INVOICE_ID) =>
    `${ROUTES.ACCOUNT_INVOICE}/${invoiceId}`;
