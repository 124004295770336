import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const withRouterProvider = (ComposedComponent) => {
	class hoc extends React.Component {
		componentDidMount() {
			if (
				!window.location.href.includes(
					process.env.REACT_APP_REDIRECT_DEV_SUB_URL
				)
			) {
				console.clear();
				window.location.href = 'https://app.rankability.com';
			}
		}

		render() {
			return (
				<BrowserRouter basename={process.env.REACT_APP_REDIRECT_DEV_SUB_URL}>
					<ComposedComponent {...this.props} />
				</BrowserRouter>
			);
		}
	}

	return hoc;
};

export default withRouterProvider;
