import React from 'react';
import { useSelector } from 'react-redux';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import BriefCitationsSection from './briefSummary/BriefCitationsSection';

const classes = {
    sectionWrapper: 'section-wrapper',
    rightMenuWrapper: 'right-menu-wrapper section-wrapper',
    content: 'container content-briefs-keywords-container',
    contentDark: 'container-dark content-briefs-keywords-container-dark',
};

const BriefRecomedations = () => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.content}
        ${darkMode ? classes.contentDark : ''}`}
        >
            <div className={`${classes.rightMenuWrapper}`}>
                <BriefCitationsSection />
            </div>
        </div>
    );
};

export default BriefRecomedations;
