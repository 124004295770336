import SimularContentIcon from '../resources/icons/viewOnlyNavMenu/SimularContentIcon';
import SeoIcon from '../resources/icons/viewOnlyNavMenu/SeoIcon';
import CancellationIcon from '../resources/icons/viewOnlyNavMenu/CancellationIcon';
import UsersIcon from '../resources/icons/UsersIcon';

import { checkIsTechAdmin } from '../utils/helpers/checkIsGlobalTechAdmin';

export const globalAdminMenuItems = (userRoles) => {
    return [
        {
            label: 'Usage',
            key: 'usage',
            icon: SeoIcon,
            isDisabled: false,
            handleClick: null,
        },
        {
            label: 'Organizations',
            key: 'organizations',
            icon: SimularContentIcon,
            isDisabled: false,
            handleClick: null,
        },
        {
            label: 'Cancellations',
            key: 'cancellations',
            icon: CancellationIcon,
            isDisabled: false,
            handleClick: null,
        },
        ...(checkIsTechAdmin(userRoles) ? [
            {
                label: 'Quota',
                key: 'quota',
                icon: UsersIcon,
                isDisabled: false,
                handleClick: null,
            },
        ] : []),
    ]
};
