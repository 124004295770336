import React from 'react';

const ForbiddenLight = () => {
    return (
        <svg
            width='360'
            height='274'
            viewBox='0 0 360 274'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M0 241.508H360V241.329H0V241.508Z' fill='#C3CFE4' />
            <path
                d='M37.7704 248.363H61.6183V248.183H37.7704V248.363Z'
                fill='#C3CFE4'
            />
            <path
                d='M104.64 246.41H129.48V246.23H104.64V246.41Z'
                fill='#C3CFE4'
            />
            <path
                d='M54.7791 251.84H94.6793V251.66H54.7791V251.84Z'
                fill='#C3CFE4'
            />
            <path
                d='M292.061 252.5H323.16V252.321H292.061V252.5Z'
                fill='#C3CFE4'
            />
            <path d='M266.71 248H283.2V247.82H266.71V248Z' fill='#C3CFE4' />
            <path
                d='M140.856 250.891H208.302V250.711H140.856V250.891Z'
                fill='#C3CFE4'
            />
            <path
                d='M31.6188 5.74815C29.4516 5.74815 27.689 7.51144 27.689 9.67792V205.109C27.689 207.275 29.4516 209.039 31.6188 209.039H170.65C172.816 209.039 174.579 207.275 174.579 205.109V9.67792C174.579 7.51144 172.816 5.74815 170.65 5.74815H31.6188ZM170.65 209.219H31.6188C29.353 209.219 27.509 207.375 27.509 205.109V9.67792C27.509 7.4128 29.353 5.56816 31.6188 5.56816H170.65C172.915 5.56816 174.759 7.4128 174.759 9.67792V205.109C174.759 207.375 172.915 209.219 170.65 209.219Z'
                fill='#C3CFE4'
            />
            <path
                d='M187.353 5.74815C185.186 5.74815 183.424 7.51144 183.424 9.67792V205.109C183.424 207.275 185.186 209.039 187.353 209.039H326.384C328.55 209.039 330.314 207.275 330.314 205.109V9.67792C330.314 7.51144 328.55 5.74815 326.384 5.74815H187.353ZM326.384 209.219H187.353C185.087 209.219 183.244 207.375 183.244 205.109V9.67792C183.244 7.4128 185.087 5.56816 187.353 5.56816H326.384C328.65 5.56816 330.494 7.4128 330.494 9.67792V205.109C330.494 207.375 328.65 209.219 326.384 209.219Z'
                fill='#C3CFE4'
            />
            <path
                d='M106.99 239.096H145.971V241.328H106.99V239.096Z'
                fill='#C3CFE4'
            />
            <path
                d='M122.977 190.349L112.206 239.098H140.755L129.983 190.349C129.154 186.597 123.806 186.597 122.977 190.349Z'
                fill='#C3CFE4'
            />
            <path
                d='M132.124 200.026H120.836L118.107 212.379H134.853L132.124 200.026Z'
                fill='#F5F5F5'
            />
            <path
                d='M135.331 214.551H117.63L116.246 220.805H136.714L135.331 214.551Z'
                fill='#F5F5F5'
            />
            <path
                d='M52.212 241.328H58.0058L75.0525 146.002H69.2587L52.212 241.328Z'
                fill='#C3CFE4'
            />
            <path
                d='M203.872 241.328H209.665L226.712 146.002H220.918L203.872 241.328Z'
                fill='#C3CFE4'
            />
            <path
                d='M243.759 185.285H52.2123V150.642H243.759V185.285Z'
                fill='#C3CFE4'
            />
            <path
                d='M72.9793 183.758H53.1224L64.137 152.171H83.9931L72.9793 183.758Z'
                fill='#FAFAFA'
            />
            <path
                d='M112.693 183.758H92.8362L103.85 152.171H123.707L112.693 183.758Z'
                fill='#FAFAFA'
            />
            <path
                d='M152.407 183.758H132.55L143.564 152.171H163.421L152.407 183.758Z'
                fill='#FAFAFA'
            />
            <path
                d='M192.121 183.758H172.264L183.278 152.171H203.134L192.121 183.758Z'
                fill='#FAFAFA'
            />
            <path
                d='M231.834 183.758H211.977L222.991 152.171H242.848L231.834 183.758Z'
                fill='#FAFAFA'
            />
            <path
                d='M92.099 241.328H86.3052L69.2585 146.002H75.0523L92.099 241.328Z'
                fill='#C3CFE4'
            />
            <path
                d='M243.759 241.328H237.965L220.918 146.002H226.712L243.759 241.328Z'
                fill='#C3CFE4'
            />
            <path
                d='M270.72 238.401H321.84V241.328H270.72V238.401Z'
                fill='#C3CFE4'
            />
            <path
                d='M291.35 175.993L277.56 238.402H315L301.21 175.993C300.043 170.714 292.517 170.714 291.35 175.993Z'
                fill='#C3CFE4'
            />
            <path
                d='M303.682 187.163H288.878L285.3 203.363H307.26L303.682 187.163Z'
                fill='#F5F5F5'
            />
            <path
                d='M307.886 206.213H284.674L282.859 214.414H309.701L307.886 206.213Z'
                fill='#F5F5F5'
            />
            <path
                d='M333.36 140.167V161.264C333.36 162.678 331.986 163.683 330.638 163.256L26.1015 66.7439C25.2324 66.4689 24.6428 65.6625 24.6428 64.7517V43.6549C24.6428 42.2409 26.0165 41.2357 27.3644 41.6627L331.901 138.174C332.77 138.45 333.36 139.256 333.36 140.167Z'
                fill='#C3CFE4'
            />
            <path
                d='M309.394 154.917L326.16 160.23L316.861 135.018L300.095 129.704L309.394 154.917Z'
                fill='#FAFAFA'
            />
            <path
                d='M275.863 144.292L292.629 149.605L283.329 124.393L266.563 119.079L275.863 144.292Z'
                fill='#FAFAFA'
            />
            <path
                d='M242.331 133.663L259.097 138.977L249.798 113.764L233.032 108.451L242.331 133.663Z'
                fill='#FAFAFA'
            />
            <path
                d='M208.8 123.038L225.566 128.352L216.266 103.139L199.5 97.825L208.8 123.038Z'
                fill='#FAFAFA'
            />
            <path
                d='M175.268 112.41L192.034 117.723L182.734 92.5097L165.968 87.1968L175.268 112.41Z'
                fill='#FAFAFA'
            />
            <path
                d='M141.736 101.784L158.502 107.098L149.203 81.8847L132.437 76.5711L141.736 101.784Z'
                fill='#FAFAFA'
            />
            <path
                d='M108.205 91.1559L124.971 96.4688L115.671 71.2558L98.9055 65.9429L108.205 91.1559Z'
                fill='#FAFAFA'
            />
            <path
                d='M74.6734 80.5302L91.4393 85.8438L82.1398 60.6308L65.3739 55.3172L74.6734 80.5302Z'
                fill='#FAFAFA'
            />
            <path
                d='M41.142 69.902L57.9079 75.2148L48.6084 50.0026L31.8425 44.689L41.142 69.902Z'
                fill='#FAFAFA'
            />
            <path
                d='M279.114 14.945C276.604 13.2919 273.997 11.7684 271.308 10.3874C248.164 -1.50124 216.294 -4.32002 194.056 8.71054C180.812 16.4714 175.154 31.4539 165.304 42.724C152.912 56.9016 133.432 64.9728 116.16 72.9878C92.8359 83.8116 71.8781 95.9292 63.6895 119.945C56.8819 139.913 60.3581 163.538 76.7633 179.45C92.3686 194.586 113.117 205.943 134.019 213.221C152.007 219.483 168.591 219.127 181.929 205.741C190.639 197 195.64 185.676 201 174.809C207.061 162.521 214.951 147.184 228.624 140.929C241.671 134.96 258.355 137.199 272.216 133.351C295.112 126.996 311.368 106.468 313.921 84.3105C316.952 57.9938 302.642 30.4329 279.114 14.945Z'
                fill='#327EEF'
            />
            <g opacity='0.899994'>
                <path
                    d='M279.114 14.945C276.604 13.2919 273.997 11.7684 271.308 10.3874C248.164 -1.50124 216.294 -4.32002 194.056 8.71054C180.812 16.4714 175.154 31.4539 165.304 42.724C152.912 56.9016 133.432 64.9728 116.16 72.9878C92.8359 83.8116 71.8781 95.9292 63.6895 119.945C56.8819 139.913 60.3581 163.538 76.7633 179.45C92.3686 194.586 113.117 205.943 134.019 213.221C152.007 219.483 168.591 219.127 181.929 205.741C190.639 197 195.64 185.676 201 174.809C207.061 162.521 214.951 147.184 228.624 140.929C241.671 134.96 258.355 137.199 272.216 133.351C295.112 126.996 311.368 106.468 313.921 84.3105C316.952 57.9938 302.642 30.4329 279.114 14.945Z'
                    fill='white'
                />
            </g>
            <path
                d='M319.6 265.691C319.6 270.194 257.099 273.844 180 273.844C102.901 273.844 40.4002 270.194 40.4002 265.691C40.4002 261.188 102.901 257.539 180 257.539C257.099 257.539 319.6 261.188 319.6 265.691Z'
                fill='#F5F5F5'
            />
            <path
                d='M235.059 259.453H226.755V9.28777C226.755 7.93776 235.059 7.93776 235.059 9.28777V259.453Z'
                fill='#1F1F1F'
            />
            <path
                d='M235.059 103.23L226.755 104.902V93.4191H235.059V103.23Z'
                fill='black'
            />
            <path
                d='M286.365 100.351L169.871 91.6873C165.132 91.3352 161.543 87.1686 161.895 82.4295L166.761 17.0111C167.114 12.272 171.279 8.68355 176.018 9.03563L292.513 17.7001C297.252 18.0522 300.84 22.2181 300.488 26.9564L295.622 92.3763C295.269 97.1147 291.104 100.704 286.365 100.351Z'
                fill='white'
            />
            <path
                d='M175.373 10.0933C173.57 10.0933 171.84 10.7363 170.449 11.9344C168.914 13.257 167.987 15.088 167.839 17.0932L162.973 82.5117C162.664 86.669 165.794 90.3028 169.951 90.6117L286.446 99.2754C288.45 99.4238 290.399 98.7786 291.934 97.4567C293.468 96.1341 294.397 94.3017 294.545 92.2979L299.411 26.878C299.719 22.7214 296.589 19.0876 292.432 18.7787L175.938 10.1142C175.749 10.1005 175.561 10.0933 175.373 10.0933ZM287.015 101.457C286.772 101.457 286.529 101.448 286.285 101.43L169.791 92.7659C167.211 92.5744 164.855 91.3821 163.156 89.41C161.456 87.4379 160.627 84.9309 160.819 82.3511L165.684 16.9326C165.876 14.3529 167.067 11.997 169.04 10.2971C171.012 8.5986 173.518 7.76413 176.098 7.95997L292.592 16.6245C295.172 16.8167 297.529 18.0083 299.228 19.9797C300.926 21.9525 301.756 24.4588 301.565 27.0385L296.699 92.4585C296.508 95.0375 295.315 97.3941 293.344 99.0926C291.558 100.631 289.334 101.457 287.015 101.457Z'
                fill='#327EEF'
            />
            <path
                d='M207.424 37.5601L208.101 28.46L199.69 36.9848L207.424 37.5601ZM206.96 43.7924L192.325 42.7045L192.816 36.0971L208.745 19.7884L215.748 20.3096L214.426 38.0814L218.055 38.3514L217.592 44.5837L213.962 44.3137L213.561 49.7188L206.559 49.1975L206.96 43.7924Z'
                fill='#327EEF'
            />
            <path
                d='M228.746 36.2114C228.473 39.8934 228.611 42.4336 229.162 43.8297C229.712 45.2258 230.605 45.9695 231.841 46.0617C232.657 46.1222 233.384 45.8882 234.026 45.3604C234.666 44.8341 235.174 43.9629 235.549 42.7475C235.923 41.5329 236.208 39.6162 236.403 36.9998C236.688 33.16 236.555 30.5558 236.002 29.1856C235.449 27.8154 234.522 27.0825 233.22 26.986C231.892 26.8867 230.882 27.4893 230.191 28.7925C229.499 30.0964 229.017 32.5696 228.746 36.2114ZM220.779 35.5994C221.188 30.1036 222.463 26.3301 224.605 24.2824C226.747 22.2326 229.843 21.3592 233.894 21.6602C235.84 21.8049 237.42 22.1642 238.633 22.7366C239.847 23.3097 240.82 24.0095 241.551 24.8375C242.284 25.6655 242.845 26.5209 243.235 27.4029C243.626 28.2849 243.917 29.3008 244.109 30.4521C244.488 32.6502 244.59 34.9182 244.416 37.2582C244.026 42.5049 242.852 46.2784 240.896 48.5795C238.941 50.8806 235.793 51.8685 231.453 51.5459C229.021 51.3652 227.083 50.8317 225.642 49.9439C224.202 49.0569 223.055 47.8278 222.208 46.2568C221.589 45.1394 221.156 43.6432 220.905 41.7669C220.655 39.8913 220.612 37.8357 220.779 35.5994Z'
                fill='#327EEF'
            />
            <path
                d='M255.958 32.2055L248.465 30.2788C249.277 27.9064 250.628 26.1431 252.518 24.9875C254.41 23.8319 256.998 23.3769 260.285 23.6217C264.059 23.9025 266.735 24.8097 268.315 26.3404C269.892 27.8733 270.603 29.7043 270.445 31.834C270.352 33.0847 269.926 34.1898 269.166 35.1511C268.407 36.113 267.309 36.9244 265.872 37.5839C266.969 37.9432 267.8 38.3284 268.364 38.7402C269.28 39.4034 269.968 40.2379 270.429 41.2451C270.89 42.2502 271.07 43.4238 270.97 44.7652C270.845 46.4486 270.284 48.0304 269.289 49.5086C268.293 50.9889 266.935 52.084 265.216 52.7968C263.497 53.5082 261.29 53.763 258.594 53.5629C255.964 53.3678 253.913 52.9041 252.44 52.1733C250.967 51.4425 249.785 50.446 248.894 49.1824C248.003 47.9195 247.361 46.3679 246.97 44.5276L255.076 44.0596C255.272 45.6998 255.678 46.8582 256.296 47.5314C256.913 48.2061 257.735 48.5805 258.761 48.6568C259.839 48.7367 260.766 48.4091 261.541 47.6733C262.317 46.9382 262.754 45.9129 262.851 44.5974C262.951 43.2575 262.682 42.1919 262.047 41.4042C261.412 40.6173 260.502 40.1788 259.319 40.091C258.688 40.0442 257.808 40.137 256.68 40.3703L257.521 34.6614C257.963 34.7608 258.309 34.8191 258.559 34.8379C259.611 34.9163 260.514 34.6463 261.267 34.0271C262.02 33.4101 262.432 32.6404 262.499 31.7195C262.565 30.839 262.354 30.1161 261.868 29.5502C261.381 28.9857 260.677 28.6682 259.757 28.5998C258.81 28.5299 258.02 28.7589 257.385 29.2866C256.751 29.8144 256.275 30.7871 255.958 32.2055Z'
                fill='#327EEF'
            />
            <path
                d='M195.891 55.1473L207.065 55.9782L206.85 58.8596L199.855 58.3398L199.696 60.484L206.184 60.9671L205.98 63.7189L199.491 63.2358L199.293 65.8969L206.491 66.4326L206.264 69.4883L194.887 68.6416L195.891 55.1473Z'
                fill='#327EEF'
            />
            <path
                d='M213.35 61.9419L215.108 62.0729C215.299 62.0866 215.672 62.0535 216.227 61.9707C216.506 61.9361 216.742 61.8116 216.934 61.5985C217.126 61.3846 217.233 61.1297 217.255 60.8353C217.287 60.3989 217.174 60.0555 216.915 59.8006C216.656 59.5472 216.146 59.3917 215.386 59.3355L213.554 59.1987L213.35 61.9419ZM208.565 69.6574L209.569 56.1632L216.518 56.6794C217.807 56.7759 218.783 56.9595 219.448 57.2317C220.113 57.5031 220.632 57.9531 221.004 58.5824C221.377 59.2117 221.532 59.9561 221.468 60.8151C221.412 61.5639 221.204 62.1975 220.845 62.7173C220.485 63.2365 220.013 63.644 219.429 63.9392C219.057 64.1293 218.556 64.2697 217.925 64.3647C218.41 64.5677 218.759 64.7607 218.973 64.9429C219.119 65.0653 219.323 65.318 219.588 65.7032C219.853 66.0869 220.026 66.38 220.11 66.5845L221.838 70.6445L217.127 70.2946L215.204 66.0049C214.962 65.4497 214.736 65.0847 214.527 64.9083C214.242 64.6772 213.909 64.5469 213.529 64.5188L213.16 64.4914L212.753 69.9685L208.565 69.6574Z'
                fill='#327EEF'
            />
            <path
                d='M228.014 63.0317L229.771 63.1628C229.961 63.1765 230.335 63.1433 230.89 63.0605C231.169 63.026 231.405 62.9014 231.598 62.6883C231.789 62.4745 231.896 62.2196 231.918 61.9251C231.95 61.4888 231.837 61.1453 231.578 60.8905C231.32 60.637 230.809 60.4815 230.049 60.4253L228.217 60.2885L228.014 63.0317ZM223.228 70.7473L224.232 57.253L231.181 57.7693C232.47 57.8657 233.447 58.0493 234.111 58.3215C234.776 58.5929 235.295 59.0429 235.668 59.6722C236.04 60.3015 236.195 61.046 236.131 61.9049C236.075 62.6537 235.867 63.2873 235.508 63.8072C235.148 64.3263 234.676 64.7338 234.092 65.029C233.72 65.2191 233.219 65.3595 232.588 65.4545C233.073 65.6576 233.422 65.8505 233.637 66.0327C233.782 66.1558 233.987 66.4078 234.251 66.793C234.516 67.1768 234.689 67.4698 234.773 67.6743L236.502 71.7344L231.79 71.3845L229.868 67.0947C229.625 66.5396 229.399 66.1745 229.19 65.9981C228.905 65.767 228.572 65.6367 228.192 65.6086L227.823 65.5813L227.417 71.0583L223.228 70.7473Z'
                fill='#327EEF'
            />
            <path
                d='M241.659 65.3615C241.557 66.7244 241.738 67.7216 242.199 68.3545C242.662 68.9874 243.328 69.3366 244.2 69.4007C245.095 69.4676 245.811 69.2272 246.345 68.6814C246.879 68.1356 247.203 67.1068 247.315 65.5976C247.409 64.3268 247.222 63.38 246.753 62.7558C246.285 62.1316 245.612 61.7867 244.734 61.7212C243.894 61.6585 243.196 61.9062 242.642 62.4642C242.089 63.0215 241.761 63.9877 241.659 65.3615ZM237.491 65.0332C237.654 62.83 238.396 61.161 239.714 60.0256C241.032 58.888 242.787 58.402 244.978 58.5654C247.224 58.7317 248.91 59.464 250.035 60.76C251.161 62.056 251.642 63.7897 251.48 65.9627C251.364 67.5402 251.001 68.8139 250.396 69.7837C249.79 70.7528 248.964 71.4815 247.919 71.9682C246.874 72.4556 245.603 72.6428 244.106 72.5312C242.584 72.4182 241.343 72.0827 240.381 71.524C239.42 70.9645 238.669 70.138 238.127 69.0414C237.585 67.947 237.373 66.61 237.491 65.0332Z'
                fill='#327EEF'
            />
            <path
                d='M258.058 65.2661L259.815 65.3972C260.006 65.4108 260.379 65.3777 260.934 65.2949C261.214 65.2604 261.45 65.1358 261.642 64.9227C261.833 64.7088 261.941 64.454 261.962 64.1595C261.995 63.7232 261.882 63.3797 261.623 63.1248C261.364 62.8714 260.854 62.7159 260.093 62.6597L258.262 62.5229L258.058 65.2661ZM253.273 72.9816L254.276 59.4874L261.226 60.0036C262.515 60.1001 263.491 60.2837 264.155 60.5559C264.821 60.8273 265.339 61.2773 265.711 61.9066C266.084 62.5359 266.239 63.2804 266.175 64.1393C266.12 64.8881 265.912 65.5217 265.552 66.0416C265.193 66.5607 264.721 66.9682 264.137 67.2634C263.764 67.4535 263.263 67.5939 262.633 67.6889C263.117 67.892 263.466 68.0849 263.682 68.2671C263.826 68.3902 264.031 68.6422 264.295 69.0274C264.56 69.4112 264.734 69.7042 264.817 69.9087L266.546 73.9688L261.834 73.6188L259.912 69.3291C259.67 68.774 259.443 68.4089 259.234 68.2325C258.949 68.0014 258.616 67.8711 258.236 67.843L257.868 67.8156L257.461 73.2927L253.273 72.9816Z'
                fill='#327EEF'
            />
            <path
                d='M181.98 83.6095L182.896 71.2961L191.203 71.9138L191.094 73.3661L184.417 72.87L184.134 76.6831L189.912 77.1129L189.804 78.5666L184.025 78.1368L183.61 83.7305L181.98 83.6095Z'
                fill='#1F1F1F'
            />
            <path
                d='M194.032 78.4984C193.921 79.9823 194.233 81.1818 194.968 82.0941C195.702 83.007 196.671 83.5082 197.875 83.5982C199.101 83.6889 200.142 83.3332 200.999 82.5304C201.855 81.7269 202.342 80.5331 202.461 78.9484C202.535 77.9462 202.43 77.0584 202.147 76.2858C201.864 75.5133 201.412 74.8962 200.79 74.4362C200.169 73.9768 199.451 73.7154 198.64 73.655C197.486 73.5693 196.464 73.8926 195.574 74.6226C194.684 75.3542 194.169 76.6466 194.032 78.4984ZM192.353 78.3486C192.506 76.3046 193.173 74.7458 194.357 73.6708C195.541 72.5966 197 72.1235 198.736 72.2531C199.872 72.3374 200.877 72.6851 201.749 73.2964C202.622 73.907 203.26 74.7162 203.667 75.7235C204.073 76.7301 204.231 77.8497 204.14 79.0816C204.047 80.3301 203.712 81.4288 203.134 82.3763C202.557 83.3246 201.787 84.0179 200.826 84.455C199.865 84.8934 198.848 85.0727 197.78 84.9935C196.621 84.9064 195.605 84.5493 194.734 83.9222C193.864 83.2943 193.229 82.4786 192.831 81.4749C192.432 80.4712 192.273 79.4294 192.353 78.3486Z'
                fill='#1F1F1F'
            />
            <path
                d='M207.948 78.6225L211.451 78.8831C212.196 78.9385 212.783 78.9047 213.215 78.783C213.647 78.6592 213.984 78.4367 214.228 78.1141C214.472 77.7916 214.608 77.4316 214.637 77.0341C214.68 76.4517 214.505 75.957 214.11 75.5509C213.715 75.1441 213.062 74.9073 212.149 74.8396L208.252 74.5494L207.948 78.6225ZM205.808 85.3804L206.724 73.0669L212.183 73.4737C213.28 73.5551 214.106 73.7279 214.661 73.9907C215.215 74.2557 215.643 74.679 215.943 75.2644C216.243 75.8497 216.367 76.4812 216.318 77.1587C216.252 78.0328 215.915 78.7477 215.305 79.305C214.695 79.8623 213.793 80.1769 212.599 80.2525C213.015 80.4916 213.328 80.7205 213.536 80.938C213.98 81.411 214.39 81.9899 214.767 82.6768L216.659 86.1875L214.61 86.0349L213.171 83.3521C212.75 82.5774 212.4 81.9827 212.122 81.568C211.842 81.1533 211.587 80.8581 211.355 80.6831C211.123 80.5081 210.884 80.3807 210.636 80.3001C210.454 80.2475 210.154 80.205 209.733 80.1733L207.843 80.0329L207.437 85.5013L205.808 85.3804Z'
                fill='#1F1F1F'
            />
            <path
                d='M220.298 79.2802L222.96 79.4775C223.683 79.5322 224.204 79.5221 224.524 79.4501C224.948 79.3572 225.276 79.1772 225.506 78.9072C225.737 78.6365 225.869 78.2859 225.902 77.8553C225.932 77.4471 225.861 77.0792 225.688 76.7537C225.515 76.4283 225.251 76.1957 224.896 76.0539C224.54 75.9113 223.919 75.8076 223.035 75.7414L220.574 75.5585L220.298 79.2802ZM219.875 84.966L222.94 85.1943C223.467 85.2339 223.837 85.2411 224.053 85.2173C224.433 85.1777 224.755 85.0884 225.019 84.9516C225.282 84.8134 225.507 84.601 225.691 84.313C225.876 84.0264 225.983 83.6852 226.012 83.2935C226.046 82.8348 225.958 82.4273 225.748 82.0709C225.539 81.7145 225.23 81.4532 224.823 81.2832C224.416 81.1162 223.821 81.0032 223.037 80.9448L220.19 80.7332L219.875 84.966ZM218.137 86.2973L219.053 73.9846L223.672 74.328C224.613 74.3979 225.358 74.5793 225.908 74.8702C226.458 75.1625 226.874 75.5787 227.158 76.1201C227.441 76.6623 227.562 77.216 227.52 77.7819C227.481 78.3082 227.301 78.7928 226.981 79.2356C226.661 79.6798 226.202 80.0232 225.604 80.2659C226.332 80.5395 226.877 80.9542 227.239 81.51C227.6 82.0666 227.753 82.7031 227.7 83.4195C227.658 83.9969 227.496 84.5225 227.216 85.0006C226.935 85.478 226.606 85.8372 226.227 86.0799C225.848 86.3218 225.385 86.4917 224.834 86.5889C224.283 86.6854 223.616 86.7056 222.832 86.6465L218.137 86.2973Z'
                fill='#1F1F1F'
            />
            <path
                d='M229.955 87.1752L230.871 74.8618L232.5 74.9834L231.584 87.2969L229.955 87.1752Z'
                fill='#1F1F1F'
            />
            <path
                d='M236.194 86.1793L238.823 86.3744C239.634 86.4349 240.277 86.4061 240.75 86.2887C241.223 86.1728 241.607 85.987 241.902 85.7336C242.317 85.3758 242.657 84.8782 242.921 84.2425C243.185 83.6067 243.352 82.8241 243.421 81.8938C243.516 80.6065 243.379 79.5999 243.007 78.877C242.636 78.1549 242.157 77.653 241.57 77.373C241.145 77.1721 240.449 77.036 239.48 76.9633L236.894 76.7718L236.194 86.1793ZM234.456 87.5106L235.372 75.1971L239.614 75.5132C240.572 75.5838 241.297 75.6975 241.793 75.8523C242.486 76.0676 243.066 76.4067 243.533 76.8682C244.142 77.4658 244.575 78.2031 244.833 79.0815C245.092 79.9585 245.18 80.9463 245.098 82.0436C245.029 82.9796 244.859 83.7997 244.586 84.506C244.314 85.2123 243.99 85.7883 243.613 86.2362C243.236 86.6847 242.835 87.0289 242.408 87.2701C241.983 87.512 241.477 87.6805 240.893 87.7791C240.308 87.8749 239.644 87.8965 238.899 87.841L234.456 87.5106Z'
                fill='#1F1F1F'
            />
            <path
                d='M248.616 87.1051L251.244 87.3002C252.056 87.3607 252.699 87.3319 253.172 87.2145C253.645 87.0986 254.029 86.9128 254.324 86.6594C254.739 86.3015 255.078 85.8033 255.343 85.1683C255.607 84.5325 255.773 83.7499 255.843 82.8196C255.938 81.5323 255.801 80.5257 255.429 79.8028C255.058 79.0807 254.579 78.5788 253.991 78.2987C253.567 78.0979 252.871 77.9618 251.902 77.8891L249.316 77.6975L248.616 87.1051ZM246.878 88.4363L247.794 76.1229L252.036 76.439C252.993 76.5095 253.719 76.6233 254.214 76.7781C254.908 76.9934 255.487 77.3325 255.955 77.794C256.564 78.3916 256.997 79.1289 257.255 80.0073C257.514 80.8843 257.602 81.8721 257.521 82.9694C257.451 83.9054 257.28 84.7255 257.008 85.4318C256.736 86.1381 256.412 86.7141 256.035 87.1619C255.658 87.6105 255.257 87.9547 254.831 88.1959C254.405 88.4378 253.899 88.6063 253.315 88.7049C252.731 88.8007 252.066 88.8223 251.322 88.7668L246.878 88.4363Z'
                fill='#1F1F1F'
            />
            <path
                d='M259.334 89.3597L260.25 77.0463L269.153 77.7087L269.044 79.1616L261.77 78.6202L261.49 82.3915L268.302 82.8984L268.195 84.3434L261.383 83.8366L261.071 88.0277L268.631 88.5907L268.523 90.043L259.334 89.3597Z'
                fill='#1F1F1F'
            />
            <path
                d='M270.757 90.2122L271.672 77.8987L273.344 78.0233L279.092 88.1717L279.811 78.5042L281.373 78.6202L280.457 90.9336L278.786 90.8098L273.038 80.6527L272.318 90.3281L270.757 90.2122Z'
                fill='#1F1F1F'
            />
            <path
                d='M122.344 84.7182L122.581 86.1308L122.855 87.632C123.052 88.6343 123.244 89.643 123.471 90.6402C123.908 92.636 124.419 94.6081 124.994 96.519C125.283 97.4744 125.594 98.414 125.937 99.3234C126.267 100.239 126.62 101.135 127.012 101.982C127.789 103.685 128.673 105.24 129.652 106.468L127.68 105.175C128.143 105.409 128.583 105.603 129.328 105.557C130.05 105.523 131.021 105.253 132.016 104.761C133.026 104.283 134.074 103.614 135.109 102.849C135.626 102.464 136.149 102.062 136.655 101.635C136.913 101.425 137.166 101.208 137.415 100.99C137.66 100.784 137.936 100.532 138.128 100.377L138.517 100.068C139.671 99.1448 141.357 99.3328 142.28 100.488C142.935 101.309 143.029 102.399 142.616 103.29C142.405 103.745 142.254 104.083 142.063 104.463C141.873 104.828 141.679 105.189 141.476 105.545C141.062 106.25 140.623 106.946 140.134 107.621C139.162 108.974 138.024 110.261 136.634 111.447C135.943 112.041 135.159 112.584 134.306 113.088C133.453 113.592 132.504 114.042 131.441 114.351C130.386 114.667 129.236 114.878 128.033 114.893C126.831 114.911 125.616 114.713 124.462 114.379C123.797 114.187 123.222 113.832 122.769 113.371L122.491 113.085C120.505 111.055 119.014 108.857 117.816 106.634C116.598 104.413 115.644 102.158 114.842 99.89C114.022 97.6278 113.394 95.3432 112.848 93.0522C112.586 91.9031 112.337 90.7554 112.139 89.5933C112.038 89.0137 111.938 88.432 111.853 87.8437C111.769 87.2353 111.697 86.7018 111.619 85.9904C111.295 83.0262 113.434 80.3608 116.398 80.036C119.251 79.7236 121.829 81.6971 122.306 84.49L122.344 84.7182Z'
                fill='#1F1F1F'
            />
            <path
                d='M138 99.9784C136.48 100.459 134.7 102.218 135.26 104.279C135.82 106.339 141.98 107.898 142.18 104.179C140.4 100.639 138 99.9784 138 99.9784Z'
                fill='white'
            />
            <path
                d='M137.192 101.987L137.848 100.986L144.572 99.1683C144.572 99.1683 145.553 103.452 140.693 105.085C140.349 105.2 139.97 105.154 139.648 104.987L137.711 103.978C136.984 103.598 136.742 102.673 137.192 101.987Z'
                fill='#CE5B51'
            />
            <path
                d='M86.4555 77.4168C95.0552 75.8602 106.704 75.5614 115.149 77.0057C119.442 77.7401 122.471 81.6382 122.092 85.9776C121.607 91.5295 120.994 96.4313 120.674 100.28C121.057 110.041 120.225 126.357 120.225 129.767C120.224 129.77 84.451 130.273 84.451 130.273C82.8577 125.814 83.3797 105.739 80.5861 85.5283C80.0547 81.6842 82.6366 78.108 86.4555 77.4168Z'
                fill='#1F1F1F'
            />
            <g opacity='0.199997'>
                <path
                    d='M104.827 129.812L104.113 129.723C106.145 113.701 104.131 86.8609 104.11 86.5916L104.828 86.5369C104.849 86.8069 106.868 113.72 104.827 129.812Z'
                    fill='black'
                />
            </g>
            <path
                d='M108.073 77.8294C106.663 77.4464 107.934 75.9927 107.934 75.9927C107.934 75.9927 114.332 75.9927 118.754 76.8603C119.43 77.383 118.702 78.0483 118.702 78.0483C118.702 78.0483 112.261 78.9663 108.073 77.8294Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M106.051 75.7741C106.051 75.7741 108.173 80.0365 105.332 83.9116C107.583 83.9274 109.656 85.3516 109.656 85.3516C109.656 85.3516 110.67 81.6983 107.81 76.142C107.498 75.6172 106.051 75.7741 106.051 75.7741Z'
                    fill='black'
                />
            </g>
            <path
                d='M108.319 77.8398C108.871 80.4397 104.041 85.3516 104.041 85.3516C104.041 85.3516 95.498 80.8732 94.958 78.3028L108.319 77.8398Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M85.9381 90.101C86.8762 91.9118 85.7343 99.4962 82.346 103.227C82.1206 99.9318 81.8441 96.4859 81.4805 92.973L85.9381 90.101Z'
                    fill='black'
                />
            </g>
            <path
                d='M95.873 64.504L105.555 69.1473C104.526 71.8883 104.038 74.7395 106.906 76.0996C108.532 76.8714 109.274 78.994 108.305 80.6075C107.477 81.9899 106.073 83.1937 103.9 83.4105C100.54 83.7467 97.2281 81.5889 95.3014 79.5613C94.5058 78.7247 95.2855 77.7477 95.3885 76.5827C95.7521 72.4629 96.395 68.1501 95.873 64.504Z'
                fill='#CE5B51'
            />
            <g opacity='0.199997'>
                <path
                    d='M100.33 65.4281L105.551 69.1433C105.252 69.9324 105.003 70.7287 104.871 71.4977C102.727 72.199 99.3218 70.3291 99.2656 68.4629C99.2375 67.5326 100.112 65.8759 100.33 65.4281Z'
                    fill='black'
                />
            </g>
            <path
                d='M107.116 48.8603C108.962 50.3651 111.076 57.092 108.363 59.8086C104.357 55.1581 103.905 48.1633 107.116 48.8603Z'
                fill='#1F1F1F'
            />
            <path
                d='M93.1936 56.6499C93.9251 62.3898 94.0604 65.7889 97.1687 68.5314C101.843 72.657 108.792 69.4868 109.793 63.6289C110.693 58.3563 109.347 49.8553 103.549 47.9811C97.8361 46.1343 92.462 50.9101 93.1936 56.6499Z'
                fill='#CE5B51'
            />
            <path
                d='M93.3783 61.597C96.097 61.8 97.0445 55.2206 95.3475 51.4982C96.529 51.9878 99.8928 51.5069 100.832 49.9625C106.255 51.5069 108.285 51.5371 108.982 49.2958C109.679 47.0537 101.135 45.0838 96.1963 46.1443C91.2571 47.2049 92.7115 50.7804 92.7115 50.7804C92.7115 50.7804 84.3488 53.4768 93.3783 61.597Z'
                fill='#1F1F1F'
            />
            <path
                d='M91.3929 62.0386C92.2202 63.3425 93.4715 64.1496 94.5861 64.4167C96.2615 64.8178 96.8418 63.133 96.1607 61.4417C95.5473 59.9196 93.9359 57.877 92.3577 58.2334C90.8025 58.5847 90.4475 60.5489 91.3929 62.0386Z'
                fill='#CE5B51'
            />
            <path
                d='M100.871 57.6084C100.901 58.0772 101.175 58.4393 101.482 58.417C101.79 58.394 102.013 57.9951 101.983 57.5256C101.952 57.0555 101.679 56.6933 101.371 56.7171C101.065 56.7394 100.84 57.139 100.871 57.6084Z'
                fill='#1F1F1F'
            />
            <path
                d='M107.24 57.0927C107.271 57.5622 107.544 57.9243 107.852 57.9013C108.159 57.879 108.382 57.4794 108.352 57.0099C108.321 56.5405 108.048 56.1783 107.74 56.2014C107.434 56.2237 107.21 56.6233 107.24 57.0927Z'
                fill='#1F1F1F'
            />
            <path
                d='M106.211 57.5723C106.211 57.5723 107.456 60.2011 108.718 61.4207C107.937 62.2574 106.501 62.0083 106.501 62.0083L106.211 57.5723Z'
                fill='#BB221F'
            />
            <path
                d='M103.29 53.8934C103.322 53.9272 103.345 53.969 103.357 54.0165C103.393 54.1648 103.301 54.3131 103.152 54.3484C101.657 54.7026 100.716 53.8401 100.677 53.8026C100.565 53.6982 100.561 53.524 100.667 53.4138C100.772 53.3037 100.947 53.2986 101.058 53.4016C101.089 53.4304 101.83 54.0957 103.022 53.8134C103.123 53.789 103.224 53.8228 103.29 53.8934Z'
                fill='#1F1F1F'
            />
            <path
                d='M104.456 55.2575C104.416 55.2352 104.382 55.2021 104.356 55.1596C104.278 55.03 104.321 54.8601 104.453 54.7816C105.773 53.9953 106.928 54.5368 106.977 54.5605C107.115 54.6268 107.172 54.7917 107.104 54.9285C107.036 55.0645 106.871 55.1229 106.734 55.0566C106.696 55.0386 105.789 54.6261 104.737 55.2539C104.647 55.3065 104.541 55.3043 104.456 55.2575Z'
                fill='#1F1F1F'
            />
            <path
                d='M109.852 52.6397C109.766 52.6664 109.669 52.6513 109.596 52.5901C108.659 51.8153 107.704 52.0961 107.664 52.1091C107.518 52.1537 107.364 52.0724 107.32 51.9269C107.276 51.7815 107.357 51.6267 107.502 51.5799C107.555 51.5648 108.775 51.1904 109.949 52.1609C110.065 52.2574 110.082 52.4324 109.984 52.5505C109.948 52.5944 109.902 52.6246 109.852 52.6397Z'
                fill='#1F1F1F'
            />
            <path
                d='M141.235 124.367C140.921 123.786 140.19 123.568 139.609 123.882L130.801 128.642L126.638 120.939C126.324 120.359 125.592 120.14 125.011 120.453C124.43 120.768 124.212 121.499 124.526 122.08L128.689 129.783L98.3361 146.187C97.7557 146.5 97.5369 147.232 97.8508 147.813C98.1647 148.394 98.8962 148.612 99.4773 148.298L140.75 125.994C141.331 125.68 141.549 124.948 141.235 124.367Z'
                fill='#1F1F1F'
            />
            <path
                d='M140.59 164.848C126.205 154.047 81.8592 149.697 97.1808 129.981L120.225 129.766C120.225 129.766 152.656 146.403 154.393 163.798C156.2 181.886 159.407 210.377 159.407 210.377L150.481 213.547C150.481 213.547 137.447 178.612 140.59 164.848Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M97.974 139.88C97.4405 141.04 96.948 142.256 96.4958 143.524C100.08 147.595 107.155 150.727 114.81 153.637C112.474 149.459 107.243 144.855 97.974 139.88Z'
                    fill='black'
                />
            </g>
            <path
                d='M150.575 216.706L159.803 214.329C160.367 214.182 161.008 214.163 161.567 214.415C164.792 215.869 166.854 215.438 170.04 215.239C173.86 215 173 219.1 170.32 219.799C165.886 220.954 166.214 220.701 162.434 221.686C161.58 221.909 159.81 222.362 159.81 222.362L158.309 221.447L157.573 222.908C157.573 222.908 154.658 223.619 152.715 224.093C152.177 224.224 151.637 223.886 151.556 223.339L150.575 216.706Z'
                fill='#1F1F1F'
            />
            <path
                d='M150.813 217.617C150.948 217.553 161.336 214.645 161.336 214.645L159.726 206.659L148.181 209.786L150.813 217.617Z'
                fill='#1F1F1F'
            />
            <path
                d='M86.2339 189.069C86.6702 162.833 81.1601 136.131 84.4512 130.271L111.178 129.893C111.178 129.893 103.189 173.632 101.078 191.107C98.8864 209.278 88.116 256.526 88.116 256.526L79.4983 256.66C79.4983 256.66 80.5171 207.901 86.2339 189.069Z'
                fill='#327EEF'
            />
            <path
                d='M79.1756 258.053L87.1086 257.93C87.6903 257.92 88.3218 258.053 88.8157 258.431C91.6654 260.608 91.9995 260.678 95.1574 261.239C98.9439 261.912 97.2425 265.692 94.4741 265.736C89.895 265.808 90.2686 265.639 86.3648 265.7C85.4828 265.715 82.124 265.736 82.124 265.736L81.5062 264.491L80.8107 265.736H79.694C79.1518 265.736 78.709 265.265 78.7436 264.726L79.1756 258.053Z'
                fill='#1F1F1F'
            />
            <path
                d='M79.1448 258.994C79.2794 258.964 89.1153 259.449 89.1153 259.449L89.7691 250.46L79.0814 250.763L79.1448 258.994Z'
                fill='#1F1F1F'
            />
            <path
                d='M105.699 75.2628C105.699 75.2628 108.173 80.0378 105.333 83.9129C107.583 83.9287 110.048 84.8906 110.048 84.8906C110.048 84.8906 112.054 80.0328 107.629 75.6847C107.316 75.1591 105.699 75.2628 105.699 75.2628Z'
                fill='white'
            />
            <path
                d='M83.471 133.019C96.2495 133.784 110.904 134.019 120.068 133.018C120.169 133.007 120.262 132.939 120.32 132.819C120.894 131.641 121.175 129.617 121.11 127.93C121.1 127.67 120.942 127.475 120.762 127.483C108.667 127.952 96.6484 127.928 84.0131 127.478C83.8706 127.473 83.7381 127.595 83.6855 127.786C83.2816 129.265 83.067 130.872 83.1434 132.561C83.1549 132.81 83.2989 133.009 83.471 133.019Z'
                fill='#1F1F1F'
            />
            <g opacity='0.199997'>
                <path
                    d='M83.471 133.019C96.2495 133.784 110.904 134.019 120.068 133.018C120.169 133.007 120.262 132.939 120.32 132.819C120.894 131.641 121.175 129.617 121.11 127.93C121.1 127.67 120.942 127.475 120.762 127.483C108.667 127.952 96.6484 127.928 84.0131 127.478C83.8706 127.473 83.7381 127.595 83.6855 127.786C83.2816 129.265 83.067 130.872 83.1434 132.561C83.1549 132.81 83.2989 133.009 83.471 133.019Z'
                    fill='black'
                />
            </g>
            <path
                d='M103.363 133.47C104.413 133.564 106.112 133.639 107.217 133.401C107.447 131.527 107.428 129.068 107.403 127.909C106.572 127.878 105.045 127.851 103.446 127.979C103.334 130.052 103.347 132.377 103.363 133.47ZM105.268 135C103.885 135 102.649 134.844 102.562 134.833L101.949 134.753L101.934 134.136C101.93 133.979 101.842 130.26 102.046 127.271L102.087 126.667L102.689 126.604C105.407 126.316 108.043 126.498 108.155 126.506L108.796 126.552L108.822 127.194C108.83 127.365 108.99 131.415 108.577 134.078L108.511 134.503L108.105 134.646C107.333 134.919 106.262 135 105.268 135Z'
                fill='white'
            />
            <path
                d='M91.3823 127.467C91.3823 127.467 91.1742 131.505 90.8819 134.076C90.3117 134.664 89.249 134.214 89.249 134.214C89.249 134.214 89.4606 130.481 89.7134 127.551C90.568 127.276 91.3823 127.467 91.3823 127.467Z'
                fill='#327EEF'
            />
            <path
                d='M117.763 127.467C117.763 127.467 117.555 131.505 117.262 134.076C116.692 134.664 115.63 134.214 115.63 134.214C115.63 134.214 115.841 130.481 116.094 127.551C116.949 127.276 117.763 127.467 117.763 127.467Z'
                fill='#327EEF'
            />
            <path
                d='M109.412 91.0105C109.8 96.2161 113.119 97.3882 113.585 97.3789C114.05 97.3688 117.185 96.0641 116.797 90.8578C115.473 90.8945 114.218 90.3207 112.994 89.4524C111.976 90.4467 110.796 91.0025 109.412 91.0105Z'
                fill='#327EEF'
            />
            <path
                d='M108.723 48.3849C112.424 49.0048 113.82 50.73 105.485 51.7394C97.1514 52.7496 90.9717 51.9835 90.9717 51.9835C90.9717 51.9835 102.083 48.354 108.723 48.3849Z'
                fill='#1F1F1F'
            />
            <path
                d='M90.2988 52.418C90.2988 52.418 97.5478 49.9649 108.973 48.6293C109.161 46.3268 107.363 44.6441 107.363 44.6441C107.363 44.6441 111.582 43.8889 109.486 42.6634C108.049 41.8232 104.606 41.7289 100.8 39.2801C99.2398 40.7057 94.9594 43.0205 92.0376 43.3172C91.3133 44.5549 89.4262 47.6544 86.3661 48.8518C86.4101 50.2032 89.4528 50.5532 89.4528 50.5532C89.4528 50.5532 89.3095 51.4632 90.2988 52.418Z'
                fill='#327EEF'
            />
            <g opacity='0.800003'>
                <path
                    d='M89.4525 50.5532C95.651 46.5269 101.596 44.7816 107.363 44.6448C107.363 44.6448 109.161 46.3268 108.974 48.6293C97.5475 49.9649 90.2985 52.418 90.2985 52.418C89.3093 51.4632 89.4525 50.5532 89.4525 50.5532Z'
                    fill='#1F1F1F'
                />
            </g>
            <path
                d='M100.446 43.5443C101.161 47.0845 103.665 47.7303 104.001 47.7023C104.338 47.6735 106.501 46.6352 105.786 43.0943C104.83 43.1814 103.872 42.848 102.913 42.3116C102.258 43.0381 101.449 43.4737 100.446 43.5443Z'
                fill='white'
            />
            <path
                d='M104.65 54.6095C105.473 54.7247 106.818 60.9023 102.529 61.2335C98.9235 61.5114 97.8003 58.2059 97.7996 57.0532C97.7988 55.3252 103.488 54.446 104.65 54.6095Z'
                fill='#1F1F1F'
            />
            <path
                d='M106.47 54.5356C105.958 54.8474 107.105 61.4267 110.101 60.7341C112.62 60.1523 112.196 56.5747 111.786 55.4205C111.172 53.6896 107.191 54.0957 106.47 54.5356Z'
                fill='#1F1F1F'
            />
            <g opacity='0.199997'>
                <path
                    d='M103.136 54.6746L105.376 58.7541C105.186 59.5324 104.808 60.2452 104.139 60.7031L101.049 55.0526C101.768 54.8783 102.511 54.748 103.136 54.6746Z'
                    fill='white'
                />
                <path
                    d='M100.14 55.3057L103.311 61.0938C103.078 61.1673 102.816 61.2105 102.533 61.2321C102.35 61.2472 102.183 61.2544 102.016 61.2472L99.0052 55.7565C99.34 55.5901 99.7252 55.444 100.14 55.3057Z'
                    fill='white'
                />
                <path
                    d='M110.095 54.275L112.08 57.8958C112.037 58.5208 111.899 59.1472 111.585 59.6562L108.575 54.1504C109.084 54.1432 109.615 54.1799 110.095 54.275Z'
                    fill='white'
                />
                <path
                    d='M111.2 60.149C111.026 60.3232 110.815 60.4687 110.56 60.5781L107.128 54.3098C107.36 54.2587 107.63 54.2227 107.928 54.1867L111.2 60.149Z'
                    fill='white'
                />
            </g>
            <path
                d='M143.838 98.2161L130.616 103.762L130.151 102.653L143.777 96.9374L143.838 98.2161ZM94.7819 123.982C93.3031 124.603 91.6111 123.936 90.9271 122.502L91.3691 122.009L91.5931 121.916C91.7875 122.17 92.0027 122.411 92.2619 122.614C93.0597 123.239 94.0533 123.517 95.0591 123.395L94.9634 122.602C94.1685 122.699 93.3844 122.48 92.7537 121.985C92.6047 121.869 92.4707 121.738 92.3483 121.599L96.3184 119.933C96.3386 119.977 96.3674 120.014 96.3861 120.059C97.0255 121.583 96.3062 123.343 94.7819 123.982ZM150.514 94.1121C150.884 93.9566 150.267 92.4842 149.897 92.6397L99.5433 113.76C99.4166 113.569 99.1754 113.482 98.9558 113.574L96.8498 114.458C96.6302 114.549 96.5236 114.783 96.5711 115.006L89.9522 117.784C88.4704 118.405 87.1456 119.349 86.0735 120.546L83.11 123.857C82.9963 125.45 81.652 126.672 80.0565 126.634L79.6151 126.624L64.3951 135.835C63.2481 136.529 62.6915 137.895 63.0263 139.194C63.6808 141.731 64.5844 143.767 65.6759 145.421C66.7142 146.996 68.939 147.222 70.2998 145.915L85.7099 131.108C86.3601 130.482 86.7662 129.645 86.854 128.747L87.0405 126.847L90.3655 123.131C91.3123 124.744 93.3203 125.461 95.0908 124.719C97.0204 123.909 97.9319 121.681 97.1227 119.751C97.1039 119.706 97.0744 119.668 97.0543 119.625L98.2869 119.108C98.41 119.311 98.6599 119.404 98.8852 119.31L100.991 118.426C101.217 118.331 101.324 118.088 101.266 117.858L130.634 105.54C130.975 105.397 131.136 105.004 130.993 104.662L146.828 98.0202C147.073 97.9173 146.696 97.0173 146.451 97.121L145.28 97.6113L145.219 96.3333L150.514 94.1121Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M143.838 98.2161L130.616 103.762L130.151 102.653L143.777 96.9374L143.838 98.2161ZM94.7819 123.982C93.3031 124.603 91.6111 123.936 90.9271 122.502L91.3691 122.009L91.5931 121.916C91.7875 122.17 92.0027 122.411 92.2619 122.614C93.0597 123.239 94.0533 123.517 95.0591 123.395L94.9634 122.602C94.1685 122.699 93.3844 122.48 92.7537 121.985C92.6047 121.869 92.4707 121.738 92.3483 121.599L96.3184 119.933C96.3386 119.977 96.3674 120.014 96.3861 120.059C97.0255 121.583 96.3062 123.343 94.7819 123.982ZM150.514 94.1121C150.884 93.9566 150.267 92.4842 149.897 92.6397L99.5433 113.76C99.4166 113.569 99.1754 113.482 98.9558 113.574L96.8498 114.458C96.6302 114.549 96.5236 114.783 96.5711 115.006L89.9522 117.784C88.4704 118.405 87.1456 119.349 86.0735 120.546L83.11 123.857C82.9963 125.45 81.652 126.672 80.0565 126.634L79.6151 126.624L64.3951 135.835C63.2481 136.529 62.6915 137.895 63.0263 139.194C63.6808 141.731 64.5844 143.767 65.6759 145.421C66.7142 146.996 68.939 147.222 70.2998 145.915L85.7099 131.108C86.3601 130.482 86.7662 129.645 86.854 128.747L87.0405 126.847L90.3655 123.131C91.3123 124.744 93.3203 125.461 95.0908 124.719C97.0204 123.909 97.9319 121.681 97.1227 119.751C97.1039 119.706 97.0744 119.668 97.0543 119.625L98.2869 119.108C98.41 119.311 98.6599 119.404 98.8852 119.31L100.991 118.426C101.217 118.331 101.324 118.088 101.266 117.858L130.634 105.54C130.975 105.397 131.136 105.004 130.993 104.662L146.828 98.0202C147.073 97.9173 146.696 97.0173 146.451 97.121L145.28 97.6113L145.219 96.3333L150.514 94.1121Z'
                    fill='black'
                />
            </g>
            <path
                d='M131.088 102.531L117.138 108.382C116.654 108.584 116.414 109.13 116.592 109.624L117.163 111.208C117.354 111.739 117.95 112 118.47 111.783L132.421 105.931C132.904 105.728 133.143 105.182 132.966 104.689L132.395 103.105C132.204 102.575 131.608 102.312 131.088 102.531Z'
                fill='#327EEF'
            />
            <path
                d='M139.969 97.0754L140.45 96.9451C141.695 96.6074 143.024 97.0091 143.873 97.9819L144.302 98.4722C144.795 99.0381 144.588 99.9208 143.893 100.207L139.148 102.163C138.579 102.398 137.929 102.118 137.707 101.544C136.987 99.6782 138.039 97.5995 139.969 97.0754Z'
                fill='#CE5B51'
            />
            <path
                d='M89.7117 87.1387L89.351 87.5678L88.9341 88.079L88.0823 89.1381C87.52 89.8639 86.9519 90.5968 86.3975 91.3442C85.2873 92.8404 84.2145 94.3812 83.182 95.9479C82.1243 97.5031 81.1754 99.126 80.1954 100.737C79.258 102.367 78.353 104.021 77.4969 105.7L76.8618 106.965L76.7063 107.273C76.6912 107.308 76.6732 107.343 76.6538 107.376C76.6077 107.433 76.5724 107.526 76.5364 107.626C76.3874 108.012 76.2506 108.608 76.2052 109.301C76.1138 110.697 76.2894 112.425 76.6192 114.17C77.2622 117.679 78.4754 121.439 79.685 125.017L79.731 125.153C80.2091 126.566 79.451 128.099 78.0376 128.577C76.9259 128.952 75.7379 128.559 75.0496 127.688C73.683 125.951 72.5584 124.232 71.4827 122.372C70.4193 120.521 69.4617 118.588 68.6574 116.5C67.8719 114.405 67.1858 112.187 66.9712 109.574C66.8682 108.269 66.8632 106.848 67.198 105.291C67.2786 104.903 67.3722 104.512 67.5141 104.103L67.7164 103.502L67.8626 103.122L68.44 101.639C69.9945 97.6845 71.8996 93.8757 74.0884 90.2296C75.1907 88.4102 76.3463 86.6203 77.615 84.8944C78.2486 84.0304 78.8958 83.1715 79.5806 82.3312C79.9298 81.9115 80.2653 81.4874 80.6368 81.0712L81.1962 80.4412L81.8349 79.75C83.8566 77.5612 87.2694 77.4266 89.4582 79.4484C91.5815 81.4104 91.7723 84.6813 89.9349 86.8737L89.7117 87.1387Z'
                fill='#1F1F1F'
            />
            <path
                d='M95.3478 78.3804C96.7921 77.8692 94.9575 76.3255 94.9575 76.3255C94.9575 76.3255 87.3234 76.5602 82.783 77.7626C82.1048 78.4135 82.8874 79.1839 82.8874 79.1839C82.8874 79.1839 91.0609 79.8974 95.3478 78.3804Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M95.2371 76.1852C95.2371 76.1852 96.3639 80.4433 101.509 83.9216C99.2575 83.9381 96.0795 86.168 96.0795 86.168C96.0795 86.168 93.0857 82.4031 94.1434 76.5041C94.4559 75.9793 95.2371 76.1852 95.2371 76.1852Z'
                    fill='black'
                />
            </g>
            <path
                d='M95.3865 75.4105C95.3865 75.4105 96.7242 80.0235 101.509 83.918C99.2579 83.9346 95.902 85.0391 95.902 85.0391C95.902 85.0391 93.0861 82.3995 94.143 76.5013C94.4562 75.9757 95.3865 75.4105 95.3865 75.4105Z'
                fill='white'
            />
            <path
                d='M78.9228 124.69L83.4005 128.542L77.4612 132.402C77.4612 132.402 73.8302 128.423 75.6331 126.151L78.9228 124.69Z'
                fill='#CE5B51'
            />
            <path
                d='M75.015 127.938C75.015 127.938 74.0848 127.201 73.8004 126.354C75.2252 124.877 78.1052 123.663 79.4099 123.332C80.2048 124.083 80.235 125.635 80.235 125.635C80.235 125.635 76.4399 126.287 75.015 127.938Z'
                fill='white'
            />
            <path
                d='M83.9078 134.657L81.598 135.659C80.6786 136.057 79.6058 135.732 79.0629 134.89L77.4616 132.403L83.4002 128.543L84.9532 131.925C85.432 132.968 84.9604 134.201 83.9078 134.657Z'
                fill='#CE5B51'
            />
            <path
                d='M101.89 63.939C101.891 63.9383 101.892 63.9376 101.894 63.9369C104.371 62.409 106.555 63.4077 106.646 63.4509C106.737 63.4941 106.775 63.6021 106.733 63.6928C106.69 63.7835 106.582 63.8224 106.492 63.7792C106.471 63.7705 104.388 62.8252 102.084 64.2465C101.999 64.299 101.886 64.2724 101.834 64.1874C101.782 64.1032 101.807 63.9923 101.89 63.939Z'
                fill='#1F1F1F'
            />
            <path
                d='M90.1103 90.9258L99.8187 90.555L99.7849 89.7709L90.0764 90.1417L90.1103 90.9258Z'
                fill='white'
            />
            <path
                d='M174.157 266.57H155.303L169.007 157.724H187.862L174.157 266.57Z'
                fill='#327EEF'
            />
            <g opacity='0.399994'>
                <path
                    d='M174.157 266.57H155.303L169.007 157.724H187.862L174.157 266.57Z'
                    fill='black'
                />
            </g>
            <path
                d='M153.617 266.57H155.303L169.007 157.724H167.321L153.617 266.57Z'
                fill='#1F1F1F'
            />
            <path
                d='M244.464 266.57H225.61L239.314 157.724H258.169L244.464 266.57Z'
                fill='#327EEF'
            />
            <g opacity='0.399994'>
                <path
                    d='M244.464 266.57H225.61L239.314 157.724H258.169L244.464 266.57Z'
                    fill='black'
                />
            </g>
            <path
                d='M223.924 266.57H225.61L239.314 157.724H237.628L223.924 266.57Z'
                fill='#1F1F1F'
            />
            <path
                d='M191.607 253.617H259.978L256.995 229.926H188.624L191.607 253.617Z'
                fill='#327EEF'
            />
            <path
                d='M191.607 253.617H190.488L187.505 229.926H188.624L191.607 253.617Z'
                fill='#1F1F1F'
            />
            <path
                d='M256.21 230.959L258.932 252.582H254.892L244.045 230.959H256.21Z'
                fill='white'
            />
            <path
                d='M237.156 230.959L248.004 252.582H233.169L222.322 230.959H237.156Z'
                fill='white'
            />
            <path
                d='M215.433 230.959L226.28 252.582H211.446L200.598 230.959H215.433Z'
                fill='white'
            />
            <path
                d='M193.714 230.959L204.561 252.582H192.392L189.67 230.959H193.714Z'
                fill='white'
            />
            <path
                d='M253.433 266.57H272.288L258.583 157.724H239.729L253.433 266.57Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M245.834 206.215L265.67 214.008L259.979 168.807H241.124L245.834 206.215Z'
                    fill='black'
                />
            </g>
            <path
                d='M253.433 266.57H251.747L238.042 157.724H239.729L253.433 266.57Z'
                fill='#1F1F1F'
            />
            <path
                d='M183.126 266.57H201.981L188.276 157.724H169.422L183.126 266.57Z'
                fill='#327EEF'
            />
            <g opacity='0.199997'>
                <path
                    d='M175.527 206.215L195.363 214.008L189.672 168.807H170.817L175.527 206.215Z'
                    fill='black'
                />
            </g>
            <path
                d='M183.126 266.57H181.441L167.736 157.724H169.422L183.126 266.57Z'
                fill='#1F1F1F'
            />
            <path
                d='M167.998 201.191H270.953L266.461 165.515H163.506L167.998 201.191Z'
                fill='#327EEF'
            />
            <path
                d='M167.998 201.191H166.312L161.82 165.515H163.506L167.998 201.191Z'
                fill='#1F1F1F'
            />
            <path
                d='M265.278 167.076L269.378 199.637H263.295L246.96 167.076H265.278Z'
                fill='white'
            />
            <path
                d='M236.587 167.076L252.922 199.637H230.583L214.248 167.076H236.587Z'
                fill='white'
            />
            <path
                d='M203.875 167.076L220.21 199.637H197.871L181.536 167.076H203.875Z'
                fill='white'
            />
            <path
                d='M171.17 167.076L187.505 199.637H169.18L165.08 167.076H171.17Z'
                fill='white'
            />
        </svg>
    );
};

export default ForbiddenLight;
