import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { hTagsLines } from '../../../constants/hTagsLines';

import './OutlineTags.scss';

const classes = {
    viewOnlyOutlineTag: 'view-only-outline-tag',
    prefixTag: 'prefix-tag',
    viewOnlyOutlineTagWrapper: 'view-only-outline-tag-wrapper',
};

const ViewOnlyOutlineTag = ({ tag = 'h1', label = '' }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.viewOnlyOutlineTagWrapper} 
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            {hTagsLines[tag]}
            <div className={`${classes.viewOnlyOutlineTag}`}>
                {label && (
                    <div
                        className={`${classes.prefixTag} ${tag} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        {tag.toUpperCase()}
                    </div>
                )}
                <Typography className={`${tag} ${darkMode ? 'dark-mode' : ''}`}>
                    {label}
                </Typography>
            </div>
        </div>
    );
};

export default ViewOnlyOutlineTag;
