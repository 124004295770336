import { useSelector } from 'react-redux';

import { colorPalette } from '../../../resources/styles/colorPalette';
import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

export const useCompleteBadgeColor = (progress) => {
    const darkMode = useSelector(selectDarkMode);

    const getColor = () => {
        switch (true) {
            case progress <= 100 && progress > 95:
                return '#1EA11D';
            case progress <= 95 && progress > 79:
                return '#4DA91D';
            case progress <= 79 && progress > 63:
                return '#81B21F';
            case progress <= 63 && progress > 47:
                return '#BBBB21';
            case progress <= 47 && progress > 31:
                return '#C38E21';
            case progress <= 31 && progress > 15:
                return '#CC5C24';
            case progress <= 15 && progress > 0:
                return '#D52624';
            default:
                return darkMode
                    ? colorPalette.noScoreBadgeColor.dark
                    : colorPalette.noScoreBadgeColor.light;
        }
    };

    const color = getColor();

    return color;
};
