import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import CompleteBadge from '../../completeBadge/CompleteBadge';

import CustomDivider from '../../Divider';

import VersionHistoryButton from './VersionHistoryButton';
import VersionItemName from './VersionItemName';

const classes = {
    versionItem: 'version-item',
    row: 'd-flex justify-content-between align-items-center',
    textInfo: 'text-info',
    mainArticle: 'main-article',

    secondArticle: 'second-article',
    secondArticleSection: 'second-article-section',
};

const VersionItem = ({
    loading,
    version,
    showSelected,
    handleActivateVersion,
    handlePreviewVersion,
    handleUpdateVersionName,
    handleCollapse,
    handleDeleteVersion,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const handleConfirmActivateVersion = () => {
        handleActivateVersion(version?.id);
    };

    const handleChangeVersion = () => {
        handlePreviewVersion(version?.id);
    };

    const getButtonLabel = () => {
        if (showSelected) {
            return 'Selected';
        } else if (version?.active) {
            return 'Current';
        } else {
            return 'Preview';
        }
    };

    return (
        <div
            className={`${classes.versionItem} ${darkMode ? 'dark-mode' : ''}`}
        >
            <CustomDivider marginBottom={'14px'} />
            <div className={`${classes.row}`}>
                <div className={`${classes.textInfo}`}>
                    <VersionItemName
                        version={version}
                        handleUpdateVersionName={handleUpdateVersionName}
                        handleDeleteVersion={handleDeleteVersion}
                    />
                    <div
                        className={`${classes.secondArticleSection} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        <Typography
                            className={`${classes.secondArticle} ${
                                darkMode ? 'dark-mode' : ''
                            }`}
                        >
                            {`Word Count: ${version?.wordCount || 0}`}
                        </Typography>
                        <CompleteBadge
                            size='small'
                            progress={
                                version?.content_brief?.progress ||
                                version?.progress
                            }
                        />
                    </div>
                </div>
                <VersionHistoryButton
                    disabled={version?.active || loading}
                    label={getButtonLabel()}
                    tooltipLabel={version?.active && 'Version is opened'}
                    handleCollapse={handleCollapse}
                    isCreateNewVersionBtn={false}
                    handlePreviewVersion={handleChangeVersion}
                    handleActivateVersion={handleConfirmActivateVersion}
                />
            </div>
        </div>
    );
};

export default VersionItem;
