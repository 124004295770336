import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDarkMode } from '../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import SkeletonLoading from '../../../../../../../../../common/SkeletonLoading';
import CustomTypography from '../../../../../../../../../common/styledTypography/CustomTypography';

import { updateCurrentOutline } from '../../store/contentBriefsOutline.actions';
import {
    selectCurrentOutline,
    selectIsRanksHtagsLoaded,
    selectRanksHtags,
} from '../../store/contentBriefsOutline.selectors';

import { outlineTags } from './constants/constants';

import { usePageTitle } from './hooks/usePageTitle';

import RankItem from './rankItem/RankItem';

import './Ranks.scss';

const classes = {
    ranksContainer: 'ranks-container',
    ranksInfoWrapper: 'ranks-info-wrapper',
};

const RanksContainer = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const currentOutline = useSelector(selectCurrentOutline);
    const ranksHtags = useSelector(selectRanksHtags);
    const isRanksHtagsLoaded = useSelector(selectIsRanksHtagsLoaded);

    const { updatePageTitle } = usePageTitle();

    const addOutlineRow = (row) => {
        if (row.tag === outlineTags.h1) {
            updatePageTitle(row.title);
        } else {
            const updatedOutline =
                currentOutline?.length > 0 ? [...currentOutline, row] : [row];

            dispatch(updateCurrentOutline(updatedOutline));
        }
    };

    return (
        <div
            className={`${classes.ranksContainer} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            {isRanksHtagsLoaded ? (
                ranksHtags?.length > 0 ? (
                    ranksHtags
                        ?.filter((x) =>
                            x.include_in_optimal_range !== undefined
                                ? x.include_in_optimal_range
                                : true
                        )
                        ?.map((rank, index) => {
                            return (
                                <RankItem
                                    rank={rank}
                                    addOutlineRow={addOutlineRow}
                                    index={index}
                                    key={`${index}-${rank?.url}`}
                                />
                            );
                        })
                ) : (
                    <div className={classes.ranksInfoWrapper}>
                        <CustomTypography
                            text={
                                'No data. Try to change competitor positions analyzed in Optimizer settings.'
                            }
                        />
                    </div>
                )
            ) : (
                <SkeletonLoading width='96%' />
            )}
        </div>
    );
};

export default RanksContainer;
