import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Dropdown } from 'antd';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import {
    getDropdownOverlayClass,
    getThemeColorClass,
} from '../../../../../utils/helpers/themeHelpers';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { headingOptions, headingOptionsValues } from '../constants';
import { EDITOR_TOOLBAR_ID } from '../EditorToolbar';

const classes = {
    headingSection: 'heading-section',
    headingButton: 'heading-button d-flex justify-content-between align-items-center',
    arrowIcon: 'arrow-icon',

    dropdownOverlay: 'toolbar-dropdown-overlay',
};

const HeadingSection = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    const iconColor = useIconColor();
    const { isOpen, handleOpen } = useOpen();

    const getActiveHeading = () => {
        if (editor.isActive(headingOptionsValues.blockquote)) {
            return headingOptionsValues.blockquote;
        }

        const headingOptionsValue = headingOptions?.find((item) => {
            if (item.level) {
                return editor.isActive(item.levelName, {
                    level: item.level,
                });
            }

            return editor.isActive(item.value);
        })?.value;

        return headingOptionsValue || (headingOptions?.length && headingOptions[0]?.value);
    };

    const headingOptionItems = headingOptions.map((item) => {
        return {
            key: item.value,
            label: item.label,
            onClick: () => {
                item.handleSelect(editor);
                handleOpen();
            },
        };
    });

    return (
        <div className={`${classes.headingSection} ${getThemeColorClass(darkMode)}`}>
            <Dropdown
                open={isOpen}
                menu={{
                    items: headingOptionItems,
                    selectedKeys: [getActiveHeading()],
                }}
                trigger={['click']}
                placement='bottom'
                onOpenChange={handleOpen}
                getPopupContainer={() => document.getElementById(EDITOR_TOOLBAR_ID)}
                overlayClassName={`${classes.dropdownOverlay} ${getDropdownOverlayClass(darkMode)}`}
            >
                <Button
                    className={classes.headingButton}
                    icon={
                        <span
                            className={classes.arrowIcon}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleOpen();
                            }}
                        >
                            {isOpen ? (
                                <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                            ) : (
                                <SiderChevronDownIcon color={iconColor} />
                            )}
                        </span>
                    }
                    onClick={handleOpen}
                >
                    {headingOptions.find((item) => item.value === getActiveHeading())?.label}
                </Button>
            </Dropdown>
        </div>
    );
};

export default HeadingSection;
