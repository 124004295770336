import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import {
    selectUsedUsers,
    selectUsersLimit,
} from '../../billing/store/billingSettings.selectors';

const UsersCredits = () => {
    const usersLimit = useSelector(selectUsersLimit);
    const usersUsed = useSelector(selectUsedUsers);

    return (
        <Typography>
            {usersUsed || 0}/{usersLimit || 0} seats used
        </Typography>
    );
};

export default UsersCredits;
