import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Tooltip, Typography } from 'antd';

import moment from 'moment';

import { accountTabs } from '../../../../constants/accountTabs';
import { ScrollIds } from '../../../../constants/scrollIds';

import { checkIsAdmin } from '../../../../utils/helpers/checkIsAdmin';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';
import { useSubscriptionLimits } from '../../../../utils/hooks/useSubscriptionLimits';

import CreditsIcon from '../../../../resources/icons/CreditsIcon';

import {
    selectCurrentUserRoles,
    selectDarkMode,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentSubscription } from '../../../pages/account/billing/store/billingSettings.selectors';
import {
    updateAccountTab,
    updateScrollSection,
} from '../../../pages/account/store/accountSettings.actions';

import Link from 'antd/es/typography/Link';

const classes = {
    creditsRemainingWrapper: 'd-flex flex-start',
    creditsSettingWrapper: 'd-flex align-items-center credits-setting-wrapper ',
    creditsSettingText: 'ml-8px',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    creditsRemainingRow: 'credits-remaining-row',
    textDecorationDotted: 'text-decoration-dotted',
    creditsSettingTooltipOverlayCollapsed:
        'credits-setting-tooltip-overlay-collapsed',
};

const CreditsRemaining = ({ collapsed }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUserRoles = useSelector(selectCurrentUserRoles);
    const darkMode = useSelector(selectDarkMode);

    const currentSubscription = useSelector(selectCurrentSubscription);

    const iconColor = useIconColor();
    const organizationSlug = useOrganizationSlug();
    const {
        totalKeywordResearchCredits,
        remainingKeywordResearchCredits,
        totalOptimizers,
        remainingOptimizers,
        totalContentMonitors,
        remainingContentMonitors,
        totalOpenAIBriefs,
        remainingOpenAIBriefs,
    } = useSubscriptionLimits();

    const handleClick = () => {
        if (checkIsAdmin(currentUserRoles[0]?.name)) {
            navigate(`${organizationSlug}/account/billing-credits`);
            dispatch(updateAccountTab(accountTabs['billing-credits']?.key));
            dispatch(updateScrollSection(ScrollIds.credits));
        }
    };

    const cursorForAdminClass = checkIsAdmin(currentUserRoles[0]?.name)
        ? 'cursor-pointer'
        : 'cursor-auto';

    const currentPeriodEnd = moment(
        currentSubscription?.current_period_end
    ).format('MMM D, YYYY');

    return (
        <div className={classes.creditsRemainingWrapper}>
            {collapsed ? (
                <Tooltip
                    trigger={['hover']}
                    arrowPointAtCenter={true}
                    title={`Resets on ${currentPeriodEnd}.`}
                    placement={'right'}
                    overlayClassName={`${classes.tooltipOverlay} ${
                        darkMode ? classes.tooltipOverlayDark : ''
                    } ${classes.creditsSettingTooltipOverlayCollapsed}`}
                >
                    <div className={`${classes.creditsSettingWrapper}`}>
                        <CreditsIcon color={iconColor} />
                    </div>
                </Tooltip>
            ) : (
                <>
                    <div className={`${classes.creditsSettingWrapper}`}>
                        <CreditsIcon color={iconColor} />
                    </div>
                    <div>
                        <div className={classes.creditsRemainingRow}>
                            <Tooltip
                                trigger={['hover']}
                                arrowPointAtCenter={true}
                                title={`Keyword Research credits remaining this month. Resets on ${currentPeriodEnd}.`}
                                placement={'topRight'}
                                overlayClassName={`${classes.tooltipOverlay}
                            ${darkMode ? classes.tooltipOverlayDark : ''}`}
                            >
                                <div
                                    className={`${classes.creditsSettingWrapper}`}
                                >
                                    <Typography
                                        className={`${classes.creditsSettingText}`}
                                    >
                                        <span
                                            className={
                                                classes.textDecorationDotted
                                            }
                                        >
                                            KR:
                                        </span>
                                    </Typography>
                                </div>
                            </Tooltip>
                            <div className={`${classes.creditsSettingWrapper}`}>
                                <Typography
                                    className={`${classes.creditsSettingText}`}
                                >
                                    <span className={'fw-700'}>
                                        {remainingKeywordResearchCredits}{' '}
                                    </span>
                                    / {totalKeywordResearchCredits}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.creditsRemainingRow}>
                            <Tooltip
                                trigger={['hover']}
                                arrowPointAtCenter={true}
                                title={
                                    <>
                                        Content Optimizer credits remaining this
                                        month. Resets on{' '}
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {currentPeriodEnd}.
                                        </span>{' '}
                                        {
                                            <Link
                                                onClick={handleClick}
                                                style={{ fontSize: 'inherit' }}
                                            >
                                                Click here
                                            </Link>
                                        }{' '}
                                        to add more credits.
                                    </>
                                }
                                placement={'topRight'}
                                overlayClassName={`${classes.tooltipOverlay}
                            ${darkMode ? classes.tooltipOverlayDark : ''}`}
                            >
                                <div
                                    className={`${classes.creditsSettingWrapper} ${cursorForAdminClass}`}
                                    onClick={handleClick}
                                >
                                    <Typography
                                        className={`${classes.creditsSettingText}`}
                                    >
                                        <span
                                            className={
                                                classes.textDecorationDotted
                                            }
                                        >
                                            CO:
                                        </span>
                                    </Typography>
                                </div>
                            </Tooltip>
                            <div
                                className={`${classes.creditsSettingWrapper} ${cursorForAdminClass}`}
                                onClick={handleClick}
                            >
                                <Typography
                                    className={`${classes.creditsSettingText}`}
                                >
                                    <span className={'fw-700'}>
                                        {remainingOptimizers}{' '}
                                    </span>
                                    / {totalOptimizers}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.creditsRemainingRow}>
                            <Tooltip
                                trigger={['hover']}
                                arrowPointAtCenter={true}
                                title={
                                    'Content Monitor pages remaining on your subscription.'
                                }
                                placement={'topRight'}
                                overlayClassName={`${classes.tooltipOverlay} ${
                                    darkMode ? classes.tooltipOverlayDark : ''
                                }`}
                            >
                                <div
                                    className={`${classes.creditsSettingWrapper}`}
                                >
                                    <Typography
                                        className={`${classes.creditsSettingText}`}
                                    >
                                        <span
                                            className={
                                                classes.textDecorationDotted
                                            }
                                        >
                                            CM:
                                        </span>
                                    </Typography>
                                </div>
                            </Tooltip>
                            <div className={`${classes.creditsSettingWrapper}`}>
                                <Typography
                                    className={`${classes.creditsSettingText}`}
                                >
                                    <span className={'fw-700'}>
                                        {remainingContentMonitors}{' '}
                                    </span>
                                    / {totalContentMonitors}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.creditsRemainingRow}>
                            <Tooltip
                                trigger={['hover']}
                                arrowPointAtCenter={true}
                                title={
                                    <>
                                        AI credits remaining this month.
                                        Resets on{' '}
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {currentPeriodEnd}.
                                        </span>{' '}
                                    </>
                                }
                                placement={'topRight'}
                                overlayClassName={`${classes.tooltipOverlay}
                            ${darkMode ? classes.tooltipOverlayDark : ''}`}
                            >
                                <div
                                    className={`${classes.creditsSettingWrapper} ${cursorForAdminClass}`}
                                    onClick={handleClick}
                                >
                                    <Typography
                                        className={`${classes.creditsSettingText}`}
                                    >
                                        <span
                                            className={
                                                classes.textDecorationDotted
                                            }
                                        >
                                            AI:
                                        </span>
                                    </Typography>
                                </div>
                            </Tooltip>
                            <div
                                className={`${classes.creditsSettingWrapper} ${cursorForAdminClass}`}
                                onClick={handleClick}
                            >
                                <Typography
                                    className={`${classes.creditsSettingText}`}
                                >
                                    <span className={'fw-700'}>
                                        {remainingOpenAIBriefs}{' '}
                                    </span>
                                    / {totalOpenAIBriefs}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CreditsRemaining;
