import { cancellationPaths } from '../constants/queryPathes';
import { axiosAPI } from '../utils/axiosAPI';
import { getAxiosHeaders } from '../utils/helpers/getAxiosHeaders';

const CancellationService = {
    getCancellationReasons: (randomizeReasons = true) => {
        return axiosAPI.get(cancellationPaths.getCancellationReasons, {
            ...getAxiosHeaders(),
            params: {
                randomize_reasons: randomizeReasons,
            },
        });
    },

    sendCancellationFeedback: (data) => {
        return axiosAPI.post(cancellationPaths.addCancellation, data, {
            ...getAxiosHeaders(),
        });
    },

    getCancellations: () => {
        return axiosAPI.get(cancellationPaths.getCancellation, {
            ...getAxiosHeaders(),
        });
    },
};

export default CancellationService;
