import React from 'react';
import { useSelector } from 'react-redux';

import { accountTabs } from '../../../constants/accountTabs';

import CustomHeader from '../../common/header/Header';

import { selectAccountTab } from './store/accountSettings.selectors';

const AccountPageHeader = () => {
    const selectedTab = useSelector(selectAccountTab);

    return (
        <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            extraBreadcrumbs={[accountTabs[selectedTab]?.label]}
        />
    );
};

export default AccountPageHeader;
