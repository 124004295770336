import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditorProvider } from '@tiptap/react';
import { debounce } from 'lodash';

import useAiResponseConfiguration from './hooks/useAiResponseConfiguration';
import useEditorEventHandlers from './hooks/useEditorEventHandlers';
import useInterruptedAIOptimizer from './hooks/useInterruptedAIOptimizer';
import useUpdateEditorContent from './hooks/useUpdateEditorContent';
import { configureCustomKeyboardShortcuts } from './utils/customKeyboardShortcuts';

import { editorAutoSaveDelay } from '../../../constants/contentOptimizer';
import { nodeTypes } from './constants/nodeTypes';

import TipTapEditorSettings from './TipTapEditorSettings';
import AISpellCheckLoader from './components/AiSpellChecker/AISpellCheckerLoader';
import DraggableComponent from './components/DraggableComponent';
import EditorToolbar from './toolbar/EditorToolbar';
import EditLinkContent from './toolbar/buttons/content/EditLinkContent';

import { CustomAi } from './extensions/aiExtensions';
import { HighlightKeywordsExtension } from './extensions/customExtensions';
import { tiptapEditorExtensions } from './extensions/tiptapEditorExtensions';

import { updateCurrentEditorText } from './store/tiptapEditor.actions';
import {
    selectAIStateAccepted,
    selectCurrentEditorContent,
    selectEditorPreviewVersion,
    selectGeneratedAIToken,
} from './store/tiptapEditor.selectors';

import './TipTapEditor.scss';

export const EDITOR_CONTENT_WRAPPER_CLASS = 'editor-content-wrapper';

const TipTapEditor = () => {
    const dispatch = useDispatch();

    const editorRef = useRef(null);
    const editorRootRef = useRef(null);

    const currentEditorContent = useSelector(selectCurrentEditorContent);
    const editorPreviewVersion = useSelector(selectEditorPreviewVersion);

    const generatedAIToken = useSelector(selectGeneratedAIToken);
    const aiAccepted = useSelector(selectAIStateAccepted);

    const [handleUpdateEditor] = useUpdateEditorContent();
    const { handleAiLoading, handleAiChunk, handleAiSuccess, handleAiError } =
        useAiResponseConfiguration();
    const { initializeInterruptedOptimizerMatch, storeInterruptedOptimizer } =
        useInterruptedAIOptimizer();

    useEditorEventHandlers(editorRef, editorRootRef);

    const handleEditorReady = (editorData) => {
        const { editor } = editorData;

        if (editor) {
            editorRef.current = editor;

            editor.options.editorProps.handleKeyDown = (_, event) => {
                configureCustomKeyboardShortcuts(editor, event);
            };

            const newEditorContent =
                editorPreviewVersion && editorPreviewVersion.content?.length > 0
                    ? editorPreviewVersion.content.slice(1, -1)
                    : currentEditorContent;

            if (editor.getHTML() !== newEditorContent) {
                editor.commands.setContent(newEditorContent);

                const editorText = editor.getText();
                dispatch(updateCurrentEditorText(editorText));
            }

            initializeInterruptedOptimizerMatch(editor);
        }
    };

    const handleEditorChange = debounce((editorOnChangeData) => {
        const { editor } = editorOnChangeData;

        const interruptedOptimizer = editor.storage[nodeTypes.aiSpellChecker].interruptedOptimizer;
        const spellCheckerVisible = editor.storage[nodeTypes.aiSpellChecker].spellCheckerVisible;

        if (
            (spellCheckerVisible && (!interruptedOptimizer.id || interruptedOptimizer.isMatch)) ||
            editor.storage.isLinkEditing
        ) {
            return;
        }

        editor.commands.setMeta('applyHighlighting', true);

        const updatedEditorData = editor?.getHTML();
        const updatedEditorText = editor?.getText() || '';

        dispatch(updateCurrentEditorText(updatedEditorText));
        handleUpdateEditor(updatedEditorData, updatedEditorText);
    }, editorAutoSaveDelay);

    const handleSelectionUpdate = ({ editor }) => {
        if (
            !aiAccepted &&
            !editor.storage[nodeTypes.aiSpellChecker].regenerateOptionEnabled &&
            editor.storage[nodeTypes.aiSpellChecker].response
        ) {
            const selectionEndPosition = editor.storage[nodeTypes.aiSpellChecker].selectedRange.to;

            editor.commands.setTextSelection({
                from: selectionEndPosition,
                to: selectionEndPosition,
            });
        }
    };

    const handleEditorDestroy = () => {
        if (editorRef.current?.storage?.[nodeTypes.aiSpellChecker]?.spellCheckerVisible) {
            storeInterruptedOptimizer(editorRef.current);
        }
    };

    return (
        <div ref={editorRootRef}>
            <EditorProvider
                editorProps={{
                    attributes: {
                        class: EDITOR_CONTENT_WRAPPER_CLASS,
                    },
                }}
                slotBefore={
                    <>
                        <EditorToolbar />
                        <EditLinkContent />
                    </>
                }
                extensions={[
                    ...tiptapEditorExtensions,
                    HighlightKeywordsExtension.configure({
                        className: 'highlight-keyword',
                    }),
                    CustomAi.configure({
                        appId: process.env.REACT_APP_TIPTAP_AI_APP_ID || '',
                        token: generatedAIToken || '',
                        autocompletion: false,
                        onLoading: handleAiLoading,
                        onChunk: handleAiChunk,
                        onSuccess: handleAiSuccess,
                        onError: handleAiError,
                    }),
                ]}
                onCreate={handleEditorReady}
                onUpdate={handleEditorChange}
                onSelectionUpdate={handleSelectionUpdate}
                onDestroy={handleEditorDestroy}
                content={currentEditorContent}
            >
                <TipTapEditorSettings />
                <DraggableComponent />
                <AISpellCheckLoader />
            </EditorProvider>
        </div>
    );
};

export default TipTapEditor;
