import React from 'react';
import { useSelector } from 'react-redux';

import { Table, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    mainLabel: 'main-label',
    userStatsLabel: 'user-stats-label',
};

const userStatsColumns = [
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        width: '40%',
    },
    {
        title: '30 day Content Optimizers',
        dataIndex: 'reports',
        key: 'reports',
        width: '30%',
    },
    {
        title: '30 day Keyword Searches',
        dataIndex: 'keywordDiscoveries',
        key: 'keywordDiscoveries',
        width: '30%',
    },
];

const UserStatsTable = ({ userStats }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <>
            <Typography
                className={`${classes.mainLabel} ${classes.userStatsLabel} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                User Stats
            </Typography>
            <Table
                dataSource={userStats}
                columns={userStatsColumns}
                loading={false}
                bordered
                rowKey='userId'
                showSorterTooltip={false}
                pagination={false}
            />
        </>
    );
};

export default UserStatsTable;
