import React from 'react';

const UndoIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.66671 5.33333H3.33337V2'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M3.52698 10.9051C4.1482 11.8617 5.05973 12.5939 6.12776 12.9943C7.19578 13.3947 8.36404 13.4421 9.461 13.1296C10.558 12.8171 11.5259 12.1612 12.2226 11.2581C12.9193 10.355 13.3082 9.25237 13.3321 8.11202C13.3559 6.97166 13.0136 5.85368 12.3553 4.92222C11.697 3.99075 10.7574 3.29486 9.67451 2.93672C8.59159 2.57858 7.42238 2.57705 6.33852 2.93236C5.25467 3.28766 4.31327 3.98109 3.65254 4.91083'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default UndoIcon;
