import React from 'react';

import { Input } from 'antd';

import { useIconColor } from '../../../utils/hooks/useIconColor';
import SearchIcon from '../../../resources/icons/SearchIcon';

const classes = {
  searchInput: 'search-input',
};

const SearchInput = ({ handleSearch, defaultSearchValue, searchValue }) => {
  const iconColor = useIconColor();

  return (
    <Input
      placeholder='Search...'
      prefix={<SearchIcon color={iconColor} />}
      className={classes.searchInput}
      onChange={(e) => handleSearch(e.target.value)}
      value={searchValue && searchValue}
      defaultValue={defaultSearchValue}
    />
  );
};

export default SearchInput;
