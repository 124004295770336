import React from 'react';
import { useSelector } from 'react-redux';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    tagLine: 'tag-line',
};

const TagLine = ({ marginLeft = '20px' }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.tagLine} ${darkMode ? 'dark-mode' : ''}`}
            style={{ marginLeft: marginLeft }}
        ></div>
    );
};

export default TagLine;
