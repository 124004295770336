import React from 'react';
import { useSelector } from 'react-redux';

import {
    CartesianGrid,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { getTrendGraphStrokeColor } from '../../../utils/helpers/getTrendGraphColors';

import BaseChartTooltip from './BaseChartTooltip';

import './Charts.scss';

const classes = {
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const SimpleLineChart = ({
    width,
    height,
    data,
    dataKey,
    strokeWidth,
    xAxisDataKey,
    tooltipContent,
    yAxisTicks,
}) => {
    const darkMode = useSelector(selectDarkMode);

    if (!data || data?.length < 2) {
        return null;
    }

    return (
        <LineChart
            height={height || 250}
            width={width || 500}
            data={data}
            margin={{ left: -48, right: 16, top: 10, bottom: -16 }}
        >
            <CartesianGrid strokeDasharray='8 8' />
            <XAxis
                dataKey={xAxisDataKey || 'name'}
                strokeOpacity={0}
                dy={10}
                // We need it for disable x axis label
                tickFormatter={() => ''}
            />
            <YAxis
                strokeOpacity={0}
                dx={0}
                // We need it for disable y axis label
                tickFormatter={() => ''}
                ticks={yAxisTicks}
            />
            <Tooltip
                content={
                    tooltipContent || (
                        <BaseChartTooltip dataKey={dataKey || 'value'} />
                    )
                }
                overlayClassName={`${classes.tooltipOverlay}
                                   ${darkMode ? classes.tooltipOverlayDark : ''}`}
            />
            <Line
                dataKey={dataKey || 'value'}
                stroke={getTrendGraphStrokeColor(
                    data[0]?.[dataKey] || data[0]?.value,
                    data[data?.length - 1]?.[dataKey] ||
                        data[data?.length - 1]?.value
                )}
                strokeWidth={strokeWidth || 1}
            />
        </LineChart>
    );
};

export default SimpleLineChart;
