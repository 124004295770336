import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { getRandomId } from '../../../../../../../utils/helpers/idGenerator';

import BasicOutlineTag from '../../../../../../common/outlineTags/BasicOutlineTag';

import {
    selectViewOnlyBriefData,
    selectViewOnlyOutlineData,
} from '../../../../../contentOptimizer/viewOnly/store/viewOnlySettings.selectors';

const classes = {
    emptyMessage: 'empty-message d-flex flex-center',
};

const OutlineContent = () => {
    const viewOnlyOutlineData = useSelector(selectViewOnlyOutlineData);
    const viewOnlyBriefData = useSelector(selectViewOnlyBriefData);

    return (
        <div>
            {viewOnlyOutlineData?.outline?.length > 0 ? (
                [
                    {
                        id: getRandomId(),
                        tag: 'h1',
                        title: viewOnlyBriefData?.extras?.page_title || 'N/A',
                    },
                    ...viewOnlyOutlineData?.outline,
                ]?.map((item) => {
                    return (
                        <BasicOutlineTag
                            key={`${item?.tag}${item?.title}${item?.id}`}
                            tag={item?.tag}
                            label={item?.title}
                            isBulletedTag={item?.isBulletedTag}
                        />
                    );
                })
            ) : (
                <div className={classes.emptyMessage}>
                    <Typography.Text className='empty-list-text'>
                        No data
                    </Typography.Text>
                </div>
            )}
        </div>
    );
};

export default OutlineContent;
