export const briefExtrasFormItemsDescription = {
    overview: {
        label: 'Overview',
        placeholder:
            'Click to add content guidelines, goals, requirements, or any other information you want the writer to know.',
        requirements: {
            label: false,
            characters: {
                min: 10,
            },
        },
        textarea: true,
        autosize: true,
        hasSubtitle: false,
    },
    page_url: {
        label: 'Page URL',
        placeholder: 'Click to add Page URL e.g. https://www.domain.com/primary-keyword',
        requirements: {
            label: true,
            primaryKeyword: true,
            url: true,
        },
        textarea: false,
        hasSubtitle: true,
        url: true,
    },
    meta_title: {
        label: 'Meta Title / Title Tag',
        placeholder: 'Click to add Meta Title',
        requirements: {
            label: true,
            characters: {
                min: 50,
                max: 60,
            },
            primaryKeyword: true,
        },
        textarea: false,
        hasSubtitle: true,
    },
    meta_description: {
        label: 'Meta Description',
        placeholder: 'Click to add Meta Description',
        requirements: {
            label: true,
            characters: {
                min: 50,
                max: 160,
            },
            primaryKeyword: true,
        },
        textarea: true,
        autosize: { minRows: 1 },
        hasSubtitle: true,
    },
    page_title: {
        label: 'Page Title / H1 Tag',
        placeholder: 'Click to add Page Title',
        requirements: {
            label: true,
            characters: {
                min: 20,
                max: 70,
            },
            primaryKeyword: true,
        },
        textarea: false,
        hasSubtitle: true,
    },
    word_count: {
        label: 'Word Count',
        placeholder: 'Click to add Word Count',
        number: true,
        hasSubtitle: true,
    },
    primary_keyword: {
        label: 'Primary Keyword',
        placeholder: 'Click to add Primary Keyword',
        requirements: {
            label: false,
            characters: {
                min: 2,
            },
        },
        textarea: false,
        hasSubtitle: false,
    },
    secondary_keywords: {
        label: 'Top NLP Keywords',
        placeholder: 'Click to add Top NLP Keywords',
        requirements: {
            label: false,
            characters: {
                min: 2,
            },
        },
        textarea: false,
        hasSubtitle: false,
    },
    example_articles: {
        label: 'Content Examples',
        requirementsLabel:
            '(Add one or more URLs separated by commas or on a new line by pressing Enter)',
        placeholder:
            'Click to add Content Examples e.g. https://www.domain.com, etc.',
        requirements: {
            label: false,
            characters: {
                min: 1,
            },
        },
        textarea: true,
        autosize: true,
        url: true,
        hasSubtitle: true,
    },
};
