import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Spin, Typography } from 'antd';
import moment from 'moment';

import { useIconColor } from '../../../../../../utils/hooks/useIconColor';

import InvoicePaidIcon from '../../../../../../resources/icons/InvoicePaidIcon';
import InvoiceUnpaidIcon from '../../../../../../resources/icons/InvoiceUnpaidIcon';

import Loader from '../../../../../common/loader/Loader';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import DownloadInvoiceButton from './DownloadInvoiceButton';
import DownloadReceiptButton from './DownloadReceiptButton';

const { Title, Link } = Typography;

const classes = {
    invoiceCardWrapper: 'invoice-card-wrapper',
    invoiceTopSection:
        'invoice-top-section d-flex flex-column align-items-center',
    invoiceBottomSection: 'invoice-bottom-section',
    invoiceInfoWrapper:
        'invoice-info-wrapper d-flex justify-content-between align-items-center w-100',
    invoiceButtonsWrapper:
        'invoice-buttons-wrapper d-flex justify-content-between w-100',
    link: 'link-hover-highlight',
    invoiceFooterText: 'invoice-footer-text',
    spinSkeleton: 'spin-skeleton',
};

const InvoiceCard = ({ isOpen, handleOpen, isLoading, invoiceData }) => {
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);

    return (
        <>
            <Card bordered className={classes.invoiceCardWrapper}>
                <Spin
                    spinning={isLoading}
                    indicator={<Loader />}
                    className={classes.spinSkeleton}
                >
                    {!isLoading && (
                        <>
                            <div className={classes.invoiceTopSection}>
                                {invoiceData?.paid ? (
                                    <InvoicePaidIcon color={iconColor} />
                                ) : (
                                    <InvoiceUnpaidIcon color={iconColor} />
                                )}
                                <Typography>
                                    Invoice{' '}
                                    {invoiceData?.paid ? 'paid' : 'unpaid'}
                                </Typography>
                                <Title level={5}>
                                    $
                                    {(invoiceData?.total / 100 || 0).toFixed(2)}
                                </Title>
                                <Link
                                    underline
                                    className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
                                    onClick={handleOpen}
                                >
                                    {isOpen
                                        ? 'Close invoice and payment details'
                                        : 'View invoice details'}
                                </Link>
                            </div>
                            <div className={classes.invoiceBottomSection}>
                                <div className={classes.invoiceInfoWrapper}>
                                    <Typography>Invoice number</Typography>
                                    <Title level={5}>
                                        {invoiceData?.number || ''}
                                    </Title>
                                </div>
                                <div className={classes.invoiceInfoWrapper}>
                                    <Typography>Payment date</Typography>
                                    <Title level={5}>
                                        {moment(
                                            invoiceData?.created * 1000
                                        ).format('LL') || ''}
                                    </Title>
                                </div>
                                <div className={classes.invoiceInfoWrapper}>
                                    <Typography>Payment method</Typography>
                                    <Title level={5}>Visa **** 4956</Title>
                                </div>
                            </div>
                            <div className={classes.invoiceButtonsWrapper}>
                                <DownloadInvoiceButton
                                    pdfLink={invoiceData?.invoice_pdf}
                                    invoiceNumber={invoiceData?.number}
                                />
                                <DownloadReceiptButton
                                    invoiceId={invoiceData?.id}
                                />
                            </div>
                        </>
                    )}
                </Spin>
            </Card>

            <div className={classes.invoiceFooterText}>
                <Title level={5}>
                    Download not working? Please disable any pop-up blockers or
                    try using a different web browser.
                </Title>
            </div>
        </>
    );
};

export default InvoiceCard;
