import { readabilityLabel } from '../../constants/readability';
import { rankabilityScoreCategories } from '../../constants/rankabilityScoreCategories';

export const getReadabilityLabel = (readabilityScore = 100) => {
    const score = Math.max(0, Math.min(readabilityScore, 100));

    let readability = readabilityLabel?.find((item) => {
        return score >= item.min && score <= item.max;
    });

    if (!readability) {
        if (readabilityScore > 100) {
            readability = readabilityLabel[readabilityLabel.length - 1];
        } else {
            readability = readabilityLabel[0];
        }
    }

    return readability?.label;
};

export const getReadabilityItem = (readabilityScore = 100) => {
    const score = Math.max(0, Math.min(readabilityScore, 100));

    return readabilityLabel?.find((item) => {
        return score >= item.min && score <= item.max;
    });
};

export const calculateOptimalRankabilityScore = (
    optimalMaxValue = 100,
    getText = false
) => {
    const ceiledOptimalValue = Math.ceil(optimalMaxValue);

    const shift =
        rankabilityScoreCategories.find(
            (categoryItem) =>
                ceiledOptimalValue >= categoryItem.min &&
                ceiledOptimalValue <= categoryItem.max
        )?.shift || 0;

    let optimalScore = shift;

    if (typeof shift === 'number') {
        optimalScore = ceiledOptimalValue + shift;
    }

    return getText ? `${optimalScore}+` : optimalScore;
};

export const getOptimalReadabilityLabel = (optimalReadabilityData = {}) => {
    const minReadability = getReadabilityLabel(
        optimalReadabilityData.min || 100
    );
    const maxReadability = getReadabilityLabel(
        optimalReadabilityData.max || 100
    );

    return maxReadability === minReadability
        ? maxReadability
        : `${maxReadability} - ${minReadability}`;
};
