import React from 'react';
import { useSelector } from 'react-redux';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { useCompleteBadgeColor } from '../completeBadge/useCompleteBadgeColor';

import './CompleteProgressBar.scss';

const classes = {
    completeProgressBar: 'complete-progress-bar w-100',
    completeProgressBarDark: 'complete-progress-bar-dark',
    nullValueProgressBar: 'null-value-progress-bar',
    completeProgressBarProgress:
        'complete-progress-bar-progress d-flex align-items-center',
};

const CompleteProgressBar = ({ progress }) => {
    const darkMode = useSelector(selectDarkMode);

    const progressColor = useCompleteBadgeColor(parseFloat(progress));

    return (
        <>
            <div
                className={`${classes.completeProgressBar}
                        ${darkMode ? classes.completeProgressBarDark : ''}
                        ${!progress && classes.nullValueProgressBar}`}
            >
                <div
                    className={classes.completeProgressBarProgress}
                    style={{
                        width: progress && `calc(${progress}% + 2px)`,
                        backgroundColor: progressColor,
                    }}
                >
                    <span>{progress ? `${progress}% COMPLETE` : '--'}</span>
                </div>
            </div>
        </>
    );
};

export default CompleteProgressBar;
