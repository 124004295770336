import React from 'react';

const SimularContentIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.1667 11.1673V13.6673C14.1667 17.0007 12.8333 18.334 9.5 18.334H6.33333C3 18.334 1.66666 17.0007 1.66666 13.6673V10.5007C1.66666 7.16732 3 5.83398 6.33333 5.83398H8.83333'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.1667 11.1673H11.5C9.5 11.1673 8.83334 10.5007 8.83334 8.50065V5.83398L14.1667 11.1673Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.66666 1.66602H13.2954'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.83334 4.16602C5.83334 2.78268 6.95 1.66602 8.33334 1.66602H10.5167'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M18.3333 7.08398V11.8255C18.3333 13.1171 17.2833 14.1671 15.9917 14.1671'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M18.3333 6.66602H15.8333C13.9583 6.66602 13.3333 6.04102 13.3333 4.16602V1.66602L18.3333 6.66602Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default SimularContentIcon;
