export const keywordResearchMainSortOptions = {
    recent: {
        label: 'Most Recent',
        key: 'recent',
        queryParams: {
            sort: 'created_at',
            order: 'desc',
        },
    },
    highVolume: {
        label: 'Highest Volume',
        key: 'highVolume',
        queryParams: {
            sort: 'volume',
            order: 'desc',
        },
    },
    lowDifficulty: {
        label: 'Lowest Difficulty',
        key: 'lowDifficulty',
        queryParams: {
            sort: 'difficulty',
            order: 'asc',
        },
    },
};

export const keywordResearchMainSortItems = [
    { label: 'Most Recent', key: 'recent' },
    { label: 'Highest Volume', key: 'highVolume' },
    { label: 'Lowest Difficulty', key: 'lowDifficulty' },
];
