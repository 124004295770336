import React from 'react';
import { useSelector } from 'react-redux';

import { Popover, Typography } from 'antd';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';
import { useCollapse } from '../../../../utils/hooks/useCollapse';
import { useIconColor } from '../../../../utils/hooks/useIconColor';

import VersionsDropdownContent from './VersionsDropdownContent';

import './VersionsContent.scss';

const classes = {
    versionDropdownLabel: 'version-dropdown-label space-no-wrap cursor-pointer',
    versionDropdown: 'version-dropdown',
    versionDropdownOverlay: 'popover-overlay versions-popover-overlay',
    versionDropdownOverlayDark: 'popover-overlay-dark',
    statusText: 'status-text',
    arrowIcon: 'arrow-icon',
};

const VersionDropdown = ({
    disabled,
    loading,
    versions,
    previewVersionId,
    handleActivateVersion,
    createNewVersion,
    handlePreviewVersion,
    newVersionCreating,
    disableCreateNewVersionBtn,
    handleUpdateVersionName,
    handleDeleteVersion,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const iconColor = useIconColor();
    const { collapsed, handleCollapse } = useCollapse();

    const versionDropdownContent = (
        <VersionsDropdownContent
            loading={loading}
            versions={versions}
            previewVersionId={previewVersionId}
            handleActivateVersion={handleActivateVersion}
            handlePreviewVersion={handlePreviewVersion}
            createNewVersion={createNewVersion}
            handleCollapse={handleCollapse}
            newVersionCreating={newVersionCreating}
            disableCreateNewVersionBtn={disableCreateNewVersionBtn}
            handleUpdateVersionName={handleUpdateVersionName}
            handleDeleteVersion={handleDeleteVersion}
        />
    );

    return (
        <Popover
            destroyTooltipOnHide
            open={!disabled ? collapsed : false}
            content={versionDropdownContent}
            trigger={['click']}
            placement='bottomRight'
            className={`${classes.versionDropdown} ${getThemeColorClass(darkMode)}`}
            overlayClassName={`${classes.versionDropdownOverlay}
                               ${darkMode ? classes.versionDropdownOverlayDark : ''}`}
            onOpenChange={!disabled ? handleCollapse : undefined}
        >
            <div
                className={`${classes.versionDropdownLabel} ${disabled ? 'disabled' : ''}`}
                onClick={handleCollapse}
            >
                <Typography className={`${classes.statusText} ${getThemeColorClass(darkMode)}`}>
                    Version
                </Typography>
                <span
                    className={classes.arrowIcon}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleCollapse();
                    }}
                >
                    {collapsed && !disabled ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )}
                </span>
            </div>
        </Popover>
    );
};

export default VersionDropdown;
