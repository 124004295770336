import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import errorImage from '../../../resources/images/error-404.gif';

import GoBackErrorButton from './GoBackErrorButton';

import './ErrorPages.scss';

const classes = {
    root: 'error-page-root',
    mainArticle: 'main-article',
    secondArticle: 'second-article',
};

const NotFoundPage = () => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.root} ${darkMode ? 'dark-mode' : ''}`}>
            <img src={errorImage} alt='not found' />
            <Typography
                className={`${classes.mainArticle} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                Oooh, you almost had it Skippy...
            </Typography>
            <Typography
                className={`${classes.secondArticle} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                But we can't seem to find what you're looking for.
            </Typography>
            <GoBackErrorButton btnText='Shake it off and go back' />
        </div>
    );
};

export default NotFoundPage;
