import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Card, Empty, Spin } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../constants/queryPathes';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../../utils/axiosAPI';
import { compareRanks } from '../../../../../../../../../utils/helpers/compareRanks';
import { getAxiosHeaders } from '../../../../../../../../../utils/helpers/getAxiosHeaders';
import { getRandomId } from '../../../../../../../../../utils/helpers/idGenerator';
import { openNotification } from '../../../../../../../../../utils/helpers/openNotification';

import Loader from '../../../../../../../../common/loader/Loader';
import RankTitle from '../../../../briefTab/content/tabsContent/outline/ranks/rankItem/RankTitle';

const classes = {
    examplesModalContentWrapper: 'examples-modal-content-wrapper',
    rankItem: 'rank-item',
    exampleSentence: 'example-sentence',
    examplesHighlitedKeyword: 'examples-highlited-keyword',
    spinSkeleton: 'examples-spin-skeleton',
    noDataWrapper: 'no-data-wrapper',
};

const ExamplesModalContent = ({ keywordItem }) => {
    const { contentOptimizerId } = useParams();

    const [examplesData, setExamplesData] = useState([]);

    const darkMode = useSelector(selectDarkMode);

    useEffect(() => {
        if (contentOptimizerId) {
            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}`,
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    const mapedExamplesData = result?.data?.task_result
                        ? JSON.parse(result.data.task_result)
                              ?.sort((a, b) => compareRanks(a, b))
                              ?.map((example) => {
                                  const filteredSentences =
                                      getFilteredSentences(
                                          example,
                                          keywordItem
                                      );
                                  return {
                                      filteredSentences: filteredSentences,
                                      ...example,
                                  };
                              })
                        : [];
                    setExamplesData(mapedExamplesData);
                })
                .catch((error) => {
                    console.log(error);
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentOptimizerId]);

    const reArrangeRows = (splitedParagraph = []) => {
        const isContinueSentencePattern = /(^[ \s]+[a-z]+)|^[a-z&-,:+']+\s*/;
        const isEndOfSentencePattern = /(?:[.?!])/g;

        const rearrangedRows = [];

        for (let i = 0; i < splitedParagraph.length; i++) {
            const currentRow = splitedParagraph[i];
            const lastChar = currentRow.substr(currentRow.length - 1);
            const rowEndMatches = lastChar.match(isEndOfSentencePattern);

            if (i === 0 || !rowEndMatches || rowEndMatches.length < 1) {
                let combinedRow = currentRow;

                // Check if the current row and the next row start with ',' or a lowercase word
                while (
                    i + 1 < splitedParagraph.length &&
                    !combinedRow
                        .substr(combinedRow.length - 1)
                        .match(isEndOfSentencePattern) &&
                    isContinueSentencePattern.test(splitedParagraph[i + 1])
                ) {
                    const separator =
                        splitedParagraph[i + 1].startsWith(',') ||
                        splitedParagraph[i + 1].startsWith(':')
                            ? ''
                            : ' ';
                    combinedRow += separator + splitedParagraph[i + 1];
                    i++;
                }

                rearrangedRows.push(combinedRow);
            } else {
                rearrangedRows.push(currentRow);
            }
        }

        return rearrangedRows;
    };

    const findKeywordMatchesInSentence = (keywordItem, sentence = '') => {
        let matchedSentence = null;

        if (!keywordItem || !sentence) {
            return matchedSentence;
        }

        const lowerCasedSentence = sentence.toLowerCase();
        const lowerCasedKeyword = keywordItem.keyword?.toLowerCase();

        /** There is try-catch block to fix broken regex expression
         * Important to search by regex first, then by keyword spelling
         */
        try {
            matchedSentence = lowerCasedSentence.match(keywordItem.regex);

            if (!matchedSentence) {
                matchedSentence = lowerCasedSentence.match(lowerCasedKeyword);
            }
        } catch (_err) {
            matchedSentence = lowerCasedSentence.match(lowerCasedKeyword);
        }

        return matchedSentence;
    };

    const getFilteredSentences = (rankItem, keywordItem) => {
        if (rankItem?.content && (keywordItem?.regex || keywordItem?.keyword)) {
            const maxSentenceWordsCount = 250;
            let filteredSentences = [];
            let dividedSentences = [];

            const splitedParagraph =
                rankItem.content.toString().split('\n') || [];
            const reArrangedRows = reArrangeRows(splitedParagraph);

            reArrangedRows.forEach((inputString) => {
                const regex = /(?:[.?!] )/g;

                const splitSentences = inputString.split(regex);

                dividedSentences = dividedSentences.concat(
                    splitSentences.filter((sentence) => {
                        return sentence.trim() !== '';
                    })
                );
            });

            // For duplicates filtering
            const textSentences = Array.from(new Set(dividedSentences));

            textSentences?.forEach((sentence) => {
                const matchedSentence = findKeywordMatchesInSentence(
                    keywordItem,
                    sentence
                );

                if (
                    matchedSentence &&
                    !(
                        matchedSentence.index === 0 &&
                        matchedSentence[0] === matchedSentence.input
                    )
                ) {
                    const words = sentence?.split(' ') || [];

                    if (words.length <= maxSentenceWordsCount) {
                        filteredSentences.push({
                            startIndex: matchedSentence.index,
                            keywordLength: matchedSentence[0]?.length,
                            sentence: sentence.replace(/\.{2,}/g, (match) =>
                                match === '..' ? '.' : match
                            ),
                        });
                    } else {
                        const keywordIndex = words.indexOf(matchedSentence[0]);

                        const startIndex = Math.max(0, keywordIndex - 17);
                        const endIndex = Math.min(
                            words.length,
                            keywordIndex + 18
                        );

                        let truncatedText = words
                            .slice(startIndex, endIndex)
                            .join(' ');

                        if (startIndex > 0) {
                            truncatedText = '...' + truncatedText;
                        }
                        if (endIndex < words.length) {
                            truncatedText += '...';
                        }

                        const truncatedMatchSentence =
                            findKeywordMatchesInSentence(
                                keywordItem,
                                truncatedText
                            );

                        if (truncatedMatchSentence) {
                            filteredSentences.push({
                                startIndex: truncatedMatchSentence.index,
                                keywordLength:
                                    truncatedMatchSentence[0]?.length,
                                sentence: truncatedText.replace(
                                    /\.{2,}/g,
                                    (match) => (match === '..' ? '.' : match)
                                ),
                            });
                        }
                    }
                }
            });

            return filteredSentences;
        }

        return [];
    };

    const getHighlightSecondaryKeywords = (sentence) => {
        const firstPart = sentence?.sentence.substring(0, sentence?.startIndex);
        const keywordPart = sentence?.sentence.substring(
            sentence?.startIndex,
            sentence?.startIndex + sentence?.keywordLength
        );

        const secondPart = sentence?.sentence.substring(
            sentence?.startIndex + sentence?.keywordLength,
            sentence?.sentence?.length
        );

        const matchedSecondPart = findKeywordMatchesInSentence(
            keywordItem,
            secondPart
        );

        if (matchedSecondPart) {
            const secondPartOfSentence = {
                startIndex: matchedSecondPart?.index,
                keywordLength: matchedSecondPart[0]?.length,
                sentence: secondPart.replace(/\.{2,}/g, (match) =>
                    match === '..' ? '.' : match
                ),
            };

            return (
                <>
                    {firstPart}
                    <span
                        className={`${classes.examplesHighlitedKeyword} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        {keywordPart}
                    </span>
                    {getHighlightSecondaryKeywords(secondPartOfSentence)}
                </>
            );
        } else {
            return (
                <>
                    {firstPart}
                    <span
                        className={`${classes.examplesHighlitedKeyword} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        {keywordPart}
                    </span>
                    {secondPart}
                </>
            );
        }
    };

    return (
        <Spin
            spinning={!examplesData?.length}
            indicator={<Loader />}
            className={classes.spinSkeleton}
        >
            {examplesData?.filter(
                (rank) => rank?.filteredSentences?.length > 0 && rank?.title
            )?.length > 0 ? (
                <div className={classes.examplesModalContentWrapper}>
                    {examplesData
                        ?.filter(
                            (rank) =>
                                rank?.filteredSentences?.length > 0 &&
                                rank?.title
                        )
                        ?.map((rank, index) => {
                            return (
                                <Card
                                    key={index}
                                    title={<RankTitle rank={rank} />}
                                    bordered
                                    className={classes.rankItem}
                                >
                                    {rank?.filteredSentences
                                        ?.slice(0, 3)
                                        ?.map((sentence) => {
                                            return (
                                                <div
                                                    key={getRandomId()}
                                                    className={`${
                                                        classes.exampleSentence
                                                    } ${
                                                        darkMode
                                                            ? 'dark-mode'
                                                            : ''
                                                    }`}
                                                >
                                                    {getHighlightSecondaryKeywords(
                                                        sentence
                                                    )}
                                                </div>
                                            );
                                        })}
                                </Card>
                            );
                        })}
                </div>
            ) : (
                <div className={classes.noDataWrapper}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
        </Spin>
    );
};

export default ExamplesModalContent;
