import React from 'react';

import BriefTabContent from './content/BriefTabContent';

import './BriefTab.scss';

const BriefTabContainer = () => {
    return <BriefTabContent />;
};

export default BriefTabContainer;
