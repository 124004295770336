import React from 'react';

import CustomHeader from '../../../../common/header/Header';

const ContentOptimizerCreatePageHeader = () => {
    return (
        <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            showSearch
            showViewMode
            extraBreadcrumbs={['Create New']}
        />
    );
};

export default ContentOptimizerCreatePageHeader;
