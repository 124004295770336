import React from 'react';
import { useSelector } from 'react-redux';

import { intentCategories } from '../../../../constants/intentCategories';
import { colorPalette } from '../../../../resources/styles/colorPalette';
import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import ColorBadge from './ColorBadge';

const IntentBadge = ({
    value,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const getBadgeColorAndTitle = () => {
        const currentCategory = intentCategories.find((category) => [category.value, category.category, category.key].includes(value));

        if (!currentCategory) {
            const color = darkMode
                ? colorPalette.noScoreBadgeColor.dark
                : colorPalette.noScoreBadgeColor.light;

            return { color, title: null };
        }

        return { color: currentCategory.color, title: currentCategory.value };
    };

    const { color, title } = getBadgeColorAndTitle(value);

    return (
        <ColorBadge
            value={title}
            backgroundColor={color}
            isInline
            size='small'
            marginRight={10}
        />
    );
};

export default IntentBadge;
