export const getUnfinishedBriefIds = (briefs) => {
    return briefs
        ?.filter(
            (item) =>
                !(
                    item?.queue_status === 'completed' ||
                    item?.queue_status === 'failed' ||
                    !item?.queue_status
                )
        )
        .map((item) => item?.id);
};

export const getNotScoredBriefIdsWithContent = (briefs) => {
    return briefs
        ?.filter(
            (item) =>
                item?.word_count === 0 &&
                item?.rankability_score === 0 &&
                item?.is_content === true &&
                item?.queue_status === 'completed'
        )
        .map((item) => item?.id);
};
