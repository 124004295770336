export const selectUserRegistrationData = (state) =>
    state?.authSettings?.userRegistrationData;

export const selectStarterPlanData = (state) =>
    state?.authSettings?.starterPlanData;

export const selectCustomerId = (state) => state?.authSettings?.customerId;

export const selectUserRegistrationEmail = (state) =>
    state.authSettings.userEmail;
