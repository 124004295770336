import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import parse from 'html-react-parser';

import AiResponseConfirmModal from './AiResponseConfirmModal';
import AiResponseFooter, { AI_RESPONSE_FOOTER_CLASS } from './AiResponseFooter';
import AiResponseOptionItem from './AiResponseOptionItem';

import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';
import { useOpen } from '../../../../../utils/hooks/useOpen';
import useAiResponseItems from '../../hooks/useAiResponseItems';
import {
    extractBodyContent,
    getHTMLBetweenRange,
    highlightHtmlDifferences,
} from '../../utils/aiSpellCheckerHelpers';

import { aiSpellCheckerOptionKeys } from '../../constants/aiEditorConstants';
import { nodeTypes } from '../../constants/nodeTypes';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectAIStateAccepted,
    selectAIStateLoading,
    selectAIStateResponse,
} from '../../store/tiptapEditor.selectors';

export const OPTIONS_MENU_CLASS = 'ai-options-menu';
const MODAL_WRAPPER_CLASS = 'ai-response-confirm-modal';

const classes = {
    optionsMenuWrapper: `${OPTIONS_MENU_CLASS} positioned-container`,
    responseWrapper: 'ai-response-wrapper positioned-container',
    responseContent: 'ai-response-content',
    modalWrapper: `${MODAL_WRAPPER_CLASS}`,
};

const AiResponseContent = ({ from, to }) => {
    const responseRef = useRef(null);

    const response = useSelector(selectAIStateResponse);

    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);
    const aiAccepted = useSelector(selectAIStateAccepted);
    const aiLoading = useSelector(selectAIStateLoading);

    const { isOpen: isConfirmModalOpen, handleOpen: handleConfirmModalOpen } = useOpen();
    const { aiResponseItems, handleReject } = useAiResponseItems(from, to, response);

    const parsedResponse = useMemo(() => {
        const extractedContent = extractBodyContent(response);

        if (
            editor.storage[nodeTypes.aiSpellChecker].activeAiCommand ===
            aiSpellCheckerOptionKeys.spellingGrammar
        ) {
            const selectedHTML = getHTMLBetweenRange(editor, from, to);

            return parse(highlightHtmlDifferences(editor, selectedHTML, extractedContent));
        }

        return parse(extractedContent);
    }, [response, editor, from, to]);

    const preventExternalActions = useCallback(
        (event) => {
            handleConfirmModalOpen();
            event.preventDefault();
            event.stopPropagation();
        },
        [handleConfirmModalOpen]
    );

    const handleClickOutside = useCallback(
        (event) => {
            if (
                !event.target.closest(`.${OPTIONS_MENU_CLASS}`) &&
                !event.target.closest(`.${MODAL_WRAPPER_CLASS}`) &&
                !event.target.closest(`.${AI_RESPONSE_FOOTER_CLASS}`)
            ) {
                preventExternalActions(event);
            }
        },
        [preventExternalActions]
    );

    const handleKeyDown = useCallback(
        (event) => {
            preventExternalActions(event);
        },
        [preventExternalActions]
    );

    useEffect(() => {
        if (responseRef.current) {
            responseRef.current.scrollTop = 0;
        }
    }, [response]);

    useEffect(() => {
        if (!aiAccepted && !aiLoading) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleKeyDown, true);

            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleKeyDown, true);
            };
        }
    }, [aiAccepted, aiLoading, handleClickOutside, handleKeyDown]);

    if (aiLoading) {
        return null;
    }

    return (
        <>
            <AiResponseConfirmModal
                isOpen={isConfirmModalOpen}
                handleCancel={handleConfirmModalOpen}
                handleDiscard={handleReject}
                wrapClassName={classes.modalWrapper}
            />
            <div className={`${classes.responseWrapper} ${getThemeColorClass(darkMode)}`}>
                <div ref={responseRef} className={classes.responseContent}>
                    {parsedResponse}
                </div>
                <AiResponseFooter response={response} />
            </div>
            <div className={`${classes.optionsMenuWrapper} ${getThemeColorClass(darkMode)}`}>
                {aiResponseItems.map((item) => (
                    <AiResponseOptionItem key={item.key} aiResponseOption={item} />
                ))}
            </div>
        </>
    );
};

export default AiResponseContent;
