import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    contentOptimizerMainSortItems,
    contentOptimizerMainSortOptions,
} from '../../../../../constants/contentOptimizerMainSortOptions';

import CustomHeader from '../../../../common/header/Header';

import { updateContentOptimizerMainQueryParam } from '../store/contentOptimizerMain.actions';
import { selectContentOptimizerMainQueryParams } from '../store/contentOptimizerMain.selectors';

const ContentOptimizerMainPageHeader = () => {
    const dispatch = useDispatch();

    const optimizerQueryParams = useSelector(
        selectContentOptimizerMainQueryParams
    );
    const searchQuery = optimizerQueryParams.search;

    const [searchValue, setSearchValue] = useState('');
    const [selectedSort, setSelectedSort] = useState(
        contentOptimizerMainSortOptions.recent.key
    );

    useEffect(() => {
        if (!searchQuery) {
            setSearchValue('');
        }
    }, [searchQuery]);

    useEffect(() => {
        const updateOptimizerMainParams = setTimeout(() => {
            dispatch(
                updateContentOptimizerMainQueryParam({
                    search: searchValue.trim(),
                    page: 1,
                })
            );
        }, 1500);

        return () => clearTimeout(updateOptimizerMainParams);

        // eslint-disable-next-line
    }, [searchValue]);

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    useEffect(() => {
        const selectedSortKey = Object.keys(
            contentOptimizerMainSortOptions
        ).find(
            (key) =>
                contentOptimizerMainSortOptions[key].queryParams.sort ===
                    optimizerQueryParams.sort &&
                contentOptimizerMainSortOptions[key].queryParams.order ===
                    optimizerQueryParams.order
        );

        setSelectedSort(selectedSortKey);
    }, [optimizerQueryParams]);

    const handleSort = (value) => {
        setSelectedSort(value);
        dispatch(
            updateContentOptimizerMainQueryParam({
                ...contentOptimizerMainSortOptions[value].queryParams,
            })
        );
    };

    return (
        <CustomHeader
            showViewMode
            showSort
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            showSearch
            handleSearch={handleSearch}
            defaultSearchValue={searchQuery}
            searchValue={searchValue}
            sortItems={contentOptimizerMainSortItems}
            selectedSort={selectedSort}
            handleSort={handleSort}
        />
    );
};

export default ContentOptimizerMainPageHeader;
