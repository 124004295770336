import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import EditIcon from '../../../resources/icons/EditIcon';
import SiderChevronDownIcon from '../../../resources/icons/SiderChevronDown';
import SiderChevronLeftIcon from '../../../resources/icons/SiderChevronLeft';
import SiderChevronRightIcon from '../../../resources/icons/SiderChevronRight';
import SiderChevronUpIcon from '../../../resources/icons/SiderChevronUp';
import UnorderedListIcon from '../../../resources/icons/editor/UnorderedListIcon';
import RemoveIcon from '../../../resources/icons/outline/RemoveIcon';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import CustomButtonsTooltip from '../../pages/contentOptimizer/contentPage/content/optimizerTab/editor/content/toolbar/buttons/CustomButtonsTooltip';

import { OUTLINE_BUTTONS_TITLES, getOutlineButtonsSubtitle } from './constants';

const classes = {
    editableOutlineButtons: 'editable-outline-buttons',
    outlineButton: 'editable-outline-button',

    tooltipOverlay: 'toolbar-tooltip-overlay tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const EditableOutlineButtons = ({
    increaseTag,
    decreaseTag,
    removeTag,
    addTag,

    moveUpTag,
    moveDownTag,
    editTag,
    setBulletTag,
}) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.editableOutlineButtons}`}>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.moveUp}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.moveUp
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={moveUpTag}
                        icon={
                            <SiderChevronUpIcon
                                color='#9199AD'
                                strokeWidth={'0.9'}
                            />
                        }
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.moveDown}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.moveDown
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={moveDownTag}
                        icon={
                            <SiderChevronDownIcon
                                color='#9199AD'
                                strokeWidth={'0.9'}
                            />
                        }
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.promoteHeading}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.promoteHeading
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={increaseTag}
                        icon={
                            <SiderChevronLeftIcon
                                color='#9199AD'
                                strokeWidth={'0.9'}
                            />
                        }
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.demoteHeading}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.demoteHeading
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={decreaseTag}
                        icon={
                            <SiderChevronRightIcon
                                color='#9199AD'
                                strokeWidth={'0.9'}
                            />
                        }
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.addNewHeading}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.addNewHeading
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={addTag}
                        className={`${classes.outlineButton}`}
                    >
                        H
                    </Button>
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.bulletPoint}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.bulletPoint
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={setBulletTag}
                        icon={<UnorderedListIcon color='#9199AD' />}
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.edit}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.edit
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={editTag}
                        icon={<EditIcon />}
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>

            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={
                    <CustomButtonsTooltip
                        title={OUTLINE_BUTTONS_TITLES.delete}
                        subtitle={getOutlineButtonsSubtitle(
                            OUTLINE_BUTTONS_TITLES.delete
                        )}
                    />
                }
            >
                <>
                    <Button
                        onClick={removeTag}
                        icon={<RemoveIcon />}
                        className={`${classes.outlineButton}`}
                    />
                </>
            </Tooltip>
        </div>
    );
};

export default EditableOutlineButtons;
