import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Checkbox, Typography } from 'antd';

import CheckedMonitorsItemsActionsPanel from '../../../../../common/checkedMonitorsItemsActionsPanel/CheckedMonitorsItemsActionsPanel';
import ContentMonitorTable from './ContentMonitorTable';

import { subscriptionLimits } from '../../../../../../constants/subscriptionLimits';

import { decreaseSubscriptionLimits } from '../../../../account/billing/store/billingSettings.actions';
import {
    removeContentMonitorChartData,
    removeContentMonitorData,
    setContentMonitorChecked,
    updateContentMonitorData,
} from '../../store/contentMonitorMain.actions';
import {
    selectContentMonitorData,
    selectContentMonitorsChecked,
} from '../../store/contentMonitorMain.selectors';

import './ContentMonitorTable.scss';

const { Title } = Typography;

const classes = {
    listHeaderWrapper:
        'monitor-list-header-wrapper d-flex justify-content-between align-items-center',
    listHeaderTitleWrapper: 'list-header-title-wrapper d-flex',
    listHeaderTitle: 'list-header-title',
    contentMonitorTableSection: 'content-monitor-table-section',
};

const ContentMonitorTableSection = ({ isLoading }) => {
    const dispatch = useDispatch();

    const contentMonitorData = useSelector(
        selectContentMonitorData,
        shallowEqual
    );
    const checkedMonitors = useSelector(selectContentMonitorsChecked);

    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);

    useEffect(() => {
        setSelectedProjects(
            contentMonitorData
                ?.filter((item) => checkedMonitors.includes(item?.id))
                ?.map((item) => item?.cm_projects)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedMonitors?.length, contentMonitorData?.length]);

    useEffect(() => {
        setIndeterminate(
            checkedMonitors.length &&
                checkedMonitors.length !== contentMonitorData?.length
        );
        setCheckAll(
            checkedMonitors.length &&
                checkedMonitors.length === contentMonitorData.length
        );
    }, [checkedMonitors, contentMonitorData?.length]);

    const onCheckAllChange = (e) => {
        dispatch(
            setContentMonitorChecked(
                e.target.checked
                    ? contentMonitorData?.map((item) => item.id)
                    : []
            )
        );
        setCheckAll(e.target.checked);
    };

    const handleChangeCheckbox = (values) => {
        dispatch(setContentMonitorChecked(values));
    };

    const handleMonitorsBulkDelete = useCallback(
        (deleteData) => {
            batch(() => {
                dispatch(removeContentMonitorData(deleteData));
                dispatch(removeContentMonitorChartData(deleteData));
                dispatch(setContentMonitorChecked([]));
                dispatch(
                    decreaseSubscriptionLimits({
                        limit: subscriptionLimits.contentMonitorsUsed,
                        value: checkedMonitors?.length,
                    })
                );
            });
        },
        [checkedMonitors, dispatch]
    );

    const handleUpdateMonitors = useCallback(
        (updatedMonitorsList) => {
            batch(() => {
                dispatch(updateContentMonitorData(updatedMonitorsList));
                dispatch(setContentMonitorChecked([]));
            });
        },
        [dispatch]
    );

    return (
        <div className={classes.contentMonitorTableSection}>
            <div className={classes.listHeaderWrapper}>
                <div className={classes.listHeaderTitleWrapper}>
                    {contentMonitorData?.length > 0 && (
                        <div className='d-flex align-items-center'>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                                disabled={isLoading}
                            />
                            <Title
                                level={3}
                                className={classes.listHeaderTitle}
                            >
                                Recent Monitors
                            </Title>
                        </div>
                    )}
                    {checkedMonitors?.length > 0 && (
                        <CheckedMonitorsItemsActionsPanel
                            checkedItems={checkedMonitors}
                            selectedProjects={selectedProjects}
                            handleDeleteAction={handleMonitorsBulkDelete}
                            handleUpdateProjects={handleUpdateMonitors}
                        />
                    )}
                </div>
            </div>
            <Checkbox.Group
                value={checkedMonitors}
                onChange={handleChangeCheckbox}
            >
                <ContentMonitorTable />
            </Checkbox.Group>
        </div>
    );
};

export default ContentMonitorTableSection;
