export const defaultCountry = 'US';

export const featuredCountries = [
    'US',
    'AU',
    'CA',
    'FR',
    'DE',
    'IN',
    'IT',
    'MX',
    'NL',
    'ES',
    'GB',
];

export const countries = {
    AD: 'Andorra (google.com)',
    AE: 'United Arab Emirates (google.ae)',
    AF: 'Afghanistan (google.com.af)',
    AG: 'Antigua and Barbuda (google.com)',
    AL: 'Albania (google.com)',
    AM: 'Armenia (google.com)',
    AO: 'Angola (google.co.ao)',
    AR: 'Argentina (google.com.ar)',
    AS: 'American Samoa (google.com)',
    AT: 'Austria (google.at)',
    AU: 'Australia (google.com.au)',
    AZ: 'Azerbaijan (google.az)',
    BA: 'Bosnia and Herzegovina (google.com)',
    BB: 'Barbados (google.com)',
    BD: 'Bangladesh (google.com.bd)',
    BE: 'Belgium (google.be)',
    BF: 'Burkina Faso (google.com)',
    BG: 'Bulgaria (google.bg)',
    BH: 'Bahrain (google.com)',
    BI: 'Burundi (google.com)',
    BJ: 'Benin (google.com)',
    BL: 'Saint Barthelemy (google.com)',
    BN: 'Brunei (google.com.bn)',
    BO: 'Bolivia (google.com.bo)',
    BQ: 'Caribbean Netherlands (google.com)',
    BR: 'Brazil (google.com.br)',
    BS: 'The Bahamas (google.com)',
    BT: 'Bhutan (google.com)',
    BW: 'Botswana (google.com)',
    BZ: 'Belize (google.com)',
    CA: 'Canada (google.ca)',
    CC: 'Cocos (Keeling) Islands (google.com)',
    CD: 'Democratic Republic of the Congo (google.com)',
    CF: 'Central African Republic (google.com)',
    CG: 'Republic of the Congo (google.com)',
    CH: 'Switzerland (google.ch)',
    CI: "Cote d'Ivoire (google.com)",
    CK: 'Cook Islands (google.com)',
    CL: 'Chile (google.cl)',
    CM: 'Cameroon (google.com)',
    CN: 'China (google.com.hk)',
    CO: 'Colombia (google.com.co)',
    CR: 'Costa Rica (google.co.cr)',
    CV: 'Cape Verde (google.com)',
    CW: 'Curacao (google.com)',
    CX: 'Christmas Island (google.com)',
    CY: 'Cyprus (google.com.cy)',
    CZ: 'Czechia (google.cz)',
    DE: 'Germany (google.de)',
    DJ: 'Djibouti (google.com)',
    DK: 'Denmark (google.dk)',
    DM: 'Dominica (google.com)',
    DO: 'Dominican Republic (google.com)',
    DZ: 'Algeria (google.com)',
    EC: 'Ecuador (google.com)',
    EE: 'Estonia (google.ee)',
    EG: 'Egypt (google.com.eg)',
    ER: 'Eritrea (google.com)',
    ES: 'Spain (google.es)',
    ET: 'Ethiopia (google.com)',
    FI: 'Finland (google.fi)',
    FJ: 'Fiji (google.com)',
    FM: 'Federated States of Micronesia (google.com)',
    FR: 'France (google.fr)',
    GA: 'Gabon (google.com)',
    GB: 'United Kingdom (google.co.uk)',
    GD: 'Grenada (google.com)',
    GE: 'Georgia (google.com)',
    GG: 'Guernsey (google.com)',
    GH: 'Ghana (google.com.gh)',
    GM: 'The Gambia (google.com)',
    GN: 'Guinea (google.com)',
    GQ: 'Equatorial Guinea (google.com)',
    GR: 'Greece (google.com.gr)',
    GS: 'South Georgia and the South Sandwich Islands (google.com)',
    GT: 'Guatemala (google.com)',
    GU: 'Guam (google.com)',
    GW: 'Guinea-Bissau (google.com)',
    GY: 'Guyana (google.com)',
    HM: 'Heard Island and McDonald Islands (google.com)',
    HN: 'Honduras (google.hn)',
    HR: 'Croatia (google.hr)',
    HT: 'Haiti (google.com)',
    HU: 'Hungary (google.hu)',
    ID: 'Indonesia (google.co.id)',
    IE: 'Ireland (google.ie)',
    IL: 'Israel (google.co.il)',
    IN: 'India (google.co.in)',
    IQ: 'Iraq (google.iq)',
    IS: 'Iceland (google.com)',
    IT: 'Italy (google.it)',
    JE: 'Jersey (google.com)',
    JM: 'Jamaica (google.com.jm)',
    JO: 'Jordan (google.com)',
    JP: 'Japan (google.co.jp)',
    KE: 'Kenya (google.co.ke)',
    KG: 'Kyrgyzstan (google.kg)',
    KH: 'Cambodia (google.com.kh)',
    KI: 'Kiribati (google.com)',
    KM: 'Comoros (google.com)',
    KN: 'Saint Kitts and Nevis (google.com)',
    KR: 'South Korea (google.co.kr)',
    KW: 'Kuwait (google.com.kw)',
    KZ: 'Kazakhstan (google.kz)',
    LA: 'Laos (google.com)',
    LB: 'Lebanon (google.com)',
    LC: 'Saint Lucia (google.com)',
    LI: 'Liechtenstein (google.com)',
    LK: 'Sri Lanka (google.lk)',
    LR: 'Liberia (google.com)',
    LS: 'Lesotho (google.com)',
    LT: 'Lithuania (google.lt)',
    LU: 'Luxembourg (google.com)',
    LV: 'Latvia (google.lv)',
    LY: 'Libya (google.com.ly)',
    MA: 'Morocco (google.com)',
    MC: 'Monaco (google.com)',
    MD: 'Moldova (google.md)',
    ME: 'Montenegro (google.me)',
    MF: 'Saint Martin (google.com)',
    MG: 'Madagascar (google.com)',
    MH: 'Marshall Islands (google.com)',
    MK: 'North Macedonia (google.mk)',
    ML: 'Mali (google.com)',
    MM: 'Myanmar (Burma) (google.com)',
    MN: 'Mongolia (google.mn)',
    MP: 'Northern Mariana Islands (google.com)',
    MR: 'Mauritania (google.com)',
    MT: 'Malta (google.com.mt)',
    MU: 'Mauritius (google.com)',
    MV: 'Maldives (google.com)',
    MW: 'Malawi (google.com)',
    MX: 'Mexico (google.com.mx)',
    MY: 'Malaysia (google.com.my)',
    MZ: 'Mozambique (google.co.mz)',
    NA: 'Namibia (google.com)',
    NC: 'New Caledonia (google.com)',
    NE: 'Niger (google.com)',
    NF: 'Norfolk Island (google.com)',
    NG: 'Nigeria (google.com.ng)',
    NI: 'Nicaragua (google.com.ni)',
    NL: 'Netherlands (google.nl)',
    NO: 'Norway (google.com)',
    NP: 'Nepal (google.com)',
    NR: 'Nauru (google.com)',
    NU: 'Niue (google.com)',
    NZ: 'New Zealand (google.co.nz)',
    OM: 'Oman (google.com)',
    PA: 'Panama (google.com)',
    PE: 'Peru (google.com.pe)',
    PF: 'French Polynesia (google.com)',
    PG: 'Papua New Guinea (google.com)',
    PH: 'Philippines (google.com.ph)',
    PK: 'Pakistan (google.com.pk)',
    PL: 'Poland (google.pl)',
    PM: 'Saint Pierre and Miquelon (google.com)',
    PN: 'Pitcairn Islands (google.com)',
    PT: 'Portugal (google.pt)',
    PW: 'Palau (google.com)',
    PY: 'Paraguay (google.com.py)',
    QA: 'Qatar (google.com)',
    RO: 'Romania (google.ro)',
    RS: 'Serbia (google.nl)',
    RW: 'Rwanda (google.com)',
    SA: 'Saudi Arabia (google.com.sa)',
    SB: 'Solomon Islands (google.com)',
    SC: 'Seychelles (google.sc)',
    SD: 'Sudan (google.com)',
    SE: 'Sweden (google.com)',
    SG: 'Singapore (google.com.sg)',
    SH: 'Saint Helena, Ascension and Tristan da Cunha (google.com)',
    SI: 'Slovenia (google.si)',
    SK: 'Slovakia (google.sk)',
    SL: 'Sierra Leone (google.com)',
    SM: 'San Marino (google.sm)',
    SN: 'Senegal (google.com)',
    SO: 'Somalia (google.com)',
    SR: 'Suriname (google.com)',
    SS: 'South Sudan (google.com)',
    ST: 'Sao Tome and Principe (google.com)',
    SV: 'El Salvador (google.com)',
    SX: 'Sint Maarten (google.com)',
    SZ: 'Eswatini (google.com)',
    TD: 'Chad (google.com)',
    TF: 'French Southern and Antarctic Lands (google.com)',
    TG: 'Togo (google.com)',
    TH: 'Thailand (google.co.th)',
    TJ: 'Tajikistan (google.com.tj)',
    TK: 'Tokelau (google.com)',
    TL: 'Timor-Leste (google.com)',
    TM: 'Turkmenistan (google.tm)',
    TN: 'Tunisia (google.com.sa)',
    TO: 'Tonga (google.com)',
    TR: 'Turkey (google.com.tr)',
    TT: 'Trinidad and Tobago (google.com)',
    TV: 'Tuvalu (google.com)',
    TZ: 'Tanzania (google.co.tz)',
    UA: 'Ukraine (google.com.ua)',
    UG: 'Uganda (google.com)',
    UM: 'United States Minor Outlying Islands (google.com)',
    US: 'United States (google.com)',
    UY: 'Uruguay (google.com.uy)',
    UZ: 'Uzbekistan (google.co.uz)',
    VA: 'Vatican City (google.com)',
    VC: 'Saint Vincent and the Grenadines (google.com)',
    VE: 'Venezuela (google.com.mx)',
    VN: 'Vietnam (google.com.vn)',
    VU: 'Vanuatu (google.com)',
    WF: 'Wallis and Futuna (google.com)',
    WS: 'Samoa (google.com)',
    YE: 'Yemen (google.com)',
    ZA: 'South Africa (google.co.za)',
    ZM: 'Zambia (google.com)',
    ZW: 'Zimbabwe (google.co.zw)',
};

export const getCountriesWithDefaultHighlighted = () => {
    let data = { ...countries };
    data[defaultCountry] = `${data[defaultCountry]} (default)`;

    return data;
};
