import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Checkbox, Tag, Typography } from 'antd';
import moment from 'moment';

import { notificationType } from '../../../../../../../constants/notificationType';
import { contentMonitorPathes } from '../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../constants/statusCodes';
import { subscriptionLimits } from '../../../../../../../constants/subscriptionLimits';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../../../utils/helpers/openBasicErrorNotification';
import { openNotification } from '../../../../../../../utils/helpers/openNotification';
import { getThemeColorClass } from '../../../../../../../utils/helpers/themeHelpers';

import CancelCreationButton from '../../../../../../common/buttons/CancelCreationButton';
import RankabilityLoader from '../../../../../../common/rankabilityLoader/RankabilityLoader';

import { decreaseSubscriptionLimits } from '../../../../../account/billing/store/billingSettings.actions';
import {
    removeContentMonitorData,
    updateContentMonitorData,
} from '../../../store/contentMonitorMain.actions';

const { Link } = Typography;

const classes = {
    alignValue: 'd-flex align-items-center',
    alignValueColumn: 'aling-value-column',

    monitorTableCheckbox: 'monitor-table-checkbox',

    projectsListWrapper: 'd-flex w-100',
    projectsWrapper: 'monitor-projects-wrapper d-flex align-items-center',

    cmRankLoaderWrapper: 'monitor-rank-loader-wrapper',

    monitoredPageUrl: 'monitored-page-url',
    monitorNameWrapper: 'monitor-name-wrapper',
    monitorNameEllipsis: 'monitor-name-ellipsis',
    monitorLinkEllipsis: 'monitor-link-ellipsis',

    disabledText: 'monitor-disabled-text',
};

const MonitorPageRowColumn = ({ record }) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const themeColorClass = getThemeColorClass(darkMode);

    const isMonitorCompleted =
        record?.queue_status === 'completed' ||
        record?.queue_status === 'failed' ||
        !record?.queue_status;

    const [renderCancelButton, setRenderCancelButton] = useState(false);

    useEffect(() => {
        let timer = null;

        const canceledSeconds = moment
            .utc()
            .local()
            .diff(moment.utc(record?.created_at).local(), 'seconds');

        if (canceledSeconds <= 30) {
            setRenderCancelButton(true);

            timer = setTimeout(() => {
                setRenderCancelButton(false);
            }, (30 - canceledSeconds) * 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [record]);

    const cancelItem = () => {
        if (record?.id) {
            // We use optimistic item cancellation
            dispatch(updateContentMonitorData({ id: record?.id, canceled: true }));

            axiosAPI
                .delete(contentMonitorPathes.baseContentMonitorUrl, {
                    ...getAxiosHeaders(),
                    data: {
                        ids: [record.id],
                    },
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            dispatch(removeContentMonitorData(result.data));
                            dispatch(
                                decreaseSubscriptionLimits({
                                    limit: subscriptionLimits.contentMonitorsUsed,
                                    value: result.data?.deleted?.length,
                                })
                            );
                        });

                        openNotification(
                            notificationType.success,
                            'Success',
                            'Canceled successfully'
                        );
                    }
                })
                .catch((_err) => {
                    dispatch(
                        updateContentMonitorData({
                            id: record.id,
                            canceled: false,
                        })
                    );

                    openBasicErrorNotification();
                });
        }
    };

    if (!isMonitorCompleted) {
        return (
            <div className={classes.cmRankLoaderWrapper}>
                <Checkbox
                    value={record?.id}
                    className={`${classes.monitorTableCheckbox} ${themeColorClass}`}
                />

                <RankabilityLoader
                    percentageStatus={record?.queue_progress}
                    optimizerStatus={record?.queue_status}
                    startedStatus={record?.queue_progress}
                    customWidth={'fit-content'}
                />
                {renderCancelButton && (
                    <CancelCreationButton
                        popconfirmTitle='Are you sure you want to cancel this Monitor?'
                        creationDate={record?.created_at}
                        handleCancel={cancelItem}
                    />
                )}
            </div>
        );
    } else {
        return (
            <div className={classes.alignValue}>
                <Checkbox
                    value={record?.id}
                    className={`${classes.monitorTableCheckbox} ${themeColorClass}`}
                />
                <div className={classes.alignValueColumn}>
                    <p className={record?.disabled ? classes.disabledText : ''}>
                        {record?.monitoredPage?.title}
                    </p>

                    <div className={classes.monitorNameWrapper}>
                        <div
                            className={`${classes.monitorNameEllipsis} ${classes.monitorLinkEllipsis}`}
                        >
                            <Link
                                href={record?.monitoredPage?.link}
                                target='_blank'
                                rel='noreferrer'
                                className={classes.monitoredPageUrl}
                            >
                                {record?.monitoredPage?.link}
                            </Link>
                        </div>
                    </div>

                    <div className={classes.projectsListWrapper}>
                        {record?.projects?.length > 0 && (
                            <div className={`${classes.projectsWrapper} ${themeColorClass}`}>
                                {record?.projects.map((project) => (
                                    <Tag color='processing' key={project.id}>
                                        {project.name}
                                    </Tag>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default MonitorPageRowColumn;
