import React from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    viewOnlyCard: 'view-only-card',
    viewOnlyCardDarkMode: 'view-only-card-dark-mode',
    activeCard: 'active-view-only-card',
};

const ViewOnlyCard = ({ title = 'Card title', active, content, width }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Card
            title={title}
            bordered={false}
            className={`${classes.viewOnlyCard} ${
                darkMode ? classes.viewOnlyCardDarkMode : ''
            } ${active && classes.activeCard}`}
        >
            {content}
        </Card>
    );
};

export default ViewOnlyCard;
