import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { errorNotificationMessage, notificationType } from '../../constants/notificationType';
import { paymentsPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';

import { selectPaymentMethod } from '../../components/pages/account/billing/store/billingSettings.selectors';
import { setPaymentMethod } from '../../components/pages/account/billing/store/billingSettings.actions';

import { openNotification } from '../helpers/openNotification';
import { getAxiosHeaders } from '../helpers/getAxiosHeaders';

import { axiosAPI } from '../axiosAPI';

export const usePaymentMethod = () => {
    const dispatch = useDispatch();

    const paymentMethod = useSelector(selectPaymentMethod);

    const [isPaymentMethodLoaded, setPaymentMethodLoaded] = useState(false);

    useEffect(() => {
        if (!paymentMethod && !isPaymentMethodLoaded) {
            setPaymentMethodLoaded(true);

            axiosAPI
                .get(paymentsPathes.getPaymentMethods, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setPaymentMethod(result?.data?.[0]));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setPaymentMethodLoaded(false);
                });
        }

        // eslint-disable-next-line
    }, [paymentMethod]);

    return { paymentMethod, isPaymentMethodLoaded };
};
