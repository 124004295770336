import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, DatePicker } from 'antd';
import moment from 'moment';

import CloseIcon from '../../../../resources/icons/CloseIcon';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';

import CustomDropdown from '../../dropdown/CustomDropdown';
import FilterPopoverButon from '../../dropdown/advancedFilters/FilterPopoverButton';

const { RangePicker } = DatePicker;

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay keyword-popover-overlay',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
    dropdownOverlay: 'dropdown-menu projects-dropdown-overlay',

    rangePicker: 'date-filter-range-picker',
    rangePickerDropdown: 'date-filter-range-picker-dropdown-content',

    dateRangeFilterWrapper: 'date-range-filter-wrapper',
};

const defaultDateLabel = 'Date';

const DateRangeFilterDropdown = ({
    dateRangePickerArray,
    handleApplyDateRangeValue,
}) => {
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);

    const [dateLabel, setDateLabel] = useState(defaultDateLabel);
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    const handleClear = () => {
        setSelectedDateRange(null);

        handleApplyDateRangeValue(null);
    };

    useEffect(() => {
        setDateLabel(
            dateRangePickerArray?.length > 0
                ? `${defaultDateLabel}: ${moment(
                      new Date(dateRangePickerArray[0])
                  ).format('YYYY-MM-DD')}-${moment(
                      new Date(dateRangePickerArray[1])
                  ).format('YYYY-MM-DD')}`
                : defaultDateLabel
        );
    }, [dateRangePickerArray]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleApply = () => {
        handleApplyDateRangeValue(
            selectedDateRange?.length > 0 ? selectedDateRange : null
        );
        handleOpen();
    };

    const handleChangeDate = (newDateRange) => {
        setSelectedDateRange(newDateRange);

        if (!newDateRange) {
            handleApplyDateRangeValue(null);
        }
    };

    const usersFilterContent = (
        <div onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <RangePicker
                    value={selectedDateRange}
                    onChange={handleChangeDate}
                    className={`${classes.rangePicker} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    dropdownClassName={`${classes.rangePickerDropdown} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                />
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div
            className={`${classes.dateRangeFilterWrapper} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <div className={classes.popoverBtn}>
                <CustomDropdown
                    dropdownOpenElement={
                        <FilterPopoverButon
                            label={dateLabel}
                            showArrow
                            isOpen={isOpen}
                            handleOpen={handleOpen}
                        />
                    }
                    withExtraContent
                    dropdownContent={usersFilterContent}
                    dropdownOverlayClassName={classes.popoverOverlay}
                    dropdownTriggersArray={['click']}
                    dropdownPlacement='bottom'
                    isDropdownOpen={isOpen}
                    handleOpenChange={handleOpen}
                />
                {dateRangePickerArray && (
                    <div className={classes.clearBtn} onClick={handleClear}>
                        <CloseIcon color={iconColor} size='16' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DateRangeFilterDropdown;
