import React from 'react';
import { useSelector } from 'react-redux';

import CompleteProgressBar from '../../../../common/completeProgressBar/CompleteProgressBar';

import { selectBriefProgress } from './briefTab/content/tabsContent/store/contentBriefsOutline.selectors';

const classes = {
    briefStatusBar: 'brief-status-bar-wrapper',
};

const BriefStatusBar = () => {
    // eslint-disable-next-line
    const briefProgress = useSelector(selectBriefProgress);

    return (
        <div className={`${classes.briefStatusBar}`}>
            <CompleteProgressBar
                progress={Math.ceil(briefProgress * 100) || 0}
            />
        </div>
    );
};

export default BriefStatusBar;
