import React from 'react';
import { useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import SiderCollapseButton from './siderHeader/SiderCollapseButton';
import SiderLogo from './siderHeader/SiderLogo';
import SiderList from './topMenu/SiderList';

import './Sidebar.scss';
import './siderHeader/SiderHeader.scss';
import './topMenu/TopMenu.scss';
import './bottomMenu/BottomMenu.scss';

const { Sider } = Layout;
const { useToken } = theme;

const classes = {
    siderWrapper: 'sider',
};

const Sidebar = ({ collapsed, handleCollapse }) => {
    const { token } = useToken();

    const darkMode = useSelector(selectDarkMode);

    return (
        <Sider
            style={{
                background: token.colorBgContainer,
            }}
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
            className={`${classes.siderWrapper}
                        ${collapsed && 'sider-collapsed'}
                        ${darkMode ? 'sider-dark' : ''}`}
            trigger={null}
        >
            <SiderCollapseButton
                collapsed={collapsed}
                handleCollapsed={handleCollapse}
            />
            <SiderLogo collapsed={collapsed} />
            <SiderList collapsed={collapsed} />
        </Sider>
    );
};

export default Sidebar;
