import React from 'react';

import { useDifficultyBadgeColor } from '../../../../utils/hooks/useDifficultyBadgeColor';
import ColorBadge from './ColorBadge';


const DifficultyBadge = ({
    difficultyScore,
    size,
    inline,
    marginRight = 0,
}) => {
    // tweak to avoid NaN (in case we pass Math.ceil of undefined/null)
    const score = isNaN(difficultyScore) ? 'N/A' : difficultyScore ?? 'N/A';
    const badgeColor = useDifficultyBadgeColor(parseFloat(score));

    return (
        <ColorBadge
            value={difficultyScore}
            isInline={inline}
            size={size}
            backgroundColor={badgeColor}
            marginRight={marginRight}
        />
    );
};

export default DifficultyBadge;
