import { keywordResearchTabs } from '../../../../constants/keywordResearchTabs';
import { sortOrders } from '../../../../constants/sortOrders';

import { keywordResearchSettingsActions } from './keywordResearchSettings.actions';

export const keywordResearchSettingsInitialState = {
    keywordResearchData: null,
    keywordResearchTab: keywordResearchTabs.keyword.key,
    currentKeyword: null,
    keywordForUpdate: null,
    queryParams: {
        type: keywordResearchTabs.keyword.key,
        search: '',
        page: 1,
        per_page: 20,
        sort: 'created_at',
        order: sortOrders.desc,
    },
    keywordListSort: {
        field: null, // Note: default ordering by 'search_volume' manages on the backend side
        order: sortOrders.desc,
    },
    keywordListPagination: {
        perPage: 50,
        page: 1,
    },
    keywordListSearch: '',
    keywordListFilters: null,

    keywordResearchChecked: [],
};

export const keywordResearchSettingsReducer = (
    state = keywordResearchSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case keywordResearchSettingsActions.CLEAR_KEYWORD_RESEARCH_SETTINGS: {
            return {
                ...keywordResearchSettingsInitialState,
            };
        }

        case keywordResearchSettingsActions.REMOVE_KEYWORDS_RESEARCH: {
            return {
                ...state,
                keywordResearchData: state.keywordResearchData.filter(
                    (item) => !payload.includes(item.id)
                ),
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORDS_RESEARCH_CHECKED: {
            return {
                ...state,
                keywordResearchChecked: payload,
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORD_RESEARCH_DATA: {
            return {
                ...state,
                keywordResearchData: payload,
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORD_LIST_PAGINATION: {
            return {
                ...state,
                keywordListPagination: {
                    ...state.keywordListPagination,
                    ...payload,
                },
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORD_LIST_SEARCH: {
            return {
                ...state,
                keywordListSearch: payload,
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORD_LIST_SORT: {
            return {
                ...state,
                keywordListSort: payload,
            };
        }

        case keywordResearchSettingsActions.SET_INITIAL_KEYWORD_LIST_SORT: {
            return {
                ...state,
                keywordListSort:
                    keywordResearchSettingsInitialState.keywordListSort,
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORD_LIST_FILTERS: {
            return {
                ...state,
                keywordListFilters: payload,
            };
        }

        case keywordResearchSettingsActions.UPDATE_KEYWORD_RESEARCH_TAB: {
            return {
                ...state,
                keywordResearchTab: payload,
            };
        }

        case keywordResearchSettingsActions.SET_CURRENT_KEYWORD: {
            return {
                ...state,
                currentKeyword: payload,
            };
        }

        case keywordResearchSettingsActions.SET_KEYWORD_FOR_UPDATE: {
            return {
                ...state,
                keywordForUpdate: payload,
            };
        }

        case keywordResearchSettingsActions.UPDATE_KEYWORD_QUERY_PARAM: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        default:
            return state;
    }
};
