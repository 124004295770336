import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, List } from 'antd';

import { useHighlightedKeywords } from '../../../../../../../../utils/hooks/useHighlightedKeywords';
import { useSortedOptimizerKeywords } from '../../../../../../../../utils/hooks/useSortedOptimizerKeywords';

import { updateSelectedKeywords } from '../../../../store/contentOptimizerContent.actions';
import { selectSelectedKeywords } from '../../../../store/contentOptimizerContent.selectors';

import OptimizerKeywordsListItem from './OptimizerKeywordsListItem';

const classes = {
    optimizerKeywordsListWrapper: 'optimizer-keywords-list-wrapper',
};

const OptimizerKeywordsList = () => {
    const dispatch = useDispatch();

    const selectedKeywords = useSelector(selectSelectedKeywords);

    const sortedKeywords = useSortedOptimizerKeywords();
    const highlightedKeywords = useHighlightedKeywords();

    const getCurrentKeywordUses = (currentKeyword, keywordList = []) => {
        return keywordList?.find(
            (keyword) =>
                keyword?.keyword.toLocaleLowerCase() ===
                currentKeyword?.toLocaleLowerCase()
        )?.uses;
    };

    const handleCheckboxGroupChange = (checkedValues) => {
        dispatch(updateSelectedKeywords(checkedValues));
    };

    return (
        <Checkbox.Group
            value={selectedKeywords}
            onChange={handleCheckboxGroupChange}
        >
            <List
                className={classes.optimizerKeywordsListWrapper}
                dataSource={sortedKeywords}
                renderItem={(item) => (
                    <OptimizerKeywordsListItem
                        key={item?.keyword}
                        keywordItem={item}
                        active={
                            getCurrentKeywordUses(
                                item?.keyword,
                                highlightedKeywords
                            ) > 0
                        }
                        uses={getCurrentKeywordUses(
                            item?.keyword,
                            highlightedKeywords
                        )}
                    />
                )}
            />
        </Checkbox.Group>
    );
};

export default OptimizerKeywordsList;
