/**
 * Function to remove empty tags from an HTML string.
 * @param {string} html - The HTML string to be cleaned.
 * @returns {string} - The cleaned HTML string.
 */
export const removeEmptyTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const removeEmptyNodes = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const children = Array.from(node.childNodes);

            children.forEach((child) => {
                removeEmptyNodes(child);
            });

            if (node.nodeType === Node.ELEMENT_NODE && !node.innerHTML.trim()) {
                node.remove();
            }
        }
    };

    removeEmptyNodes(doc.body);

    return doc.body.innerHTML;
};

/**
 * Function to remove extra spaces between root-level tags in an HTML string.
 * @param {string} html - The HTML string to be cleaned.
 * @returns {string} - The cleaned HTML string.
 */
export const removeExtraSpacesBetweenTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const root = doc.body || doc.documentElement;

    const normalizeWhitespace = (node) => {
        if (node.nodeType === Node.TEXT_NODE && !node.nodeValue.trim()) {
            node.remove();
        } else {
            const children = Array.from(node.childNodes);

            children.forEach((child) => {
                normalizeWhitespace(child);
            });
        }
    };

    normalizeWhitespace(root);

    return root.innerHTML;
};
