import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tabs } from 'antd';

import useGetListOfContentBriefs from '../../../../../../../utils/hooks/useGetListOfContentBriefs';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { briefOutlineTabs } from '../../../contentOptimizerContentConstants';

import BriefRecomedations from './tabsContent/BriefRecomedations';
import RanksContainer from './tabsContent/outline/ranks/RanksContainer';

import BriefQuestionsSection from './tabsContent/briefSummary/BriefQuestionsSection';
import {
    selectCurrentBriefId,
    selectIsRanksHtagsLoaded,
} from './tabsContent/store/contentBriefsOutline.selectors';

import './ContentBriefOutlineTabs.scss';

const classes = {
    optimizerRightBlockWrapper: 'optimizer-right-block-wrapper',
};

const ContentBriefOutlineTabs = ({ selectedTab, onChange }) => {
    const getListOfContentBriefs = useGetListOfContentBriefs();

    const { contentOptimizerId } = useParams();

    const darkMode = useSelector(selectDarkMode);

    const currentBriefId = useSelector(selectCurrentBriefId);
    const isRanksHtagsLoaded = useSelector(selectIsRanksHtagsLoaded);

    const tabsItems = [
        {
            key: briefOutlineTabs.brief.key,
            label: briefOutlineTabs.brief.label,
            children: <BriefRecomedations />,
        },
        {
            key: briefOutlineTabs.outline.key,
            label: briefOutlineTabs.outline.label,
            children: (
                <div className='outline-tab-wrapper'>
                    <BriefQuestionsSection />
                    <div className='ranks-container-wrapper'>
                        <RanksContainer />
                    </div>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (
            (contentOptimizerId &&
                contentOptimizerId !== currentBriefId?.toString()) ||
            !isRanksHtagsLoaded
        ) {
            getListOfContentBriefs(contentOptimizerId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentOptimizerId]);

    return (
        <div
            className={`${classes.optimizerRightBlockWrapper}
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            <Tabs
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                items={tabsItems}
                onChange={onChange}
            />
        </div>
    );
};

export default ContentBriefOutlineTabs;
