import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectUserOrganizationSettings } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { advancedSettingsTermListItems } from '../../../../../constants/advancedSettingsListItems';

import FilterRemoveIcon from '../../../../../resources/icons/FilterRemoveIcon';

import { useIconColor } from '../../../../../utils/hooks/useIconColor';

import AdvancedSettingsEmptyArea from './AdvancedSettingsEmptyArea';
import AdvancedSettingsTable from './AdvancedSettingsTable';
import AdvancedSettingModal from './AdvancedSettingModal';
import AdvancedSettingsList from './AdvancedSettingsList';

const { Title } = Typography;

const classes = {
    termSettingWrapper: 'term-setting-wrapper',
    settingsSectiontitle: 'section-title',
};

const TermSettingContent = () => {
    const organisationSettings = useSelector(selectUserOrganizationSettings);

    const iconColor = useIconColor();

    return (
        <div className={classes.termSettingWrapper}>
            <Title
                level={4}
                className={classes.settingsSectiontitle}
            >
                Keyword Filter
            </Title>
            <AdvancedSettingsList listItems={advancedSettingsTermListItems} />
            <AdvancedSettingModal
                linkText='Add a keyword to the filter'
                title='Add Keyword To Filter'
                confirmBtnText='Add filtered keyword'
                fieldName='filtered_terms'
                valueName='term'
                fieldLabel='Add a specific word or phrase to the keyword filter'
                fieldPlaceholder='e.g. YouTube'
                existErrorMessage='Term already exists'
            />
            {organisationSettings?.filtered_terms?.length > 0 ? (
                <AdvancedSettingsTable
                    title='term'
                    fieldName='filtered_terms'
                    advancedSettingsData={organisationSettings.filtered_terms}
                />
            ) : (
                <AdvancedSettingsEmptyArea
                    tilte='No Filtered Keywords Match Your Filters.'
                    subtitle='Add one using the link above.'
                    icon={<FilterRemoveIcon color={iconColor} />}
                />
            )}
        </div>
    );
};

export default TermSettingContent;
