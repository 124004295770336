export const contentOptimizerContentTabValues = {
    brief: {
        value: 'brief',
        label: 'Brief',
    },
    optimizer: {
        value: 'optimizer',
        label: 'Optimizer',
    },
    competitors: {
        value: 'competitors',
        label: 'Competitors',
    },
};

export const contentOptimizerOnlyViewOptions = [
    {
        label: contentOptimizerContentTabValues.optimizer.label,
        value: contentOptimizerContentTabValues.optimizer.value,
    },
    {
        label: contentOptimizerContentTabValues.competitors.label,
        value: contentOptimizerContentTabValues.competitors.value,
    },
];

export const contentOptimizerContentTabOptions = [
    {
        label: contentOptimizerContentTabValues.brief.label,
        value: contentOptimizerContentTabValues.brief.value,
    },
    ...contentOptimizerOnlyViewOptions,
];

export const briefOutlineTabs = {
    brief: {
        key: 'brief',
        label: 'Content Brief',
    },
    outline: {
        key: 'outline',
        label: 'Outline',
    },
};

export const optimizerTabs = {
    keywords: {
        key: 'keywords',
        label: 'Keywords',
    },
    brief: {
        key: 'brief',
        label: 'Content Brief',
    },
};
