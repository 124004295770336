import React from 'react';
import { useSelector } from 'react-redux';

import { Badge, Card, Typography } from 'antd';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import CustomDivider from '../../../../../common/Divider';

const classes = {
    baseChartTooltipRoot: 'base-chart-tooltip-root',
    valueLine: 'd-flex justify-content-between',
    titleLabel: 'd-flex flex-center title-label',
    valueLabelWrapper: 'd-flex flex-center',
    valueLabel: 'value-label',
    valueBudge: 'value-budge',
};

const ContentMonitorChartTooltip = ({ payload }) => {
    const darkTheme = useSelector(selectDarkMode);

    return (
        <Card className={classes.baseChartTooltipRoot}>
            <Typography
                className={`${classes.titleLabel} ${darkTheme && 'dark-mode'}`}
            >
                {payload?.length > 0 && payload[0]?.payload?.name}
            </Typography>

            <CustomDivider />

            {payload?.map((chartItem, index) => {
                return (
                    <div className={classes.valueLine} key={index}>
                        <div className='d-flex'>
                            <Badge
                                color={chartItem?.color}
                                className={classes.valueBudge}
                            />
                            <Typography>{chartItem?.name}</Typography>
                        </div>
                        <div className={classes.valueLabelWrapper}>
                            <Typography
                                align='center'
                                className={`${classes.valueLabel} ${
                                    darkTheme && 'dark-mode'
                                }`}
                            >
                                {chartItem?.value}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </Card>
    );
};

export default ContentMonitorChartTooltip;
