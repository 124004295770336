import { sortOrders } from '../../../../../constants/sortOrders';

import { quotasSortFields } from '../../constants';

import { adminQuotasSettingsActions } from './adminQuotasSettings.actions';

const adminQuotasSettingsInitialState = {
    quotasData: [],
    isDataLoading: false,
    queryParams: {
        page: 1,
        per_page: 20,
        sort_field: quotasSortFields.created_at,
        sort_order: sortOrders.desc,
    },
    totalPages: 1,
};

const updateOrganization = (organization, payload) => ({
    ...organization,
    ...(payload.stripe_customer_id && { stripe_customer_id: payload.stripe_customer_id }),
    ...(payload.stripe_subscription_id && {
        stripe_subscription_id: payload.stripe_subscription_id,
    }),
});

export const adminQuotasSettingsReducer = (
    state = adminQuotasSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case adminQuotasSettingsActions.SET_QUOTAS_DATA: {
            return {
                ...state,
                quotasData: payload,
            };
        }

        case adminQuotasSettingsActions.UPDATE_QUOTA: {
            return {
                ...state,
                quotasData: state.quotasData.map((quota) =>
                    quota.id === payload.id
                        ? {
                              ...quota,
                              ...payload,
                              organization: updateOrganization(quota.organization, payload),
                          }
                        : quota
                ),
            };
        }

        case adminQuotasSettingsActions.SET_QUOTAS_IS_DATA_LOADING: {
            return {
                ...state,
                isDataLoading: payload,
            };
        }

        case adminQuotasSettingsActions.UPDATE_QUOTAS_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        case adminQuotasSettingsActions.SET_QUOTAS_TOTAL_PAGES: {
            return {
                ...state,
                totalPages: payload,
            };
        }

        default: {
            return state;
        }
    }
};
