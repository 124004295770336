import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { capitalizeFirstLetter } from '../../../../../utils/helpers/capitalizeFirstLetter';

const classes = {
    paymentInfoSectionWrapper: 'payment-info-section-wrapper',
    paymentInfoSectionWrapperDark: 'payment-info-section-wrapper-dark',
    textAccent: 'text-accent',
};

const PaymentInfoSection = ({ paymentMethod }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.paymentInfoSectionWrapper}
                             ${darkMode ? classes.paymentInfoSectionWrapperDark : ''}`}>
            <Typography>
                {capitalizeFirstLetter(paymentMethod?.brand)} ending in{' '}
                <span className={classes.textAccent}>
                    {paymentMethod?.last4 || '****'}
                </span>
            </Typography>
            <Typography>
                Expires{' '}
                <span className={classes.textAccent}>
                    {paymentMethod?.exp_month || '00'}/
                    {paymentMethod?.exp_year || '0000'}
                </span>
            </Typography>
        </div>
    );
};

export default PaymentInfoSection;
