import React from 'react';

const BoldIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.7533 1.74818L3.74304 1.14826L3.1533 1.15835V1.74818H3.7533ZM8.49996 1.66699V1.0669L8.4897 1.06708L8.49996 1.66699ZM3.7533 8.00033H3.1533C3.1533 8.3317 3.42193 8.60033 3.7533 8.60033V8.00033ZM4.3533 3.00033V1.74818H3.1533V3.00033H4.3533ZM3.76356 2.34809L8.51022 2.2669L8.4897 1.06708L3.74304 1.14826L3.76356 2.34809ZM8.49996 2.26699C9.91526 2.26699 11.0666 3.41836 11.0666 4.83366H12.2666C12.2666 2.75562 10.578 1.06699 8.49996 1.06699V2.26699ZM11.0666 4.83366C11.0666 6.24896 9.91526 7.40033 8.49996 7.40033V8.60033C10.578 8.60033 12.2666 6.9117 12.2666 4.83366H11.0666ZM8.49996 7.40033H3.7533V8.60033H8.49996V7.40033ZM4.3533 8.00033V3.00033H3.1533V8.00033H4.3533Z'
                fill={color}
            />
            <path
                d='M3.7533 8H10.0866C11.8333 8 13.2533 9.42 13.2533 11.1667C13.2533 12.9133 11.8333 14.3333 10.0866 14.3333H5.08663C4.66644 14.3333 3.7533 14.3333 3.7533 14.3333C3.7533 14.3333 3.7533 13.3131 3.7533 13V8Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
            />
        </svg>
    );
};

export default BoldIcon;
