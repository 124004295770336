export const getFaviconUrl = (url) => {
    let faviconUrl;

    const regex = /^https?:\/\/[^/]+/;
    const match = url.match(regex);

    if (match) {
        faviconUrl = match[0] + '/';
    } else {
        faviconUrl = url;
    }

    faviconUrl += 'favicon.ico';

    return `https://www.google.com/s2/favicons?domain=${faviconUrl}`;
};
