export const keywordFilterOptions = [
    {
        value: 'equals',
        label: 'Is',
    },
    {
        value: 'not_equals',
        label: 'Is Not',
    },
    {
        value: 'contains',
        label: 'Contains',
    },
    {
        value: 'does_not_contain',
        label: 'Doesn`t contain',
    },
    {
        value: 'starts_with',
        label: 'Starts with',
    },
    {
        value: 'ends_with',
        label: 'Ends with',
    },
];

export const keywordRulesOptions = [
    {
        value: 'and',
        label: 'AND',
    },
    {
        value: 'or',
        label: 'OR',
    },
];
