import React, { useState } from 'react';

import { Table, Typography } from 'antd';

import { sortUsersByFieldAndOrder } from '../../../../../utils/helpers/sortUsersByFieldAndOrder';
import { getRoleLabel } from '../../../../../utils/helpers/getRoleLabel';

import { sortOrders } from '../../../../../constants/sortOrders';

import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';

import { accountUsersSortFields } from '../../../account/users/constants/accountUsersSortFields';

const classes = {
    tableHeaderTitle:
        'd-flex align-items-center users-table-header-title cursor-pointer',
};

const ExpandedInvitesTable = ({ invitesData }) => {
    const [usersSort, setUsersSort] = useState({
        field: null,
        order: sortOrders.desc,
    });

    const handleSort = (sortField) => {
        setUsersSort({
            field: sortField,
            order:
                usersSort.field === sortField &&
                usersSort.order === sortOrders.desc
                    ? sortOrders.asc
                    : sortOrders.desc,
        });
    };

    const columns = [
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.email)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.email &&
                            'active'
                        }
                    >
                        EMAIL
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.email
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'email',
            width: '60%',
            fixed: 'left',
        },
        {
            title: (
                <div
                    className={classes.tableHeaderTitle}
                    onClick={() => handleSort(accountUsersSortFields.role)}
                >
                    <Typography
                        className={
                            usersSort.field === accountUsersSortFields.role &&
                            'active'
                        }
                    >
                        ROLE
                    </Typography>
                    <TableHeaderSortIcons
                        active={
                            usersSort?.field === accountUsersSortFields.role
                        }
                        order={usersSort?.order}
                    />
                </div>
            ),
            dataIndex: 'role',
            render: (role) => {
                return getRoleLabel(role);
            },
        },
        {
            title: 'ACTIONS',
            dataIndex: 'operation',
            fixed: 'right',
        },
    ];

    return (
        <Table
            dataSource={[
                ...sortUsersByFieldAndOrder(
                    invitesData,
                    usersSort.field,
                    usersSort.order
                ),
            ]}
            columns={columns}
            bordered
            pagination={false}
            showSorterTooltip={false}
            scroll={{
                x: true,
            }}
        />
    );
};

export default ExpandedInvitesTable;
