import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Form } from 'antd';

import {
    briefExtrasArrayFields,
    numberFields,
} from '../../../../../../../../../constants/briefExtrasArrayFields';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../constants/queryPathes';
import { urlSpecificPattern } from '../../../../../../../../../constants/regularExpression';
import { statusCodes } from '../../../../../../../../../constants/statusCodes';

import { setRankabilityLoading } from '../../../../../../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectDarkMode,
    selectRankabilityLoading,
} from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../utils/helpers/getAxiosHeaders';
import { getBriefExtrasFormItemsData } from '../../../../../../../../../utils/helpers/getBriefExtrasFormItems';
import { getInitialValuesForBriefExtras } from '../../../../../../../../../utils/helpers/getInitialValuesForBriefExtras';
import { openNotification } from '../../../../../../../../../utils/helpers/openNotification';
import { useAutoSave } from '../../../../../../../../../utils/hooks/useAutoSave';

import RankabilityLoader from '../../../../../../../../common/rankabilityLoader/RankabilityLoader';
import { versionAutoSaveTime } from '../../../../../../../../common/versionHistory/constants';

import BriefFormItem from '../../../../optimizerTab/rightBlock/brief/BriefFormItem';

import {
    setCurrentBrief,
    setCurrentBriefLoading,
    updateBriefVersion,
} from '../store/contentBriefsOutline.actions';
import {
    selectCurrentBrief,
    selectCurrentBriefId,
    selectPreviewBriefVersion,
} from '../store/contentBriefsOutline.selectors';

const classes = {
    formWrapper: 'form-wrapper',
    formWrapperDark: 'dark-input-autofill',
    InputNumber: 'w-100',
    rankabilityLoaderWrapper: 'outline-content-rankability-loader-wrapper',
};

const BriefForm = ({ createNewBriefVersion }) => {
    const [form] = Form.useForm();
    const briefFormData = Form.useWatch([], form);

    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const currentBrief = useSelector(selectCurrentBrief);
    const currentBriefId = useSelector(selectCurrentBriefId);
    const briefPreviewVersion = useSelector(selectPreviewBriefVersion);
    const aiLoading = currentBrief?.ai_job_id !== null;

    const rankabilityLoading = useSelector(selectRankabilityLoading);

    const [hasContentChanged, setHasContentChanged] = useState(false);

    useEffect(() => {
        if (aiLoading) {
            const updatedCount = rankabilityLoading + Math.random() * 2;

            setTimeout(
                () =>
                    dispatch(
                        setRankabilityLoading(
                            updatedCount < 100 ? updatedCount : 100
                        )
                    ),
                100
            );
        } else {
            dispatch(setRankabilityLoading(0));
        }
        // eslint-disable-next-line
    }, [aiLoading, rankabilityLoading]);

    useEffect(() => {
        form.setFieldsValue(
            getInitialValuesForBriefExtras(
                briefPreviewVersion
                    ? briefPreviewVersion?.extras
                    : currentBrief?.extras
            )
        );

        // eslint-disable-next-line
    }, [
        currentBrief,
        currentBrief?.name,
        briefPreviewVersion,
        currentBrief?.id,
    ]);

    const handleUpdateBrief = (name) => {
        dispatch(setCurrentBriefLoading(true));
        !hasContentChanged && setHasContentChanged(true);

        const formValues = Object.entries(form.getFieldsValue())?.map(
            (item) => {
                const fieldName = item[0];
                const fieldValue = item[1];

                const getValue = () => {
                    if (briefExtrasArrayFields.includes(fieldName)) {
                        return fieldValue
                            ? fieldValue.match(urlSpecificPattern)
                            : [];
                    } else if (
                        numberFields.includes(fieldName) &&
                        !fieldValue
                    ) {
                        return 0;
                    } else {
                        return fieldValue;
                    }
                };

                return [fieldName, getValue()];
            }
        );

        if (currentBrief && currentBriefId) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${currentBrief?.content_brief_id}/versions/${currentBrief?.id}`,
                    {
                        extras: {
                            ...currentBrief?.extras,
                            ...Object.fromEntries(formValues),
                        },
                    },
                    { ...getAxiosHeaders() }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            dispatch(setCurrentBrief(result?.data));
                            dispatch(
                                updateBriefVersion({
                                    id: result?.data?.id,
                                    progress: result?.data?.progress,
                                    word_count: result?.data?.word_count,
                                })
                            );
                        });
                    }
                    form.setFieldsValue(
                        getInitialValuesForBriefExtras({
                            [name]: result?.data?.extras[name],
                        })
                    );
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                    form.setFieldsValue(
                        getInitialValuesForBriefExtras({
                            [name]: currentBrief?.extras[name],
                        })
                    );
                })
                .finally(() => {
                    dispatch(setCurrentBriefLoading(false));
                });
        }
    };

    useAutoSave(
        () => {
            createNewBriefVersion();
        },
        hasContentChanged ? versionAutoSaveTime : 0
    );

    const setError = (name, error) => {
        form.setFields([
            {
                name,
                errors: error ? [error] : [],
            },
        ]);
    };

    const deletePageUrlIfExists = (value) => {
        if (
            briefFormData?.['page_url'] &&
            value &&
            value.includes(briefFormData?.['page_url'])
        ) {
            form.setFieldValue(
                'example_articles',
                value.replaceAll(briefFormData?.['page_url'], '')
            );

            openNotification(
                notificationType.error,
                'Error',
                'Content Examples can not contain Page Url. It was automatically removed by system.'
            );
        }
    };

    return aiLoading ? (
        <div className={classes.rankabilityLoaderWrapper}>
            <RankabilityLoader startedStatus={rankabilityLoading} />
        </div>
    ) : (
        <Form
            form={form}
            layout='vertical'
            className={`${classes.formWrapper}
                        ${darkMode ? classes.formWrapperDark : ''}`}
            initialValues={getInitialValuesForBriefExtras(
                briefPreviewVersion
                    ? briefPreviewVersion?.content_brief_extras
                    : currentBrief?.extras
            )}
        >
            {getBriefExtrasFormItemsData(currentBrief?.extras)?.map((item) => {
                return (
                    <BriefFormItem
                        key={item.name}
                        value={briefFormData?.[item.name] || ''}
                        savedValue={
                            getInitialValuesForBriefExtras(
                                currentBrief?.extras
                            )?.[item.name]
                        }
                        primaryKeyword={currentBrief?.content_brief?.keywords}
                        briefFormItem={item}
                        deletePageUrlIfExists={deletePageUrlIfExists}
                        handleEditBrief={
                            !briefPreviewVersion && handleUpdateBrief
                        }
                        setError={setError}
                    />
                );
            })}
        </Form>
    );
};

export default BriefForm;
