const base64UrlEncode = (str) => {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
};

const createHmacSha256 = async (key, msg) => {
    const enc = new TextEncoder();
    const keyData = enc.encode(key);
    const msgData = enc.encode(msg);

    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        keyData,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
    );

    const signature = await window.crypto.subtle.sign(
        'HMAC',
        cryptoKey,
        msgData
    );

    return new Uint8Array(signature);
};

const generateJWT = async (header, payload, secret) => {
    const encodedHeader = base64UrlEncode(
        new TextEncoder().encode(JSON.stringify(header))
    );
    const encodedPayload = base64UrlEncode(
        new TextEncoder().encode(JSON.stringify(payload))
    );
    const signatureArray = await createHmacSha256(
        secret,
        `${encodedHeader}.${encodedPayload}`
    );
    const signature = base64UrlEncode(signatureArray);

    return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const generateTipTapAIJwtToken = () => {
    const header = {
        alg: 'HS256',
        typ: 'JWT',
    };

    const payload = {
        iat: Math.floor(Date.now() / 1000),
    };

    const secret = process.env.REACT_APP_TIPTAP_AI_JWT_SECRET;

    if (!secret) {
        return '';
    }

    return generateJWT(header, payload, secret);
};
