import { maxVersionsNumber } from '../../components/common/versionHistory/constants';

export const addNewVersionToArray = (newVersion, versions) => {
    if (versions.length + 1 <= maxVersionsNumber) {
        return [newVersion, ...versions];
    }

    let newVersions = [newVersion];
    let isActiveVersion = false;

    for (let i = 0; i < maxVersionsNumber - 1; i++) {
        if (versions[i].active) {
            isActiveVersion = true;
        }

        // if case of last iteration and array still does not contain active version; -2 because of 1 already added version (see line 4)
        if (i === maxVersionsNumber - 2 && !isActiveVersion) {
            newVersions.push(versions.find((x) => x.active));
        } else {
            newVersions.push(versions[i]);
        }
    }

    return newVersions;
};
