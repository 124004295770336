import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'antd';

import ExportIcon from '../../../../../../../resources/icons/ExportIcon';
import { colorPalette } from '../../../../../../../resources/styles/colorPalette';

import {
    selectCompetitorsSearch,
    selectCompetitorsSortDescending,
    selectCompetitorsSortKey,
    selectCurrentCompetitorsList,
    selectCurrentContentOptimizer,
} from '../../../store/contentOptimizerContent.selectors';

import { competitorsSortDictionary } from './competitorsContentConstants';

const classes = {
    competitorsExportButton: 'competitors-export-button',
};

const CompetitorsExportButton = () => {
    const currentContentOptimizer = useSelector(selectCurrentContentOptimizer);
    const competitorsList = useSelector(selectCurrentCompetitorsList);
    const competitorsSortKey = useSelector(selectCompetitorsSortKey);
    const competitorsSortDescending = useSelector(
        selectCompetitorsSortDescending
    );
    const competitorsSearch = useSelector(selectCompetitorsSearch);

    const [mappedCompetitorsList, setMappedCompetitorsList] = useState([]);

    useEffect(() => {
        const searchElement = competitorsList?.find(
            (item) => item?.url === competitorsSearch
        );

        let sortedList = competitorsList
            ?.filter(
                (item) => item?.[competitorsSortDictionary[competitorsSortKey]]
            )
            ?.sort((a, b) => {
                return (
                    a?.[competitorsSortDictionary[competitorsSortKey]] -
                    b?.[competitorsSortDictionary[competitorsSortKey]]
                );
            });

        if (!competitorsSortDescending) {
            sortedList = sortedList.reverse();
        }

        sortedList = [
            ...sortedList,
            ...competitorsList?.filter(
                (item) => !item?.[competitorsSortDictionary[competitorsSortKey]]
            ),
        ];

        if (searchElement) {
            sortedList = [
                searchElement,
                ...sortedList?.filter((item) => item?.id !== searchElement?.id),
            ];
        }

        setMappedCompetitorsList(
            sortedList?.map((item) => ({
                title: item?.title,
                url: item?.url,
                desktop: item?.desktop || null,
                mobile: item?.mobile || null,
                wordCount: item?.wordCount || null,
                rankabilityScore: item?.rankabilityScore || null,
            }))
        );
    }, [
        competitorsList,
        competitorsSortKey,
        competitorsSearch,
        competitorsSortDescending,
    ]);

    const handleExport = () => {
        let convertedCompetitorsData =
            'title,url,desktop,mobile,word_count,rankability_score\n';

        mappedCompetitorsList?.forEach((item) => {
            const convertedCompetitorItem = JSON.stringify(Object.values(item))
                ?.substring(1)
                ?.slice(0, -1)
                ?.replaceAll(',null', ',');

            convertedCompetitorsData =
                convertedCompetitorsData + convertedCompetitorItem + '\n';
        });

        const BOM = '\uFEFF';
        const url = window.URL.createObjectURL(
            new Blob([BOM + convertedCompetitorsData], {
                type: 'text/csv;charset=utf-8',
            })
        );
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.setAttribute(
            'download',
            `${currentContentOptimizer?.content_brief?.keywords}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <Button
            className={`${classes.competitorsExportButton}`}
            icon={<ExportIcon color={colorPalette.colorPrimary} />}
            type='primary'
            ghost
            onClick={handleExport}
        >
            Export
        </Button>
    );
};

export default CompetitorsExportButton;
