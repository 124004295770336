import React from 'react';

const HelpIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.99996 18.3346C14.6023 18.3346 18.3333 14.6037 18.3333 10.0013C18.3333 5.39893 14.6023 1.66797 9.99996 1.66797C5.39759 1.66797 1.66663 5.39893 1.66663 10.0013C1.66663 14.6037 5.39759 18.3346 9.99996 18.3346Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.57495 7.49852C7.77087 6.94158 8.15758 6.47194 8.66658 6.1728C9.17558 5.87365 9.77403 5.7643 10.3559 5.86411C10.9378 5.96393 11.4656 6.26646 11.8458 6.71813C12.2261 7.1698 12.4342 7.74146 12.4333 8.33185C12.4333 9.99852 9.93328 10.8319 9.93328 10.8319'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M10 14.168H10.0073'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default HelpIcon;
