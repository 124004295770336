import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import _ from 'lodash';

import { isNumber } from '../../../../utils/helpers/isNumber';

import { initialAdvancedFiltersValues } from '../../../../constants/initialAdvancedFiltersValues';
import { keywordResearchesAdvancedFilters } from '../../../../constants/keywordResearchesAdvancedFilters';
import { keywordTypes } from './constants';

import FromToFiltersDropdown from '../../../common/dropdown/advancedFilters/FromToFiltersDropdown';
import KeywordFilterDropdown from '../../../common/dropdown/advancedFilters/KeywordFilterDropdown';
import SearchIntentFilterDropdown from '../../../common/dropdown/advancedFilters/SearchIntentFilterDropdown';
import TrendFilterDropdown from '../../../common/dropdown/advancedFilters/TrendFilterDropdown';

import {
    setKeywordListFilters,
    setKeywordListPagination,
} from '../store/keywordResearchSettings.actions';
import { selectCurrentKeyword } from '../store/keywordResearchSettings.selectors';

const classes = {
    filtersWrapper: 'results-filters-wrapper d-flex',
    filtersPopoversWrapper: 'filters-popovers-wrapper d-flex',
    filtersActionWrapper: 'filters-action-wrapper d-flex',
};

const ResultsFilters = ({ isListLoading }) => {
    const dispatch = useDispatch();

    const currentKeyword = useSelector(selectCurrentKeyword);

    const [numFilters, setNumFilters] = useState([
        initialAdvancedFiltersValues.cpc,
        initialAdvancedFiltersValues.difficulty,
        initialAdvancedFiltersValues.searchVolume,
        initialAdvancedFiltersValues.position,
    ]);

    const [keywordFilters, setKeywordFilters] = useState(
        initialAdvancedFiltersValues.keywords
    );

    const [trendFilter, setTrendFilter] = useState(
        initialAdvancedFiltersValues.monthlySearches
    );

    const [searchIntentFilter, setSearchIntentFilter] = useState(
        initialAdvancedFiltersValues.searchIntent
    );

    useEffect(() => {
        const filtersModel = getFilterModel();

        if (checkFilterHasValue()) {
            batch(() => {
                dispatch(setKeywordListFilters(filtersModel));
                dispatch(setKeywordListPagination({ page: 1 }));
            });
        } else {
            dispatch(setKeywordListFilters(null));
        }

        // eslint-disable-next-line
    }, [numFilters, keywordFilters, trendFilter, searchIntentFilter]);

    useEffect(() => {
        if (checkFilterHasValue()) {
            handleClear();
        }

        // eslint-disable-next-line
    }, [currentKeyword?.id]);

    const handleFilterChange = (field, value) => {
        setNumFilters((prevFilters) =>
            prevFilters.map((filter) => {
                if (filter.field === field) {
                    return {
                        ...filter,
                        ...value,
                    };
                }
                return filter;
            })
        );
    };

    const handleKeywordFilterChange = (updatedKeywordFilters) => {
        setKeywordFilters(updatedKeywordFilters);
    };

    const handleTrendFilterChange = (updatedTrendFilter) => {
        setTrendFilter(updatedTrendFilter);
    };

    const handleSearchIntentFilterChange = (updatedFilterData = []) => {
        const newValue =
            updatedFilterData.length > 0
                ? updatedFilterData.map((value) => ({
                      ...initialAdvancedFiltersValues.searchIntent[0],
                      value: value,
                  }))
                : initialAdvancedFiltersValues.searchIntent;
        setSearchIntentFilter(newValue);
    };

    const checkFilterHasValue = () => {
        if (
            numFilters?.some(
                (filter) => isNumber(filter?.from) || isNumber(filter?.to)
            ) ||
            keywordFilters
                ?.filter(
                    (keywordFilter) =>
                        keywordFilter?.rule !==
                        keywordResearchesAdvancedFilters.keywords.rules.operator
                )
                ?.some((keywordFilter) => keywordFilter?.value) ||
            trendFilter?.some((trendFilter) => trendFilter?.value) ||
            searchIntentFilter?.some((item) => item?.value)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getFilterModel = () => {
        const filtersModel = [];

        numFilters.forEach((filter) => {
            if (isNumber(filter.from)) {
                filtersModel.push({
                    field: filter.field === 'cpc' ? 'cpc_from' : filter.field,
                    rule: 'from',
                    value: filter.from,
                });
            }
            if (isNumber(filter.to)) {
                filtersModel.push({
                    field: filter.field === 'cpc' ? 'cpc_to' : filter.field,
                    rule: 'to',
                    value: filter.to,
                });
            }
        });

        keywordFilters.forEach((keywordFilter) => {
            if (keywordFilter.value) {
                filtersModel.push(keywordFilter);
            }
        });

        trendFilter.forEach((trendFilter) => {
            if (trendFilter.value) {
                if (trendFilter.rule === 'trend') {
                    const trendFiltersModel = [
                        {
                            field: 'monthly_searches',
                            rule: 'trend',
                            value: trendFilter.value,
                        },
                    ];

                    filtersModel.push(...trendFiltersModel);
                } else {
                    filtersModel.push(trendFilter);
                }
            }
        });

        if (
            trendFilter.find(
                (trendFilter) =>
                    trendFilter.rule ===
                    keywordResearchesAdvancedFilters.monthlySearches.rules.trend
            ) &&
            !trendFilter.find(
                (trendFilter) =>
                    trendFilter.rule ===
                    keywordResearchesAdvancedFilters.monthlySearches.rules
                        .months
            )
        ) {
            filtersModel.push({
                field: 'monthly_searches',
                rule: 'months',
                value: 12,
            });
        }

        searchIntentFilter?.forEach((searchIntentData) => {
            filtersModel.push(searchIntentData);
        });

        return filtersModel;
    };

    const handleClear = () => {
        batch(() => {
            dispatch(setKeywordListFilters(null));
            dispatch(setKeywordListPagination({ page: 1 }));
        });
        setNumFilters((prevFilters) =>
            prevFilters.map((filter) => ({
                ...filter,
                from: null,
                to: null,
            }))
        );
        setKeywordFilters(initialAdvancedFiltersValues.keywords);
        setTrendFilter((prevFilters) =>
            prevFilters.map((filter) => ({
                ...filter,
                value: '',
            }))
        );
        setSearchIntentFilter(initialAdvancedFiltersValues.searchIntent);
    };

    return (
        <div className={classes.filtersWrapper}>
            <div className={classes.filtersPopoversWrapper}>
                <KeywordFilterDropdown
                    value={keywordFilters}
                    isActive={
                        !_.isEqual(
                            keywordFilters,
                            initialAdvancedFiltersValues.keywords
                        )
                    }
                    handleKeywordFilterChange={handleKeywordFilterChange}
                />
                <SearchIntentFilterDropdown
                    isActive={
                        !_.isEqual(
                            searchIntentFilter,
                            initialAdvancedFiltersValues.searchIntent
                        )
                    }
                    selectedValues={searchIntentFilter
                        ?.filter((fieldData) => fieldData.value)
                        .map((fieldData) => fieldData.value)}
                    handleSearchIntentFilterChange={
                        handleSearchIntentFilterChange
                    }
                />
                <FromToFiltersDropdown
                    title='Volume'
                    isActive={
                        !_.isEqual(
                            numFilters?.find(
                                (filter) =>
                                    filter?.field ===
                                    keywordResearchesAdvancedFilters
                                        .searchVolume.field
                            ),
                            initialAdvancedFiltersValues.searchVolume
                        )
                    }
                    field={keywordResearchesAdvancedFilters.searchVolume.field}
                    min={0}
                    value={numFilters?.find(
                        (filter) =>
                            filter?.field ===
                            keywordResearchesAdvancedFilters.searchVolume.field
                    )}
                    maxNumOfCharacters={10}
                    handleFilterChange={handleFilterChange}
                />
                <TrendFilterDropdown
                    isActive={
                        !_.isEqual(
                            trendFilter,
                            initialAdvancedFiltersValues.monthlySearches
                        )
                    }
                    value={trendFilter}
                    handleTrendFilterChange={handleTrendFilterChange}
                />
                <FromToFiltersDropdown
                    title='CPC'
                    isActive={
                        !_.isEqual(
                            numFilters?.find(
                                (filter) =>
                                    filter?.field ===
                                    keywordResearchesAdvancedFilters.cpc.field
                            ),
                            initialAdvancedFiltersValues.cpc
                        )
                    }
                    field={keywordResearchesAdvancedFilters.cpc.field}
                    min={0}
                    value={numFilters?.find(
                        (filter) =>
                            filter?.field ===
                            keywordResearchesAdvancedFilters.cpc.field
                    )}
                    maxNumOfCharacters={10}
                    handleFilterChange={handleFilterChange}
                />
                {currentKeyword?.type === keywordTypes.url && (
                    <FromToFiltersDropdown
                        title='Position'
                        isActive={
                            !_.isEqual(
                                numFilters?.find(
                                    (filter) =>
                                        filter?.field ===
                                        keywordResearchesAdvancedFilters
                                            .position.field
                                ),
                                initialAdvancedFiltersValues.position
                            )
                        }
                        field={keywordResearchesAdvancedFilters.position.field}
                        min={0}
                        value={numFilters?.find(
                            (filter) =>
                                filter?.field ===
                                keywordResearchesAdvancedFilters.position.field
                        )}
                        maxNumOfCharacters={10}
                        handleFilterChange={handleFilterChange}
                    />
                )}
                <FromToFiltersDropdown
                    title='KD'
                    isActive={
                        !_.isEqual(
                            numFilters?.find(
                                (filter) =>
                                    filter?.field ===
                                    keywordResearchesAdvancedFilters.difficulty
                                        .field
                            ),
                            initialAdvancedFiltersValues.difficulty
                        )
                    }
                    field={keywordResearchesAdvancedFilters.difficulty.field}
                    min={0}
                    max={100}
                    value={numFilters?.find(
                        (filter) =>
                            filter?.field ===
                            keywordResearchesAdvancedFilters.difficulty.field
                    )}
                    maxNumOfCharacters={10}
                    handleFilterChange={handleFilterChange}
                />
            </div>
            {checkFilterHasValue() && (
                <div className={classes.filtersActionWrapper}>
                    <Button
                        type='primary'
                        ghost
                        onClick={handleClear}
                        disabled={isListLoading}
                    >
                        Clear
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ResultsFilters;
