import { briefExtrasFormItemsDescription } from '../../constants/briefExtrasFormItemsDescription';

export const getBriefExtrasFormItemsData = () => {
    const formItemsData = [];

    Object.keys(briefExtrasFormItemsDescription).forEach((key) => {
        const formItem = briefExtrasFormItemsDescription[key];

        formItemsData.push({
            name: key,
            ...formItem,
        });
    });

    return formItemsData;
};
