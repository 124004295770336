import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from 'antd';

import ShareIcon from '../../../../resources/icons/ShareIcon';
import { useIconColor } from '../../../../utils/hooks/useIconColor';

import { selectCurrentBrief } from '../../../pages/contentOptimizer/contentPage/content/briefTab/content/tabsContent/store/contentBriefsOutline.selectors';
import {
    selectContentOptimizerShared,
    selectCurrentContentOptimizer,
} from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';
import { setShared } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.actions';

import ContentOptimizerShareDropdown from '../../../pages/contentOptimizer/mainPage/content/ContentOptimizerShareDropdown';

const classes = {
    shareSettingButton:
        'd-flex flex-center align-items-center btn-hover-light-primary share-setting-btn ml-16px',
    shareDropdownWrapper: 'd-flex align-items-center',
    shareDropdownOverlay:
        'share-dropdown-overlay dropdown-overlay-with-extra-content',
    shareDropdownOverlayDark:
        'share-dropdown-overlay-dark dropdown-overlay-with-extra-content-dark',
    shareDropdownContentWrapper: 'dropdown-with-extra-content-wrapper',
    shareLinkWrapper: 'share-link-wrapper',
};

const ShareSetting = () => {
    const iconColor = useIconColor();
    const dispatch = useDispatch();

    const currentBrief = useSelector(selectCurrentBrief);
    const currentOptimizer = useSelector(selectCurrentContentOptimizer);
    const isShared = useSelector(selectContentOptimizerShared);

    if (currentOptimizer || currentBrief) {
        return (
            <ContentOptimizerShareDropdown
                optimizerId={
                    currentOptimizer?.id || currentBrief?.content_brief_id
                }
                isShared={isShared}
                onChangedSharedCallback={(checked) =>
                    dispatch(setShared(checked))
                }
            >
                <Button className={classes.shareSettingButton}>
                    <ShareIcon color={iconColor} />
                    <Typography>Share</Typography>
                </Button>
            </ContentOptimizerShareDropdown>
        );
    } else {
        return (
            <Button className={classes.shareSettingButton}>
                <ShareIcon color={iconColor} />
                <Typography>Share</Typography>
            </Button>
        );
    }
};

export default ShareSetting;
