export const selectKeywordResearchTab = (state) =>
    state?.keywordResearchSettings?.keywordResearchTab;
export const selectKeywordResearchData = (state) =>
    state?.keywordResearchSettings?.keywordResearchData;
export const selectCurrentKeyword = (state) =>
    state?.keywordResearchSettings?.currentKeyword;
export const selectKeywordForUpdate = (state) =>
    state?.keywordResearchSettings?.keywordForUpdate;
export const selectKeywordQueryParams = (state) =>
    state?.keywordResearchSettings?.queryParams;
export const selectKeywordQuerySearch = (state) =>
    state?.keywordResearchSettings?.queryParams?.search;
export const selectKeywordListSort = (state) =>
    state?.keywordResearchSettings?.keywordListSort;
export const selectKeywordListPagination = (state) =>
    state?.keywordResearchSettings?.keywordListPagination;
export const selectKeywordListSearch = (state) =>
    state?.keywordResearchSettings?.keywordListSearch;
export const selectKeywordListFilters = (state) =>
    state?.keywordResearchSettings?.keywordListFilters;

export const selectKeywordResearchChecked = (state) =>
    state?.keywordResearchSettings?.keywordResearchChecked;
