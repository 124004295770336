import React from 'react';

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { notification } from 'antd';

import { notificationType } from '../../constants/notificationType';

export const openNotification = (
    type,
    notificationTitle,
    notificationMessage
) => {
    notification[type]({
        duration: 3,
        message: notificationTitle,
        description: notificationMessage,
        icon: <NotificationIcon type={type} />,
    });
};

const NotificationIcon = ({ type }) => {
    if (notificationType.error === type) {
        return <CloseCircleFilled />;
    }

    if (notificationType.success === type) {
        return <CheckCircleFilled />;
    }

    return null;
};
