import React from 'react';

import SignIn from './content/SignIn';

const classes = {
    loginPageWrapper: 'h-100 d-flex flex-center',
};

const LoginPageWrapper = () => {
    return (
        <div className={classes.loginPageWrapper}>
            <SignIn />
        </div>
    );
};

export default LoginPageWrapper;
