import React from 'react';

const TaskSquareIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.3081 7.90039H14.6831'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M5.31689 7.90039L5.94189 8.52539L7.81689 6.65039'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M10.3081 13.7334H14.6831'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M5.31689 13.7334L5.94189 14.3584L7.81689 12.4834'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M7.49984 18.8337H12.4998C16.6665 18.8337 18.3332 17.167 18.3332 13.0003V8.00033C18.3332 3.83366 16.6665 2.16699 12.4998 2.16699H7.49984C3.33317 2.16699 1.6665 3.83366 1.6665 8.00033V13.0003C1.6665 17.167 3.33317 18.8337 7.49984 18.8337Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default TaskSquareIcon;
