import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import VersionHistoryButton from './VersionHistoryButton';
import VersionItem from './VersionItem';

const classes = {
    versionDropdownMenuItemContent: 'version-dropdown-menu-item-content',
    versionHistoryInput: 'version-history-input',
    row: 'd-flex',
    articleTitle: 'article-title',
    dropdownMessage: 'dropdown-message',
    itemsSection: 'items-section',
};
const VersionsDropdownContent = ({
    loading,
    versions,
    previewVersionId,
    handleActivateVersion,
    createNewVersion,
    handleCollapse,
    handlePreviewVersion,
    newVersionCreating,
    disableCreateNewVersionBtn,
    handleUpdateVersionName,
    handleDeleteVersion,
}) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div className={`${classes.versionDropdownMenuItemContent}`}>
            <div className={`${classes.itemsSection}`}>
                <VersionHistoryButton
                    label='Create new version'
                    disabled={!createNewVersion}
                    newVersionCreating={newVersionCreating}
                    handleCreateNewVersion={createNewVersion}
                    disableCreateNewVersionBtn={disableCreateNewVersionBtn}
                    isCreateNewVersionBtn={true}
                />

                {versions?.map((version, index) => {
                    return (
                        <VersionItem
                            key={`${version?.id}-${index}`}
                            loading={loading}
                            version={version}
                            showSelected={version?.id === previewVersionId}
                            handleActivateVersion={handleActivateVersion}
                            handlePreviewVersion={handlePreviewVersion}
                            handleUpdateVersionName={handleUpdateVersionName}
                            handleCollapse={handleCollapse}
                            handleDeleteVersion={handleDeleteVersion}
                        />
                    );
                })}
            </div>

            <div
                className={`${classes.dropdownMessage} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                <Typography>
                    Changes are saved automatically and new versions are stored
                    every 15 minutes and store up to 8 versions for 30 days.
                </Typography>
            </div>
        </div>
    );
};

export default VersionsDropdownContent;
