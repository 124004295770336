import React from 'react';

const UnderlineIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.83337 14H13.1667'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3.83337 2V6.66667C3.83337 9.24667 5.92004 11.3333 8.50004 11.3333C11.08 11.3333 13.1667 9.24667 13.1667 6.66667V2'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default UnderlineIcon;
