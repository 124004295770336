import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'antd';
import { isString } from 'lodash';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { useOnScreen } from '../../../utils/hooks/useOnScreen';

const classes = {
    dropdownWithExtraContentOverlay: 'dropdown-overlay-with-extra-content',
    dropdownWithExtraContentOverlayDark:
        ' dropdown-overlay-with-extra-content-dark',
    dropdownWithExtraContentWrapper: 'dropdown-with-extra-content-wrapper',
    dropdownOverlayDark: 'dropdown-overlay-dark dark-mode',
};

const CustomDropdown = ({
    withArrow,
    withExtraContent,
    dropdownOverlayClassName,
    dropdownMenu,
    dropdownDisabled,
    dropdownTriggersArray,
    dropdownPlacement,
    isDropdownOpen,
    dropdownOpenElement,
    dropdownContent,
    handleOpenChange,
    keepDropdownOpen,
    getPopupContainer,
}) => {
    const dropdownOpenElementRef = useRef(null);
    const dropdownContentRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const [dropdownTopPosition, setDropdownTopPosition] = useState(0);

    const isVisible = useOnScreen(dropdownOpenElementRef);

    const handleScroll = () => {
        if (dropdownOpenElementRef?.current) {
            const { bottom } =
                dropdownOpenElementRef?.current?.getBoundingClientRect();

            setDropdownTopPosition(bottom);
        }
    };

    useEffect(() => {
        if (isDropdownOpen || keepDropdownOpen) {
            document.addEventListener('scroll', handleScroll, true);
        }

        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };

        // eslint-disable-next-line
    }, [isDropdownOpen]);

    useEffect(() => {
        if (isDropdownOpen && !isVisible && handleOpenChange) {
            handleOpenChange();
        }

        // eslint-disable-next-line
    }, [isVisible, isDropdownOpen]);

    const calculateOverlayTopPosition = () => {
        if (isString(dropdownPlacement) && dropdownPlacement.includes('top')) {
            return (
                dropdownTopPosition -
                dropdownContentRef.current?.clientHeight -
                dropdownOpenElementRef.current?.clientHeight
            );
        }

        return dropdownTopPosition;
    };

    return (
        <Dropdown
            open={keepDropdownOpen ? true : isDropdownOpen && isVisible}
            arrow={withArrow && { pointAtCenter: true }}
            trigger={dropdownTriggersArray}
            overlayStyle={{
                top: calculateOverlayTopPosition() || 0,
                ...(keepDropdownOpen && {
                    display: isVisible ? 'block' : 'none',
                }),
            }}
            overlayClassName={`
                ${
                    withExtraContent
                        ? classes.dropdownWithExtraContentOverlay
                        : ''
                }
                ${dropdownOverlayClassName ? dropdownOverlayClassName : ''}
                ${darkMode ? classes.dropdownOverlayDark : ''}
            `}
            dropdownRender={
                withExtraContent &&
                (() => (
                    <div
                        className={classes.dropdownWithExtraContentWrapper}
                        ref={dropdownContentRef}
                    >
                        {dropdownContent}
                    </div>
                ))
            }
            menu={dropdownMenu}
            placement={dropdownPlacement}
            disabled={dropdownDisabled}
            onOpenChange={handleOpenChange}
            getPopupContainer={getPopupContainer}
        >
            <div ref={dropdownOpenElementRef}>{dropdownOpenElement}</div>
        </Dropdown>
    );
};

export default CustomDropdown;
