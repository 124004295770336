export const contentMonitorContentActions = {
    CLEAR_CONTENT_MONITOR_CONTENT:
        'CONTENT_MONITOR_CONTENT/CLEAR_CONTENT_MONITOR_CONTENT',
};

export const clearContentMonitorContent = () => {
    return {
        type: contentMonitorContentActions.CLEAR_CONTENT_MONITOR_CONTENT,
    };
};
