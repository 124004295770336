export const organizationsSortFields = {
    created_at: 'created_at',
    name: 'name',
};

export const usersSortFields = {
    created_at: 'created_at',
    email: 'email',
    full_name: 'first_name',
};

export const usageStatsTableFields = {
    user: {
        key: 'user',
        title: 'user',
        width: '24%',
    },
    organization: {
        key: 'organization',
        title: 'organization',
        width: '24%',
    },
    contentBriefs: {
        key: 'contentBriefs',
        title: 'optimizers',
        width: '13%',
    },
    contentMonitors: {
        key: 'contentMonitors',
        title: 'monitors',
        width: '13%',
    },
    keywordResearches: {
        key: 'keywordResearches',
        title: 'keywords',
        width: '13%',
    },
    aiCredits: {
        key: 'aiCredits',
        title: 'ai credits',
        width: '13%',
    },
};

export const quotasSortFields = {
    created_at: 'created_at',
    name: 'name',
};

export const quotaEditFields = {
    optimizers: 'Optimizers',
    keyword_researches: 'Keywords',
    content_monitors: 'Monitors',
    openai_briefs: 'AI Credits',
};

export const organizationFieldsPatterns = {
    stripe_customer_id: {
        key: 'stripe_customer_id',
        name: 'Stripe Customer ID',
        pattern: /^cus_[A-Za-z0-9]+$/,
    },
    stripe_subscription_id: {
        key: 'stripe_subscription_id',
        name: 'Stripe Subscription ID',
        pattern: /^sub_[A-Za-z0-9]+$/,
    },
};
