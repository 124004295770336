import React from 'react';

import { Spin, Typography } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { usePaymentMethod } from '../../../../../utils/hooks/usePaymentMethod';

import { stripePublicKey } from '../../../../../constants/stripePublicKey';

import Loader from '../../../../common/loader/Loader';

import EditPaymentMethodModal from './EditPaymentMethodModal';
import PaymentInfoSection from './PaymentInfoSection';

const { Title } = Typography;

const classes = {
    paymentWrapper: 'payment-wrapper',
    paymentSectiontitle: 'section-title',
    textAccent: 'text-accent',
    link: 'link-hover-highlight',
};

const PaymentSection = ({ id }) => {
    const { paymentMethod, isPaymentMethodLoaded } = usePaymentMethod();

    return (
        <section id={id} className={classes.paymentWrapper}>
            <Title level={4} className={classes.paymentSectiontitle}>
                Payment Method
            </Title>
            {paymentMethod ? (
                <Spin spinning={isPaymentMethodLoaded} indicator={<Loader />}>
                    <PaymentInfoSection paymentMethod={paymentMethod} />
                </Spin>
            ) : (
                <Typography>No payment method provided</Typography>
            )}

            <Elements stripe={loadStripe(stripePublicKey)}>
                <EditPaymentMethodModal />
            </Elements>
        </section>
    );
};

export default PaymentSection;
