export const KEY_CODES = {
    ENTER: 'Enter',
};

export const RIGHT_SINGLE_QUOTATION_MARK = '\u2019';

export const FILTERED_TERMS = 'filtered_terms';

export const MIN_KEYWORD_LENGTH = 2;
export const MAX_KEYWORD_LENGTH = 80;

export const MAX_CUSTOM_KEYWORD_LENGTH = 60;
