import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Button, Popconfirm, Tooltip, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import {
    selectDarkMode,
    selectIsSubscriptionExpired,
    selectOrganizationId,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { colorPalette } from '../../../../../../resources/styles/colorPalette';

import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../../utils/axiosAPI';

import { notificationType } from '../../../../../../constants/notificationType';
import { organizationPathes } from '../../../../../../constants/queryPathes';
import { accountTabs } from '../../../../../../constants/accountTabs';
import { statusCodes } from '../../../../../../constants/statusCodes';

import { selectUsedUsers } from '../../store/billingSettings.selectors';
import {
    setInvites,
    setUsers,
    updateAccountTab,
} from '../../../store/accountSettings.actions';
import {
    selectInvites,
    selectUsers,
} from '../../../store/accountSettings.selectors';
import SwitchPlanContentMonitorWarningModal from './SwitchPlanContentMonitorWarningModal';

const classes = {
    selectPlanButton: 'select-plan-button',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    popconfirm: 'delete-popconfirm',
    popconfirmDark: 'delete-popconfirm-dark',
};

const SwitchPlanButton = ({
    active,
    usersPlanLimit,
    handleSwitchPlan,
    btnTitle = 'Switch Plan',
    trial,
    expired,
    contentMonitorDetails,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const usersUsed = useSelector(selectUsedUsers);

    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const organizationId = useSelector(selectOrganizationId);
    const invites = useSelector(selectInvites);
    const users = useSelector(selectUsers);

    const currentUsersSeats =
        users?.filter((user) => user?.active)?.length + invites?.length;

    const organizationSlug = useOrganizationSlug();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const disabled =
        (active && !trial && !expired) ||
        (!active &&
            (usersUsed > usersPlanLimit || currentUsersSeats > usersPlanLimit));

    useEffect(() => {
        if (
            isSubscriptionExpired &&
            invites?.length === 0 &&
            users?.length === 0
        ) {
            axiosAPI
                .get(`${organizationPathes.getOrganization}${organizationId}`, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setUsers(result?.data?.users));
                        dispatch(setInvites(result?.data?.invites));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        'Looks like something went wrong while loading users. Please try again later'
                    );
                });
        }

        // eslint-disable-next-line
    }, [isSubscriptionExpired]);

    const handleNavigateToUsers = () => {
        dispatch(updateAccountTab(accountTabs.users.key));
        navigate(`/${organizationSlug}/account/${accountTabs.users.key}`);
    };

    if (
        !active &&
        (usersUsed > usersPlanLimit || currentUsersSeats > usersPlanLimit)
    ) {
        return (
            <Tooltip
                title={
                    <>
                        You have too many active user seats for this
                        subscription. Please{' '}
                        <Typography.Link onClick={handleNavigateToUsers}>
                            disable one or more users
                        </Typography.Link>{' '}
                        and try again.
                    </>
                }
                placement='top'
                overlayClassName={`${classes.tooltipOverlay}
                               ${darkMode ? classes.tooltipOverlayDark : ''}`}
            >
                <Button
                    type='primary'
                    className={classes.selectPlanButton}
                    disabled={disabled}
                    onClick={handleNavigateToUsers}
                >
                    {btnTitle}
                </Button>
            </Tooltip>
        );
    }

    const handleConfirm = () => {
        if (contentMonitorDetails) {
            setIsModalOpen(true);
        } else {
            handleSwitchPlan();
        }
    };

    if (!active && !isSubscriptionExpired) {
        return (
            <>
                <Popconfirm
                    placement='topRight'
                    title={`Are you sure you want to change your subscription?`}
                    onConfirm={handleConfirm}
                    okText='Yes'
                    cancelText='Cancel'
                    overlayClassName={`${classes.popconfirm} ${
                        darkMode ? classes.popconfirmDark : ''
                    }`}
                    autoAdjustOverflow
                    icon={
                        <ExclamationCircleFilled
                            style={{ color: colorPalette.colorPrimary }}
                        />
                    }
                >
                    <Button
                        type='primary'
                        className={classes.selectPlanButton}
                        disabled={disabled}
                    >
                        {btnTitle}
                    </Button>
                </Popconfirm>
                <SwitchPlanContentMonitorWarningModal
                    contentMonitorDetails={contentMonitorDetails}
                    darkMode={darkMode}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleConfirm={handleSwitchPlan}
                />
            </>
        );
    }

    return (
        <Button
            type='primary'
            className={classes.selectPlanButton}
            disabled={disabled}
            onClick={handleSwitchPlan}
        >
            {btnTitle}
        </Button>
    );
};

export default SwitchPlanButton;
