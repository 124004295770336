import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'antd';

import { selectIsGracePeriod } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../../utils/axiosAPI';

import { paymentsPathes } from '../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../constants/statusCodes';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';

import InvoiceHistoryTable from '../../content/InvoiceHistoryTable';
import { invoicesLimit } from '../../BillingConstants';

const classes = {
    invoiseHistoryCardWrapper: 'invoice-history-card-wrapper',
};

const InvoiceHistoryCard = () => {
    const isGracePeriod = useSelector(selectIsGracePeriod);

    const [invoiceData, setInvoiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getInvoiceData = (limit = 99999, starting_after = null) => {
        setIsLoading(true);

        axiosAPI
            .get(
                paymentsPathes.getInvoices,
                { params: { limit, starting_after } },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    const invoiceDataResult = result?.data;

                    const concatData = invoiceDataResult
                        ?.filter((item) => {
                            return !invoiceData?.some(
                                (t) => t?.id === item?.id
                            );
                        })
                        ?.map((item) => {
                            return {
                                ...item,
                                key: item?.id,
                            };
                        });

                    if (concatData?.length === invoicesLimit) {
                        concatData?.push({
                            key: 'more',
                        });
                    }

                    const prevInvoices =
                        invoiceData?.length > 0
                            ? invoiceData?.slice(0, -1)
                            : [];

                    setInvoiceData([...prevInvoices, ...concatData]);
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getInvoiceData();

        // eslint-disable-next-line
    }, [isGracePeriod]);

    const handleShowMore = () => {
        const lastInvoice = invoiceData[invoiceData?.length - 2];

        if (lastInvoice) {
            getInvoiceData(invoicesLimit, lastInvoice?.id);
        }
    };

    return (
        <Card className={classes.invoiseHistoryCardWrapper}>
            <InvoiceHistoryTable
                invoiceData={invoiceData}
                isLoading={isLoading && invoiceData?.length === 0}
                isShowMoreLoading={isLoading && invoiceData?.length > 0}
                handleShowMore={handleShowMore}
            />
        </Card>
    );
};

export default InvoiceHistoryCard;
