import React from 'react';
import { useSelector } from 'react-redux';

import { Spin } from 'antd';

import Loader from '../../../../../../../common/loader/Loader';

import { selectIsOptimizerLoading } from '../../../../store/contentOptimizerContent.selectors';

import OptimizerKeywordsCompleteProgressBar from './OptimizerKeywordsCompleteProgressBar';
import OptimizerKeywordsTopSection from './OptimizerKeywordsTopSection';
import OptimizerKeywordsList from './OptimizerKeywordsList';

import './OptimizerKeywordsContent.scss';

const classes = {
    keywordsPanelWrapper: 'optimizer-keywords-panel-wrapper',
};

const OptimizerKeywordsContent = () => {
    const isOptimizerLoading = useSelector(selectIsOptimizerLoading);

    return (
        <>
            <div className={classes.keywordsPanelWrapper}>
                <Spin
                    spinning={isOptimizerLoading}
                    indicator={<Loader />}
                >
                    <OptimizerKeywordsTopSection />
                    <OptimizerKeywordsList />
                </Spin>
            </div>
            <OptimizerKeywordsCompleteProgressBar />
        </>
    );
};

export default OptimizerKeywordsContent;
