import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { useOpen } from '../../../../../utils/hooks/useOpen';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { paymentsPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import InvoiceDetailsDrawer from './content/InvoiceDetailsDrawer';
import InvoiceCard from './content/InvoiceCard';

import './InvoicePageContent.scss';

const { Title } = Typography;

const classes = {
    invoiceContentWrapper:
        'invoice-wrapper d-flex flex-column align-items-center w-100 h-100',
    invoiceContentWrapperDark: 'invoice-wrapper-dark',
    invoiceDetailsOpenWrapper: 'invoice-details-open-wrapper',
};

const InvoicePageContent = () => {
    const { invoiceId } = useParams();

    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();

    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        axiosAPI
            .get(`${paymentsPathes.getInvoices}/${invoiceId}`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    setInvoiceData(result?.data);
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });

        // eslint-disable-next-line
    }, []);

    return (
        <div
            className={`${classes.invoiceContentWrapper}
                        ${darkMode ? classes.invoiceContentWrapperDark : ''}
                        ${isOpen && classes.invoiceDetailsOpenWrapper}`}
        >
            <Title level={2}>Rankability Invoice</Title>
            <InvoiceCard
                isOpen={isOpen}
                handleOpen={handleOpen}
                isLoading={isLoading || !invoiceData}
                invoiceData={invoiceData}
            />
            <InvoiceDetailsDrawer
                isOpen={isOpen}
                handleOpen={handleOpen}
                invoiceData={invoiceData}
            />
        </div>
    );
};

export default InvoicePageContent;
