import React from 'react';
import { useSelector } from 'react-redux';

import { Area, AreaChart } from 'recharts';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { getRandomId } from '../../../utils/helpers/idGenerator';
import {
    getFirstLinearGradientColor,
    getSecondLinearGradientColor,
    getTrendGraphStrokeColor,
} from '../../../utils/helpers/getTrendGraphColors';

const TinyAreaChart = ({ width, height, data, dataKey, strokeWidth }) => {
    const darkMode = useSelector(selectDarkMode);

    if (!data || data?.length < 2) {
        return null;
    }

    const firstDataValue = data[0]?.[dataKey] || data[0]?.value;
    const lastDataValue =
        data[data?.length - 1]?.[dataKey] || data[data?.length - 1]?.value;

    const firstLinearGradientColor = getFirstLinearGradientColor(
        firstDataValue,
        lastDataValue,
        darkMode
    );

    const secondLinearGradientColor = getSecondLinearGradientColor(
        firstDataValue,
        lastDataValue,
        darkMode
    );

    const linearGradientId = `areaFilling${firstLinearGradientColor}${secondLinearGradientColor}${getRandomId()}`;

    return (
        <>
            <AreaChart
                width={width || 160}
                height={height || 50}
                data={data}
            >
                <defs>
                    <linearGradient
                        id={linearGradientId}
                        x1='0'
                        y1='0'
                        x2='0'
                        y2='1'
                    >
                        <stop
                            offset='50%'
                            stopColor={firstLinearGradientColor}
                            stopOpacity={0.1}
                        />
                        <stop
                            offset='95%'
                            stopColor={secondLinearGradientColor}
                            stopOpacity={0}
                        />
                    </linearGradient>
                </defs>
                <Area
                    dataKey={dataKey || 'value'}
                    stroke={getTrendGraphStrokeColor(
                        firstDataValue,
                        lastDataValue
                    )}
                    strokeWidth={strokeWidth || 1}
                    fill={`url(#${linearGradientId})`}
                />
            </AreaChart>
        </>
    );
};

export default TinyAreaChart;
