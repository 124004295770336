import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { EventType } from '@upollo/web';
import { Spin } from 'antd';

import {
    ConfirmEmailStatusCode,
    localStorageUserKey,
} from '../../../../constants/authConsts';
import { authPathes } from '../../../../constants/queryPathes';
import { axiosAPI } from '../../../../utils/axiosAPI';
import { unauthorizedRoutes } from '../../../common/router/Unauthorized/routes';

import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { openNotification } from '../../../../utils/helpers/openNotification';

import { openWelcomeHelpScoutMessage } from '../../../../utils/helpers/helpScoutHelper';

import { UppoloContext } from '../../../hocs/UppoloProvider';

import { setUserData } from '../../../../userBrowserSettings/store/browserSettings.actions';

import Loader from '../../../common/loader/Loader';

import './ConfirmedEmail.scss';

const ConfirmedEmail = () => {
    const upollo = useContext(UppoloContext);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const getErrorText = (data) => {
        switch (data) {
            case ConfirmEmailStatusCode.ALREADY_CONFIRMED:
                return 'This email has already been confirmed.';
            case ConfirmEmailStatusCode.EXPIRED_AND_NOT_CONFIRMED:
                return 'Confirmation link has expired.';
            case ConfirmEmailStatusCode.INVALID:
                return 'Confirmation link is invalid.';
            default:
                return errorNotificationMessage;
        }
    };

    const sendUppoloTrackEvent = async (userEmail) => {
        if (upollo)
            await upollo.track(
                { userEmail },
                EventType.EVENT_TYPE_REGISTER_SUCCESS
            );
    };

    const confirmMail = () => {
        if (params.token) {
            setLoading(true);

            axiosAPI
                .get(`${authPathes.confirm}/${params.token}`, {
                    ...getAxiosHeaders,
                })
                .then((result) => {
                    if (result?.data?.user) {
                        if (result.data.invite) {
                            localStorage.setItem('loggedIn', true);
                            dispatch(setUserData(result.data.user));
                            openWelcomeHelpScoutMessage();
                        } else {
                            localStorage.setItem(
                                localStorageUserKey,
                                JSON.stringify(result.data.user)
                            );
                            navigate(unauthorizedRoutes.selectPlan);
                        }

                        sendUppoloTrackEvent(result.data.user.email);
                    }
                })
                .catch((err) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        getErrorText(err.response?.data)
                    );

                    // TODO: ask customer about receiving new confirmation link
                    navigate(unauthorizedRoutes.login);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        confirmMail();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='confirmed-email-wrapper'>
            <Spin spinning={loading} indicator={<Loader />} />
        </div>
    );
};

export default ConfirmedEmail;
