import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { contentMonitorPathes } from '../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../constants/statusCodes';

import CloseIcon from '../../../../../../resources/icons/CloseIcon';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { useIconColor } from '../../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../../utils/hooks/useOpen';

import CustomDropdown from '../../../../../common/dropdown/CustomDropdown';
import FilterPopoverButon from '../../../../../common/dropdown/advancedFilters/FilterPopoverButton';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setMonitorsQueryParams } from '../../store/contentMonitorMain.actions';
import { selectContentMonitorQueryParams } from '../../store/contentMonitorMain.selectors';

const classes = {
    popoverBtn: 'filter-popover-btn d-flex',
    clearBtn: 'clear-btn d-flex flex-center',
    dropdownOverlay: 'dropdown-menu monitor-projects-dropdown-overlay',
    dropdownOverlayDark: 'dark-mode dropdown-overlay-dark',
};

const getProjectItems = (projects) => {
    if (projects.length === 0) {
        return [
            {
                key: '',
                label: 'No projects',
                disabled: true,
            },
        ];
    } else {
        return projects.map((project) => ({
            key: String(project.id),
            label: project.name,
        }));
    }
};

const defaultProjectLabel = 'Projects';

const ProjectFilterDropdown = () => {
    const dispatch = useDispatch();

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);
    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);

    const [projectsItems, setProjectsItems] = useState([]);
    const [projectLabel, setProjectLabel] = useState(defaultProjectLabel);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (isOpen) {
            axiosAPI
                .get(
                    `${contentMonitorPathes.cmProject}`,
                    { params: { with_monitors: true } },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setProjects(result.data);
                        setProjectsItems(getProjectItems(result.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }

        // eslint-disable-next-line
    }, [isOpen]);

    const onClick = (item) => {
        dispatch(setMonitorsQueryParams({ project: item.key }));

        handleOpen();
    };

    const handleClear = () => {
        dispatch(setMonitorsQueryParams({ project: null }));
    };

    useEffect(() => {
        const projectName = projects.find(
            (project) => project.id === Number(monitorQueryParams?.project)
        )?.name;

        setProjectLabel(
            projectName ? `Projects: ${projectName}` : defaultProjectLabel
        );
    }, [monitorQueryParams?.project, projects]);

    return (
        <div className={classes.popoverBtn}>
            <CustomDropdown
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={projectLabel}
                        showArrow
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                dropdownMenu={{
                    items: projectsItems,
                    selectable: true,
                    selectedKeys: [String(monitorQueryParams?.project)],
                    onClick,
                }}
                dropdownOverlayClassName={`${classes.dropdownOverlay}
                ${darkMode ? classes.dropdownOverlayDark : ''}`}
                dropdownTriggersArray={['click']}
                dropdownPlacement='bottom'
                isDropdownOpen={isOpen}
                handleOpenChange={handleOpen}
            />
            {monitorQueryParams?.project && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default ProjectFilterDropdown;
