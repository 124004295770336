import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form, Input, Select, Typography } from 'antd';
import { Option } from 'antd/es/mentions';
import Link from 'antd/es/typography/Link';

import moment from 'moment';

import { localStorageUserKey } from '../../../../constants/authConsts';
import { planRecurringPeriod } from '../../../../constants/billingPlanConsts';
import { dateFormat } from '../../../../constants/dateConsts';
import { formEmailRules } from '../../../../constants/formItemRules';
import { errorNotificationMessage, notificationType } from '../../../../constants/notificationType';
import { statusCodes } from '../../../../constants/statusCodes';
import { trialLengthInDays } from '../../account/billing/BillingConstants';

import { unauthorizedRoutes } from '../../../common/router/Unauthorized/routes';

import { setUserData } from '../../../../userBrowserSettings/store/browserSettings.actions';
import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateUserSignUp } from '../store/authSettings.actions';

import { openNotification } from '../../../../utils/helpers/openNotification';

import CustomDivider from '../../../common/Divider';
import PaymentMethod from '../../../common/paymentMethod/PaymentMethod';
import BillingPlans from './BillingPlans';

import BillingHelper from '../../../../utils/helpers/billingHelper';
import RuptService from '../../../../utils/helpers/RuptService';
import SubscriptionHelper from '../helpers/subscription.helper';

import { SubscriptionPlansService } from '../services/subscriptionPlans.service';

import { openWelcomeHelpScoutMessage } from '../../../../utils/helpers/helpScoutHelper';

import { colorPalette } from '../../../../resources/styles/colorPalette';
import { useIconColor } from '../../../../utils/hooks/useIconColor';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';

import '../Auth.scss';

const classes = {
    root: 'select-payment-plan',
    customInputs: 'custom-inputs',
    mainTypography: 'main-typography fw-700',
    descTypography: 'description-typography',
    darkThemeTypography: 'dark-theme-typography',
    creditCardWrapper: 'credit-card-wrapper',
    bottomLink: 'd-flex flex-center bottom-link',
    totalDue: 'd-flex justify-content-between',
    select: 'select-payment-dropdown',
    backBtn: 'select-payment-back-btn',
};

const SelectPaymentPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const darkMode = useSelector(selectDarkMode);

    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [isTrial, setIsTrial] = useState(true);
    const [currentSubscriptionAndPriceInfo, setCurrentSubscriptionAndPriceInfo] = useState();

    const jsonLocalStorageUser = localStorage.getItem(localStorageUserKey);
    const user = jsonLocalStorageUser ? JSON.parse(jsonLocalStorageUser) : null;

    const stripe = useStripe();
    const elements = useElements();
    const [form] = Form.useForm();

    const iconColor = useIconColor();

    const showDefaultErrorNotification = (message) => {
        openNotification(notificationType.error, message || 'Please try again later');
    };

    useEffect(() => {
        if (!user) {
            navigate(unauthorizedRoutes.login);
        }

        SubscriptionPlansService.getProducts()
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    const mappedResult = result.data
                        ?.filter(
                            (x) =>
                                !x.metadata?.custom_solution &&
                                !x.prices.find((x) => !x.recurring.interval)
                        )
                        .sort(
                            (a, b) =>
                                BillingHelper.getMonthlyPriceFromSubscription(a)?.unit_amount -
                                BillingHelper.getMonthlyPriceFromSubscription(b)?.unit_amount
                        );

                    setProducts(mappedResult);
                }
            })
            .catch(() => {
                showDefaultErrorNotification();
            })
            .finally(() => {
                setIsLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = products
        ?.map((product) => {
            return product.prices
                .sort((a, b) => a.unit_amount - b.unit_amount)
                .map((price) => {
                    return {
                        label: SubscriptionHelper.getSubscriptionLabel(product, price),
                        value: price.id,
                        annuallyDiff:
                            price.recurring.interval === planRecurringPeriod.year
                                ? SubscriptionHelper.getSubscriptionPlanPricesDifference(product)
                                : 0,
                    };
                });
        })
        .flat();

    const proceedCardOperations = async (client_secret, email) => {
        let stripeResult;

        if (isTrial) {
            stripeResult = await stripe.confirmCardSetup(client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: `${user.first_name} ${user.last_name}`,
                        email: email,
                    },
                },
            });
        } else {
            stripeResult = await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: `${user.first_name} ${user.last_name}`,
                        email: email,
                    },
                },
                receipt_email: email,
            });
        }

        return stripeResult;
    };

    const logInUser = async (subscription_id, customer_id) => {
        try {
            const authResult = await SubscriptionPlansService.addSubscriptionToExistingUser(
                user,
                subscription_id,
                customer_id
            );

            localStorage.setItem('loggedIn', true);
            localStorage.removeItem(localStorageUserKey);

            dispatch(setUserData(authResult?.data?.user));
            dispatch(updateUserSignUp(null));

            openWelcomeHelpScoutMessage();
        } catch (err) {
            openNotification(
                notificationType.error,
                'Error',
                err?.response?.data || errorNotificationMessage
            );
        }
    };

    const handleRegister = async () => {
        setIsLoading(true);

        const userBillingEmail = form.getFieldValue('billingEmail');
        let stripeResult;
        let subscription_result;

        try {
            if (!subscriptionData) {
                const result = await SubscriptionPlansService.createSubscription(
                    selectedPlanId,
                    isTrial,
                    userBillingEmail,
                    user
                );

                stripeResult = await proceedCardOperations(
                    result.data.client_secret,
                    userBillingEmail
                );

                subscription_result = result.data;

                setSubscriptionData({
                    clientSecret: result.data.client_secret,
                    subscriptionId: result.data.subscription.subscription_id,
                    customerId: result.data.customer.id,
                });
            } else {
                stripeResult = await proceedCardOperations(
                    subscriptionData.clientSecret,
                    userBillingEmail
                );
            }

            if (stripeResult.error) {
                openNotification(
                    notificationType.error,
                    'Error',
                    'Please enter a valid credit card'
                );
            } else {
                if (isTrial) {
                    await RuptService.sendRuptStartTrialTrackEvent(
                        userBillingEmail,
                        String(user.id)
                    );
                }

                if (user?.organization?.rewardful_referral_id) {
                    window.rewardful && window.rewardful('convert', { email: userBillingEmail });
                }

                await logInUser(
                    subscription_result?.subscription.subscription_id ||
                        subscriptionData.subscriptionId,
                    subscription_result?.customer.id || subscriptionData.customerId
                );
            }
        } catch (error) {
            openNotification(notificationType.error, 'Error', 'Please enter a valid credit card');
        } finally {
            setIsLoading(false);
        }
    };

    const changeSelectedSubscription = (id) => {
        setSelectedPlanId(id);
        setCurrentSubscriptionAndPriceInfo(
            SubscriptionHelper.getSelectedSubscription(id, products)
        );
    };

    const skipTrial = (_) => {
        setIsTrial(false);
    };

    const getInfoMessage = () => {
        const formattedPrice = SubscriptionHelper.getFormattedPrice(
            currentSubscriptionAndPriceInfo?.price
        );
        const recurringPeriodName = SubscriptionHelper.getSelectedRecurringPeriodName(
            currentSubscriptionAndPriceInfo?.price
        );
        const trialEndDate = moment()
            .add(trialLengthInDays, 'd')
            .format(dateFormat.fullMonthDayYear);

        return isTrial
            ? `After your trial ends, you will be charged $${formattedPrice} ${recurringPeriodName} starting ${trialEndDate}. You can always cancel before then and will receive a reminder email before your trial expires.`
            : `You will be charged $${formattedPrice} today and your subscription will automatically renew ${recurringPeriodName} until canceled.`;
    };

    const goBack = () => {
        setIsTrial(true);
    };

    return (
        <>
            {!isTrial && (
                <Button className={classes.backBtn} onClick={goBack}>
                    <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                </Button>
            )}
            <Form
                name='paymentPlanForm'
                autoComplete='off'
                form={form}
                className={classes.root}
                onFinish={handleRegister}
                initialValues={{ billingEmail: user?.email }}
            >
                <Form.Item
                    name='planId'
                    rules={[
                        {
                            required: true,
                            message: 'Please select the subscription',
                        },
                        {
                            type: '',
                        },
                    ]}
                    className={`${classes.select} ${darkMode ? classes.darkThemeTypography : ''}`}
                >
                    <Select
                        open={isOpen}
                        value={selectedPlanId}
                        suffixIcon={
                            isOpen ? (
                                <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                            ) : (
                                <SiderChevronDownIcon color={iconColor} />
                            )
                        }
                        popupClassName='keyword-filter-select'
                        placeholder='Select your Rankability subscription'
                        onDropdownVisibleChange={setIsOpen}
                        onSelect={(id) => changeSelectedSubscription(id)}
                    >
                        {options?.map((option, i) => (
                            <Option key={`select-plan-key-option-${i}`} value={option.value}>
                                {option.label}
                                {option.annuallyDiff ? (
                                    <>
                                        {' '}
                                        – <b>Save ${option.annuallyDiff}/year</b>
                                    </>
                                ) : (
                                    ''
                                )}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <BillingPlans {...currentSubscriptionAndPriceInfo} />

                <CustomDivider />

                <Typography>Billing email</Typography>
                <Form.Item
                    name='billingEmail'
                    rules={formEmailRules}
                    className={classes.customInputs}
                >
                    <Input placeholder='Enter your email' />
                </Form.Item>

                <CustomDivider />

                <div className={classes.totalDue}>
                    <Typography
                        className={`${classes.mainTypography} ${
                            darkMode ? classes.darkThemeTypography : ''
                        }`}
                    >
                        Total due
                    </Typography>
                    <Typography
                        className={`${classes.mainTypography} ${
                            darkMode ? classes.darkThemeTypography : ''
                        }`}
                    >
                        $
                        {isTrial
                            ? '0.00'
                            : currentSubscriptionAndPriceInfo?.price
                            ? SubscriptionHelper.getFormattedPrice(
                                  currentSubscriptionAndPriceInfo?.price
                              )
                            : 0}{' '}
                    </Typography>
                </div>
                {selectedPlanId && (
                    <Typography className={classes.descTypography}>{getInfoMessage()}</Typography>
                )}

                <CustomDivider marginTop={'16px'} />

                <PaymentMethod />

                <Form.Item>
                    <Button type='primary' htmlType='submit' loading={isLoading}>
                        {isTrial
                            ? `Start your ${trialLengthInDays}-day limited free trial`
                            : 'Complete checkout'}
                    </Button>
                </Form.Item>

                {isTrial && (
                    <div className={classes.bottomLink}>
                        <Link onClick={skipTrial}>Or skip the trial and subscribe now</Link>
                    </div>
                )}
            </Form>
        </>
    );
};

export default SelectPaymentPlan;
