import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { getThemeColorClass } from '../../../../../utils/helpers/themeHelpers';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import EditQuotaModal from './EditQuotaModal';

const classes = {
    link: 'link-hover-highlight space-no-wrap',
};

const EditQuotaModalWrapper = ({ editableQuota }) => {
    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();

    return (
        <>
            <Typography.Link
                underline
                onClick={handleOpen}
                className={`${classes.link} ${getThemeColorClass(darkMode)}`}
            >
                Edit
            </Typography.Link>
            <EditQuotaModal editableQuota={editableQuota} isOpen={isOpen} handleOpen={handleOpen} />
        </>
    );
};

export default EditQuotaModalWrapper;
