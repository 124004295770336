import { urlPattern } from '../../constants/regularExpression';

export const checkAreUrlsStrictlyEqual = (firstUrl, secondUrl) => {
    if (firstUrl && secondUrl) {
        return normalizeUrl(firstUrl) === normalizeUrl(secondUrl);
    }

    return false;
};

export const normalizeUrl = (url) => {
    let strippedUrl = url?.replace(/^https?:\/\//, '');

    strippedUrl = strippedUrl?.replace(/^www\./, '');

    strippedUrl = strippedUrl?.replace(/\/\/+/g, '/');

    strippedUrl = strippedUrl?.replace(/\/$/, '');

    return strippedUrl;
};

export const isUrlValid = (url) => {
    const parts = url.split(/https?:\/?\//).filter(Boolean);

    if (parts.length > 1) {
        return false;
    }

    return urlPattern.test(url);
};

export const removeUrlExtraSpaces = (url) => {
    return url?.replace(/\s/g, '');
};

export const getLinkHref = (linkHref = '') => {
    const isHrefContainHttp =
        linkHref.startsWith('//') ||
        linkHref.startsWith('https:') ||
        linkHref.startsWith('http:') ||
        linkHref.startsWith('tel:');

    return isHrefContainHttp || !linkHref.length ? linkHref : `https://${linkHref}`;
};
