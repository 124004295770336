export const initialAdvancedFiltersValues = {
    cpc: {
        field: 'cpc',
        from: null,
        to: null,
    },
    position: {
        field: 'position',
        from: null,
        to: null,
    },
    searchVolume: {
        field: 'search_volume',
        from: null,
        to: null,
    },
    difficulty: {
        field: 'difficulty',
        from: null,
        to: null,
    },
    keywords: [
        {
            field: 'keyword',
            rule: 'contains',
            value: '',
        },
    ],
    monthlySearches: [
        {
            field: 'monthly_searches',
            rule: 'months',
            value: '',
        },
        {
            field: 'monthly_searches',
            rule: 'trend',
            value: '',
        },
    ],
    searchIntent: [
        {
            field: 'search_intent',
            rule: 'contains',
            value: '',
        }
    ]
};
