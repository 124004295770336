import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import moment from 'moment';

import { colorPalette } from '../../../resources/styles/colorPalette';
import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    popconfirm: 'delete-popconfirm',
    popconfirmDark: 'delete-popconfirm-dark',
    cancelRankabilityButton: 'cancel-rankability-button',
};

const CancelCreationButton = ({
    popconfirmTitle,
    creationDate,
    handleCancel,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (creationDate) {
            const canceledSeconds = moment
                .utc()
                .local()
                .diff(moment.utc(creationDate).local(), 'seconds');

            setCounter(canceledSeconds <= 30 ? 30 - canceledSeconds : 0);
        }
    }, [creationDate]);

    useEffect(() => {
        let timer = null;

        if (counter <= 30 && counter > 0) {
            timer =
                counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [counter]);

    if (counter <= 0 || counter > 30) {
        return null;
    }

    return (
        <Popconfirm
            placement='topLeft'
            title={popconfirmTitle}
            onConfirm={handleCancel}
            okText='Yes'
            cancelText='No'
            icon={
                <ExclamationCircleFilled
                    style={{ color: colorPalette.colorPrimary }}
                />
            }
            overlayClassName={`${classes.popconfirm}
                               ${darkMode && classes.popconfirmDark}`}
            autoAdjustOverflow
        >
            <Button
                type='primary'
                size='large'
                ghost
                className={classes.cancelRankabilityButton}
            >
                <u>Cancel - {counter}s</u>
            </Button>
        </Popconfirm>
    );
};

export default CancelCreationButton;
