import { keywordResearchResultsSortOptions } from '../../constants/keywordResearchResultsSortOptions';
import { keywordResearchesAdvancedFilters } from '../../constants/keywordResearchesAdvancedFilters';

export const getKeywordResearchResultsFilterQueryByKey = (key, keyword) => {
    switch (key) {
        case keywordResearchResultsSortOptions.match:
            return [
                {
                    field: keywordResearchesAdvancedFilters.keywords.field,
                    rule: keywordResearchesAdvancedFilters.keywords.rules
                        .contains,
                    value: keyword || '',
                },
            ];
        case keywordResearchResultsSortOptions.trending:
            return [
                {
                    field: keywordResearchesAdvancedFilters.monthlySearches
                        .field,
                    rule: keywordResearchesAdvancedFilters.monthlySearches.rules
                        .months,
                    value: 3,
                },
                {
                    field: keywordResearchesAdvancedFilters.monthlySearches
                        .field,
                    rule: keywordResearchesAdvancedFilters.monthlySearches.rules
                        .trend,
                    value: 'up',
                },
            ];

        default:
            return null;
    }
};
