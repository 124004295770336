import React from 'react';

const ListIcon = ({ color = '#327EEF' }) => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1.75 4.08398H12.25'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
            />
            <path
                d='M1.75 7H12.25'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
            />
            <path
                d='M1.75 9.91602H12.25'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
            />
        </svg>
    );
};

export default ListIcon;
