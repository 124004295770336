import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Table, Typography } from 'antd';
import moment from 'moment';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';

import Loader from '../../../../common/loader/Loader';
import { usePaymentMethod } from '../../../../../utils/hooks/usePaymentMethod';

const { Link } = Typography;

const classes = {
    invoiceHistoryTable: 'invoice-history-table',
    link: 'link-hover-highlight',
    statusLabel: 'status-label',
    statusLabelPaid: 'status-label-paid',
    statusLabelUnpaid: 'status-label-unpaid',
};

const InvoiceHistoryTable = ({
    invoiceData,
    isLoading,
    isShowMoreLoading,
    handleShowMore,
}) => {
    const navigate = useNavigate();

    const darkMode = useSelector(selectDarkMode);

    const organizationSlug = useOrganizationSlug();

    const { paymentMethod } = usePaymentMethod();

    const handleViewInvoice = (invoiceId) => {
        if (invoiceId) {
            navigate(`/${organizationSlug}/account/invoice/${invoiceId}`);
        }
    };

    const columns = [
        {
            title: 'created',
            dataIndex: 'created',
            width: '33.3%',
            key: 'created',
            render: (_, record) => {
                return record.key !== 'more' ? (
                    moment(record?.created * 1000).format('LL')
                ) : (
                    <Button
                        type='link'
                        loading={isShowMoreLoading}
                        className={`${classes.link}
                        ${darkMode ? 'dark-mode' : ''}`}
                        onClick={handleShowMore}
                    >
                        Show more
                    </Button>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                return record.key !== 'more' ? (
                    <div
                        className={`${classes.statusLabel}
                                    ${
                                        record?.paid
                                            ? classes.statusLabelPaid
                                            : classes.statusLabelUnpaid
                                    }
                                    ${darkMode ? 'dark-mode' : ''}`}
                    >
                        {record?.paid ? 'Paid' : 'Unpaid'}
                    </div>
                ) : null;
            },
        },
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            render: (_, record) => {
                const regex = /×(.*?)\(at/g;

                return record?.items
                    ?.map((item) =>
                        item?.description?.indexOf('×') > -1
                            ? regex.exec(item?.description)[1].trim()
                            : item?.description?.split('after')[0]?.trim()
                    )
                    ?.join(', ');
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => {
                return record.key !== 'more'
                    ? `$${(record?.total / 100).toFixed(2)}`
                    : null;
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                return record.key !== 'more' &&
                    !record.items
                        ?.map((x) => x.description)
                        .join()
                        .includes('Trial') ? (
                    <Link
                        underline
                        className={`${classes.link}
                        ${darkMode ? 'dark-mode' : ''}`}
                        onClick={() => handleViewInvoice(record?.id)}
                    >
                        View
                    </Link>
                ) : null;
            },
        },
    ];

    return (
        <Table
            bordered
            className={classes.invoiceHistoryTable}
            columns={
                paymentMethod
                    ? columns
                    : columns.filter((x) => x.key !== 'actions')
            }
            dataSource={invoiceData}
            loading={{
                spinning: isLoading,
                indicator: <Loader />,
            }}
            pagination={false}
        />
    );
};

export default InvoiceHistoryTable;
