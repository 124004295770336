import React from 'react';

const RankabilityRoundedLightLogoIcon = ({ width = '40' }) => {
    return (
        <svg
            width={width}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1671.26 1671.25'
        >
            <defs>
                <style>
                    {`.aRankabilityRoundedLightLogoIcon {
                        fill: #000e4c;
                    }

                    .bRankabilityRoundedLightLogoIcon {
                        fill: #327eef;
                    }`}
                </style>
            </defs>
            <path
                className='aRankabilityRoundedLightLogoIcon'
                d='M835.63,0C374.13,0,0,374.12,0,835.63s374.13,835.62,835.63,835.62,835.63-374.12,835.63-835.62S1297.13,0,835.63,0Zm0,1472.68c-351.83,0-637-285.22-637-637s285.17-637.11,637-637.11,637.05,285.22,637.05,637.06S1187.46,1472.68,835.63,1472.68Z'
            />
            <path
                className='bRankabilityRoundedLightLogoIcon'
                d='M947.83,1016.81c24.22,1.86,47.75,7.13,66.56,20.84,22.6,16.48,28.28,46.36,24,72.58-4.68,28.32-18.62,51.94-47.62,59.51-13.91,3.63-28.47,4.22-42.78,4.22H548c-17,0-34.75-2.62-50.42-9.43-31.94-13.9-45.52-47.07-39.72-80.43,3.47-20,10.42-41.55,27.56-54,20.84-15.08,52.13-13.73,76.58-13.73h40.8V669.84h-6.17c-24,0-49.25,2.47-72.24-5.9-27.22-9.9-38.66-34.85-41.21-62.29-2.37-25.56,1.38-51.68,21.67-69.49q22.6-19.85,61-19.86H724.63c13.92,0,27.18,4.41,34.83,16.7,19.32,31,8.23,70.82,5.58,104.54-.47,6-.69,11.19-.69,15.75q6.84-6.84,14.38-13.7c5-4.56,10.28-9.12,15.76-13.7A784.47,784.47,0,0,1,913,532.16c73.16-45.44,206.34-67.15,268.51,8.91q21.21,26,26.71,54.79a61.83,61.83,0,0,0,.68,8.91,63.16,63.16,0,0,1,.69,8.9v7.53a24.55,24.55,0,0,1-1.37,7.54,84.48,84.48,0,0,1-13,33.56q-10.28,15.76-28.08,26.72l-5.48,2.74-5.48,2.74a214.76,214.76,0,0,1-23.29,8.9,69,69,0,0,1-20.55,3.42,93.73,93.73,0,0,1-37-7.53q-17.82-7.51-35.62-17.12a6.59,6.59,0,0,1-3.42-1.37,20.63,20.63,0,0,1-3.43-2.74q-13.71-6.84-27.4-12.33a102.5,102.5,0,0,0-28.76-6.85h-5.48q-5.49,0-12.33.68a90.55,90.55,0,0,0-12.25,2.07q-30.2,8.21-59,28.75a639.35,639.35,0,0,0-54.79,43.84q-15.09,13.71-28.09,26.72l-24,24v231.51h98C900,1016.42,924.25,1015,947.83,1016.81Z'
            />
        </svg>
    );
};

export default RankabilityRoundedLightLogoIcon;
