import { useState } from 'react';

export const useCollapse = (isCollapse = false) => {
    const [collapsed, setCollapsed] = useState(isCollapse);

    const handleCollapse = () => {
        setCollapsed(!collapsed);
    };

    return { collapsed, handleCollapse };
};
