export const keywordResearchSettingsActions = {
    CLEAR_KEYWORD_RESEARCH_SETTINGS:
        'KEYWORD_RESEARCH_SETTINGS/CLEAR_KEYWORD_RESEARCH_SETTINGS',

    UPDATE_KEYWORD_RESEARCH_TAB:
        'KEYWORD_RESEARCH_SETTINGS/UPDATE_KEYWORD_RESEARCH_TAB',
    SET_KEYWORD_RESEARCH_DATA:
        'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_RESEARCH_DATA',
    SET_IS_LIST_OF_RESULTS: 'KEYWORD_RESEARCH_SETTINGS/SET_IS_LIST_OF_RESULTS',
    SET_CURRENT_KEYWORD: 'KEYWORD_RESEARCH_SETTINGS/SET_CURRENT_KEYWORD',
    SET_KEYWORD_FOR_UPDATE: 'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_FOR_UPDATE',
    UPDATE_KEYWORD_QUERY_PARAM:
        'KEYWORD_RESEARCH_SETTINGS/UPDATE_KEYWORD_QUERY_PARAM',
    SET_KEYWORD_LIST_SORT: 'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_LIST_SORT',
    SET_INITIAL_KEYWORD_LIST_SORT:
        'KEYWORD_RESEARCH_SETTINGS/SET_INITIAL_KEYWORD_LIST_SORT',
    SET_KEYWORD_LIST_PAGINATION:
        'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_LIST_PAGINATION',
    SET_KEYWORD_LIST_SEARCH:
        'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_LIST_SEARCH',
    SET_KEYWORD_LIST_FILTERS:
        'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_LIST_FILTERS',
    SET_KEYWORD_LIST_TOTAL_PAGES:
        'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORD_LIST_TOTAL_PAGES',

    SET_KEYWORDS_RESEARCH_CHECKED:
        'KEYWORD_RESEARCH_SETTINGS/SET_KEYWORDS_RESEARCH_CHECKED',
    REMOVE_KEYWORDS_RESEARCH:
        'KEYWORD_RESEARCH_SETTINGS/REMOVE_KEYWORDS_RESEARCH',
};

export const removeKeywordResearch = (payload) => {
    return {
        type: keywordResearchSettingsActions.REMOVE_KEYWORDS_RESEARCH,
        payload,
    };
};

export const setKeywordResearchChecked = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORDS_RESEARCH_CHECKED,
        payload,
    };
};

export const setKeywordResearchData = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORD_RESEARCH_DATA,
        payload,
    };
};

export const setKeywordListPagination = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORD_LIST_PAGINATION,
        payload,
    };
};

export const setKeywordListSearch = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORD_LIST_SEARCH,
        payload,
    };
};

export const setKeywordListSort = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORD_LIST_SORT,
        payload,
    };
};

export const setInitialKeywordListSort = () => {
    return {
        type: keywordResearchSettingsActions.SET_INITIAL_KEYWORD_LIST_SORT,
    };
};

export const setKeywordListFilters = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORD_LIST_FILTERS,
        payload,
    };
};

export const updateKeywordResearchTab = (payload) => {
    return {
        type: keywordResearchSettingsActions.UPDATE_KEYWORD_RESEARCH_TAB,
        payload,
    };
};

export const setCurrentKeyword = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_CURRENT_KEYWORD,
        payload,
    };
};

export const setKeywordForUpdate = (payload) => {
    return {
        type: keywordResearchSettingsActions.SET_KEYWORD_FOR_UPDATE,
        payload,
    };
};

export const updateKeywordQueryParam = (payload) => {
    return {
        type: keywordResearchSettingsActions.UPDATE_KEYWORD_QUERY_PARAM,
        payload,
    };
};

export const clearKeywordResearchSettings = () => {
    return {
        type: keywordResearchSettingsActions.CLEAR_KEYWORD_RESEARCH_SETTINGS,
    };
};
