import React from 'react';
import { useSelector } from 'react-redux';

import { Empty, Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectBriefCitations } from '../store/contentBriefsOutline.selectors';

import CopyItem from '../../../../../../../../common/copyItem/CopyItem';

const { Title, Link } = Typography;

const classes = {
    citationsSection: 'brief-section citations-section',
    sectionTitle: 'section-title d-flex align-items-center',
    copyIcon: 'copy-icon',
    citationsWrapper: 'citations-wrapper',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    citationTooltipOverlay: 'tooltip-overlay citation-tooltip-overlay',
    textDotted: 'text-decoration-dotted',
};

const BriefCitationsSection = () => {
    const darkMode = useSelector(selectDarkMode);

    const briefsCitations = useSelector(selectBriefCitations);

    const getCopiedText = () => {
        const copiedText = briefsCitations
            ?.map((citation) => citation?.url)
            ?.join('\n');
        return copiedText;
    };

    return (
        <section className={classes.citationsSection}>
            <div className={classes.sectionTitle}>
                <Tooltip
                    overlayClassName={`${classes.citationTooltipOverlay} ${
                        darkMode ? classes.tooltipOverlayDark : ''
                    }`}
                    title='Model similar topics and ideas from these competitors ranking on page one of Google for your primary keyword.'
                    placement='topLeft'
                >
                    <Title level={5} className={classes.textDotted}>
                        Highest-Ranking Competitors
                    </Title>
                </Tooltip>
                <CopyItem copiedText={getCopiedText()} />
            </div>
            <div className={classes.citationsWrapper}>
                {briefsCitations && briefsCitations?.length > 0 ? (
                    briefsCitations?.map((citation, index) => (
                        <div className={classes.citation} key={index}>
                            <Title level={5}>{citation?.title}</Title>
                            <Link href={citation?.url} target='_blank'>
                                {citation?.url}
                            </Link>
                        </div>
                    ))
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </section>
    );
};

export default BriefCitationsSection;
