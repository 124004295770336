import { planFeatures } from '../../components/pages/account/billing/BillingConstants';
import { billingPlanTypes } from '../../constants/billingPlanConsts';

export const getPlanOptimizerCredits = (planMetaData, planType) => {
    if (planType === billingPlanTypes.yearly.value) {
        return (
            planMetaData.optimizers * planFeatures.optimizers.yearlyCoefficient
        );
    }

    return planMetaData.optimizers;
};
