import React, { useEffect, useRef } from 'react';

import EditableLinkInput from './EditableLinkInput';

import { colorPalette } from '../../../../../../resources/styles/colorPalette';

import {
    createHighlightTransaction,
    createRemoveHighlightTransaction,
} from '../../../utils/editorTransactionHelpers';
import { getLinkHref } from '../../../../../../utils/helpers/urlHelpers';

const classes = {
    addLinkWrapper: 'add-link-wrapper d-flex',
};

const AddLinkContent = ({ handleCollapse, editor }) => {
    const linkInputRef = useRef(null);

    useEffect(() => {
        const { from, to } = editor.state.selection;

        createHighlightTransaction(editor, from, to, colorPalette.colorSelectedHighlight);
        editor.commands.setIsLinkEditing(true);

        return () => {
            createRemoveHighlightTransaction(editor, from, to);
            editor.commands.setIsLinkEditing(false);
        };
    }, [editor]);

    useEffect(() => {
        if (linkInputRef.current) {
            linkInputRef.current.focus();
        }

        return () => {
            editor.commands.focus();
        };
    }, [editor]);

    const handleAddLink = () => {
        const linkValue = getLinkHref(linkInputRef.current?.input?.value);
        handleCollapse();

        if (linkValue) {
            const { view, state } = editor;
            const { from, to } = view.state.selection;
            const text = state.doc.textBetween(from, to, '');

            if (text) {
                editor.chain().focus().extendMarkRange('link').setLink({ href: linkValue }).run();
            } else {
                editor.commands.insertContent(`<a href="${linkValue}">${linkValue}</a>`);
            }
        } else {
            editor.chain().focus().unsetLink().run();
        }
    };

    const activeLinkHref = getLinkHref(editor.getAttributes('link').href || '');

    return (
        <div className={`${classes.addLinkWrapper}`}>
            <EditableLinkInput
                linkInputRef={linkInputRef}
                onSave={handleAddLink}
                defaultValue={activeLinkHref}
            />
        </div>
    );
};

export default AddLinkContent;
