import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Form, Input, Row, Typography } from 'antd';

import { setUserData } from '../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectCurrentUserRoles,
    selectDarkMode,
    selectUserData,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { notificationType } from '../../../../constants/notificationType';
import { organizationPathes, userDataPathes } from '../../../../constants/queryPathes';
import { companyNamePattern } from '../../../../constants/regularExpression';
import { roleTypes } from '../../../../constants/roleTypes';
import { statusCodes } from '../../../../constants/statusCodes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { checkIsAdmin } from '../../../../utils/helpers/checkIsAdmin';
import { checkIsGlobalTechAdmin } from '../../../../utils/helpers/checkIsGlobalTechAdmin';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';

import { elementsStyles } from '../../../../resources/styles/elementsStyles';

import { updateUserFullName } from '../store/accountSettings.actions';

import ChangeEmailModal from './content/ChangeEmailModal';
import ChangePasswordModal from './content/ChangePasswordModal';
import UpdateSlugModal from './content/UpdateSlugModal';

const { Title } = Typography;

const classes = {
    profileContentWrapper: 'account-content-wrapper profile-content-wrapper',
    profileSubtitle: 'section-title',
    profileNameSection: 'profile-name-section',
    profileForm: 'd-flex flex-column justify-content-between h-100',
    profileFormFieldsWrapper: 'form-fields-wrapper h-100 d-flex flex-column',
    profileFormButtonsWrapper:
        'form-buttons-wrapper d-flex justify-content-center align-items-center',
    profileFormCancelButton: 'btn-secondary',
    changeEmailModalWrapper: 'change-email-modal-wrapper',
};

const ProfileContent = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);
    const currentUserRoles = useSelector(selectCurrentUserRoles);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                ...user,
                organization:
                    user?.organization?.name && !checkIsGlobalTechAdmin(user?.roles)
                        ? user?.organization?.name
                        : '',
            });
        }

        // eslint-disable-next-line
    }, [user]);

    const handleFormChange = (allFields) => {
        const valuesAreEqual = allFields.every((item) => item.value === user[item.name]);

        const formHasErrors = form.getFieldsError().some((item) => item.errors.length > 0);

        if (valuesAreEqual || formHasErrors) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const handleSetInitialValues = () => {
        form.setFieldsValue({
            ...user,
            organization: user.organization.name ? user.organization.name : '',
        });
        setIsDisabled(true);
    };

    const handleUpdateUser = (values) => {
        setIsLoading(true);
        delete values.currentPassword;

        const UpdatedUserModel = {
            first_name: values.first_name,
            last_name: values.last_name,
        };

        const updatedOrganizationModel = {
            name: values.organization,
        };

        const updateUserInfo = () => {
            axiosAPI
                .patch(`${userDataPathes.users}/${user?.id}`, UpdatedUserModel, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setUserData(result?.data));
                        dispatch(
                            updateUserFullName({
                                id: result?.data?.id,
                                first_name: result?.data?.first_name,
                                last_name: result?.data?.last_name,
                            })
                        );
                        openNotification(
                            notificationType.success,
                            'Success',
                            'Profile information has been updated successfully'
                        );
                        setIsDisabled(true);
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                    handleSetInitialValues();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        if (checkIsAdmin(user?.roles[0]?.name) && values.organization !== user.organization.name) {
            axiosAPI
                .patch(
                    `${organizationPathes.updateOrganization}/${user?.organization?.id}`,
                    updatedOrganizationModel,
                    { ...getAxiosHeaders() }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        if (
                            values.first_name !== user.first_name ||
                            values.last_name !== user.last_name
                        ) {
                            updateUserInfo();
                        } else {
                            dispatch(
                                setUserData({
                                    ...user,
                                    organization: {
                                        ...user.organization,
                                        name: result?.data?.name,
                                    },
                                })
                            );
                            openNotification(
                                notificationType.success,
                                'Success',
                                'Profile information has been updated successfully'
                            );
                            setIsDisabled(true);
                        }
                    }
                })
                .catch((error) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        `Sorry, but something went wrong. ${
                            error?.response?.data?.errors?.name[0] || 'Please try again later'
                        }`
                    );
                    handleSetInitialValues();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            updateUserInfo();
        }
    };

    return (
        <div className={classes.profileContentWrapper}>
            <Form
                form={form}
                layout='vertical'
                className={classes.profileForm}
                initialValues={{
                    ...user,
                    currentPassword: '********',
                    organization:
                        user?.organization?.name && !checkIsGlobalTechAdmin(user?.roles)
                            ? user.organization.name
                            : '',
                }}
                onFieldsChange={handleFormChange}
                onFinish={handleUpdateUser}
            >
                <div
                    className={classes.profileFormFieldsWrapper}
                    style={{
                        bottom: !isDisabled
                            ? `${elementsStyles.AccountButtonsPanel.height}px`
                            : '0',
                    }}
                >
                    <section className={classes.profileNameSection}>
                        <Title level={4} className={classes.profileSubtitle}>
                            Name
                        </Title>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    name='first_name'
                                    label='First name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your first name',
                                        },
                                        {
                                            max: 32,
                                            message:
                                                'First name cannot be longer than 32 characters',
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={checkIsGlobalTechAdmin(currentUserRoles)}
                                        placeholder='Enter your first name'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name='last_name'
                                    label='Last name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your last name',
                                        },
                                        {
                                            max: 32,
                                            message:
                                                'Last name cannot be longer than 32 characters',
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={checkIsGlobalTechAdmin(currentUserRoles)}
                                        placeholder='Enter your last name'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item name='email' label='Email'>
                                    <Input placeholder='Enter your email' disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className={classes.changeEmailModalWrapper}>
                            <ChangeEmailModal />
                        </div>
                    </section>
                    {!currentUserRoles?.some(
                        (userRole) => roleTypes.organizationMember.key === userRole?.name
                    ) &&
                        !checkIsGlobalTechAdmin(currentUserRoles) && (
                            <section>
                                <Title level={4} className={classes.profileSubtitle}>
                                    Organization
                                </Title>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item
                                            name='organization'
                                            label='Company name'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your company name',
                                                },
                                                {
                                                    min: 2,
                                                    message:
                                                        'Company name must be between 2 and 64 characters long',
                                                },
                                                {
                                                    max: 64,
                                                    message:
                                                        'Company name must be between 2 and 64 characters long',
                                                },
                                                {
                                                    pattern: companyNamePattern,
                                                    message:
                                                        'Please use only letters, digits, spaces, and dashes, and ensure it starts and ends with a letter or digit',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Enter company name' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <UpdateSlugModal />
                            </section>
                        )}
                    <section>
                        <Title level={4} className={classes.profileSubtitle}>
                            Password
                        </Title>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item name='currentPassword' label='Current password'>
                                    <Input.Password disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <ChangePasswordModal />
                    </section>
                </div>
                {!isDisabled && (
                    <div className={classes.profileFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            className={`${classes.profileFormCancelButton} ${
                                getThemeColorClass(darkMode)
                            }`}
                            onClick={handleSetInitialValues}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            disabled={isDisabled}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default ProfileContent;
