import React, { useEffect, useState } from 'react';

import { Button, Input, InputNumber } from 'antd';

import CloseIcon from '../../../../resources/icons/CloseIcon';

import { useOpen } from '../../../../utils/hooks/useOpen';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { isNumber } from '../../../../utils/helpers/isNumber';

import CustomDropdown from '../CustomDropdown';

import FilterPopoverButon from './FilterPopoverButton';

import './Dropdowns.scss';

const classes = {
    popoverOverlay:
        'popover-overlay popover-filter-overlay from-to-popover-overlay',
    popoverFilterInputsWrapper: 'popover-filter-inputs-wrapper',
    popoverBtn: 'filter-popover-btn d-flex',
    filterWrapper: 'filter-wrapper',
    clearBtn: 'clear-btn d-flex flex-center',
};

const FromToFiltersDropdown = ({
    title,
    field,
    min,
    max,
    value,
    maxNumOfCharacters,
    handleFilterChange,
    isActive,
}) => {
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const [filterValue, setFilterValue] = useState(value);

    useEffect(() => {
        setFilterValue(value);
    }, [value]);

    const handleChangeFrom = (value) => {
        if (
            maxNumOfCharacters &&
            value?.toString()?.length > maxNumOfCharacters
        ) {
            setFilterValue((prevValue) => ({
                ...prevValue,
                from: '',
            }));
        } else {
            setFilterValue((prevValue) => ({
                ...prevValue,
                from: value,
            }));
        }
    };

    const handleChangeTo = (value) => {
        if (
            maxNumOfCharacters &&
            value?.toString()?.length > maxNumOfCharacters
        ) {
            setFilterValue((prevValue) => ({
                ...prevValue,
                to: '',
            }));
        } else {
            setFilterValue((prevValue) => ({
                ...prevValue,
                to: value,
            }));
        }
    };

    const handleApply = () => {
        const newFilterValue = { ...filterValue };

        if (
            isNumber(newFilterValue.from) &&
            isNumber(newFilterValue.to) &&
            newFilterValue.from > newFilterValue.to
        ) {
            newFilterValue.from = filterValue.to;
            newFilterValue.to = filterValue.from;
        }

        handleFilterChange(field, newFilterValue);
        handleOpen();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleApply();
        }
    };

    const handleClear = () => {
        const filterNullValue = {
            from: null,
            to: null,
        };
        handleFilterChange(field, filterNullValue);
        setFilterValue(setFilterValue(filterNullValue));
    };

    const getFilterLabel = () => {
        if (isNumber(value?.from) && isNumber(value?.to)) {
            return `${title}: ${value.from}–${value.to}`;
        } else if (isNumber(value?.from)) {
            return `${title}: From ${value.from}`;
        } else if (isNumber(value?.to)) {
            return `${title}: Up To ${value.to}`;
        } else {
            return title;
        }
    };

    const content = (
        <div className={classes.filterWrapper} onKeyDown={handleKeyDown}>
            <div className={classes.popoverFilterInputsWrapper}>
                <Input.Group compact>
                    <InputNumber
                        type='number'
                        min={min}
                        max={max}
                        placeholder='From'
                        value={filterValue?.from}
                        onChange={handleChangeFrom}
                    />
                    <InputNumber
                        type='number'
                        min={min}
                        max={max}
                        placeholder='To'
                        value={filterValue?.to}
                        onChange={handleChangeTo}
                    />
                </Input.Group>
            </div>
            <Button type='primary' ghost onClick={handleApply}>
                Apply
            </Button>
        </div>
    );

    return (
        <div className={`${classes.popoverBtn} ${isActive ? 'active' : ''}`}>
            <CustomDropdown
                withExtraContent
                dropdownOpenElement={
                    <FilterPopoverButon
                        label={getFilterLabel()}
                        showArrow={
                            !isNumber(value?.from) && !isNumber(value?.to)
                        }
                        isOpen={isOpen}
                        handleOpen={handleOpen}
                    />
                }
                dropdownContent={content}
                dropdownOverlayClassName={classes.popoverOverlay}
                dropdownPlacement='bottom'
                dropdownTriggersArray={['click']}
                handleOpenChange={handleOpen}
                isDropdownOpen={isOpen}
            />
            {(isNumber(value?.from) || isNumber(value?.to)) && (
                <div className={classes.clearBtn} onClick={handleClear}>
                    <CloseIcon color={iconColor} size='16' />
                </div>
            )}
        </div>
    );
};

export default FromToFiltersDropdown;
