import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    selectCurrentUserRoles,
    selectUserOrganization,
} from '../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsGlobalTechAdmin } from '../helpers/checkIsGlobalTechAdmin';

export const useOrganizationSlug = () => {
    const currentUserRoles = useSelector(selectCurrentUserRoles);
    const userOrganization = useSelector(selectUserOrganization);
    const { organizationSlug } = useParams();

    const getSlug = () => {
        if (checkIsGlobalTechAdmin(currentUserRoles)) {
            return 'admin';
        }

        if (organizationSlug) {
            return organizationSlug;
        } else if (userOrganization?.custom_slug) {
            return userOrganization.custom_slug;
        } else if (userOrganization?.slug) {
            return userOrganization.slug;
        } else {
            return null;
        }
    };

    return getSlug();
};
