export const userRoleRadioItems = [
    {
        label: 'Member',
        value: 'organization_member',
        description: 'Has access to all base Rankability features like Content Optimizer, Content Monitor and Keyword Research.',
    },
    {
        label: 'Manager',
        value: 'organization_manager',
        description: 'Has access to user management and organization settings except for billing.',
    },
    {
        label: 'Admin',
        value: 'organization_admin',
        description: 'Has full account access including billing and user management.',
    },
];

export const defaultUserRole = userRoleRadioItems[0].value;