import ContentOptimizerIcon from '../../../resources/icons/ContentOptimizer';
import KeywordResearchIcon from '../../../resources/icons/KeywordResearch';
import ContentMonitorIcon from '../../../resources/icons/ContentMonitor';

const DEFAULT_ICONS_COLOR = '#ffffff';

export const homeCardContentKeys = {
    createOptimizer: 'create-optimizer',
    improveOptimizer: 'improve-optimizer',
    keywordResearch: 'keyword-research',
    contentMonitor: 'content-monitor',
};

export const homeCardContent = [
    {
        key: homeCardContentKeys.createOptimizer,
        title: 'Create & Optimize New Content',
        subtitle: 'Looking for a proven way to create new content that ranks?',
        description:
            'First, we’ll help you build a detailed Content Brief to set the stage for writing highly-optimized content. Finally, our Content Optimizer will provide AI-generated keyword recommendations from top-performing competitors to ensure your content has maximum relevancy.',
        startOptimizingPath: 'optimizer',
        howToUse: null,
        buttonName: 'Start Optimizing',
        icon: (
            <span id='s-optimizer-icon-container' className='title-icon'>
                <ContentOptimizerIcon size={17} color={DEFAULT_ICONS_COLOR} />
            </span>
        ),
    },
    {
        key: homeCardContentKeys.improveOptimizer,
        title: 'Improve & Optimize Existing Content',
        subtitle: 'Need to optimize existing content so it ranks higher?',
        description:
            'Use our Import URL Content feature inside Content Optimizer to import existing content and improve its Rankability Score using natural language processing (NLP) AI technology from Google and IBM Watson.',
        startOptimizingPath: 'optimizer',
        howToUse: null,
        buttonName: 'Start Optimizing',
        icon: (
            <span id='i-optimizer-icon-container' className='title-icon'>
                <ContentOptimizerIcon size={17} color={DEFAULT_ICONS_COLOR} />
            </span>
        ),
    },
    {
        key: homeCardContentKeys.keywordResearch,
        title: 'Research Content Ideas',
        subtitle: 'Got writer’s block or wondering what to write about?',
        description:
            'Our Keyword Research tool will quickly help you find relevant content ideas and topics related to your target keyword based on accurate and up-to-date search data from Google.',
        startOptimizingPath: 'research',
        howToUse: null,
        buttonName: 'Start Researching',
        icon: (
            <span id='kr-icon-container' className='title-icon'>
                <KeywordResearchIcon size={17} color={DEFAULT_ICONS_COLOR} />
            </span>
        ),
    },
    {
        key: homeCardContentKeys.contentMonitor,
        title: 'Monitor Existing Content',
        subtitle: 'Interested in monitoring content for multiple websites?',
        description:
            'Our Content Monitor will keep track of your content’s Rankability scores and performance over time so you’ll know precisely which URLs and web pages need the most attention.',
        startOptimizingPath: 'monitor',
        howToUse: null,
        buttonName: 'Start Monitoring',
        icon: (
            <span id='cm-icon-container' className='title-icon'>
                <ContentMonitorIcon size={17} color={DEFAULT_ICONS_COLOR} />
            </span>
        ),
    },
];
