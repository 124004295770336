import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { invitesPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';
import { errorNotificationMessage, notificationType } from '../../constants/notificationType';

import { selectInvites } from '../../components/pages/account/store/accountSettings.selectors';
import { setInvites } from '../../components/pages/account/store/accountSettings.actions';

import { openNotification } from '../helpers/openNotification';
import { getAxiosHeaders } from '../helpers/getAxiosHeaders';

import { axiosAPI } from '../axiosAPI';

export const useInvites = () => {
    const dispatch = useDispatch();

    const invites = useSelector(selectInvites);

    const [isInvitesLoaded, setIsInvitesLoaded] = useState(false);

    useEffect(() => {
        if (invites.length < 1) {
            setIsInvitesLoaded(true);

            axiosAPI
                .get(invitesPathes.getListOfInvites, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setInvites(result?.data));
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsInvitesLoaded(false);
                });
        }

        // eslint-disable-next-line
    }, []);

    return { invites, isInvitesLoaded };
};
