import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';
import moment from 'moment';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { userDataPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';

import Loader from '../../../common/loader/Loader';

import ReportsChartsSection from './ReportsChartsSection';
import UserStatsTable from './UserStatsTable';

import './UsageContent.scss';

const classes = {
    usageContentRoot: 'usage-content',
};

const UsageContent = () => {
    const [usageData, setUsageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        axiosAPI
            .get(`${userDataPathes.usage}`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    setUsageData({
                        contentBriefCount:
                            result?.data?.content_brief_count?.map((item) => ({
                                name: moment(item?.date).format('MMM DD, YYYY'),
                                value: item?.value,
                            })),
                        keywordResearchCount:
                            result?.data?.keyword_research_count?.map(
                                (item) => ({
                                    name: moment(item?.date).format(
                                        'MMM DD, YYYY'
                                    ),
                                    value: item?.value,
                                })
                            ),
                        userStats: result?.data?.user_stats?.map((item) => ({
                            userId: item?.id,
                            user: `${item?.first_name} ${item?.last_name}`,
                            reports: item?.content_brief_count,
                            keywordDiscoveries: item?.keyword_research_count,
                            aiContent: item?.ai_brief_count,
                        })),
                    });
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={`${classes.usageContentRoot}`}>
            <Spin spinning={isLoading} indicator={<Loader />}>
                <ReportsChartsSection
                    reports={usageData?.contentBriefCount}
                    keywordDiscoveries={usageData?.keywordResearchCount}
                />
                <UserStatsTable userStats={usageData?.userStats} />
            </Spin>
        </div>
    );
};

export default UsageContent;
