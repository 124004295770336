import React from 'react';

const MastermindCallIcon = ({ color = '#9199AD' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.7701 19.7498H9.26012C8.82012 19.7498 8.26012 19.6998 7.89012 19.3198C7.53012 18.9498 7.54012 18.4898 7.55012 18.1798V17.2898C5.03012 15.6098 3.37012 12.6898 3.37012 9.9098C3.37012 7.2798 4.55012 4.8198 6.60012 3.1698C8.65012 1.5198 11.3401 0.899803 13.9701 1.4798C16.4701 2.0298 18.6301 3.6998 19.7501 5.9498C21.9201 10.3198 19.8301 15.0498 16.4901 17.2998V18.0498C16.5001 18.3398 16.5101 18.8698 16.1001 19.2898C15.7901 19.5898 15.3501 19.7498 14.7701 19.7498ZM9.04012 18.2398C9.09012 18.2398 9.16012 18.2498 9.25012 18.2498H14.7701C14.8701 18.2498 14.9401 18.2398 14.9801 18.2298C14.9801 18.1998 14.9801 18.1598 14.9801 18.1298V16.8698C14.9801 16.6098 15.1201 16.3598 15.3401 16.2298C18.3001 14.4398 20.2601 10.3498 18.3901 6.5998C17.4701 4.7498 15.6901 3.3798 13.6301 2.9298C11.4501 2.4498 9.22012 2.9598 7.52012 4.3298C5.82012 5.6998 4.85012 7.7298 4.85012 9.9098C4.85012 12.6898 6.77012 15.0898 8.67012 16.2398C8.90012 16.3798 9.03012 16.6198 9.03012 16.8798V18.2298C9.04012 18.2298 9.04012 18.2298 9.04012 18.2398Z'
                fill={color}
                style={{ stroke: 'none' }}
            />
            <path
                d='M15.5003 22.7499C15.4303 22.7499 15.3603 22.7399 15.2903 22.7199C13.1303 22.0999 10.8603 22.0999 8.70025 22.7199C8.30025 22.8299 7.89025 22.5999 7.77025 22.1999C7.65025 21.7999 7.89025 21.3899 8.29025 21.2699C10.7103 20.5799 13.2803 20.5799 15.7003 21.2699C16.1003 21.3799 16.3303 21.7999 16.2203 22.1999C16.1303 22.5399 15.8303 22.7499 15.5003 22.7499Z'
                fill={color}
                style={{ stroke: 'none' }}
            />
        </svg>
    );
};

export default MastermindCallIcon;
