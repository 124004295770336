export const competitorsSortItems = [
    { label: 'Desktop position', key: 'desktop-position' },
    { label: 'Mobile position', key: 'mobile-position' },
    { label: 'Content grade', key: 'content-grade' },
    { label: 'Word count', key: 'word-count' },
    { label: 'Domain rank', key: 'domain-rank' },
];

export const competitorsSortDictionary = {
    'desktop-position': 'desktop',
    'mobile-position': 'mobile',
    'content-grade': 'rankabilityScore',
    'word-count': 'wordCount',
    'competitor-title': 'title',
    'domain-rank': 'domainRank',
};

export const competitorsSortKeys = {
    desktop: 'desktop-position',
    mobile: 'mobile-position',
    rankabilityScore: 'content-grade',
    wordCount: 'word-count',
    title: 'competitor-title',
    domainRank: 'domain-rank',
};
