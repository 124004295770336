import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popconfirm, Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { subscriptionLimits } from '../../../../../constants/subscriptionLimits';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { userDataPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import {
    decreaseSubscriptionLimits,
    increaseSubscriptionLimits,
} from '../../billing/store/billingSettings.actions';
import { updateUserActive } from '../../store/accountSettings.actions';
import {
    selectUsedUsers,
    selectUsersLimit,
} from '../../billing/store/billingSettings.selectors';

const { Link } = Typography;

const classes = {
    deletePopconfirm: 'delete-popconfirm',
    link: 'link-hover-highlight space-no-wrap',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const DisableUserPopConfirm = ({ userId, userRole, active }) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const usersLimit = useSelector(selectUsersLimit);
    const usersUsed = useSelector(selectUsedUsers);

    const [isLoading, setIsLoading] = useState(false);

    const handleDisable = () => {
        setIsLoading(true);

        axiosAPI
            .patch(
                `${userDataPathes.changeUserRole}${userId}/change-role`,
                { role_name: userRole, active: !active },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    active
                        ? dispatch(
                              decreaseSubscriptionLimits({
                                  limit: subscriptionLimits.userSeatsUsed,
                                  value: 1,
                              })
                          )
                        : dispatch(
                              increaseSubscriptionLimits({
                                  limit: subscriptionLimits.userSeatsUsed,
                                  value: 1,
                              })
                          );
                    dispatch(
                        updateUserActive({
                            id: userId,
                            active: !active,
                        })
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if ((usersUsed === usersLimit && !active) || !usersLimit) {
        return (
            <Tooltip
                title='You have reached the limit of users for your subscription plan. Please upgrade your subscription to invite more users.'
                placement='top'
                overlayClassName={`${classes.tooltipOverlay}
                                   ${
                                       darkMode
                                           ? classes.tooltipOverlayDark
                                           : ''
                                   }`}
            >
                <Link
                    underline
                    className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
                    disabled
                >
                    {active ? 'Disable' : 'Activate'}
                </Link>
            </Tooltip>
        );
    }

    return (
        <Popconfirm
            placement='topRight'
            title={`Are you sure you want to ${
                active ? 'disable' : 'activate'
            } this user?`}
            onConfirm={handleDisable}
            okText='Yes'
            cancelText='Cancel'
            loading={isLoading}
            overlayClassName={`${classes.deletePopconfirm}
                               ${darkMode ? 'delete-popconfirm-dark' : ''}`}
        >
            <Link
                underline
                className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
            >
                {active ? 'Disable' : 'Activate'}
            </Link>
        </Popconfirm>
    );
};

export default DisableUserPopConfirm;
