import React from 'react';

const LinkIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.69228 8.64075C7.6035 9.55198 9.08089 9.55198 9.99211 8.64076L12.3491 6.28373C13.2604 5.37251 13.2604 3.89512 12.3491 2.9839C11.4379 2.07268 9.96053 2.07268 9.0493 2.9839L7.87079 4.16241'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M8.5792 6.75475C7.66798 5.84353 6.1906 5.84353 5.27937 6.75475L2.92235 9.11178C2.01113 10.023 2.01113 11.5004 2.92235 12.4116C3.83357 13.3228 5.31096 13.3228 6.22218 12.4116L7.40069 11.2331'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default LinkIcon;
