import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectUserRegistrationEmail } from '../store/authSettings.selectors';

import { unauthorizedRoutes } from '../../../common/router/Unauthorized/routes';

import { resendDefaultTimerValueInSeconds } from '../../../../constants/authConsts';
import { authPathes } from '../../../../constants/queryPathes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';

import './ConfirmInstructions.scss';

const classes = {
    root: 'confirm-instructions-wrapper',
    mainTypography: 'main-typography',
    darkModeTypography: 'dark-theme-typography dark-mode',
    btn: 'confirm-instructions-button',
};

const ConfirmInstructions = () => {
    let interval = useRef();

    const navigate = useNavigate();
    const darkMode = useSelector(selectDarkMode);
    const email = useSelector(selectUserRegistrationEmail);

    const [remainingTime, setRemainingTime] = useState(
        resendDefaultTimerValueInSeconds
    );

    const [loading, setLoading] = useState();

    useEffect(() => {
        if (remainingTime === resendDefaultTimerValueInSeconds) {
            interval.current = setInterval(() => {
                setRemainingTime((prev) => prev - 1);
            }, 1000);
        }

        if (remainingTime <= 0) {
            clearInterval(interval.current);
        }
    }, [remainingTime]);

    useEffect(() => {
        if (!email) {
            navigate(unauthorizedRoutes.login);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const resendEmail = async () => {
        setLoading(true);

        await axiosAPI.post(
            authPathes.resendConfirm,
            {
                email: email,
            },
            { ...getAxiosHeaders() }
        );
        setLoading(false);
        setRemainingTime(resendDefaultTimerValueInSeconds);
    };

    return (
        <div className={classes.root}>
            <Typography
                className={`${classes.mainTypography} ${
                    darkMode ? classes.darkModeTypography : ''
                }`}
            >
                {email}
            </Typography>
            <Typography>
                A temporary link was sent to the email address above. Please
                check your junk/spam or trash folders as well.
            </Typography>
            <Button
                onClick={resendEmail}
                className={classes.btn}
                type='primary'
                disabled={remainingTime || loading}
            >{`Resend${
                remainingTime ? ` in ${remainingTime} seconds` : ''
            }`}</Button>
        </div>
    );
};

export default ConfirmInstructions;
