import React from 'react';
import { useSelector } from 'react-redux';

import { Badge, Card, Typography } from 'antd';

import { getNumberWithCommaSeparator } from '../../../utils/helpers/getNumberWithCommaSeparator';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import CustomDivider from '../Divider';
import { colorPalette } from '../../../resources/styles/colorPalette';

const classes = {
    baseChartTooltipRoot: 'base-chart-tooltip-root',
    valueLine: 'd-flex justify-content-between',
    titleLabel: 'd-flex flex-center title-label',
    valueLabel: 'value-label',
    valueBudge: 'value-budge',
};

// label and payload props are basic rechart props (You shouldn't provide these)
const BaseChartTooltip = ({ dataKey, valueDescLabel, label, payload }) => {
    const darkTheme = useSelector(selectDarkMode);

    return (
        <Card className={classes.baseChartTooltipRoot}>
            <Typography className={`${classes.titleLabel} ${darkTheme && 'dark-mode'}`}>
                {label}
            </Typography>

            <CustomDivider />

            {payload.map((entry, index) => {
                const value = dataKey ? entry[dataKey] : entry.value;

                return (
                    <div key={index} className={classes.valueLine}>
                        <Badge color={colorPalette.colorPrimary} className={classes.valueBudge} />
                        <Typography>{valueDescLabel || entry.name || 'Value'}</Typography>
                        <Typography
                            className={`${classes.valueLabel} ${darkTheme ? 'dark-mode' : ''}`}
                        >
                            {getNumberWithCommaSeparator(value)}
                        </Typography>
                    </div>
                );
            })}
        </Card>
    );
};

export default BaseChartTooltip;
