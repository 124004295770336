import React, { useEffect, useState } from 'react';

import { Input, Typography } from 'antd';

import { usePageTitle } from '../../utils/hooks/usePageTitle';

const { Title } = Typography;

const classes = {
    pageTitleInputWrapper:
        'page-title-input-wrapper d-flex align-items-center w-100',
    label: 'space-no-wrap',
};

const PageTitleInput = () => {
    const { pageTitle, updatePageTitle } = usePageTitle();

    const [inputValue, setInputValue] = useState(pageTitle);

    useEffect(() => {
        setInputValue(pageTitle);

        // eslint-disable-next-line
    }, [pageTitle]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
        updatePageTitle(inputValue);
    };

    return (
        <div className={classes.pageTitleInputWrapper}>
            <Title level={5} className={classes.label}>
                H1 Tag
            </Title>
            <Input
                placeholder='Click to add H1 Tag (Page Title)'
                className='page-title-input'
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
            />
        </div>
    );
};

export default PageTitleInput;
