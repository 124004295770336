import React, { useState } from 'react';

import { Select, Typography } from 'antd';

import SiderChevronDownIcon from '../../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../../../utils/hooks/useIconColor';

import { creditsOptions } from '../../BillingConstants';

const classes = {
    creditsSelectWrapper: 'credits-select-wrapper credits-section-wrapper',
    label: 'label',
};

const CreditsSelectSection = ({ creditsValue, handleChange }) => {
    const iconColor = useIconColor();

    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const handleSelectOpen = (open) => {
        setIsSelectOpen(open);
    };

    const handleCreditsChange = (value) => {
        handleChange(value);
    };

    return (
        <div className={classes.creditsSelectWrapper}>
            <Typography className={classes.label}>Quantity</Typography>
            <Select
                value={creditsValue || creditsOptions[0].value}
                defaultValue={creditsOptions[0].value}
                options={creditsOptions}
                suffixIcon={
                    isSelectOpen ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )
                }
                onDropdownVisibleChange={handleSelectOpen}
                onSelect={handleCreditsChange}
            />
        </div>
    );
};

export default CreditsSelectSection;
