export const handleNavigation = (event, url, navigate) => {
    if (event.ctrlKey) {
        window.open(
            `${process.env.REACT_APP_REDIRECT_DEV_SUB_URL}` + url,
            '_blank',
            'noopener,noreferrer'
        );
    } else {
        navigate(`/` + url);
    }
};
