import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Input, Modal, Typography } from 'antd';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setUserData } from '../../../../../userBrowserSettings/store/browserSettings.actions';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../../resources/icons/CloseIcon';

import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { useOpen } from '../../../../../utils/hooks/useOpen';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { notificationType } from '../../../../../constants/notificationType';
import { organizationPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

const { Link, Title } = Typography;

const classes = {
    modalWrapper: 'modal-wrapper update-slug-modal',
    modalWrapperDark:
        'modal-wrapper-dark update-slug-modal-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalUrlWrapper: 'url-wrapper',
    modalUrlRules: 'url-rules',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    link: 'link-hover-highlight',
};

const UpdateSlugModal = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);

    const { isOpen, handleOpen } = useOpen();
    const organizationSlug = useOrganizationSlug();

    const [showErrorStatus, setShowErrorStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        form.resetFields();
        setShowErrorStatus(false);
        setError(null);
    };

    const handleFormChange = () => {
        if (form.getFieldsError().some((item) => item.errors.length > 0)) {
            setShowErrorStatus(true);
        } else {
            setShowErrorStatus(false);
        }
    };

    const handleUpdateSlug = (values) => {
        if (values.custom_slug !== organizationSlug) {
            if (values.custom_slug !== '') {
                setIsLoading(true);

                axiosAPI
                    .patch(
                        `${organizationPathes.updateOrganization}/${user?.organization?.id}`,
                        values,
                        { ...getAxiosHeaders() }
                    )
                    .then((result) => {
                        if (result?.status === statusCodes.success) {
                            dispatch(
                                setUserData({
                                    ...user,
                                    organization: {
                                        ...user?.organization,
                                        custom_slug: result?.data?.custom_slug,
                                        slug: result?.data?.slug,
                                    },
                                })
                            );
                            handleOpen();
                            openNotification(
                                notificationType.success,
                                'Success',
                                'Profile information has been updated successfully'
                            );
                        }
                    })
                    .catch((error) => {
                        setError(error?.response?.data?.errors?.custom_slug[0]);
                        setShowErrorStatus(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setError('Slug can`t be blank');
                setShowErrorStatus(true);
            }
        } else {
            setError('Organization with this slug already exists');
            setShowErrorStatus(true);
        }
    };

    return (
        <>
            <Link
                underline
                onClick={handleOpen}
                className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
            >
                Change organization URL
            </Link>
            <Modal
                centered
                title='Change organization URL'
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleUpdateSlug}
                    initialValues={{
                        custom_slug: organizationSlug,
                    }}
                    onFieldsChange={handleFormChange}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        <Title level={5}>
                            When you change your organizatiom’s URL, we’ll
                            redirect your old links to the new URLs. Only one
                            redirect can be active at a time.
                        </Title>
                        {user?.organization?.custom_slug && (
                            <Title level={5}>
                                Currently redirecting
                                <span className='text-danger'>
                                    {' '}
                                    {user?.organization?.slug}{' '}
                                </span>
                                to
                                <span className='text-danger'>
                                    {' '}
                                    {user?.organization?.custom_slug}
                                </span>
                            </Title>
                        )}
                        {error && (
                            <ErrorMessage
                                errorTitle='Please check your entry and try again'
                                errorMessage={
                                    error ||
                                    'Slug can only contain lowercase letters, numbers, and hyphens'
                                }
                            />
                        )}
                        <div className={classes.modalUrlWrapper}>
                            <Form.Item
                                name='custom_slug'
                                label='URL'
                                help='' // hide validation message
                            >
                                <Input
                                    placeholder='URL'
                                    addonBefore={`${window.location.origin}/`}
                                    status={
                                        showErrorStatus ? 'error' : 'success'
                                    }
                                />
                            </Form.Item>
                            <div className={classes.modalUrlRules}>
                                <Typography>
                                    Only lowercase letters, numbers, and hyphens
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                            className={`${darkMode ? 'dark-mode' : ''}`}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default UpdateSlugModal;
