import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { Card, Layout } from 'antd';

import RankabilityLightLogoIcon from '../../../../resources/icons/RankabilityLightLogoIcon';
import RankabilityDarkLogoIcon from '../../../../resources/icons/RankabilityDarkLogoIcon';

import './UnauthorizedLayout.scss';

const UnauthorizedLayout = () => {
    const darkMode = useSelector(selectDarkMode);

    const classes = {
        loginPageRoot: 'unauthorized-card-wrapper',
        loginPageIcon: 'd-flex flex-center login-logo',
        darkModeAutofill: 'dark-input-autofill',
        unauthorizedLayout: 'd-flex h-100 unauthorized-layout',
    };

    return (
        <Layout className={classes.unauthorizedLayout}>
            <Card
                bordered={false}
                className={`${classes.loginPageRoot} ${
                    darkMode ? classes.darkModeAutofill : ''
                }`}
            >
                <div className={classes.loginPageIcon}>
                    {darkMode ? (
                        <RankabilityDarkLogoIcon width='220' />
                    ) : (
                        <RankabilityLightLogoIcon width='220' />
                    )}
                </div>
                <Outlet />
            </Card>
        </Layout>
    );
};

export default UnauthorizedLayout;
