import { briefExtrasArrayFields } from '../../constants/briefExtrasArrayFields';

export const getInitialValuesForBriefExtras = (briefExtras) => {
    const initialValues = {};

    if (briefExtras) {
        for (const [key, value] of Object.entries(briefExtras)) {
            if (briefExtrasArrayFields.includes(key)) {
                initialValues[key] = value?.join('\n');
            } else {
                initialValues[key] = value;
            }
        }
    }

    if (initialValues?.word_count === 0) {
        initialValues.word_count = '';
    }

    return initialValues;
};
