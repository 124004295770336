import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { formatAIResponseHtml } from '../utils/aiSpellCheckerHelpers';
import useEditorAiCredits from './useEditorAiCredits';

import { nodeTypes } from '../constants/nodeTypes';

import { setAIState } from '../store/tiptapEditor.actions';

const useAiResponseConfiguration = () => {
    const dispatch = useDispatch();

    const { decrementAiCredits } = useEditorAiCredits();

    const handleAiLoading = useCallback(() => {
        dispatch(
            setAIState({
                isLoading: true,
                errorMessage: null,
            })
        );
    }, [dispatch]);

    const handleAiChunk = useCallback(
        ({ response }) => {
            dispatch(
                setAIState({
                    isLoading: true,
                    errorMessage: null,
                    response,
                })
            );
        },
        [dispatch]
    );

    const manageAiSuccessResponse = useCallback(
        (editor, response) => {
            const formattedResponse = formatAIResponseHtml(response);

            editor.commands.setResponse(formattedResponse);
            editor.commands.setRegenerateOptionEnabled(false);

            const interruptedOptimizer =
                editor.storage[nodeTypes.aiSpellChecker].interruptedOptimizer || {};

            if (!interruptedOptimizer?.id || interruptedOptimizer?.isMatch) {
                dispatch(setAIState({ response: formattedResponse }));
                editor.commands.resetInterruptedOptimizer();
            }
        },
        [dispatch]
    );

    const handleAiSuccess = useCallback(
        ({ editor, response }) => {
            decrementAiCredits(() => {
                manageAiSuccessResponse(editor, response);
            });
        },
        [decrementAiCredits, manageAiSuccessResponse]
    );

    const handleAiError = useCallback(
        (error) => {
            dispatch(
                setAIState({
                    isLoading: false,
                    errorMessage: error.message,
                    response: null,
                })
            );
        },
        [dispatch]
    );

    return {
        handleAiLoading,
        handleAiChunk,
        handleAiSuccess,
        handleAiError,
    };
};

export default useAiResponseConfiguration;
