import { Badge } from 'antd';

export const billingPlanTypes = {
    monthly: {
        value: 'monthly',
        label: 'Monthly',
    },
    yearly: {
        value: 'yearly',
        label: 'Annually',
    },
};

export const billingPlanTypeOptions = [
    {
        label: billingPlanTypes.monthly.label,
        value: billingPlanTypes.monthly.value,
    },
    {
        label: (
            <>
                {billingPlanTypes.yearly.label}
                <Badge />2 months free
            </>
        ),
        value: billingPlanTypes.yearly.value,
    },
];

export const planInfoPlaceholder = [
    {
        title: 'Keyword Research',
        value: true,
    },
    {
        title: 'Content Monitors',
        value: true,
    },
    {
        title: 'Content Optimizers',
        value: true,
    },
    {
        title: 'Mastermind Calls',
        value: true,
    },
    {
        title: 'AI Content Briefs',
        value: true,
    },
    {
        title: 'Monthly Rollovers',
        value: true,
    },
];

export const planRecurringPeriod = {
    month: 'month',
    year: 'year',
};

export const supportMail = 'support@rankability.com';
