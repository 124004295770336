import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Form, Input, Typography } from 'antd';

import TickIcon from '../../../../../../../../../../resources/icons/versionHistory/TickIcon';

import { selectDarkMode } from '../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentContentOptimizer } from '../../../../../../store/contentOptimizerContent.selectors';

import { getKeywordUrlErrorMessage } from '../../../../../../../../../../utils/helpers/keywordHelpers';
import { getThemeColorClass } from '../../../../../../../../../../utils/helpers/themeHelpers';
import { truncateString } from '../../../../../../../../../../utils/helpers/truncateHelper';
import {
    isUrlValid,
    removeUrlExtraSpaces,
} from '../../../../../../../../../../utils/helpers/urlHelpers';

import { MAX_URL_LENGTH } from './constants';

const { Title, Link } = Typography;

const classes = {
    modalWrapper: 'modal-wrapper import-from-url-modal-wrapper',
    modalWrapperDark: 'modal-wrapper-dark import-from-url-modal-wrapper-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    extractedUrlWrapper: 'extracted-url-wrapper',
    noExtractedContentWrapper:
        'no-extracted-content-wrapper d-flex flex-column justify-content-center',
};

const ImportFromUrlForm = ({ form, error, setError, importedUrl, handleOpen, handleImport }) => {
    const darkMode = useSelector(selectDarkMode);
    const contentOptimizer = useSelector(selectCurrentContentOptimizer);

    const [languageCode, setLanguageCode] = useState('en');
    const [countryCode, setCountryCode] = useState('US');

    useEffect(() => {
        if (contentOptimizer?.id) {
            const { content_brief } = contentOptimizer ?? {};
            const { language_code, country_code } = content_brief ?? {};
            language_code && setLanguageCode(language_code);
            country_code && setCountryCode(country_code);
        }
    }, [contentOptimizer]);

    const handleResetErrors = () => {
        form.setFields([
            {
                name: 'url',
                errors: [],
            },
        ]);
    };

    const handleViewContent = () => {
        handleImport({
            url: removeUrlExtraSpaces(importedUrl),
            language_code: languageCode,
            country_code: countryCode,
        });
    };

    const handleTryAgain = () => {
        setError(null);
        form.setFields([
            {
                name: 'url',
                errors: [],
            },
        ]);

        form.setFieldsValue({
            url: null,
        });
    };

    if (error) {
        const urlFieldValue = form.getFieldValue('url') || importedUrl;
        const truncatedUrlFieldValue = truncateString(
            urlFieldValue,
            MAX_URL_LENGTH
        );

        return (
            <div className={classes.noExtractedContentWrapper}>
                <Typography className='text-center'>
                    Unfortunately, we were unable to import the URL content from{' '}
                    <i>{truncatedUrlFieldValue}</i>
                    {`${
                        urlFieldValue?.length < MAX_URL_LENGTH ? '.' : ''
                    }`}{' '}
                    Some URLs are inaccessible due to technical reasons or they
                    block our ability to scrape and import their content for
                    analysis.{' '}
                    <Link onClick={handleTryAgain}>Try a different URL</Link>
                </Typography>
            </div>
        );
    }

    const onFinishClick = (values) => {
        const { url } = values;
        const cleanedUrl = removeUrlExtraSpaces(url);

        if (isUrlValid(cleanedUrl)) {
            handleImport({
                ...values,
                url: cleanedUrl,
                language_code: languageCode,
                country_code: countryCode,
            });
        } else {
            form.setFields([
                {
                    name: 'url',
                    errors: [getKeywordUrlErrorMessage()],
                },
            ]);
        }
    };

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={onFinishClick}
            onValuesChange={handleResetErrors}
        >
            <div className={classes.modalFormFieldsWrapper}>
                <Title level={5}>
                    Add a URL below to extract its content. Keep in mind that some URLs will block
                    our ability to scrape. Also, we try to remove certain web page elements such as
                    navigation, sidebars, and footers to keep the content as relevant as possible.
                </Title>
                {importedUrl && (
                    <div className={classes.extractedUrlWrapper}>
                        <div className='d-flex'>
                            <TickIcon />
                            <Title level={5}>
                                Extracted from{' '}
                                <i>
                                    {truncateString(
                                        importedUrl,
                                        MAX_URL_LENGTH
                                    )}
                                </i>
                            </Title>
                        </div>
                        <Link onClick={handleViewContent}>
                            View extracted content
                        </Link>
                    </div>
                )}
                <Form.Item name='url' label='Add a URL for extraction'>
                    <Input placeholder='e.g. https://www.gotchseo.com/best-seo-books' />
                </Form.Item>
            </div>
            <div className={classes.modalFormButtonsWrapper}>
                <Button
                    type='primary'
                    size='large'
                    ghost
                    onClick={handleOpen}
                    className={`${getThemeColorClass(darkMode)}`}
                >
                    Cancel
                </Button>
                <Button type='primary' size='large' htmlType='submit'>
                    Extract
                </Button>
            </div>
        </Form>
    );
};

export default ImportFromUrlForm;
