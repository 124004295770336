export const dateSortKeys = {
    thisMonth: 'this_month',
    lastMonth: 'last_month',
    last30Days: 'last_30_days',
    last90Days: 'last_90_days',
    last12Months: 'last_12_months',
};

export const monitorDateSortItems = [
    {
        key: dateSortKeys.thisMonth,
        label: 'This month',
    },
    {
        key: dateSortKeys.lastMonth,
        label: 'Last month',
    },
    {
        key: dateSortKeys.last30Days,
        label: 'Last 30 days',
    },
    {
        key: dateSortKeys.last90Days,
        label: 'Last 90 days',
    },
    {
        key: dateSortKeys.last12Months,
        label: 'Last 12 months',
    },
];

export const defaultMonitorDateSortItem = monitorDateSortItems[2]?.key;
