import React from 'react';

import { Button, Input } from 'antd';

const classes = {
    saveLinkButton: 'save-link-button',
};

const EditableLinkInput = ({ defaultValue = '', linkInputRef, onSave }) => {
    return (
        <>
            <Input
                ref={linkInputRef}
                placeholder='Type or paste a URL'
                defaultValue={defaultValue}
                onPressEnter={onSave}
                autoFocus
            />
            <Button type='primary' className={classes.saveLinkButton} onClick={onSave}>
                Save
            </Button>
        </>
    );
};

export default EditableLinkInput;
