import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, Typography } from 'antd';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';

import DarkModeIcon from '../../../../resources/icons/DarkModeIcon';

import { updateDarkMode } from '../../../../userBrowserSettings/store/browserSettings.actions';
import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentEditor } from '../../../pages/contentOptimizer/contentPage/content/optimizerTab/editor/store/contentOptimizerEditor.selectors';

import { useIconColor } from '../../../../utils/hooks/useIconColor';

const classes = {
    darkModeSwitchWrapper:
        'd-flex flex-center justify-content-between align-items-center cursor-pointer',
    darkModeSwitchText: 'ml-8px',
};

const DarkModeSwitchContainer = ({ collapsed }) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const currentEditor = useSelector(selectCurrentEditor);

    const handleChangeDarkMode = () => {
        dispatch(updateDarkMode(!darkMode));
    };

    const iconColor = useIconColor();

    return (
        <>
            <Helmet>
                <meta
                    name='theme-color'
                    content={darkMode ? '#0b0a0f' : '#ffffff'}
                />
            </Helmet>
            <div
                className={`${classes.darkModeSwitchWrapper}
                            ${collapsed && 'cursor-pointer'}`}
                onClick={handleChangeDarkMode}
            >
                <DarkModeIcon color={iconColor} />
                {!collapsed && (
                    <>
                        <Typography className={classes.darkModeSwitchText}>
                            Dark Mode
                        </Typography>
                        <Switch
                            checked={darkMode}
                            onChange={debounce(
                                handleChangeDarkMode,
                                currentEditor ? 100 : 0
                            )}
                            size='small'
                        />
                    </>
                )}
            </div>
        </>
    );
};

const DarkModeSwitch = memo(DarkModeSwitchContainer);

export default DarkModeSwitch;
