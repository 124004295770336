import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';

const CustomDivider = ({ marginBottom, marginTop }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Divider
            className={darkMode ? 'dark-mode' : ''}
            style={{ marginBottom: marginBottom, marginTop: marginTop }}
        />
    );
};

export default CustomDivider;
