import React from 'react';

const ParagraphSpacingIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.5 8L13.8333 8'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.64655 6.11339L1.75989 8.00005L3.64655 9.88672'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
            <path
                d='M13.0931 6.11339L14.9798 8.00005L13.0931 9.88672'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default ParagraphSpacingIcon;
