import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import RankabilityDarkLogoIcon from '../../../../../resources/icons/RankabilityDarkLogoIcon';
import RankabilityLightLogoIcon from '../../../../../resources/icons/RankabilityLightLogoIcon';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import MastermindTimer from './MastermindTimer';

const { Link } = Typography;

const classes = {
    mastermindContentWrapper: 'mastermind-content-wrapper',
    mastermindTitle: 'mastermind-title',
    mastermindSubtitle: 'mastermind-subtitle',

    tooltipOverlay: 'tooltip-overlay mastermind-nathan-label-tooltip',
    tooltipOverlayDark: 'tooltip-overlay-dark',

    textDotted: 'text-decoration-dotted',

    clickHereLink: 'click-here-link',

    addCalendarLabel: 'add-calendar-label',
};

const MastermindCallContent = () => {
    const darkMode = useSelector(selectDarkMode);

    const handleOpenPopupLinkClick = (event) => {
        event.stopPropagation();
    };

    const mastermindNathanLabel = (
        <div>
            To join a call on mobile, download the Zoom app on the{' '}
            <Link
                target='_blank'
                href='https://itunes.apple.com/us/app/id546505307'
                onClick={handleOpenPopupLinkClick}
                className={classes.clickHereLink}
            >
                Apple App Store
            </Link>{' '}
            (iOS) or{' '}
            <Link
                target='_blank'
                href='https://play.google.com/store/apps/details?id=us.zoom.videomeetings'
                onClick={handleOpenPopupLinkClick}
                className={classes.clickHereLink}
            >
                Google Play
            </Link>{' '}
            (Android). Open the Zoom app, click Join Meeting, enter Meeting ID{' '}
            <strong>824 8751 5449</strong> and click Join. If you’re having
            trouble joining a call from your web browser, please try{' '}
            <Link
                target='_blank'
                href='https://zoom.us/join'
                onClick={handleOpenPopupLinkClick}
                className={classes.clickHereLink}
            >
                clicking this link
            </Link>
            , enter Meeting ID <strong>824 8751 5449</strong>, and click Join.
        </div>
    );

    return (
        <div
            className={`${classes.mastermindContentWrapper} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            {darkMode ? (
                <RankabilityDarkLogoIcon width='300' />
            ) : (
                <RankabilityLightLogoIcon width='300' />
            )}

            <Tooltip
                placement='top'
                title={mastermindNathanLabel}
                trigger={['hover']}
                overlayClassName={`${classes.tooltipOverlay}
                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
            >
                <div
                    className={`${classes.mastermindTitle} ${classes.textDotted}`}
                >
                    Weekly Rankability Mastermind w/ Nathan Gotch
                </div>
            </Tooltip>

            <Typography className={classes.mastermindSubtitle}>
                Learn how to harness the power of Rankability with live demos,
                SEO campaign analysis, Q&A, advanced strategies, & more!
            </Typography>
            <Typography className={classes.mastermindSubtitle}>
                No registration required. A button will appear when the
                countdown timer reaches zero to join the live call.
            </Typography>

            <MastermindTimer />

            <div className={`${classes.addCalendarLabel}`}>
                Add to calendar:&nbsp;&nbsp;
                <Link
                    target='_blank'
                    href='https://zoom.us/meeting/tZYpf-6trz4uHt1JB8ui3j8-nvhFJe_tC5mf/calendar/google/add'
                    onClick={handleOpenPopupLinkClick}
                    className={classes.clickHereLink}
                >
                    Google
                </Link>
                &nbsp;&nbsp;
                <Link
                    target='_blank'
                    href='https://zoom.us/meeting/tZYpf-6trz4uHt1JB8ui3j8-nvhFJe_tC5mf/ics'
                    onClick={handleOpenPopupLinkClick}
                    className={classes.clickHereLink}
                >
                    Outlook
                </Link>
                &nbsp;&nbsp;
                <Link
                    target='_blank'
                    href='https://calendar.yahoo.com/?v=60&VIEW=d&TITLE=Weekly%20Rankability%20Mastermind%20w%2F%20Nathan%20Gotch&in_loc=https%3A%2F%2Fus02web.zoom.us%2Fj%2F82487515449&URL=https%3A%2F%2Fus02web.zoom.us%2Fj%2F82487515449&ST=20240327T200000Z&DUR=0100&DESC=Nathan%20Gotch%20is%20inviting%20you%20to%20a%20scheduled%20Zoom%20meeting.%0D%0A%0D%0AJoin%20Zoom%20Meeting%0D%0Ahttps%3A%2F%2Fus02web.zoom.us%2Fj%2F82487515449%0D%0A%0D%0AMeeting%20ID%3A%20824%208751%205449%0D%0A%0D%0A---%0D%0A%0D%0AOne%20tap%20mobile%0D%0A%2B16892781000%2C%2C82487515449%23%20US%0D%0A%2B17193594580%2C%2C82487515449%23%20US%0D%0A%0D%0A---%0D%0A%0D%0ADial%20by%20your%20location%0D%0A%E2%80%A2%20%2B1%20689%20278%201000%20US%0D%0A%E2%80%A2%20%2B1%20719%20359%204580%20US%0D%0A%E2%80%A2%20%2B1%20253%20205%200468%20US%0D%0A%E2%80%A2%20%2B1%20253%20215%208782%20US%20%28Tacoma%29%0D%0A%E2%80%A2%20%2B1%20301%20715%208592%20US%20%28Washington%20DC%29%0D%0A%E2%80%A2%20%2B1%20305%20224%201968%20US%0D%0A%E2%80%A2%20%2B1%20309%20205%203325%20US%0D%0A%E2%80%A2%20%2B1%20312%20626%206799%20US%20%28Chicago%29%0D%0A%E2%80%A2%20%2B1%20346%20248%207799%20US%20%28Houston%29%0D%0A%E2%80%A2%20%2B1%20360%20209%205623%20US%0D%0A%E2%80%A2%20%2B1%20386%20347%205053%20US%0D%0A%E2%80%A2%20%2B1%20507%20473%204847%20US%0D%0A%E2%80%A2%20%2B1%20564%20217%202000%20US%0D%0A%E2%80%A2%20%2B1%20646%20558%208656%20US%20%28New%20York%29%0D%0A%E2%80%A2%20%2B1%20646%20931%203860%20US%0D%0A%E2%80%A2%20%2B1%20669%20444%209171%20US%0D%0A%E2%80%A2%20%2B1%20669%20900%209128%20US%20%28San%20Jose%29%0D%0A%0D%0AMeeting%20ID%3A%20824%208751%205449%0D%0A%0D%0AFind%20your%20local%20number%3A%20https%3A%2F%2Fus02web.zoom.us%2Fu%2FkBTDhgamt%0D%0A%0D%0A'
                    onClick={handleOpenPopupLinkClick}
                    className={classes.clickHereLink}
                >
                    Yahoo
                </Link>
            </div>
        </div>
    );
};

export default MastermindCallContent;
