import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import ExportIcon from '../../../../../../../../resources/icons/ExportIcon';
import { colorPalette } from '../../../../../../../../resources/styles/colorPalette';

import { getTooltipOverlayClass } from '../../../../../../../../utils/helpers/themeHelpers';
import { useSortedOptimizerKeywords } from '../../../../../../../../utils/hooks/useSortedOptimizerKeywords';
import { getOptimizerRightBlockWrapper } from '../optimizerRightBlockHelpers';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentContentOptimizer } from '../../../../store/contentOptimizerContent.selectors';

const classes = {
    exportKeywordsBtn: 'optimizer-keywords-icon-btn',
    tooltipOverlay: 'optimizer-keywords-tooltip-overlay',
};

const OptimizerKeywordsExportButton = () => {
    const darkMode = useSelector(selectDarkMode);
    const currentContentOptimizer = useSelector(selectCurrentContentOptimizer);

    const sortedKeywords = useSortedOptimizerKeywords();

    const handleExport = () => {
        const mappedKeywordsData = sortedKeywords?.map((item) => {
            return {
                term: item?.keyword,
                importance: Math.ceil(item?.importance * 10) * 10 || null,
                heading_presence: item?.heading_presence ? 'present' : null,
                typical_uses_min: item?.typical_uses?.[0] || null,
                typical_uses_max: item?.typical_uses?.[1] || null,
                uses: item?.usesCount || 0,
                semantic_group: 'semantic_group', // TODO: add semantic_group
            };
        });

        let convertedKeywordsData =
            'term,importance,heading_presence,typical_uses_min,typical_uses_max,uses,semantic_group\n';

        mappedKeywordsData?.forEach((item) => {
            const convertedKeywordItem = JSON.stringify(Object.values(item))
                ?.substring(1)
                ?.slice(0, -1)
                ?.replaceAll(',null', ',');

            convertedKeywordsData = convertedKeywordsData + convertedKeywordItem + '\n';
        });

        const BOM = '\uFEFF';
        const url = window.URL.createObjectURL(
            new Blob([BOM + convertedKeywordsData], {
                type: 'text/csv;charset=utf-8',
            })
        );
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.setAttribute(
            'download',
            `${currentContentOptimizer?.content_brief?.keywords}_keywords.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <Tooltip
            title='Export CSV'
            overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
            placement='topRight'
            getPopupContainer={getOptimizerRightBlockWrapper}
        >
            <Button
                className={`${classes.exportKeywordsBtn}
                ${darkMode ? 'dark-mode' : ''}`}
                type='primary'
                size='large'
                ghost
                onClick={handleExport}
            >
                <ExportIcon color={colorPalette.colorPrimary} size={18} />
            </Button>
        </Tooltip>
    );
};

export default OptimizerKeywordsExportButton;
