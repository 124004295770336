import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Typography, Form, Input, Button } from 'antd';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setUserData } from '../../../../../userBrowserSettings/store/browserSettings.actions';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import CloseIcon from '../../../../../resources/icons/CloseIcon';

import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { useOpen } from '../../../../../utils/hooks/useOpen';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { organizationPathes } from '../../../../../constants/queryPathes';
import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

const { Link } = Typography;

const classes = {
    modalWrapper: 'modal-wrapper advanced-settings-modal-wrapper',
    modalWrapperDark:
        'modal-wrapper-dark advanced-settings-modal-wrapper-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    link: 'link-hover-highlight',
};

const AdvancedSettingModal = ({
    title,
    linkText,
    confirmBtnText,
    fieldName,
    valueName,
    fieldLabel,
    fieldPlaceholder,
    pattern,
    patternErrorMessage,
    existErrorMessage,
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);

    const { isOpen, handleOpen } = useOpen();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        setIsLoading(false);
        form.resetFields();
        setError(null);
    };

    const handleApply = (values) => {
        if (values[fieldName]) {
            if (pattern && !pattern.test(values[fieldName])) {
                setError(patternErrorMessage);
            } else if (
                user?.organization?.settings?.[fieldName]?.some(
                    (setting) => setting[valueName] === values[fieldName]
                )
            ) {
                setError(existErrorMessage || 'Value already exists');
            } else {
                setIsLoading(true);

                axiosAPI
                    .patch(
                        `${organizationPathes.updateOrganization}/${user.organization.id}`,
                        {
                            settings: {
                                [fieldName]: user?.organization?.settings?.[
                                    fieldName
                                ]
                                    ? [
                                          ...user?.organization?.settings?.[
                                              fieldName
                                          ],
                                          {
                                              [valueName]: values[fieldName],
                                          },
                                      ]
                                    : [
                                          {
                                              [valueName]: values[fieldName],
                                          },
                                      ],
                            },
                        },
                        { ...getAxiosHeaders() }
                    )
                    .then((res) => {
                        dispatch(
                            setUserData({
                                ...user,
                                organization: {
                                    ...user.organization,
                                    settings: res?.data?.settings,
                                },
                            })
                        );
                        handleOpen();
                    })
                    .catch((error) => {
                        setError(
                            error?.response?.data?.message || 'Please try again'
                        );
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        } else {
            setError(`Text can't be blank`);
        }
    };

    return (
        <>
            <Link
                underline
                onClick={handleOpen}
                className={`${classes.link}
                ${darkMode ? 'dark-mode' : ''}`}
            >
                {linkText}
            </Link>
            <Modal
                centered
                title={title}
                open={isOpen}
                onOk={handleOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    className={classes.profileForm}
                    onFinish={handleApply}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        {error && (
                            <ErrorMessage
                                errorMessage={
                                    error ? error : 'Please try again'
                                }
                            />
                        )}
                        <Form.Item name={fieldName} label={fieldLabel}>
                            <Input placeholder={fieldPlaceholder} />
                        </Form.Item>
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            loading={isLoading}
                        >
                            {confirmBtnText}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default AdvancedSettingModal;
