import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Typography } from 'antd';

import { checkIsAdmin } from '../../../utils/helpers/checkIsAdmin';

import { selectCurrentUserRoles } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import './Notifications.scss';

const { Title } = Typography;

const classes = {
    notificationWrapper: 'warning-notification-wrapper d-flex flex-center',
};

const TopPageWarningNotification = ({
    notificationTitle,
    btnText,
    btnClass,
    hideBtn = false,
    isLoading,
    handleClickButton,
}) => {
    const darkMode = useSelector(selectDarkMode);
    const userRoles = useSelector(selectCurrentUserRoles);

    return (
        <div
            className={`${classes.notificationWrapper}
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            <Title level={5}>{notificationTitle}</Title>

            {checkIsAdmin(userRoles[0]?.name) && !hideBtn && (
                <Button
                    type='primary'
                    onClick={handleClickButton}
                    className={btnClass ? btnClass : ''}
                    loading={isLoading}
                >
                    {btnText}
                </Button>
            )}
        </div>
    );
};

export default TopPageWarningNotification;
