import React from 'react';

const OrderedListIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.71484 4.18799V3.50049L4.71528 3.33301H14.6664V3.50049C14.6664 3.50049 14.6664 3.7004 14.6664 3.83301C14.6664 3.96562 14.6664 4.33301 14.6664 4.33301C14.6664 4.33301 14.2991 4.33301 14.1664 4.33301H4.71528L4.71484 4.18799Z'
                fill={color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.71484 8.18799V7.50049L4.71528 7.33301H14.6664V7.50049C14.6664 7.50049 14.6664 7.7004 14.6664 7.83301C14.6664 7.96562 14.6664 8.33301 14.6664 8.33301C14.6664 8.33301 14.2991 8.33301 14.1664 8.33301H4.71528L4.71484 8.18799Z'
                fill={color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.71484 12.188V11.5005L4.71528 11.333H14.6664V11.5005C14.6664 11.5005 14.6664 11.7004 14.6664 11.833C14.6664 11.9656 14.6664 12.333 14.6664 12.333C14.6664 12.333 14.2991 12.333 14.1664 12.333H4.71528L4.71484 12.188Z'
                fill={color}
            />
            <path
                d='M1.37902 12.198V11.724H1.66602C1.88302 11.724 2.02902 11.587 2.02902 11.407C2.02902 11.222 1.87102 11.097 1.66802 11.097C1.44502 11.097 1.30102 11.249 1.29502 11.407H0.705016C0.721016 10.94 1.07802 10.62 1.69102 10.62C2.27902 10.618 2.64502 10.911 2.64802 11.323C2.64991 11.4643 2.60144 11.6017 2.5113 11.7105C2.42115 11.8193 2.29521 11.8925 2.15602 11.917V11.95C2.31383 11.9618 2.46102 12.0339 2.567 12.1514C2.67298 12.269 2.72957 12.4228 2.72502 12.581C2.72802 13.114 2.22302 13.381 1.67402 13.381C1.01802 13.381 0.674016 13.011 0.666016 12.587H1.24802C1.25602 12.765 1.43402 12.893 1.67002 12.896C1.92402 12.896 2.09402 12.751 2.09202 12.546C2.09002 12.351 1.93702 12.198 1.67802 12.198H1.37802H1.37902ZM1.37502 7.49899H0.771016V7.46399C0.771016 7.05599 1.06602 6.61999 1.72902 6.61999C2.31202 6.61999 2.68902 6.94599 2.68902 7.37599C2.68902 7.76499 2.43202 7.99299 2.21302 8.22399L1.67602 8.79599V8.82599H2.73002V9.33299H0.809016V8.93799L1.76602 7.94799C1.90402 7.80599 2.05902 7.64399 2.05902 7.43999C2.05902 7.25999 1.91202 7.11999 1.71702 7.11999C1.672 7.11834 1.62712 7.12592 1.58514 7.14227C1.54317 7.15862 1.50498 7.18339 1.47294 7.21506C1.4409 7.24672 1.41569 7.28461 1.39884 7.32639C1.382 7.36817 1.37389 7.41296 1.37502 7.45799V7.49899ZM2.23002 5.33299H1.59502V3.25699H1.56402L0.966016 3.67699V3.10999L1.59502 2.66699H2.23002V5.33299Z'
                fill={color}
            />
        </svg>
    );
};

export default OrderedListIcon;
