import { useSelector } from 'react-redux';

import BillingContent from '../../components/pages/account/billing/BillingContent';
import ProfileContent from '../../components/pages/account/profile/ProfileContent';
import SettingsContent from '../../components/pages/account/settings/SettingsContent';
import UsageContent from '../../components/pages/account/usage/UsageContent';
import UsersContent from '../../components/pages/account/users/UsersContent';

import { accountTabs } from '../../constants/accountTabs';
import { roleTypes } from '../../constants/roleTypes';

import {
    selectCurrentUserRoles,
    selectUserData,
} from '../../userBrowserSettings/store/browserSettings.selectors';

import { hasAccessToManageUsers } from '../helpers/hasAccessToManageUsers';

export const useAccountTabsItems = () => {
    const user = useSelector(selectUserData);
    const userRoles = useSelector(selectCurrentUserRoles);

    const tabsItems = [
        {
            key: accountTabs.profile.key,
            label: accountTabs.profile.label,
            children: <ProfileContent />,
            roles: [
                roleTypes.techAdmin.key,
                roleTypes.globalAdmin.key,
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
                roleTypes.organizationMember.key,
            ],
        },
        {
            key: accountTabs.settings.key,
            label: accountTabs.settings.label,
            children: <SettingsContent />,
            roles: [
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
            ],
        },
        {
            key: accountTabs['billing-credits'].key,
            label: accountTabs['billing-credits'].label,
            children: <BillingContent />,
            roles: [
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
            ],
        },
        {
            key: accountTabs.usage.key,
            label: accountTabs.usage.label,
            children: <UsageContent />,
            roles: [
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
            ],
        },
        // TODO: uncomment after implementation
        // {
        //     key: accountTabs.integrations.key,
        //     label: accountTabs.integrations.label,
        //     children: <IntegrationsContent />,
        //     roles: [
        //         roleTypes.organizationOwner.key,
        //         roleTypes.organizationAdmin.key,
        //         roleTypes.organizationManager.key,
        //     ],
        // },
    ];

    if (hasAccessToManageUsers(user?.roles?.[0]?.name)) {
        tabsItems.splice(2, 0, {
            key: accountTabs.users.key,
            label: accountTabs.users.label,
            children: <UsersContent />,
            roles: [
                roleTypes.techAdmin.key,
                roleTypes.globalAdmin.key,
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
            ],
        });
    }

    return tabsItems.filter((item) =>
        item?.roles?.some((itemRole) =>
            userRoles?.some((userRole) => itemRole === userRole?.name)
        )
    );
};
