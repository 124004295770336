import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Modal, Typography } from 'antd';

import CloseIcon from '../../../resources/icons/CloseIcon';
import RankabilityDarkLogoIcon from '../../../resources/icons/RankabilityDarkLogoIcon';
import RankabilityLightLogoIcon from '../../../resources/icons/RankabilityLightLogoIcon';
import { colorPalette } from '../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { setIsAddURLsActive } from '../contentMonitor/mainPage/store/contentMonitorMain.actions';
import { setIsImportURLActive } from '../contentOptimizer/mainPage/store/contentOptimizerMain.actions';

import { useOpen } from '../../../utils/hooks/useOpen';
import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import { homeCardContentKeys } from './constants';

const { Title } = Typography;

const classes = {
    homeCard: 'home-card',
    homeCardTitle: 'home-card-title',
    homeCardSubtitle: 'home-card-subtitle',
    homeCardDescription: 'home-card-description',
    homeCardButtons: 'home-card-buttons',

    startOptimizingBtn: 'start-optimizing-btn',

    howToUseBtn: 'how-to-use-btn ',
    modalWrapper: 'how-to-use-modal',
};

const HomeCard = ({ homeContent }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const darkMode = useSelector(selectDarkMode);

    const organizationSlug = useOrganizationSlug();

    const { isOpen, handleOpen } = useOpen();

    // TODO: — Hide all “How To Use” buttons (temporary)
    // const iconColor = useIconColor();

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleStartOptimizingClick = () => {
        if (homeContent?.startOptimizingPath) {
            if (homeContent?.key === homeCardContentKeys?.improveOptimizer) {
                dispatch(setIsImportURLActive(true));
            } else if (
                homeContent?.key === homeCardContentKeys?.contentMonitor
            ) {
                dispatch(setIsAddURLsActive(true));
            }

            navigate(
                `/${organizationSlug}/${homeContent?.startOptimizingPath}`
            );
        }
    };

    // TODO: — Hide all “How To Use” buttons (temporary)
    // const handleHowToUseClick = () => {
    //     handleOpen();
    // };

    return (
        <>
            <div
                className={`${classes.homeCard} ${darkMode ? 'dark-mode' : ''}`}
            >
                <div>
                    <Title level={4} className={classes.homeCardTitle}>
                        {homeContent?.icon}
                        {homeContent?.title}
                    </Title>
                    <Typography
                        className={`${classes.homeCardSubtitle} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        {homeContent?.subtitle}
                    </Typography>
                    <Typography
                        className={`${classes.homeCardDescription} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                    >
                        {homeContent?.description}
                    </Typography>
                </div>
                <div className={`${classes.homeCardButtons}`}>
                    <Button
                        type='primary'
                        size='large'
                        block
                        className={classes.startOptimizingBtn}
                        onClick={handleStartOptimizingClick}
                    >
                        <span>{homeContent?.buttonName}</span>
                    </Button>
                    {/* // TODO: — Hide all “How To Use” buttons (temporary)

                    <Button
                        size='large'
                        block
                        className={classes.howToUseBtn}
                        onClick={handleHowToUseClick}
                    >
                        <HelpIcon color={iconColor} />
                        <span>How To Use</span>
                    </Button> */}
                </div>
            </div>

            {isOpen && (
                <Modal
                    centered
                    open={isOpen}
                    onCancel={handleOpen}
                    footer={null}
                    closeIcon={<CloseIcon color={closeModalIconColor} />}
                    wrapClassName={classes.modalWrapper}
                >
                    {darkMode ? (
                        <RankabilityDarkLogoIcon width='240' />
                    ) : (
                        <RankabilityLightLogoIcon width='240' />
                    )}
                </Modal>
            )}
        </>
    );
};

export default HomeCard;
