import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { contentMonitorPathes } from '../../../../../constants/queryPathes';

import { getUnfinishedBriefIds } from '../../../contentOptimizer/contentPage/content/briefTab/contentBriefsConstants';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../utils/helpers/openNotification';

import {
    updateContentMonitorChartData,
    updateContentMonitorData,
} from '../store/contentMonitorMain.actions';
import {
    selectContentMonitorData,
    selectIsAddURLsActive,
} from '../store/contentMonitorMain.selectors';

import AddURLsSection from './addURLs/AddURLsSection';
import ContentMonitorChartSection from './chart/ContentMonitorChartSection';
import ContentMonitorTableSection from './table/ContentMonitorTableSection';

import './ContentMonitorContent.scss';

const ContentMonitorMainContent = () => {
    const dispatch = useDispatch();

    const contentMonitorData = useSelector(selectContentMonitorData);
    const isAddURLsActive = useSelector(selectIsAddURLsActive);

    useEffect(() => {
        let updateTimer = null;

        const updateMonitors = (ids) => {
            axiosAPI
                .get(contentMonitorPathes.contentMonitorStatus, {
                    ...getAxiosHeaders(),
                    params: { ids: ids?.join() },
                })
                .then((result) => {
                    batch(() => {
                        dispatch(updateContentMonitorData(result?.data));
                        dispatch(updateContentMonitorChartData(result?.data));
                    });

                    const monitorsToUpdate = getUnfinishedBriefIds(result.data);

                    if (monitorsToUpdate.length) {
                        updateTimer = setTimeout(
                            () => updateMonitors(monitorsToUpdate),
                            15 * 1000
                        );
                    }
                })
                .catch((_err) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        };

        const monitorIdsToUpdate = getUnfinishedBriefIds(
            contentMonitorData || []
        );

        if (monitorIdsToUpdate?.length) {
            updateMonitors(monitorIdsToUpdate);
        }

        return () => {
            if (updateTimer) {
                clearTimeout(updateTimer);
            }
        };
        // eslint-disable-next-line
    }, [contentMonitorData?.length]);

    return (
        <>
            {isAddURLsActive && <AddURLsSection />}
            <ContentMonitorChartSection />
            <ContentMonitorTableSection />
        </>
    );
};

export default ContentMonitorMainContent;
