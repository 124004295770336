import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { useOpen } from '../../../../../utils/hooks/useOpen';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import EditProfileModal from './EditProfileModal';

const classes = {
    link: 'link-hover-highlight space-no-wrap',
};

const EditProfileModalWrapper = ({ editableUser }) => {
    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();

    return (
        <>
            <Typography.Link
                underline
                onClick={handleOpen}
                className={`${classes.link}
                ${darkMode ? 'dark-mode' : ''}`}
            >
                Edit profile
            </Typography.Link>
            <EditProfileModal
                editableUser={editableUser}
                isOpen={isOpen}
                handleOpen={handleOpen}
            />
        </>
    );
};

export default EditProfileModalWrapper;
