import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Form, Radio, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/es/modal/Modal';
import { Link } from 'react-router-dom';

import CancellationService from '../../../../../services/cancellation.service';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import StartIcon from '../../../../../resources/images/Nathan-Gotch-round.png';

import { openBasicErrorNotification } from '../../../../../utils/helpers/openBasicErrorNotification';
import Loader from '../../../../common/loader/Loader';

import { CancellationConsts } from '../../../../../constants/cancellationConsts';

import './CancellationModal.scss';

const classes = {
    modalWrapper: 'modal-wrapper cancellation-modal',
    textSection: 'text-section',
    highlightedSection: 'highlighted-section',
    imageSection: 'image-section',
    cancellationReasonForm: 'cancellation-reason-form',
    formItemButton: 'form-item-button',
    link: 'link',
};

const CancellationModal = ({ closeCallback, submitCallback }) => {
    const darkMode = useSelector(selectDarkMode);
    const [reasons, setReasons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const getReasonsAsync = async () => {
        try {
            setIsLoading(true);

            const response = await CancellationService.getCancellationReasons();

            if (response?.data) {
                setReasons(response.data);
            }
        } catch {
            openBasicErrorNotification();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getReasonsAsync();
    }, []);

    const sendCancellationFeedbackAsync = async (data) => {
        setIsLoading(true);

        try {
            await CancellationService.sendCancellationFeedback(data);
        } catch {
            openBasicErrorNotification();
        } finally {
            setIsLoading(false);
            closeCallback();
        }
    };

    const sendFeedback = () => {
        const formData = form.getFieldsValue();

        sendCancellationFeedbackAsync({
            cancellation_reason_id: formData.reason,
            note: formData.note,
        });
        submitCallback();
    };

    return (
        <Modal
            centered
            open
            closable={false}
            onCancel={closeCallback}
            footer={null}
            wrapClassName={`${classes.modalWrapper}
            ${darkMode ? 'dark-mode' : ''}`}
        >
            <Spin spinning={isLoading} indicator={<Loader />}>
                <div className={classes.imageSection}>
                    <img src={StartIcon} alt='' />
                    <h1>Personal note from Nathan Gotch</h1>
                </div>
                <div className={classes.textSection}>
                    <p>
                        Hey, it's Nathan (co-founder of Rankability) here. Thank
                        you for using our software, I truly appreciate your
                        business and you're always welcome back!
                    </p>
                </div>
                <div className={classes.highlightedSection}>
                    <p>
                        Before you go, please share why you're leaving and let
                        me know where we fell short or could improve. I read
                        single every message – thank you so much 🙏
                    </p>
                </div>
                <div className={classes.cancellationReasonForm}>
                    <Form form={form} onFinish={sendFeedback}>
                        <Form.Item name='reason'>
                            <Radio.Group>
                                {reasons.map((item) => (
                                    <Radio value={item.id}>{item.text}</Radio>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name='note'>
                            <TextArea
                                rows={4}
                                draggable={false}
                                maxLength={CancellationConsts.NOTE_MAX_LENGTH}
                                placeholder='Anything you want to share? (Optional)'
                            />
                        </Form.Item>
                        <Form.Item className={classes.formItemButton}>
                            <Button type='primary' htmlType='submit'>
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form>
                    <Link onClick={closeCallback} className={classes.link}>
                        Nevermind, I don't want to cancel.
                    </Link>
                </div>
            </Spin>
        </Modal>
    );
};

export default CancellationModal;
