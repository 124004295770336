import React from 'react';

const DecreaseArrowIcon = ({ color = '#d52624' }) => {
    return (
        <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 10.5 10.5'
            width='12px'
            height='12px'
        >
            <defs></defs>
            <path
                fill={color}
                d='M21,11.2a.74.74,0,0,1-.22.53.75.75,0,0,1-1.06,0l-8.2-8.2a.75.75,0,1,1,1.06-1.06l8.2,8.2A.75.75,0,0,1,21,11.2Z'
                transform='translate(-11.25 -2.25)'
            />
            <path
                fill={color}
                d='M21.75,7.17V12a.76.76,0,0,1-.75.75H16.17a.75.75,0,1,1,0-1.5h4.08V7.17a.75.75,0,0,1,1.5,0Z'
                transform='translate(-11.25 -2.25)'
            />
        </svg>
    );
};

export default DecreaseArrowIcon;
