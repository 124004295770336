import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { NodeViewWrapper, useCurrentEditor } from '@tiptap/react';
import { isNumber } from 'lodash';

import { selectAIStateAccepted, selectAIStateResponse } from '../../store/tiptapEditor.selectors';

import AiActionsPanel from './AiActionsPanel';
import AiResponseContent from './AiResponseContent';

import './AiSpellChecker.scss';

const AiSpellChecker = ({ node }) => {
    const { editor } = useCurrentEditor();

    const nodeViewWrapperRef = useRef(null);

    const { from, to } = node?.attrs || {};

    const aiAccepted = useSelector(selectAIStateAccepted);
    const response = useSelector(selectAIStateResponse);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (nodeViewWrapperRef.current) {
                nodeViewWrapperRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }, 100);

        return () => clearTimeout(timeout);
    }, [response]);

    if (!editor || !isNumber(from) || !isNumber(to) || aiAccepted) {
        return null;
    }

    return (
        <NodeViewWrapper ref={nodeViewWrapperRef} as='div'>
            {response ? <AiResponseContent from={from} to={to} /> : <AiActionsPanel />}
        </NodeViewWrapper>
    );
};

export default AiSpellChecker;
