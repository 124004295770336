import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomHeader from '../../../../../common/header/Header';

import { setMonitorsQueryParams } from '../../store/contentMonitorMain.actions';
import { selectContentMonitorQueryParams } from '../../store/contentMonitorMain.selectors';

import { defaultMonitorDateSortItem, monitorDateSortItems } from './constants';

const ContentMonitorMainPageHeader = () => {
    const dispatch = useDispatch();

    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);

    const handleSortChange = (newDateRangeValue) => {
        dispatch(setMonitorsQueryParams({ dateRange: newDateRangeValue }));
    };

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (!monitorQueryParams?.search) {
            setSearchValue('');
        }
    }, [monitorQueryParams?.search]);

    useEffect(() => {
        const updateMonitorsQueryParams = setTimeout(() => {
            dispatch(
                setMonitorsQueryParams({
                    search: searchValue.trim(),
                })
            );
        }, 1500);

        return () => clearTimeout(updateMonitorsQueryParams);

        // eslint-disable-next-line
    }, [searchValue]);

    const handleMonitorSearch = (value) => {
        setSearchValue(value);
    };

    return (
        <CustomHeader
            showSearch
            handleSearch={handleMonitorSearch}
            showSort
            sortItems={monitorDateSortItems}
            sortDropdownPrefix={'Graph display'}
            selectedSort={
                monitorQueryParams?.dateRange || defaultMonitorDateSortItem
            }
            handleSort={handleSortChange}
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
        />
    );
};

export default ContentMonitorMainPageHeader;
