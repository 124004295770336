import React from 'react';

import SiderChevronLeftIcon from '../../../../resources/icons/SiderChevronLeft';
import SiderChevronRightIcon from '../../../../resources/icons/SiderChevronRight';

import './SiderHeader.scss';

const classes = {
    siderCollapseBtnWrapper:
        'sider-collapse-btn cursor-pointer d-flex flex-center',
};

const SiderCollapseButton = ({ collapsed, handleCollapsed }) => {
    return (
        <div
            className={classes.siderCollapseBtnWrapper}
            onClick={handleCollapsed}
        >
            {collapsed ? <SiderChevronRightIcon /> : <SiderChevronLeftIcon />}
        </div>
    );
};

export default SiderCollapseButton;
