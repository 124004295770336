import React from 'react';

import { Typography } from 'antd';

import QuotaPageContent from './content/QuotaPageContent';
import QuotaPageHeader from './content/QuotaPageHeader';

import './QuotasPage.scss';

const { Title } = Typography;

const classes = {
    pageLayoutWrapper: 'page-layout-wrapper',
    quotaTitleWrapper: 'page-title-wrapper',
    pageTitle: 'page-title',
};

const QuotaPage = () => {
    return (
        <>
            <QuotaPageHeader />
            <div className={classes.pageLayoutWrapper}>
                <div className={classes.quotaTitleWrapper}>
                    <Title level={2} className={classes.pageTitle}>
                        Quotas
                    </Title>
                </div>
                <QuotaPageContent />
            </div>
        </>
    );
};

export default QuotaPage;
