import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Popconfirm, Typography } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../../constants/statusCodes';

import RemoveIcon from '../../../../../../../../../resources/icons/outline/RemoveIcon';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../../../utils/helpers/openNotification';
import { useIconColor } from "../../../../../../../../../utils/hooks/useIconColor";
import { useOpen } from '../../../../../../../../../utils/hooks/useOpen';

import AIButton from '../../../../../../../../common/aiButton/AIButton';
import MoreDropdown from '../../../../../../../../common/moreDropdown/MoreDropdown';
import VersionHistory from '../../../../../../../../common/versionHistory/VersionHistory';

import {
    deleteBriefVersion,
    setCurrentBrief,
    setCurrentBriefLoading,
    setPreviewBriefVersion,
    updateBriefVersion,
    updateBriefVersionName,
} from '../store/contentBriefsOutline.actions';
import {
    selectBriefVersions,
    selectCurrentBrief,
    selectCurrentBriefId,
    selectCurrentBriefLoading,
    selectCurrentOutlineVersion,
    selectPreviewBriefVersion,
} from '../store/contentBriefsOutline.selectors';

import './BriefContent.scss';
import BriefStatusBar from '../../../../BriefStatusBar';

const { Title } = Typography;

const classes = {
    versionHistoryContainer: 'version-history-container',
    briefTabTitle: 'brief-tab-title',
    label: 'space-no-wrap',
    versionHistoryDropdownTitle: 'version-history-dropdown-title d-flex align-items-center',
    versionHistoryDropdownTitleDark: 'version-history-dropdown-title-dark d-flex align-items-center',

    deletePopconfirm: 'delete-popconfirm',
    deletePopconfirmDark: 'delete-popconfirm-dark dark-mode',
};

const BriefVersionHistoryContainer = ({
    createNewBriefVersion,
    fetchBriefVersions,
    newVersionCreating,
}) => {
    const { contentOptimizerId } = useParams();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const briefVersions = useSelector(selectBriefVersions);
    const currentBriefLoading = useSelector(selectCurrentBriefLoading);
    const currentOutlineVersion = useSelector(selectCurrentOutlineVersion);
    const briefPreviewVersion = useSelector(selectPreviewBriefVersion);

    const currentBrief = useSelector(selectCurrentBrief);
    const currentBriefId = useSelector(selectCurrentBriefId);

    const [mappedBriefVersions, setMappedBriefVersions] = useState([]);

    const { isOpen: isDropdownOpen, handleOpen: handleDropdownOpen } =
        useOpen();

    const iconColor = useIconColor();

    useEffect(() => {
        if (briefVersions?.length > 0) {
            setMappedBriefVersions(
                briefVersions?.map((version) => {
                    return {
                        id: version?.id,
                        name: version?.name,
                        active: version?.active,
                        created_at: version?.created_at,
                        wordCount: currentOutlineVersion?.word_count,
                        progress: Math.ceil(version?.progress * 100) || 0,
                    };
                })
            );
        }
    }, [briefVersions, currentOutlineVersion]);

    useEffect(() => {
        if (currentBrief?.ai_job_id) {
            const interval = setInterval(() => {
                fetchBriefVersions();
            }, 1000 * 5);

            return () => { clearInterval(interval); };
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBrief?.ai_job_id]);

    const handleActivateBriefVersion = (briefVersionId) => {
        if (contentOptimizerId && briefVersionId) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${contentOptimizerId}/versions/${briefVersionId}`,
                    { active: true },
                    { ...getAxiosHeaders() }
                )
                .then(() => {
                    fetchBriefVersions();
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handlePreviewBriefVersion = (versionId) => {
        dispatch(
            setPreviewBriefVersion(
                versionId && !briefPreviewVersion ? versionId : null
            )
        );
    };

    const handleUpdateVersionName = (versionId, versionName) => {
        if (contentOptimizerId && versionId) {
            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${contentOptimizerId}/versions/${versionId}`,
                    { name: versionName },
                    { ...getAxiosHeaders() }
                )
                .then(() => {
                    dispatch(
                        updateBriefVersionName({
                            id: versionId,
                            name: versionName,
                        })
                    );
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handleDeleteBriefVersion = (versionId) => {
        if (contentOptimizerId && versionId) {
            axiosAPI
                .delete(
                    `${contentBriefPathes.deleteContentBrief}/${contentOptimizerId}/versions/${versionId}`,
                    { ...getAxiosHeaders() }
                )
                .then(() => {
                    dispatch(deleteBriefVersion(versionId));
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handleUpdateBrief = () => {
        if (currentBrief && currentBriefId) {
            dispatch(setCurrentBriefLoading(true));

            axiosAPI
                .patch(
                    `${contentBriefPathes.createContentBrief}/${currentBrief?.content_brief_id}/versions/${currentBrief?.id}`,
                    {
                        extras: {
                            example_articles: [],
                            meta_description: '',
                            page_url: '',
                            meta_title: '',
                            overview: '',
                            page_title: '',
                            primary_keyword: '',
                            secondary_keywords: '',
                            word_count: 0,
                        },
                    },
                    { ...getAxiosHeaders() }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            dispatch(setCurrentBrief(result?.data));
                            dispatch(
                                updateBriefVersion({
                                    id: result?.data?.id,
                                    progress: result?.data?.progress,
                                    word_count: result?.data?.word_count,
                                })
                            );
                        });
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    dispatch(setCurrentBriefLoading(false));
                });
        }
    };

    const moreItems = [
        {
            key: 'delete-outline',
            label: (
                <Popconfirm
                    placement='top'
                    okText='Yes'
                    cancelText='Cancel'
                    title='Delete this entire Brief Summary?'
                    overlayClassName={`${classes.deletePopconfirm}
                               ${darkMode ? classes.deletePopconfirmDark : ''}`}
                    onConfirm={() => {
                        handleUpdateBrief();
                        handleDropdownOpen();
                    }}
                    onCancel={() => {
                        handleDropdownOpen();
                    }}
                >
                    <div className={
                        `${classes.versionHistoryDropdownTitle} ${darkMode ? classes.versionHistoryDropdownTitleDark : ''}`
                    }>
                        <RemoveIcon color={iconColor} />
                        <span>Delete Summary</span>
                    </div>
                </Popconfirm>
            ),
            onClick: (event) => {
                event.preventDefault();
            },
        },
    ];

    return (
        <div
            className={`${classes.versionHistoryContainer} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <div className={classes.briefTabTitle}>
                <Title level={5} className={classes.label}>
                    CONTENT BRIEF SUMMARY
                </Title>
                <BriefStatusBar />
                <AIButton />
            </div>
            <VersionHistory
                versions={mappedBriefVersions}
                previewVersionId={briefPreviewVersion?.id}
                loading={currentBriefLoading}
                handleActivateVersion={handleActivateBriefVersion}
                handlePreviewVersion={handlePreviewBriefVersion}
                createNewVersion={createNewBriefVersion}
                newVersionCreating={newVersionCreating}
                disableCreateNewVersionBtn={briefPreviewVersion}
                handleUpdateVersionName={handleUpdateVersionName}
                handleDeleteVersion={handleDeleteBriefVersion}
            />
            <MoreDropdown
                moreItems={moreItems}
                isDropdownOpen={isDropdownOpen}
                handleDropdownOpen={handleDropdownOpen}
            />
        </div>
    );
};

export default BriefVersionHistoryContainer;
