export const selectCurrentEditor = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditor;

export const selectCurrentEditorId = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorId;

export const selectEditorVersions = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.editorVersions;

export const selectCurrentEditorLoading = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorLoading;

export const selectSaveEditorLoading = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.saveEditorLoading;

export const selectCurrentEditorVersion = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorVersion;

export const selectCurrentVersionImportedUrl = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentVersionImportedUrl;

export const selectEditorHtmlTags = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.htmlTags;

export const selectEditorFullWidth = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.fullWidth;

export const selectEditorHighlightKeywords = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.highlightKeywords;

/** @deprecated */
export const selectEditorBasicData = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.basicData;

export const selectIsAddLinkModalOpen = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.isAddLinkModalOpen;

export const selectEditorPreviewVersion = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.previewVersion;
