import React from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import './Notifications.scss';

const classes = {
    notificationCard: 'notification-card',
    notificationCardDark: 'notification-card-dark',
};

const NotificationCard = ({ content }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Card
            className={`${classes.notificationCard} ${
                darkMode ? classes.notificationCardDark : ''
            }`}
        >
            {content}
        </Card>
    );
};

export default NotificationCard;
