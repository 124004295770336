export const viewOnlySettingsActions = {
    CLEAR_VIEW_ONLY_SETTINGS: 'VIEW_ONLY_SETTINGS/CLEAR_VIEW_ONLY_SETTINGS',

    SET_ACTIVE_CARD: 'VIEW_ONLY_SETTINGS/SET_ACTIVE_CARD',
    SET_BRIEF_DATA: 'VIEW_ONLY_SETTINGS/SET_BRIEF_DATA',
    SET_OUTLINE_DATA: 'VIEW_ONLY_SETTINGS/SET_OUTLINE_DATA',
    SET_IS_DATA_LOADING: 'VIEW_ONLY_SETTINGS/SET_IS_DATA_LOADING',

    SET_VIEW_ONLY_MODE: 'CONTENT_OPTIMIZER_CONTENT/SET_VIEW_ONLY_MODE',
};

export const setViewOnlyMode = (payload) => {
    return {
        type: viewOnlySettingsActions.SET_VIEW_ONLY_MODE,
        payload,
    };
};

export const setViewOnlyOutlineData = (payload) => {
    return {
        type: viewOnlySettingsActions.SET_OUTLINE_DATA,
        payload,
    };
};

export const setViewOnlyBriefData = (payload) => {
    return {
        type: viewOnlySettingsActions.SET_BRIEF_DATA,
        payload,
    };
};

export const setActiveCard = (payload) => {
    return {
        type: viewOnlySettingsActions.SET_ACTIVE_CARD,
        payload,
    };
};

export const setIsDataLoading = (payload) => {
    return {
        type: viewOnlySettingsActions.SET_IS_DATA_LOADING,
        payload,
    };
};

export const clearViewOnlySettings = () => {
    return {
        type: viewOnlySettingsActions.CLEAR_VIEW_ONLY_SETTINGS,
    };
};
