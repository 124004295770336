import React from 'react';
import { Button } from 'antd';

import SiderChevronUpIcon from '../../../resources/icons/SiderChevronUp';

import { colorPalette } from '../../../resources/styles/colorPalette';

import './GoBackButton.scss';

const classes = {
    btn: 'go-back-btn',
};

const GoBackButton = ({ className, icon, onClick }) => {
    return (
        <Button
            className={`${classes.btn} ${className || ''}`}
            onClick={onClick}
        >
            {icon || <SiderChevronUpIcon color={colorPalette.colorPrimary} />}
        </Button>
    );
};

export default GoBackButton;
