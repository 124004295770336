import React from 'react';

const UnorderedListIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='1 0 15 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='stroke-icon'
        >
            <path
                d='M6 11.333H12.6667'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3 11.335L3.33537 11.333V11.335H3.33337H3Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6 8H12.6667'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3 8H3.33598V8.002H3.33398L3 8Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6 4.66699H12.6667'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M3.33254 4.66699H3.33454V4.66899H2.94531L3.33254 4.66699Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default UnorderedListIcon;
