import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, Tabs, theme } from 'antd';

import { keywordResearchTabs } from '../../../../constants/keywordResearchTabs';
import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import CommonPageLayout from '../../../common/layout/CommonPageLayout';

import {
    updateKeywordQueryParam,
    updateKeywordResearchTab,
} from '../store/keywordResearchSettings.actions';
import { selectKeywordResearchTab } from '../store/keywordResearchSettings.selectors';

import KeywordResearchCredits from '../common/KeywordResearchCredits';

import KeywordResearchContentLayout from './KeywordResearchContentLayout';
import KeywordResearchPageHeader from './KeywordResearchPageHeader';

import './KeywordResearchPage.scss';

const { Content } = Layout;

const classes = {
    content: 'container keyword-research-container',
    contentDark:
        'container-dark keyword-research-container-dark dark-input-autofill',
    keywordViewAllContainer: 'keyword-view-all-container',
};

const KeywordResearchPage = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const selectedTab = useSelector(selectKeywordResearchTab);

    const [isKeywordsLoading, setIsKeywordsLoading] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const tabsContent = (
        <KeywordResearchContentLayout
            isKeywordsLoading={isKeywordsLoading}
            setIsKeywordsLoading={setIsKeywordsLoading}
        />
    );

    const tabsItems = [
        {
            key: keywordResearchTabs.keyword.key,
            label: keywordResearchTabs.keyword.label,
            children: tabsContent,
            disabled: isKeywordsLoading,
        },
        {
            key: keywordResearchTabs.url.key,
            label: keywordResearchTabs.url.label,
            children: tabsContent,
        },
    ];

    const handleChangeTab = (key) => {
        dispatch(updateKeywordResearchTab(key));
        dispatch(updateKeywordQueryParam({ type: key, search: '', page: 1 }));
    };

    return (
        <>
            <KeywordResearchPageHeader />
            <CommonPageLayout>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                            ${darkMode ? classes.contentDark : ''}`}
                >
                    <Tabs
                        defaultActiveKey={selectedTab}
                        activeKey={selectedTab}
                        items={tabsItems}
                        onChange={handleChangeTab}
                        tabBarExtraContent={<KeywordResearchCredits />}
                        destroyInactiveTabPane={true}
                    />
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default KeywordResearchPage;
