import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../../../../constants/queryPathes';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../../../../utils/hooks/useOrganizationSlug';

import { briefOutlineTabs } from '../../../contentOptimizerContentConstants';

import { updateEditorBasicData } from '../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

import ContentBriefOutlineTabs from './ContentBriefOutlineTabs';
import BriefSummaryContainer from './tabsContent/briefSummary/BriefSummaryContainer';
import OutlineOptimizerContainer from './tabsContent/outline/optimizer/OutlineOptimizerContainer';

const classes = {
    briefContent: 'brief-content-wrapper',
};

const BriefTabContent = () => {
    const dispatch = useDispatch();
    const { contentOptimizerId } = useParams();

    const organizationSlug = useOrganizationSlug();

    const darkMode = useSelector(selectDarkMode);

    const [selectedTab, setSelectedTab] = useState(
        localStorage.getItem('briefTabKey') || briefOutlineTabs.brief.key
    );

    const onChange = (key) => {
        localStorage.setItem('briefTabKey', key);
        setSelectedTab(key);
    };

    useEffect(() => {
        if (contentOptimizerId) {
            axiosAPI
                .get(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/`,
                    {},
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then((result) => {
                    dispatch(
                        updateEditorBasicData(
                            result?.data?.find((item) => item?.active)
                        )
                    );
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    return (
        <Row
            className={`${classes.briefContent} 
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            <Col span={17} className='h-100'>
                {selectedTab === briefOutlineTabs.brief.key ? (
                    <BriefSummaryContainer />
                ) : (
                    <OutlineOptimizerContainer />
                )}
            </Col>
            <Col span={7}>
                <ContentBriefOutlineTabs
                    selectedTab={selectedTab}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};

export default BriefTabContent;
