import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'antd';

import CopySectionIcon from '../../../../resources/icons/CopySectionIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { ordering } from '../../../../constants/ordering';
import { keywordPathes } from '../../../../constants/queryPathes';

import { axiosAPI } from '../../../../utils/axiosAPI';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectCurrentKeyword,
    selectKeywordListFilters,
    selectKeywordListSearch,
    selectKeywordListSort,
} from '../store/keywordResearchSettings.selectors';

const classes = {
    root: 'd-flex',
    exportButton: 'copy-button btn-hover-light-primary',
};

const CopyButton = () => {
    const [isLoading, setIsLoading] = useState(false);

    const darkMode = useSelector(selectDarkMode);
    const currentKeyword = useSelector(selectCurrentKeyword);
    const keywordListSort = useSelector(selectKeywordListSort);
    const keywordListSearch = useSelector(selectKeywordListSearch);
    const keywordListFilter = useSelector(selectKeywordListFilters);

    const handleCopy = () => {
        setIsLoading(true);

        axiosAPI
            .get(
                `${keywordPathes.getKeywordResearchInfo}${currentKeyword.id}`,
                {
                    params: {
                        format: 'copy_list',
                        sort: keywordListSort?.field,
                        order: ordering[keywordListSort?.order],
                        search: keywordListSearch,
                        filter_rules: keywordListFilter
                            ? JSON.stringify(keywordListFilter)
                            : null,
                    },
                }
            )
            .then((response) => {
                navigator.clipboard.writeText(response?.data || '');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={classes.root}>
            <Button
                className={`${classes.exportButton}
                ${darkMode ? 'dark-mode' : ''}`}
                icon={
                    <CopySectionIcon
                        color={colorPalette.colorPrimary}
                        size={18}
                    />
                }
                type='primary'
                ghost
                onClick={handleCopy}
                loading={isLoading}
            >
                Copy
            </Button>
        </div>
    );
};

export default CopyButton;
