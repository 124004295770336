import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import { viewModeType } from '../../../../constants/viewModeType';

import KanbanIcon from '../../../../resources/icons/KanbanIcon';
import ListIcon from '../../../../resources/icons/ListIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { updateListViewMode } from '../../../../userBrowserSettings/store/browserSettings.actions';
import { selectDarkMode, selectViewMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    viewModeSwitchWrapper: 'ml-16px view-mode-switcher-wrapper',
    viewModeSwitchItem: 'view-mode-switcher-item',
    viewModeSwitchItemIcon: 'view-mode-switcher-item-icon',
    viewModeSwitchActiveItem: 'view-mode-switcher-item-active',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const ViewModeSwitch = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const viewMode = useSelector(selectViewMode);

    const handleChangeViewMode = (value) => () => {
        if (value && value !== viewMode) {
            dispatch(updateListViewMode(value));
        }
    };

    const segmentedColor = darkMode ? colorPalette.textColor.dark : colorPalette.textColor.light;

    const viewOptions = [
        {
            value: viewModeType.list,
            icon: <ListIcon color={segmentedColor}/>,
            tooltip: 'List View',
        },
        {
            value: viewModeType.kanban,
            icon: <KanbanIcon color={segmentedColor} />,
            tooltip: 'Grid View',
        }
    ];

    return (
            <div className={classes.viewModeSwitchWrapper}>
                    {viewOptions.map((viewOption, index) => 
                        (
                            <Tooltip 
                                key={index}
                                title={viewOption.tooltip}
                                showArrow={true}
                                placement='bottom'
                                overlayClassName={`${classes.tooltipOverlay}
                                                   ${darkMode ? classes.tooltipOverlayDark : ''}`}>
                                <Button 
                                    onClick={handleChangeViewMode(viewOption.value)} 
                                    className={`${classes.viewModeSwitchItem}
                                                ${viewMode === viewOption.value && classes.viewModeSwitchActiveItem}`} 
                                    icon = {<div className={classes.viewModeSwitchItemIcon}>{viewOption.icon}</div>}/> 
                            </Tooltip>
                        ),
                    )}
            </div>
    );
};

export default ViewModeSwitch;
