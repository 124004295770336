import React from 'react';

const UsersIcon = ({ color = '#9199AD'}) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.63338 9.55841C7.55005 9.55008 7.45005 9.55008 7.35838 9.55841C5.37505 9.49175 3.80005 7.86675 3.80005 5.86675C3.80005 3.82508 5.45005 2.16675 7.50005 2.16675C9.54172 2.16675 11.2 3.82508 11.2 5.86675C11.1917 7.86675 9.61672 9.49175 7.63338 9.55841Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
            <path
                d='M13.675 3.83325C15.2916 3.83325 16.5917 5.14159 16.5917 6.74992C16.5917 8.32492 15.3417 9.60825 13.7833 9.66659C13.7167 9.65825 13.6417 9.65825 13.5667 9.66659'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                 strokeLinejoin='round'
            />
            <path
                d='M3.46672 12.6333C1.45006 13.9833 1.45006 16.1833 3.46672 17.5249C5.75839 19.0583 9.51672 19.0583 11.8084 17.5249C13.8251 16.1749 13.8251 13.9749 11.8084 12.6333C9.52506 11.1083 5.76672 11.1083 3.46672 12.6333Z'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='round'
                 strokeLinejoin='round'
            />
            <path
                d='M15.2833 17.1667C15.8833 17.0417 16.45 16.8001 16.9167 16.4417C18.2167 15.4667 18.2167 13.8584 16.9167 12.8834C16.4583 12.5334 15.9 12.3001 15.3083 12.1667'
                stroke={color}
                strokeWidth='1.2'
                strokeLinecap='square'
                 strokeLinejoin='round'
            />
        </svg>
    );
};

export default UsersIcon;
