import React from 'react';

import { colorPalette } from '../../styles/colorPalette';

const AIGenerateIcon = ({ color = colorPalette.colorPrimary }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.0'
            width='18'
            height='18'
            viewBox='0 0 256 256'
            className='fill-icon'
        >
            <g transform='translate(0,256) scale(0.07,-0.07)' fill={color} stroke='none'>
                <path d='M1717 2467 c-20 -62 -37 -93 -64 -120 -27 -27 -58 -44 -120 -64 -58 -19 -83 -32 -83 -43 0 -11 25 -24 83 -43 113 -37 147 -71 184 -184 36 -110 50 -110 86 0 37 113 71 147 184 184 110 36 110 50 0 86 -113 37 -147 71 -184 184 -19 58 -32 83 -43 83 -11 0 -24 -25 -43 -83z' />
                <path d='M616 2378 c-7 -13 -28 -68 -46 -123 -56 -172 -93 -208 -277 -269 -173 -58 -173 -74 0 -132 186 -62 215 -91 282 -284 37 -105 42 -115 65 -115 23 0 28 10 65 115 22 64 51 132 63 153 34 53 93 89 207 127 184 60 185 78 13 136 -185 61 -221 97 -278 269 -33 103 -54 145 -70 145 -5 0 -16 -10 -24 -22z' />
                <path d='M1253 1585 c-15 -11 -38 -65 -78 -187 -69 -208 -101 -272 -170 -337 -67 -64 -114 -86 -323 -156 -135 -44 -176 -62 -189 -81 -14 -22 -14 -26 0 -48 13 -19 54 -37 189 -81 209 -70 256 -92 323 -156 69 -65 101 -129 170 -337 44 -135 62 -176 81 -189 22 -14 26 -14 48 0 19 13 37 54 81 189 69 208 101 272 170 337 67 64 114 86 323 156 135 44 176 62 189 81 14 22 14 26 0 48 -13 19 -54 37 -189 81 -208 69 -249 89 -320 154 -71 65 -103 128 -173 339 -40 122 -63 176 -78 187 -26 18 -28 18 -54 0z' />
            </g>
        </svg>
    );
};

export default AIGenerateIcon;
