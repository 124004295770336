import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Card, Checkbox, List, Typography } from 'antd';

import { getNumberWithCommaSeparator } from '../../../../utils/helpers/getNumberWithCommaSeparator';
import { handleNavigation} from "../../../../utils/helpers/handleNavigation";
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import { difficultyBadgeSize } from '../../../../constants/difficultyBadgeSize';

import {
    selectDarkMode,
    selectViewMode,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateKeywordQueryParam } from '../store/keywordResearchSettings.actions';

import DifficultyBadge from '../common/DifficultyBadge';
import FlagCountrySection from '../../../common/mainPagesLists/FlagCountrySection';
import CreateInfoSection from '../../../common/mainPagesLists/CreateInfoSection';

const { Link } = Typography;

const classes = {
    listCard: 'list-card w-100',
    listCardHeader: 'list-card-header d-flex align-items-center',
    listCardMain:
        'list-card-main d-flex justify-content-between align-items-center',
    listLeftBlock: 'list-left-block d-flex',
    listRightBlock: 'list-right-block d-flex align-items-center',
    listLinkWrapper: 'list-link-wrapper',
    listLink: 'list-link',
    listVolume: 'list-volume',
    volumeText: 'volume-text',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    dFlex: 'd-flex',
};

const KeywordResearchListItem = ({ keywordItem }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const viewMode = useSelector(selectViewMode);

    const organizationSlug = useOrganizationSlug();

    const handleKeywordClick = (event) => {
        const url = `${organizationSlug}/research/${keywordItem.id}`;

        handleNavigation(event, url, navigate);
        dispatch(
            updateKeywordQueryParam({ search: '', type: keywordItem.type })
        );
    };

    return (
        <List.Item>
            <Card className={`${classes.listCard} ${viewMode}-view`}>
                <div className={classes.listLeftBlock}>
                    <div className={classes.listCardHeader}>
                        <Checkbox value={keywordItem.id} />
                        <div className={classes.listLinkWrapper}>
                            <Link
                                className={`${classes.listLink} ${
                                    darkMode ? 'dark-mode' : ''
                                }`}
                                onClick={handleKeywordClick}
                            >
                                {keywordItem.keyword}
                            </Link>
                            <FlagCountrySection
                                countryCode={keywordItem.country_code}
                                languageCode={keywordItem.language_code}
                            />
                        </div>
                    </div>
                    <CreateInfoSection
                        createdByUserId={keywordItem.user_id}
                        createdAt={keywordItem.created_at}
                    />
                </div>
                <div className={classes.listRightBlock}>
                    {(keywordItem.difficulty !== null ||
                        keywordItem.volume) && (
                        <Typography
                            className={`${classes.volumeText} ${
                                darkMode ? 'dark-mode' : ''
                            }`}
                        >
                            {`Volume: ${
                                getNumberWithCommaSeparator(
                                    keywordItem.volume
                                ) || 'N/A'
                            }`}
                        </Typography>
                    )}
                    {keywordItem.difficulty !== null && (
                        <DifficultyBadge
                            size={difficultyBadgeSize.small}
                            difficultyScore={keywordItem.difficulty}
                        />
                    )}
                </div>
            </Card>
        </List.Item>
    );
};

export default KeywordResearchListItem;
