import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../utils/hooks/useIconColor';
import { useOnScreen } from '../../../utils/hooks/useOnScreen';
import { useOpen } from '../../../utils/hooks/useOpen';

import SiderChevronDownIcon from '../../../resources/icons/SiderChevronDown';
import { colorPalette } from '../../../resources/styles/colorPalette';
import SiderChevronUpIcon from '../../../resources/icons/SiderChevronUp';

import './KeywordSelect.scss';

const KeywordSelect = ({
    placeholder,
    options,
    value,
    onChange,
    defaultValue,
}) => {
    const selectWrapperRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();
    const isVisible = useOnScreen(selectWrapperRef);

    const [dropdownTopPosition, setDropdownTopPosition] = useState(0);

    const featuredOptions = options
        .filter((option) => option.featuredPosition !== -1)
        .sort((a, b) => a.featuredPosition - b.featuredPosition);
    const nonFeaturedOptions = options.filter(
        (option) => option.featuredPosition === -1
    );

    const handleScroll = () => {
        if (selectWrapperRef?.current) {
            const { bottom } =
                selectWrapperRef?.current?.getBoundingClientRect();

            setDropdownTopPosition(bottom);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('scroll', handleScroll, true);
        }

        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };

        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && !isVisible) {
            handleOpen();
        }

        // eslint-disable-next-line
    }, [isVisible, isOpen]);

    return (
        <div ref={selectWrapperRef}>
            <Select
                style={{
                    '.antSelectItem.antSelectItemGroup': { height: '100px' },
                }}
                suffixIcon={
                    isOpen ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )
                }
                popupClassName={`keyword-select ${
                    darkMode ? 'keyword-select-dark' : ''
                }`}
                dropdownStyle={{
                    top: dropdownTopPosition,
                }}
                open={isOpen && isVisible}
                showSearch
                placeholder={placeholder ?? 'Search to Select'}
                optionFilterProp='children'
                value={value}
                defaultValue={defaultValue}
                filterOption={(input, option) => {
                    if (option.hasOwnProperty('options')) {
                        return option.options.includes(
                            (child) =>
                                child.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        );
                    }

                    return (
                        option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    );
                }}
                options={
                    nonFeaturedOptions.length
                        ? [...featuredOptions, { options: nonFeaturedOptions }]
                        : featuredOptions
                }
                onSelect={onChange}
                onDropdownVisibleChange={handleOpen}
            />
        </div>
    );
};

export default KeywordSelect;
