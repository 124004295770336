import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { selectViewOnlyBriefData } from '../../../../../contentOptimizer/viewOnly/store/viewOnlySettings.selectors';

import './CardsContentStyles.scss';

const classes = {
    root: 'overview-card-content',
    overview: 'overview',
};

const OverviewCardContent = () => {
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyBriefData = useSelector(selectViewOnlyBriefData);

    return (
        <div className={classes.root}>
            <Typography
                className={`${classes.overview} ${darkMode ? 'dark-mode' : ''}`}
            >
                {viewOnlyBriefData?.extras?.overview || 'N/A'}
            </Typography>
        </div>
    );
};

export default OverviewCardContent;
