import React from 'react';

const AIShortenIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            version='1.1'
            x='0px'
            y='0px'
            viewBox='0 0 24 24'
            xmlSpace='preserve'
            className='fill-icon'
            style={{ enableBackground: 'new 0 0 24 24' }}
        >
            <path
                d='M12.5,15.2H3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h9.5c0.4,0,0.8,0.3,0.8,0.8S12.9,15.2,12.5,15.2z'
                fill={color}
            />
            <path
                d='M21,10.2H3c-0.4,0-0.8-0.3-0.8-0.8S2.6,8.8,3,8.8h18c0.4,0,0.8,0.3,0.8,0.8S21.4,10.2,21,10.2z'
                fill={color}
            />
        </svg>
    );
};

export default AIShortenIcon;
