import Ai, { getHTMLContentBetween } from '@tiptap-pro/extension-ai';
import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { nodeTypes } from '../constants/nodeTypes';

import AiSpellChecker from '../components/AiSpellChecker/AiSpellChecker';

export const CustomAi = Ai.extend({
    addCommands() {
        return {
            ...this.parent?.(),

            aiImproveWriting:
                (textOptions) =>
                ({ editor, state }) => {
                    const { from, to } = state.selection;
                    const selectedText = getHTMLContentBetween(editor, from, to);

                    return editor.commands.aiTextPrompt({
                        ...textOptions,
                        text: `Improve the overall writing quality of the following content by addressing these aspects:
                               1. Grammar and Spelling: Correct any grammatical errors and spelling mistakes.
                               2. Flow and Structure: Enhance the overall flow and coherence of the text, ensuring smooth transitions between sentences and paragraphs.
                               3. Engagement: Make the content more engaging and interesting to read. Use varied sentence structures and expressions to maintain the reader’s interest.
                               4. Tone and Style: Ensure the tone and style are consistent and appropriate for the intended audience and purpose of the content.
                               Content to improve: ${selectedText}`,
                    });
                },
            aiCustomPrompt:
                (textOptions) =>
                ({ editor }) => {
                    const promptText = editor.storage[nodeTypes.aiSpellChecker].customPromptText;

                    if (promptText) {
                        const { from, to } = editor.storage[nodeTypes.aiSpellChecker].selectedRange;
                        const selectedText = getHTMLContentBetween(editor, from, to);

                        return editor.commands.aiTextPrompt({
                            ...textOptions,
                            text: `${promptText}. Original content: ${selectedText}`,
                        });
                    }

                    return false;
                },
        };
    },
});

export const AiSpellCheckerNode = Node.create({
    name: nodeTypes.aiSpellChecker,
    group: 'block',
    content: 'inline*',
    addStorage() {
        return {
            spellCheckerVisible: false,
            regenerateOptionEnabled: false,
            selectedRange: { from: 0, to: 0 },
            response: null,
            activeAiCommand: null,
            spellingErrorsDetected: false,
            customPromptText: '',
            interruptedOptimizer: {
                id: '',
                versionId: '',
                isMatch: false,
                progress: 0,
            },
        };
    },
    addCommands() {
        return {
            setSpellCheckerVisible:
                (status) =>
                ({ editor }) => {
                    editor.setEditable(!status);

                    editor.storage[this.name].spellCheckerVisible = status;

                    return false;
                },
            setRegenerateOptionEnabled:
                (status) =>
                ({ editor }) => {
                    editor.storage[this.name].regenerateOptionEnabled = status;

                    return false;
                },
            setSelectedRange:
                (from, to) =>
                ({ editor }) => {
                    editor.storage[this.name].selectedRange = { from, to };

                    return false;
                },
            setResponse:
                (response) =>
                ({ editor }) => {
                    editor.storage[this.name].response = response;

                    return false;
                },
            setActiveAiCommand:
                (command) =>
                ({ editor }) => {
                    editor.storage[this.name].activeAiCommand = command;

                    return false;
                },
            setSpellingErrorsDetected:
                (status) =>
                ({ editor }) => {
                    editor.storage[this.name].spellingErrorsDetected = status;

                    return false;
                },
            setCustomPromptText:
                (text) =>
                ({ editor }) => {
                    editor.storage[this.name].customPromptText = text;

                    return false;
                },
            setInterruptedOptimizer:
                (optimizer) =>
                ({ editor }) => {
                    editor.storage[this.name].interruptedOptimizer = {
                        ...editor.storage[this.name].interruptedOptimizer,
                        ...optimizer,
                    };

                    return false;
                },
            resetInterruptedOptimizer:
                () =>
                ({ editor }) => {
                    editor.storage[this.name].interruptedOptimizer = {
                        id: '',
                        versionId: '',
                        isMatch: false,
                        progress: 0,
                    };

                    return false;
                },
            resetAiSpellCheckerStorage:
                () =>
                ({ editor }) => {
                    editor.setEditable(true);

                    editor.storage[this.name].spellCheckerVisible = false;
                    editor.storage[this.name].regenerateOptionEnabled = false;
                    editor.storage[this.name].selectedRange = { from: 0, to: 0 };
                    editor.storage[this.name].response = null;
                    editor.storage[this.name].activeAiCommand = null;
                    editor.storage[this.name].spellingErrorsDetected = false;
                    editor.storage[this.name].customPromptText = '';

                    return false;
                },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div[data-type="ai-spell-checker"]',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', { ...HTMLAttributes, 'data-type': 'ai-spell-checker' }, 0];
    },
    addAttributes() {
        return {
            from: { default: 0 },
            to: { default: 0 },
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(AiSpellChecker);
    },
});
