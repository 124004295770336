import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Layout, Tabs, theme } from 'antd';

import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import { keywordResearchTabs } from '../../../../constants/keywordResearchTabs';
import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import CommonPageLayout from '../../../common/layout/CommonPageLayout';

import {
    setCurrentKeyword,
    setKeywordListPagination,
    updateKeywordQueryParam,
    updateKeywordResearchTab,
} from '../store/keywordResearchSettings.actions';
import { keywordResearchSettingsInitialState } from '../store/keywordResearchSettings.reducer';
import { selectKeywordResearchTab } from '../store/keywordResearchSettings.selectors';

import KeywordResearchCredits from '../common/KeywordResearchCredits';

import KeywordResultsContentLayout from './KeywordResultsContentLayout';
import ListOfResultsPageHeader from './ListOfResultsPageHeader';
import ResultsFilters from './ResultsFilters';

const { Content } = Layout;

const classes = {
    content: 'container keyword-research-container results-container',
    contentDark:
        'container-dark keyword-research-container-dark dark-input-autofill',
    keywordResearchTitleWrapper:
        'page-title-wrapper d-flex flex-column flex-center',
};

const KeywordResultsPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const organizationSlug = useOrganizationSlug();

    const { keywordId } = params;

    const darkMode = useSelector(selectDarkMode);
    const selectedTab = useSelector(selectKeywordResearchTab);

    const [isListLoading, setIsListLoading] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setCurrentKeyword(null));
        };

        // eslint-disable-next-line
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const tabsContent = null;

    const tabsItems = [
        {
            key: keywordResearchTabs.keyword.key,
            label: keywordResearchTabs.keyword.label,
            children: tabsContent,
        },
        {
            key: keywordResearchTabs.url.key,
            label: keywordResearchTabs.url.label,
            children: tabsContent,
        },
    ];

    const handleChangeTab = (key) => {
        dispatch(updateKeywordResearchTab(key));

        // Reseting store
        dispatch(updateKeywordQueryParam({ type: key, search: '', page: 1 }));
        dispatch(
            setKeywordListPagination(
                keywordResearchSettingsInitialState.keywordListPagination
            )
        );

        // navigate
        navigate(`/${organizationSlug}/research`);
    };

    return (
        <>
            <ListOfResultsPageHeader />

            <CommonPageLayout>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content} 
                                ${darkMode ? classes.contentDark : ''}`}
                >
                    <Tabs
                        defaultActiveKey={selectedTab}
                        activeKey={selectedTab}
                        items={tabsItems}
                        onChange={handleChangeTab}
                        tabBarExtraContent={
                            <>
                                <ResultsFilters isListLoading={isListLoading} />
                                <KeywordResearchCredits />
                            </>
                        }
                    />
                    <KeywordResultsContentLayout
                        keywordId={keywordId}
                        isListLoading={isListLoading}
                        setIsListLoading={setIsListLoading}
                    />
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default KeywordResultsPage;
