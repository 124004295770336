import React from 'react';

const StartGuideModalContent = () => {
    return (
        // We use video from `vimeo.com`. For getting access please contact Simon Smith
        <>
            <div
                style={{
                    padding: '56.25% 0 0 0',
                    position: 'relative',
                }}
            >
                <iframe
                    src='https://player.vimeo.com/video/924575993?h=d6526e0a73&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                    }}
                    title='rankability-fast-start'
                ></iframe>
            </div>
            <script src='https://player.vimeo.com/api/player.js'></script>
        </>
    );
};

export default StartGuideModalContent;
