import { roleTypes } from '../../constants/roleTypes';

export const hasAccessToManageUsers = (roleKey) => {
    const rolesWithAccessToManageUsers = [
        roleTypes.organizationOwner.key,
        roleTypes.organizationAdmin.key,
        roleTypes.organizationManager.key,
    ];

    return rolesWithAccessToManageUsers.includes(roleKey);
};
