import { keywordResearchesAdvancedFilters } from './keywordResearchesAdvancedFilters';
import { sortOrders } from './sortOrders';

export const keywordResearchResultsSortOptions = {
    match: 'match',
    trending: 'trending',
    highVolume: 'highVolume',
    highRanking: 'highRanking',
    highTrending: 'highTrending',
    lowDifficulty: 'lowDifficulty',
};

const keywordResearchResultsKeywordSortItems = [
    { label: 'Highest Volume', key: 'highVolume' },
    { label: 'Exact Match', key: 'match' },
    { label: 'Highest Trending', key: 'highTrending' },
    { label: 'Lowest Difficulty', key: 'lowDifficulty' },
];

const keywordResearchResultsURLSortItems = [
    { label: 'Highest Ranking', key: 'highRanking' },
    { label: 'Highest Volume', key: 'highVolume' },
    { label: 'Exact Match', key: 'match' },
    { label: 'Highest Trending', key: 'highTrending' },
    { label: 'Lowest Difficulty', key: 'lowDifficulty' },
];

export const getKeywordResearchResultsSortItems = (tab) => {
    switch (tab) {
        case 'keyword':
            return keywordResearchResultsKeywordSortItems;
        case 'url':
            return keywordResearchResultsURLSortItems;
        default:
            return [];
    }
};

export const getKeywordResearchResultsSortQueryByKey = (key) => {
    if (key === keywordResearchResultsSortOptions.highTrending) {
        return {
            field: keywordResearchesAdvancedFilters.monthlySearches.field,
            order: sortOrders.asc,
        };
    } else if (key === keywordResearchResultsSortOptions.lowDifficulty) {
        return {
            field: keywordResearchesAdvancedFilters.difficulty.field,
            order: sortOrders.asc,
        };
    } else if (key === keywordResearchResultsSortOptions.highVolume) {
        return {
            field: keywordResearchesAdvancedFilters.searchVolume.field,
            order: sortOrders.desc,
        };
    } else if (key === keywordResearchResultsSortOptions.highRanking) {
        return {
            field: keywordResearchesAdvancedFilters.position.field,
            order: sortOrders.asc,
        };
    }

    return null;
};
