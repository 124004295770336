import React from 'react';

const IncreaseArrowIcon = ({ color = '#20c752' }) => {
    return (
        <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 10.5 10.5'
            width='12px'
            height='12px'
        >
            <defs></defs>
            <path
                fill={color}
                d='M20.2,3.05a.74.74,0,0,1,.53.22.75.75,0,0,1,0,1.06l-8.2,8.2a.75.75,0,0,1-1.06-1.06l8.2-8.2A.75.75,0,0,1,20.2,3.05Z'
                transform='translate(-11.25 -2.25)'
            />
            <path
                fill={color}
                d='M16.17,2.25H21a.76.76,0,0,1,.75.75V7.83a.75.75,0,0,1-1.5,0V3.75H16.17a.75.75,0,1,1,0-1.5Z'
                transform='translate(-11.25 -2.25)'
            />
        </svg>
    );
};

export default IncreaseArrowIcon;
