const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replaceAll(' ', '[\\/\\ \\-_ ]*');
};

/** Not used */
export const getWordBoundaryRegex = (string) => {
    const escapedString = escapeRegExp(string); // Escape special characters to avoid regex errors
    return new RegExp(`\\b${escapedString}\\b`, 'gi');
};

export const adjustPatternForBrackets = (keyword, patternString = '') => {
    const hasBrackets = /[()[\]]/.test(keyword);
    const isRegexString = typeof patternString === 'string';

    if (hasBrackets) {
        const adjustedPattern = (isRegexString ? patternString : patternString.source).replace(
            /\\b/g,
            ''
        );
        return isRegexString ? adjustedPattern : new RegExp(adjustedPattern, patternString.flags);
    }

    return patternString;
};

export const roundPercentagesInStringByRegex = (string) => {
    const percentageRegex = /(\d+\.\d+)%/g; // Matches percentages with decimal points (e.g. 12.5%)

    return string.replace(percentageRegex, (match, percentage) => {
        return `${Math.round(Number(percentage))}%`;
    });
};
