export const difficultyCategories = [
    {
        min: 0,
        max: 14,
        category: 'Very Easy',
        color: '#1EA11D',
        key: 'very-easy',
    },
    {
        min: 15,
        max: 29,
        category: 'Easy',
        color: '#81B21F',
        key: 'easy',
    },
    {
        min: 30,
        max: 49,
        category: 'Possible',
        color: '#BBBB21',
        key: 'possible',
    },
    {
        min: 50,
        max: 69,
        category: 'Hard',
        color: '#C38E21',
        key: 'hard',
    },
    {
        min: 70,
        max: 89,
        category: 'Very Hard',
        color: '#CC5C24',
        key: 'very-hard',
    },
    {
        min: 90,
        max: 100,
        category: 'Forget About It',
        color: '#D52624',
        key: 'forget-about-it',
    },
];
