import React from 'react';

import { useCompleteBadgeColor } from './useCompleteBadgeColor';

import './CompleteBadge.scss';

const classes = {
    completeBadge: 'complete-badge',
    completeBadgeSmall: 'complete-badge-small',
};

const CompleteBadge = ({ progress, size, inline }) => {
    const badgeColor = useCompleteBadgeColor(progress);

    const getLabel = () => {
        if (size === 'small') {
            return progress ? `${progress}` : '--';
        } else {
            return progress ? `${progress}% COMPLETE` : '--';
        }
    };

    return (
        <div
            className={`${classes.completeBadge} ${
                !progress ? 'badge-disabled' : ''
            } ${size === 'small' ? classes.completeBadgeSmall : ''}`}
            style={{
                backgroundColor: badgeColor,
                display: inline ? 'inline-block' : 'block',
            }}
        >
            {getLabel()}
        </div>
    );
};

export default CompleteBadge;
