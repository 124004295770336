export const selectOrganizationsData = (state) =>
    state?.admin?.adminOrganizationsSettings?.organizationsData;

export const selectOrganizationsIsDataLoading = (state) =>
    state?.admin?.adminOrganizationsSettings?.isDataLoading;

export const selectOrganizationsQueryParams = (state) =>
    state?.admin?.adminOrganizationsSettings?.queryParams;

export const selectOrganizationsTotalPages = (state) =>
    state?.admin?.adminOrganizationsSettings?.totalPages;
