import React from 'react';

import SiderChevronDownIcon from '../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../resources/icons/SiderChevronUp';

import { sortOrders } from '../../../constants/sortOrders';

const classes = {
    activeSortIcon: 'active',
};

const TableHeaderSortIcons = ({ active, order }) => {
    const downIcon = (
        <SiderChevronDownIcon
            className={`${active && classes.activeSortIcon}`}
        />
    );

    const upIcon = (
        <SiderChevronUpIcon className={`${active && classes.activeSortIcon}`} />
    );

    return active ? (order === sortOrders.asc ? upIcon : downIcon) : downIcon;
};

export default TableHeaderSortIcons;
