import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dropdown, Typography } from 'antd';

import AccountSettingsIcon from '../../../../resources/icons/AccountSettings';
import AccountSettingsActiveIcon from '../../../../resources/icons/AccountSettingsActiveIcon';
import AffiliateIcon from '../../../../resources/icons/AffiliateIcon';
import BillingCreditsIcon from '../../../../resources/icons/BillingCreditsIcon';
import ProfileIcon from '../../../../resources/icons/ProfileIcon';
import SettingsIcon from '../../../../resources/icons/SettingsIcon';
import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import SignOutIcon from '../../../../resources/icons/SignOutIcon';
import UsersIcon from '../../../../resources/icons/UsersIcon';

import { accountTabs } from '../../../../constants/accountTabs';
import { authPathes } from '../../../../constants/queryPathes';
import { roleTypes } from '../../../../constants/roleTypes';

import {
    selectCurrentUserRoles,
    selectDarkMode,
    selectIsSubscriptionExpired,
    selectUserData,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import RuptService from '../../../../utils/helpers/RuptService';
import { cleanUpUserData } from '../../../../utils/helpers/authHelpers';
import { checkIsAdmin } from '../../../../utils/helpers/checkIsAdmin';
import { checkIsGlobalTechAdmin } from '../../../../utils/helpers/checkIsGlobalTechAdmin';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { hasAccessToManageUsers } from '../../../../utils/helpers/hasAccessToManageUsers';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import { updateAccountTab } from '../../../pages/account/store/accountSettings.actions';

import { unauthorizedRoutes } from '../../router/Unauthorized/routes';

const classes = {
    accountDropdownWrapper: 'd-flex align-items-center w-100 cursor-pointer sider-nav-acc-wrapper',
    accountDropdownText: 'ml-8px',
    accountDropdownOverlay:
        'nav-acc-overlay dropdown-overlay dropdown-menu dropdown-overlay-with-extra-content',
    accountDropdownOverlayDark:
        'nav-acc-dark-overlay dropdown-overlay-dark dropdown-overlay-with-extra-content-dark',
    accountDropdownContentWrapper: 'dropdown-with-extra-content-wrapper',
    accountDropdownButton: 'd-flex justify-content-between align-items-center w-100',
    UserInfoWrapper: 'nav-acc-user-info-wrapper',
    userNameOption: 'nav-acc-user-name',
    companyOption: 'nav-acc-company',
    bottomLineOption: 'bottom-line line',
    topLineOption: 'top-line line',
    logOutOption: 'd-flex logout-btn',
};

const AccountNavDropdown = ({ collapsed }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);
    const userRoles = useSelector(selectCurrentUserRoles);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();
    const organizationSlug = useOrganizationSlug();

    const logOut = () => {
        axiosAPI
            .get(authPathes.logout, getAxiosHeaders())
            .then(async () => {
                await RuptService.detachDevice(String(user?.id));

                cleanUpUserData(dispatch);
                navigate(unauthorizedRoutes.login);
            })
            .catch((_err) => {});
    };

    const onClick = ({ key }) => {
        handleOpen();

        if (key === 'sign-out') {
            logOut();
        } else if (key === 'affiliate') {
            window.open('https://www.rankability.com/affiliates', '_blank');
        } else {
            navigate(`${organizationSlug}/account/${key}`);
            dispatch(updateAccountTab(key));
        }
    };

    const accountNavItems = [
        {
            label: accountTabs.profile.label,
            key: accountTabs.profile.key,
            icon: <ProfileIcon color={iconColor} />,
            title: '',
            roles: [
                roleTypes.techAdmin.key,
                roleTypes.globalAdmin.key,
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
                roleTypes.organizationMember.key,
            ],
            disabled:
                !checkIsGlobalTechAdmin(userRoles) &&
                !checkIsAdmin(user?.roles?.[0]?.name) &&
                isSubscriptionExpired,
        },
        {
            label: accountTabs.settings.label,
            key: accountTabs.settings.key,
            icon: <SettingsIcon color={iconColor} />,
            title: '',
            roles: [
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
            ],
            disabled: !checkIsGlobalTechAdmin(userRoles) && isSubscriptionExpired,
        },
        {
            label: accountTabs['billing-credits'].label,
            key: accountTabs['billing-credits'].key,
            icon: <BillingCreditsIcon color={iconColor} />,
            title: '',
            roles: [roleTypes.organizationOwner.key, roleTypes.organizationAdmin.key],
            disabled: !checkIsAdmin(user?.roles?.[0]?.name) && isSubscriptionExpired,
        },
        // {
        //     label: accountTabs['affiliate'].label,
        //     key: accountTabs['affiliate'].key,
        //     icon: <AffiliateIcon color={iconColor} />,
        //     title: '',
        //     roles: [roleTypes.organizationOwner.key, roleTypes.organizationAdmin.key],
        //     disabled: !checkIsAdmin(user?.roles?.[0]?.name) && isSubscriptionExpired,
        // },
        // TODO: Uncomment after implementation
        // {
        //     label: accountTabs.integrations.label,
        //     key: accountTabs.integrations.key,
        //     icon: <IntegrationsIcon color={iconColor} />,
        //     title: '',
        //     roles: [
        //         roleTypes.organizationOwner.key,
        //         roleTypes.organizationAdmin.key,
        //         roleTypes.organizationManager.key,
        //     ],
        //     disabled: isSubscriptionExpired,
        // },
        {
            label: (
                <>
                    <div className={classes.topLineOption}></div>
                    <div className={classes.logOutOption}>
                        <SignOutIcon color={iconColor} />
                        <Typography className={classes.accountDropdownText}>Sign Out</Typography>
                    </div>
                </>
            ),
            key: 'sign-out',
            title: '',
            roles: [
                roleTypes.techAdmin.key,
                roleTypes.globalAdmin.key,
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
                roleTypes.organizationMember.key,
            ],
        },
    ];

    if (hasAccessToManageUsers(user?.roles?.[0]?.name)) {
        accountNavItems.splice(2, 0, {
            label: accountTabs.users.label,
            key: accountTabs.users.key,
            icon: <UsersIcon color={iconColor} />,
            title: '',
            roles: [
                roleTypes.organizationOwner.key,
                roleTypes.organizationAdmin.key,
                roleTypes.organizationManager.key,
            ],
            disabled: isSubscriptionExpired,
        });
    }

    return (
        <Dropdown
            menu={{
                items: accountNavItems?.filter((item) =>
                    item?.roles?.some((itemRole) =>
                        userRoles?.some((userRole) => itemRole === userRole?.name)
                    )
                ),
                onClick,
            }}
            trigger={['click']}
            placement={collapsed ? 'rightBottom' : 'top'}
            overlayStyle={{
                width: '178px',
            }}
            overlayClassName={`${classes.accountDropdownOverlay}
                               ${darkMode ? classes.accountDropdownOverlayDark : ''}
                               ${collapsed && 'nav-acc-overlay-collapsed'}`}
            onOpenChange={handleOpen}
            dropdownRender={(menu) => (
                <div className={classes.accountDropdownContentWrapper}>
                    <div className={classes.UserInfoWrapper}>
                        <Typography className={classes.userNameOption}>
                            {`${user?.first_name} ${user?.last_name}`}
                        </Typography>
                        <Typography className={classes.companyOption}>
                            {user?.organization?.name}
                        </Typography>
                    </div>
                    {menu}
                </div>
            )}
        >
            <div className={classes.accountDropdownWrapper}>
                {isOpen ? <AccountSettingsActiveIcon /> : <AccountSettingsIcon color={iconColor} />}
                {!collapsed && (
                    <div className={classes.accountDropdownButton}>
                        <Typography
                            className={`${classes.accountDropdownText}
                                        ${isOpen && 'text-active'}`}
                        >
                            My Account
                        </Typography>
                        {isOpen ? <SiderChevronDownIcon /> : <SiderChevronUpIcon />}
                    </div>
                )}
            </div>
        </Dropdown>
    );
};

export default AccountNavDropdown;
