import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import './Loader.scss';

const classes = {
    loaderWrapper:
        'loader-wrapper bar-loader-wrapper w-100 h-100 d-flex flex-center',
    loaderWrapperDark: 'bar-loader-wrapper-dark',
    loaderMessage: 'bar-loader-message',
    loader: 'bar-loader',
};

const BarLoader = ({ loaderMessage }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.loaderWrapper}
                        ${darkMode ? classes.loaderWrapperDark : ''}`}
        >
            {loaderMessage && (
                <Typography className={classes.loaderMessage}>
                    {loaderMessage}
                </Typography>
            )}
            <div className={classes.loader} />
        </div>
    );
};

export default BarLoader;
