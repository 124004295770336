import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { transformContentData } from '../../../../utils/helpers/editor/slateToHtml';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import {
    addEditorVersion,
    updateCurrentEditorLoading,
    updateEditorVersions,
} from '../store/tiptapEditor.actions';
import {
    selectCurrentEditorContent,
    selectCurrentEditorText,
} from '../store/tiptapEditor.selectors';

const useEditorVersions = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const organizationSlug = useOrganizationSlug();

    const currentEditorContent = useSelector(selectCurrentEditorContent);
    const currentEditorText = useSelector(selectCurrentEditorText);

    const [isVersionCreating, setIsVersionCreating] = useState(false);

    const createNewEditorVersion = () => {
        if (contentOptimizerId) {
            setIsVersionCreating(true);

            axiosAPI
                .post(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions`,
                    {
                        content: JSON.stringify(currentEditorContent || ''),
                        content_text: currentEditorText || '',
                    },
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then((res) => {
                    dispatch(addEditorVersion(res.data));
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsVersionCreating(false);
                });
        }
    };

    const fetchEditorVersions = () => {
        if (contentOptimizerId) {
            dispatch(updateCurrentEditorLoading(true));

            axiosAPI
                .get(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions`,
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        if (result.data?.length > 0) {
                            const transformedData = result.data.map(
                                (dataItem) => ({
                                    ...dataItem,
                                    content: transformContentData(
                                        dataItem.content,
                                        dataItem.active
                                    ),
                                })
                            );

                            dispatch(updateEditorVersions(transformedData));
                        } else {
                            createNewEditorVersion();
                        }
                    }

                    dispatch(updateCurrentEditorLoading(false));
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsVersionCreating(false);
                });
        }
    };

    return [
        fetchEditorVersions,
        createNewEditorVersion,
        isVersionCreating,
        setIsVersionCreating,
    ];
};

export default useEditorVersions;
