export const usageSettingsActions = {
    UPDATE_USAGE_QUERY_PARAMS: 'UPDATE_USAGE_QUERY_PARAMS',
};

export const updateUsageQueryParams = (payload) => {
    return {
        type: usageSettingsActions.UPDATE_USAGE_QUERY_PARAMS,
        payload,
    };
};
