import { colorPalette } from './colorPalette';

export const lightTheme = {
    token: {
        colorBgLayout: colorPalette.layoutBackground.light,
        colorBgContainer: colorPalette.cardBackground.light,
        colorPrimary: colorPalette.colorPrimary,
        colorPrimaryHover: colorPalette.colorPrimary,
        colorError: colorPalette.colorError,
        colorSuccess: colorPalette.colorSuccess,
        colorPrimaryBg: colorPalette.colorPrimaryBg.light,
        colorTextDisabled: colorPalette.textDisabledColor.light,
        colorLink: colorPalette.colorPrimary,
        colorLinkHover: colorPalette.colorPrimary,
    },
    components: {
        Menu: {
            colorItemText: colorPalette.textColor.light,
            colorItemTextHover: colorPalette.textColor.light,
            colorItemBgHover: colorPalette.colorPrimaryBg.light,
            motionDurationSlow: '0s',
        },
        Card: {
            colorBgContainer: colorPalette.cardBackground.light,
            colorBorderSecondary: colorPalette.borderColor.light,
            borderRadiusLG: 6,
        },
        Button: {
            colorPrimaryHover: colorPalette.colorPrimaryBgHover.light,
            colorTextDisabled: colorPalette.textDisabledBtnColor,
            colorBgContainerDisabled: colorPalette.colorPrimaryBgDisabled.light,
            colorBorder: colorPalette.colorPrimaryBgDisabled.light,
        },
        Typography: {
            colorText: colorPalette.textColor.light,
            colorTextHeading: colorPalette.textAccentColor.light,
        },
        Dropdown: {
            controlItemBgHover: colorPalette.colorPrimaryBg.light,
            colorBgElevated: colorPalette.dropdownBackground.light,
            colorText: colorPalette.textColor.light,
        },
        Select: {
            colorBgContainer: colorPalette.inputBackground.light,
            colorBgElevated: colorPalette.dropdownBackground.light,
            controlItemBgActive: colorPalette.colorPrimaryBg.light,
            colorTextPlaceholder: colorPalette.textColor.light,
            colorBorder: colorPalette.borderColor.light,
            colorText: colorPalette.textColor.light,
            colorTextDescription: colorPalette.textColor.light,
            controlItemBgHover: colorPalette.colorPrimaryBg.light,
            colorTextQuaternary: colorPalette.textColor.light,
            controlOutlineWidth: 0,
            fontWeightStrong: 400,
        },
        Modal: {
            colorBgElevated: colorPalette.modalBackground.light,
            colorBgMask: colorPalette.modalMask.light,
            borderRadiusLG: 6,
            boxShadowSecondary: colorPalette.modalBoxShadow.light,
            colorTextHeading: colorPalette.textAccentColor.light,
        },
        Input: {
            colorBg: colorPalette.inputBackground.light,
            colorTextPlaceholder: colorPalette.textColor.light,
            colorBorder: colorPalette.borderColor.light,
            colorText: colorPalette.textAccentColor.light,
            controlOutline: 'transparent',
        },
        InputNumber: {
            colorBgContainer: colorPalette.inputBackground.light,
            colorTextPlaceholder: colorPalette.textColor.light,
            colorBorder: colorPalette.borderColor.light,
            colorText: colorPalette.textAccentColor.light,
            controlOutline: 'transparent',
        },
        Spin: {
            colorPrimary: colorPalette.layoutBackground.dark,
        },
        Checkbox: {
            colorText: colorPalette.textColor.light,
        },
        Tabs: {
            colorText: colorPalette.textColor.light,
            colorSplit: colorPalette.borderColor.light,
        },
        Form: {
            colorTextHeading: colorPalette.textColor.light,
        },
        Table: {
            colorTextHeading: colorPalette.textColor.light,
            colorText: colorPalette.textAccentColor.light,
            colorBorderSecondary: colorPalette.borderColor.light,
            colorFillAlter: colorPalette.tableHeaderBackground.light,
            colorIcon: colorPalette.textColor.light,
            colorFillSecondary: colorPalette.tableHeaderBackground.light,
            borderRadiusLG: 6,
            padding: 24,
            motionDurationSlow: '0s',
            motionDurationMid: '0s',
            motionDurationFast: '0s',
        },
        Radio: {
            colorBorder: colorPalette.borderColor.light,
            colorBgContainer: colorPalette.radioBackground.light,
            colorWhite: colorPalette.radioBackground.light,
        },
        Switch: {
            colorTextQuaternary: colorPalette.switchSecondaryBackground.light,
        },
        Segmented: {
            colorBgLayout: colorPalette.segmentedBackground.light,
            colorBgElevated: colorPalette.segmentedElevated.light,
            colorText: colorPalette.colorPrimary,
            colorTextLabel: colorPalette.textColor.light,
            fontSize: 16,
            lineHeight: 22,
        },
        Drawer: {
            colorBgElevated: colorPalette.drawerBackground.light,
        },
    },
};
