import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectIsGracePeriod,
    selectUserData,
} from '../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsGlobalTechAdmin } from '../../utils/helpers/checkIsGlobalTechAdmin';
import { usePrevious } from '../../utils/hooks/usePrevious';
import { useSetSubscriptionLimits } from '../../utils/hooks/useSetSubscriptionLimits';

import {
    setIsGracePeriod,
    setIsSubscriptionExpired,
} from '../../userBrowserSettings/store/browserSettings.actions';
import {
    selectCurrentSubscription,
    selectSubscriptionLimits,
} from '../pages/account/billing/store/billingSettings.selectors';

const withSubscriptionLimitsProvider = (ComposedComponent) => {
    return memo((props) => {
        const dispatch = useDispatch();

        const subscriptionLimits = useSelector(selectSubscriptionLimits);
        const currentSubscription = useSelector(selectCurrentSubscription);
        const isGracePeriod = useSelector(selectIsGracePeriod);
        const userData = useSelector(selectUserData);

        const { updateSubscriptionLimits } = useSetSubscriptionLimits();

        const prevSubscriptionPlanId = usePrevious(currentSubscription?.plan?.id);
        const prevIsGracePeriod = usePrevious(isGracePeriod);

        useEffect(() => {
            if (
                currentSubscription &&
                !currentSubscription?.canceled_at &&
                !currentSubscription?.cancel_at_period_end
            ) {
                dispatch(setIsSubscriptionExpired(false));

                if (!currentSubscription?.paid) {
                    dispatch(setIsGracePeriod(true));
                }
            }

            if (currentSubscription?.paid) {
                dispatch(setIsGracePeriod(false));
            }

            // eslint-disable-next-line
        }, [currentSubscription]);

        useEffect(() => {
            if (userData && !checkIsGlobalTechAdmin(userData?.roles)) {
                if (
                    (userData && !subscriptionLimits) ||
                    (userData &&
                        prevSubscriptionPlanId &&
                        currentSubscription?.plan?.id &&
                        prevSubscriptionPlanId !== currentSubscription?.plan?.id) ||
                    (userData && !isGracePeriod && prevIsGracePeriod)
                ) {
                    updateSubscriptionLimits();
                }
            }

            // eslint-disable-next-line
        }, [
            subscriptionLimits,
            userData,
            currentSubscription?.plan?.id,
            currentSubscription?.subscription_id,
            isGracePeriod,
        ]);

        return <ComposedComponent {...props} />;
    });
};

export default withSubscriptionLimitsProvider;
