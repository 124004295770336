import React from 'react';

import CancelledSubscriptionNotification from '../notifications/CanceledSubscriptionNotification';
import ExpiredSubscriptionNotification from '../notifications/ExpiredSubscriptionNotification';
import GracePeriodNotification from '../notifications/GracePeriodNotification';
import TrialNotification from '../notifications/TrialNotification';

const classes = {
    pageLayoutWrapper: 'page-layout-wrapper',
};

const CommonPageLayout = ({ children, customStyle }) => {
    return (
        <div className={classes.pageLayoutWrapper} style={customStyle}>
            <CancelledSubscriptionNotification />
            <ExpiredSubscriptionNotification />
            <GracePeriodNotification />
            <TrialNotification />

            {children}
        </div>
    );
};

export default CommonPageLayout;
