import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { openNotification } from '../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../utils/axiosAPI';

import { notificationType } from '../../../../../constants/notificationType';
import { globalAdminPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import {
    setOrganizationsData,
    setOrganizationsIsDataLoading,
    setOrganizationsTotalPages,
} from '../store/adminOrganizationsSettings.actions';
import { selectOrganizationsQueryParams } from '../store/adminOrganizationsSettings.selectors';

import AdminOrganizationsTable from './AdminOrganizationsTable';

const { Content } = Layout;

const classes = {
    content: 'container organizations-container',
    contentDark: 'container-dark',
};

const OrganizationPageContent = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const queryParams = useSelector(selectOrganizationsQueryParams);

    useEffect(() => {
        dispatch(setOrganizationsIsDataLoading(true));

        axiosAPI
            .get(
                globalAdminPathes.getOrganizations,
                { params: queryParams },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    batch(() => {
                        dispatch(setOrganizationsData(result?.data));
                        dispatch(
                            setOrganizationsTotalPages(
                                result?.headers?.['x-total-pages']
                            )
                        );
                    });
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    'Looks like something went wrong while loading users. Please try again later'
                );
            })
            .finally(() => {
                dispatch(setOrganizationsIsDataLoading(false));
            });

        // eslint-disable-next-line
    }, [queryParams]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Content
            style={{
                background: colorBgContainer,
            }}
            className={`${classes.content} 
                        ${darkMode ? classes.contentDark : ''}`}
        >
            <AdminOrganizationsTable />
        </Content>
    );
};

export default OrganizationPageContent;
