export const contentOptimizerMainTabs = {
    single: {
        key: 'single',
        label: 'Single',
    },
    multiple: {
        key: 'multiple',
        label: 'Multiple',
    },
    projects: {
        key: 'projects',
        label: 'Projects',
    },
};
