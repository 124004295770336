export const adminQuotasSettingsActions = {
    SET_QUOTAS_DATA: 'SET_QUOTAS_DATA',
    UPDATE_QUOTA: 'UPDATE_QUOTA',
    SET_QUOTAS_IS_DATA_LOADING: 'SET_QUOTAS_IS_DATA_LOADING',
    UPDATE_QUOTAS_QUERY_PARAMS: 'UPDATE_QUOTAS_QUERY_PARAMS',
    SET_QUOTAS_TOTAL_PAGES: 'SET_QUOTAS_TOTAL_PAGES',
};

export const setQuotasData = (payload) => {
    return {
        type: adminQuotasSettingsActions.SET_QUOTAS_DATA,
        payload,
    };
};

export const updateQuota = (payload) => {
    return {
        type: adminQuotasSettingsActions.UPDATE_QUOTA,
        payload,
    };
};

export const setQuotasIsDataLoading = (payload) => {
    return {
        type: adminQuotasSettingsActions.SET_QUOTAS_IS_DATA_LOADING,
        payload,
    };
};

export const updateQuotasQueryParams = (payload) => {
    return {
        type: adminQuotasSettingsActions.UPDATE_QUOTAS_QUERY_PARAMS,
        payload,
    };
};

export const setQuotasTotalPages = (payload) => {
    return {
        type: adminQuotasSettingsActions.SET_QUOTAS_TOTAL_PAGES,
        payload,
    };
};
