import { optimizerKeywordsSortKeys } from '../../../../../constants/optimizerKeywordsSortItems';
import { checkAreUrlsStrictlyEqual } from '../../../../../utils/helpers/urlHelpers';
import { competitorsSortItems } from '../content/competitorsTab/content/competitorsContentConstants';
import { contentOptimizerContentActions } from './contentOptimizerContent.actions';

const contentOptimizerContentInitialState = {
    currentContentOptimizer: null,
    isOptimizerLoading: false,

    contentBriefCompetitor: null,
    competitorsList: [],
    isCompetitorScanUrlLoading: false,

    competitorsFilters: {
        competitorsSearch: '',
        competitorsSortKey: competitorsSortItems[0]?.key,
        competitorsSortDescending: true,
    },

    keywords: null,
    keywordsSortKey: optimizerKeywordsSortKeys.importance,
    keywordsSearchKey: '',
    selectedKeyword: null,
    shared: false,
    competitorsToUpdate: [],
    competitorsPendingRequest: [],
    // Multiple keywords selection
    isMultipleKeywordsSelectionEnabled: false,
    selectedKeywords: [],
};

export const contentOptimizerContentReducer = (
    state = contentOptimizerContentInitialState,
    { type, payload }
) => {
    switch (type) {
        case contentOptimizerContentActions.UPDATE_SELECTED_KEYWORD: {
            return {
                ...state,
                selectedKeyword: payload,
            };
        }

        case contentOptimizerContentActions.UPDATE_COMPETITORS_SEARCH: {
            return {
                ...state,
                competitorsFilters: {
                    ...state.competitorsFilters,
                    competitorsSearch: payload,
                },
            };
        }

        case contentOptimizerContentActions.UPDATE_COMPETITORS_SORT_KEY: {
            return {
                ...state,
                competitorsFilters: {
                    ...state.competitorsFilters,
                    competitorsSortKey: payload,
                    competitorsSortDescending:
                        payload === state.competitorsFilters.competitorsSortKey
                            ? !state.competitorsFilters
                                  .competitorsSortDescending
                            : false,
                },
            };
        }

        case contentOptimizerContentActions.UPDATE_COMPETITOR: {
            if (Array.isArray(payload)) {
                const updatedCompetitorsList = state?.competitorsList?.map(
                    (competitor) => {
                        const matchCompetitor = payload?.find(
                            (updatedCompetitor) =>
                                (competitor?.onpageTaskId &&
                                    competitor?.onpageTaskId ===
                                        updatedCompetitor?.onpageTaskId) ||
                                checkAreUrlsStrictlyEqual(
                                    competitor?.url,
                                    updatedCompetitor?.url
                                )
                        );

                        if (matchCompetitor) {
                            return {
                                ...competitor,
                                ...matchCompetitor,
                            };
                        }

                        return competitor;
                    }
                );

                return {
                    ...state,
                    competitorsList: updatedCompetitorsList,
                };
            } else {
                const updatedCompetitorsList = state?.competitorsList?.map(
                    (competitor) => {
                        if (
                            competitor?.onpageTaskId === payload?.onpageTaskId
                        ) {
                            return {
                                ...competitor,
                                ...payload,
                            };
                        }

                        return competitor;
                    }
                );

                return {
                    ...state,
                    competitorsList: updatedCompetitorsList,
                };
            }
        }

        case contentOptimizerContentActions.REMOVE_COMPETITOR: {
            const competitor = state?.competitorsList.find(
                (x) => x.onpageTaskId === payload.id
            );
            const mappedCompetitorsList =
                (competitor?.desktop &&
                    competitor?.desktop <=
                        payload.competitor_positions_analyzed) ||
                (competitor?.mobile &&
                    competitor?.mobile <= payload.competitor_positions_analyzed)
                    ? state?.competitorsList.map((x) =>
                          x.onpageTaskId === payload.id
                              ? { ...x, highlighted: false }
                              : x
                      )
                    : state?.competitorsList.filter(
                          (x) => x.onpageTaskId !== payload.id
                      );

            return {
                ...state,
                competitorsList: mappedCompetitorsList,
            };
        }

        case contentOptimizerContentActions.SET_COMPETITORS_TO_UPDATE: {
            return {
                ...state,
                competitorsToUpdate: payload,
            };
        }

        case contentOptimizerContentActions.SET_COMPETITORS_PENDING_REQUEST: {
            return {
                ...state,
                competitorsPendingRequest: payload,
            };
        }

        case contentOptimizerContentActions.UPDATE_CONTENT_BRIEF_COMPETITORS: {
            const mappedCompetitorsList = payload?.task_result
                ? JSON.parse(payload?.task_result)?.map((item, index) => {
                      return {
                          id: index,
                          desktop: item?.rank?.desktop || null,
                          mobile: item?.rank?.mobile || null,
                          title: item?.title,
                          url: item?.url,
                          favicon: item?.favicon,
                          wordCount: item?.word_count || null,
                          rankabilityScore: Math.ceil(item?.score) || null,
                          domainRank: Math.ceil(item?.domain_rank) || null,
                          onpageTaskId: item?.onpage_task_id,
                          highlighted: item?.highlighted,
                          useInOptimalRange: item?.include_in_optimal_range,
                      };
                  })
                : [];

            return {
                ...state,
                contentBriefCompetitor: payload,
                competitorsList: mappedCompetitorsList,
            };
        }

        case contentOptimizerContentActions.ADD_ITEM_TO_CONTENT_BRIEF_COMPETITORS: {
            return {
                ...state,
                competitorsList: [
                    {
                        ...payload,
                        rankabilityScore: Math.ceil(payload?.score) || null,
                        id: state.competitorsList.length,
                        wordCount: payload?.word_count || null,
                        domainRank: Math.ceil(payload?.domain_rank) || null,
                        onpageTaskId: payload?.onpage_task_id,
                        highlighted: payload?.highlighted,
                    },
                    ...state.competitorsList,
                ],
            };
        }

        case contentOptimizerContentActions.SET_COMPETITOR_SCAN_URL_LOADING: {
            return {
                ...state,
                isCompetitorScanUrlLoading: payload,
            };
        }

        case contentOptimizerContentActions.UPDATE_CURRENT_CONTENT_OPTIMIZER: {
            return {
                ...state,
                currentContentOptimizer: payload,
                currentOptimizerShared: payload?.content_brief?.shared,
                keywords: JSON.parse(payload?.keywords),
                suggestions: JSON.parse(payload?.suggestions),
                shared: payload?.shared,
            };
        }

        case contentOptimizerContentActions.SET_SHARED: {
            return {
                ...state,
                shared: payload,
            };
        }

        case contentOptimizerContentActions.SET_OPTIMIZER_LOADING: {
            return {
                ...state,
                isOptimizerLoading: payload,
            };
        }

        case contentOptimizerContentActions.SET_KEYWORDS: {
            return {
                ...state,
                keywords: payload,
            };
        }

        case contentOptimizerContentActions.SET_SUGGESTIONS: {
            return {
                ...state,
                suggestions: payload,
            };
        }

        case contentOptimizerContentActions.UPDATE_KEYWORDS_SORT_KEY: {
            return {
                ...state,
                keywordsSortKey: payload,
            };
        }

        case contentOptimizerContentActions.UPDATE_KEYWORDS_SEARCH_KEY: {
            return {
                ...state,
                keywordsSearchKey: payload,
            };
        }

        case contentOptimizerContentActions.CLEAR_CONTENT_OPTIMIZER_CONTENT: {
            return {
                ...contentOptimizerContentInitialState,
            };
        }

        // Multiple keywords selection
        case contentOptimizerContentActions.TOGGLE_MULTIPLE_KEYWORDS_SELECTION: {
            return {
                ...state,
                isMultipleKeywordsSelectionEnabled:
                    !state.isMultipleKeywordsSelectionEnabled,
            };
        }

        case contentOptimizerContentActions.UPDATE_SELECTED_KEYWORDS: {
            return {
                ...state,
                selectedKeywords: payload,
            };
        }

        case contentOptimizerContentActions.RESET_MULTIPLE_KEYWORDS_SELECTION: {
            return {
                ...state,
                isMultipleKeywordsSelectionEnabled:
                    contentOptimizerContentInitialState.isMultipleKeywordsSelectionEnabled,
                selectedKeywords:
                    contentOptimizerContentInitialState.selectedKeywords,
            };
        }

        default: {
            return state;
        }
    }
};
