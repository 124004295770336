import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Spin, Table, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Title from 'antd/es/skeleton/Title';
import moment from 'moment';

import CancellationService from '../../../../services/cancellation.service';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import CustomHeader from '../../../common/header/Header';
import Loader from '../../../common/loader/Loader';

const classes = {
    root: 'cancellations-page',
    pageLayoutWrapper: 'page-layout-wrapper',
    organizationTitleWrapper: 'page-title-wrapper',
    pageTitle: 'page-title',
    content: 'container organizations-container',
    contentDark: 'container-dark',
};

const CancellationsPage = () => {
    const [cancellations, setCancellations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const darkMode = useSelector(selectDarkMode);

    const getCancellationsAsync = async () => {
        setIsLoading(true);

        try {
            const response = await CancellationService.getCancellations();

            if (response?.data) {
                setCancellations(response.data);
            }
        } catch {
            openBasicErrorNotification();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCancellationsAsync();
    }, []);

    const columns = [
        {
            title: 'NAME',
            key: 'name',
            render: (row) => `${row.user.first_name} ${row.user.last_name}`,
        },
        {
            title: 'EMAIL',
            key: 'email',
            render: (row) => row.user.email,
        },
        {
            title: 'REASON',
            key: 'reason',
            render: (row) => row.cancellation_reason?.text,
        },
        {
            title: 'NOTE',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'DATE CREATED',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => {
                return moment.utc(created_at).local().format('LL');
            },
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            render: (_, record) => {
                return <div className='actions-cell'></div>;
            },
        },
    ];

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <CustomHeader />
            <div className={classes.pageLayoutWrapper}>
                <div className={classes.organizationTitleWrapper}>
                    <Title level={2} className={classes.pageTitle}>
                        Cancellations
                    </Title>
                </div>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content} ${
                        darkMode ? classes.contentDark : ''
                    }`}
                >
                    <Spin indicator={<Loader />} spinning={isLoading}>
                        <Table
                            columns={columns}
                            dataSource={cancellations}
                            bordered
                            scroll={{
                                x: true,
                            }}
                        />
                    </Spin>
                </Content>
            </div>
        </>
    );
};

export default CancellationsPage;
