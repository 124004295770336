import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { mainBreadcrumbPaths } from '../../../../constants/bredcrumbPaths';

import SiderChevronRightIcon from '../../../../resources/icons/SiderChevronRight';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';
import { useBreadcrumbItem } from '../../../../utils/hooks/useBreadcrumbItem';

const CustomBreadcrumb = ({
    extraBreadcrumbs = [],
    additionalBreadcrumbFunction,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const defaultBreadcrumbItem = useBreadcrumbItem(mainBreadcrumbPaths);
    const organizationSlug = useOrganizationSlug();

    const chevronIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const getExtraBreadcrumbItems = () => {
        if (extraBreadcrumbs && extraBreadcrumbs.length > 0) {
            return extraBreadcrumbs.map((item, index) => {
                return <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>;
            });
        }
    };

    const breadcrumbItems = [
        <Breadcrumb.Item key='home'>
            <Link
                to={`/${organizationSlug}${defaultBreadcrumbItem.path}`}
                onClick={additionalBreadcrumbFunction || null}
            >
                {defaultBreadcrumbItem.name}
            </Link>
        </Breadcrumb.Item>,
    ].concat(getExtraBreadcrumbItems());

    return (
        <Breadcrumb
            separator={<SiderChevronRightIcon color={chevronIconColor} />}
        >
            {breadcrumbItems}
        </Breadcrumb>
    );
};

export default CustomBreadcrumb;
