import React, { useState } from 'react';

import { Select } from 'antd';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../utils/hooks/useIconColor';

import { monthlySearchesMonthsOptions } from './trendFilterOptions';

const TimeFrameSelect = ({ selectValue, handleChange }) => {
    const iconColor = useIconColor();

    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const handleSelectOpen = (open) => {
        setIsSelectOpen(open);
    };

    const handleMonthsChange = (value) => {
        handleChange('months', value);
    };

    return (
        <Select
            value={selectValue || null}
            placeholder='Timeframe'
            options={monthlySearchesMonthsOptions}
            suffixIcon={
                isSelectOpen ? (
                    <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                ) : (
                    <SiderChevronDownIcon color={iconColor} />
                )
            }
            popupClassName='keyword-filter-select'
            onDropdownVisibleChange={handleSelectOpen}
            onSelect={handleMonthsChange}
        />
    );
};

export default TimeFrameSelect;
