import { useEffect, useMemo, useState } from 'react';

export const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIntersecting(entry.isIntersecting)
            ),

        // eslint-disable-next-line
        [ref.current]
    );

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };

        // eslint-disable-next-line
    }, [ref, observer]);

    return isIntersecting;
};
