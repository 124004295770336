import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import './Lists.scss';

const { Title } = Typography;

const classes = {
    categoriesList: 'categories-list',
    categoryWrapper:
        'category-wrapper d-flex align-items-center justify-content-between',
    categoryBadge: 'difficulty-badge',
    categoryName: 'category-name d-flex flex-center',
    categoryBadgeDivider: 'category-badge-divider',
    categoryEmptyDivider: 'category-empty-divider',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    categoryNameDotted: 'text-decoration-dotted cursor-pointer',
};

const CategoriesList = ({ categories }) => {
    const darkMode = useSelector(selectDarkMode);

    const getCategoryBadge = (category) => {
        const backgroundStyles = {
            backgroundColor: category.color,
        };

        if (category.value) {
            return (
                <>
                    <Title className={classes.categoryEmptyDivider} level={5}>
                        {' '}
                    </Title>
                    <div
                        style={{ ...backgroundStyles }}
                        className={classes.categoryBadge}
                    >
                        {category.value}
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    style={{ ...backgroundStyles }}
                    className={classes.categoryBadge}
                >
                    {category.min}
                </div>
                <Title className={classes.categoryBadgeDivider} level={5}>
                    -
                </Title>
                <div
                    style={{ ...backgroundStyles }}
                    className={classes.categoryBadge}
                >
                    {category.max}
                </div>
            </>
        );
    };

    return (
        <div className={classes.categoriesList}>
            {categories.map((category) => (
                <div
                    key={`category-list-${category.key}`}
                    className={classes.categoryWrapper}
                >
                    <div className='d-flex align-items-center'>
                        {getCategoryBadge(category)}
                    </div>
                    <div
                        className={`${classes.categoryName} ${
                            category.tooltip ? classes.categoryNameDotted : ''
                        }`}
                    >
                        <Tooltip
                            title={category.tooltip || ''}
                            overlayClassName={`${classes.tooltipOverlay} ${
                                darkMode ? classes.tooltipOverlayDark : ''
                            }`}
                        >
                            <Title level={5}>{category.category}</Title>
                        </Tooltip>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CategoriesList;
